export const STATUS_DRAFT = "draft";
export const STATUS_APPROVED = "approved";
export const STATUS_ACTIVE = "active";
export const STATUS_OVERDUE = "over_due";
export const STATUS_COMPLETED = "completed";
export const STATUS_ARCHIVED = "archived";

/*
    ``````````````````
    CALCULATION METHOD

    ``````````````````
*/
export const SWD_MA_CALCULATION_METHOD_SUM = "swd_ma_calculation_method_sum";
export const SWD_MA_CALCULATION_METHOD_AVERAGE =
  "swd_ma_calculation_method_average";
export const SWD_MA_CALCULATION_METHOD_LAST_VALUE =
  "swd_ma_calculation_method_last_value";

/*
    ````````````
    INPUT METHOD

    ````````````
*/

export const SWD_MA_INPUT_METHOD_LAST_VALUE = "swd_ma_input_method_last_value";
export const SWD_MA_INPUT_METHOD_INCREMENT = "swd_ma_input_method_increment";

/*
    ``````````
    VALUE TYPE

    ``````````
*/

export const NUMERIC = "numeric";
export const PERCENTAGE = "percentage";

/*
    `````````````````
    ACHIEVEMENT TYPE

    `````````````````
*/

export const SWD_MA_ACHIEVEMENT_TYPE_MORE_ORIENTED =
  "swd_ma_achievement_type_more_oriented";
export const SWD_MA_ACHIEVEMENT_TYPE_LESS_ORIENTED =
  "swd_ma_achievement_type_less_oriented";

import React, { useState } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Radio,
  CircularProgress,
  Hidden,
} from "@material-ui/core";

import axios from "axios";

import Redirect from "../../../../utilities/Redirect";
import { ToMembershipPaymentStatusAfterCountDown } from "../../../../constants/config-redirect-url";

import DialogModalOptionBerbayar from "./DialogModalOptionBerbayar";
import { URL_API } from "../../../../constants/config-api";

import bank_bca from "../../../../assets/images/bank-bca.png";
import bank_bni from "../../../../assets/images/bank-bni.png";
import bank_bri from "../../../../assets/images/bank-bri.png";
import bank_mandiri from "../../../../assets/images/bank-mandiri.png";
import PictInfo from "../../../../assets/images/icon-info-24px.svg";

const DialogModalTransferOptionBerbayar = (props) => {
  // setBerbayar, isBerbayar

  const {
    classes,
    userTokenState,
    nominalAfterDiscount,
    setNominalAfterDiscount,
    isBerbayar,
    setBerbayar,
    paramsData,
    setParamsData,
  } = props;

  const [selectedRadioButton, setSelectedRadioButton] = useState(undefined);

  console.log("paramsData from CompletionMembershipOptions : ", paramsData);

  /*
        ```````````````````
        HANDLE RADIO BUTTON

        ```````````````````
    */

  const handleChangeRadioButton = (e) => {
    setSelectedRadioButton(e.target.value);

    console.log("paramsData inside RADIO BUTTON : ", e.target.value);

    // if(paramsData !== undefined){
    //     paramsData.bank_id =  e.target.value;
    // };

    setParamsData({ ...paramsData, bank_id: e.target.value });
  };

  /*
        ````````````````````````````
        HANDLE UPGRADE SAVE INVOICE

        * In here you fire API to get response invoice, expired date, etc !

        ````````````````````````````
    */

  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [expiredDatePayment, setExpiredDatePayment] = useState("");

  const [isModalUpgradeSaveInvoice, setModalUpgradeSaveInvoice] =
    useState(false);

  const [loaderBayar, setLoaderbayar] = useState(false);

  const handleModalSaveInvoice = () => {
    setLoaderbayar(true);
    console.log("Params State NEW : ", paramsData);

    let data = {
      GroupMembership: paramsData,
    };

    console.log("Data : ", data);

    if (userTokenState !== undefined) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .put(URL_API + `/group/membership/premium`, data)
        .then(function (response) {
          setLoaderbayar(false);
          console.log("Response Original UPGRADE : ", response);

          if (response.status == 200) {
            setBerbayar(false);
            setModalUpgradeSaveInvoice(true);

            setInvoiceNumber(response.data.data.invoice_number);
            setExpiredDatePayment(response.data.data.expiry_datetime);
            setNominalAfterDiscount(
              response.data.data.total_price_after_unique
            );
          }
        })
        .catch(function (error) {
          setLoaderbayar(false);
          console.log("Error : ", error.response);

          if (error.response.status == 400) {
            setResponse400(true);
          }

          if (error.response.status == 422) {
            setResponse422(true);
          }
        });
    } else {
      console.log("Tidak dapat user Token, why ?");
    }
  };

  /*

        ````````````````````````````        
        RESPONSE 400

        ````````````````````````````
    */
  const [isResponse400, setResponse400] = useState(false);
  const handleResponse400 = () => {
    setResponse400(false);
    setModalUpgradeSaveInvoice(false);
  };

  /*

        ````````````````````````````        
        RESPONSE 422

        ````````````````````````````
    */
  const [isResponse422, setResponse422] = useState(false);
  const handleResponse422 = () => {
    setResponse422(false);
    setModalUpgradeSaveInvoice(false);
    setBerbayar(false);

    Redirect(ToMembershipPaymentStatusAfterCountDown);
  };

  return (
    <div>
      <Dialog
        open={isBerbayar}
        onClose={() => setBerbayar(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ margin: 14 }}
        // maxWidth='sm'
        // fullWidth={true}
        // fullScreen={true}
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          <Typography variant="h6" className={classes.titleModal}>
            Metode Pembayaran
          </Typography>
          <hr style={{ borderWidth: 1, borderColor: "#f9f0f0" }} />
        </DialogTitle>

        <DialogContent style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle2"
            className={classes.titleModal}
            style={{ color: "black" }}>
            <b>Total Tagihan </b>
          </Typography>

          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "left" }}>
            <br />
            <Typography variant="caption" className={classes.titleModal}>
              <b>Pilih Bank : </b>
            </Typography>

            <List>
              {paymentMethods.length > 0 &&
                paymentMethods.map((item, i) => (
                  <ListItem
                    style={{ paddingLeft: 0, paddingRight: 0, marginRight: 0 }}
                    key={i}>
                    <ListItemIcon>
                      <img
                        src={item.image}
                        alt={item.value}
                        className={classes.imageBank}
                      />
                    </ListItemIcon>

                    <Hidden only="xs">
                      <ListItemText
                        id={item.label}
                        primary={item.label}
                        style={{ fontFamily: "Roboto" }}
                      />
                    </Hidden>

                    <ListItemSecondaryAction
                      className={classes.listItemSecondaryActionDialogModal}>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Radio
                        // checked={selectedRadioButton === item.value}
                        checked={selectedRadioButton === item.id}
                        onChange={handleChangeRadioButton}
                        value={item.id}
                        name={item.label}
                        aria-label={item.label}
                        className={classes.listItemSecondaryActionDialogModal}
                        disabled={item.value == "bca" ? false : true}
                        // labelPlacement="left"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          </DialogContentText>
        </DialogContent>

        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={handleModalSaveInvoice}
            // color="primary"
            size="medium"
            className={classes.button}
            fullWidth
            style={{ width: 281 }}>
            Bayar &nbsp;
            {loaderBayar == true ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) : null}
          </Button>
        </DialogActions>
      </Dialog>

      {/* 

                ```````````````````````````````````````
                DIALOG SAVE INVOICE & EXPIRED DATE INFO

                ```````````````````````````````````````
                
            */}

      <DialogModalOptionBerbayar
        classes={classes}
        isModalUpgradeSaveInvoice={isModalUpgradeSaveInvoice}
        setModalUpgradeSaveInvoice={setModalUpgradeSaveInvoice}
        invoiceNumber={invoiceNumber}
        expiredDatePayment={expiredDatePayment}
        userTokenState={userTokenState}
        nominalAfterDiscount={nominalAfterDiscount}
      />

      {/* 

                ``````````````````````````
                DIALOG MODAL RESPONSE 400

                ``````````````````````````
                
            */}

      <Dialog
        open={isResponse400}
        onClose={handleResponse400}
        aria-labelledby="alert-dialog-title-response400"
        aria-describedby="alert-dialog-description-response400">
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <img src={PictInfo} className={classes.media} alt="info-icon" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-response400">
            <Typography variant="h6" className={classes.titleModal}>
              Oops, something went wrong !
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={handleResponse400}
            color="primary"
            size="small"
            className={classes.button}>
            Silahkan coba beberapa saat lagi
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>

      {/* 

                ``````````````````````````````````````````````````````````````
                DIALOG MODAL RESPONSE 422, user already has pending payment !

                ``````````````````````````````````````````````````````````````
                
            */}

      <Dialog
        open={isResponse422}
        onClose={handleResponse422}
        aria-labelledby="alert-dialog-title-response422"
        aria-describedby="alert-dialog-description-response422">
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <img src={PictInfo} className={classes.media} alt="info-icon" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-response422">
            <Typography variant="h6" className={classes.titleModal}>
              User already has pending payment !
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={handleResponse422}
            color="primary"
            size="medium"
            className={classes.button}>
            Ok
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>
    </div>
  );
};

export default DialogModalTransferOptionBerbayar;

let paymentMethods = [
  {
    value: "bca",
    label: "Bank BCA",
    image: bank_bca,
    id: "c7333fd9-1c18-4613-870c-c8c9f24b52d3",
  },
  {
    value: "bni",
    label: "Bank BNI",
    image: bank_bni,
    id: "91d108e2-a5ac-4c12-b63c-b38f37007c26",
  },
  {
    value: "bri",
    label: "Bank BRI",
    image: bank_bri,
    id: "1c681c4d-4f7a-40d1-9d70-df4f30c4bdf2",
  },
  {
    value: "mandiri",
    label: "Bank Mandiri",
    image: bank_mandiri,
    id: "0a1e6250-df03-4082-8d2e-d147758406dd",
  },
];

import React, { useState } from "react";
import { Grid, Tabs, Tab, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import FormChangePassword from "./Components/FormChangePassword";

/* ====================================
    Custom Tabs
   ==================================== */

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxHeight: 30,
      width: "100%",
      backgroundColor: "#333",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    textAlign: "left",
    color: "#333",
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 13,
    marginRight: 8,
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const ViewPersonalSetting = ({ theme, classes }) => {
  const [personalTab, setPersonalTab] = useState(0);

  const handleChangeAccountTab = (e, newValue) => {
    setPersonalTab(newValue);
  };

  return (
    <Grid container>
      <Grid item md={2}>
        <Box
          style={{
            flexGrow: 1,
            marginTop: theme.spacing(6),
          }}>
          <StyledTabs
            value={personalTab}
            orientation="vertical"
            onChange={handleChangeAccountTab}
            aria-label="styled tabs example">
            <StyledTab label="Kata Sandi" />
          </StyledTabs>
        </Box>
      </Grid>
      <Grid item md={10}>
        <div style={{ backgroundColor: "#fff", height: "100vh" }}>
          {personalTab === 0 && <FormChangePassword classes={classes} />}
        </div>
      </Grid>
    </Grid>
  );
};

export default ViewPersonalSetting;

import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import axios from "axios";

import ContextNewSO from "../Context/ContextNewSO";

import DialogError from "../../../components/DialogError";

import { styles } from "../Style/StyleDialogDetail";

import { URL_API } from "../../../constants/config-api";

// import { ToGoal } from '../../../../constants/config-redirect-url';
// import Redirect from '../../../../utilities/Redirect';

/*
    ````````````````````````````````````````````
    GLOBAL STATE : 

        context : {

            dataDetail : String,
            userTokenState: String,
            setOpenDialogTambahHead: Boolean,
            isOpenDialogDeleteNodeSO: Boolean,
            setOpenDialogDeleteNodeSO : Boolean
            
        };

    ````````````````````````````````````````````    

*/

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const DialogDeleteNodeSO = (props) => {
  const { classes } = props;
  const context = useContext(ContextNewSO);

  useEffect(() => {
    if (context.isOpenDialogDeleteNodeSO === true) {
      context.setSuccessDeleteNodeSO(false);

      console.log("context.dataDetail : ", context.dataDetail);
    }
  }, [context.isOpenDialogDeleteNodeSO]);

  /*
        ````````````````````
        HANDLE DIALOG ERROR

        ````````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [listError, setListError] = useState([]);
  const [errorStatus, setErrorStatus] = useState(null);

  const [loader, setLoader] = useState(false);
  const handleDelete = () => {
    setLoader(true);

    if (context.userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + context.userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .delete(
          URL_API +
            `/human-resource/structure-position/${context.dataDetail.id}`
        )
        .then(function (response) {
          setLoader(false);

          console.log("Response Original : ", response);

          context.setDataDetail({ id: context.dataDetail.parent_id });

          context.setSuccessDeleteNodeSO(true);
          context.setOpenDialogDeleteNodeSO(false);

          // context.setOpenDialogTambahHead(false);

          context.setSnackbarResponse200(true);
          context.setMessages(
            `Berhasil menghapus SO ${context.dataDetail.structure_position_title_name}`
          );
        })
        .catch(function (error) {
          setLoader(false);

          if (error.response !== undefined) {
            if (error.response.status == 422) {
              setErrorStatus(422);
              if (error.response.data.info.errors !== null) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                  context.setOpenDialogDeleteNodeSO(false);

                  // if(error.response.data.info.errors[0].code === "HAS_CHILD"){

                  //     setTextErrorInformation(error.response.data.info.errors[0].description)
                  //     setOpenDialogError(true);

                  //     context.setOpenDialogDeleteNodeSO(false);

                  // };

                  // if(error.response.data.info.errors[0].code === "HAS_GOAL"){

                  //     setTextErrorInformation(error.response.data.info.errors[0].description)
                  //     setOpenDialogError(true);

                  //     context.setOpenDialogDeleteNodeSO(false);

                  // };

                  // if(error.response.data.info.errors[0].code === "ROOT_POSITION"){
                  //     setTextErrorInformation(error.response.data.info.errors[0].description)
                  //     setOpenDialogError(true);

                  //     context.setOpenDialogDeleteNodeSO(false);
                  // }

                  // if(error.response.data.info.errors[0].code === "HAS_MEASURED_ACTIVITY"){
                  //     setTextErrorInformation(error.response.data.info.errors[0].description)
                  //     setOpenDialogError(true);

                  //     context.setOpenDialogDeleteNodeSO(false);
                  // }

                  // if(error.response.data.info.errors.length > 1){

                  //     if(error.response.data.info.errors[0].code === "HAS_GOAL" && error.response.data.info.errors[1].code === "HAS_MEASURED_ACTIVITY" ){

                  //         setTextErrorInformation(error.response.data.info.errors[0].description + " <br />" + error.response.data.info.errors[1].description )
                  //         setOpenDialogError(true);

                  //         context.setOpenDialogDeleteNodeSO(false);

                  //     };
                  // }
                }
              }
            }

            if (error.response.status == 404) {
              setErrorStatus(404);
              setTextErrorInformation(
                "Error 404 : Whoops, something went wrong !"
              );
              setOpenDialogError(true);
            }
          } else {
            setTextErrorInformation("Whoops, something went wrong !");
            setOpenDialogError(true);

            context.setOpenDialogDeleteNodeSO(false);
            context.setOpenDialogTambahHead(false);
          }

          console.log("Error : ", error.response);
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        open={context.isOpenDialogDeleteNodeSO}
        onClose={() => context.setOpenDialogDeleteNodeSO(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          {/* 
                        <Paper style={{backgroundColor: '#ffcbcb'}}>
                            <Grid container>
                                <Grid item sm={1}>
                                        <Box marginLeft={1} marginTop={1}>
                                            <InfoIcon style={{color: 'white'}} /> 
                                        </Box>
                                </Grid>
                                <Grid item sm={11}>        
                                        <Box marginTop={1}>
                                            <Typography variant='subtitle1' className={classes.title} style={{color: 'black'}}>
                                                <i style={{color: 'red'}}>
                                                    Sekali Anda menghapus <i>Goal</i>, maka data <i>Goal Result</i> dan <i>Measure Activity</i> akan hilang ! 
                                                </i>
                                                <br />
                                                <b style={{color: 'red'}}>
                                                    (Pastikan Anda benar- benar paham konsekuensi-nya.)
                                                </b>
                                            </Typography>
                                        </Box>
                                </Grid>
                            </Grid>
                        </Paper> 

                    */}
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "black" }}>
              <b>
                Apakah Anda yakin ingin menghapus <i>SO</i> ini ?
              </b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            onClick={handleDelete}
            variant="contained"
            className={classes.button}
            // fullWidth
          >
            {loader !== true ? (
              "Yakin"
            ) : (
              <CircularProgress size={20} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        listError={listError}
        errorStatus={errorStatus}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(DialogDeleteNodeSO);

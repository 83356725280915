import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import ContextAllMA from "../context/AllMaContext";
import { URL_API } from "../../../../constants/config-api";
import Snackbeer from "../../../../components/Snackbeer";

import DialogError from "../../../../components/DialogError";

const DialogSetAsComplete = (props) => {
  const {
    classes,
    isOpenDialogSetAsComplete,
    setOpenDialogSetAsComplete,
    maDetailState,
    setMeasuredActivityDetailShow,
  } = props;
  const context = useContext(ContextAllMA);
  const [userTokenState, setUserTokenState] = useState("");

  useEffect(() => {
    if (isOpenDialogSetAsComplete == true) {
      const userToken = localStorage.getItem("userToken");
      setUserTokenState(userToken);
    }
  }, [isOpenDialogSetAsComplete]);

  const [loader, setLoader] = useState(false);
  const handleSetAsComplete = () => {
    setLoader(true);
    if (userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .put(URL_API + `/swd/measured-activity/${maDetailState.id}:completed`)
        .then(function (response) {
          setLoader(false);
          console.log("Response Original : ", response);

          if (response.status === 200) {
            setModalResponse200(true);
            setMessage("Berhasil ubah status Goal menjadi complete");
            context.setSuccessSetAsComplete(
              Math.floor(Math.random() * 1000 + 1)
            );
            setOpenDialogSetAsComplete(false);
            setMeasuredActivityDetailShow(true);
          }
        })
        .catch(function (error) {
          setLoader(false);
          console.log("Error : ", error.response);
          setOpenDialogSetAsComplete(false);

          if (error.response !== undefined) {
            if (error.response.status === 403) {
              setErrorStatus(403);
              setTextErrorInformation(error.response.data.info.message);
              setOpenDialogError(true);
            }

            if (error.response.status === 422) {
              setErrorStatus(422);
              setTextErrorInformation(".");
              setListError(error.response.data.info.errors);
              setOpenDialogError(true);
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformation();
          }
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  /*
        ```````````````
        HANDLE SNACKBEER

        ```````````````
    */

  const [isModalResponse200, setModalResponse200] = useState(false);
  const [message, setMessage] = useState("");

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */

  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  return (
    <Fragment>
      <Dialog
        open={isOpenDialogSetAsComplete}
        onClose={() => setOpenDialogSetAsComplete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          style={{ textAlign: "left" }}></DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "black" }}>
              <b>
                Apakah Anda yakin ingin mengubah status{" "}
                <i>"{maDetailState.name}"</i> menjadi <i>Completed </i> ?
              </b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            onClick={handleSetAsComplete}
            variant="contained"
            className={classes.buttonModalDelete}
            // fullWidth
          >
            {loader !== true ? (
              "Yakin"
            ) : (
              <CircularProgress size={16} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>
      <Snackbeer
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages={message}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
};

export default DialogSetAsComplete;

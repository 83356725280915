import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab, Paper } from "@material-ui/core";
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";

import ViewUser from "../UserManagement/ViewUser";
import { styles } from "./Style/StyleCompanySetting";

import { withStyles } from "@material-ui/styles";
import ViewPersonalSetting from "./ViewPersonalSetting";
import ViewOrganizationSetting from "./ViewOrganizationSetting";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles(styles);

/* ====================================
    Custom Tabs
   ==================================== */

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 65,
      width: "100%",
      backgroundColor: "#d1354a",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#333",
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 15,
    marginRight: 8,
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const ViewCompanySetting = (props) => {
  // console.log("COntext : ", context)

  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("status_user_login"));
  const statusUser = user.membership_status_id;

  const [accountTab, setAcccountTab] = useState(0);

  useEffect(() => {
    const tabSetting = localStorage.getItem("setting_tab");
    const newTabVal = parseInt(tabSetting);

    // console.log("Sett Tab", typeof newTabVal);

    if (tabSetting === null || tabSetting === undefined) {
      setAcccountTab(0);
    } else {
      setAcccountTab(newTabVal);
    }
  }, []);

  /*
        ``````````
        HANDLE TAB 

        ``````````

    */

  const handleChangeAccountTab = (e, newValue) => {
    // console.log("Sett Tab", newValue);
    setAcccountTab(newValue);
    localStorage.setItem("setting_tab", newValue);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Box
        style={{
          flexGrow: 1,
          marginTop: theme.spacing(8.3),
          marginLeft: theme.spacing(7),
        }}>
        <Paper style={{ backgroundColor: "#fff" }} elevation={1}>
          <StyledTabs
            elevation={1}
            value={accountTab}
            onChange={handleChangeAccountTab}
            aria-label="styled tabs example">
            <StyledTab label="Personal" disabled={statusUser === "22"} />
            <StyledTab label="Organization" />
          </StyledTabs>
        </Paper>
      </Box>

      {accountTab === 0 ? (
        <Box marginLeft={theme.spacing(1.1)} marginTop={theme.spacing(0.1)}>
          <ViewPersonalSetting theme={theme} classes={classes} />
        </Box>
      ) : (
        <Box marginLeft={theme.spacing(1.1)} marginTop={theme.spacing(0.1)}>
          <ViewOrganizationSetting theme={theme} classes={classes} />
        </Box>
      )}
    </MuiThemeProvider>
  );
};

export default ViewCompanySetting;

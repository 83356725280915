import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";

import {
  FormatDecimal,
  FormatThousand,
} from "../../../../utilities/FormatDecimal";

import PictGAP from "../../../../assets/images/Group_2619.png";
import { VALUE_TYPE_PERCENTAGE } from "../../../../constants/config-value-type";

function TextOverDotLineChartThird({ classes, maDetailState }) {
  return (
    <List>
      <ListItem>
        <ListItemIcon
          style={{
            marginLeft: 8,
          }}>
          <img src={PictGAP} style={{ width: 40, height: 40 }} alt="." />
        </ListItemIcon>
        <ListItemText
          className={classes.listItemtext}
          primary={
            <Typography variant="subtitle2" className={classes.title}>
              {maDetailState.valueType.code === VALUE_TYPE_PERCENTAGE ? (
                <b>
                  {FormatDecimal(
                    maDetailState.target_value -
                      maDetailState.calculatedValue.resultOriented.result_value
                  )}{" "}
                  %
                </b>
              ) : (
                <b>
                  {FormatDecimal(
                    maDetailState.target_value -
                      maDetailState.calculatedValue.resultOriented.result_value
                  )}
                </b>
              )}
            </Typography>
          }
          secondary={
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "grey" }}>
              Gap to Target Value
            </Typography>
          }
        />
      </ListItem>
    </List>
  );
}

export default TextOverDotLineChartThird;

import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TableRow,
  TableCell,
  ListItemIcon,
  Slide,
  Badge,
  // Link
} from "@material-ui/core";

import moment from "moment";

import DoneIcon from "@material-ui/icons/Done";
import RoomIcon from "@material-ui/icons/Room";
import PersonIcon from "@material-ui/icons/Person";
import ReorderRoundedIcon from "@material-ui/icons/ReorderRounded";

import LightTooltip from "../../../../components/LightTooltip";

import CircleInPercentMATemporary from "../Components/CircleInPercentMATemporary";

import { styles } from "../../Goal/Style/StyleGoal";
import TruncateTextShort from "../../../../utilities/TruncateTextShort";
import ImageSmallUser from "../../../../components/ImageSmallUser";

const numeral = require("numeral");

const useStyle = makeStyles(styles);

const ListCardItemMeasureActivity = (props) => {
  const { item, handleDetail } = props;

  const classes = useStyle();

  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <TableRow key={item.id}>
        <List dense className={classes.listCardItem}>
          <Paper
            elevation={1}
            className={
              item.calculatedValue.resultOriented.overall_result_achievement *
                100 <
              85
                ? classes.paperListKebakaran
                : classes.paperList
            }
            style={{ marginBottom: 16 }}>
            <TableCell
              component="th"
              scope="row"
              size="small"
              padding="none"
              classes={{
                root: classes.noBorderBottom,
              }}>
              <Box width={"16%"} marginLeft={2}>
                <div className={classes.circularBeside}>
                  <CircleInPercentMATemporary
                    classes={classes}
                    item={item}
                    index={item}
                  />
                </div>
              </Box>
            </TableCell>

            <TableCell
              classes={{
                root: classes.noBorderBottom,
              }}
              component="th"
              scope="row"
              size="small"
              padding="none">
              <Box marginLeft={-4} width={240}>
                <List>
                  <ListItem style={{ paddingLeft: 0, marginRight: 16 }}>
                    <ListItemIcon>
                      <IconButton
                        size="small"
                        style={{
                          backgroundColor: "transparent",
                          padding: "1px",
                        }}>
                        <PersonIcon style={{ color: "transparent" }} />
                      </IconButton>
                    </ListItemIcon>
                    <ListItemText
                      className={classes.listItemtext}
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}>
                          <b>{item.name}</b>
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}>
                          {
                            // *PERIOD
                            item.period !== null &&
                              item.period !== undefined &&
                              item.period.name
                          }

                          {
                            // *DATE RANGE
                            item.start_date !== null &&
                              item.end_date !== null &&
                              item.period == null && (
                                <LightTooltip
                                  title={
                                    moment(item.start_date).format(
                                      "DD MMMM YYYY"
                                    ) +
                                    " - " +
                                    moment(item.end_date).format("DD MMMM YYYY")
                                  }
                                  placement="right-start">
                                  <span>
                                    {TruncateTextShort(
                                      moment(item.start_date).format(
                                        "DD MMMM YYYY"
                                      ) +
                                        " - " +
                                        moment(item.end_date).format(
                                          "DD MMMM YYYY"
                                        )
                                    )}
                                  </span>
                                </LightTooltip>
                              )
                          }

                          {
                            // *DUE DATE
                            item.start_date == null &&
                              item.end_date !== null &&
                              item.period == null &&
                              moment(item.end_date).format("DD MMMM YYYY")
                          }
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            </TableCell>

            <TableCell
              classes={{
                root: classes.noBorderBottom,
              }}
              component="th"
              scope="row"
              size="small"
              padding="none">
              <Box width={200}>
                <List>
                  <ListItem
                    style={{
                      marginRight: 24,
                      paddingLeft: 0,
                      // padding: 0
                    }}>
                    {/* {
                                         item.member.self.rel.photo_url !== null ? 

                                            <Avatar                                                     
                                                alt="U" 
                                                src={URL_API + '/' + item.member.self.rel.photo_url + "&token=" + userTokenState } 
                                            />
                                        
                                         : */}
                    <ListItemIcon
                      style={{
                        marginBottom: -8,
                        marginLeft: 8,
                      }}>
                      <IconButton
                        style={{ backgroundColor: "#dbdbdb", padding: "1px" }}>
                        {item.member !== null &&
                        item.member.photo_url === null &&
                        item.member.self.rel.photo_url === null ? (
                          <PersonIcon color="#c4c5c4" />
                        ) : (
                          <ImageSmallUser
                            item={item.member.self.rel.photo_url}
                            index={1}
                          />
                        )}
                      </IconButton>
                    </ListItemIcon>
                    {/* } */}

                    <ListItemText
                      className={classes.listItemtext}
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}>
                          <b>
                            {item.member !== null
                              ? item.member.first_name + " "
                              : item.owner.member_first_name}
                            {item.member !== null
                              ? item.member.last_name
                              : item.owner.member_last_name}
                          </b>
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}>
                          {item.owner
                            ? item.owner.structure_position_title_name
                            : "-"}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            </TableCell>

            <TableCell
              classes={{
                root: classes.noBorderBottom,
              }}
              component="th"
              scope="row"
              size="small"
              padding="none">
              <List>
                <ListItem
                  style={{
                    marginRight: 16,
                    paddingLeft: 0,
                  }}>
                  <ListItemIcon style={{ marginLeft: 8 }}>
                    <IconButton
                      style={{ backgroundColor: "#bdffde", padding: "8px" }}>
                      <DoneIcon style={{ color: "#36d686", fontSize: 21 }} />
                    </IconButton>
                  </ListItemIcon>

                  <ListItemText
                    className={classes.listItemtext}
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Expected Value</b>
                      </Typography>
                    }
                    secondary={
                      <Typography variant="subtitle2" className={classes.title}>
                        {numeral(
                          item.calculatedValue.resultOriented
                            .expected_result_value_achievement * 100
                        ).format("0.00")}
                        %
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </TableCell>

            <TableCell
              component="th"
              scope="row"
              size="small"
              padding="none"
              classes={{
                root: classes.noBorderBottom,
              }}>
              <List>
                <ListItem
                  style={{
                    marginRight: 24,
                    paddingLeft: 0,
                    // marginTop: 16
                  }}>
                  <ListItemIcon style={{ marginLeft: 8 }}>
                    <IconButton
                      style={{ backgroundColor: "#f2f2f2", padding: "8px" }}>
                      <RoomIcon style={{ color: "#b7b7b7", fontSize: 21 }} />
                    </IconButton>
                  </ListItemIcon>

                  <ListItemText
                    className={classes.listItemtext}
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Result Value</b>
                      </Typography>
                    }
                    secondary={
                      <Typography variant="subtitle2" className={classes.title}>
                        {numeral(
                          item.calculatedValue.resultOriented
                            .result_value_achievement * 100
                        ).format("0.00")}
                        %
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </TableCell>

            {/* Weight MA */}
            <TableCell
              component="th"
              scope="row"
              size="small"
              padding="none"
              classes={{
                root: classes.noBorderBottom,
              }}>
              <List>
                <ListItem
                  style={{
                    marginRight: 24,
                    paddingLeft: 0,
                    // marginTop: 16
                  }}>
                  <ListItemIcon style={{ marginLeft: 8 }}>
                    <IconButton
                      style={{ backgroundColor: "#D9D1E3", padding: "8px" }}>
                      <ReorderRoundedIcon
                        style={{ color: "#A96BA0", fontSize: 21 }}
                      />
                    </IconButton>
                  </ListItemIcon>

                  <ListItemText
                    className={classes.listItemtext}
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Weight</b>
                      </Typography>
                    }
                    secondary={
                      <Typography variant="subtitle2" className={classes.title}>
                        {item.weight !== null
                          ? `${numeral(item.weight).format("0.00")}%`
                          : "Not set"}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </TableCell>

            <TableCell
              classes={{
                root: classes.noBorderBottom,
              }}
              component="th"
              scope="row"
              size="small"
              padding="none">
              <Box marginLeft={-8}>
                <List>
                  <ListItem
                    alignItems="center"
                    style={{ paddingLeft: 0, marginRight: 24 }}>
                    <ListItemIcon>
                      <IconButton
                        size="small"
                        style={{
                          backgroundColor: "transparent",
                          padding: "1px",
                        }}>
                        <PersonIcon style={{ color: "transparent" }} />
                      </IconButton>
                    </ListItemIcon>

                    <ListItemText
                      // noWrap
                      className={classes.listItemtext}
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}>
                          Terakhir update &nbsp;
                          <Button
                            onClick={(e) => handleDetail(e, item)}
                            variant="outlined"
                            size="small"
                            className={classes.moreDetail}>
                            <b>More Detail</b>
                          </Button>
                        </Typography>
                      }
                      secondary={
                        item.last_measured_activity_result_date !== null ? (
                          <LightTooltip
                            title={`Terakhir update ${moment(
                              item.last_measured_activity_result_date
                            ).format("DD MMMM YYYY h:mm:ss a")}`}
                            placement="left-start">
                            <Typography
                              variant="subtitle2"
                              className={classes.title}>
                              {/* {TruncateText(`${moment(item.last_measured_activity_result_date).format('DD MMMM YYYY h:mm:ss a')}`)} */}
                              {moment(
                                item.last_measured_activity_result_date
                              ).format("DD MMMM YYYY h:mm:ss a")}
                            </Typography>
                          </LightTooltip>
                        ) : (
                          <Typography
                            variant="subtitle2"
                            className={classes.title}>
                            -
                          </Typography>
                        )
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            </TableCell>
          </Paper>
        </List>
      </TableRow>
    </Slide>
  );
};

export default ListCardItemMeasureActivity;

const StyledBadge1 = withStyles((theme) => ({
  badge: {
    right: -4,
    top: -4,
    border: `2px solid #edcfd8`,
    padding: "0 4px",
    //   border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Badge);

const data = {
  info: {
    status: 200,
    message: "Sukses",
    developer_message: "",
    errors: [],
  },
  data: {
    updated_at: "2020-03-27 09:41:13",
    fields: {
      name: {
        label: "Judul MA",
        placeholder: "",
        value: "Sebar Brosur 2",
        help: "",
        visibility: "visible",
      },
      goal_id: {
        label: "Goal",
        placeholder: "",
        value: "f83e49af-1ae6-4575-a2cb-e09fd538a4b9",
        help: "",
        visibility: "visible",
      },
      achievement_type_id: {
        label: "Jenis Pencapaian",
        placeholder: "",
        value: "1",
        help: "",
        visibility: "visible",
      },
      value_type_id: {
        label: "Jenis Nilai",
        placeholder: "",
        value: "1",
        help: "",
        visibility: "visible",
      },
      input_method_id: {
        label: "Jenis Input",
        placeholder: "",
        value: "2",
        help: "",
        visibility: "visible",
      },
      calculation_method_id: {
        label: "Jenis Perhitungan",
        placeholder: "",
        value: "1",
        help: "",
        visibility: "visible",
      },
      period_id: {
        label: "Periode",
        placeholder: "",
        value: null,
        help: "",
        visibility: "visible",
      },
      start_date: {
        label: "Tanggal Mulai",
        placeholder: "",
        value: "2021-01-01",
        help: "",
        visibility: "visible",
      },
      end_date: {
        label: "Tanggal Selesai",
        placeholder: "",
        value: "2021-03-31",
        help: "",
        visibility: "visible",
      },
      start_value: {
        label: "Pencapaian Awal",
        placeholder: "",
        value: "0.0000",
        help: "",
        visibility: "visible",
      },
      target_value: {
        label: "Target Pencapaian",
        placeholder: "",
        value: "11.0000",
        help: "",
        visibility: "visible",
      },
      target_range_value: {
        label: "Range Target Pencapaian",
        placeholder: "",
        value: null,
        help: "",
        visibility: "visible",
      },
      description: {
        label: "Deskripsi",
        placeholder: "",
        value: null,
        help: "",
        visibility: "visible",
      },
      time_frame_id: {
        label: "Time Frame",
        placeholder: "",
        value: "2",
        help: "",
        visibility: "visible",
      },
    },
    is_used: false,
    minimumEndDate: "2020-01-01",
    maximumEndDate: "2020-03-31",
    minimumStartDate: "2020-01-01",
    maximumStartDate: "2020-03-31",
    periodCollections: [],
    inputMethodCollections: [
      {
        id: "1",
        code: "swd_ma_input_method_last_value",
        name: "last value",
      },
      {
        id: "2",
        code: "swd_ma_input_method_increment",
        name: "increment",
      },
    ],
    calculationMethodCollections: [
      {
        id: "1",
        code: "swd_ma_calculation_method_sum",
        name: "sum",
      },
      {
        id: "2",
        code: "swd_ma_calculation_method_average",
        name: "average",
      },
      {
        id: "3",
        code: "swd_ma_calculation_method_last_value",
        name: "last value",
      },
    ],
    valueTypeCollections: [
      {
        id: "1",
        code: "numeric",
        name: "numeric",
      },
      {
        id: "2",
        code: "percentage",
        name: "percentage",
      },
    ],
    achievementTypeCollections: [
      {
        id: "1",
        code: "swd_ma_achievement_type_more_oriented",
        name: "more oriented",
      },
      {
        id: "2",
        code: "swd_ma_achievement_type_less_oriented",
        name: "less oriented",
      },
    ],
    timeframeCollections: [
      {
        id: "1",
        code: "period",
        name: "period",
      },
      {
        id: "2",
        code: "date_range",
        name: "date range",
      },
      {
        id: "3",
        code: "due_date",
        name: "due date",
      },
    ],
  },
};

import {
  GET_DATA_FIELD,
  GET_FIELD_PRESETS,
  HIT_DATA_PRESET,
  HIT_ENTITIES,
  HIT_USER_SELECT,
  HIT_UNIT_SELECT,
  HIT_STATUS,
  HIT_VALUE_TYPE,
  HIT_CAL_METHOD,
  HIT_ORDER_BY,
  HIT_TOTAL_RECORDS,
  SET_PAGINATION,
  GET_FIELD_COMPARE,
} from "../types";

// ~ Initial State for Table ~
export const initialTable = {
  comparison: {
    name: "",
    code: "",
    date_option: {
      finish_date: "",
      start_date: "",
      days: 0,
    },
  },
  fieldCompare: [],
  fieldDiff: [],
  fieldPresets: {
    id: 0,
    name: "",
    selected_fields: [],
  },
  entity: [],
  dataPresets: [],
  usersSelected: [],
  unitsSelected: [],
  presetDetail: {},
  status: [3],
  valueType: {
    code: "ora",
    fields: ["overall_result_achievement"],
  },
  calMethod: [
    {
      formula: "_value_ >= 100",
    },
    {
      formula: "_value_ >= 85 && _value_ <= 99",
    },
    {
      formula: "_value_ < 85",
    },
  ],
  selectedOrder: [],
  pagination: {
    currentPage: 1,
    perPage: 10,
    lastPage: 0,
  },
  loadTable: false,
  totalRecords: 0,
  syncTime: "",
};

// =======================================================================
// ~ REDUCER | Managing GLobal State for Table ~
export const tableReducer = (state, { type, payload }) => {
  switch (type) {
    case GET_DATA_FIELD:
      return {
        ...state,
        comparison: payload.comparison,
      };
    case GET_FIELD_COMPARE:
      return {
        ...state,
        fieldCompare: payload.fieldCompare,
        fieldDiff: payload.fieldDiff,
      };
    case GET_FIELD_PRESETS:
      return { ...state, fieldPresets: { ...payload.fieldPresets } };
    case HIT_DATA_PRESET:
      return {
        ...state,
        dataPresets: [...payload.dataPresets],
        loadTable: payload.load,
      };
    case HIT_ENTITIES:
      return { ...state, entity: payload.entity };
    case HIT_USER_SELECT:
      return { ...state, usersSelected: payload.selected };
    case HIT_UNIT_SELECT:
      return { ...state, unitsSelected: payload.selected };
    case HIT_STATUS:
      return { ...state, status: payload.selected };
    case HIT_VALUE_TYPE:
      return { ...state, valueType: payload.valueType };
    case HIT_CAL_METHOD:
      return { ...state, calMethod: payload.selected };
    case SET_PAGINATION:
      return { ...state, pagination: payload.pagination };
    case HIT_ORDER_BY:
      return { ...state, selectedOrder: payload.allOrderBy };
    case HIT_TOTAL_RECORDS:
      return { ...state, totalRecords: payload.totalRecords };
    default:
      return state;
  }
};

import React, { useState } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
  fade,
} from "@material-ui/core/styles";

import {
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@material-ui/core";

import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import ImageMembership from "../assets/images/SVG/Group_870.svg";
import ImagePengaturanUser from "../assets/images/SVG/Group_866.svg";
import {
  cyan,
  lightBlue,
  lightGreen,
  grey,
  red,
  green,
} from "@material-ui/core/colors";

import Redirect from "../utilities/Redirect";
import {
  ToEmptyStateGeneral,
  ToSOTable,
} from "../constants/config-redirect-url";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  overrides: {
    MuiToolbar: {
      root: {
        backgroundColor: "white",
      },
    },

    MuiButton: {
      text: {
        color: "white",
      },
      textSecondary: {
        color: "white",
        fontFamily: "Roboto",
      },
    },
    MuiDrawer: {
      root: {
        backgroundColor: lightBlue,
      },
      paper: {
        backgroundColor: green,
      },

      paperAnchorLeft: {},
    },
    MuiListItemIcon: {
      root: {
        color: "white",
      },
    },
    MuiBadge: {
      root: {
        color: "#d1354a",
      },
    },
  },
});

const styles = (theme) => ({
  list: {
    width: 250,
  },

  fullList: {
    width: "auto",
  },

  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },

  search: {
    position: "relative",
    borderRadius: 3,
    backgroundColor: grey,
    marginRight: 1 * 2,
    marginLeft: 0,
    width: "100%",
  },
  searchIcon: {
    color: "grey",
  },
  sectionDesktop: {
    display: "flex",
  },
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  accountCircle: {
    color: "white",
  },
  menuIcon: {
    color: "grey",
  },
  mail: {
    color: "white",
  },

  notifIcon: {
    color: "white",
  },
  list: {
    backgroundColor: "red",
  },
  listDrawer: {
    backgroundColor: "red",
  },

  /* DRAWER */
  drawerRoot: {
    width: "100%",
    maxWidth: 360,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "transperent",
    color: "#707070",
  },
  nested: {
    // paddingLeft: theme.spacing.unit * 4,
    paddingLeft: 7,
  },

  icon: {
    // margin: theme.spacing.unit * 2,
    margin: 1 * 2,
  },
  iconHover: {
    // margin: theme.spacing.unit * 2,
    margin: 1 * 2,
    "&:hover": {
      color: red[800],
    },
  },

  imageSvg: {
    width: 27,
    height: 27,
    marginLeft: 8,
  },
  iconMenuOffCanvass: {
    width: 27,
    height: 27,
  },
  buttonIconMenuOffCanvass: {
    padding: 0,
    marginLeft: 127,
    marginBottom: 12,
  },
  iconSmall: {
    fontSize: 20,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  buttonBackDashboard: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: 5,
    fontFamily: "Roboto",
    textTransform: "capitalize",
    color: "grey",
  },
  iconArrowLeft: {
    marginRight: theme.spacing(1),
  },
  buttonMenuBesiArrowLeft: {
    width: "27px",
    height: "27px",
    borderRadius: 2,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    padding: 0,
  },
  nav: {
    color: "#707070",
    fontFamily: "Roboto",
  },
  navActive: {
    color: "#c1272d",
    fontFamily: "Roboto",
  },
  perbaruiSampul: {
    fontFamily: "Roboto",
    color: "white",
    textTransform: "capitalize",
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(22),
  },
  nested: {
    // paddingLeft: theme.spacing.unit * 4,
    paddingLeft: 7,
  },
  iconMenuOffCanvass: {
    width: 30,
    height: 30,
    cursor: "pointer",
    marginTop: 24,
  },
  title: {
    fontFamily: "Roboto",
  },
  titleRed: {
    fontFamily: "Roboto",
    color: "#cc0707",
  },
  titleHead: {
    fontFamily: "Roboto",
    color: "black",
  },

  /*
        ```````
        SEARCH

        ```````
    */
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
});

const HeaderSO = (props) => {
  // const classes = useStyles();
  const { classes } = props;

  const [state, setState] = useState({
    top: false,
    // left: true,
    left: false,
    bottom: false,
    right: false,
  });

  /*
        
        ``````````````````
        USE SCROLL TOP v2

        ``````````````````

   */
  // optionally you can pass options, those are default:
  // let optionz = {

  //     throttle: 10,
  // };

  // let position = useWindowScrollPosition();
  // console.log("position : ", position);

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar
        position="sticky"
        // style={{
        //     backgroundImage: 'url(' + PictBackgroundInHeader + ')',
        //     backgroundSize: 'cover',
        //     backgroundPosition: 'center center',
        //     backgroundRepeat: 'no-repeat',
        //     transition : 'all 500ms',
        //     height: isTopOfPage ? 300 : 60,

        // }}
      >
        <Toolbar>
          <IconButton
            onClick={() => Redirect(ToSOTable)}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu">
            <KeyboardBackspaceIcon style={{ fontSize: 27 }} />
            <span className={classes.titleRed} style={{ fontSize: 17 }}>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <b>Daftar Posisi</b>
            </span>
          </IconButton>

          {/* <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'Search' }}
                        />
                    </div> */}

          <Grid
            container
            // spacing={8}
            alignItems="flex-end">
            <Grid item sm={10} style={{ textAlign: "center" }}>
              <Typography variant="h6" className={classes.titleHead}>
                <b>Master Struktur Organisasi</b>
              </Typography>
            </Grid>

            <Grid item sm={2} style={{ textAlign: "center" }}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {/* 
                `````````````
                TOOLBAR BACK

                `````````````
            
            */}

      {/* 
                ``````
                DRAWER

                ``````
            */}
    </MuiThemeProvider>
  );
};

// export default HeadCompany;
export default withStyles(styles)(HeaderSO);

let navs = [
  // {
  //     label: 'Profil Perusahaan',
  //     image: ImageProfilPerusahaan,
  //     type: 'ProfilPerusahaan'
  // },
  {
    label: "Keanggotaan",
    image: ImageMembership,
    type: "Keanggotaan",
  },
  {
    label: "Pengaturan User",
    image: ImagePengaturanUser,
    type: "PengaturanUser",
  },
  // {
  //     label: 'Pengaturan Role',
  //     image: ImagePengaturanRole,
  //     type: 'PengaturanRole'
  // },
];

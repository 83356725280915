import React, { useEffect, useState } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { Paper, Typography, Grid, Button } from "@material-ui/core";

import Redirect from "../../../utilities/Redirect";
import { ToHrEmployeeKepegawaianInfoPegawai } from "../../../constants/config-redirect-url";
// import Snackber from '../Components/Snackber';
import DialogDeleteAnggotaTambahan from "./Components/DialogDeleteAnggotaTambahan";
import DialogTambahAnggotaKeluarga from "./Components/DialogTambahAnggotaKeluarga";
import DialogEditAnggotaKeluarga from "./Components/DialogEditAnggotaKeluarga";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const styles = (theme) => ({
  root: {
    padding: theme.spacing(5, 2),
    marginTop: theme.spacing(4),
    width: 575,
    borderRadius: 7,
  },
  button: {
    borderRadius: 5,
    backgroundColor: "#d1354a",
    border: 0,
    fontFamily: "Roboto",
    // marginBottom: theme.spacing(4),
    color: "white",
    textTransform: "capitalize",
    "&:disabled": {
      backgroundColor: "#f28181",
    },
  },
  buttonModal: {
    borderRadius: 5,
    backgroundColor: "#d1354a",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    marginRight: theme.spacing(4),
    color: "white",
    "&:hover": {
      backgroundColor: "#f28181",
    },
  },
  buttonModalDelete: {
    borderRadius: 5,
    backgroundColor: "#d1354a",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    marginRight: theme.spacing(4),
    color: "white",
    "&:hover": {
      backgroundColor: "#f28181",
    },
  },
  buttonOutlined: {
    borderRadius: 5,
    border: 0,
    fontFamily: "Roboto",
    color: "grey",
    // color: '#d2d2d2',
    textTransform: "capitalize",
    "&:hover": {
      color: "grey",
      background: "#d2d2d2",
      borderWidth: 0,
    },
  },
  paperKeluarga: {
    width: "95%",
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
  },
  titleHeader: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
    // marginTop:  theme.spacing(2),
  },
  titleTambahAnggotaKeluarga: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: "#cc0707",
    cursor: "pointer",
  },
  title: {
    fontFamily: "Roboto",
  },
  titleForm: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    // marginTop: theme.spacing(2),
  },
  textField: {
    minWidth: 425,
    marginLeft: theme.spacing(6),
    marginBottom: theme.spacing(1),
  },
  titleTanggalLahir: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
});

const ViewIdentitasInfoTambahan = (props) => {
  const { classes } = props;

  /*
        ````````````````````````````````````
        COMPONENT DID MOUNT

        ````````````````````````````````````
    */

  const ID = function () {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  const [listDataKeluarga, setListDataKeluarga] = useState("");

  let dataList = [];
  const employeeDataKeluarga = localStorage.getItem("employee_data_keluarga");
  let employeeDataKeluargaAfterParse =
    employeeDataKeluarga !== null ? JSON.parse(employeeDataKeluarga) : [];

  useEffect(() => {
    console.log(
      "employeeDataKeluargaAfterParse : ",
      employeeDataKeluargaAfterParse
    );
    setListDataKeluarga(employeeDataKeluargaAfterParse);
  }, []);

  /*
        ````````````````````````````````````
        HANDLE MODAL TAMBAH, DELETE, 
        
        ````````````````````````````````````
    */
  const [modalTambahAnggotaKeluarga, setModalTambahAnggotaKeluarga] =
    useState(false);

  const [dataDetailDelete, setDataDetailDelete] = useState("");
  const [modalDeleteAnggotaKeluarga, setModalDeleteAnggotaKeluarga] =
    useState(false);

  const handleTambahAnggotaKeluarga = () => {
    setNameAnggotaKeluarga("");
    setTanggalLahir("");

    setModalTambahAnggotaKeluarga(true);
  };

  const handleDeleteDataDetail = (e, data) => {
    e.preventDefault();

    setModalDeleteAnggotaKeluarga(true);
    setDataDetailDelete(data);
  };

  /*
        ````````````````````````````````````
        HANDLE MODAL UPDATE
        
        ````````````````````````````````````
    */

  const [modalEditAnggotaKeluarga, setModalEditAnggotaKeluarga] =
    useState(false);
  const [dataDetailEdit, setDataDetailEdit] = useState("");

  const handleEditDataDetail = (e, data) => {
    e.preventDefault();

    setModalEditAnggotaKeluarga(true);

    console.log("Data edit detail  : ", data);

    setDataDetailEdit(data);
  };

  const [isUpdatedWork, setUpdatedWork] = useState(false);
  const handleSimpanPerubahan = () => {
    setUpdatedWork(true);

    let data = {
      id: dataDetailEdit.id,
      name: nameAnggotaKeluarga,
      family_relationship_type_id: hubunganKeluarga.name,
      date_of_birth: tanggalLahir,
    };

    console.log("Perubahan data : ", data);

    setListDataKeluarga(
      listDataKeluarga.map((item) => (item.id === data.id ? data : item))
    );
    setModalEditAnggotaKeluarga(false);
  };

  useEffect(() => {
    if (isUpdatedWork == true) {
      localStorage.setItem(
        "employee_data_keluarga",
        JSON.stringify(listDataKeluarga)
      );
    }
  }, [isUpdatedWork]);

  /*
        ```````````
        HANDLE FORM 

        ```````````
    */
  const [nameAnggotaKeluarga, setNameAnggotaKeluarga] = useState("");

  const handleChangeNamaAnggotaKeluarga = (e) =>
    setNameAnggotaKeluarga(e.target.value);

  const [hubunganKeluarga, setHubunganKeluarga] = useState([]);

  const handleChangeHubunganKeluarga = (name) => (e) => {
    setHubunganKeluarga({ ...hubunganKeluarga, [name]: e.target.value });
  };

  const [tanggalLahir, setTanggalLahir] = useState("");
  const handleChangeTanggalLahir = (e) => setTanggalLahir(e.target.value);

  const handleSimpanDataAnggotaKeluarga = () => {
    if (nameAnggotaKeluarga !== "") {
      let data = {
        id: ID(),
        name: nameAnggotaKeluarga,
        family_relationship_type_id: hubunganKeluarga.name,
        date_of_birth: tanggalLahir,
      };

      console.log("Data : ", data);

      if (listDataKeluarga.length > 0) {
        console.log("Run v1");

        const newList = [...listDataKeluarga, data];

        console.log("newList : ", newList);

        localStorage.setItem("employee_data_keluarga", JSON.stringify(newList));
        setListDataKeluarga([...listDataKeluarga, data]);
      } else {
        console.log("Run v2");

        dataList.push(data);
        localStorage.setItem(
          "employee_data_keluarga",
          JSON.stringify(dataList)
        );

        setListDataKeluarga(data);
        window.location.reload();
      }
    } else {
      alert("Anda belum melengkapi data !");
    }

    setModalTambahAnggotaKeluarga(false);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <br />
      <br />
      <Paper elevation={1} className={classes.paperKeluarga} square={true}>
        <Grid container>
          <Grid item xs={10} style={{ textAlign: "left" }}>
            <br />
            <Typography variant="subtitle1" className={classes.titleHeader}>
              <b>Keluarga</b>
            </Typography>

            <br />
            <Typography
              variant="subtitle2"
              className={classes.titleTambahAnggotaKeluarga}
              // onClick={() => setModalTambahAnggotaKeluarga(true)}
              onClick={handleTambahAnggotaKeluarga}>
              <b>+ Tambah Anggota Keluarga</b>
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            {/* 
                            <IconButton className={classes.iconEdit}>
                                <i className='material-icons'>edit</i>
                            </IconButton> 
                        */}
          </Grid>
        </Grid>

        {listDataKeluarga.length > 0 &&
          listDataKeluarga.map((item, i) => (
            <Grid container key={i}>
              <Grid item xs={3}>
                <Typography variant="subtitle2" className={classes.titleHeader}>
                  <b>{item.name}</b>
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="subtitle2" className={classes.titleHeader}>
                  <b>{item.family_relationship_type_id.value}</b>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span
                    onClick={(e) => handleEditDataDetail(e, item)}
                    style={{ color: "#cc0707", cursor: "pointer" }}>
                    Edit
                  </span>{" "}
                  &nbsp;&nbsp;- &nbsp;&nbsp;
                  <span
                    onClick={(e) => handleDeleteDataDetail(e, item)}
                    // onClick={() => localStorage.removeItem('employee_data_keluarga')}
                    style={{ color: "#cc0707", cursor: "pointer" }}>
                    Hapus
                  </span>
                </Typography>

                <Typography
                  variant="subtitle2"
                  className={classes.titleTanggalLahir}>
                  {item.date_of_birth}
                </Typography>
                <br />
              </Grid>
            </Grid>
          ))}
        <br />
      </Paper>

      <DialogEditAnggotaKeluarga
        classes={classes}
        modalEditAnggotaKeluarga={modalEditAnggotaKeluarga}
        setModalEditAnggotaKeluarga={setModalEditAnggotaKeluarga}
        handleEditDataDetail={handleEditDataDetail}
        dataDetailEdit={dataDetailEdit}
        handleChangeNamaAnggotaKeluarga={handleChangeNamaAnggotaKeluarga}
        nameAnggotaKeluarga={nameAnggotaKeluarga}
        hubunganKeluarga={hubunganKeluarga}
        handleChangeHubunganKeluarga={handleChangeHubunganKeluarga}
        // hubunganKeluargas = { hubunganKeluargas }
        handleChangeTanggalLahir={handleChangeTanggalLahir}
        tanggalLahir={tanggalLahir}
        // handleSimpanDataAnggotaKeluarga = {handleSimpanDataAnggotaKeluarga}
        // handleSimpanPerubahanAnggotaKeluarga = { handleSimpanPerubahanAnggotaKeluarga}
        handleSimpanPerubahan={handleSimpanPerubahan}
      />

      <DialogTambahAnggotaKeluarga
        classes={classes}
        modalTambahAnggotaKeluarga={modalTambahAnggotaKeluarga}
        setModalTambahAnggotaKeluarga={setModalTambahAnggotaKeluarga}
        handleChangeNamaAnggotaKeluarga={handleChangeNamaAnggotaKeluarga}
        nameAnggotaKeluarga={nameAnggotaKeluarga}
        hubunganKeluarga={hubunganKeluarga}
        handleChangeHubunganKeluarga={handleChangeHubunganKeluarga}
        // hubunganKeluargas = { hubunganKeluargas }
        handleChangeTanggalLahir={handleChangeTanggalLahir}
        tanggalLahir={tanggalLahir}
        handleSimpanDataAnggotaKeluarga={handleSimpanDataAnggotaKeluarga}
      />

      <DialogDeleteAnggotaTambahan
        classes={classes}
        modalDeleteAnggotaKeluarga={modalDeleteAnggotaKeluarga}
        setModalDeleteAnggotaKeluarga={setModalDeleteAnggotaKeluarga}
        dataDetailDelete={dataDetailDelete}
        listDataKeluarga={listDataKeluarga}
        setListDataKeluarga={setListDataKeluarga}
      />

      {/* 

                ````````````````````
                STICKY FOOTER

                ````````````````````
            */}
      <Grid
        container
        spacing={8}
        direction="row"
        justify="center"
        // alignItems="center"
      >
        <Grid item sm={9}></Grid>

        <Grid item sm={1} style={{ textAlign: "right" }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Button
            variant="outlined"
            color="secondary"
            className={classes.buttonOutlined}
            style={{ margin: 14 }}
            // onClick={() => Redirect(ToMembershipStatusScenarioPENDING_SEMENTARA)}
          >
            Keluar
          </Button>
        </Grid>
        <Grid item sm={2} style={{ textAlign: "left" }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            style={{ margin: 14 }}
            disabled={false}
            onClick={() => Redirect(ToHrEmployeeKepegawaianInfoPegawai)}>
            Lanjut
          </Button>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewIdentitasInfoTambahan);

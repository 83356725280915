import React from "react";
import { Box, Typography, Checkbox } from "@material-ui/core";
import clsx from "clsx";

const ListItemUsers = ({
  classes,
  index,
  user,
  selected,
  setSelected,
  isSelected,
}) => {
  const isItemSelected = isSelected(user.structure_position.id);

  const handleChangeCheckbox = (item) => {
    const selectedIndex = selected.indexOf(item.structure_position.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item.structure_position.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    // console.log("selected ALL MA", newSelected);

    setSelected(newSelected);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      marginLeft={1}
      marginRight={2}
      onClick={() => handleChangeCheckbox(user)}>
      <Checkbox
        className={classes.checkboxRoot}
        checkedIcon={
          <span className={clsx(classes.icon, classes.checkedIcon)} />
        }
        icon={<span className={classes.icon} />}
        checked={isItemSelected}
        disableRipple
        color="default"
        inputProps={{ "aria-labelledby": `enhanced-table-checkbox-${index}` }}
      />
      <Typography variant="subtitle2" className={classes.title333}>
        {user.member.first_name} {user.member.last_name}
      </Typography>
    </Box>
  );
};

export default ListItemUsers;

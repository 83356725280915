import axios from 'axios';

const userToken = localStorage.getItem('userToken')
const instance = axios.create({});
// baseURL: 'https://api-dev.performate.id/api/v1'

instance.defaults.headers.common['Accept'] = 'application/json';
instance.defaults.headers.common['Content-Type'] = 'application/json';
instance.defaults.headers.common['Authorization'] = "bearer " + userToken;


export default instance;
import React, { useContext } from "react";
import { Paper, Box, Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import numeral from "numeral";

import CircularProgress from "./CircularProgress";
import BarProgress from "./BarProgress";
import ContextReports from "../../../../context/ContextReports";

const Businessprofile = ({ classes }) => {
  const { summaryState } = useContext(ContextReports);

  const average =
    summaryState.averages !== null &&
    summaryState.averages.length > 0 &&
    summaryState.averages.reduce((acc, curr) => {
      return acc + curr.value;
    }, 0) / summaryState.averages.length;

  return (
    <Paper className={classes.shadowSection}>
      <Box padding={3}>
        {/* ====== Title & Icon More ====== */}
        <Typography className={classes.title333} variant="subtitle1">
          <b>Overall Performance</b>
        </Typography>
        <Box
          mt={4}
          mb={3}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center">
          <div style={{ width: 200, height: 200 }}>
            <CircularProgress average={average} />
          </div>
        </Box>
        <Box marginBottom={3}>
          <Typography className={classes.title333} variant="subtitle2">
            Perusahaan anda telah mencapai {numeral(average).format("0.00")}%
            dari target yang diharapkan
          </Typography>
        </Box>

        {/* ====== PROGRESS BAR ====== */}
        {summaryState.averages !== null &&
          summaryState.averages.length > 0 &&
          summaryState.averages.map((item) => (
            <>
              <Box display="flex" alignItems="center">
                <FiberManualRecordIcon
                  style={{
                    color:
                      item.value <= 85
                        ? "#CF364A"
                        : item.value > 85 && item.value <= 95
                        ? "#E8A700"
                        : "#35B970",
                    fontSize: 13,
                  }}
                />
                <Typography className={classes.title888} variant="caption">
                  <b>Average {item.type}</b>
                </Typography>
              </Box>
              <BarProgress classes={classes} countValue={item.value} />
            </>
          ))}
      </Box>
    </Paper>
  );
};

export default Businessprofile;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import { navigate, A } from "hookrouter";

import { ToRegister, ToLogin } from "../../../constants/config-redirect-url";

import logo from "../../../assets/images/performate-03.png"; // with import
import imgGoalManagement from "../../../assets/images/landing/goal-mgmt2.PNG";
import imgMeasuredActivity from "../../../assets/images/landing/ma1.PNG";
import imgScoreboard from "../../../assets/images/landing/scoreboard1.PNG";
import imgLogoSwd from "../../../assets/images/landing/logo-swd.PNG";
import imgPhone from "../../../assets/images/landing/ic-phone.PNG";
import imgRightArrow from "../../../assets/images/landing/arrow-right.PNG";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  imageLogo: {
    verticalAlign: "text-bottom",
    width: 35,
    height: 35,
  },
  centerLayout: {
    textAlign: "center",
  },
  textLowerCase: {
    textTransform: "none",
  },
  contentMenu: {
    display: "grid",
    gridTemplateColumns: "34% 22% 34%",
    gridColumnGap: "5%",
  },
  fontSubtitleDropdownMenu: {
    fontSize: "14px",
    color: "#7d7d7d",
    display: "flex",
    alignItems: "center",
  },
  fontTitleDropdownMenu: {
    color: "#333333",
    fontWeight: "700",
  },
});

const useStyles = makeStyles(styles);

const Nav = (props) => {
  const classes = useStyles();

  // function handleClickLogo() {

  // 	window.location.href = "/landing";
  // }

  return (
    <nav id="nav">
      <div className="nav-container" align="center">
        <ul>
          <li
            className="logo"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/home")}>
            <img src={logo} className={classes.imageLogo} />
            Performate
            <span></span>
          </li>
        </ul>

        <ul className="main-menu">
          <li>
            {
              //<A href="#">Product</A>
            }
            <div className="dropdown">
              <button className="dropbtn">
                Produk
                <i className="fa fa-caret-down"></i>
              </button>
              <div className="dropdown-content">
                <div style={{ paddingTop: "23px" }}></div>
                <div className="dropdown-main-content">
                  <div className={classes.contentMenu}>
                    <div>
                      <div style={{ marginLeft: "35%" }}>
                        <h3>Overview</h3>
                        <hr
                          style={{ borderColor: "#ffffff", opacity: "0.5" }}
                        />
                        <span className={classes.fontTitleDropdownMenu}>
                          Performate Overview
                        </span>
                        <br />
                        <span className={classes.fontSubtitleDropdownMenu}>
                          Your mate in performance monitoring
                        </span>
                        <br />
                        <br />
                        <br />
                        <img src={imgLogoSwd} width="75px" />
                        <br />
                        <span className={classes.fontSubtitleDropdownMenu}>
                          Tools untuk mencapai goal anda
                        </span>
                        <br />
                      </div>
                    </div>

                    <div>
                      <h3>Fitur</h3>
                      <hr style={{ borderColor: "#ffffff", opacity: "0.5" }} />
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/home/feature/goal-management")
                        }>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img src={imgGoalManagement} width="30px" />{" "}
                          <span className={classes.fontTitleDropdownMenu}>
                            Goal Management
                          </span>
                        </div>
                        <div style={{ marginLeft: "30px" }}>
                          <span className={classes.fontSubtitleDropdownMenu}>
                            Tentukan target{" "}
                            <img
                              src={imgRightArrow}
                              width="30px"
                              height="16px"
                              style={{ marginLeft: "10px" }}
                            />
                          </span>
                        </div>
                      </div>
                      <br />

                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/home/feature/measured-activity")
                        }>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img src={imgMeasuredActivity} width="30px" />{" "}
                          <span className={classes.fontTitleDropdownMenu}>
                            Measure activites
                          </span>
                        </div>
                        <div style={{ marginLeft: "30px" }}>
                          <span className={classes.fontSubtitleDropdownMenu}>
                            Tentukan strategi untuk mencapai goal{" "}
                            <img
                              src={imgRightArrow}
                              width="30px"
                              height="16px"
                              style={{ marginLeft: "0px" }}
                            />
                          </span>
                        </div>
                      </div>
                      <br />

                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/home/feature/scoreboard")}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img src={imgScoreboard} width="30px" />{" "}
                          <span className={classes.fontTitleDropdownMenu}>
                            Scoreboard
                          </span>
                        </div>
                        <div style={{ marginLeft: "30px" }}>
                          <span className={classes.fontSubtitleDropdownMenu}>
                            Monitor perkembangan goal dan MA{" "}
                            <img
                              src={imgRightArrow}
                              width="30px"
                              height="16px"
                              style={{ marginLeft: "4px" }}
                            />
                          </span>
                        </div>
                      </div>
                      <br />
                    </div>

                    <div
                      style={{
                        backgroundColor: "#f5f5f5",
                        paddingLeft: "20px",
                      }}>
                      <h3>Road show</h3>
                      <span className={classes.fontSubtitleDropdownMenu}>
                        Jadwal workshop dan seminar
                      </span>
                      <br />
                      <br />
                      <br />
                      <span className={classes.fontTitleDropdownMenu}>
                        Aplikasi Mobile di playstore
                      </span>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={imgPhone} width="15px" />{" "}
                        <span className={classes.fontSubtitleDropdownMenu}>
                          Performate.id
                        </span>
                      </div>
                      <br />
                      <br />
                      <span className={classes.fontTitleDropdownMenu}>
                        Bantuan
                      </span>
                      <br />
                      <span className={classes.fontSubtitleDropdownMenu}>
                        cs@performate.id
                      </span>
                      <br />
                    </div>
                  </div>

                  <hr
                    style={{
                      borderColor: "#ffffff",
                      opacity: "0.5",
                      margin: "0px",
                    }}
                  />
                  <A
                    href="/home/demo-schedule"
                    style={{
                      textAlign: "left",
                      margin: "0px 0px 0px 12%",
                      padding: "10px 0px",
                    }}>
                    Jadwalkan demo
                  </A>
                </div>
              </div>
            </div>
          </li>
          <li>
            <A href="/home/price">Harga</A>
          </li>
          <li>
            <A href="/home/contact">Kontak</A>
          </li>
          <li>
            <A href="/home/about">Tentang</A>
          </li>
        </ul>

        <ul>
          <li onClick={() => navigate(ToRegister)}>
            <a href="#" style={{ textAlign: "right" }}>
              Daftar
            </a>
          </li>
          <li
            onClick={() => navigate(ToLogin)}
            className={classes.centerLayout}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              disableElevation
              className={classes.textLowerCase}>
              Masuk
            </Button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;

import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#d1354a", //#cc0707, #c62828
      light: "#fff",
      dark: "#fff",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Roboto"].join(","),
    subtitle2: {
      fontWeight: 600,
    },
  },
});

export const styles = () => ({
  // Typogrtaphy
  title: {},
  title333: {
    color: "#333",
  },
  title555: {
    color: "#555",
  },
  title888: {
    color: "#888",
  },
  titleRed: {
    color: "#d1354a",
  },
  titleGreen: {
    color: "#2ecc71",
  },
  titleHead: {
    color: "#fff",

    fontSize: 14,
  },
  titleHead3: {
    color: "#fff",

    width: 70,
    fontSize: 13,
  },
  txtTitleGoal: {
    "&:hover": {
      textDecoration: "underline",
      opacity: 0.8,
      cursor: "pointer",
    },
  },
  tableHead: {
    backgroundColor: "#d1354a",
  },
  subTable: {
    fontFamily: "Roboto",
    color: "#333",
    borederBottom: "none",
    // width: 100,
    // paddingRight: 0,
    // paddingLeft: 0,
  },
  shadowTable: {
    borderRadius: 8.5,
    boxShadow: "0px 14px 15px -4px rgba(0,0,0,0.05)",
  },
  extendTable: {
    backgroundColor: "#f1f2f6",
    // paddingBottom: 0,
    // paddingTop: 0,
    padding: 0,
    margin: 0,
  },
  bgRow: {
    backgroundColor: "#eee",
  },
});

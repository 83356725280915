import React from "react";
import { Box, Typography } from "@material-ui/core";
import AccessAlarmRoundedIcon from "@material-ui/icons/AccessAlarmRounded";
import MicRoundedIcon from "@material-ui/icons/MicRounded";
import FullscreenExitRoundedIcon from "@material-ui/icons/FullscreenExitRounded";

const TopTitle = ({ classes }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="subtitle1" className={classes.title555}>
        <b>2. Review Goal Meeting</b>
      </Typography>

      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center" mr={4}>
          <AccessAlarmRoundedIcon
            style={{ fontSize: 16, color: "#d64253", marginRight: 7 }}
          />
          <Typography variant="subtitle1" className={classes.title555}>
            45 min
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" mr={4}>
          <MicRoundedIcon
            style={{ fontSize: 16, color: "#d64253", marginRight: 7 }}
          />
          <Typography variant="subtitle1" className={classes.title555}>
            Karim E
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <FullscreenExitRoundedIcon
            style={{ fontSize: 16, color: "#d64253", marginRight: 7 }}
          />
          <Typography variant="subtitle1" className={classes.title555}>
            Collapsed All
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TopTitle;

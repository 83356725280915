import { GET_BAR_CHART } from "../types";

// ~ Initial State for Table ~
export const initialBar = {
  barData: [],
  barPreset: [],
  loading: false,
};

// =======================================================================
// ~ REDUCER | Managing GLobal State for Table ~
export const barReducer = (state, { type, payload }) => {
  switch (type) {
    case GET_BAR_CHART:
      return {
        ...state,
        barData: payload.barData,
        barPreset: payload.barPreset,
        loading: payload.loading,
      };
    default:
      return state;
  }
};

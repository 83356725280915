import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Divider,
  Grid,
  CircularProgress,
  Button,
  Tooltip,
  Menu,
  MenuItem,
} from "@material-ui/core";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import DateRangeIcon from "@material-ui/icons/DateRange";

import moment from "moment";

import PercentagesSlider from "react-percentages-slider";
import "react-percentages-slider/dist/index.css";

import Axios from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";
import DialogError from "../../../../../components/DialogError";
import handleError from "../../../../Report/components/global/handleError";
import ListItemPeriod from "./ListItemPeriod";

const DialogSetWeightGoal = ({
  classes,
  openDialogSetWeightGoal,
  setOpenDialogSetWeightGoal,
  user,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadWeight, setLoadWeight] = useState(false);
  const [anchorPeriod, setAnchorPeriod] = useState(null);
  const [period, setPeriod] = useState(varPeriod);
  const [collectionPeriod, setCollectionPeriod] = useState([]);
  const [collectionGoal, setCollectionGoal] = useState([]);

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (openDialogSetWeightGoal) {
      setLoadWeight(true);

      Axios.get(
        `${URL_API}/swd/goal/weighting/${user.id}?period_id=${period.id}`
      )
        .then((res) => {
          const result = res.data.data;
          const periodList = result.fields.period_id.default_value;
          const goalList = result.fields.goal_id.default_value;

          console.log("RES Goal Weight", result);
          console.log("GL List", goalList);

          if (goalList.length === 0) {
            setMaxWidth("md");
          } else if (goalList.length > 0 && goalList.length <= 8) {
            setMaxWidth("md");
            setFullWidth(true);
          } else if (goalList.length > 8 && goalList.length <= 15) {
            setMaxWidth("lg");
          } else if (goalList.length > 15) {
            setMaxWidth("xl");
            setFullWidth(true);
          }

          const percent = 100 / goalList.length;

          if (res.status === 200) {
            setCollectionPeriod(periodList);

            const addPer = goalList.map((el, i) => {
              const weight = el.weighting;

              return {
                ...el,
                text: `Goal ${i + 1}`,
                percentage: weight !== null ? Number(weight) : percent,
                color: colors[i],
              };
            });

            setCollectionGoal(addPer.length > 0 ? addPer : []);
          }
          setLoadWeight(false);
        })
        .catch((error) => {
          setLoadWeight(false);
          console.log("Error", error);
        });
    }
  }, [openDialogSetWeightGoal, period]);

  //   console.log("USR", user);

  const handleShowAnchorPriod = (event) => {
    setAnchorPeriod(event.currentTarget);
  };

  const handleCloseAnchorPriod = () => {
    setAnchorPeriod(null);
  };

  const handlePeriod = (data) => {
    setPeriod(data);
    handleCloseAnchorPriod();
    console.log("PerR", data);
  };

  const handleWeightGoal = () => {
    setLoading(true);

    const mapGoal =
      collectionGoal.length > 0 &&
      collectionGoal.map((el) => {
        return {
          goal_id: el.id,
          weight: Number(el.percentage).toFixed(),
        };
      });
    const data = {
      period_id: period.id,
      date_start: moment(period.start_date).format("YYYY-MM-DD"),
      date_finish: moment(period.end_date).format("YYYY-MM-DD"),
      entity: "user",
      weightings: mapGoal,
    };

    console.log("WEIGHT GOLZ", data);

    Axios.post(`${URL_API}/swd/goal/weighting/${user.id}/store`, data)
      .then((res) => {
        console.log("RES WEIGHT", res);

        if (res.status === 200) {
          setLoading(false);
          setOpenDialogSetWeightGoal(false);
        }
      })
      .catch((error) => {
        console.log("Error : ", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={openDialogSetWeightGoal}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle1" className={classes.title}>
            <b>{`${user.member_first_name} ${user.member_last_name}`}</b>
          </Typography>

          <Button
            className={classes.btnPeriod}
            variant="outlined"
            size="small"
            disabled={loadWeight}
            endIcon={<DateRangeIcon />}
            onClick={handleShowAnchorPriod}>
            {loadWeight ? (
              <CircularProgress size={14} style={{ color: "#1976D2" }} />
            ) : (
              period.name
            )}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorPeriod}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            getContentAnchorEl={null}
            open={Boolean(anchorPeriod)}
            onClose={handleCloseAnchorPriod}>
            {collectionPeriod.length > 0 &&
              collectionPeriod.map((item, i) => (
                <MenuItem
                  key={i}
                  style={{ margin: 0, padding: "0 10px" }}
                  dense>
                  <ListItemPeriod
                    classes={classes}
                    item={item}
                    period={period}
                    onPeriod={handlePeriod}
                  />
                </MenuItem>
              ))}
          </Menu>
        </Box>
      </DialogTitle>

      <DialogContent>
        {loadWeight ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={15} style={{ color: "#d1354a" }} />
            <Typography variant="subtitle2" className={classes.title888}>
              Load Goal...
            </Typography>
          </Box>
        ) : (
          <PercentagesSlider
            divisions={collectionGoal}
            setDivisions={setCollectionGoal}
          />
        )}

        {!loadWeight && collectionGoal.length === 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column">
            <Typography variant="subtitle2" className={classes.title333}>
              {`${user.member_first_name} Tidak Memiliki Goal,`}
            </Typography>
            <Typography variant="subtitle2" className={classes.title333}>
              Silhakan pilih <b>Period</b>
            </Typography>
          </Box>
        )}

        <Box my={4}>
          <Divider />
        </Box>

        <Grid container>
          <Grid item md={8}>
            <Typography variant="subtitle2" className={classes.title888}>
              Goal
            </Typography>

            <Box mt={3}>
              {collectionGoal.map((item, i) => (
                <Typography
                  key={i}
                  variant="subtitle2"
                  className={classes.title888}
                  style={{ marginBottom: 10 }}>
                  <b>
                    ({item.text}) {item.name}
                  </b>
                </Typography>
              ))}
              {!loadWeight && collectionGoal.length === 0 && (
                <Typography variant="subtitle2" className={classes.title333}>
                  ---
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item md={4}>
            <Box display="flex" alignItems="center">
              <Typography
                variant="subtitle2"
                className={classes.title888}
                style={{ marginRight: 5 }}>
                Weight
              </Typography>
              <Tooltip
                title="Maksimal nilai keseluruhan weight pada Goal adalah 100% . Setiap ada perubahan Goal maka akan diwajibkan untuk menyesuaikan kembali nilai weight."
                placement="bottom">
                <InfoRoundedIcon style={{ fontSize: 13, color: "#333" }} />
              </Tooltip>
            </Box>
            <Box mt={3}>
              {collectionGoal.map((item, i) => (
                <Typography
                  key={i}
                  variant="subtitle2"
                  className={classes.title888}
                  style={{ marginBottom: 10 }}>
                  <b>{Number(item.percentage).toFixed()} %</b>
                </Typography>
              ))}
              {!loadWeight && collectionGoal.length === 0 && (
                <Typography variant="subtitle2" className={classes.title333}>
                  ---
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          justifyContent: "flex-end",
          margin: 15,
          marginTop: 20,
        }}>
        <Button
          variant="outlined"
          disableElevation
          size="medium"
          className={classes.btnWhite}
          onClick={() => setOpenDialogSetWeightGoal(false)}>
          Cancel
        </Button>

        <Button
          variant="contained"
          size="medium"
          className={classes.button}
          disabled={collectionGoal.length === 0}
          onClick={handleWeightGoal}>
          {loading ? "Saving..." : "Save"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogSetWeightGoal;

const colors = [
  "#F6B03E",
  "#F47A48",
  "#DD668C",
  "#A05095",
  "#6D5A94",
  "#a29bfe",
  "#6AAFC1",
  "#74b9ff",
  "#55efc4",
  "#00b894",
  "#fab1a0",
  "#ff7675",
  "#fdcb6e",
  "#e17055",
  "#dfe6e9",
  "#b2bec3",
  "#F6B03E",
  "#F47A48",
  "#DD668C",
  "#A05095",
  "#6D5A94",
  "#a29bfe",
  "#6AAFC1",
  "#74b9ff",
  "#55efc4",
  "#00b894",
  "#fab1a0",
  "#ff7675",
  "#fdcb6e",
  "#e17055",
  "#dfe6e9",
  "#b2bec3",
];

const varPeriod = {
  id: "",
  name: "Pilih Period",
  period_group_id: null,
  sequence: null,
  prev_id: null,
  next_id: null,
  interval_id: "",
  start_date: "",
  end_date: "",
  status_id: "",
  created_by: "",
  updated_by: null,
  deleted_by: null,
  created_at: "",
  updated_at: "",
  deleted_at: null,
  prev: null,
  next: null,
  status: {
    id: "",
    name: "",
  },
  periodGroup: null,
  interval: {
    id: "",
    name: "",
    code: "",
    value: "3",
    unit: "month",
    sort_order: 2,
  },
  accessRule: [
    {
      action: "update",
      result: true,
      reason: [],
    },
    {
      action: "view",
      result: true,
      reason: [],
    },
    {
      action: "delete",
      result: true,
      reason: [],
    },
  ],
  self: {
    rel: {
      prev: null,
      next: null,
      periodGroup: null,
    },
  },
};

import React, { useState, useEffect, useContext } from "react";
import { Grid, Tabs, Tab, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import FormEditCompanySetting from "./Components/FormEditCompanySetting";
import ViewManageMembership from "./ManageMembership/ViewManageMembership";
import ViewUser from "../UserManagement/ViewUser";

import axios from "axios";
import { URL_API } from "../../../constants/config-api";

import ContextCompanySetting from "./Context/ContextCompanySetting";

/* ====================================
    Custom Tabs
   ==================================== */

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "transparent",
    "& > span": {
      maxHeight: 30,
      width: "100%",
      backgroundColor: "#333",
      textAlign: "left",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    textAlign: "left",
    color: "#333",
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 13,
    marginRight: 8,
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const ViewOrganizationSetting = ({ theme, classes, onAcccountTab }) => {
  const context = useContext(ContextCompanySetting);
  const user = JSON.parse(localStorage.getItem("status_user_login"));
  const statusUser = user.membership_status_id;

  const [memberCountCollections, setMemberCountCollections] = useState([]);
  const [updatedAt, setUpdatedAt] = useState("");
  const [organizationTab, setOrganizationTab] = useState(0);

  const [fields, setFields] = useState({
    name: "",
    photo_logo_icon: "",
    tax_id_number: "", //*NPWP
    member_count: 0,
    alamat_detail: "",
    country_id: "",
    country_name: "",
    province_id: "",
    province_name: "",
    city_id: "",
    city_name: "",
    postal_code_id: "",
    postal_code_name: "",
    vision_and_mission: "",
  });

  useEffect(() => {
    //*
    const userToken = localStorage.getItem("userToken");
    const tabOrg = localStorage.getItem("org_tab");
    const tabOrgVal = parseInt(tabOrg);

    if (tabOrg === null || tabOrg === undefined) {
      setOrganizationTab(0);
    } else {
      setOrganizationTab(tabOrgVal);
    }

    if (userToken !== undefined) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userToken,
      };

      axios.defaults.headers.common = header;

      axios
        .get(URL_API + `/group/profile/update`)
        .then(function (response) {
          console.log("Response Original : ", response);

          if (response.status === 200) {
            if (response.data.data.fields !== null) {
              setFields({
                name: response.data.data.fields.name.value,
                photo_logo_icon:
                  response.data.data.fields.photo_logo_icon.value,
                tax_id_number: response.data.data.fields.tax_id_number.value, //*NPWP
                member_count: response.data.data.fields.member_count.value,
                alamat_detail: response.data.data.fields.detail.value,
                country_id: response.data.data.fields.country_id.value,
                country_name: "",
                province_id: response.data.data.fields.province_id.value,
                province_name: "",
                city_id: response.data.data.fields.city_id.value,
                city_name: "",
                postal_code_id: response.data.data.fields.postal_code_id.value,
                postal_code_name: "",
                vision_and_mission:
                  response.data.data.fields.vision_and_mission.value,
              });

              context.setCityCollections(response.data.data.cityCollections);
              context.setCountryCollections(
                response.data.data.countryCollections
              );
              setMemberCountCollections(
                response.data.data.memberCountCollections
              );
              context.setPostalCodeCollections(
                response.data.data.postalCodeCollections
              );
              context.setProvinceCollections(
                response.data.data.provinceCollections
              );
              setUpdatedAt(response.data.data.updated_at);
            }
          }
        })
        .catch(function (error) {
          console.log("Error : ", error.response);
        });
    } else {
      console.log("No Access Token available!");
    }
  }, []);

  useEffect(() => {
    // const userToken = localStorage.getItem('userToken');
    if (context.fireUpdate === true) {
      if (context.userTokenStateGlobal !== undefined) {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + context.userTokenStateGlobal,
        };

        axios.defaults.headers.common = header;

        axios
          .get(URL_API + `/group/profile/update`)
          .then(function (response) {
            console.log("Response Original : ", response);

            if (response.status === 200) {
              if (response.data.data.fields !== null) {
                setFields({
                  name: response.data.data.fields.name.value,
                  photo_logo_icon:
                    response.data.data.fields.photo_logo_icon.value,
                  tax_id_number: response.data.data.fields.tax_id_number.value, //*NPWP
                  member_count: response.data.data.fields.member_count.value,
                  alamat_detail: response.data.data.fields.detail.value,
                  country_id: response.data.data.fields.country_id.value,
                  country_name: "",
                  province_id: response.data.data.fields.province_id.value,
                  province_name: "",
                  city_id: response.data.data.fields.city_id.value,
                  city_name: "",
                  postal_code_id:
                    response.data.data.fields.postal_code_id.value,
                  postal_code_name: "",
                  vision_and_mission:
                    response.data.data.fields.vision_and_mission.value,
                });

                context.setCityCollections(response.data.data.cityCollections);
                context.setCountryCollections(
                  response.data.data.countryCollections
                );
                setMemberCountCollections(
                  response.data.data.memberCountCollections
                );
                context.setPostalCodeCollections(
                  response.data.data.postalCodeCollections
                );
                context.setProvinceCollections(
                  response.data.data.provinceCollections
                );
                setUpdatedAt(response.data.data.updated_at);
              }
            }
          })
          .catch(function (error) {
            console.log("Error : ", error.response);
          });
      } else {
        console.log("No Access Token available!");
      }
    }
  }, [context.fireUpdate]);

  const [fieldsMemberCount, setFieldsMemberCount] = useState({
    employee: `${fields.member_count}`,
  });

  useEffect(() => {
    //*
    memberCountCollections.filter((element) => {
      if (element.id === `${fields.member_count}`) {
        // console.log("Member Count : ", element)
        setFieldsMemberCount({
          employee: element.name,
          // id: element.id
        });
        return element;
      }
    });
  }, [memberCountCollections]);

  const handleChangeAccountTab = (e, newValue) => {
    setOrganizationTab(newValue);
    localStorage.setItem("org_tab", newValue);
  };

  return (
    <Grid container style={{ marginTop: 0 }}>
      <Grid item md={2} style={{ backgroundColor: "#f8f8fc" }}>
        <Box
          style={{
            flexGrow: 0.5,
            marginTop: theme.spacing(5),
          }}>
          <StyledTabs
            value={organizationTab}
            orientation="vertical"
            onChange={handleChangeAccountTab}
            aria-label="styled tabs example">
            <StyledTab label="Company Profile" disabled={statusUser === "22"} />
            <StyledTab label="Membership" />
            <StyledTab label="User" disabled={statusUser === "22"} />
          </StyledTabs>
        </Box>
      </Grid>
      <Grid item md={10}>
        <div style={{ backgroundColor: "#fff", height: "100vh" }}>
          {organizationTab === 0 ? (
            <FormEditCompanySetting
              classes={classes}
              fields={fields}
              memberCountCollections={memberCountCollections}
              fieldsMemberCount={fieldsMemberCount}
              setFieldsMemberCount={setFieldsMemberCount}
              updatedAt={updatedAt}
            />
          ) : organizationTab === 1 ? (
            <ViewManageMembership classes={classes} />
          ) : (
            <ViewUser onAcccountTab={handleChangeAccountTab} />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ViewOrganizationSetting;

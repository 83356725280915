import React, { useState, Fragment } from "react";
import DialogSuccessChangePassword from "./DialogSuccessChangePassword";
import {
  Dialog,
  DialogContent,
  Typography,
  DialogTitle,
  Grid,
  IconButton,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import { URL_API } from "../../../../constants/config-api";
import axiosConfig from "../../../../constants/config-axios";
import DialogError from "../../../../components/DialogError";

import warningPict from "../../../../assets/images/icon_warning_yellow.png";

function DialogChangePassword({
  dialogChangePassword,
  setDialogChangePassword,
  classes,
  value,
  setValue,
}) {
  const [loading, setLoading] = useState(false);
  const [
    dialogSuccessChangePassword,
    setDialogSuccessChangePassword,
  ] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleSubmitChangePassword = (e) => {
    e.preventDefault();

    const data = {
      Auth: value,
    };

    console.log("Change Pwd Submit", data);

    setLoading(true);

    axiosConfig
      .post(`${URL_API}/auth/change-password`, data)
      .then((response) => {
        console.log("Original Rsponse Change Password", response);

        if (response.status === 200) {
          setDialogSuccessChangePassword(true);
          setDialogChangePassword(false);
          setTimeout(() => {
            setValue({
              old_password: "",
              new_password: "",
              new_password_confirmation: "",
            });
          }, 1000);
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error Change Password", error);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  return (
    <Fragment>
      <Dialog
        open={dialogChangePassword}
        maxWidth="md"
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>
          <Grid container justify="flex-end">
            <IconButton
              size="small"
              onClick={() => setDialogChangePassword(false)}>
              <HighlightOffIcon style={{ color: "grey", fontSize: "18px" }} />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent
          style={{
            textAlign: "center",
            marginTop: -25,
            paddingRight: 30,
            paddingLeft: 30,
          }}>
          <img src={warningPict} width="40" height="35" />

          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ marginBottom: 7, marginTop: 20 }}>
            <b>Anda yakin mengubah Kata Sandi ?</b>
          </Typography>

          <Typography
            variant="subtitle2"
            className={classes.textPro1}
            style={{ marginBottom: 15, marginTop: 0 }}>
            kata Sandi hanya boleh diubah 1x24 jam
          </Typography>
        </DialogContent>
        <DialogActions style={{ marginTop: 15, marginBottom: 15 }}>
          <Grid container justify="center">
            <Button
              className={classes.button3}
              onClick={() => setDialogChangePassword(false)}>
              Batal
            </Button>

            <Button
              className={classes.button2}
              style={{ padding: 0 }}
              onClick={handleSubmitChangePassword}>
              {loading ? <CircularProgress color="#fff" size={17} /> : "Ubah"}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>

      <DialogSuccessChangePassword
        dialogSuccessChangePassword={dialogSuccessChangePassword}
        setDialogSuccessChangePassword={setDialogSuccessChangePassword}
        classes={classes}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
}

export default DialogChangePassword;

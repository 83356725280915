import React, { Component, useEffect, useState } from "react";
import { makeStyles, createMuiTheme, withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { Container, Paper, Typography, Grid, TextField, Checkbox, FormControlLabel, Box, Button, MenuItem, Popover, Fab, Chip, Slider} from '@material-ui/core';
// import Slider from '@material-ui/lab/Slider';

import LensIcon from '@material-ui/icons/LensOutlined';

import PictPaketBasic from '../../../assets/images/SVG/Group_1655.svg';
import PictPaketSilver from '../../../assets/images/SVG/Group_1.svg';
import PictPaketGold from '../../../assets/images/SVG/Group_2.svg';
import PictPaketPlatinum from '../../../assets/images/SVG/Group_3.svg';
import IconSliderPacketMahkota from '../../../assets/images/Group_1745.png';
import PictBackgroundImage from '../../../assets/images/Group_1922.png'

import Redirect from '../../../utilities/Redirect';
import { ToMembershipStatus } from '../../../constants/config-redirect-url';

import {stylesIncreaseQuota} from './styles/stylesIncreaseQuota';
// import './backgroundPaper.css';

const theme = createMuiTheme({
    
    palette: {

        primary: {
            main: '#cc0707', //#cc0707, #c62828
            light: '#ff5f52',
            dark: '#8e0000',
            contrastText: '#ffffff'
        }
    },
    overrides: {
      
    }   
});

const ViewMembershipStatus_scenarioIncreaseQuota = props => {

    const { classes } = props;

    /*
        ````````````````
        HANDLE POPOVER

        ````````````````
    */
    const [ anchorEl, setAnchorEl ] = useState(null);
    const openPopover = Boolean(anchorEl);

    const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget)
    const handlePopoverClose = () => setAnchorEl(null);

    /*
        ````````````````
        HANDLE SLIDER

        ````````````````
    */

    const [valueSlider, setValueSlider ] = useState(20);
    const handleChangeSlider = (event, value) => {
        event.preventDefault();

        console.log("Value : ", value);
        setValueSlider(value);
    };

    /*
        `````````````````````
        HANDLE TOGGLE BUTTON

        `````````````````````
    */
    const [activeButton, setActiveButton] = useState(false)
    
    /*
        ``````````````````````````
        HANDLE TOGGLE BOX PACKET 

        ``````````````````````````
    */
    const [activeBoxPict, setActiveBoxPict] = useState(false)


    /*
        `````````````````````````````````
        HANDLE SLIDER BOX PACKET MAHKOTA

        ``````````````````````````````````
    */

    const [valueSliderMahkota, setValueSliderMahkota ] = useState(1);
    const handleChangeSliderMahkota = (event, value) => {
        event.preventDefault();

        console.log("Value : ", value);
        setValueSliderMahkota(value);

    };

    /*
        `````````````````````````````````
        HANDLE KELOLA KEANGGOTAAN

        ``````````````````````````````````
    */

   const handleKelolaKeanggotaan = () => Redirect('/membership-status-manage');
//    const [ invoiceNumber, setInvoiceNumber ] = useGlobal('invoice_number');

    return (
        
        <MuiThemeProvider theme={theme}>
            {/* <Container maxWidth="sm"> */}
                <Paper className={classes.root} elevation={0}>
                    <Grid 
                        container 
                        alignItems="flex-start"
                    >  
                        <Grid item xs={12}>
                            <Typography variant="h5" className={classes.title}>
                                Keanggotaan 
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper 
                    elevation={0} 
                    square 
                    style={{
                        marginLeft:'16px', 
                        marginRight: '16px',
                        backgroundImage: 'url(' + PictBackgroundImage + ')', 
                        backgroundSize: 'cover', 
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    <Grid container>
                        <Grid item xs={3}>
                            <Typography 
                                variant="subtitle2" 
                                className={classes.kuotaUser}
                                style={{color: 'grey'}}

                            >
                                2 <i>Kuota user</i> sudah di gunakan dari 3

                            </Typography>
                        </Grid>
                        {/* <Grid item xs={0}>
                            <Slider
                                value={valueSlider}
                                aria-labelledby="slider-icon"
                                onChange={handleChangeSlider}
                                classes={{
                                    root: classes.slider,
                                    thumbIconWrapper: classes.thumbIconWrapper,
                                    track: classes.trackSlider,
                                }}
                                thumb={
                                    <span
                                        style={{ color: '#2196f3' }} 
                                        // *Explore
                                        aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}
                                    >
                                        
                                    </span>
                                }
                            />
                            <Popover
                                id="mouse-over-popover"
                                className={classes.popover}
                                classes={{
                                    paper: classes.paperPopover,
                                }}
                                open={openPopover}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                                >
                                <Typography>2 dari 3</Typography>
                            </Popover>
                        </Grid> */}
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" className={classes.masaBerlaku}>
                                Masa berlaku anda 36 hari lagi
                            </Typography>
                        </Grid>

                        <Grid item xs={1}>
                            {/* <LensIcon 
                                style={{ color: '#19dc5a', width: 10, position: 'absolute', textAlign: 'right'}}            
                            />
                            <Typography variant="subtitle2" className={classes.statusAktif}>
                                Aktif
                            </Typography> */}
                            <Button variant='outlined' size='small' className={classes.buttonStatusActive} >
                                <LensIcon 
                                    style={{ color: '#19dc5a', width: 10, marginRight: 7}}            
                                />
                                Aktif
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid 
                        container 
                        justify="center" 
                        alignItems="center"
                    >  
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                            <img src={PictPaketGold} alt='Gold' className={classes.titleMahkota} />
                            <Typography variant="h5" className={classes.titleCompanyName}>
                                PT. Madinah Indonesia
                            </Typography>

                            <Typography variant="subtitle1" className={classes.titleTrial}>
                                Gold 
                            </Typography>
                            <Button 
                                variant='outlined' 
                                size='medium' 
                                className={classes.kelolaKeanggotaan}
                                onClick={handleKelolaKeanggotaan}
                            >
                                Kelola Keanggotaan
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper className={classes.subRoot3} elevation={0}>
                    <Grid 
                        container 
                        justify="center" 
                        alignItems="center"
                    >  
                        <Grid item xs={12}>
                            <Typography variant="h6" className={classes.title}>
                                Pembayaran Terakhir
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper elevation={4} className={classes.subRootCardRiwayat}>
                    <Grid container >  
                        <Grid item xs={2}> 
                            <Chip label="Sukses" className={classes.successButton} variant="outlined" />
                            <br />
                            <br />
                            <Typography variant="subtitle1" className={classes.successButtonDate} >
                                01/11/2019
                            </Typography>
                        </Grid>

                        <Grid item xs={7} style={{textAlign: 'left'}}>
                            <Typography variant="h6" className={classes.langgananKeanggotaan}>
                                Berlangganan Keanggotaan
                            </Typography>
                            <br />
                            <Typography variant="subtitle1" className={classes.langgananInvoice} >
                                INV/2016009/098689/09
                            </Typography>
                           
                        </Grid>

                        <Grid item xs={3} style={{textAlign: 'center'}}>
                            <Typography variant="h6" className={classes.nominal}>
                                Rp 6.885.000
                            </Typography>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                </Paper>

                <Grid container >  
                    <Grid item xs={12} style={{textAlign:'center'}}>
                        <Button 
                            variant='contained' 
                            className={classes.buttonLihatRiwayat}
                            onClick={() => Redirect(ToMembershipStatus)}
                        >
                            Lihat Riwayat
                        </Button>
                    </Grid>
                </Grid>
            {/* </Container> */}
        </MuiThemeProvider>
    )
};

export default withStyles(stylesIncreaseQuota)(ViewMembershipStatus_scenarioIncreaseQuota);

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

function DialogNewVersion({ open, setOpen }) {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Mendeteksi versi baru</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Browser anda mendeteksi versi baru dari <i>Performate</i>, tekan{" "}
          <b>Ctrl+F5</b> atau <b>Ctrl+Shift+R</b> untuk update versi.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default DialogNewVersion;

import React from "react";
import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";

function CardSixTargetType({ classes, i, targetType, handleSelectTargetType }) {
  return (
    <Grid container justify="space-between" key={i}>
      <Typography
        variant="subtitle2"
        className={classes.title}
        style={{ color: "grey", marginTop: 16, cursor: "pointer" }}>
        <b>Pilih Target Type</b>
      </Typography>

      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="targetType"
          name="targetType"
          value={targetType[i]}
          onChange={(e) => handleSelectTargetType(e, i)}>
          <FormControlLabel value="1" control={<Radio />} label="Daily" />
          <FormControlLabel value="2" control={<Radio />} label="Final" />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}

export default CardSixTargetType;

/*
    -----------------------------------------------------------------------------
  |  Page View ini sebenarnya berisi component Treeview, sory penamaan-nya pakai  |
  |  "ViewOrgChart", insya Allah bakal di review code lagi and di ganti jadi      |
  |  "ViewTreeOrg"                                                                |
    -----------------------------------------------------------------------------
*/

import React, { useState, useEffect, Fragment, useContext } from "react";

import { Box, IconButton } from "@material-ui/core";

import { useTheme } from "@material-ui/core/styles";

import axios from "axios";

import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import DataTreeView from "./DataTreeView";
import SnackbeerSO from "../../components/SnackbeerSO";

import ContextNewSO from "./Context/ContextNewSO";

import HeaderGOAL from "../../layout/HeaderGOAL";
// import { styles } from "./Style/StyleNewSO";

import { URL_API } from "../../constants/config-api";
// import { TAB_TREE_VIEW, TAB_POSISI, TAB_KARYAWAN, TAB_USER } from '../../constants/config-tab';

// const useStyles = makeStyles(styles);

const ViewOrgChart = (props) => {
  /*
        ------------------------------------
        context : {               
                                    
            setTreeItems : Array,
            isOpenDialogTambahHead: Boolean
        }                         
        ------------------------------------
    */
  const context = useContext(ContextNewSO);
  const theme = useTheme();

  //   const classes = useStyles();

  const [loader, setLoading] = useState(false);

  useEffect(() => {
    localStorage.removeItem("question_bantu_generate");

    const userToken = localStorage.getItem("userToken");

    if (userToken !== undefined) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userToken,
      };

      axios.defaults.headers.common = header;

      axios
        .get(URL_API + `/human-resource/structure-position/root/with-child`)
        .then((response) => {
          console.log("Response ORG Chart : ", response);

          const copyData = { ...response.data.data };
          const listChild = [...copyData.child];

          delete copyData.child;

          let listAfterSort = listChild.sort((data1, data2) => {
            if (data1.classification_id < data2.classification_id) {
              return -1;
            }

            if (data1.classification_id > data2.classification_id) {
              return 1;
            }

            return 0;
          });

          let readyData = {
            ...copyData,
            child: listAfterSort,
          };
          console.log("readyData : ", readyData);

          let listReadyData = [];
          listReadyData.push(readyData);

          context.setTreeItems(listReadyData);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error : ", error.response);
        });
    }
  }, []);

  useEffect(() => {
    if (
      context.isOpenDialogTambahHead === false &&
      context.isOpenDialogTambahDeputyHead === false &&
      context.isOpenDialogTambahAssistant === false &&
      context.isOpenDialogTambahStaff === false &&
      context.isOpenDialogDeleteNodeSO === false &&
      context.isOpenDialogEditNodeSOInRootTop === false &&
      context.isOpenDialogEditNodeSO === false && //*ini HEAD
      context.isOpenDialogEditNodeSOInDeputyHEAD === false &&
      context.isOpenDialogEditNodeSOInASSISTANT === false &&
      context.isOpenDialogEditNodeSOInSTAFF === false
    ) {
      const userToken = localStorage.getItem("userToken");

      if (userToken !== undefined) {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + userToken,
        };

        axios.defaults.headers.common = header;

        /*
                    ``````````````````````````````````````
                    In here, letakan kode untuk validasi :
                    isSuccessCreateHEAD, 
                    isSuccessCreateDeputyHEAD, 
                    isSuccessCreateASSISTANT,
                    isSuccessCreateSTAFF

                    *Tujuannya ketika SUCCESS maka dapet List child dari parent based on ID SO

                    ``````````````````````````````````````
                */

        if (
          context.isSuccessTambahHEAD === true ||
          context.isSuccessTambahDeputyHEAD === true ||
          context.isSuccessTambahASSISTANT === true ||
          context.isSuccessTambahSTAFF === true ||
          context.isSuccessDeleteNodeSO === true ||
          context.isSuccessEditNodeSOInHEAD === true ||
          context.isSuccessEditNodeSOInSTAFF === true
        ) {
          console.log("Oke run result list after SUCCESS do something ! ");
          // console.log("dataDetail : ", context.dataDetail);

          axios
            .get(
              URL_API +
                `/human-resource/structure-position/${context.dataDetail.id}/with-child`
            ) //*?options[filter][parent_id]=
            .then((response) => {
              setLoading(false);

              console.log("Response Original : ", response);

              const copyData = { ...response.data.data };
              const listChild = [...copyData.child];

              delete copyData.child;

              let listAfterSort = listChild.sort((data1, data2) => {
                if (data1.classification_id < data2.classification_id) {
                  return -1;
                }

                if (data1.classification_id > data2.classification_id) {
                  return 1;
                }

                return 0;
              });

              let readyData = {
                ...copyData,
                child: listAfterSort,
              };
              console.log("readyData : ", readyData);

              let listReadyData = [];
              listReadyData.push(readyData);

              context.setTreeItems(listReadyData);

              //*
              context.setTreeExisting(listReadyData);
            })
            .catch((error) => {
              setLoading(false);
              console.log("Error : ", error.response);
            });
        } else {
          console.log("Data detail : ", context.dataDetail);

          axios
            .get(
              URL_API +
                `/human-resource/structure-position/${
                  context.dataDetail.parent_id !== null &&
                  context.dataDetail.parent_id !== undefined
                    ? context.dataDetail.parent_id
                    : "root"
                }/with-child`
            )
            .then((response) => {
              setLoading(false);

              console.log("Response Original : ", response);

              const copyData = { ...response.data.data };
              const listChild = [...copyData.child];

              delete copyData.child;
              // console.log("copyData after delete : ", copyData)

              let listAfterSort = listChild.sort((data1, data2) => {
                if (data1.classification_id < data2.classification_id) {
                  return -1;
                }

                if (data1.classification_id > data2.classification_id) {
                  return 1;
                }

                return 0;
              });

              let readyData = {
                ...copyData,
                child: listAfterSort,
              };
              console.log("readyData : ", readyData);

              let listReadyData = [];
              listReadyData.push(readyData);

              context.setTreeItems(listReadyData);

              //*
              context.setTreeExisting(listReadyData);
            })
            .catch((error) => {
              setLoading(false);
              console.log("Error : ", error.response);
            });
        }
      }

      /*  
                ````````````````````````````````
                VALIDASI SUCCESS BLA...BLA...BLA

                ````````````````````````````````
            */
    }
  }, [
    context.isOpenDialogTambahHead,
    context.isOpenDialogTambahDeputyHead,
    context.isOpenDialogTambahAssistant,
    context.isOpenDialogTambahStaff,

    context.isSuccessTambahHEAD,
    context.isSuccessTambahDeputyHEAD,
    context.isSuccessTambahASSISTANT,
    context.isSuccessTambahSTAFF,

    context.isSuccessDeleteNodeSO,

    context.isSuccessEditNodeSOInHEAD,
    context.isSuccessEditNodeSOInSTAFF,

    context.isOpenDialogDeleteNodeSO,

    context.isOpenDialogEditNodeSOInRootTop,
    context.isOpenDialogEditNodeSO,
    context.isOpenDialogEditNodeSOInDeputyHEAD,
    context.isOpenDialogEditNodeSOInASSISTANT,
    context.isOpenDialogEditNodeSOInSTAFF,
  ]);

  /*
        ``````````````````
        HANDLE BACK BUTTON

        ``````````````````
    */
  const handleBackButton = () => {
    // context.setShowBackButton(false);
    console.log("Context data detail : ", context.dataDetail.parent_id);

    let parentSo = localStorage.getItem("parent_so");
    let parentSoAfterParse = JSON.parse(parentSo);

    console.log("parentSoAfterParse : ", parentSoAfterParse);

    context.setTreeItems(parentSoAfterParse);

    if (
      context.dataDetail.parent_id === parentSoAfterParse[0].parent_id ||
      context.dataDetail.id === parentSoAfterParse[0].id ||
      parentSoAfterParse[0].parent_id == null
    ) {
      const userToken = localStorage.getItem("userToken");

      //*With Children
      if (userToken !== undefined) {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + userToken,
        };

        axios.defaults.headers.common = header;

        axios
          .get(URL_API + `/human-resource/structure-position/root/with-child`)
          .then((response) => {
            setLoading(false);

            console.log("Response Original : ", response);

            const copyData = { ...response.data.data };
            const listChild = [...copyData.child];

            delete copyData.child;
            // console.log("copyData after delete : ", copyData)

            let listAfterSort = listChild.sort((data1, data2) => {
              if (data1.classification_id < data2.classification_id) {
                return -1;
              }

              if (data1.classification_id > data2.classification_id) {
                return 1;
              }

              return 0;
            });

            // console.log("listAfterSort : ", listAfterSort);
            let readyData = {
              ...copyData,
              child: listAfterSort,
            };
            console.log("readyData : ", readyData);

            let listReadyData = [];
            listReadyData.push(readyData);

            context.setTreeItems(listReadyData);

            //*
            context.setTreeExisting(listReadyData);

            //**
            context.setShowBackButton(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log("Error : ", error.response);
          });
      }
    }
  };

  /*
        ``````````````````````````
        HANDLE TAB ACTIVE

            *Belum di implementasi

        ``````````````````````````
    */

  // const [ dataTabActive, setDataTabActive] = useState(
  //     {
  //         id: 1,
  //         label: 'Tree Chart',
  //         code: TAB_TREE_VIEW
  //     },
  // );

  // const handleClickChooseTab = (e, data) => {//* To see contekan TAB, in "ViewYou"

  //     e.preventDefault();

  //     console.log("Data tab : ", data)

  //     // setDataActiveOrComplete(data);
  //     setDataTabActive(data)
  // };

  return (
    <Fragment>
      <HeaderGOAL />

      {/* 
            <Grid container>

                {

                    //*HANDLE TAB ACTIVE, belum di implementasi

                    listChooseTab.length > 0 && listChooseTab.map((item, i) => {

                        return (

                            <Fragment key={i}>
                                <Grid item sm={1.25}>
                                     <Box marginLeft={theme.spacing(1.5)} marginTop={theme.spacing(1.5)}>
                                        <Button
                                            variant='outlined'
                                            classes={
                                                dataTabActive.id === item.id ?
                                                {
                                                    outlined: classes.outlinedTabActive

                                                } : {

                                                    outlined: classes.outlinedTabNotActive

                                                }
                                            }  
                                        >
                                            <Typography 
                                                variant='subtitle2'
                                                className={classes.title}
                                                onClick={(e) => handleClickChooseTab(e, item)}
                                            >
                                                <b>{item.label}</b>
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Fragment>
                        )

                    })
                }
                <Grid item sm={7}></Grid>

            </Grid> 
            <br /> 
        */}

      <br />
      <br />
      <br />

      {context.isShowBackButton === true && (
        <Box marginLeft={theme.spacing(1.4)} marginTop={theme.spacing(0.5)}>
          <IconButton onClick={handleBackButton}>
            <KeyboardBackspaceIcon />
          </IconButton>
        </Box>
      )}

      <Box marginLeft={theme.spacing(1.6)} marginTop={theme.spacing(0.3)}>
        <DataTreeView treeItems={context.treeItems} />
      </Box>

      <SnackbeerSO />
    </Fragment>
  );
};

export default ViewOrgChart;

// const listChooseTab = [

//     {
//         id: 1,
//         label: 'Tree Chart',
//         code: TAB_TREE_VIEW
//     },

//     {
//         id: 2,
//         label: 'Posisi',
//         code: TAB_POSISI
//     },

//     {
//         id: 3,
//         label: 'Karyawan',
//         code: TAB_KARYAWAN

//     },

//     {
//         id: 4,
//         label: 'User',
//         code: TAB_USER
//     }

// ];

import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";

import InfoIcon from "@material-ui/icons/Info";

import { navigate } from "hookrouter";

import DialogError from "../../../../components/DialogError";
import Capitalize from "../../../../utilities/Capitalize";
import ContextGoal from "../Context/ContextGoal";
import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import { ToGoal } from "../../../../constants/config-redirect-url";

const DialogDelete = (props) => {
  const {
    classes,
    isModalDeleteGoalMaster,
    setModalDeleteGoalMaster,
    goalDetailState,
    // goalResultId,
    // goalResultNameDescription,
    // collectionGoalResultList,
    // setCollectionGoalResultList
  } = props;

  const context = useContext(ContextGoal);

  const [userTokenState, setUserTokenState] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalDeleteGoalMaster === true) {
      const userToken = localStorage.getItem("userToken");
      setUserTokenState(userToken);
    }
  }, [isModalDeleteGoalMaster]);

  const handleDelete = () => {
    // console.log("goalResultNameDescription : ", goalResultNameDescription);

    setLoading(true);

    if (userTokenState !== "") {
      axiosConfig
        .delete(URL_API + `/swd/goal/${goalDetailState.id}`)
        .then(function (response) {
          // console.log("Response Original : ", response);
          // setCollectionGoalResultList(collectionGoalResultList.filter(item => item.id !== goalResultId));
          if (response.status === 200) {
            navigate(ToGoal);
            setModalDeleteGoalMaster(false);
            context.setOpenSnackbeer(true);
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.log("Error : ", error.response);
          setLoading(false);

          if (error.response !== undefined) {
            if (error.response.status === 403) {
              setErrorStatus(403);
              if (error.response.data.info !== null) {
                if (error.response.data.info.message !== null) {
                  setOpenDialogError(true);
                  setTextErrorInformationValue(
                    "Error 403 : " +
                      Capitalize(error.response.data.info.message)
                  );
                }
              }
            }

            if (error.response.status === 422) {
              setErrorStatus(422);
              if (error.response.data.info !== null) {
                if (
                  error.response.data.info.errors !== null &&
                  error.response.data.info.errors !== undefined
                ) {
                  if (error.response.data.info.errors.length > 0) {
                    // if(error.response.data.info.errors[0].code === 'HAS_MEASURED_ACTIVITY'){
                    setListError(error.response.data.info.errors);
                    setOpenDialogError(true);
                    // setTextErrorInformationValue('Error 422 : ' + Capitalize(error.response.data.info.errors[0].description));
                    // }
                  }
                }
              }
            }
          } else {
            console.log("Error : ", error.response);

            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  /*
       ```````````````````
       HANDLE DIALOG ERROR

       ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] = useState(
    ""
  );
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  return (
    <Fragment>
      <Dialog
        open={isModalDeleteGoalMaster}
        onClose={() => setModalDeleteGoalMaster(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          <Paper style={{ backgroundColor: "#ffcbcb" }}>
            <Grid container>
              <Grid item sm={1}>
                <Box marginLeft={1} marginTop={1}>
                  <InfoIcon style={{ color: "white" }} />
                </Box>
              </Grid>
              <Grid item sm={11}>
                <Box marginTop={1}>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ color: "black" }}>
                    <i style={{ color: "red" }}>
                      Sekali Anda menghapus <i>Goal</i>, maka data{" "}
                      <i>Goal Result</i> dan <i>Measure Activity</i> akan hilang
                      !
                    </i>
                    <br />
                    <b style={{ color: "red" }}>
                      (Pastikan Anda benar- benar paham konsekuensi-nya.)
                    </b>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "black" }}>
              <b>
                Apakah Anda yakin ingin menghapus Goal :
                <i>
                  {goalDetailState.member !== null
                    ? goalDetailState.member.first_name + "-"
                    : "-"}
                  {goalDetailState.member !== null
                    ? goalDetailState.member.last_name
                    : ""}
                </i>{" "}
                ?
              </b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            onClick={handleDelete}
            variant="contained"
            className={classes.buttonModalDelete}>
            {loading ? <CircularProgress color="#d1354a" size={17} /> : "Hapus"}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformationValue}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
};

export default DialogDelete;

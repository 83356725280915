import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Chip,
  Button,
  IconButton,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import LensIcon from "@material-ui/icons/LensOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import LightTooltip from "../../../../components/LightTooltip";

function ListEmployee({
  n,
  classes,
  isSuperAdmin,
  setIdEmployee,
  handleClickDetail,
  setEmployeeName,
  setModalDelete,
}) {
  const [isShowIconDelete, setShowIconDelete] = useState({
    index: null,
    show: false,
  });

  return (
    <TableRow
      key={n.id}
      // onClick={event => handleClick(event, n.id)}
      hover
      onMouseOver={() => setShowIconDelete({ index: n.id, show: false })}
      onMouseLeave={() => setShowIconDelete({ index: n.id, show: true })}

      // role="checkbox"
      // aria-checked={ isItemSelected }
      // tabIndex={-1}
      // selected={ isItemSelected }
    >
      {/* <TableCell padding="checkbox">
            <Checkbox checked={isItemSelected} />
        </TableCell> */}

      <TableCell component="th" scope="row" padding="none">
        <LensIcon className={classes.lensIcon} />
      </TableCell>

      <TableCell>
        {n.member.status.code === "member_status_id_enum_active" ? (
          <Chip
            icon={<DoneIcon style={{ color: "white" }} />}
            label="Aktif"
            className={classes.chipVerifified}
            style={{
              backgroundColor: "#55dc87",
              color: "white",
            }}
          />
        ) : null}
      </TableCell>

      <TableCell className={classes.title}>
        {n.member.role.length > 0 ? (
          <LightTooltip
            open={true}
            title={n.member.role[0].name === "superadmin" ? "Superadmin" : ""}
            placement="right-start">
            <span>
              {n.member.first_name !== null
                ? n.member.first_name
                : n.member.email}
            </span>
          </LightTooltip>
        ) : (
          <span>
            {n.member.first_name !== null
              ? n.member.first_name
              : n.member.email}
          </span>
        )}
      </TableCell>

      <TableCell align="left" className={classes.title}>
        {n.member !== null ? n.member.email : "-"}
      </TableCell>

      <TableCell className={classes.title}>
        {n.employee_number !== null ? n.employee_number : "-"}
      </TableCell>

      <TableCell align="left" className={classes.title}>
        {n.position !== null ? n.position.structure_position_title_name : "-"}
      </TableCell>

      <TableCell align="left" className={classes.title}>
        <Button
          disabled={!isSuperAdmin}
          onClick={(e) => handleClickDetail(e, n.id)}
          variant="outlined"
          color="secondary"
          className={classes.buttonDetail}>
          Lihat Detail
        </Button>
      </TableCell>

      <TableCell align="left" className={classes.title}>
        {isShowIconDelete.index === n.id ? (
          <IconButton
            disabled={!isSuperAdmin}
            onClick={() => {
              setIdEmployee(n.id);
              setModalDelete(true);
              setEmployeeName(
                `${
                  n.member !== null
                    ? n.member.first_name + " " + n.member.last_name
                    : ""
                }`
              );
              console.log(n);
            }}
            color="secondary">
            <DeleteIcon />
          </IconButton>
        ) : null}
      </TableCell>
    </TableRow>
  );
}

export default ListEmployee;

import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { A } from "hookrouter";
import logoPerformate from "../../../assets/images/performate-03.png";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  textParagraph: {
    color: "#888",
    textAlign: "justify",
  },
});

const useStyles = makeStyles(styles);

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <div>
      <section style={{ paddingTop: 85, textAlign: "center" }}>
        <div className="box-about">
          <img src={logoPerformate} width="50px" />
          <h1>Term and Conditions</h1>
        </div>
      </section>
      <Container maxWidth="md" style={{ marginTop: 20 }}>
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Perusahaan Kami mengoperasikan{" "}
          <A href="http://performate.id">http://performate.id</A>. Halaman ini
          memberi tahu anda tentang kebijakan kami mengenai pengumpulan,
          penggunaan, dan pengungkapan Informasi pribadi yang kami terima dari
          pengguna situs dan aplikasi.
        </Typography>
        <br />
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Kami menggunakan Informasi Pribadi Anda hanya untuk menyediakan
          kebutuhan anda. Dengan menggunakan situs dan aplikasi anda menyetujui
          pengumpulan dan penggunaan informasi sesuai dengan kebijakan ini.
        </Typography>
        <br />
        <Typography variant="subtitle1">
          <b>Information Collection And Use</b>
        </Typography>

        <Typography variant="subtitle2" className={classes.textParagraph}>
          Saat menggunakan situs dan aplikasi kami, kami mungkin meminta anda
          untuk memberikan kami informasi pengenal pribadi tertentu yang dapat
          digunakan untuk menghubungi atau mengidentifikasi anda agar aplikasi
          kami dapat bermanfaat bagi anda dan mambantu anda mencapai tujuan,
          Informasi pribadi tersebut antara lain:
          <ol key="1">
            <li>Informasi umum perusahaan</li>
            <li>Data karyawan &amp; struktur organisasi</li>
            <li>Data Goal sebagai konfirmasi validitas penginputan</li>
            <li>Data MA yang mencakup strategi perusahaan</li>
            <li>Data pencapaian perusahaan</li>
            <li>Data pencapaian individu</li>
          </ol>
        </Typography>
        <br />

        <Typography variant="subtitle1">
          <b>Log Data</b>
        </Typography>
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Kami mengumpulkan informasi yang dikirimkan browser anda setiap kali
          anda mengunjungi Situs kami (&quot;Log Data&quot;). Data Log ini dapat
          mencakup informasi seperti alamat Internet Protocol (&quot;IP&quot;)
          komputer Anda, jenis perangkat, jenis browser, versi browser, waktu
          kunjungan, dan waktu yang dihabiskan selama berada di situs dan
          aplikasi kami.
        </Typography>
        <br />

        <Typography variant="subtitle1">
          <b>Communications</b>
        </Typography>
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Kami dapat menggunakan informasi pribadi anda untuk menghubungi anda
          dengan buletin, materi pemasaran atau promosi dan informasi lain.
        </Typography>
        <br />

        <Typography variant="subtitle1">
          <b>Cookies</b>
        </Typography>
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Cookie adalah bagian kecil dari data yang dikirim dari sebuah situs
          web dan disimpan dalam komputer pengguna oleh peramban web ketika
          pengguna tersebut sedang membuka halaman web. Cookie dikirim ke
          browser anda dari situs web dan disimpan di hard drive komputer anda.
        </Typography>
        <br />

        <Typography variant="subtitle2" className={classes.textParagraph}>
          Kami menggunakan &quot;cookie&quot; untuk mengumpulkan informasi yang
          dibutuhkan oleh sistem agar dapat membantu anda dalam menjalankan
          aplikasi. Anda dapat menginstruksikan browser anda untuk menolak semua
          cookie atau untuk menunjukkan kapan cookie dikirim. Namun, jika anda
          tidak menerima cookie, Anda mungkin tidak dapat menggunakan beberapa
          bagian dari Situs kami.
        </Typography>
        <br />

        <Typography variant="subtitle1">
          <b>Security</b>
        </Typography>
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Keamanan informasi pribadi anda penting bagi kami, kami berusaha untuk
          menggunakan sarana yang terbaik untuk melindungi informasi pribadi
          anda.
        </Typography>
        <br />

        <Typography variant="subtitle1">
          <b>Changes To This Privacy Policy</b>
        </Typography>
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Kebijakan Privasi ini berlaku sejak 17 Juli 2020 dan akan tetap
          berlaku kecuali sehubungan dengan perubahan dalam ketentuannya di masa
          depan, yang akan berlaku segera setelah diposting di halaman atau file
          ini. Kami berhak memperbarui atau mengubah Kebijakan Privasi kami
          kapan saja dan anda harus memeriksa Kebijakan Privasi ini secara
          berkala di halaman situs yang kami sediakan. Penggunaan berkelanjutan
          atas layanan setelah kami memposting Kebijakan Privasi terbaru
          merupakan pengakuan dan persetujuan anda atas Kebijakan Privasi yang
          diperbaharui.
        </Typography>
        <br />

        <Typography variant="subtitle2" className={classes.textParagraph}>
          Jika kami membuat perubahan materi pada Kebijakan Privasi ini, kami
          akan memberi tahu Anda baik melalui alamat email yang Anda berikan
          kepada kami, atau dengan menempatkan pemberitahuan penting di situs
          web kami.
        </Typography>
        <br />

        <Typography variant="subtitle1">
          <b>Hubungi kami</b>
        </Typography>
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Jika ada pertanyaan mengenai kebijakan ini silahkan hubungi kami
          melalui email <A href="mailto:cs@performate.id">cs@performate.id</A>
        </Typography>
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default PrivacyPolicy;

import React, { useState, useContext, useEffect } from "react";
import { Button, Menu, MenuItem, Badge, Box, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import TrackChangesOutlinedIcon from "@material-ui/icons/TrackChangesOutlined";
import ListItemEntities from "./sub-components/ListItemEntities";
import ContextReports from "../../../../context/ContextReports";
import {
  GET_DATA_TABLE,
  GET_FIELD_PRESET,
  SET_PAGINATING,
  TOGGLE_DRAWER_CHART,
  // selected summary
  HIT_ENTITY,
  GET_BAR_CHART,
  GET_PIE_CHART,
  GET_AVG_COMPANY,
} from "../../../../context/types";

import handleError from "../../../global/handleError";
import AxiosConfig from "../../../../../../constants/config-axios";
import { URL_API } from "../../../../../../constants/config-api";
import DialogError from "../../../../../../components/DialogError";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#d1354a",
    color: "#fff",
  },
}))(Badge);

const ConfigEntities = ({ classes }) => {
  const {
    summaryState,
    tableSmrState,
    summaryDispatch,
    tableSmrDispatch,
    reportDispatch,
    barDispatch,
    pieDispatch,
  } = useContext(ContextReports);

  // =================== State ===================
  const [anchorEntities, setAnchorEntities] = useState(null);
  const [selectedEntities, setSelectedEntities] = useState(
    summaryState.selectedEntities
  );

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const isSelectedEntities = (id) => selectedEntities.indexOf(id) !== -1;

  // ===================== SIDE EFFECT(S) =====================
  useEffect(() => {
    summaryDispatch({
      type: HIT_ENTITY,
      payload: { selectedEntities },
    });
  }, [selectedEntities]);

  // ERROR STATE
  const handleShowAnchorEntities = (event) => {
    setAnchorEntities(event.currentTarget);
  };
  const handleCloseAnchorEntities = () => {
    setAnchorEntities(null);
  };

  const handleShowResults = () => {
    // ~~~~~~~~~~~ Data Pagination From Context ~~~~~~~~~~~
    const pagination = tableSmrState.paginating;
    const currentPage = pagination.currentPage;
    const perPage = pagination.perPage;

    // Convet achievement
    const calMethodGoal =
      summaryState.selectedAchievements.length > 0
        ? summaryState.selectedAchievements.map((item) => {
            return {
              formula: item,
            };
          })
        : [];
    const calMethodMa =
      summaryState.selectedAchievementsMa.length > 0
        ? summaryState.selectedAchievementsMa.map((item) => {
            return {
              formula: item,
            };
          })
        : [];

    const achievementGoal = {
      field: "goal",
      cal_method: calMethodGoal,
    };
    const achievementMa = {
      field: "ma",
      cal_method: calMethodMa,
    };

    const refGoal = [].concat(
      achievementGoal.cal_method.length > 0 ? achievementGoal : []
    );
    const refMa = [].concat(
      achievementMa.cal_method.length > 0 ? achievementMa : []
    );

    const newAchievement = [...refGoal, ...refMa];

    // ~~~~~~~~~~~ Data POST Summary ~~~~~~~~~~~
    const dataSummary = {
      name: "Table",
      platform: "web",
      report_format: "table",
      filter_by: {
        entity: [],
        date_range: summaryState.dateRange.code,
        user: summaryState.selectedUsers,
        unit: summaryState.selectedUnits,
        status: summaryState.selectedStatus,
        achievement: newAchievement,
      },
      entities: selectedEntities,
      group_by: [summaryState.selectedGroup.field],
      group_by_calculation: summaryState.selectedCalc.code,
      order_by: [summaryState.selectedOrder],
      primary_column: summaryState.selectedFields.code,
      unit_type_id: summaryState.selectedUnit.id,
      limit_count_number: 0,
      week_start: "monday",
      date_format: "d m Y",
      summary_only: false,
    };

    tableSmrDispatch({
      type: GET_DATA_TABLE,
      payload: {
        dataTable: [],
        loading: true,
      },
    });

    // ================= GET INITIAL DATA TABLE SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataSummary
    )
      .then((res) => {
        const result = res.data.data;
        // console.log("RES SUMMARY TABLE", result);

        const paginating = {
          currentPage: result.results.current_page,
          perPage: result.results.per_page,
          lastPage: result.results.last_page,
          totalRecords: result.results.total,
        };

        if (res.status === 200) {
          tableSmrDispatch({
            type: GET_FIELD_PRESET,
            payload: { fieldPreset: result.settings.field_preset },
          });

          setTimeout(() => {
            tableSmrDispatch({
              type: GET_DATA_TABLE,
              payload: {
                dataTable: result.results.data,
                loading: false,
              },
            });
          }, 1000);

          tableSmrDispatch({
            type: SET_PAGINATING,
            payload: { paginating },
          });
        }
      })
      .catch((error) => {
        console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        tableSmrDispatch({
          type: GET_DATA_TABLE,
          payload: {
            dataTable: [],
            loading: false,
          },
        });
      });

    //============= Datat For Ovaerge Company =============
    const dataAvg = {
      ...dataSummary,
      report_format: "summary",
    };

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataAvg
    )
      .then((res) => {
        const result = res.data.data;
        // console.log("RES SUMMARY BAR", result);

        if (res.status === 200) {
          summaryDispatch({
            type: GET_AVG_COMPANY,
            payload: {
              averages: result.results,
            },
          });
        }
      })
      .catch((error) => {
        console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });

    //============= Datat For Bar Chart =============
    const dataBar = { ...dataSummary, report_format: "bar_chart" };

    barDispatch({
      type: GET_BAR_CHART,
      payload: {
        barData: [],
        barPreset: [],
        loading: true,
      },
    });

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataBar
    )
      .then((res) => {
        const result = res.data.data;
        // console.log("RES SUMMARY BAR", result);

        if (res.status === 200) {
          barDispatch({
            type: GET_BAR_CHART,
            payload: {
              barData: result.results.data,
              barPreset: result.settings.field_preset,
              loading: false,
            },
          });
        }
      })
      .catch((error) => {
        console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        barDispatch({
          type: GET_BAR_CHART,
          payload: {
            barData: [],
            barPreset: [],
            loading: true,
          },
        });
      });

    //============= Datat For Pie Chart =============
    const dataPie = {
      ...dataSummary,
      report_format: "pie_chart",
      order_by: [],
    };

    pieDispatch({
      type: GET_PIE_CHART,
      payload: {
        pieDataGoal: [],
        pieDataMa: [],
        piePreset: [],
        loading: true,
      },
    });

    // console.log("dataPie", dataPie);

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataPie
    )
      .then((res) => {
        const result = res.data.data;
        // console.log("RES SUMMARY BAR", result);
        const pieGoal = result.results.filter((el) => el.type === "goal");
        const pieMa = result.results.filter((el) => el.type === "ma");

        if (res.status === 200) {
          reportDispatch({ type: TOGGLE_DRAWER_CHART, payload: false });
          pieDispatch({
            type: GET_PIE_CHART,
            payload: {
              pieDataGoal: pieGoal,
              pieDataMa: pieMa,
              piePreset: result.settings.field_preset,
              loading: false,
            },
          });
        }
      })
      .catch((error) => {
        console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        pieDispatch({
          type: GET_PIE_CHART,
          payload: {
            pieDataGoal: [],
            pieDataMa: [],
            piePreset: [],
            loading: false,
          },
        });
      });

    handleCloseAnchorEntities();
  };

  return (
    <>
      <StyledBadge
        badgeContent={selectedEntities.length}
        children={
          <Button
            size="small"
            variant="outlined"
            className={classes.configBtn}
            startIcon={<TrackChangesOutlinedIcon style={{ fontSize: 15 }} />}
            onClick={handleShowAnchorEntities}>
            Entities
          </Button>
        }
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEntities}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
        }}
        transformOrigin={{
          vertical: "top",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorEntities)}
        onClose={handleCloseAnchorEntities}>
        {summaryState.entities.length > 0 &&
          summaryState.entities.map((item, i) => (
            <MenuItem key={i} style={{ padding: 0, width: 200 }} dense>
              <ListItemEntities
                classes={classes}
                index={i}
                entity={item}
                selected={selectedEntities}
                setSelected={setSelectedEntities}
                isSelected={isSelectedEntities}
              />
            </MenuItem>
          ))}

        <Box p={2}>
          <Divider />
        </Box>
        <Box mr={2} mb={1} display="flex" justifyContent="flex-end">
          <Button
            size="small"
            variant="outlined"
            className={classes.button0}
            onClick={handleCloseAnchorEntities}>
            Cancel
          </Button>
          <Button
            size="small"
            variant="outlined"
            className={classes.button1}
            style={{ marginLeft: 7.5 }}
            onClick={handleShowResults}>
            Show results
          </Button>
        </Box>
      </Menu>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default ConfigEntities;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { navigate } from "hookrouter";

import boxGray from "../../../assets/images/landing/box-gray.png";
import logoMadinahCare from "../../../assets/images/landing/logo-madinah-care.PNG";
import logoMadinahFlash from "../../../assets/images/landing/logo-madinah-flash.PNG";
import logoMadinahGroup from "../../../assets/images/landing/logo-madinah-group.PNG";
import logoMadinahMultimedia from "../../../assets/images/landing/logo-madinah-multimedia.PNG";
import logoMadinahStore from "../../../assets/images/landing/logo-madinah-store.PNG";
import mobilePreview from "../../../assets/images/landing/mobile-preview.PNG";

import headerImg01 from "../../../assets/images/landing/header-img-11.svg";
import headerImg02 from "../../../assets/images/landing/header-img-12.svg";
import headerImg03 from "../../../assets/images/landing/header-img-21.svg";
import headerImg04 from "../../../assets/images/landing/header-img-22.svg";

import benefit01 from "../../../assets/images/landing/benefit-01.svg";
import benefit02 from "../../../assets/images/landing/benefit-02.svg";
import benefit03 from "../../../assets/images/landing/benefit-03.svg";
import benefit04 from "../../../assets/images/landing/benefit-04.svg";
import benefit05 from "../../../assets/images/landing/benefit-05.svg";

import imgBusiness from "../../../assets/images/landing/img-business.svg";
import imgFamily from "../../../assets/images/landing/img-family.svg";
import imgEducation from "../../../assets/images/landing/img-education.svg";
import imgPerson from "../../../assets/images/landing/img-person.svg";

import imgDemo1 from "../../../assets/images/landing/demo-01.svg";
import imgDemo2 from "../../../assets/images/landing/demo-02.svg";
import imgDemo3 from "../../../assets/images/landing/demo-03.svg";
import imgDemo4 from "../../../assets/images/landing/demo-04.svg";

import imgButtonGooglePlaystore from "../../../assets/images/landing/image-button-playstore.svg";
import DialogPaidPayment from "./components/DialogPaidPayment";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  imageLogo: {
    verticalAlign: "text-bottom",
    width: 35,
    height: 35,
  },
  centerLayout: {
    textAlign: "center",
  },
  textLowerCase: {
    textTransform: "none",
  },
  alternateBackground: {
    backgroundColor: "#f5f5f5",
  },
  bottomBackground: {
    backgroundColor: "#d1364a",
    color: "#ffffff",
  },
  benefitIconItem: {
    width: "100%",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    display: "table",
    textAlign: "center",
    boxShadow: "0px",
  },
  title: {
    fontFamily: "Roboto",
  },
  txtSummary: {
    color: "#555",
    marginBottom: 10,
    fontFamily: "Roboto",
  },
  button1: {
    borderRadius: 5,
    background: "#d1354a",
    border: 0,
    color: "#fff",
    fontFamily: "Roboto",
    textTransform: "capitalize",
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: "#f28181",
    },
  },
  // ========================================
  // Style For Checkout Register
  cardCheckout: {
    display: "flex",
    backgroundColor: "#102770",
    borderRadius: 20,
  },
  cardImgCont: {
    position: "relative",
    textAlign: "center",
    color: "#fff",
  },
  textBadge: {
    color: "#fff",
    backgroundColor: "#eb4d4b",
    fontFamily: "Roboto",
    width: 95,
    borderBottomRightRadius: 40,
    borderTopLeftRadius: 7.5,
    padding: `2px 10px`,
    fontWeight: "600",
    position: "absolute",
  },
  txtCenterCard: {
    left: 52,
    position: "absolute",
    top: 52,
    fontFamily: "Roboto",
  },
  txtCenterCard1: {
    left: 30,
    position: "absolute",
    top: 52,
    fontFamily: "Roboto",
  },
  txtSummary2: {
    color: "#555",
    marginBottom: 10,
    fontFamily: "Roboto",
  },
  txtCartItem: {
    color: "#fff",
    fontFamily: "Roboto",
  },
});

const useStyles = makeStyles(styles);

const Main = (props) => {
  //const { classes } = props;
  const classes = useStyles();
  const [openDialogPaidPayment, setOpenDialogPaidPayment] = useState(false);

  return (
    <main>
      <section
        className={classes.alternateBackground}
        style={{ paddingTop: "0px" }}>
        <h2>
          Anda memiliki banyak goal, tetapi menetapkan strategi yang salah
        </h2>
        <div className="wrapper-strategy">
          <div>
            <img src={headerImg01} width="20%" />
            <p style={{ padding: "0px", margin: "0px" }}>
              Kesulitan mencari penyebab
              <br />
              bisnis tidak profit ?
            </p>
          </div>

          <div>
            <img src={headerImg02} width="20%" />
            <p style={{ padding: "0px", margin: "0px" }}>
              Kesulitan menilai kinerja
              <br />
              perusahaan ?
            </p>
          </div>

          <div>
            <img src={headerImg03} width="20%" />
            <p style={{ padding: "0px", margin: "0px" }}>
              Tidak mengetahui aktivitas dari
              <br />
              karyawan anda ?
            </p>
          </div>

          <div>
            <img src={headerImg04} width="20%" />
            <p style={{ padding: "0px", margin: "0px" }}>
              Tidak disiplin terhadap komitmen
              <br />
              yang sudah anda buat
            </p>
          </div>
        </div>
      </section>

      <section className="intro">
        <h2>Dapatkan banyak Beneﬁt</h2>
        <br />
        <div className="services">
          <div className="services-div">
            <div
              className={classes.benefitIconItem}
              style={{ backgroundColor: "#829cf3", padding: "35px 0px" }}>
              <img src={benefit01} width="30%" />
            </div>
            <p>Memonitor goal tim anda dengan mudah secara online</p>
          </div>
          <div className="services-div">
            <div
              className={classes.benefitIconItem}
              style={{ backgroundColor: "#2cd18f", padding: "29px 0px" }}>
              <img src={benefit02} width="30%" />
            </div>
            <p>Mengawal eksekusi dari strategi yang telah direncanakan.</p>
          </div>
          <div className="services-div">
            <div
              className={classes.benefitIconItem}
              style={{ backgroundColor: "#e2ae54", padding: "30px 0px" }}>
              <img src={benefit03} width="30%" />
            </div>
            <p>
              Menyelaraskan program kerja seluruh elemen perusahaan dengan
              tujuan, visi & misi
            </p>
          </div>
          <div className="services-div">
            <div
              className={classes.benefitIconItem}
              style={{ backgroundColor: "#f579af", padding: "25px 0px" }}>
              <img src={benefit04} width="20%" />
            </div>
            <p>Meningkatkan produktivitas karyawan</p>
          </div>
          <div className="services-div">
            <div
              className={classes.benefitIconItem}
              style={{ backgroundColor: "#a5c9d4", padding: "36px 0px" }}>
              <img src={benefit05} width="30%" />
            </div>
            <p>Mendeteksi penumpang gelap dalam bisnis anda</p>
          </div>
        </div>
      </section>

      <section className={classes.alternateBackground}>
        <h2>Siapa yang direkomendasikan menggunakan solusi ini?</h2>
        <br />
        <div className="recommended">
          <div style={{ padding: "20px 0px" }}>
            <img src={imgBusiness} width="30%" /> <br /> <br />
            <span
              style={{ fontWeight: "900", fontSize: "18px", color: "#333333" }}>
              Bisnis
            </span>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <img src={imgFamily} width="40%" /> <br /> <br />
            <span
              style={{ fontWeight: "900", fontSize: "18px", color: "#333333" }}>
              Keluarga
            </span>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <img src={imgEducation} width="40%" /> <br /> <br />
            <span
              style={{ fontWeight: "900", fontSize: "18px", color: "#333333" }}>
              Pendidikan
            </span>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <img src={imgPerson} width="11%" /> <br />
            <span
              style={{ fontWeight: "900", fontSize: "18px", color: "#333333" }}>
              Personal
            </span>
          </div>
        </div>
      </section>

      <section>
        <div className="grid-view-demo">
          <div className="text-align-demo-title">
            <h3 className="demo-title">Tentukan Goals Jangka panjang anda</h3>
            <p>
              Sebuah goal dapat membantu anda untuk fokus mencapai suatu tujuan
              yang anda miliki dengan sasaran dan rentang waktu yang telah anda
              rencanakan.
            </p>
          </div>
          <div>
            <img src={imgDemo1} width="100%" />
          </div>
        </div>
      </section>

      <section className={classes.alternateBackground}>
        <div className="grid-view-demo">
          <div>
            <img src={imgDemo2} width="100%" />
          </div>
          <div className="text-align-demo-title">
            <h3 className="demo-title">
              Buat strategi untuk mencapai goal anda
            </h3>
            <p>
              Dengan membuat goal yang jelas, anda bisa menentukan strategi apa
              saja yang akan dilakukan untuk mencapai sebuah goal dalam waktu
              yang telah anda tentukan.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid-view-demo">
          <div className="text-align-demo-title">
            <h3 className="demo-title">Berbagi goal dengan tim anda</h3>
            <p>
              Memiliki banyak goal akan menimbulkan hilangnya fokus seorang
              leader. Dengan Performate, seorang leader bisa berbagi tugas
              dengan timnya.
            </p>
          </div>
          <div>
            <img src={imgDemo3} width="100%" />
          </div>
        </div>
      </section>

      <section className={classes.alternateBackground}>
        <div className="grid-view-demo">
          <div>
            <img src={imgDemo4} width="100%" />
          </div>
          <div className="text-align-demo-title">
            <h3 className="demo-title">
              Monitor performance tim anda dalam 5 detik
            </h3>
            <p>
              Performate bisa membantu anda untuk mengeatur dan memantau
              aktifitas, pencapaian dan kinerja tim anda.
            </p>
          </div>
        </div>
      </section>

      <section className="testimony">
        <div>
          <p>Customer Testimony</p>
        </div>
      </section>

      <section>
        <h3 className="text-client-title">Klien dan Partner</h3> <br />
        <div className="box-client">
          <div>
            <img src={logoMadinahGroup} width="50%" />
          </div>

          <div>
            <img src={logoMadinahStore} width="50%" />
          </div>

          <div>
            <img src={logoMadinahFlash} width="100%" />
          </div>

          <div>
            <img src={logoMadinahCare} width="70%" />
          </div>

          <div>
            <img src={logoMadinahMultimedia} width="80%" />
          </div>
        </div>
      </section>

      <section>
        <div className="box-tour">
          <h3>Ikuti Tur</h3>
          <p>
            Ingin mempelajari lebih banyak? Atur jadwal demo bersama kami untuk
            memandu dan menjawab pertanyaan anda dengan menggunakan platform
            secara langsung
          </p>
          <Button
            onClick={() => navigate("/home/demo-schedule")}
            variant="outlined"
            color="primary"
            disableElevation
            className={classes.textLowerCase}>
            Atur demo
          </Button>
        </div>
      </section>

      <section>
        <div className="box-mobile">
          <div>
            <img src={mobilePreview} width="100%" />
          </div>

          <div className="box-mobile-right">
            <br />
            <h3>Tetap fokus dan perbarui dengan mudah</h3>
            <p>
              Pantau perkembangan goal anda di mana saja, kapan saja di tangan
              anda
            </p>{" "}
            <br />
            <img src={imgButtonGooglePlaystore} width="120px" />
          </div>
        </div>
      </section>

      <section className={classes.bottomBackground}>
        <span className="bottom-main-title">
          Get better goals results today
        </span>
        <br />
        <br />
        <Button
          onClick={() => navigate("/register")}
          variant="contained"
          color="primary"
          disableElevation
          className={classes.textLowerCase}
          style={{ width: "160px", margin: "0 auto" }}>
          TRY IT FOR FREE
        </Button>
      </section>

      {/* <DialogPaidPayment
        openDialogPaidPayment={openDialogPaidPayment}
        setOpenDialogPaidPayment={setOpenDialogPaidPayment}
        classes={classes}
      /> */}
    </main>
  );
};

export default Main;

import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  Fragment,
  useRef,
} from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  InputBase,
  Chip,
  Tabs,
  Tab,
} from "@material-ui/core";

import { useDebouncedCallback } from "use-debounce";

import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";

import ContextGoal from "../Context/ContextGoal";

import Snackbeer from "../../../../components/Snackbeer";
import ImageSmallUser from "../../../../components/ImageSmallUser";

import ListStatusGoalDraft from "../ComponentsStatusGoal/Draft/ListStatusGoalDraft";
import ListStatusGoalApproved from "../ComponentsStatusGoal/Approved/ListStatusGoalApproved";
import ListStatusGoalActive from "../ComponentsStatusGoal/Active/ListStatusGoalActive";
import ListStatusGoalOverdue from "../ComponentsStatusGoal/Overdue/ListStatusGoalOverdue";
import ListStatusGoalComplete from "../ComponentsStatusGoal/Complete/ListStatusGoalComplete";

import DialogBiggerPictInAllAndYou from "../Components/DialogBiggerPictInAllAndYou";
import DialogExportGoal from "../Components/DialogExportGoal";
import ButtonWeight from "./components/ButtonWeight";

import { styles } from "../Style/StyleGoal";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import {
  STATUS_DRAFT,
  STATUS_APPROVED,
  STATUS_ACTIVE,
  STATUS_OVERDUE,
  STATUS_COMPLETED,
} from "../../../../constants/config-status-goal";

import PictREFRESH from "../../../../assets/images/Group_2725.png";

import "react-circular-progressbar/dist/styles.css";
import "../Style/custom.css";

const useStyle = makeStyles(styles);

const ViewYou = (props) => {
  const context = useContext(ContextGoal);
  const classes = useStyle();

  let inputRefSearch = useRef(null);

  /*
      ```````````````````
      COMPONENT DID MOUNT

      ```````````````````
  */
  const userToken = localStorage.getItem("userToken");
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);

  const [userTokenState, setUserTokenState] = useState("");

  const [fotoQuery, setFotoQuery] = useState("");

  //*Snackbeer function
  const [isFormFilledAll, setFormFilledAll] = useState(false);

  // Goal Selected Multiple
  // const [selected, setSelected] = useState([]);

  useEffect(() => {
    setUserTokenState(userToken);
    // context.setLoader(true);

    if (statusUserLoginAfterParse !== null) {
      if (statusUserLoginAfterParse.member_first_name !== null) {
        context.setUserLoginName(
          statusUserLoginAfterParse.member_first_name +
            " " +
            statusUserLoginAfterParse.member_last_name
        );
        setFotoQuery(statusUserLoginAfterParse.member_photo_url);
      }
      if (statusUserLoginAfterParse.member_position !== null) {
        context.setMemberPositionList(
          statusUserLoginAfterParse.member_position
        );
      }
      /*
                `````````````````````````````````````````````````   
                HANDLE KETIKA TIDAK MEMILIKI NAMA DEPAN /BELAKANG
    
                `````````````````````````````````````````````````   
            */
      if (
        statusUserLoginAfterParse.member_first_name == null &&
        statusUserLoginAfterParse.member_last_name == null
      ) {
        setFormFilledAll(true);
      }
    }

    /*
            `````````````````````````````````
            HANDLE INTERAKSI KETIKA SEARCHING
            
            `````````````````````````````````
        */
    const valueSearch = localStorage.getItem("value_search");

    if (valueSearch !== null && valueSearch !== undefined) {
      context.setSearchRun(true);
      context.setValueSearchState(valueSearch);
    } else {
      context.setSearchRun(false);
      context.setValueSearchState("");
    }

    const statusGoalId = localStorage.getItem("status_goal_id");
    const locationTab = localStorage.getItem("location_tab");

    // if(context.dataTabActive.id === 2){ //   label: 'You'
    if (locationTab === "You") {
      //   label: 'You'

      if (userToken !== undefined) {
        axiosConfig
          .get(
            URL_API +
              `/swd/goal?options[filter][scope]=self&options[filter][status_id]=${
                statusGoalId !== null ? statusGoalId : 3
              }`
          )
          .then(function (response) {
            context.setLoader(false);
            console.log("Response Original COLLECTION GOAL : ", response);

            if (response.status === 200) {
              if (response.data.data !== null) {
                context.setCollectionGoalList(response.data.data);
                context.setLengthGoalCount(response.data.info.count);
              }
            }
          })
          .catch(function (error) {
            context.setLoader(false);
            console.log("Error : ", error);
          });
      }
    }
  }, [
    context.isOpenSnackbeer,
    context.successComplete,
    context.successCreateGoal,
  ]);

  /*    
        ``````````````````````````````````````````````````
        HANDLE COLLECTION BASED ON TAB SELECTED AND SEARCH

        ``````````````````````````````````````````````````
      */

  //   const [isFeatureSearchRun, setFeatureSearchRun ] = useState(false);

  useEffect(() => {
    context.setLoader(true);
    // setUserTokenState(userToken);

    /*
          `````````````````````````````````
          HANDLE INTERAKSI KETIKA ADA DATA SEARCH
          
          `````````````````````````````````
      */
    const valueSearch = localStorage.getItem("value_search");

    if (context.dataTabActive.id === 2) {
      //   label: 'You'

      if (userToken !== undefined) {
        if (valueSearch !== null && valueSearch !== undefined) {
          console.log("wwwwww in Tab You, when SEARCH !");
          context.setSearchRun(true);

          axiosConfig
            .get(
              URL_API +
                `/swd/goal?options[filter][search]=${valueSearch}&options[filter][status_id]=${context.statusGoalIdInYou}&options[filter][scope]=self`
            )
            .then(function (response) {
              context.setLoader(false);

              if (response.status === 200) {
                if (response.data.data !== null) {
                  context.setCollectionGoalList(response.data.data);
                }
              }
            })
            .catch(function (error) {
              context.setLoader(false);
              console.log("Error : ", error.response);
            });
        } else {
          context.setSearchRun(false);
        }
      } else {
        console.log("No Access Token available!");
      }
    }
  }, [
    context.statusGoalIdInYou,
    context.dataTabActive,
    // context.valueTabStatusGoalInYou
  ]);

  /*
        ``````````````````````````
        HANDLE DIALOG MODAL CREATE 
        
        ``````````````````````````             
    */
  // const [ isModalCreate, setModalCreate ] = useState(false);
  const handleDialogCreate = () => {
    context.setModalCreate(true);

    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");
  };

  /*
        ````````````
        Search  GOAL

        ````````````
    */

  const [handleSearch, cancel] = useDebouncedCallback(
    useCallback((value) => {
      context.setLoader(true);

      const statusGoalId = localStorage.getItem("status_goal_id");
      // console.log("Status Goal Id : ", statusGoalId);

      // console.log("I want to know goal : ", value);

      localStorage.setItem("value_search", value);

      if (userToken !== undefined) {
        axiosConfig
          .get(
            `${URL_API}/swd/goal?options[filter][search]=${value}&options[filter][status_id]=${statusGoalId}&options[filter][scope]=self`
          )
          .then(function (response) {
            context.setLoader(false);
            console.log("Response Original Goal from search : ", response);

            if (response.status === 200) {
              if (response.data.data !== null) {
                context.setSearchRun(true);
                context.setCollectionGoalList(response.data.data);
              }
            }
          })
          .catch(function (error) {
            context.setLoader(false);
            localStorage.removeItem("value_search");
            console.log("Error : ", error.response);
          });
      } else {
        console.log("No Access Token available!");
      }
    }, []),
    1000,

    { maxWait: 5000 }
  );

  /* =======================================================
    Handle Disable Create Goal depending on status code 403
  ======================================================= */
  const [disableCreateGoal, setDisableCreateGoal] = useState(false);

  useEffect(() => {
    if (userToken !== undefined) {
      axiosConfig
        .get(`${URL_API}/swd/goal/create`)
        .then((response) => {
          // console.log("Response Create Goal", response);
        })
        .catch((error) => {
          console.log("Error : ", error);
          if (error.response !== undefined) {
            if (error.response.status === 403) {
              setDisableCreateGoal(true);
            }
          }
        });
    } else {
      console.log("User Token Not Exist");
    }
  }, []);

  /*
        ````````````````````````````
        HANDLE BIGGER PICT IN DETAIL
        
        ````````````````````````````
    */
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [listPictState, setListPictState] = useState([]);

  const handleBiggerPict = () => {
    setOpenDialog(true);
    let dataFoto = [];
    dataFoto.push("my-profile/photo");
    setListPictState(dataFoto);
  };

  /*
        ``````````````
        HANDLE NEW TAB

        ``````````````
    */
  const handleChangeTab = (event, newValue) => {
    //*
    context.setValueTabStatusGoalInYou(newValue);

    // if (newValue === 0) {
    //   context.setStatusGoalIdinYou(1); //*Draft
    //   localStorage.setItem("status_goal_id", "1");

    //   axiosConfig
    //     .get(
    //       URL_API +
    //         `/swd/goal?options[filter][scope]=self&options[filter][status_id]=${1}`
    //     )
    //     .then(function (response) {
    //       context.setLoader(false);
    //       // console.log("Response Original COLLECTION GOAL : ", response);

    //       if (response.status === 200) {
    //         if (response.data.data !== null) {
    //           context.setCollectionGoalList(response.data.data);
    //           context.setLengthGoalCount(response.data.info.count);
    //         }
    //       }
    //     })
    //     .catch(function (error) {
    //       context.setLoader(false);
    //       console.log("Error : ", error);
    //     });
    // }

    if (newValue === 0) {
      context.setStatusGoalIdinYou(2); //*Approved
      localStorage.setItem("status_goal_id", "2");

      axiosConfig
        .get(
          URL_API +
            `/swd/goal?options[filter][scope]=self&options[filter][status_id]=${2}`
        )
        .then(function (response) {
          context.setLoader(false);
          console.log("Response Original COLLECTION GOAL : ", response);

          if (response.status === 200) {
            if (response.data.data !== null) {
              context.setCollectionGoalList(response.data.data);
              context.setLengthGoalCount(response.data.info.count);
            }
          }
        })
        .catch(function (error) {
          context.setLoader(false);
          console.log("Error : ", error);
        });
    }

    if (newValue === 1) {
      context.setStatusGoalIdinYou(3); //*Active
      localStorage.setItem("status_goal_id", "3");

      axiosConfig
        .get(
          URL_API +
            `/swd/goal?options[filter][scope]=self&options[filter][status_id]=${3}`
        )
        .then(function (response) {
          context.setLoader(false);
          console.log("Response Original COLLECTION GOAL : ", response);

          if (response.status === 200) {
            if (response.data.data !== null) {
              context.setCollectionGoalList(response.data.data);
              context.setLengthGoalCount(response.data.info.count);
            }
          }
        })
        .catch(function (error) {
          context.setLoader(false);
          console.log("Error : ", error);
        });
    }

    if (newValue === 2) {
      context.setStatusGoalIdinYou(6); //*Overdue
      localStorage.setItem("status_goal_id", "6");

      axiosConfig
        .get(
          URL_API +
            `/swd/goal?options[filter][scope]=self&options[filter][status_id]=${6}`
        )
        .then(function (response) {
          context.setLoader(false);
          // console.log("Response Original COLLECTION GOAL : ", response);

          if (response.status === 200) {
            if (response.data.data !== null) {
              context.setCollectionGoalList(response.data.data);
              context.setLengthGoalCount(response.data.info.count);
            }
          }
        })
        .catch(function (error) {
          context.setLoader(false);
          console.log("Error : ", error);
        });
    }

    if (newValue === 3) {
      context.setStatusGoalIdinYou(4); //*Completed
      localStorage.setItem("status_goal_id", "4");

      axiosConfig
        .get(
          URL_API +
            `/swd/goal?options[filter][scope]=self&options[filter][status_id]=${4}`
        )
        .then(function (response) {
          context.setLoader(false);
          console.log("Response Original COLLECTION GOAL : ", response);

          if (response.status === 200) {
            if (response.data.data !== null) {
              context.setCollectionGoalList(response.data.data);
              context.setLengthGoalCount(response.data.info.count);
            }
          }
        })
        .catch(function (error) {
          context.setLoader(false);
          console.log("Error : ", error);
        });
    }
  };

  /**
   ==================================================================================
    Show Goal Counter Status
   ==================================================================================
   */
  const [goalCounter, setGoalCounter] = useState([]);

  useEffect(() => {
    axiosConfig
      .get(`${URL_API}/swd/goal/status-count?options[filter][scope]=self`)
      .then((response) => {
        console.log("Response Status Counter", response);
        const result = response.data.data;

        if (response.status === 200) {
          const filterDraft = result.filter((item) => item.id !== "1");
          setGoalCounter(filterDraft);
        }
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  }, [
    context.isOpenSnackbeer,
    context.successComplete,
    context.successCreateGoal,
  ]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}>
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Fragment>
      <Snackbeer
        isFormFilledAll={isFormFilledAll}
        setFormFilledAll={setFormFilledAll}
      />

      <Grid container>
        <br />
        <br />
        <br />
        <Grid item sm={12} md={12}>
          <List className={classes.layoutMarginLeftList}>
            <ListItem style={{ paddingLeft: 0, marginRight: 24 }}>
              <IconButton aria-haspopup="true" color="inherit">
                {fotoQuery !== "" ? (
                  <ImageSmallUser item={fotoQuery} index={1} />
                ) : (
                  <AccountCircleIcon fontSize="inherit" />
                )}
              </IconButton>
              &nbsp;&nbsp;
              <ListItemText
                className={classes.listItemtext}
                primary={
                  <Typography variant="subtitle2" className={classes.title}>
                    <b>{context.userLoginName}</b>
                  </Typography>
                }
                secondary={
                  context.memberPositionList.length > 0 &&
                  context.memberPositionList.map((item, i) => (
                    <Chip
                      key={i}
                      label={item.structure_position_title_name}
                      style={{
                        backgroundColor: "#f4f7fc",
                        color: "grey",
                        fontFamily: "Roboto",
                      }}
                      size="small"
                    />
                  ))
                }
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>

      {context.isShowDateRangeComponent === false && (
        <Grid container style={{ backgroundColor: "transparent" }}>
          <Grid item sm={6} md={6}>
            <Tabs
              indicatorColor="primary"
              value={context.valueTabStatusGoalInYou}
              onChange={handleChangeTab}
              aria-label="simple tabs example">
              {listTabs.length > 0 &&
                listTabs.map((item, i) => {
                  let counter = 0;

                  if (goalCounter.length > 0) {
                    if (goalCounter[i].code === item.code) {
                      counter = goalCounter[i].count;
                    }
                  }

                  return (
                    <Tab
                      classes={{ root: classes.widthTab }}
                      label={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{
                            textTransform: "capitalize",
                            marginBottom: -10,
                          }}>
                          <b>{item.label}</b>
                          <span
                            style={{
                              color: "#d1354a",
                              backgroundColor: "#ffa9c1",
                              fontWeight: "700",
                              paddingLeft: 4,
                              paddingRight: 4,
                              fontSize: 14,
                              marginLeft: 5,
                              borderRadius: 5,
                            }}>
                            {counter}
                          </span>
                        </Typography>
                      }
                      {...a11yProps(i)}
                    />
                  );
                })}
            </Tabs>
          </Grid>

          <Grid item sm={3} md={3} style={{ textAlign: "right" }}>
            <IconButton
              style={{ padding: 4 }}
              // onClick={handleRefresh}
            >
              <img src={PictREFRESH} style={{ width: 17, height: 17 }} />
            </IconButton>
            &nbsp;&nbsp;
            <IconButton style={{ padding: 4 }}>
              <ViewHeadlineIcon color="#4aa9fb" />
            </IconButton>
            &nbsp;&nbsp;
            <Button
              onClick={() => context.setModalExportGoal(true)}
              variant="outlined"
              className={classes.buttonOutlined}
              style={{ borderWidth: 1 }}>
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "grey" }}>
                Export CSV
              </Typography>
            </Button>
          </Grid>
          <Grid item sm={3} md={3}>
            <Box display="flex">
              <Box flexGrow={1} widht="80%">
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    onChange={(e) => handleSearch(e.target.value)}
                    inputRef={inputRefSearch}
                    placeholder={
                      context.valueSearchState !== ""
                        ? context.valueSearchState
                        : "Search Goal..."
                    }
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "Search" }}
                  />
                </div>
              </Box>

              <Box width="20%" marginLeft={theme.spacing(-0.8)} marginTop={0.1}>
                {context.isSearchRun === true && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      context.setValueSearchState("");
                      context.setSearchRun(false);
                      context.setClearSearchResult(true);
                      localStorage.removeItem("value_search");

                      setTimeout(() => {
                        inputRefSearch.current.value = "";
                      }, 200);
                    }}>
                    <CancelIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}

      <Grid container style={{ backgroundColor: "transparent" }}>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          {context.statusGoalIdInYou === 3 && ( //*Default Aktif === 3
            <>
              <ButtonWeight
                classes={classes}
                disableCreateGoal={disableCreateGoal}
              />
              <Button
                onClick={handleDialogCreate}
                variant="contained"
                className={classes.button}
                disabled={disableCreateGoal}
                style={{ marginRight: 24, marginBottom: 16, marginTop: 16 }}>
                Buat Goal baru
              </Button>
            </>
          )}
          {context.statusGoalIdInYou === 2 && ( //*Default Approved === 2
            <Button
              onClick={handleDialogCreate}
              variant="contained"
              className={classes.button}
              disabled={disableCreateGoal}
              style={{ marginRight: 24, marginBottom: 16, marginTop: 16 }}>
              Buat Goal baru
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container style={{ backgroundColor: "transparent" }}>
        <Grid item xs={12} md={12}>
          {/* <TabPanel value={context.valueTabStatusGoalInYou} index={0}>
            <ListStatusGoalDraft />
          </TabPanel> */}
          <TabPanel value={context.valueTabStatusGoalInYou} index={0}>
            <ListStatusGoalApproved />
          </TabPanel>
          <TabPanel value={context.valueTabStatusGoalInYou} index={1}>
            <ListStatusGoalActive />
          </TabPanel>
          <TabPanel value={context.valueTabStatusGoalInYou} index={2}>
            <ListStatusGoalOverdue />
          </TabPanel>
          <TabPanel value={context.valueTabStatusGoalInYou} index={3}>
            <ListStatusGoalComplete />
          </TabPanel>
        </Grid>
      </Grid>

      <DialogBiggerPictInAllAndYou
        isOpenDialog={isOpenDialog}
        setOpenDialog={setOpenDialog}
        listPictState={listPictState}
        userToken={userTokenState}
      />

      <DialogExportGoal
        isModalExportGoal={context.isModalExportGoal}
        setModalExportGoal={context.setModalExportGoal}
        statusGoalId={context.statusGoalIdInYou}
      />

      <Snackbeer
        isModalResponse200={context.isOpenSnackbeer}
        setModalResponse200={context.setOpenSnackbeer}
        messages={context.messages}
      />
    </Fragment>
  );
};

export default ViewYou;

const listTabs = [
  {
    id: 2,
    label: "Approved",
    code: STATUS_APPROVED,
    status_id: 2,
  },

  {
    id: 3,
    label: "Active",
    code: STATUS_ACTIVE,
    status_id: 3,
  },

  {
    id: 4,
    label: "Overdue",
    code: STATUS_OVERDUE,
    status_id: 6,
  },

  {
    id: 5,
    label: "Complete",
    code: STATUS_COMPLETED,
    status_id: 4,
  },
];

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

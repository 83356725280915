import React, { useState, useEffect } from "react";
import { Button, Menu, MenuItem, Box, InputBase } from "@material-ui/core";

import Axios from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";
import { Typography } from "@material-ui/core";
import ListItemUser from "./ListItemUser";
import DialogSetWeightGoal from "./DialogSetWeightGoal";

const ButtonWeight = ({ classes, disableCreateGoal }) => {
  const [anchorWeight, setAnchorWeight] = useState(null);
  const [loading, setLoading] = useState(false);
  const [collectionUsers, setCollectionUsers] = useState([]);
  const [user, setUser] = useState(varUser);
  const [search, setSearch] = useState("");
  const [openDialogSetWeightGoal, setOpenDialogSetWeightGoal] = useState(false);

  useEffect(() => {
    if (anchorWeight !== null) {
      setLoading(true);

      Axios.get(`${URL_API}/swd/goal/weighting/owner-list`)
        .then((res) => {
          const result = res.data.data;
          console.log("RES, URS COLL", result);

          if (res.status === 200) {
            setCollectionUsers(result);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log("ERROR", error);
        });
    }
  }, [anchorWeight]);

  useEffect(() => {
    if (anchorWeight !== null) {
      const filterUser =
        collectionUsers.length > 0 &&
        collectionUsers.filter((item) => {
          const fullName = `${item.member_first_name} ${item.member_last_name}`;
          const userName =
            fullName.toLowerCase().indexOf(search.toLowerCase()) !== -1;

          return userName;
        });

      // console.log("USER PARSED", getUserParsed);
      setCollectionUsers(filterUser);
    }
  }, [anchorWeight, search]);

  const handleShowAnchorWeight = (event) => {
    setAnchorWeight(event.currentTarget);
  };

  const handleCloseAnchorWeight = () => {
    setAnchorWeight(null);
  };

  const handleSelectUser = (data) => {
    console.log("Data Userx", data);
    setUser(data);

    setOpenDialogSetWeightGoal(true);
    handleCloseAnchorWeight();
    setSearch("");
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <Button
        onClick={handleShowAnchorWeight}
        variant="contained"
        className={classes.btnWhite}
        disabled={disableCreateGoal}
        style={{ marginRight: 14, marginBottom: 16, marginTop: 16 }}>
        Set Weight Goal
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorWeight}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorWeight)}
        onClose={handleCloseAnchorWeight}>
        <Box m={2} minWidth={200}>
          <Typography>
            <b>Set Weight Goal for</b>
          </Typography>
          {/* <Box mb={1.5} mt={1.5} ml={2} mr={2}> */}
          <InputBase
            placeholder="Type user's name"
            variant="outlined"
            fullWidth
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            style={{ border: "1px solid #aaa", borderRadius: 5, marginTop: 8 }}
            onChange={handleSearch}
          />
          {/* </Box> */}
          {loading && (
            <Box mt={3}>
              <Typography variant="caption" className={classes.title888}>
                Loading...
              </Typography>
            </Box>
          )}

          <Box mt={3}>
            {!loading &&
              collectionUsers.length > 0 &&
              collectionUsers.map((item, i) => (
                <MenuItem key={i} style={{ margin: 0, padding: 0 }} dense>
                  <ListItemUser
                    classes={classes}
                    item={item}
                    user={user}
                    onHandleUser={handleSelectUser}
                  />
                </MenuItem>
              ))}
          </Box>
        </Box>
      </Menu>

      <DialogSetWeightGoal
        classes={classes}
        openDialogSetWeightGoal={openDialogSetWeightGoal}
        setOpenDialogSetWeightGoal={setOpenDialogSetWeightGoal}
        user={user}
      />
    </>
  );
};

export default ButtonWeight;

let varUser = {
  id: "",
  member_id: "",
  member_first_name: "",
  member_last_name: "-",
  user_id: "",
  user_email: "",
  structure_position_title_id: "",
  structure_position_title_name: "",
  structure_unit_id: null,
  structure_unit_name: null,
  structure_unit_type_id: null,
  structure_unit_type_name: null,
  self: {
    rel: null,
  },
  member: {
    id: "",
    first_name: "",
    last_name: "-",
    email: "",
    photo_url: "",
    self: {
      rel: {
        photo_url: "",
        photo_url_thumbnail: "",
      },
    },
  },
  user: {
    id: "",
    email: "",
    self: {
      rel: null,
    },
  },
};

import React, { Component } from 'react';
import { makeStyles, createMuiTheme, withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { 
    Container, Paper, Typography, Grid, TextField, Checkbox, CircularProgress,
    FormControlLabel, Box, Button, MenuItem, Dialog, DialogTitle, DialogContent, 
    DialogContentText, DialogActions, Breadcrumbs, Link
} from '@material-ui/core';

import { navigate } from 'hookrouter';
import Nav from "./Nav";

import headerImg from '../../../assets/images/landing/header-bg.svg';

const styles = theme => ({
    root: {     
        width: '100%',
    },
	imageLogo : {
		verticalAlign : 'text-bottom',
		width : 35,
		height : 35
	},
	centerLayout : {
		textAlign : 'center'
	},
	textLowerCase : {
		textTransform: 'none'
	},
	alternateBackground : {
		backgroundColor : '#f5f5f5'
	},
	containerImageScreen : {
		width: '100%',
		height: '100%',
		position: 'relative',
		height : 650
	},
	containerImageScreen1 : {
		width: '100%',
		height: '100%',
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex : 2
	},
	containerImageScreen2 : {
		width: '100%',
		position: 'absolute',
		height: '300px',
		top: 430,
		left: 0,
		backgroundColor : '#f5f5f5'
	}
	
});

const useStyles = makeStyles(styles)

const Header = props => {
	//const { classes } = props;
	const classes = useStyles();
	 
	return (
		<header>
			
			<div className="head">
				<h2>Ketahui performa anda dengan 5 detik</h2>
				<p>Performate membantu anda untuk memonitor performa dan aktiﬁtas tim anda</p>
				
				<Button 
					onClick={() => navigate('/register')}
				variant="contained" color="primary" disableElevation className={classes.textLowerCase}>Coba Gratis</Button>
			
				<div className={classes.containerImageScreen}>
					<div className={classes.containerImageScreen1}>
						<img src={headerImg} width="80%" />
					</div>
					
					<div className={classes.containerImageScreen2}>
						<p></p>
					</div>
				</div>
				
			</div>      
      </header>
	)
};

/*
class Header extends Component {
  render() {
    return (
     
      <header>
      <Nav/>
        <div className="head">
			<h2>Ketahui performance anda dengan 5 detik</h2><br/>
            <h1>We Design,< br/> We Create</h1>
            <div>
              <p>Integer posuere leo non erat ornare dictum id vitae magna. Proin consectetur iaculis nisi, ut convallis tortor tempor congue. Curabitur sit amet tempus felis. Duis tellus eros, pellentesque at rhoncus eu, maximus ut diam.</p>
              <div><a className="contact" href="#">Contact Us</a></div>
            </div>
        </div>
       
      </header>
      
    );
  }
}
*/
export default Header;
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { navigate, A } from "hookrouter";

import logoPerformate from "../../../assets/images/performate-03.png";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  imageLogo: {
    verticalAlign: "text-bottom",
    width: 35,
    height: 35,
  },
  centerLayout: {
    textAlign: "center",
  },
  footerUl: {
    marginTop: 10,
  },
  textLowerCase: {
    textTransform: "none",
  },
});

const useStyles = makeStyles(styles);

const Footer = (props) => {
  //const { classes } = props;
  const classes = useStyles();

  return (
    <footer>
      <div className="box-footer-root">
        <br />
        <div className="box-footer">
          <div className="footer-icon">
            <img
              src={logoPerformate}
              width="40px"
              style={{ marginTop: "-5px" }}
            />
          </div>

          <div>
            <span className="footer-span-title">Performate</span>
            <ul className={classes.footerUl}>
              <li>
                <a href="#">
                  <span className="fab">About</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Careers</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Blog</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Customer</span>
                </a>
              </li>
            </ul>
          </div>

          <div>
            <span className="footer-span-title">Our Products</span>
            <ul className={classes.footerUl}>
              <li>
                <a href="#">
                  <span className="fab">Products</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Promo</span>
                </a>
              </li>
              <li>
                <A href="/home/price">
                  <span className="fab">Pricing</span>
                </A>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Family</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Business</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Education</span>
                </a>
              </li>
            </ul>
          </div>

          <div>
            <span className="footer-span-title">Help</span>
            <ul className={classes.footerUl}>
              <li>
                <a href="#">
                  <span className="fab">Workshop</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Seminar</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Guides</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Support</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Services</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Help Center</span>
                </a>
              </li>
            </ul>
          </div>

          <div>
            <span className="footer-span-title">Legal</span>
            <ul className={classes.footerUl}>
              <li>
                <a href="#">
                  <span className="fab">Legal</span>
                </a>
              </li>
              <li>
                <A href="/home/term-conditions">
                  <span className="fab">Terms and Conditions</span>
                </A>
              </li>
              <li>
                <A href="/home/privacy-policy">
                  <span className="fab">Privacy Policy</span>
                </A>
              </li>
            </ul>
          </div>

          <div>
            <span className="footer-span-title">Contact</span>
            <p>
              Jl. Raya Pondok Kelapa Jakarta Timur <br />
              <br />
              cs@performate.id <br />
              0812-1203-0314
            </p>
          </div>
        </div>

        <div style={{ marginTop: "10px" }}>
          <p>© 2020 Performate.id All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { Fragment, useContext } from "react";
import {
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import PictResultValue from "../../../../assets/images/Group_2620.png";
import { VALUE_TYPE_PERCENTAGE } from "../../../../constants/config-value-type";
import ConvertExponentialToSubstring from "../../../../utilities/ConvertExponentialToSubstring";
import ContextGoalDetail from "../Context/ContextGoalDetail";

import { FormatDecimal } from "../../../../utilities/FormatDecimal";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}))(Tooltip);

const IconResultValue = (props) => {
  const { classes, goalDetailState, isExponentRva } = props;
  const context = useContext(ContextGoalDetail);

  return (
    <Fragment>
      <ListItemIcon
        style={{
          marginLeft: 8,
        }}>
        <img src={PictResultValue} style={{ width: 40, height: 40 }} alt="." />
      </ListItemIcon>

      <ListItemText
        className={classes.listItemtext}
        primary={
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "white" }}>
            Result Value
          </Typography>
        }
        secondary={
          <LightTooltip
            placement="bottom-start"
            title={`Result Value : ${FormatDecimal(
              goalDetailState.calculatedValue.resultOriented.result_value
            )} (${FormatDecimal(
              goalDetailState.calculatedValue.resultOriented
                .result_value_achievement * 100
            )}%)`}>
            {goalDetailState.valueType.code === VALUE_TYPE_PERCENTAGE ? (
              isExponentRva === true ? (
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: "white" }}>
                  <b>
                    {FormatDecimal(
                      goalDetailState.calculatedValue.resultOriented
                        .result_value
                    )}
                    % &nbsp; (
                    {ConvertExponentialToSubstring(
                      goalDetailState.calculatedValue.resultOriented
                        .result_value_achievement * 100
                    )}
                    %)
                  </b>
                </Typography>
              ) : (
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: "white" }}>
                  <b>
                    {FormatDecimal(
                      goalDetailState.calculatedValue.resultOriented
                        .result_value
                    )}
                    % &nbsp;
                    {`(${FormatDecimal(
                      goalDetailState.calculatedValue.resultOriented
                        .result_value_achievement * 100
                    )}%)`}
                  </b>
                </Typography>
              )
            ) : (
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "white" }}>
                <b>
                  {context.modeRupiahOn === true
                    ? `Rp ${FormatDecimal(
                        goalDetailState.calculatedValue.resultOriented
                          .result_value
                      )}`
                    : FormatDecimal(
                        goalDetailState.calculatedValue.resultOriented
                          .result_value
                      )}
                  &nbsp;
                  {/* ({numeral(goalDetailState.calculatedValue.resultOriented.result_value_achievement * 100).format('0.00')}%)   */}
                  {isExponentRva === true
                    ? `(${FormatDecimal(
                        goalDetailState.calculatedValue.resultOriented
                          .result_value_achievement * 100
                      )}%)`
                    : `(${FormatDecimal(
                        goalDetailState.calculatedValue.resultOriented
                          .result_value_achievement * 100
                      )}%)`}
                </b>
              </Typography>
            )}
          </LightTooltip>
        }
      />
    </Fragment>
  );
};

export default IconResultValue;

import React from "react";
import { LinearProgress, Box, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { easeQuadInOut } from "d3-ease";
import numeral from "numeral";

import AnimatedProgressProvider from "../../../../../../components/AnimatedProgressProvider";

const BarProgress = ({ classes, countValue }) => {
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor:
        countValue <= 85
          ? "#CF364A"
          : countValue > 85 && countValue <= 95
          ? "#E8A700"
          : "#35B970",
    },
  }))(LinearProgress);

  return (
    <>
      <AnimatedProgressProvider
        valueStart={0}
        valueEnd={countValue}
        duration={3}
        easingFunction={easeQuadInOut}>
        {(value) => {
          const newVal = numeral(value).format("00");

          return (
            <Box display="flex" alignItems="center">
              <Box width="100%" mr={2}>
                <BorderLinearProgress
                  variant="determinate"
                  value={newVal > 100 ? 100 : newVal}
                />
              </Box>
              <Box minWidth={35}>
                <Typography variant="caption" className={classes.title333}>
                  <b>{newVal}%</b>
                </Typography>
              </Box>
            </Box>
          );
        }}
      </AnimatedProgressProvider>
    </>
  );
};

export default BarProgress;

import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import axios from "axios";

import ContextNewSO from "../Context/ContextNewSO";

import DialogError from "../../../components/DialogError";

import { styles } from "../Style/StyleDialogDetail";
import { URL_API } from "../../../constants/config-api";

// import { ToGoal } from '../../../../constants/config-redirect-url';
// import Redirect from '../../../../utilities/Redirect';

/*
    ````````````````````````````````````````````
    GLOBAL STATE : 

        context : {

            dataDetail : String,
            userTokenState: String,
            setOpenDialogTambahHead: Boolean,
            context.isOpenDialogDeleteEmployeeMember: Boolean,
            context.setOpenDialogDeleteEmployeeMember : Boolean
            
        };

    ````````````````````````````````````````````    

*/

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const DialogDeleteEmployeeMember = (props) => {
  const context = useContext(ContextNewSO);

  const {
    classes,
    // context.isOpenDialogDeleteEmployeeMember,
    // context.setOpenDialogDeleteEmployeeMember,
    // context.setSuccessDeleteEmployeeMember
  } = props;

  useEffect(() => {
    if (context.isOpenDialogDeleteEmployeeMember == true) {
      context.setSuccessDeleteEmployeeMember(false);
    }
  }, [context.isOpenDialogDeleteEmployeeMember]);

  /*
        ````````````````````
        HANDLE DIALOG ERROR

        ````````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [listError, setListError] = useState([]);
  const [errorStatus, setErrorStatus] = useState(null);

  /*
        `````````````
        HANDLE LOADER

        `````````````
    */
  const [loader, setLoader] = useState(false);

  const handleDelete = () => {
    setLoader(true);

    /*
            `````````````````````````````````````````````

            Will be update, still development in BACK-END

            `````````````````````````````````````````````
        */
    if (context.userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + context.userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .delete(URL_API + `/account-management/member/${context.dataDetail.id}`)
        .then(function (response) {
          setLoader(false);

          console.log("Response Original : ", response);
          context.setOpenDialogDeleteEmployeeMember(false);

          context.setSuccessDeleteEmployeeMember(true);
          context.setSnackbarResponse200(true);
          context.setMessages("Berhasil di hapus ! ");
        })
        .catch(function (error) {
          setLoader(false);
          console.log("Error : ", error.response);

          if (error.response !== undefined) {
            if (error.response.status === 403) {
              setErrorStatus(403);
              setTextErrorInformation(error.response.data.info.message);
              setOpenDialogError(true);
            }

            if (error.response.status === 422) {
              setErrorStatus(422);
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setListError(error.response.data.info.errors);
                  setOpenDialogError(true);
                }
              }
            }

            if (error.response.status === 404) {
              setErrorStatus(404);
              setTextErrorInformation(
                "Error 404 : Whoops, something went wrong !"
              );
              setOpenDialogError(true);
            }
          } else {
            setTextErrorInformation("Whoops, something went wrong !");
            setOpenDialogError(true);
          }

          context.setOpenDialogDeleteEmployeeMember(false);
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        open={context.isOpenDialogDeleteEmployeeMember}
        onClose={() => context.setOpenDialogDeleteEmployeeMember(false)}
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title">
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          {/* <Typography variant='subtitle1' className={classes.title} style={{color: 'black'}}>
                        <b>Delete Nama Jabatan</b>
                    </Typography> */}
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "black" }}>
              <b>Apakah Anda yakin ingin hapus ?</b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            onClick={handleDelete}
            variant="contained"
            className={classes.button}
            // fullWidth
          >
            {loader !== true ? (
              "Yakin"
            ) : (
              <CircularProgress size={20} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(DialogDeleteEmployeeMember);

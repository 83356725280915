import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  Fragment,
  useRef,
} from "react";
import {
  createMuiTheme,
  MuiThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  TextField,
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TablePagination,
  InputAdornment,
  Fade,
  TableCell,
  TableRow,
  LinearProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";

import { ThemeProvider } from "@material-ui/core/styles";

import axiosConfig from "../../constants/config-axios";

import MomentUtils from "@date-io/moment";
import moment from "moment";
import { useDebouncedCallback } from "use-debounce";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import CancelIcon from "@material-ui/icons/Cancel";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import SearchIcon from "@material-ui/icons/Search";

import ContextTask from "./Context/ContextTask";
import TableItem from "./Components/TableItem";
import TablePaginationCustom from "./Components/TablePaginationCustom";

// import TruncateTextShort from '../../utilities/TruncateTextShort';

import DialogError from "../../components/DialogError";
import Snackbeer from "../../components/Snackbeer";
import PictEmptyStateTask from "../../assets/images/empty_task_state.png";

import { URL_API } from "../../constants/config-api";
import { styles } from "./Style/StyleTask";

import "moment/locale/id";
import EnhancedTableHead from "./Components/EnhancedTableHead";
import EnhancedTableToolbar from "./Components/EnhancedTableToolbar";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const ViewTask = () => {
  const classes = useStyles();

  const context = useContext(ContextTask);

  /*
    `````````````````````
    HANDLE ADD TO DO LIST

    `````````````````````
  */
  const [isShowPictEmptyTask, setShowPictEmptyTask] = useState(false);

  const [listCollectionTask, setListCollectionTask] = useState([
    {
      name: "",
      end_timestamp: "",
      owner: {
        member: {
          first_name: "",
          last_name: "",
        },
      },
      target_result: null,
      actual_result: null,
      note: null,
      status: {
        id: "",
        code: "",
        name: "",
        sort_order: "",
      },
    },
  ]);

  const [isClickedAddToDoList, setClickedAddToDoList] = useState(false);

  const [listOwner, setListOwner] = useState([]);

  /*
    ```````````````````
    COMPONENT DID MOUNT

    ```````````````````
  */
  const [isLoaderEmpty, setLoaderEmpty] = useState(false);
  const [userTokenStateLocal, setUserTokenStateLocal] = useState("");

  const [loader, setLoader] = useState(false);
  /*
    `````````````````
    HANDLE PAGINATION

    `````````````````
  */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [taskCount, setTaskCount] = useState(0);
  const [isSuccesCreateTask, setSuccesCreateTask] = useState(false);
  const [loaderForPaging, setLoaderForPaging] = useState(false);

  //  const emptyRows =
  //    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  // ============== Show / Hide Completed ==============

  const [triggerTDL, setTriggerTDL] = useState(null);

  useEffect(() => {
    // console.log("Context : ", context)

    //*
    setLoaderEmpty(true);
    setLoader(true);

    const userToken = localStorage.getItem("userToken");

    if (userToken !== undefined && userToken !== null && userToken !== "") {
      setUserTokenStateLocal(userToken);

      axiosConfig
        .get(`${URL_API}/todolist/create`)
        .then(function (response) {
          setLoaderEmpty(false);
          setLoader(false);
          console.log("Response CREATE TDL : ", response);

          if (response.status === 200) {
            setListOwner(
              response.data.data.ownerCollections.map((item) => {
                return {
                  id: item.id,
                  email: item.email,
                  first_name: item.member.first_name,
                  last_name: item.member.last_name,
                  structure_position_title_name:
                    item.structurePosition === null
                      ? ""
                      : item.structurePosition.structure_position_title_name,
                  structure_unit_type_name:
                    item.structurePosition === null
                      ? ""
                      : item.structurePosition.structure_unit_type_name,
                  structure_unit_name:
                    item.structurePosition === null
                      ? ""
                      : item.structurePosition.structure_unit_name,
                };
              })
            );
          }
        })
        .catch(function (error) {
          setLoaderEmpty(false);
          setLoader(false);

          console.log("Error : ", error.response);
        });

      /*
        ````````````````````
        LIST COLLECTION TASK

        ````````````````````
      */

      const valueTaskSearh = localStorage.getItem("task_search");

      if (valueTaskSearh !== null) {
        setLoader(true);

        setValueSearch(valueTaskSearh);
        setSearchRun(true);

        axiosConfig
          .get(
            URL_API +
              `/todolist?options[filter][search]=${valueTaskSearh}&options[paging][offset]=${
                offset === -10 ? 0 : offset
              }&options[paging][limit]=${rowsPerPage}`
          )
          .then(function (response) {
            setLoaderEmpty(false);
            console.log("Response Original COLLECTION : ", response);

            if (response.status === 200) {
              context.setSuccessTaskCOMPLETED(false);
              context.setSuccessTaskINCOMPLETED(false);

              setSearchRun(true);

              context.setAnchorEl(null);
              // handleCloseDropdownOwner();

              setTaskCount(response.data.info.count);

              if (!context.showhideCompleted) {
                setListCollectionTask(response.data.data);
              } else {
                const filteredTask = response.data.data.filter(
                  (row) => row.status_id === "1"
                );

                setListCollectionTask(filteredTask);
              }

              if (response.data.data.length > 0) {
                setShowPictEmptyTask(false);
              } else {
                setShowPictEmptyTask(true);
              }
            }
            setLoader(false);
          })
          .catch(function (error) {
            setLoaderEmpty(false);
            setLoader(false);
            console.log("Error : ", error.response);

            setShowPictEmptyTask(true);
            setListCollectionTask([]);
          });
      } else {
        setLoader(true);
        setSearchRun(false);

        axiosConfig
          .get(
            URL_API +
              `/todolist?options[paging][offset]=0&options[paging][limit]=10`
          )
          .then(function (response) {
            setLoaderEmpty(false);
            console.log("Response Original COLLECTION : ", response);

            if (response.status === 200) {
              context.setSuccessTaskCOMPLETED(false);
              context.setSuccessTaskINCOMPLETED(false);

              context.setAnchorEl(null);
              // handleCloseDropdownOwner();
              setTaskCount(response.data.info.count);

              if (!context.showhideCompleted) {
                setListCollectionTask(response.data.data);
              } else {
                const filteredTask = response.data.data.filter(
                  (row) => row.status_id === "1"
                );

                setListCollectionTask(filteredTask);
              }

              if (response.data.data.length > 0) {
                setShowPictEmptyTask(false);
              } else {
                setShowPictEmptyTask(true);
              }
            }
            setLoader(false);
          })
          .catch(function (error) {
            setLoaderEmpty(false);
            setLoader(false);
            console.log("Error : ", error.response);

            setShowPictEmptyTask(true);
            setListCollectionTask([]);
          });
      }
    } else {
      console.log("No Access Token available!");
    }
  }, [
    triggerTDL,

    isSuccesCreateTask,
    context.isSuccessDeleteTDL,
    context.isSuccessEditTDL,

    context.isSuccessTaskCOMPLETED,
    context.isSuccessTaskINCOMPLETED,
  ]);

  // handle show/hide completed
  const handleShowHideCompleted = () => {
    setTriggerTDL(Math.floor(Math.random() * 1000 + 1));
    context.setShowhideCompleted((prev) => !prev);
  };

  /*
    ````````
    HANDLE PAGINATION

    ````````
  */

  const handleChangePage = (event, newPage) => {
    setLoaderForPaging(true);
    setPage(newPage);
    setOffset((parseInt(newPage + 1) - 1) * rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoaderForPaging(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /*
    ````````
    DUE DATE

    ````````
  */

  const [locale, setLocale] = useState("id");
  const [selectedDueDate, setSelectedDueDate] = useState(null);

  /*
      ```````````````````
      DROPDOWN LIST OWNER

      ```````````````````
  */
  // const [anchorEl, setAnchorEl] = useState(null);

  function handleDropdownChooseOwner(event) {
    context.setAnchorEl(event.currentTarget);
  }

  function handleCloseDropdownOwner() {
    context.setAnchorEl(null);
  }

  /*  
    `````````````````
    HANDLE VALUE TARGET

    `````````````````
  */
  const [valueTarget, setValueTarget] = useState("");

  const handleValueSetTarget = (e) => {
    setValueTarget(e.target.value);
  };

  /*
    ``````````````````
    HANDLE VALUE TITLE

    ``````````````````
  */
  const [valueTitle, setValueTitle] = useState(""); //*Name of Task
  const handleValueTitle = (e) => {
    e.preventDefault();
    setValueTitle(e.target.value);
  };

  const [isValueTitleKutuMacanInTITLE, setValueTitleKutuMacanInTITLE] =
    useState(false); //*Kutu Macan === "Too Much character"

  useEffect(() => {
    if (typeof valueTitle === "string") {
      if (valueTitle.length > 48) {
        setValueTitleKutuMacanInTITLE(true);

        // setTimeout(() => {

        //   textInputReffBigTextfield.current.focus();

        // }, 100);
      } else {
        setValueTitleKutuMacanInTITLE(false);
      }
    }
  }, [valueTitle]);

  /*
    ````````````
    HANDLE OWNER

    ````````````
  */
  const [isTerpilihOwner, setTerpilihOwner] = useState(false);

  const [ownerId, setOwnerId] = useState("");

  const handleChooseOwner = (event, data) => {
    console.log("Tar Val", event);

    console.log("Owner terpilih : ", data);
    if (data !== null) {
      setTerpilihOwner(true);
      handleCloseDropdownOwner();

      setOwnerId(data.id);
    }
  };

  /*
        ````````````
        Search  GOAL

        ````````````
    */

  let inputRefSearch = useRef(null);
  const [isSearchRun, setSearchRun] = useState(false);
  const [clearSearchResult, setClearSearchResult] = useState(false);
  const [taskSearchValue, setTaskSearchValue] = useState("");
  const [valueSearch, setValueSearch] = useState("");

  const [handleSearch, cancel] = useDebouncedCallback(
    useCallback(
      (value) => {
        if (value !== "") {
          setLoaderEmpty(true);
          setLoader(true);

          // console.log("I want to know task : ", value);
          localStorage.setItem("task_search", value);

          setValueSearch(value);

          axiosConfig
            .get(
              `${URL_API}/todolist?options[filter][search]=${value}&options[paging][offset]=${
                offset === -10 ? 0 : offset
              }&options[paging][limit]=${rowsPerPage}`
            )
            .then(function (response) {
              console.log("Response Search TDL : ", response);

              if (response.status === 200) {
                setTaskCount(response.data.info.count);

                setSearchRun(true);
                if (context.showhideCompleted === true) {
                  const filteredTaskInCompleted = response.data.data.filter(
                    (row) => row.status_id === "1"
                  );

                  setListCollectionTask(filteredTaskInCompleted);
                } else if (context.showhideCompleted === false) {
                  setListCollectionTask(response.data.data);
                }

                if (response.data.data.length > 0) {
                  setShowPictEmptyTask(false);
                } else {
                  setShowPictEmptyTask(true);
                }
              }
              setLoader(false);
              setLoaderEmpty(false);
            })
            .catch(function (error) {
              setLoader(false);
              setLoaderEmpty(false);
              console.log("Error : ", error.response);

              setShowPictEmptyTask(true);
              setListCollectionTask([]);
            });
        } else {
          setSearchRun(false);
          setValueSearch("");

          localStorage.removeItem("task_search");
          inputRefSearch.current.value = "";

          setLoaderEmpty(true);
          setLoader(true);

          axiosConfig
            .get(
              `${URL_API}/todolist?options[paging][offset]=0&options[paging][limit]=10`
            )
            .then(function (response) {
              console.log("Response Search TDL : ", response);

              if (response.status === 200) {
                setTaskCount(response.data.info.count);

                if (context.showhideCompleted === true) {
                  const filteredTaskInCompleted = response.data.data.filter(
                    (row) => row.status_id === "1"
                  );

                  setListCollectionTask(filteredTaskInCompleted);
                } else if (context.showhideCompleted === false) {
                  setListCollectionTask(response.data.data);
                }

                if (response.data.data.length > 0) {
                  setShowPictEmptyTask(false);
                } else {
                  setShowPictEmptyTask(true);
                }
              }
              setLoaderEmpty(false);
              setLoader(false);
            })
            .catch(function (error) {
              setLoaderEmpty(false);
              setLoader(false);
              console.log("Error : ", error.response);

              setShowPictEmptyTask(true);
              setListCollectionTask([]);
            });
        }
      },
      [triggerTDL]
    ),
    1000,

    { maxWait: 5000 }
  );

  // context.isSuccessTaskCOMPLETED, context.isSuccessTaskINCOMPLETED

  useEffect(() => {
    if (
      taskSearchValue === "" &&
      isSearchRun === false &&
      clearSearchResult === true
    ) {
      setLoaderEmpty(true);
      setLoader(true);

      axiosConfig
        .get(
          `${URL_API}/todolist?options[paging][offset]=0&options[paging][limit]=10`
        )
        .then(function (response) {
          // console.log("Response Search TDL : ", response);

          if (response.status === 200) {
            setTaskCount(response.data.info.count);
            if (context.showhideCompleted === true) {
              const filteredTaskInCompleted = response.data.data.filter(
                (row) => row.status_id === "1"
              );

              setListCollectionTask(filteredTaskInCompleted);
            } else if (context.showhideCompleted === false) {
              setListCollectionTask(response.data.data);
            }

            if (response.data.data.length > 0) {
              setShowPictEmptyTask(false);
            } else {
              setShowPictEmptyTask(true);
            }
          }
          setLoaderEmpty(false);
          setLoader(false);
          setClearSearchResult(false);
        })
        .catch(function (error) {
          setLoaderEmpty(false);
          setLoader(false);
          console.log("Error : ", error.response);

          setShowPictEmptyTask(true);
          setShowPictEmptyTask(true);
          setListCollectionTask([]);
        });
    }
  }, [taskSearchValue, isSearchRun, clearSearchResult, triggerTDL]);

  /*
    ````````````````````````````````````
    WHEN STATE PAGING CHANGED

    ````````````````````````````````````
  */

  useEffect(() => {
    if (loaderForPaging === true) {
      setLoaderEmpty(true);
      setLoader(true);
      const valueTaskSearh = localStorage.getItem("task_search");

      if (valueTaskSearh !== null) {
        axiosConfig
          .get(
            URL_API +
              `/todolist?options[filter][search]=${valueTaskSearh}&options[paging][offset]=${
                offset === -10 ? 0 : offset
              }&options[paging][limit]=${rowsPerPage}`
          )
          .then(function (response) {
            setLoaderEmpty(false);
            console.log("Response Original COLLECTION : ", response);

            if (response.status === 200) {
              context.setSuccessTaskCOMPLETED(false);
              context.setSuccessTaskINCOMPLETED(false);

              setTaskCount(response.data.info.count);

              context.setAnchorEl(null);
              // handleCloseDropdownOwner();

              if (!context.showhideCompleted) {
                setListCollectionTask(response.data.data);
              } else {
                const filteredTask = response.data.data.filter(
                  (row) => row.status_id === "1"
                );

                setListCollectionTask(filteredTask);
              }

              if (response.data.data.length > 0) {
                setShowPictEmptyTask(false);
              } else {
                setShowPictEmptyTask(true);
              }
            }
            setLoader(false);
          })
          .catch(function (error) {
            setLoaderEmpty(false);
            setLoader(false);
            console.log("Error : ", error.response);

            setShowPictEmptyTask(true);
            setListCollectionTask([]);
          });
      } else {
        setLoader(true);
        setLoaderEmpty(true);

        axiosConfig
          .get(
            URL_API +
              `/todolist?options[paging][offset]=${
                offset === -10 ? 0 : offset
              }&options[paging][limit]=${rowsPerPage}`
          )
          .then(function (response) {
            setLoaderEmpty(false);
            console.log("Response Original COLLECTION : ", response);

            if (response.status === 200) {
              context.setSuccessTaskCOMPLETED(false);
              context.setSuccessTaskINCOMPLETED(false);

              setTaskCount(response.data.info.count);

              context.setAnchorEl(null);
              // handleCloseDropdownOwner();

              if (!context.showhideCompleted) {
                setListCollectionTask(response.data.data);
              } else {
                const filteredTask = response.data.data.filter(
                  (row) => row.status_id === "1"
                );

                setListCollectionTask(filteredTask);
              }

              if (response.data.data.length > 0) {
                setShowPictEmptyTask(false);
              } else {
                setShowPictEmptyTask(true);
              }
            }
            setLoader(false);
          })
          .catch(function (error) {
            setLoaderEmpty(false);
            setLoader(false);
            console.log("Error : ", error.response);

            setShowPictEmptyTask(true);
            setListCollectionTask([]);
          });
      }
    }
  }, [offset, rowsPerPage, page]);

  /*
    ````````````````````````````````````
    HANDLE POST SUBMIT CREATE TO DO LIST

    ````````````````````````````````````
  */

  const handleSubmitCreateTask = () => {
    setLoader(true);
    setClickedAddToDoList(false);

    let data = {
      Todolist: {
        name: valueTitle,
        end_timestamp: moment(selectedDueDate).format("YYYY-MM-DD 00:00:00"),
        target_result: valueTarget,
        owner_id: ownerId,
      },
    };

    console.log("Data submit : ", data);

    if (context.userTokenState !== "") {
      axiosConfig
        .post(URL_API + `/todolist`, data)
        .then(function (response) {
          setLoader(false);
          setSuccesCreateTask(true);

          console.log("Response Original : ", response);

          context.setSnackbarResponse200(true);
          context.setMessages("Berhasil membuat To Do List :)");
        })
        .catch(function (error) {
          console.log("Error : ", error.response);

          setLoader(false);

          if (error.response !== undefined) {
            if (error.response.status === 400) {
              setErrorStatus(400);
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setListError(error.response.data.info.errors);
                  setTextErrorInformation(".");
                  setOpenDialogError(true);
                }
                // if(error.response.data.info.errors[0].code === 'VALIDATION_ERROR'){

                //   setOpenDialogError(true);
                //   setTextErrorInformation("Error 400 : " + error.response.data.info.errors[0].description);
                // };
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformation("Whoops, something went wrong !");
          }
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  /*
    ```````````````````
    HANLDE DIALOG ERROR

    ```````````````````
 */

  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  /*
      ````````````````````````
      USE REFF BIG TEXT FIELD

      ```````````````````````
    */

  let textInputReffBigTextfield = useRef(null);

  useEffect(() => {
    if (isValueTitleKutuMacanInTITLE === true) {
      //*Focus in Text Field
      setTimeout(() => {
        textInputReffBigTextfield.current.focus();
      }, 100);
    }
  }, [isValueTitleKutuMacanInTITLE]);

  /* =========================================================
    Multiple Delete Section
  ========================================================= */

  const [selected, setSelected] = useState([]);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = listCollectionTask.map((n) => n.id);
      setSelected(newSelecteds);
      // console.log("selAll", newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Box marginLeft={theme.spacing(1.6)} marginTop={theme.spacing(1.5)}>
        {/* 
              `````````````````````
              EMPTY STATE LIST TASK

              `````````````````````        
          */}

        {/* {listCollectionTask.length === 0 &&
          isLoaderEmpty === false &&
          isShowPictEmptyTask === true && (
            <Grid container style={{ marginBottom: 720 }}>
              <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                <Box marginTop={theme.spacing(1.5)}>
                  <img
                    src={PictEmptyStateTask}
                    alt={"Empty state"}
                    width={95}
                    height={104}
                  />
                </Box>

                <Box marginTop={theme.spacing(0.5)}>
                  <Typography variant="h6" className={classes.title}>
                    <b>
                      Anda belum memiliki <i>To Do List</i> saat ini :(
                    </b>
                  </Typography>
                </Box>

                <Box marginTop={theme.spacing(0.1)}>
                  <Typography variant="subtitle1" className={classes.title}>
                    Mulai buat <i>To Do List</i> sekarang ?
                  </Typography>
                </Box>

                <Box marginTop={theme.spacing(0.5)}>
                  <Button
                    size="small"
                    onClick={() => {
                      setShowPictEmptyTask(false);
                      setClickedAddToDoList(true);
                    }}
                    variant="contained"
                    className={classes.button}>
                    <Typography variant="subtitle2" className={classes.title}>
                      Buat sekarang !
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )} */}

        <Grid container>
          <Grid item md={4}>
            {isClickedAddToDoList === true ? (
              <Box marginRight={theme.spacing(0.1)}>
                {isValueTitleKutuMacanInTITLE !== true ? (
                  <TextField
                    // inputRef={textInputReff}
                    value={valueTitle}
                    onChange={handleValueTitle}
                    id="standard-basic"
                    placeholder="Tulis To Do List di sini !"
                    fullWidth
                    inputProps={{
                      className: classes.title,
                    }}
                    defaultValue
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={() => setClickedAddToDoList(false)}
                            style={{ marginLeft: "-40px" }}>
                            <CancelIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <Fade in={true}>
                    <TextField
                      inputRef={textInputReffBigTextfield}
                      value={valueTitle}
                      defaultValue={valueTitle}
                      onChange={handleValueTitle}
                      style={{ marginTop: 0 }}
                      id="outlined-multiline-static-visi"
                      // label="Multiline"
                      multiline
                      rows="7"
                      // className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        className: classes.title,
                      }}
                    />
                  </Fade>
                )}
              </Box>
            ) : (
              //*Muncul search ketika isClickedAddToDoList !== true

              <TextField
                id="standard-basic"
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Telusuri nama To Do List di sini..."
                fullWidth
                // className={classes.textField}
                // variant="outlined"
                inputProps={{
                  className: classes.title,
                }}
                inputRef={inputRefSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        onClick={() => {
                          setSearchRun(false);
                          setTaskSearchValue("");
                          localStorage.removeItem("task_search");
                          setClearSearchResult(true);

                          setTimeout(() => {
                            inputRefSearch.current.value = "";
                          }, 200);
                        }}>
                        <HighlightOffOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Grid>

          <Grid item md={2}>
            {isClickedAddToDoList === true && (
              <Box marginRight={theme.spacing(0.1)}>
                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                  <ThemeProvider theme={themePicker}>
                    <Fragment>
                      <DatePicker
                        autoOk
                        value={selectedDueDate}
                        onChange={setSelectedDueDate}
                        animateYearScrolling
                        variant="inline" // dialog, static, inline
                        disableToolbar={false}
                        // style={{marginLeft: 16}}
                        format="DD MMMM YYYY"
                        placeholder="Pilih Due date"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                size="small"
                                // onClick={setSelectedDueDate}
                              >
                                <EventAvailableIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}

                        // label="Pilih tanggal"
                        // orientation="landscape"
                        // format
                        // TextFieldComponent =
                        // ToolbarComponent
                      />
                    </Fragment>
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </Box>
            )}
          </Grid>

          <Grid item md={3}>
            {isClickedAddToDoList && (
              <Box marginRight={theme.spacing(0.1)} style={{ marginTop: -16 }}>
                <Autocomplete
                  id="combo-box-demo"
                  options={listOwner}
                  getOptionLabel={(option) =>
                    `${option.first_name} ${option.last_name}`
                  }
                  style={{ marginTop: 0 }}
                  noOptionsText="Tidak ada pilihan"
                  onChange={(e, owner) => handleChooseOwner(e, owner)}
                  renderInput={(params) => (
                    <TextField
                      style={{ fontSize: 13 }}
                      {...params}
                      fullWidth
                      label="Search Owner"
                      variant="standard"
                    />
                  )}
                />
              </Box>
            )}
          </Grid>

          <Grid item md={1}>
            {isClickedAddToDoList && (
              <Box>
                <TextField
                  type="number"
                  value={valueTarget}
                  onChange={handleValueSetTarget}
                  id="standard-basic"
                  placeholder="Set Target"
                  inputProps={{
                    className: classes.title,
                  }}
                  fullWidth
                  inputProps={{
                    className: classes.title,
                  }}
                />
              </Box>
            )}
          </Grid>

          <Grid item md={2} style={{ textAlign: "center" }}>
            {isClickedAddToDoList !== true ? (
              <Button
                onClick={() => setClickedAddToDoList(true)}
                variant="outlined"
                className={classes.button}>
                <Typography variant="subtitle2" className={classes.title}>
                  Tambah <i>To Do List</i>
                </Typography>
              </Button>
            ) : (
              <Button
                onClick={handleSubmitCreateTask}
                variant="outlined"
                className={classes.button}>
                <Typography variant="subtitle2" className={classes.title}>
                  {/* {
                        loader !== true ? "Tambah" : <CircularProgress size={20} style={{color: 'white'}} />
                      }                */}
                  Tambah
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>

      {/* 
          ```````````````
          GRID LIKE TABLE
          ```````````````
      */}
      <br />
      <br />
      <Box marginLeft={theme.spacing(1.2)} marginRight={theme.spacing(0.2)}>
        <Paper
          className={classes.rootTable}
          style={{ backgroundColor: "transparent" }}>
          {/* <TableContainer> */}
          <EnhancedTableToolbar
            numSelected={selected.length}
            itsclasses={classes}
            selected={selected}
            setSelected={setSelected}
            onShowHideCompleted={handleShowHideCompleted}
            showhideCompleted={context.showhideCompleted}
          />
          {loader ? <LinearProgress /> : null}

          <Table
            className={classes.table}
            aria-label="sticky table"
            // stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              rowCount={listCollectionTask.length}
              onSelectAllClick={handleSelectAllClick}
            />

            {listCollectionTask.length === 0 && !isLoaderEmpty && (
              <TableBody>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>
                    <Box style={{ textAlign: "center" }}>
                      <Typography
                        variant="subtitle1"
                        className={classes.title}
                        style={{
                          color: "grey",
                          marginTop: 16,
                          marginBottom: 16,
                        }}>
                        <b>ToDo List tidak tersedia</b>
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}

            <TableBody>
              {isShowPictEmptyTask === false &&
                listCollectionTask.length > 0 &&
                listCollectionTask
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    return (
                      <TableItem
                        key={i}
                        row={row}
                        classes={classes}
                        valueSearch={valueSearch}
                        handleSearch={handleSearch}
                        setSelected={setSelected}
                        selected={selected}
                        isSelected={isSelected}
                      />
                    );
                  })}
            </TableBody>
          </Table>
          {/* </TableContainer> */}
        </Paper>

        <Snackbeer
          classes={classes}
          isModalResponse200={context.isSnackbarResponse200}
          setModalResponse200={context.setSnackbarResponse200}
          messages={context.messages}
        />
      </Box>

      <Fragment>
        <Box marginLeft={theme.spacing(1)} marginTop={theme.spacing(0.3)}>
          <Grid container direction="row" justify="flex-end" alignItems="right">
            <TablePagination
              classes={{ toolbar: classes.toolbar }}
              rowsPerPageOptions={[10, 25, 50]}
              colSpan={3}
              count={taskCount}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "Jumlah baris",
                },
              }}
              labelDisplayedRows={({ from, to, count }) => (
                <Typography variant="subtitle2" className={classes.title}>
                  <i>{`${from} - ${
                    to === -1 ? count : to
                  } baris di tampilkan dari total semua ${count} baris.`}</i>
                </Typography>
              )}
              labelRowsPerPage={
                <Typography variant="subtitle2" className={classes.title}>
                  <b>
                    <i>Jumlah baris yang mau di tampilkan : </i>
                  </b>
                </Typography>
              }
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationCustom}
            />
          </Grid>
        </Box>

        <DialogError
          classes={classes}
          isOpenDialogError={isOpenDialogError}
          setOpenDialogError={setOpenDialogError}
          textErrorInformation={textErrorInformation}
          errorStatus={errorStatus}
          listError={listError}
        />
      </Fragment>
    </MuiThemeProvider>
  );
};

export default ViewTask;

const themePicker = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },

  typography: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  textfield: {
    width: 200,
  },
});

function createData(name, duedate, owner, target, aktual, catatan) {
  return { name, duedate, owner, target, aktual, catatan };
}

const rows = [
  createData(
    "Sebar brosurf fshdlfjsaldfh fashldsaflkadsjf",
    "17 Agusutus 2020",
    "Imran",
    5,
    3,
    "Lanjutkan"
  ),

  createData(
    "Sebar kartu nama",
    "17 Agusutus 2020",
    "Marfuah",
    5,
    5,
    "Lanjutkan"
  ),
  createData("Sebar pamflet", "17 Agusutus 2020", "Yeni", 5, 6, "Lanjutkan"),

  createData(
    "Sebar flyer",
    "17 Agusutus 2020",

    "Fajar",
    5,
    3,
    "Lanjutkan fdgdlskgj"
  ),

  createData("Beli galon", "17 Agusutus 2020", "Vani", 49, 4, "Lanjutkan"),
];

import React, { useCallback, useState } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Breadcrumbs,
  Hidden,
} from "@material-ui/core";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import uploadIcon from "../../../assets/images/Group-1.png";
import uploadIconColor from "../../../assets/images/Group-813.png";

import { extractImageFileExtensionFromBase64 } from "../../../utilities/ReusableUtils";

import ConvertDataUrlToFile from "../../../utilities/ConvertDataUrlToFile";
import DialogError from "../../../components/DialogError";

import "./styles/styleCompletionProfileGroup.css";
import FormCompletionProfileGroup from "./ComponentsProfileCompletion/FormCompletionProfileGroup";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const styles = (theme) => ({
  root: {
    borderRadius: 2,
  },
  button: {
    width: "503px",
    height: "42px",
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    // backgroundColor: 'cyan',
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
  },

  title: {
    fontFamily: "Roboto",
  },
  titleDrag: {
    fontFamily: "Roboto",
    cursor: "pointer",
  },
  breadcrumb: {
    // marginLeft: theme.spacing(5),
  },
  media: {
    width: 200,
    height: 137,
    cursor: "pointer",
  },
  cardAvatar: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  titleBack: {
    fontFamily: "Roboto",
    color: "#333333",
    // marginTop: theme.spacing(16),
    // marginLeft: theme.spacing(4),
    // marginRight: theme.spacing(4),
    cursor: "pointer",
    fontWeight: "bold",
  },
  button: {
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    // marginLeft:theme.spacing(1)
    color: "white",
    textTransform: "capitalize",
  },
  buttonOutlined: {
    fontFamily: "Roboto",
    textTransform: "lowercase",
    color: "#d64253",
    borderWidth: 0,
    "&:hover": {
      borderWidth: 0,
    },
  },
  dropDown: {
    // flexBasis: 200,
    minWidth: 100,
    width: 120,
  },
});

const ViewCompletionProfileGroup = (props) => {
  const { classes } = props;

  /*
        ```````````````````````
        HANDLE DROPZONE

        ````````````````````````
    */

  const [isPick, setPick] = useState(true);
  // const [isPick, setPick ] = useState(false);
  const [isCropActive, setCropActive] = useState(false);
  const [isForm, setForm] = useState(false);
  const [isHover, setHoverPict] = useState(false);

  //*
  const [imgSrc, setImgSrc] = useState();
  const [imgSrcExt, setImgSrcExt] = useState();
  const [scale, setScale] = useState(1);

  //*
  const [contentFile, setContentFile] = useState(undefined);
  const [nameFile, setNameFile] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    //*
    console.log("acceptedFiles : ", acceptedFiles);
    setNameFile(acceptedFiles[0].name);

    setContentFile(acceptedFiles);

    //*
    setCropActive(true);
    setPick(false);

    //*
    const reader = new FileReader();
    let dataImage = new FormData();

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      const binaryStr = reader.result;
      // console.log("Binary String : ",binaryStr);

      //*If > 2 MB
      if (acceptedFiles[0].size / 1024 / 1024 >= 2.004190444946289) {
        //284871

        setOpenDialogError(true);
        setErrorStatus(400);
        setTextErrorInformation("File yang di unggah tidak boleh melebihi 2MB");

        setCropActive(false);
        setPick(true);
      } else if (
        acceptedFiles[0].type !== "image/jpeg" &&
        acceptedFiles[0].type !== "image/png"
      ) {
        setOpenDialogError(true);
        setErrorStatus(400);
        setTextErrorInformation(
          "Maaf, tidak dapat mengunggah foto dalam format selain JPEG atau PNG ! "
        );

        setCropActive(false);
        setPick(true);
      } else {
        //*Running Oke !
        setImgSrc(binaryStr);
        setImgSrcExt(extractImageFileExtensionFromBase64(binaryStr));
      }
    };

    acceptedFiles.forEach((file) => reader.readAsDataURL(file));
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    rejectedFiles,
  } = useDropzone({ onDrop });

  /*
        `````````````````````
        HANDLE AVATAR EDITOR 

        ``````````````````````
    */

  const [editorState, setEditorState] = useState(null);
  const setEditorRef = (editor) => {
    if (editor !== null) {
      // console.log("Editor :", editor);
      setEditorState(editor);
      // const canvas = editor.props.image.getImage().toDataURL();
      // console.log("Canvass : ", canvas);
    }
  };

  const onChangeScale = (e) => {
    setScale(e.target.value);
  };

  /*
        ``````````````````````````````
        HANDLE BACK & HANDLE CONTINUE

        ``````````````````````````````
    */

  const handleBackInsideComponentCrop = () => {
    setPick(true);
    setCropActive(false);
  };

  const handleCropAndContinue = () => {
    setPick(false);
    setCropActive(false);
    setForm(true);

    const canvas = editorState.getImage().toDataURL();
    const dataFileReadyCrop = ConvertDataUrlToFile(canvas, contentFile[0].name);

    setImgSrc(canvas);
    setContentFile(dataFileReadyCrop);

    // console.log("Canvass : ", canvas);
  };

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);

  return (
    <MuiThemeProvider theme={theme}>
      <br />
      <br />
      <Paper elevation={0}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Breadcrumbs
            className={classes.breadcrumb}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="Breadcrumb">
            <Typography color="inherit" className={classes.title}>
              Keanggotaan
            </Typography>
            <Typography color="primary" className={classes.title}>
              <b>Profil</b>
            </Typography>

            <Typography color="inherit" className={classes.title}>
              <Hidden only="xs">Struktur Perusahaan</Hidden>
              <Hidden smUp={true}>...</Hidden>
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Paper>

      <br />
      <br />
      {isPick !== false && (
        <Paper className={classes.root} elevation={0}>
          <Grid container>
            <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
              <Typography variant="h4" className={classes.title}>
                <b>Upload Logo</b>
              </Typography>
              <br />
              <br />
              <br />
              <div
                onMouseOver={() => setHoverPict(true)}
                onMouseOut={() => setHoverPict(false)}
                {...getRootProps()}>
                <input {...getInputProps()} />

                {/* <p>Drag 'n' drop some files here, or click to select files</p> */}

                <img
                  src={isHover ? uploadIconColor : uploadIcon}
                  alt="PictUploader"
                  className={classes.media}
                />
                <br />
                <br />

                <Typography variant="h4" className={classes.titleDrag}>
                  {!isDragActive &&
                    (isHover
                      ? "Click to Upload"
                      : "Drag and drop image to upload")}
                  {isDragActive && !isDragReject && "Drop it here"}
                  {isDragReject && "File type not accepted, sorry!"}
                  {/* {isFileTooLarge && (<div className="text-danger mt-2" >File is too large.</div>)} */}
                </Typography>

                {isHover ? (
                  <span style={{ color: "#a8a8a8", fontFamily: "Roboto" }}>
                    (up to 1 Mb){" "}
                  </span>
                ) : (
                  <span
                    style={{
                      contentFilecolor: "#a8a8a8",
                      fontFamily: "Roboto",
                    }}>
                    or{" "}
                    <font color="#67aded">
                      <b style={{ cursor: "pointer", fontFamily: "Roboto" }}>
                        browse
                      </b>
                    </font>{" "}
                    to choose a file
                  </span>
                )}

                <br />
                <div style={{ color: "#a8a8a8", fontFamily: "Roboto" }}>
                  {isHover ? <div>&nbsp;</div> : "(up to 1 Mb)"}
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-end">
            <Box marginRight={theme.spacing(0.3)}>
              <Button
                variant="outlined"
                className={classes.buttonOutlined}
                style={{ backgroundColor: "#f6d6db" }}
                endIcon={<NavigateNextIcon />}
                onClick={() => {
                  setForm(true);
                  setPick(false);
                }}>
                <span style={{ textTransform: "uppercase" }}>L</span>anjut tanpa
                upload foto
              </Button>
            </Box>
          </Grid>
        </Paper>
      )}

      {isCropActive && (
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h4" className={classes.title}>
              Crop your Logo
            </Typography>
            <br />
            <br />
          </Grid>

          <Grid item sm={4} md={4} style={{ textAlign: "center" }}></Grid>
          <Grid item xs={12} sm={4} md={4} style={{ textAlign: "center" }}>
            <Paper className={classes.cardAvatar} elevation={2}>
              <br />
              <br />
              <Typography variant="h5" className={classes.title}>
                Logo Perusahaan
              </Typography>
              <br />
              <br />
              <AvatarEditor
                ref={setEditorRef}
                image={imgSrc}
                width={250}
                height={250}
                border={0}
                color={[255, 255, 255, 0.6]}
                scale={scale}
                rotate={0}
                style={{ borderRadius: "50%", border: "1px solid #e3e3e3" }}
              />
              <br />
              <span style={{ fontSize: "25px", fontWeight: "bold" }}>
                -
              </span>{" "}
              &nbsp;
              <input
                id="typeinp"
                type="range"
                min="1"
                max="5"
                value={scale}
                onChange={onChangeScale}
                step="0.1"
                className="slider-crop"
              />
              &nbsp;
              <span style={{ fontSize: "20px", fontWeight: "bold" }}>+</span>
              <Typography variant="subtitle2" className={classes.title}>
                Cropped Image : 100 x 100
              </Typography>
              <br />
              <br />
              <span
                // variant='subtitle1'
                className={classes.titleBack}
                onClick={handleBackInsideComponentCrop}>
                Kembali &nbsp;&nbsp;&nbsp;
              </span>
              <Button
                onClick={handleCropAndContinue}
                variant="contained"
                size="small"
                className={classes.button}>
                Crop & continue
              </Button>
              <br />
              <br />
            </Paper>
            <br />
            <br />
            <br />
            <br />
          </Grid>
          <Grid item sm={4} md={4} style={{ textAlign: "center" }}></Grid>
        </Grid>
      )}

      {isForm && (
        <FormCompletionProfileGroup
          classes={classes}
          imgSrc={imgSrc}
          nameFile={nameFile}
          contentFile={contentFile}
        />
      )}

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewCompletionProfileGroup);

import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AccessAlarmRoundedIcon from "@material-ui/icons/AccessAlarmRounded";

const LeftMenu = ({ classes }) => {
  const user = localStorage.getItem("status_user_login");
  const group_name = JSON.parse(user).group_name;

  return (
    <Box display="flex" alignItems="center">
      <Box mr={2}>
        <Typography variant="h6" className={classes.title333}>
          <b>Regular Meeting {group_name}</b>
        </Typography>
      </Box>

      <Box mr={6}>
        <Button variant="outlined" size="small" className={classes.btnNav}>
          Draft
        </Button>
      </Box>

      <Box display="flex" alignItems="center" mr={2}>
        <DateRangeIcon
          style={{ color: "#d64253", fontSize: 20, marginRight: 10 }}
        />
        <Typography variant="subtitle1">21 Februari 2021</Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <AccessAlarmRoundedIcon
          style={{ color: "#d64253", fontSize: 20, marginRight: 10 }}
        />
        <Typography variant="subtitle1">1 hr 30 min</Typography>
      </Box>
    </Box>
  );
};

export default LeftMenu;

import React, { useContext } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  Typography,
  Tooltip,
} from "@material-ui/core";
import FlagRoundedIcon from "@material-ui/icons/FlagRounded";

import { navigate } from "hookrouter";

import ContextGlobalDrive from "../../context/ContextDrive";
import TruncateTextShortNameGoal from "../../../../utilities/TruncateTextShortNameGoal";

function GoalGridGallery({ classes, item }) {
  const context = useContext(ContextGlobalDrive);

  // const evidenceCount = count[item.goal_id];

  //   console.log("Itung Golz", hitung.length);

  const handleNavigateGallery = (data) => {
    let breadcrumb = localStorage.getItem("breadcrumb");
    breadcrumb = breadcrumb ? breadcrumb.split("/") : [];
    let goalName = data.name.replace(/,+/g, " ");
    breadcrumb.push(goalName);
    localStorage.setItem("breadcrumb", breadcrumb.toString());

    let directory_id = localStorage.getItem("directory_id");
    directory_id = directory_id ? directory_id.split("/") : [];
    directory_id.push(data.id);
    localStorage.setItem("directory_id", directory_id.toString());

    navigate(`/drive/${data.id}`);
  };

  let statusId;

  if (item.status_id === "2") {
    statusId = "Status : Approved";
  } else if (item.status_id === "3") {
    statusId = "Status : Active";
  } else if (item.status_id === "6") {
    statusId = "Status : Over Due";
  } else if (item.status_id === "4") {
    statusId = "Status : Completed";
  }

  return (
    <Grid item md={3}>
      <Card
        variant="outlined"
        style={{
          marginTop: !context.toggleSelect ? 0 : -20,
          backgroundColor: "#fffee0",
        }}>
        <CardHeader
          avatar={
            <Avatar style={{ backgroundColor: "#ffbe76" }}>
              <FlagRoundedIcon style={{ color: "#e67e22" }} />
            </Avatar>
          }
          title={
            <Tooltip title={item !== undefined && item.name}>
              <Typography
                className={classes.folderNameText}
                variant="subtitle2"
                onClick={() => handleNavigateGallery(item)}>
                {TruncateTextShortNameGoal(item !== undefined && item.name)}
              </Typography>
            </Tooltip>
          }
          subheader={
            <Typography style={{ color: "#999", fontSize: 13 }}>
              {statusId}
            </Typography>
          }
        />
      </Card>
    </Grid>
  );
}

export default GoalGridGallery;

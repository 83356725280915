import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Divider,
  Grid,
  CircularProgress,
  Button,
  Tooltip,
} from "@material-ui/core";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import PercentagesSlider from "react-percentages-slider";
import "react-percentages-slider/dist/index.css";

import Axios from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";
import handleError from "../../../Report/components/global/handleError";
// import { FormatDecimal } from "../../../../utilities/FormatDecimal";

const DialogWeightMA = ({
  classes,
  openDialogWeightMA,
  setOpenDialogWeightMA,
}) => {
  const goalId = window.location.pathname.split("/")[2];

  const [maCollection, setMaCollection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadWeight, setLoadWeight] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (openDialogWeightMA) {
      setLoading(true);
      Axios.get(`${URL_API}/swd/measured-activity/weighting/${goalId}`).then(
        (res) => {
          const result = res.data.data;
          const listMA = result.ma_id.default_value;
          // console.log("Weighting REs", result);
          const percent = 100 / listMA.length;

          if (res.status === 200) {
            const addPer = listMA.map((el, i) => {
              const weight = el.weight;

              return {
                ...el,
                text: `MA ${i + 1}`,
                percentage: weight !== null ? Number(weight) : percent,
                color: colors[i],
              };
            });

            setMaCollection(addPer);
            setLoading(false);
          }
        }
      );
    }
  }, [openDialogWeightMA]);

  const handleWeightMA = () => {
    setLoadWeight(true);

    const mapMA =
      maCollection.length > 0 &&
      maCollection.map((el) => {
        return {
          ma_id: el.id,
          weight: Number(el.percentage).toFixed(),
        };
      });

    const data = {
      weightings: mapMA,
    };

    console.log("WEIGHT MAP", data);

    Axios.post(
      `${URL_API}/swd/measured-activity/weighting/${goalId}/store`,
      data
    )
      .then((res) => {
        console.log("RES WEIGHT", res);

        if (res.status === 200) {
          setLoadWeight(false);
          setOpenDialogWeightMA(false);
        }
      })
      .catch((error) => {
        console.log("Error : ", error);
        setLoadWeight(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={openDialogWeightMA}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Typography variant="subtitle1" className={classes.title}>
          <b>Set MA Weight</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ width: 700 }}>
        {loading ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={15} style={{ color: "#d1354a" }} />
            <Typography variant="subtitle2" className={classes.title888}>
              Load MA...
            </Typography>
          </Box>
        ) : (
          <PercentagesSlider
            divisions={maCollection}
            setDivisions={setMaCollection}
          />
        )}

        <Box my={4}>
          <Divider />
        </Box>

        <Grid container>
          <Grid item md={8}>
            <Typography variant="subtitle2" className={classes.title888}>
              Measured Activities
            </Typography>
            <Box mt={3}>
              {maCollection.map((item, i) => (
                <Typography
                  key={i}
                  variant="subtitle2"
                  className={classes.title888}
                  style={{ marginBottom: 10 }}>
                  <b>
                    ({item.text}) {item.name}
                  </b>
                </Typography>
              ))}
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box display="flex" alignItems="center">
              <Typography
                variant="subtitle2"
                className={classes.title888}
                style={{ marginRight: 5 }}>
                Weight
              </Typography>
              <Tooltip
                title="Maksimal nilai keseluruhan weight pada MA adalah 100% . Setiap ada perubahan MA maka akan diwajibkan untuk menyesuaikan kembali nilai weight."
                placement="bottom">
                <InfoRoundedIcon style={{ fontSize: 13, color: "#333" }} />
              </Tooltip>
            </Box>
            <Box mt={3}>
              {maCollection.map((item, i) => (
                <Typography
                  key={i}
                  variant="subtitle2"
                  className={classes.title888}
                  style={{ marginBottom: 10 }}>
                  <b>{Number(item.percentage).toFixed()} %</b>
                </Typography>
              ))}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        style={{
          alignItems: "center",
          justifyContent: "flex-end",
          margin: 15,
          marginTop: 20,
        }}>
        <Button
          variant="outlined"
          disableElevation
          size="medium"
          className={classes.btnWhite}
          onClick={() => setOpenDialogWeightMA(false)}>
          Cancel
        </Button>

        <Button
          variant="outlined"
          size="medium"
          className={classes.button}
          onClick={handleWeightMA}>
          {loadWeight ? "Saving..." : "Save"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogWeightMA;

const colors = [
  "#F6B03E",
  "#F47A48",
  "#DD668C",
  "#A05095",
  "#6D5A94",
  "#a29bfe",
  "#6AAFC1",
  "#74b9ff",
  "#55efc4",
  "#00b894",
  "#fab1a0",
  "#ff7675",
  "#fdcb6e",
  "#e17055",
  "#dfe6e9",
  "#b2bec3",
  "#F6B03E",
  "#F47A48",
  "#DD668C",
  "#A05095",
  "#6D5A94",
  "#a29bfe",
  "#6AAFC1",
  "#74b9ff",
  "#55efc4",
  "#00b894",
  "#fab1a0",
  "#ff7675",
  "#fdcb6e",
  "#e17055",
  "#dfe6e9",
  "#b2bec3",
];

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Backdrop,
  DialogTitle,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
// import PDFViewer from 'pdf-viewer-reactjs'

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#f28181",
  },
  fileText: {
    fontFamily: "Roboto",
    fontWeight: "600",
  },
}));

function DialogPreviewPDF({
  openDialogPreviewPDF,
  setOpenDialogPreviewPDF,
  item,
}) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pdfViewer, setPDFViewer] = useState(null);

  useEffect(() => {
    if (openDialogPreviewPDF === true) {
      setLoading(true);

      axiosConfig
        .get(
          `${URL_API}/swd/measured-activity-result/${item.measured_activity_result_id}/evidence/${item.id}/content?mode=thumbnail`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          // console.log("Response View Content", response);

          if (response.status === 200) {
            // setPDFViewer(response.config.url);
            const file = new Blob([response.data], { type: "application/pdf" });
            const obj_url = URL.createObjectURL(file);
            setPDFViewer(obj_url);
            // window.open(obj_url);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Error", error);
          setLoading(false);
        });
    }
  }, [openDialogPreviewPDF]);

  const handleClosePreview = () => {
    setLoading(false);
    setOpenDialogPreviewPDF(false);
  };

  return (
    <>
      {loading ? (
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={handleClosePreview}>
          <CircularProgress color="#f28181" />
        </Backdrop>
      ) : (
        <Dialog
          open={openDialogPreviewPDF}
          aria-labelledby="customized-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg" //*Default "md"
          style={{ height: "auto" }}>
          <DialogTitle id="customized-dialog-title">
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2" className={classes.fileText}>
                {item.label}
              </Typography>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => setOpenDialogPreviewPDF(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <iframe
              className={item.label}
              width="600"
              height="800"
              frameBorder="0"
              src={pdfViewer}></iframe>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default DialogPreviewPDF;

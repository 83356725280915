import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import ContextGoalDetail from "../../../7WD/Goal/Context/ContextGoalDetail";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

const DialogDelete = (props) => {
  const {
    classes,
    isModalDeleteMA,
    setModalDeleteMA,
    maDetailState,
    setMeasuredActivityDetailShow,
  } = props;

  const context = useContext(ContextGoalDetail);

  const [userTokenState, setUserTokenState] = useState("");
  const [loader, setLoader] = useState(false);

  /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */
  // const [ isModalResponse200, setModalResponse200 ] = useState(false);//*Moved To Global State

  useEffect(() => {
    if (isModalDeleteMA == true) {
      const userToken = localStorage.getItem("userToken");
      setUserTokenState(userToken);
    }
  }, [isModalDeleteMA]);

  const handleDelete = () => {
    setLoader(true);

    if (userTokenState !== "") {
      axiosConfig
        .delete(URL_API + `/swd/measured-activity/${maDetailState.id}`)
        .then(function (response) {
          console.log("Response Original : ", response);
          setLoader(false);

          // setModalDeleteMA(false);
          setMeasuredActivityDetailShow(true);

          context.setModalResponse200(true);
        })
        .catch(function (error) {
          console.log("Error : ", error.response);
          setLoader(false);

          setModalDeleteMA(false);
          alert("Whoops, something went wrong !");
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  return (
    <Fragment>
      <Dialog
        open={isModalDeleteMA}
        onClose={() => setModalDeleteMA(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          style={{ textAlign: "left" }}></DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "black" }}>
              <b>
                Apakah Anda yakin ingin menghapus <i>MA</i>:{" "}
                <i>{maDetailState.name}</i> ?
              </b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            onClick={handleDelete}
            variant="contained"
            className={classes.buttonModalDelete}
            // fullWidth
          >
            {loader === true ? (
              <CircularProgress size={16} style={{ color: "white" }} />
            ) : (
              "Yakin"
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>
    </Fragment>
  );
};

export default DialogDelete;

import React, { useState, useContext } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";

import ContextTask from "../Context/ContextTask";

import { URL_API } from "../../../constants/config-api";

const DialogDelete = (props) => {
  const {
    classes,
    isModalDeleteTDL,
    setModalDeleteTDL,
    handleCloseDropdownEditDelete,
  } = props;

  const context = useContext(ContextTask);

  const [loader, setLoader] = useState(false);

  const handleDelete = () => {
    setLoader(true);

    if (context.userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + context.userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .delete(URL_API + `/todolist/${context.dataDetailHoriz.id}`)
        .then(function (response) {
          setLoader(false);

          context.setAnchorEl(null);
          handleCloseDropdownEditDelete();

          console.log("Response Original : ", response);

          setModalDeleteTDL(false);
          context.setSucessDeleteTDL(true);

          context.setSnackbarResponse200(true);
          context.setMessages("Berhasil di hapus !");
        })
        .catch(function (error) {
          setLoader(false);
          console.log("Error : ", error.response);
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  return (
    <Dialog
      open={isModalDeleteTDL}
      onClose={() => setModalDeleteTDL(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle
        id="alert-dialog-title"
        style={{ textAlign: "left" }}></DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <DialogContentText id="alert-dialog-description">
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}>
            <b>
              Apakah Anda yakin ingin hapus{" "}
              {context.dataDetailHoriz.name !== ""
                ? context.dataDetailHoriz.name
                : ""}{" "}
              ?
            </b>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ alignItems: "center", justifyContent: "center" }}>
        <Button
          onClick={handleDelete}
          variant="contained"
          className={classes.button}
          // fullWidth
        >
          {loader !== true ? (
            "Yakin"
          ) : (
            <CircularProgress size={20} style={{ color: "white" }} />
          )}
        </Button>
      </DialogActions>
      <br />
    </Dialog>
  );
};

export default DialogDelete;

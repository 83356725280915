import React, {
  Component,
  useState,
  useEffect,
  useContext,
  useRef,
  Fragment,
} from "react";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItemText,
  Radio,
  IconButton,
  InputAdornment,
} from "@material-ui/core";

import { createMuiTheme } from "@material-ui/core/styles";

import MomentUtils from "@date-io/moment";
import moment from "moment";

import axiosConfig from "../../../../constants/config-axios";

import ButtonMaHaveConnectedWithGoal from "./ButtonMaHaveConnectedWithGoal";
import ButtonIconPositionTitle from "./ButtonIconPositionTitle";
import ButtonListItemPhotoAndOwnerName from "./ButtonListItemPhotoAndOwnerName";

import PeriodListMenu from "./PeriodListMenu";
import ComponentDueDate from "./ComponentDueDate";

// import ContextGoalDetail from '../../Goal/Context/ContextGoalDetail';
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";
import Snackbeer from "../../../../components/Snackbeer";
import DialogError from "../../../../components/DialogError";
import LightTooltip from "../../../../components/LightTooltip";

import { URL_API } from "../../../../constants/config-api";
import Capitalize from "../../../../utilities/Capitalize";
import DialogSetWeight from "./DialogSetWeight";
import DialogDateRange from "../../Goal/Components/DialogDateRange";

import {
  STATUS_ACTIVE,
  STATUS_APPROVED,

  //*CALCULATION METHOD
  SWD_MA_CALCULATION_METHOD_SUM,
  SWD_MA_CALCULATION_METHOD_AVERAGE,
  SWD_MA_CALCULATION_METHOD_LAST_VALUE,

  //*INPUT METHOD
  SWD_MA_INPUT_METHOD_LAST_VALUE,
  SWD_MA_INPUT_METHOD_INCREMENT,

  //*VALUE TYPE
  NUMERIC,
  PERCENTAGE,

  //*ACHIEVEMENT TYPE
  SWD_MA_ACHIEVEMENT_TYPE_MORE_ORIENTED,
  SWD_MA_ACHIEVEMENT_TYPE_LESS_ORIENTED,
} from "../../../../constants/config-status-goal";

import "moment/locale/id";

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const DialogCreateMA = (props) => {
  const {
    classes,
    isModalCreateMA,
    setModalCreateMA,
    collectionMA,
    setColletionMA,
    goalDetailState,
    setOpenDialogSetWeight,
  } = props;

  // const context = useContext(ContextGoalDetail);

  /*
        ````````
        USE REFF

        ````````
    */
  let textInputReff = useRef(null);
  const [ownerMAName, setOwnerMAName] = useState("");

  /*
        ```````````````````
        COMPONENT DID MOUNT 

        ```````````````````
    */
  const userToken = localStorage.getItem("userToken");

  const [startDateRangeState, setStartDateRangeState] = useState(null);
  const [endDateRangeState, setEndDateRangeState] = useState(null);
  const startDateRange = localStorage.getItem("start_date");
  const endDateRange = localStorage.getItem("end_date");

  const [userTokenState, setUserTokenState] = useState("");
  const [positionList, setPositionList] = useState([]);
  const [calculationMethodList, setCalculationMethodList] = useState([]);
  const [inputMethodList, setInputMethodList] = useState([]);
  const [inputValueTypeList, setInputValueTypeList] = useState([]);
  const [achievementTypeCollections, setAchievementTypeCollections] = useState(
    []
  );
  const [targetTypeList, setTargetTypeList] = useState([]);

  const [periodCollections, setPeriodCollections] = useState([]);
  const [memberPositionList, setMemberPositionList] = useState([]);

  const [statusUsedACTIVE, setStatusUsedACTIVE] = useState(false);

  const [fotoQueryContent, setFotoQueryContent] = useState("");
  const [goalId, setGoalId] = useState("");
  const [positionTitleName, setPositionTitleName] = useState("");

  const [timeFrameId, setTimeFrameId] = useState(null);
  const [periodId, setPeriodId] = useState(null);
  const [periodName, setPeriodName] = useState("");

  // const [openDialogSetWeight, setOpenDialogSetWeight] = useState(false);

  /*
        HANDLE SNACKBEER 
    */
  const [isModalResponse200, setModalResponse200] = useState(false);

  useEffect(() => {
    if (isModalCreateMA === true) {
      console.log("Goal Detail State : ", goalDetailState);

      setStartValue("0");
      setNilaiTarget("");

      if (goalDetailState.id !== null) {
        setGoalId(goalDetailState.id);
        setTimeFrameId(goalDetailState.time_frame_id);

        setPositionTitleName(
          goalDetailState.owner.structure_position_title_name !== null
            ? goalDetailState.owner.structure_position_title_name
            : "-"
        );

        setOwnerId(goalDetailState.owner_id);

        setStartDateRangeState(goalDetailState.calculatedValue.start_date);
        setEndDateRangeState(goalDetailState.calculatedValue.end_date);

        if (goalDetailState.member !== null) {
          setOwnerMAName(
            goalDetailState.member.first_name +
              " " +
              goalDetailState.member.last_name
          );
          setFotoQueryContent(goalDetailState.member.self.rel.photo_url);

          if (goalDetailState.period !== null) {
            setPeriodId(goalDetailState.period_id);
            setPeriodName(goalDetailState.period.name);
          }
        } else {
          setOwnerMAName("-");
          // setFotoQueryContent(goalDetailState.member.self.rel.photo_url);
        }
      }

      // setFotoQueryContent(goalDetailState.member.self.rel.photo_url);
      // setShowTextDateRange(false);

      /* 
                ```````````````````
                Focus in Text Field

                ```````````````````
            */
      setTimeout(() => {
        textInputReff.current.focus();
      }, 100);

      setBigGoal("");
      setDisabledButton(false);

      //* ****************************************** */
      setUserTokenState(userToken);
      setLoader(false);
      setDisabledButton(false);

      if (userToken !== undefined || userToken !== null) {
        axiosConfig
          .get(
            URL_API +
              `/swd/measured-activity/create?goal_id=${
                goalDetailState.id !== null ? goalDetailState.id : ""
              }`
          )
          .then(function (response) {
            console.log("Response Original Label : ", response);

            if (response.status === 200) {
              setSelectedValueCalcMethod({
                id: "1",
                code: SWD_MA_CALCULATION_METHOD_SUM,
                name: "sum",
              });

              setCalculationMethodList([
                {
                  code: SWD_MA_CALCULATION_METHOD_SUM,
                  id: "1",
                  name: "sum",
                },
                {
                  code: SWD_MA_CALCULATION_METHOD_AVERAGE,
                  id: "2",
                  name: "average",
                },
              ]);

              setInputMethodList(response.data.data.inputMethodCollections);
              setInputValueTypeList(response.data.data.valueTypeCollections);
              setAchievementTypeCollections(
                response.data.data.achievementTypeCollections
              );
              setTargetTypeList(response.data.data.targetTypeCollections);
              setPeriodCollections(response.data.data.periodCollections);
            }
          })
          .catch(function (error) {
            if (error.response !== undefined) {
              if (
                error.response.data !== null &&
                error.response.data !== undefined
              ) {
                if (
                  error.response.data.info !== null &&
                  error.response.data.info !== undefined
                ) {
                  if (
                    error.response.data.info.errors !== null &&
                    error.response.data.info.errors !== undefined
                  ) {
                    if (
                      error.response.data.info.errors !== null &&
                      error.response.data.info.errors !== undefined
                    ) {
                      if (error.response.status == 422) {
                        setErrorStatus(422);

                        if (error.response.data.info.errors.length > 0) {
                          setListError(error.response.data.info.errors);
                          setOpenDialogError(true);
                        }
                      }
                    }
                  }
                }
              }
            }
            console.log("Error : ", error.response);
          });
      } else {
        console.log("No Access Token available!");
        setOpenDialogError(true);
      }
    }
  }, [isModalCreateMA]);

  /*
        `````````````````````
        CHECK IS IMAGE IS OKE
        
        `````````````````````
    */
  useEffect(() => {
    if (goalDetailState.member !== null) {
      setFotoQueryContent(goalDetailState.member.self.rel.photo_url);
    }

    if (isModalCreateMA == true && goalDetailState.member !== null) {
      if (goalDetailState.id !== null) {
        if (fotoQueryContent == "") {
          if (userToken !== undefined) {
            axiosConfig
              .get(
                URL_API +
                  `/${
                    goalDetailState.id !== null
                      ? goalDetailState.member.self.rel.photo_url
                      : undefined
                  }&token=${userToken}`
              )
              .then(function (response) {
                console.log("Response Original FOTO : ", response);
                setFotoQueryContent(goalDetailState.member.self.rel.photo_url);
              })
              .catch(function (error) {
                console.log("Error : ", error.response);
              });
          } else {
            console.log("No Access Token available!");
          }
        }
      }
    }
  }, [goalDetailState, isModalCreateMA]);

  useEffect(() => {
    if (startDateRange !== null && endDateRange !== null) {
      setStartDateRangeState(startDateRange);
      setEndDateRangeState(endDateRange);
    }
  }, [startDateRange, endDateRange]);

  /*
        ``````````````````````
        HANDLE CHANGE BIG GOAL

        ```````````````````
    */
  const [bigGoal, setBigGoal] = useState("");
  const handleChangeBIGGoal = (e) => {
    setBigGoal(e.target.value);
  };

  /*
        `````````````````````````
        HANDLE CHANGE DESCRIPTION

        `````````````````````````
    */
  const [description, setDescription] = useState("");
  const handleChangeDescription = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  /*
        ``````````````````````````````````
        HANDLE SELECTED CALCULATION METHOD 

        `````````````````````````````````
    */
  const [selectedValueCalcMethod, setSelectedValueCalcMethod] = useState({
    id: "1",
    code: SWD_MA_CALCULATION_METHOD_SUM,
    name: "sum",
  });

  const handleChangeRadioButton = (e, data) => {
    e.preventDefault();
    setSelectedValueCalcMethod(data);

    console.log("Data Calculation Method : ", data);

    if (data.id === "3") {
      //*if Calculation Method Last Value

      setSelectedValueInputMethod({
        id: "1",
        code: "swd_ma_input_method_last_value",
        name: "last value",
      });
    } else {
      setSelectedValueInputMethod({
        id: "2",
        code: "swd_ma_input_method_increment",
        name: "increment",
      });
    }

    if (data.id === "2") {
      setTargetTypeList([
        {
          code: "swd_ma_target_type_final",
          id: "2",
          name: "Final",
        },
      ]);

      setTargetType({
        code: "swd_ma_target_type_final",
        id: "2",
        name: "Final",
      });
    } else {
      setTargetTypeList([
        {
          code: "swd_ma_target_type_daily",
          id: "1",
          name: "Daily",
        },
        {
          code: "swd_ma_target_type_final",
          id: "2",
          name: "Final",
        },
      ]);

      setTargetType({
        code: "swd_ma_target_type_daily",
        id: "1",
        name: "Daily",
      });
    }
  };

  /*
        ``````````````````````````
        HANDLE CHANGE NILAI TARGET

        ``````````````````````````
    */
  const [nilaiTarget, setNilaiTarget] = useState("");
  const handleChangeNilaiTarget = (e) => {
    e.preventDefault();
    setNilaiTarget(e.target.value);
  };

  /*
        ``````````````````````````````````
        HANDLE SELECTED INPUT METHOD 

        `````````````````````````````````
    */
  const [selectedValueInputMethod, setSelectedValueInputMethod] = useState({
    id: "2",
    code: SWD_MA_INPUT_METHOD_INCREMENT,
    name: "increment",
  });

  const handleChangeRadioButtonInputMethod = (e, data) => {
    e.preventDefault();
    setSelectedValueInputMethod(data);

    console.log("Data Input Method : ", data);

    if (data.code === SWD_MA_INPUT_METHOD_INCREMENT) {
      // setShowSixRadioButton(true);

      setCalculationMethodList([
        {
          id: "1",
          code: SWD_MA_CALCULATION_METHOD_SUM,
          name: "sum",
        },
        {
          id: "2",
          code: SWD_MA_CALCULATION_METHOD_AVERAGE,
          name: "average",
        },
      ]);

      setSelectedValueCalcMethod({
        id: "1",
        code: SWD_MA_CALCULATION_METHOD_SUM,
        name: "sum",
      });
    } else {
      // setShowSixRadioButton(false);
      setCalculationMethodList([
        {
          code: SWD_MA_CALCULATION_METHOD_LAST_VALUE,
          id: "3",
          name: "last value",
        },
      ]);

      setSelectedValueCalcMethod({
        id: "3",
        code: SWD_MA_CALCULATION_METHOD_LAST_VALUE,
        name: "last value",
      });

      setTargetTypeList([
        {
          code: "swd_ma_target_type_daily",
          id: "1",
          name: "Daily",
        },
        {
          code: "swd_ma_target_type_final",
          id: "2",
          name: "Final",
        },
      ]);
    }
  };

  /*
        ``````````````````````````````````
        HANDLE SELECTED  VALUE METHOD 

        `````````````````````````````````
    */

  const [selectedValueType, setSelectedValueType] = useState({
    id: "1",
    code: NUMERIC,
    name: "numeric",
  });

  const [isShowPercent, setShowPercent] = useState(false);
  const handleChangeRadioButtonValueType = (e, data) => {
    e.preventDefault();
    setSelectedValueType(data);

    if (data.code == PERCENTAGE) {
      setShowPercent(true);
    } else {
      setShowPercent(false);
    }
  };

  /*
        ``````````````````````````````````
        SET TARGET TYPE

        `````````````````````````````````
    */

  const [targetType, setTargetType] = useState({
    code: "swd_ma_target_type_daily",
    id: "1",
    name: "Daily",
  });

  const handleSelectTargetType = (e, data) => {
    e.preventDefault();
    setTargetType(data);
  };

  /*
        ````````````````````````````````
        HANDLE SELECTED ACHIEVEMENT TYPE 

        ````````````````````````````````
    */
  const [selectedValueAchievementType, setSelectedValueAchievementType] =
    useState({
      id: "1",
      code: SWD_MA_ACHIEVEMENT_TYPE_MORE_ORIENTED,
      name: "more oriented",
    });

  const handleChangeRadioButtonAchievementType = (e, data) => {
    e.preventDefault();
    setSelectedValueAchievementType(data);
  };

  /*
        `````````````````
        DATE RANGE OPTION

        `````````````````
    */
  const [isShowDateRangeComponent, setShowDateRangeComponent] = useState(false);
  // const [ isShowTextDateRange, setShowTextDateRange ] = useState(true);

  // const handleChooseRange = () => {

  //     setShowDateRangeComponent(true);
  //     setShowTextDateRange(true);
  // };

  /*
        `````````````````````````
        HANDLE CHANGE START VALUE

        `````````````````````````
    */
  const [startValue, setStartValue] = useState("0");
  const handleChangeStartValue = (e) => {
    e.preventDefault();
    setStartValue(e.target.value);
  };

  /*
        ````````````````````````````````
        HANDLE CHANGE TARGET RANGE VALUE

        ````````````````````````````````
    */
  const [targetRangeValue, setTargetRangeValue] = useState("");
  const handleChangeTargetRangeValue = (e) => {
    e.preventDefault();
    setTargetRangeValue(e.target.value);
  };

  /*
        ```````````````````````````````````````````````
        HANDLE SHOW COMPONENENT TEXT FIELD RANGE VALUE

        ```````````````````````````````````````````````
    */
  const [isShowTextFieldTargetRangeValue, setShowTextFieldTargetRangeValue] =
    useState(false);

  // useEffect(() => {

  //     if(startValue === nilaiTarget){

  //         setShowTextFieldTargetRangeValue(true)

  //     } else {

  //         setShowTextFieldTargetRangeValue(false)
  //     }

  // },[startValue, nilaiTarget])

  useEffect(() => {
    //*
    if (
      startValue === nilaiTarget &&
      (selectedValueCalcMethod.code === SWD_MA_CALCULATION_METHOD_SUM ||
        selectedValueCalcMethod.code === SWD_MA_CALCULATION_METHOD_LAST_VALUE)
    ) {
      setShowTextFieldTargetRangeValue(true);
    } else if (
      nilaiTarget === "0" &&
      selectedValueCalcMethod.code === SWD_MA_CALCULATION_METHOD_AVERAGE
    ) {
      setShowTextFieldTargetRangeValue(true);
    } else {
      setShowTextFieldTargetRangeValue(false);
    }
  }, [startValue, nilaiTarget, selectedValueCalcMethod]);

  // useEffect(() => {
  //   if (isModalResponse200) {
  //     setOpenDialogSetWeight(true);
  //   }
  // }, [isModalResponse200]);

  /*
        `````````````````````````
        HANDLE BUTTON SUBMIT GOAL

        `````````````````````````
    */
  const [loader, setLoader] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const handleSUBMITGoal = () => {
    setLoader(true);
    setDisabledButton(true);

    let data = {
      MeasuredActivity: {
        name: bigGoal,
        start_value: startValue !== "0" ? startValue : 0,
        target_value: nilaiTarget,
        target_range_value:
          targetRangeValue !== null && targetRangeValue !== ""
            ? targetRangeValue
            : null,
        input_method_id: selectedValueInputMethod.id,
        calculation_method_id: selectedValueCalcMethod.id,
        value_type_id: selectedValueType.id,
        achievement_type_id: selectedValueAchievementType.id,
        time_frame_id: timeFrameId !== null ? timeFrameId : null,
        period_id: periodId !== "" ? periodId : null, //*Mandatory if time_frame_id === 1

        start_date:
          startDateRangeState === null
            ? moment(selectedDueDate).format("YYYY-MM-DD")
            : startDateRangeState,
        end_date:
          endDateRangeState === null
            ? moment(selectedDueDate).format("YYYY-MM-DD")
            : endDateRangeState,

        description: description,
        owner_id: ownerId,
        is_evidence_required: evidance === true ? 1 : 0, //*0 === Tidak butuh evidence, 1 === Butuh evidence
        goal_id: goalId,
        target_type_id: targetType.id,
      },
    };

    if (data.MeasuredActivity.period_id === null) {
      delete data.MeasuredActivity.period_id;
    }

    if (
      data.MeasuredActivity.period_id !== null &&
      data.MeasuredActivity.start_date === null &&
      data.MeasuredActivity.end_date === "Invalid date"
    ) {
      delete data.MeasuredActivity.start_date;
      delete data.MeasuredActivity.end_date;
    }

    if (data.MeasuredActivity.start_date === null) {
      delete data.MeasuredActivity.start_date;
    }

    if (
      data.MeasuredActivity.target_range_value === null ||
      data.MeasuredActivity.target_range_value === ""
    ) {
      delete data.MeasuredActivity.target_range_value;
    }

    if (
      data.MeasuredActivity.period_id === null ||
      data.MeasuredActivity.period_id === ""
    ) {
      delete data.MeasuredActivity.period_id;
    }

    console.log("Data SUBMIT : ", data);

    if (userTokenState !== "") {
      axiosConfig
        .post(URL_API + `/swd/measured-activity?goal_id=${goalId}`, data)
        .then(function (response) {
          setLoader(false);
          setModalResponse200(true);
          setOpenDialogSetWeight(true);

          console.log("Response Original : ", response);

          if (response.status === 200) {
            setModalCreateMA(false);
            setColletionMA([...collectionMA, response.data.data]);
          }
        })
        .catch(function (error) {
          setLoader(false);
          setDisabledButton(false);

          if (error.response !== undefined) {
            if (error.response.status == 400) {
              setErrorStatus(400);
              setTextErrorInformationValue("");

              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setListError(error.response.data.info.errors);
                  setOpenDialogError(true);
                } else {
                  if (
                    error.response.data.info.errors.hasOwnProperty("end_date")
                  ) {
                    setOpenDialogError(true);
                    setTextErrorInformationValue(
                      "Error 400 : " +
                        Capitalize(error.response.data.info.errors.end_date)
                    );
                  }

                  if (
                    error.response.data.info.errors.hasOwnProperty(
                      "calculation_method_id"
                    )
                  ) {
                    setOpenDialogError(true);
                    setTextErrorInformationValue(
                      "Error 400 : " +
                        Capitalize(
                          error.response.data.info.errors.calculation_method_id
                        )
                    );
                  }

                  if (
                    error.response.data.info.errors.hasOwnProperty("owner_id")
                  ) {
                    setOpenDialogError(true);
                    setTextErrorInformationValue(
                      "Error 400 : " +
                        Capitalize(error.response.data.info.errors.owner_id)
                    );
                  }

                  if (error.response.data.info.errors.hasOwnProperty("goal")) {
                    setOpenDialogError(true);
                    setTextErrorInformationValue(
                      "Error 400 : " +
                        Capitalize(error.response.data.info.errors.goal)
                    );
                  }
                }
              }
            }

            if (error.response.status == 422) {
              setErrorStatus(422);
              setListError(error.response.data.info.errors);
              setOpenDialogError(true);
            }

            if (error.response.status == 500) {
              setErrorStatus(500);
              setTextErrorInformationValue("");
              if (error.response.data.hasOwnProperty("message")) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  "Error 500 : " + Capitalize(error.response.data.message)
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }

          console.log("Error : ", error.response);
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  /*
        `````````````````
        DROPDOWN POSITION

        `````````````````
    */

  const [ownerId, setOwnerId] = useState("");

  /*
        ``````````````````````````
        HANDLE CHOOSE MEMBER OWNER

        ``````````````````````````
    */
  const [choosedMemberOwner, setChoosedMemberOwner] = useState("");

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */

  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] = useState(
    "Anda tidak memiliki izin mengakses halaman ini !"
  );
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  /*
        ````````
        EVIDANCE

        ````````
    */
  const [evidance, setEvidance] = useState(false);

  /*
        ``````````````````````````````
        HANDLE SPEED DIAL & TIME FRAME

        ``````````````````````````````
    */
  const [anchorElTimeFrame, setAnchorElTimeFrame] = useState(null);

  function handleClickTimeFrame(event) {
    setAnchorElTimeFrame(event.currentTarget);
  }

  function handleCloseTimeFrame() {
    setAnchorElTimeFrame(null);
  }

  /*
        ```````````````
        HANDLE DUE DATE

        ```````````````
    */
  const [isShowDueDateComponentDirectly, setShowDueDateComponentDirectly] =
    useState(false);
  const [selectedDueDate, setSelectedDueDate] = useState("");

  /*
        ```````````````````````````````````
        HANDLE PERIOD LIST STYLED MENU ITEM

        ```````````````````````````````````
    */

  const [anchorElPeriod, setAnchorElPeriod] = useState(null);
  function handleChoosePeriod(event) {
    //*Fungsi setAnchor di sini sudah di pakai langsung di 'handleChoosePeriod()'

    // console.log("Event PEriod : ", event)
    setAnchorElPeriod(event.currentTarget);
    // setShowPeriodComponent(true);

    handleCloseTimeFrame();
  }

  function handleClosePeriod() {
    setAnchorElPeriod(null);
  }

  const handlePilihPeriod = (e, data) => {
    // context.setPeriod(data);
    // context.setTimeFrameId("1");

    console.log("data period : ", data);

    setPeriodName(data.name);
    setPeriodId(data.id);
    setTimeFrameId("1");
    handleClosePeriod();
  };

  return (
    <Fragment>
      <Dialog
        open={isModalCreateMA}
        onClose={() => setModalCreateMA(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography variant="h6" className={classes.title}>
            <b>Buat Measured Actvities</b>
          </Typography>
        </DialogTitle>

        <DialogContent style={{ textAlign: "left" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="subtitle1" className={classes.title}>
              <b>
                Tulis di sini <i>Measured Activity</i> Anda
              </b>
            </Typography>
            <TextField
              inputRef={textInputReff}
              id="outlined-bare"
              className={classes.textField}
              onChange={handleChangeBIGGoal}
              value={bigGoal}
              variant="outlined"
              fullWidth
              inputProps={{
                className: classes.title,
              }}
            />
          </DialogContentText>

          <Grid container>
            <Grid item sm={6} md={6}>
              <ButtonListItemPhotoAndOwnerName
                classes={classes}
                fotoQueryContent={fotoQueryContent}
                choosedMemberOwner={choosedMemberOwner}
                ownerMAName={ownerMAName}
              />
            </Grid>

            <Grid item sm={6} md={6}>
              {/* <Button 
                                variant='outlined' 
                                size='small' 
                                className={classes.timeFrameIcontDateRangeText} 
                                style={{borderWidth: 0, marginTop: 40}}
                                onClick={() => handleChooseRange(true)}
                            >
                                <b>{ moment(startDateRangeState).format('DD MMMM') } - { moment(endDateRangeState).format('DD MMMM YYYY') }</b>
                            </Button>

                            {
                                isShowDateRangeComponent == true && (
                                    
                                    <Fragment>

                                        <DialogDateRange 
        
                                            classes = { classes }
                                            userTokenState = { userTokenState }
                                            isShowDateRangeComponent = { isShowDateRangeComponent }
                                            setShowDateRangeComponent = { setShowDateRangeComponent }
        
                                        />                            
                                    </Fragment>
                                )
                            }                             */}

              {/* 

                                `````````````````
                                TIME FRAME BUTTON

                                `````````````````                        
                            */}
              <Button
                onClick={handleClickTimeFrame}
                variant="outlined"
                size="small"
                className={classes.timeFrameIconInModal}
                style={{ marginTop: 24 }}
                disabled={disabledButton == true ? true : false}>
                <IconButton style={{ background: "#edcfd8" }}>
                  <i
                    className="material-icons"
                    style={{ color: "white", fontSize: 14 }}>
                    calendar_today
                  </i>
                </IconButton>
                &nbsp;
                <b>
                  Pilih <i>Timeframe</i>
                </b>
              </Button>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElTimeFrame}
                keepMounted
                open={Boolean(anchorElTimeFrame)}
                onClose={handleCloseTimeFrame}>
                <StyledMenuItem
                  // onClick={handleChooseDueDate}
                  onClick={() => {
                    setShowDueDateComponentDirectly(true);
                    setStartDateRangeState(null);
                    setEndDateRangeState(null);
                    setTimeFrameId("3");
                    handleCloseTimeFrame();
                  }}
                  disabled={statusUsedACTIVE === true ? true : false}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Due Date</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                <StyledMenuItem
                  // onClick= { handleChooseRange }
                  onClick={() => {
                    setShowDateRangeComponent(true);
                    setTimeFrameId("2");
                    handleCloseTimeFrame();
                  }}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Date Range</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                <StyledMenuItem
                  onClick={handleChoosePeriod}
                  // onClick={handleChoosePeriod}
                >
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Period</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>
              </StyledMenu>

              <PeriodListMenu
                periodCollections={periodCollections}
                anchorElPeriod={anchorElPeriod}
                handleClosePeriod={handleClosePeriod}
                handlePilihPeriod={handlePilihPeriod}
              />

              {isShowDueDateComponentDirectly === true && (
                <ComponentDueDate
                  selectedDueDate={selectedDueDate}
                  setSelectedDueDate={setSelectedDueDate}
                  isShowDueDateComponentDirectly={
                    isShowDueDateComponentDirectly
                  }
                  setShowDueDateComponentDirectly={
                    setShowDueDateComponentDirectly
                  }
                />
              )}

              {isShowDateRangeComponent == true && (
                <Fragment>
                  <DialogDateRange
                    classes={classes}
                    userTokenState={userTokenState}
                    isShowDateRangeComponent={isShowDateRangeComponent}
                    setShowDateRangeComponent={setShowDateRangeComponent}
                  />
                </Fragment>
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              {timeFrameId === "1" && ( //if PERIOD
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIcontDateRangeText}
                  style={{ marginRight: 8, borderWidth: 0 }}
                  // onClick={() => setShowDateRangeComponent(true)}
                >
                  <b>{periodName}</b>
                </Button>
              )}

              {timeFrameId === "2" && ( //if DATE RANGE
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIcontDateRangeText}
                  style={{ marginRight: 8, borderWidth: 0 }}
                  onClick={() => setShowDateRangeComponent(true)}>
                  <b>
                    {moment(startDateRangeState).format("DD MMMM YYYY")} -{" "}
                    {moment(endDateRangeState).format("DD MMMM YYYY")}
                  </b>
                </Button>
              )}

              {timeFrameId === "3" && ( //if DUE DATE
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIcontDateRangeText}
                  style={{ marginRight: 8, borderWidth: 0 }}
                  onClick={() => setShowDueDateComponentDirectly(true)}>
                  <b>{moment(selectedDueDate).format("DD MMMM YYYY")}</b>
                </Button>
              )}
            </Grid>
          </Grid>

          <ButtonMaHaveConnectedWithGoal classes={classes} />
          <br />
          <br />

          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "grey" }}>
            <b>Deskripsi (Optional)</b>
          </Typography>
          <TextField
            onChange={handleChangeDescription}
            style={{ marginTop: 0 }}
            id="outlined-multiline-static-description"
            // label="Multiline"
            multiline
            rows="4"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            fullWidth
          />
          <br />
          <br />

          <Grid container>
            <Grid item sm={6} md={6}>
              <ButtonIconPositionTitle
                classes={classes}
                positionTitleName={positionTitleName}
              />
            </Grid>

            <Grid item sm={6} md={6}>
              {isShowTextFieldTargetRangeValue === true ? (
                <Fragment>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    <b>Target Range value : </b>
                  </Typography>
                  <Box>
                    <TextField
                      placeholder="0"
                      type="number"
                      id="outlined-bare"
                      // onChange= { handleChangeStartValue }
                      onChange={handleChangeTargetRangeValue}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              variant="subtitle1"
                              className={classes.title}
                              style={{ color: "grey" }}>
                              {isShowPercent === true ? <b>%</b> : null}
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                      disabled={
                        selectedValueCalcMethod.code ===
                        SWD_MA_CALCULATION_METHOD_AVERAGE
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Fragment>
              ) : (
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      // color="primary"
                      size="small"
                      checked={evidance}
                      onChange={(e) => setEvidance(e.target.checked)}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "grey" }}>
                      Wajib lampirkan bukti gambar
                    </Typography>
                  }
                  labelPlacement="end"
                />
              )}
            </Grid>
          </Grid>

          <br />
          <br />
          <Grid container>
            <Grid item xs={6} md={6}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}>
                <b>Start Value</b>
              </Typography>
              <Box marginRight={theme.spacing(0.2)}>
                <TextField
                  id="outlined-bare"
                  // className={classes.textField}
                  onChange={handleChangeStartValue}
                  value={startValue}
                  variant="outlined"
                  // fullWidth
                  disabled={
                    selectedValueCalcMethod.code ===
                    SWD_MA_CALCULATION_METHOD_AVERAGE
                      ? true
                      : false
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          variant="subtitle1"
                          className={classes.title}
                          style={{ color: "grey" }}>
                          {isShowPercent == true ? <b>%</b> : null}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={6} md={6}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}>
                <b>Nilai Target</b>
              </Typography>
              <TextField
                id="outlined-bare"
                // className={classes.textField}
                onChange={handleChangeNilaiTarget}
                value={nilaiTarget}
                variant="outlined"
                // fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography
                        variant="subtitle1"
                        className={classes.title}
                        style={{ color: "grey" }}>
                        {isShowPercent == true ? <b>%</b> : null}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <br />
          <LightTooltip
            title={
              <div>
                Pilihan More: Semakin besar nilai, semakin baik. <br />
                Pilihan Less: Semakin kecil nilai, semakin baik
              </div>
            }
            placement="top">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Achievement Type :</b>
            </Typography>
          </LightTooltip>

          <List>
            {achievementTypeCollections.length > 0 &&
              achievementTypeCollections.map((item, i) => (
                <Fragment key={i}>
                  <Radio
                    checked={selectedValueAchievementType.id === item.id}
                    onChange={(e) =>
                      handleChangeRadioButtonAchievementType(e, item)
                    }
                    value={item.id}
                    name={item.name}
                  />
                  <span style={{ fontFamily: "Roboto", color: "grey" }}>
                    {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                  </span>
                </Fragment>
              ))}
          </List>

          <br />
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "grey" }}>
            <b>Input Method :</b>
          </Typography>
          <List>
            {inputMethodList.length > 0 &&
              inputMethodList.map((item, i) => (
                <Fragment key={i}>
                  <Radio
                    checked={selectedValueInputMethod.code == item.code}
                    onChange={(e) =>
                      handleChangeRadioButtonInputMethod(e, item)
                    }
                    value={item.id}
                    name={item.name}
                  />
                  <span style={{ fontFamily: "Roboto", color: "grey" }}>
                    {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </Fragment>
              ))}
          </List>

          <br />
          <LightTooltip
            title={
              <div>
                Pilihan <i>Numeric</i>: Data input berupa angka aktual. <br />
                Pilihan <i>Percentage</i>: Data input berupa persen
              </div>
            }
            placement="top">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Value Type : </b>
            </Typography>
          </LightTooltip>

          <List>
            {inputValueTypeList.length > 0 &&
              inputValueTypeList.map((item, i) => (
                <Fragment key={i}>
                  <Radio
                    checked={selectedValueType.code === item.code}
                    onChange={(e) => handleChangeRadioButtonValueType(e, item)}
                    value={item.id}
                    name={item.name}
                  />
                  <span style={{ fontFamily: "Roboto", color: "grey" }}>
                    {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                  </span>
                </Fragment>
              ))}
          </List>

          <br />
          <LightTooltip
            title={
              <div>
                SUM: Penjumlahan dari semua Goal Result. <br />
                AVG: Rata-rata dari semua Goal Result <br />
                LAST VALUE: Hanya untuk "Input Method - Total"
              </div>
            }
            placement="top">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Calculation Method :</b>
            </Typography>
          </LightTooltip>

          <List>
            {calculationMethodList.length > 0 &&
              calculationMethodList.map((item, i) => {
                return (
                  <Fragment key={i}>
                    <Radio
                      checked={selectedValueCalcMethod.code === item.code}
                      onChange={(e) => handleChangeRadioButton(e, item)}
                      value={item.id}
                      name={item.name}
                      // disabled={item.code == 'last_value' ? true : false }
                    />
                    <span style={{ fontFamily: "Roboto", color: "grey" }}>
                      {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                    </span>
                  </Fragment>
                );
              })}
          </List>

          <br />
          <LightTooltip
            title={
              <div>
                Daily: Perhitungan yang digunakan adalah target di hari yang
                sedang berjalan <br />
                Final: Perhitungan yang digunakan adalah target di akhir periode
              </div>
            }
            placement="top">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Target Type :</b>
            </Typography>
          </LightTooltip>
          <List>
            {targetTypeList.length > 0 &&
              targetTypeList.map((item, i) => {
                return (
                  <Fragment key={i}>
                    <Radio
                      checked={targetType.code === item.code}
                      onChange={(e) => handleSelectTargetType(e, item)}
                      value={item.id}
                      name={item.name}
                    />
                    <span style={{ fontFamily: "Roboto", color: "grey" }}>
                      {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                    </span>
                  </Fragment>
                );
              })}
          </List>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            onClick={handleSUBMITGoal}
            variant="contained"
            className={classes.button}
            fullWidth
            disabled={disabledButton == true ? true : false}>
            {loader == true ? (
              <CircularProgress size={20} style={{ color: "white" }} />
            ) : (
              "  Buat Measure Activity !"
            )}
          </Button>
        </DialogActions>
        <br />

        {/* <DialogSetWeight
          classes={classes}
          openDialogSetWeight={openDialogSetWeight}
          setOpenDialogSetWeight={setOpenDialogSetWeight}
        /> */}

        <DialogError
          classes={classes}
          isOpenDialogError={isOpenDialogError}
          setOpenDialogError={setOpenDialogError}
          textErrorInformation={textErrorInformationValue}
          errorStatus={errorStatus}
          listError={listError}
        />
      </Dialog>

      <Snackbeer
        classes={classes}
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages="Berhasil membuat Measured Activity :)"
      />
    </Fragment>
  );
};

export default DialogCreateMA;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },

  typography: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  textfield: {
    width: 200,
  },
});

import React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";

import axiosConfig from "../../../constants/config-axios";
import { URL_API } from "../../../constants/config-api";
import DialogError from "../../../components/DialogError";

import ContextTask from "../Context/ContextTask";

function DialogDeleteMultiple({
  classes,
  selected,
  setSelected,
  openDialogDeleteMultiple,
  setOpenDialogDeleteMultiple,
}) {
  const context = React.useContext(ContextTask);
  const [loader, setLoader] = React.useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = React.useState(false);
  const [
    textErrorInformationValue,
    setTextErrorInformationValue,
  ] = React.useState("");
  const [errorStatus, setErrorStatus] = React.useState(undefined);
  const [listError, setListError] = React.useState([]);

  const handleDeleteMultiple = () => {
    return Promise.all(
      selected.length > 0 &&
        selected.map((item, index) => {
          setLoader(true);
          const params = new URLSearchParams();
          params.append(`Todolist[${index}][id]`, item);

          return axiosConfig
            .delete(`${URL_API}/todolist/batch`, {
              data: params,
            })
            .then((response) => {
              // console.log("RESponsE DelMul", response);

              // setResponseDelete(response);
              // setOpenDialogFeedbackDelete(true);
              setOpenDialogDeleteMultiple(false);
              setLoader(false);

              setSelected([]);

              context.setSucessDeleteTDL(true);

              context.setSnackbarResponse200(true);
              context.setMessages("Berhasil di hapus !");
            })
            .catch((error) => {
              setLoader(false);
              console.log("Error : ", error);

              // error response
              if (error.response !== undefined) {
                let responseStatus = error.response.status;

                setErrorStatus(responseStatus);
                setOpenDialogError(true);

                if ([422, 400].includes(responseStatus)) {
                  if (
                    error.response.data.info.errors !== null &&
                    error.response.data.info.errors !== undefined
                  ) {
                    if (error.response.data.info.errors.length > 0) {
                      setOpenDialogError(true);
                      setListError(error.response.data.info.errors);
                    }
                  }
                }

                if (
                  [503, 500, 403, 429, 405, 404, 401].includes(responseStatus)
                ) {
                  if (error.response.data.hasOwnProperty("message")) {
                    setTextErrorInformationValue(
                      `Error ${responseStatus} : ` + error.response.data.message
                    );
                  }
                }
              } else {
                setOpenDialogError(true);
                setTextErrorInformationValue("Whoops, something went wrong !");
              }
            });
        })
    );
  };

  return (
    <Dialog
      open={openDialogDeleteMultiple}
      onClose={() => setOpenDialogDeleteMultiple(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle
        id="alert-dialog-title"
        style={{ textAlign: "left" }}></DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <DialogContentText id="alert-dialog-description">
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}>
            <b>
              Apakah Anda yakin ingin hapus {selected.length} ToDo List yang
              ditandai ?
            </b>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ alignItems: "center", justifyContent: "center" }}>
        <Button
          onClick={handleDeleteMultiple}
          variant="contained"
          className={classes.button}
          // fullWidth
        >
          {!loader ? (
            "Hapus"
          ) : (
            <CircularProgress size={20} style={{ color: "white" }} />
          )}
        </Button>
      </DialogActions>
      <br />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformationValue}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
}

export default DialogDeleteMultiple;

import React, { useContext, useState } from "react";
import { Box, Paper, Divider, Checkbox, Typography } from "@material-ui/core";

import clsx from "clsx";

import ContextReports from "../../../context/ContextReports";

import ConfigEntities from "./filter-components/configEntities";
import ConfigUser from "./filter-components/configUser";
import ConfigUnit from "./filter-components/configUnit";
import ConfigAchievement from "./filter-components/configAchievement";
import ConfigStatus from "./filter-components/configStatus";
import DateRangeFilter from "./date-range/DateRangeFilter";
import ConfigGroupBy from "./filter-components/configGroupBy";
import ConfigUnitGroup from "./filter-components/configUnitGroup";
import ConfigOrderBy from "./filter-components/configOrderBy";

const SummaryConfig = ({ classes }) => {
  const { summaryState } = useContext(ContextReports);

  const [advanced, setAdvanced] = useState(false);

  return (
    <Paper className={classes.shadowSection} style={{ marginBottom: 30 }}>
      <Box p={2} display="flex" flexWrap="wrap">
        <DateRangeFilter classes={classes} />
        <Box px={1} />
        <ConfigGroupBy classes={classes} />
        <Box px={1} />
        {summaryState.selectedGroup.field === "structure_unit_name" && (
          <>
            <ConfigUnitGroup classes={classes} />
            <Box px={1} />
          </>
        )}
        <ConfigUnit classes={classes} />
        <Box px={1} />
        <ConfigUser classes={classes} />
        <Box px={1} />

        <ConfigEntities classes={classes} />
        <Box px={1} />

        <Divider orientation="vertical" flexItem />

        <Box px={1} />

        {advanced && (
          <>
            <ConfigOrderBy classes={classes} />
            <Box px={1} />
            <ConfigAchievement classes={classes} />
            <Box px={1} />
            <ConfigStatus classes={classes} />
            <Box px={1} />
            <Divider orientation="vertical" flexItem />
            <Box px={1} />
          </>
        )}

        <Box display="flex" alignItems="center">
          <Checkbox
            checked={advanced}
            size="small"
            onChange={() => setAdvanced((prv) => !prv)}
            className={classes.checkboxRoot}
            checkedIcon={
              <span className={clsx(classes.icon, classes.checkedIcon)} />
            }
            icon={<span className={classes.icon} />}
            style={{ padding: 0 }}
          />
          <Typography
            variant="caption"
            className={classes.title555}
            style={{ marginLeft: 10 }}>
            Show Advanced
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default SummaryConfig;

import React, { useEffect, useState, useContext, Fragment } from "react";
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Typography, Grid, Box, Button, ListItemText } from "@material-ui/core";

import MomentUtils from "@date-io/moment";
import moment from "moment";
import clsx from "clsx";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DialogDateRange from "./Components/DialogDateRange";
import DialogCreate from "./Components/DialogCreate";

import ContextGoal from "./Context/ContextGoal";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../components/StyledMenuDropdown";
import ViewYou from "./You/ViewYou";
import ViewAll from "./All/ViewAll";
import { styles } from "../Goal/Style/StyleGoal";
import axiosConfig from "../../../constants/config-axios";

import { URL_API } from "../../../constants/config-api";
import PictIconGoalWhenEmptyState from "../../../assets/images/Group_2966.png";

import "moment/locale/id";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const useStyle = makeStyles(styles);

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const ViewGoal = (props) => {
  const context = useContext(ContextGoal);
  const classes = useStyle();
  const userToken = localStorage.getItem("userToken");

  /*
       ```````````````````
       COMPONENT DID MOUNT

       ```````````````````
    */

  /*
       ````
       TAB

       ````
    */
  // const [paramsUrlGoal, setParamsUrlGoal ] = useState('options[filter][scope]=self'); //*MOved to Global State
  const [tabIndex, setTabIndex] = useState(1); //*1 = You, 0 = All
  const handleTab = (tabIndex) => {
    /*
            `````````````````````````````````
            DELETE VALUE SEARCH LOCAL STORAGE
            
            `````````````````````````````````
        */
    localStorage.removeItem("value_search");

    setTabIndex(tabIndex);

    if (tabIndex === 1) {
      context.setParamsUrlGoal("options[filter][scope]=self");
    } else {
      context.setParamsUrlGoal("");
    }
  };

  const handleClickChooseTab = (e, data) => {
    e.preventDefault();
    localStorage.removeItem("value_search");

    // context.setLoader(true);

    // console.log("Data tab ALL & YOU : ", data);
    context.setDataTabActive(data);

    localStorage.setItem("location_tab", data.label);
  };

  const [userTokenState, setUserTokenState] = useState("");

  useEffect(() => {
    setUserTokenState(userToken);

    const valueTab = localStorage.getItem("location_tab");

    if (valueTab === "All") {
      context.setDataTabActive({
        id: 1,
        label: "All",
      });
    } else {
      context.setDataTabActive({
        id: 2,
        label: "You",
      });
    }
  }, []);

  /*
       ```````````````````
       DROPDOWN ALL STATUS
       ```````````````````
   */
  const [anchorElAllStatus, setAnchorElAllStatus] = useState(null);

  function handleClickAllStatus(event) {
    setAnchorElAllStatus(event.currentTarget);
  }

  function handleCloseAllStatus() {
    setAnchorElAllStatus(null);
  }

  /*
        ```````````````````
        DROPDOWN TIME FRAME
        ```````````````````
    */
  const [anchorElTimeFrame, setAnchorElTimeFrame] = useState(null);

  const [textValueTimeFrame, setTextValueTimeFrame] = useState("Time Frame");

  function handleClickTimeFrame(event) {
    setAnchorElTimeFrame(event.currentTarget);
  }

  function handleCloseTimeFrame() {
    setAnchorElTimeFrame(null);
  }

  /*
        ````````
        DUE DATE

        ````````
    */

  const [locale, setLocale] = useState("id");
  const [selectedDueDate, setSelectedDueDate] = useState(null);
  const [isShowDueDateComponent, setShowDueDateComponent] = useState(false);
  const [isShowDueDateComponentDirectly, setShowDueDateComponentDirectly] =
    useState(false);

  const handleDueDate = () => {
    handleCloseTimeFrame();
    setShowDueDateComponent(true);
    // setShowDueDateComponentDirectly(true);

    context.setShowDateRangeComponent(false);
    setShowTextDateRange(false);

    setTextValueTimeFrame("Due Date");
  };

  /*
        ``````````
        DATE RANGE

        ``````````
    */
  // const [ isShowDateRangeComponent, setShowDateRangeComponent ] = useState(false); //*Change to Global State
  const [isShowTextDateRange, setShowTextDateRange] = useState(false);

  const handleDateRange = () => {
    handleCloseTimeFrame();
    setShowDueDateComponent(false);
    // setShowDueDateComponentDirectly(false);

    // setShowTextDateRange(true);
    context.setShowDateRangeComponent(true);

    setTextValueTimeFrame("Date Range");
  };

  const [openDateRange, setOpenDateRange] = useState(true);
  const [dateRange, setDateRange] = useState({});

  const handleChangeDateRange = (data) => {
    console.log("Data Date Range : ", data);
    setDateRange(data);

    setShowTextDateRange(true);
    context.setShowDateRangeComponent(false);
  };

  /*
        ```````````````````
        Filtering Due Date

        ``````````````````
    */
  useEffect(() => {
    if (selectedDueDate !== null) {
      //*RUN!
      context.setLoader(true);

      if (userTokenState !== undefined) {
        axiosConfig
          .get(
            URL_API +
              `/swd/goal?options[filter][due_date]=${moment(
                selectedDueDate
              ).format("YYYY-MM-DD")}`
          )
          .then(function (response) {
            context.setLoader(false);
            setShowDueDateComponent(false);

            console.log("Response Original Due Date : ", response);

            if (response.status == 200) {
              if (
                response.data.data !== null &&
                response.data.data !== undefined
              ) {
                context.setCollectionGoalList(response.data.data);
              }
            }
          })
          .catch(function (error) {
            context.setLoader(false);
            setShowDueDateComponent(false);
            console.log("Error : ", error.response);
          });
      } else {
        console.log("No Access Token available!");
      }
    }
  }, [selectedDueDate]);

  const startDate = localStorage.getItem("start_date");
  const endDate = localStorage.getItem("end_date");

  /*
        ``````````````````````````
        HANDLE SUCCESS CREATE GOAL

        ``````````````````````````
    */
  useEffect(() => {
    if (context.successCreateGoal === true) {
      context.setDataTabActive({
        id: 1,
        label: "All",
      });

      //*RUN!
      context.setLoader(true);

      if (userTokenState !== undefined) {
        axiosConfig
          .get(
            URL_API +
              `/swd/goal?options[filter][status_id]=${context.statusGoalId}&options[paging][offset]=0&options[paging][limit]=10`
          )
          .then(function (response) {
            context.setLoader(false);
            console.log("Response Original COLLECTION GOAL : ", response);

            if (response.status === 200) {
              if (
                response.data.data !== null &&
                response.data.data !== undefined
              ) {
                context.setCollectionGoalList(response.data.data);
                context.setLengthGoalCount(response.data.info.count);
              }
            }
          })
          .catch(function (error) {
            context.setLoader(false);
            console.log("Error : ", error.response);
          });
      } else {
        console.log("No Access Token available!");
      }
    }
  }, [context.successCreateGoal]);

  return (
    <MuiThemeProvider theme={theme}>
      <Grid container>
        <Grid item sm={7} style={{ textAlign: "right" }}></Grid>

        <Grid item sm={5} style={{ textAlign: "right" }}>
          {/* 

                        `````````````````
                        STATUS BUTTON ALL PROGRESS
                        
                        `````````````````                        
                    */}

          {isShowTextDateRange === true && (
            <Button
              variant="outlined"
              size="small"
              className={clsx(
                classes.timeFrameIcontDateRangeText,
                classes.layoutMarginTopNew
              )}
              style={{ marginRight: 8, borderWidth: 0 }}
              onClick={() => context.setShowDateRangeComponent(true)}>
              <b>
                {dateRange !== {}
                  ? moment(dateRange.startDate).format("DD MMMM YYYY")
                  : "-"}{" "}
                -{" "}
                {dateRange !== {}
                  ? moment(dateRange.endDate).format("DD MMMM YYYY")
                  : "-"}
              </b>
            </Button>
          )}

          {/* 

                        `````````````````
                        TIME FRAME BUTTON

                        `````````````````                        
                    */}
          <Button
            onClick={handleClickTimeFrame}
            variant="outlined"
            size="small"
            className={clsx(classes.timeFrameIcon, classes.layoutMarginTopNew)}
            style={{ marginRight: 8 }}>
            <i
              className="material-icons"
              style={{ color: "grey", fontSize: 14 }}>
              calendar_today
            </i>
            &nbsp;
            <b>{textValueTimeFrame}</b>
          </Button>

          <StyledMenu
            id="customized-menu"
            anchorEl={anchorElTimeFrame}
            keepMounted
            open={Boolean(anchorElTimeFrame)}
            onClose={handleCloseTimeFrame}>
            <StyledMenuItem onClick={handleDueDate}>
              <ListItemText
                primary={
                  <Typography variant="subtitle2" className={classes.title}>
                    <b>Due Date</b>
                  </Typography>
                }
              />
            </StyledMenuItem>

            <StyledMenuItem onClick={handleDateRange}>
              <ListItemText
                primary={
                  <Typography variant="subtitle2" className={classes.title}>
                    <b>Date Range</b>
                  </Typography>
                }
              />
            </StyledMenuItem>
          </StyledMenu>
        </Grid>
      </Grid>

      {/* 
                ``````````````````````````````````````````
                COMPONENT DATE PICKER, DATE RANGE, AND TAB

                ``````````````````````````````````````````
            */}
      <Grid container>
        {/* <Grid item sm={4} style={{textAlign: 'right'}}></Grid> */}
        {listChooseTab.length > 0 &&
          listChooseTab.map((item, i) => {
            return (
              <Fragment key={i}>
                <Grid item sm={1} md={1}>
                  <Box marginLeft={theme.spacing(1.3)} marginTop={-3.5}>
                    <Button
                      onClick={(e) => handleClickChooseTab(e, item)}
                      variant="outlined"
                      classes={
                        context.dataTabActive.id === item.id
                          ? {
                              outlined: classes.outlinedTabActive,
                            }
                          : {
                              outlined: classes.outlinedTabNotActive,
                            }
                      }>
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        onClick={(e) => handleClickChooseTab(e, item)}>
                        <b>{item.label}</b>
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
              </Fragment>
            );
          })}

        <Grid item sm={2} md={2}></Grid>

        <Grid item sm={8} md={8} style={{ textAlign: "right" }}>
          {isShowDueDateComponent === true && ( //https://stackoverflow.com/questions/17493309/how-do-i-change-the-language-of-moment-js
            <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
              <ThemeProvider theme={themePicker}>
                <Fragment>
                  <DatePicker
                    value={selectedDueDate}
                    onChange={setSelectedDueDate}
                    animateYearScrolling
                    variant="inline" // dialog, static, inline
                    disableToolbar={false}
                    // orientation="landscape"
                    // format
                    // TextFieldComponent =
                    // ToolbarComponent
                    label="Pilih tanggal"
                    style={{ marginLeft: 16 }}
                  />
                </Fragment>
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          )}

          {context.isShowDateRangeComponent === true && (
            <DialogDateRange
              classes={classes}
              isShowDateRangeComponent={context.isShowDateRangeComponent}
              setShowDateRangeComponent={context.setShowDateRangeComponent}
            />
          )}
        </Grid>
      </Grid>

      {context.dataTabActive.id === 1 ? ( //*Data Tab Active ALL
        <Box marginLeft={theme.spacing(1)} marginTop={theme.spacing(0.1)}>
          <ViewAll />
        </Box>
      ) : (
        //*Data Tab Active YOU

        <Box marginLeft={theme.spacing(1)} marginTop={theme.spacing(0.1)}>
          <ViewYou />
        </Box>
      )}

      <DialogCreate
        classes={classes}
        isModalCreate={context.isModalCreate}
        setModalCreate={context.setModalCreate}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        memberPositionList={context.memberPositionList}
        setCollectionGoalList={context.setCollectionGoalList}
        collectionGoalList={context.collectionGoalList}
      />
    </MuiThemeProvider>
  );
};

// export default withStyles(styles)(ViewGoal);
export default ViewGoal;

const themePicker = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },

  typography: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  textfield: {
    width: 200,
  },
});

const listChooseTab = [
  {
    id: 1,
    label: "All",
  },

  {
    id: 2,
    label: "You",
  },
];

import { GET_PIE_CHART } from "../types";

// ~ Initial State for Table ~
export const initialPie = {
  pieDataGoal: [],
  pieDataMa: [],
  piePreset: [],
  loading: false,
};

// =======================================================================
// ~ REDUCER | Managing GLobal State for Table ~
export const pieReducer = (state, { type, payload }) => {
  switch (type) {
    case GET_PIE_CHART:
      return {
        ...state,
        pieDataGoal: payload.pieDataGoal,
        pieDataMa: payload.pieDataMa,
        piePreset: payload.piePreset,
        loading: payload.loading,
      };
    default:
      return state;
  }
};

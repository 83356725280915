import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
  CardMedia,
  Box,
  CardContent,
  Checkbox,
} from "@material-ui/core";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";

import ImageIcon from "@material-ui/icons/Image";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import RestorePageRoundedIcon from "@material-ui/icons/RestorePageRounded";

import moment from "moment";
import clsx from "clsx";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextGlobalDrive from "../../context/ContextDrive";

import pdf_icon from "../../../../assets/images/icons_file/PDF.png";
import excel_icon from "../../../../assets/images/icons_file/Excel.png";
import ppt_icon from "../../../../assets/images/icons_file/PowerPoint.png";
import word_icon from "../../../../assets/images/icons_file/Word.png";
import video_icon from "../../../../assets/images/icons_file/Video.png";
import text_icon from "../../../../assets/images/icons_file/Text.png";

import DialogInfoRecycleBin from "../components/DialogInfoRecycleBin";
import DialogDeleteFileRB from "../components/DialogDeleteFileRB";
import TruncateTextShortNameGoal from "../../../../utilities/TruncateTextShortNameGoal";
import DialogRestoreFile from "../components/DialogRestoreFile";

const RecycleBinFile = ({ item, classes, isFileSelected }) => {
  const context = useContext(ContextGlobalDrive);
  /* ====================================================
    LOCAL STATE
    ==================================================== */
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [anchorElFile, setAnchorElFile] = useState(null);
  const [typeFile, setTypeFile] = useState(null);

  const [openDialogInfoRecycleBin, setOpenDialogInfoRecycleBin] = useState(
    false
  );
  const [openDialogDeleteFileRB, setOpenDialogDeleteFileRB] = useState(false);
  const [openDialogRestoreFile, setOpenDialogRestoreFile] = useState(false);

  /* ====================================================
    SIDE EFFECT
  ==================================================== */

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`${URL_API}/drive/recycle-bin/file/${item.id}?mode=thumbnail`, {
        responseType: "blob",
      })
      .then((response) => {
        // console.log("RB File Content", response);
        let ori_contetnt_type = response.headers["content-type"];
        let splited_content_type = ori_contetnt_type.split(",");
        let content_type = splited_content_type[0];

        // console.log("CONTENT TYPE", content_type);

        if (response.status === 200) {
          setTypeFile(content_type);
          setImage(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setLoading(false);
      });
  }, []);

  const handleShowAnchorFile = (event) => {
    setAnchorElFile(event.currentTarget);
  };

  const handleCloseAnchorFile = () => {
    setAnchorElFile(null);
  };

  const handleInfoRecycleBin = () => {
    setOpenDialogInfoRecycleBin(true);
    setAnchorElFile(null);
  };

  const handleDeleteForever = () => {
    setOpenDialogDeleteFileRB(true);
    setAnchorElFile(null);
  };

  const handleRestoreFile = () => {
    setOpenDialogRestoreFile(true);
    setAnchorElFile(null);
  };

  const blobUrlImage = new Blob([image], {
    type: typeFile !== null && typeFile,
  });

  // Handle Select Checkbox
  const handleChangeCheckbox = (item) => {
    const selectedIndex = context.selectedFile.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(context.selectedFile, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(context.selectedFile.slice(1));
    } else if (selectedIndex === context.selectedFile.length - 1) {
      newSelected = newSelected.concat(context.selectedFile.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        context.selectedFile.slice(0, selectedIndex),
        context.selectedFile.slice(selectedIndex + 1)
      );
    }

    // console.log("selected Folders", newSelected);

    context.setSelectedFile(newSelected);
  };

  // Check is Folder selected or not
  const isItemFileSelected = isFileSelected(item.id);

  return (
    <Grid item md={3}>
      {context.toggleSelect && (
        <Checkbox
          style={{ marginTop: !context.toggleSelect ? 0 : -10 }}
          onClick={() => handleChangeCheckbox(item)}
          className={classes.checkboxRoot}
          checked={isItemFileSelected}
          disableRipple
          color="default"
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          inputProps={{ "aria-label": "decorative checkbox" }}
        />
      )}
      <Card
        variant="outlined"
        style={{
          marginTop: !context.toggleSelect ? 0 : -20,
          backgroundColor: "#e5fbfb",
        }}>
        {typeFile !== null && typeFile.includes("image") ? (
          <CardMedia
            component="img"
            src={image !== "" ? URL.createObjectURL(blobUrlImage) : undefined}
            style={{
              height: 210,
            }}
          />
        ) : (
          <CardContent
            style={{
              height: 200,
              padding: 5,
              borderRadius: 10,
            }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop={10}>
              {typeFile !== null && typeFile === "application/pdf" ? (
                <img src={pdf_icon} />
              ) : (typeFile !== null && typeFile === "application/msword") ||
                (typeFile !== null &&
                  typeFile ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document") ? (
                <img src={word_icon} />
              ) : (typeFile !== null &&
                  typeFile === "application/vnd.ms-powerpoint") ||
                typeFile ===
                  "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                <img src={ppt_icon} />
              ) : (typeFile !== null &&
                  typeFile === "application/vnd.ms-excel") ||
                typeFile ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                <img src={excel_icon} />
              ) : typeFile !== null && typeFile.includes("video") ? (
                <img src={video_icon} />
              ) : typeFile !== null && typeFile === "text/plain" ? (
                <img src={text_icon} style={{ width: 62 }} />
              ) : (
                <InsertDriveFileIcon color="inherit" fontSize="large" />
              )}
            </Box>
          </CardContent>
        )}

        <CardHeader
          avatar={<ImageIcon style={{ color: "#888" }} />}
          action={
            <div>
              <IconButton onClick={handleShowAnchorFile}>
                <MoreVertIcon />
              </IconButton>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElFile}
                keepMounted
                open={Boolean(anchorElFile)}
                onClose={handleCloseAnchorFile}>
                <StyledMenuItem
                  style={{ width: 200 }}
                  onClick={handleInfoRecycleBin}>
                  <ListItemIcon>
                    <VisibilityOutlinedIcon
                      fontSize="small"
                      className={classes.hoverColor}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        className={classes.rightMenuText}>
                        Info File
                      </Typography>
                    }
                  />
                </StyledMenuItem>
                <StyledMenuItem
                  style={{ width: 200 }}
                  onClick={handleDeleteForever}>
                  <ListItemIcon>
                    <DeleteRoundedIcon
                      fontSize="small"
                      className={classes.hoverColor}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        className={classes.rightMenuText}>
                        Delete File
                      </Typography>
                    }
                  />
                </StyledMenuItem>
                <StyledMenuItem onClick={handleRestoreFile}>
                  <ListItemIcon>
                    <RestorePageRoundedIcon
                      fontSize="small"
                      className={classes.hoverColor}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        className={classes.rightMenuText}>
                        Restore File
                      </Typography>
                    }
                  />
                </StyledMenuItem>
              </StyledMenu>
            </div>
          }
          title={
            <Typography variant="subtitle2" className={classes.folderNameText}>
              {TruncateTextShortNameGoal(item.file_name)}
            </Typography>
          }
          subheader={moment(item.executed_at).format("DD MMM YYYY, HH:mm")}
        />
      </Card>

      <DialogInfoRecycleBin
        item={item}
        classes={classes}
        origin="file"
        openDialogInfoRecycleBin={openDialogInfoRecycleBin}
        setOpenDialogInfoRecycleBin={setOpenDialogInfoRecycleBin}
      />

      <DialogDeleteFileRB
        item={item}
        classes={classes}
        openDialogDeleteFileRB={openDialogDeleteFileRB}
        setOpenDialogDeleteFileRB={setOpenDialogDeleteFileRB}
      />

      <DialogRestoreFile
        item={item}
        classes={classes}
        openDialogRestoreFile={openDialogRestoreFile}
        setOpenDialogRestoreFile={setOpenDialogRestoreFile}
      />
    </Grid>
  );
};

export default RecycleBinFile;

import React, { Fragment, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import InfoIcon from "@material-ui/icons/Info";

import ContextAllMA from "../context/AllMaContext";

function DialogFeedbackSetAsCompleteMA({
  classes,
  openDialogFeedbackSetAsComplete,
  setOpenDialogFeedbackSetAsComplete,
  responseSetAsComplete,
  listMA,
}) {
  const context = useContext(ContextAllMA);

  // Take Succes or Failed Array
  // const completeItem = responseSetAsComplete.map((item) => item.data.data);
  //   console.log("Response Set as COMCOM", responseSetAsComplete);

  //   Take ID and Name
  const maItem = listMA.map((item) => {
    return {
      name: item.data.data.name,
      id: item.data.data.id,
    };
  });
  // console.log("MA Item", maItem);

  //   Handle Close Dialog And Refresh collection of Goal
  const closeDialogUpdateListMA = () => {
    context.setSuccessSetAsComplete(Math.floor(Math.random() * 1000 + 1));
    setOpenDialogFeedbackSetAsComplete(false);
  };

  return (
    <Fragment>
      <Dialog
        maxWidth="md"
        open={openDialogFeedbackSetAsComplete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title-422"
          style={{ textAlign: "center" }}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start">
            <span></span>
            <InfoIcon style={{ fontSize: 36, color: "#FFAC9C" }} />
            <IconButton size="small" onClick={closeDialogUpdateListMA}>
              <HighlightOffIcon style={{ color: "grey", fontSize: "17px" }} />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <List>
              {maItem.length > 0 &&
                maItem.map((ma, idx) => (
                  <ListItem>
                    <ListItemText
                      primary={
                        <span
                          style={{
                            color: "#333",
                            fontStyle: "normal",
                          }}>
                          <b>{ma.name}</b>
                        </span>
                      }
                      secondary={
                        <span
                          style={{
                            fontStyle: "normal",
                          }}>
                          <ul>
                            {responseSetAsComplete.failed !== undefined &&
                              responseSetAsComplete.failed.length > 0 &&
                              responseSetAsComplete.failed.map((fail) => {
                                if (fail.id === ma.id) {
                                  // console.log("logError", fail.errors);

                                  return fail.errors.map((error) => (
                                    <li style={{ color: "#d1354a" }}>
                                      {error.description}
                                    </li>
                                  ));
                                }
                              })}

                            {responseSetAsComplete.success !== undefined &&
                              responseSetAsComplete.success.length > 0 &&
                              responseSetAsComplete.success.map((sccs) => {
                                if (sccs.id === ma.id) {
                                  // console.log("logSuccess", sccs.result);

                                  return (
                                    <li style={{ color: "#1EBC61" }}>
                                      Berhasil!
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </span>
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "center",
            marginBottom: 15,
          }}>
          <Button
            onClick={closeDialogUpdateListMA}
            variant="contained"
            className={classes.buttonModalDelete}
            style={{ paddingRight: 25, paddingLeft: 25 }}>
            Tutup
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default DialogFeedbackSetAsCompleteMA;

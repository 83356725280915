import { isSameDay } from "date-fns";
import moment from "moment";

const defineds = {
  // startOfWeek: startOfWeek(new Date()),
  // endOfWeek: endOfWeek(new Date()),
  // startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  // endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  // startOfToday: startOfDay(new Date()),
  // endOfToday: endOfDay(new Date()),
  // startOfYesterday: startOfDay(addDays(new Date(), -1)),
  // endOfYesterday: endOfDay(addDays(new Date(), -1)),
  // startOfMonth: startOfMonth(new Date()),
  // endOfMonth: endOfMonth(new Date()),
  // startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  // endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  // startLast7Days: startOfDay(addDays(new Date(), -7)),
  // endLast7Days: endOfDay(addDays(new Date(), -1)),
  // startPrev7Days: startOfDay(addDays(new Date(), -14)),
  // endPrev7Days: endOfDay(addDays(new Date(), -7)),

  // moment
  startOfWeek: moment().startOf("w"),
  endOfWeek: moment().endOf("w"),
  startOfLastWeek: moment().subtract(1, "w").startOf("w"),
  endOfLastWeek: moment().subtract(1, "w").endOf("w"),
  startOfToday: moment().startOf("d"),
  endOfToday: moment().endOf("d"),
  startOfYesterday: moment().subtract(1, "d").startOf("d"),
  endOfYesterday: moment().subtract(1, "d").endOf("d"),
  startLast7Days: moment().subtract(7, "d").startOf("day"),
  endLast7Days: moment().subtract(1, "d").endOf("day"),
  startPrev7Days: moment().subtract(14, "d").startOf("day"),
  endPrev7Days: moment().subtract(8, "d").endOf("day"),
  startThisMonth: moment().startOf("months"),
  endThisMonth: moment().endOf("months"),
  startLastMonth: moment().subtract(1, "month").startOf("month"),
  endLastMonth: moment().subtract(1, "month").endOf("month"),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: "All Time",
    code: "all_time",
    range: () => ({
      title: "All Time",
      code: "all_time",
      startDate: null,
      endDate: null,
    }),
    start_date: null,
    end_date: null,
  },
  {
    label: "Today",
    code: "today",
    range: () => ({
      title: "Today",
      code: "today",
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
    start_date: defineds.startOfToday,
    end_date: defineds.endOfToday,
  },
  {
    label: "Yesterday",
    code: "yesterday",
    range: () => ({
      title: "Yesterday",
      code: "yesterday",
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
    start_date: defineds.startOfYesterday,
    end_date: defineds.endOfYesterday,
  },

  {
    label: "This Week",
    code: "this_week",
    range: () => ({
      title: "This Week",
      code: "this_week",
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
    start_date: defineds.startOfWeek,
    end_date: defineds.endOfWeek,
  },
  {
    label: "Last Week",
    code: "last_week",

    range: () => ({
      title: "Last Week",
      code: "last_week",
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
    start_date: defineds.startOfLastWeek,
    end_date: defineds.endOfLastWeek,
  },
  {
    label: "Last 7 Days",
    code: "last_seven_days",
    range: () => ({
      title: "Last 7 Days",
      code: "last_seven_days",
      startDate: defineds.startLast7Days,
      endDate: defineds.endLast7Days,
    }),
    start_date: defineds.startLast7Days,
    end_date: defineds.endLast7Days,
  },
  {
    label: "Previous 7 Days",
    code: "previous_seven_days",
    range: () => ({
      title: "Previous 7 Days",
      code: "previous_seven_days",
      startDate: defineds.startPrev7Days,
      endDate: defineds.endPrev7Days,
    }),
    start_date: defineds.startPrev7Days,
    end_date: defineds.endPrev7Days,
  },
  {
    label: "This Month",
    code: "this_month",
    range: () => ({
      title: "This Month",
      code: "this_month",
      startDate: defineds.startThisMonth,
      endDate: defineds.endThisMonth,
    }),
    start_date: defineds.startThisMonth,
    end_date: defineds.endThisMonth,
  },
  {
    label: "Last Month",
    code: "last_month",
    range: () => ({
      title: "Last Month",
      code: "last_month",
      startDate: defineds.startLastMonth,
      endDate: defineds.endLastMonth,
    }),
    start_date: defineds.startLastMonth,
    end_date: defineds.endLastMonth,
  },
]);

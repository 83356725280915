import React from "react";
import Button from "@material-ui/core/Button";

import PersonIcon from "@material-ui/icons/Person";

const ButtonIconPositionTitle = (props) => {
  const { classes, positionTitleName } = props;

  return (
    <Button
      variant="outlined"
      size="small"
      className={classes.timeFrameIconInModal}
      startIcon={<PersonIcon style={{ color: "#555", fontSize: 14 }} />}>
      &nbsp;
      <b>{positionTitleName}</b>
    </Button>
  );
};

export default ButtonIconPositionTitle;

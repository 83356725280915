import React, { useState, useContext } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import ContextAllMA from "../context/AllMaContext";
import Capitalize from "../../../../utilities/Capitalize";

import DialogError from "../../../../components/DialogError";

function DialogDeleteEvidenceMA({
  openDialogDeleteEvidence,
  setOpenDialogDeleteEvidence,
  classes,
  item,
  listPictState,
  setListPictState,
}) {
  const context = useContext(ContextAllMA);

  const [loader, setLoader] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleDelete = () => {
    setLoader(true);

    axiosConfig
      .delete(
        `${URL_API}/swd/measured-activity-result/${item.measured_activity_result_id}/evidence/${item.id}`,
        { timeout: 20000 }
      )
      .then(function (response) {
        console.log("Response Del Evidence MA : ", response);

        if (response.status === 200) {
          context.setModalResponse200(true);
          context.setMessages("Berhasil menghapus file bukti !");

          setListPictState(listPictState.filter((tile) => tile.id !== item.id));

          context.setSuccessCreateMAR(Math.floor(Math.random() * 1000 + 1));

          setOpenDialogDeleteEvidence(false);
        }
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);

        setOpenDialogDeleteEvidence(false);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  return (
    <Dialog
      open={openDialogDeleteEvidence}
      onClose={() => setOpenDialogDeleteEvidence(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography
          variant="subtitle1"
          className={classes.title}
          style={{ color: "black" }}>
          <b>Hapus bukti Measured Activity</b>
        </Typography>
      </DialogTitle>
      <DialogContent style={{ textAlign: "center", width: 450 }}>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="subtitle2" className={classes.title}>
            Apakah Anda yakin mau menghapus bukti <b>{item.label} </b> ?
          </Typography>
        </DialogContentText>
        <DialogActions
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
          }}>
          <Button
            onClick={handleDelete}
            variant="contained"
            className={classes.button}>
            {loader ? (
              <CircularProgress size={16} style={{ color: "#fff" }} />
            ) : (
              "Hapus"
            )}
          </Button>
        </DialogActions>
      </DialogContent>

      <DialogError
        classes={classes}
        textErrorInformation={textErrorMessage}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
}

export default DialogDeleteEvidenceMA;

import React, { useContext, useEffect, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  CircularProgress,
} from "@material-ui/core";

import axios from "axios";
import moment from "moment";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";

// import ContextGoal from '../Context/ContextGoal';
import ContextGoalDetail from "../../Goal/Context/ContextGoalDetail";

import DialogError from "../../../../components/DialogError";
import IconWarningYellow from "../../../../assets/images/icon_warning_yellow.png";
import { URL_API } from "../../../../constants/config-api";
import Capitalize from "../../../../utilities/Capitalize";

// import { styles } from '../Style/StyleGoal';
import { styles } from "../Style/StyleMA";

const fileDownload = require("js-file-download");

const useStyle = makeStyles(styles);

const DialogExportMA = (props) => {
  const { isModalExportMA, setModalExportMA } = props;

  const context = useContext(ContextGoalDetail);
  const classes = useStyle();

  const [profileGroupName, setProfileGroupName] = useState("");

  useEffect(() => {
    if (isModalExportMA === true) {
      // console.log("COntext : ", context)
      const profileName = localStorage.getItem("status_user_login");
      const profileNameAfterParse = JSON.parse(profileName);

      if (
        profileNameAfterParse !== null &&
        profileNameAfterParse !== undefined
      ) {
        // console.log("profileNameAfterParse", profileNameAfterParse);
        if (
          profileNameAfterParse.group_name !== null &&
          profileNameAfterParse.group_name !== "" &&
          profileNameAfterParse.group_name !== undefined
        ) {
          setProfileGroupName(profileNameAfterParse.group_name);
        }
      }
    }
  }, [isModalExportMA]);

  /*
        `````````````````````````
        HANDLE BUTTON SUBMIT GOAL

        `````````````````````````
    */
  const [loader, setLoader] = useState(false);

  const handleSubmitExportMA = () => {
    setLoader(true);

    const userTokenState = localStorage.getItem("userToken");

    if (userTokenState !== "") {
      const header = {
        "Content-Type": "text/csv; charset=UTF-8",
        Authorization: "bearer " + userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .get(
          `${URL_API}/swd/measured-activity:export?options[filter][search]=${""}&options[filter][scope]=${""}&options[filter][status_id]=${
            context.statusMaId
          }`
        )
        .then(function (response) {
          setLoader(false);
          console.log("Response Original : ", response);
          //    fileDownload(response.data, `${moment(new Date()).format('DD MMMM YYYY hh:mm')}.csv`);
          fileDownload(
            response.data,
            `PF_${profileGroupName}_MA_${moment(new Date()).format(
              "YYYY-MM-DD hh:mm:ss"
            )}.csv`
          );

          setModalExportMA(false);
        })
        .catch(function (error) {
          setLoader(false);

          if (error.response !== undefined) {
            if (error.response.status === 400) {
              setErrorStatus(400);

              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if (error.response.status == 500) {
              setErrorStatus(500);
              setOpenDialogError(true);
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorInformation(
                  "Error 500 : " + Capitalize(error.response.data.message)
                );
              }
            }

            if (error.response.status === 429) {
              setErrorStatus(429);

              setTextErrorInformation("Too Many Requests");
              setOpenDialogError(true);
              // setListError(error.response.data.info.errors);
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformation("Whoops, something went wrong !");
          }

          console.log("Error : ", error.response);
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  return (
    <Fragment>
      <Dialog
        open={isModalExportMA}
        onClose={() => setModalExportMA(false)}
        aria-labelledby="alert-dialog-title-422"
        aria-describedby="alert-dialog-description-422"
        // fullWidth
      >
        <DialogTitle
          id="alert-dialog-title-422"
          style={{ textAlign: "center" }}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-start">
            <IconButton size="small" onClick={() => setModalExportMA(false)}>
              <HighlightOffIcon style={{ color: "grey", fontSize: "17px" }} />
            </IconButton>
          </Grid>

          <Box marginTop={2}>
            <img
              src={IconWarningYellow}
              alt="info-icon-pict-info"
              style={{ width: 48, height: 40 }}
            />
          </Box>
        </DialogTitle>

        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description-422">
            <Typography variant="subtitle1" className={classes.title}>
              <b>
                Export <i>Measured Activity</i> ini ?
              </b>
            </Typography>
          </DialogContentText>
        </DialogContent>

        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            variant="outlined"
            onClick={() => setModalExportMA(false)}
            color="primary"
            size="small"
            className={classes.title}>
            <span style={{ fontSize: 12 }}>Batal</span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            sizeDialogError="small"
            className={classes.title}
            onClick={handleSubmitExportMA}>
            {loader !== true ? (
              <span style={{ fontSize: 12 }}>Ya</span>
            ) : (
              <CircularProgress size={16} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>
      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
};

export default DialogExportMA;

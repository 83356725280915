import React from "react";
import { Box, Divider, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import NavMenu from "./components/nav-menu";
import SideMenu from "./components/side-menu";
import MainMenu from "./components/main-menu";
import { styles } from "./style/styleMeeting";

const useStyles = makeStyles(styles);

const ViewMeeting = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box mb={3} className={classes.layoutMarginLeftAndTop}>
        <NavMenu classes={classes} />
      </Box>

      <Divider orientation="horizontal" />

      <Box>
        <Grid container>
          <Grid md={3}>
            <SideMenu classes={classes} />
          </Grid>

          <Grid md={9} className={classes.mainArea}>
            <MainMenu classes={classes} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ViewMeeting;

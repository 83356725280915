import React, { Fragment } from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { Paper, Typography, Box } from "@material-ui/core";

import ProgressBarItem from "./ProgressBarItem";

import PictNewDashboard from "../../../../assets/images/new_dashboard.png";
import { styles } from "../../../7WD/Goal/Style/StyleGoal";

const numeral = require("numeral");

const useStyle = makeStyles(styles);

const CardAchievementYou = (props) => {
  const { collectionSelfGoal, collectionSelfMeasuredActivity } = props;

  const classes = useStyle();

  return (
    <Fragment>
      <Box
        marginTop={theme.spacing(0.3)}
        marginLeft={theme.spacing(0.9)}
        marginRight={theme.spacing(0.4)}>
        <Paper elevation={1} style={{ backgroundColor: "#edf4fe" }}>
          <Box
            display="flex"
            justifyContent="center"
            paddingTop={theme.spacing(0.7)}>
            <img
              src={PictNewDashboard}
              width={124}
              height={72}
              alt="dashboard"
            />
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            marginTop={theme.spacing(0.5)}>
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "#3b7ecf" }}>
              <b>
                Anda telah mencapai &nbsp;
                {`${numeral(
                  collectionSelfGoal[0].overall_result_achievement * 100
                ).format("0.00")}%`}{" "}
                dari target Anda !
              </b>
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="left"
            marginTop={theme.spacing(0.5)}
            marginLeft={theme.spacing(0.2)}>
            <Typography variant="subtitle2" className={classes.title}>
              <b>
                Performa <i>Goal</i> Anda
              </b>
            </Typography>
          </Box>

          <Box display="flex">
            <Box
              flexGrow={1}
              marginTop={theme.spacing(0.2)}
              marginLeft={theme.spacing(0.2)}>
              {collectionSelfGoal.length > 0 &&
                collectionSelfGoal.map((item, i) => {
                  return (
                    <Fragment key={item.id}>
                      <ProgressBarItem item={item} index={i} />
                    </Fragment>
                  );
                })}
            </Box>
            <Box
              marginTop={theme.spacing(0.3)}
              marginRight={theme.spacing(0.2)}>
              <Typography variant="caption" className={classes.title}>
                {`${numeral(
                  collectionSelfGoal[0].overall_result_achievement * 100
                ).format("0.00")}%`}
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="left"
            marginTop={theme.spacing(0.7)}
            marginLeft={theme.spacing(0.2)}>
            <Typography variant="subtitle2" className={classes.title}>
              <b>
                Performa <i>Measured Activity</i> Anda
              </b>
            </Typography>
          </Box>

          <Box display="flex">
            <Box
              flexGrow={1}
              marginTop={theme.spacing(0.2)}
              marginLeft={theme.spacing(0.2)}
              // marginRight={theme.spacing(0.2)}
            >
              {collectionSelfMeasuredActivity.length > 0 &&
                collectionSelfMeasuredActivity.map((item, i) => {
                  return (
                    <Fragment key={item.id}>
                      <ProgressBarItem item={item} index={i} />
                    </Fragment>
                  );
                })}
            </Box>

            <Box
              marginTop={theme.spacing(0.3)}
              marginRight={theme.spacing(0.2)}>
              <Typography variant="caption" className={classes.title}>
                {`${numeral(
                  collectionSelfMeasuredActivity[0].overall_result_achievement *
                    100
                ).format("0.00")}%`}
              </Typography>
            </Box>
          </Box>

          <br />
          <br />
        </Paper>
      </Box>
    </Fragment>
  );
};

export default CardAchievementYou;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

import React from "react";
import { MenuItem, FormControl, Select } from "@material-ui/core";

import { BootstrapInput } from "../group-by/BootstrapInput";

function MenuSelect({ classes, OrderByList, isDisable, onSelected }) {
  const [order, setOrder] = React.useState("");

  React.useEffect(() => {
    if (OrderByList.length === 0) {
      setOrder("");
    }
  }, [OrderByList]);

  const handleChange = (e) => {
    const val = e.target.value;

    const filterSelectOrder =
      OrderByList !== undefined &&
      OrderByList.length > 0 &&
      OrderByList.filter((item) => item.field === val);

    // console.log("SELECT filterSelectOrder :", filterSelectOrder);
    onSelected(filterSelectOrder[0]);
    setOrder(val);
  };

  return (
    <FormControl>
      <Select
        value={order}
        onChange={handleChange}
        input={<BootstrapInput />}
        disabled={isDisable}>
        {OrderByList !== undefined &&
          OrderByList.length > 0 &&
          OrderByList.map((item, i) => {
            return (
              <MenuItem
                key={i}
                dense
                value={item.field}
                className={classes.hoverItem}>
                {item.name}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}

export default MenuSelect;

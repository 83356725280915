import React, { Fragment } from "react";
import {
  Grid,
  Chip,
  Typography,
  Button,
  ListItemText,
  IconButton,
  Box,
} from "@material-ui/core";

import { useTheme } from "@material-ui/core/styles";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import EventNoteIcon from "@material-ui/icons/EventNote";

import { grey } from "@material-ui/core/colors";
import moment from "moment";

import DialogDateRangeMultiple from "../Components/DialogDateRangeMultiple";
import ComponentDueDate from "../ComponentSubGoal/ComponentDueDate";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";

const CardFourth = (props) => {
  const {
    classes,
    context,
    i,

    isShowDescription,
    setShowDescription,

    handleChooseRange,
    isShowDueDateComponent,

    isShowDateRangeComponent,
    setShowDateRangeComponent,

    setShowDueDateComponent,
    handleCloseTimeFrame,

    isShowDueDateComponentDirectly,
    setShowDueDateComponentDirectly,

    handleClickTimeFrame,
    anchorElTimeFrame,

    handleChooseDueDate,
    handleChoosePeriod,

    anchorElPeriod,
    handleClosePeriod,

    handlePilihPeriod,
    isShowPeriodComponent,

    goalDetailState,
  } = props;

  const theme = useTheme();

  return (
    <Grid container>
      <Grid item md={3}>
        {isShowDescription !== true ? (
          <Typography
            onClick={() => setShowDescription(true)}
            variant="subtitle2"
            className={classes.title}
            style={{ color: "grey", marginTop: 16, cursor: "pointer" }}>
            <b>Tulis Deskripsi</b>
          </Typography>
        ) : (
          <Box marginTop={theme.spacing(0.2)}>
            <IconButton size="small" onClick={() => setShowDescription(false)}>
              <ExpandLessIcon style={{ color: grey[400] }} />
            </IconButton>
          </Box>
        )}
      </Grid>

      <Grid item md={8} style={{ textAlign: "right" }}>
        {context.period.name !== "" && (
          <Chip
            size="small"
            style={{
              marginTop: theme.spacing(2),
              marginRight: theme.spacing(2),
            }}
            variant="outlined"
            label={
              <Typography
                variant="caption"
                className={classes.title}
                style={{ color: "grey" }}>
                <b>{context.period.name}</b>
              </Typography>
            }
          />
        )}

        {context.period.name === "" && (
          <Button
            style={{ marginTop: 16, borderWidth: 0 }}
            variant="outlined"
            size="small"
            className={classes.timeFrameIcontDateRangeText}
            onClick={handleChooseRange}
            disabled={isShowDueDateComponent === true ? true : false}>
            {/* <b>
                    { 
                        moment(context.startDateRangeState).format('DD MMMM YYYY') 
                    } 

                    { 
                        isShowDueDateComponent === true ? 
                        "" : 
                            "- " + moment(context.endDateRangeState).format('DD MMMM YYYY') 
                        }                                                                                                                                       
                </b>      
            */}
            <b>
              {isShowDueDateComponent === true
                ? ""
                : moment(goalDetailState.start_date).format("DD MMMM YYYY") +
                  " - "}

              {moment(goalDetailState.end_date).format("DD MMMM YYYY")}
            </b>
          </Button>
        )}

        {isShowDateRangeComponent === true && (
          <Fragment>
            <DialogDateRangeMultiple
              index={i}
              classes={classes}
              userTokenState={context.userTokenStateGlobal}
              isShowDateRangeComponent={isShowDateRangeComponent}
              setShowDateRangeComponent={setShowDateRangeComponent}
              setShowDueDateComponent={setShowDueDateComponent}
              handleCloseTimeFrame={handleCloseTimeFrame}
              goalDetailState={goalDetailState}
            />
          </Fragment>
        )}

        {isShowDueDateComponentDirectly === true && (
          <ComponentDueDate
            selectedDueDate={context.endDateRangeState}
            setSelectedDueDate={context.setEndDateRangeState}
            isShowDueDateComponentDirectly={isShowDueDateComponentDirectly}
            setShowDueDateComponentDirectly={setShowDueDateComponentDirectly}
          />
        )}
      </Grid>

      {/* 
          ```````````````````````````
          SPEED DIAL/ ICON TIME FRAME

          ````````````````````````````                                                  
      */}
      <Grid item md={1} style={{ textAlign: "right" }}>
        <Box marginTop={theme.spacing(0.1)}>
          <IconButton onClick={handleClickTimeFrame} size="medium">
            <EventNoteIcon
              color="primary"
              // style={{color: grey[400] }}
            />
          </IconButton>
        </Box>
      </Grid>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorElTimeFrame}
        keepMounted
        open={Boolean(anchorElTimeFrame)}
        onClose={handleCloseTimeFrame}>
        <StyledMenuItem
          onClick={handleChooseDueDate}
          // onClick={() => setShowDueDateComponentDirectly(true)}
        >
          <ListItemText
            primary={
              <Typography variant="subtitle2" className={classes.title}>
                <b>Due Date</b>
              </Typography>
            }
          />
        </StyledMenuItem>

        <StyledMenuItem onClick={handleChooseRange}>
          <ListItemText
            primary={
              <Typography variant="subtitle2" className={classes.title}>
                <b>Date Range</b>
              </Typography>
            }
          />
        </StyledMenuItem>

        <StyledMenuItem onClick={handleChoosePeriod}>
          <ListItemText
            primary={
              <Typography variant="subtitle2" className={classes.title}>
                <b>Period</b>
              </Typography>
            }
          />
        </StyledMenuItem>
      </StyledMenu>

      {/* 

                ```````````````````````
                LIST PERIOD STYLED MENU

                ```````````````````````
            */}
      <StyledMenu
        id="customized-menu-period"
        anchorEl={anchorElPeriod}
        keepMounted
        open={Boolean(anchorElPeriod)}
        onClose={handleClosePeriod}
        style={{ marginTop: "-160px" }}>
        {isShowPeriodComponent === true &&
        context.periodCollections.length > 0 ? (
          context.periodCollections.map((item, i) => (
            <Fragment>
              <StyledMenuItem
                key={i}
                onClick={(e) => handlePilihPeriod(e, item)}>
                <ListItemText
                  primary={
                    <Typography variant="subtitle2" className={classes.title}>
                      <b>{item.name}</b>
                    </Typography>
                  }
                />
              </StyledMenuItem>
            </Fragment>
          ))
        ) : (
          <StyledMenuItem disabled>
            <ListItemText
              primary={
                <Typography variant="subtitle2" className={classes.title}>
                  <b>Anda belum memiliki periode ;(</b>
                </Typography>
              }
            />
          </StyledMenuItem>
        )}
      </StyledMenu>
    </Grid>
  );
};

export default CardFourth;

import React, { useEffect, useState, Fragment } from "react";
import { IndexedDBConfig } from "./constants/config-indexed-db";
import { initDB } from "react-indexed-db";

import { useRoutes, useRedirect } from "hookrouter";

import { routes } from "./routes";
import NotFoundPage from "./screen/Explore/NotFoundPage";
import CacheBuster from "./CacheBuster";

import preval from "preval.macro";
import DialogNewVersion from "./components/DialogNewVersion";

initDB(IndexedDBConfig);

const buildTimeCurrent = preval`module.exports = new Date().toLocaleString();`;

const App = () => {
  const [open, setOpen] = useState(false);
  /*
     -------------------------------
    |                                 |
    | HANDLE ROUTER WITH HOOK ROUTER  |
    |                                 |
      -------------------------------
   */

  useRedirect("/", "/home");
  const routeResult = useRoutes(routes);

  useEffect(() => {
    //*
    // const elem = document.getElementById("progress");

    // if (elem) {
    //   elem.remove();
    // }

    console.log("Build Date time : ", buildTimeCurrent);
  }, []);

  return (
    <Fragment>
      <CacheBuster>
        {({ loading, isLatestVer, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVer) {
            // You can decide how and when you want to force reload
            // setOpen(true);
            setTimeout(() => {
              window.location.reload(true);
              refreshCacheAndReload();
            }, 500);
          }

          return routeResult || <NotFoundPage />;
        }}
      </CacheBuster>

      <DialogNewVersion open={open} setOpen={setOpen} />
    </Fragment>
  );
};

export default App;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

import { navigate } from "hookrouter";

import logoPerformate from "../../../assets/images/performate-03.png";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  title: {
    fontFamily: "Roboto",
    color: "#333",
  },
  imageLogo: {
    verticalAlign: "text-bottom",
    width: 35,
    height: 35,
  },
  alternateBackground: {
    backgroundColor: "#f5f5f5",
  },
  bottomBackground: {
    backgroundColor: "#d1364a",
    color: "#ffffff",
  },
  // =============================
  // MEBERSHIP STYLES
  // =============================

  cardPackage: {
    width: 325,
    borderRadius: 7.5,
  },
  txtTitle: {
    color: "#fff",
    fontFamily: "Roboto",
    fontSize: 16,
    width: 95,
    borderBottomRightRadius: 40,
    borderTopLeftRadius: 7.5,
    padding: `2px 10px`,
    fontWeight: "600",
  },
  txtPrice: {
    // color: "#d63031",
    fontFamily: "Roboto",
    fontWeight: "bold",
    padding: `0 10px`,
    margin: `25px 0 5px 0`,
    fontSize: 35,
    textAlign: "left",
  },
  txtStartsFrom: {
    fontFamily: "Roboto",
    textAlign: "left",
    color: "#fff",
  },
  listBenefits: {
    padding: `0 10px`,
    marginBottom: 25,
  },
  benefitText: {
    color: "#fff",
    fontSize: 13,
    fontFamily: "Roboto",
  },
});

const useStyles = makeStyles(styles);

const businessPrice = [
  {
    name: "Bisnis",
    price: "Rp 3.500.000",
    colorBg: "#102770",
    colorTxt: "#eb4d4b",
    colorPrice: "#eb4d4b",
    benefits: [
      "25 Lisensi user / bulan & training",
      "4 Sesi konsultasi per bulan",
    ],
  },
  {
    name: "Personal",
    price: "Rp 25.000",
    colorBg: "#eb4d4b",
    colorTxt: "#102770",
    colorPrice: "#102770",
    benefits: ["User / bulan"],
  },
];

const Price = (props) => {
  //const { classes } = props;
  const classes = useStyles();

  return (
    <div>
      <section style={{ paddingTop: "0px", textAlign: "center" }}>
        <div className="box-price2">
          <Box marginTop={14} display="flex" justifyContent="center">
            <Typography variant="h4" className={classes.title}>
              <b>Harga Paket</b>
            </Typography>
          </Box>
          <Box marginTop={4} display="flex" justifyContent="center">
            {businessPrice.map((item, i) => (
              <Box
                key={i}
                className={classes.cardPackage}
                margin={2}
                style={{ backgroundColor: item.colorBg }}>
                <Typography
                  variant="subtitle1"
                  className={classes.txtTitle}
                  style={{ backgroundColor: item.colorTxt }}>
                  {item.name}
                </Typography>

                {/* {item.name === "Personal" && (
                  <Typography
                    variant="caption"
                    className={classes.txtStartsFrom}>
                    mulai dari
                  </Typography>
                )} */}
                <Typography
                  variant="h3"
                  className={classes.txtPrice}
                  style={{ color: item.colorPrice }}>
                  {item.price}
                </Typography>

                <Box marginTop={2} className={classes.listBenefits}>
                  {item.benefits.map((benefit, j) => (
                    <Box
                      key={j}
                      display="flex"
                      alignItems="center"
                      marginBottom={1}>
                      <CheckIcon
                        style={{ color: "#fff", marginRight: 7 }}
                        fontSize="small"
                      />
                      <Typography
                        variant="subtitle2"
                        className={classes.benefitText}>
                        {benefit}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </div>
        <div className="box-price"></div>
        <br />
        <br />
        <br />
        <br />
      </section>

      <section
        className={classes.bottomBackground}
        style={{ textAlign: "center" }}>
        <br />
        <br />
        <br />
        <span className="bottom-main-title">
          Get better goals results today
        </span>
        <br />
        <br />
        <br />
        <Button
          onClick={() => navigate("/register")}
          variant="contained"
          color="primary"
          disableElevation
          className={classes.textLowerCase}
          style={{ width: "160px", margin: "0 auto" }}>
          TRY IT FOR FREE
        </Button>
        <br />
        <br />
        <br />
      </section>
    </div>
  );
};

export default Price;

import React from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@material-ui/core";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import moment from "moment";

import { styles } from "../../../7WD/Goal/Style/StyleGoal";
import PictEmptyStateMA from "../../../7WD/MeasureActivities/Components/PictEmptyStateMA";
import ImageSmallUser from "../../../../components/ImageSmallUser";

const useStyle = makeStyles(styles);

const CardTDL = ({ tdlCollections }) => {
  const classes = useStyle();

  return (
    <Box
      marginTop={theme.spacing(0.3)}
      marginLeft={theme.spacing(0.9)}
      marginRight={theme.spacing(0.4)}>
      <Paper elevation={1}>
        {tdlCollections.length === 0 && (
          <Grid container direction="row" justify="center" alignItems="center">
            <Box marginBottom={theme.spacing(0.8)}>
              <Box display="flex" justifyContent="center">
                <PictEmptyStateMA />
              </Box>

              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "grey" }}>
                Tidak ada{" "}
                <b>
                  <i>Todo List</i>
                </b>{" "}
                yang muncul saat ini.
              </Typography>
            </Box>
          </Grid>
        )}

        {tdlCollections.length > 0 && (
          <Grid container>
            <Grid item sm={12} md={12} xl={12}>
              <Box
                marginLeft={theme.spacing(0.2)}
                marginBottom={theme.spacing(0.2)}>
                <Typography variant="subtitle2" className={classes.title}>
                  <b>{`${
                    tdlCollections.length <= 5 ? tdlCollections.length : 5
                  } Todo List Anda`}</b>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item xs={12}>
            <List>
              {tdlCollections.length > 0 &&
                tdlCollections.slice(0, 5).map((item, i) => {
                  return (
                    <ListItem key={i}>
                      <ListItemAvatar>
                        <Avatar>
                          {item.owner.member.self.rel !== null &&
                          item.owner.member.self.rel !== undefined ? (
                            <ImageSmallUser
                              item={item.owner.member.self.rel.photo_url}
                              index={1}
                            />
                          ) : (
                            <AccountCircleIcon fontSize="large" />
                          )}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}>
                            {`${item.name}`}{" "}
                            <i>
                              <b>({item.status.name})</b>
                            </i>
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="caption"
                            className={classes.title}>
                            {moment(item.end_timestamp).format(
                              "DD MMMM YYYY, HH:mm"
                            )}
                          </Typography>
                        }
                      />
                    </ListItem>
                  );
                })}
            </List>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default CardTDL;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#c62828", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

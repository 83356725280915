import React, { useState, useEffect, useContext, Fragment } from "react";
import {
  Typography,
  Grid,
  TextField,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemIcon,
  Radio,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import MomentUtils from "@date-io/moment";
import moment from "moment";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ContextGoal from "../Context/ContextGoal";

import ImageSmallUser from "../../../../components/ImageSmallUser";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";
import Snackbeer from "../../../../components/Snackbeer";
import LightTooltip from "../../../../components/LightTooltip";

import DialogError from "../../../../components/DialogError";

import { URL_API } from "../../../../constants/config-api";
import axiosConfig from "../../../../constants/config-axios";
import Capitalize from "../../../../utilities/Capitalize";
import AvatarDummy from "../../../../assets/images/Avatar_dummy.png";
import DialogDateRange from "../Components/DialogDateRange";

import DialogCreateModeWizard from "./DialogCreateModeWizard";

import "moment/locale/id";

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const DialogCreate = (props) => {
  const {
    classes,
    isModalCreate,
    setModalCreate,
    // fotoQuery,
    // setFotoQuery,
    userLoginName,
    collectionGoalList,
    setCollectionGoalList,
  } = props;

  const context = useContext(ContextGoal);

  /*
        ````````
        USE REFF

        ````````
    */

  const SWD_GOAL_INPUT_METHOD_INCREMENT = "swd_goal_input_method_increment";
  const SWD_GOAL_INPUT_METHOD_LAST_VALUE = "swd_goal_input_method_last_value";

  const SWD_GOAL_CALCULATION_METHOD_SUM = "swd_goal_calculation_method_sum";
  const SWD_GOAL_CALCULATION_METHOD_LAST_VALUE =
    "swd_goal_calculation_method_last_value";
  const SWD_GOAL_CALCULATION_METHOD_AVERAGE =
    "swd_goal_calculation_method_average";

  useEffect(() => {
    if (isModalCreate === true) {
      context.setSuccessCreateGoal(false);

      // setCollectionGoalList([])

      //*
      // setOwnerList([]);

      setBigGoal("");
      setOwnerId("");

      setDisabledButton(false);

      //*Untuk kedua kalinya, child panggil anak-nya
      const statusUserLogin = localStorage.getItem("status_user_login");
      const statusUserLoginAfterParse = JSON.parse(statusUserLogin);

      //*
      setShowTextFieldTargetRangeValue(false);
      setStartValue("0");

      //*DEFAULT INCREMENT
      setSelectedValueInputMethod({
        id: "2",
        code: SWD_GOAL_INPUT_METHOD_INCREMENT,
        name: "increment",
      });
    }
  }, [isModalCreate]);

  /*
        ```````````````````
        COMPONENT DID MOUNT 

        ```````````````````
    */
  const userToken = localStorage.getItem("userToken");
  const [fotoQuery, setFotoQuery] = useState(null);

  const [startDateRangeState, setStartDateRangeState] = useState(null);
  const [endDateRangeState, setEndDateRangeState] = useState(null);

  const startDateRange = localStorage.getItem("start_date");
  const endDateRange = localStorage.getItem("end_date");

  const [userTokenState, setUserTokenState] = useState("");
  const [positionList, setPositionList] = useState([]);
  const [calculationMethodList, setCalculationMethodList] = useState([]);
  const [inputMethodList, setInputMethodList] = useState([]);
  const [inputAchievementTypeList, setInputAchievementTypeList] = useState([]);
  const [inputValueTypeList, setInputValueTypeList] = useState([]);
  const [targetTypeList, setTargetTypeList] = useState([]);

  const [periodCollections, setPeriodCollections] = useState([]);
  const [ownerList, setOwnerList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  //   STATE WITH EVIDENCE OR NOT
  const [evidance, setEvidance] = useState(false);

  //*Enable Dialog INFO
  useEffect(() => {
    if (isModalCreate === true) {
      setUserTokenState(userToken);
      setLoader(false);
      setDisabledButton(false);

      setFotoQuery(null);

      if (userToken !== undefined || userToken !== null) {
        axiosConfig
          .get(URL_API + "/swd/goal/create")
          .then(function (response) {
            console.log("Response Original Label : ", response);

            if (response.status === 200) {
              //*
              setSelectedValueCalcMethod({
                id: "1",
                code: SWD_GOAL_CALCULATION_METHOD_SUM,
                name: "sum",
              });
              setCalculationMethodList([
                {
                  id: "1",
                  code: SWD_GOAL_CALCULATION_METHOD_SUM,
                  name: "sum",
                },
                {
                  id: "2",
                  code: SWD_GOAL_CALCULATION_METHOD_AVERAGE,
                  name: "average",
                },
              ]);

              //*
              setInputMethodList(response.data.data.inputMethodCollections);
              setInputAchievementTypeList(
                response.data.data.achievementTypeCollections
              );
              setInputValueTypeList(response.data.data.valueTypeCollections);

              setTargetTypeList(response.data.data.targetTypeCollections);

              setPeriodCollections(response.data.data.periodCollections);
              setOwnerList(response.data.data.ownerCollections);

              setMemberList(response.data.data.ownerCollections);
            }
          })
          .catch(function (error) {
            console.log("Error : ", error.response);
          });
      } else {
        console.log("No Access Token available!");
        setOpenDialogError(true);
      }

      //******************************* */
    }
  }, [isModalCreate]);

  useEffect(() => {
    setStartDateRangeState(startDateRange);
    setEndDateRangeState(endDateRange);
  }, [startDateRange, endDateRange]);

  /*
        ``````````````````````
        HANDLE CHANGE BIG GOAL

        ```````````````````
    */
  const [bigGoal, setBigGoal] = useState("");
  const handleChangeBIGGoal = (e) => {
    e.preventDefault();
    setBigGoal(e.target.value);
  };

  /*
        `````````````````````````
        HANDLE CHANGE DESCRIPTION

        `````````````````````````
    */
  const [description, setDescription] = useState("");
  const handleChangeDescription = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  /*
        ``````````````````````````
        HANDLE CHANGE TARGET VALUE

        ``````````````````````````
    */
  const [targetValue, setTargetValue] = useState("");
  const handleChangeTargetValue = (e) => {
    e.preventDefault();
    setTargetValue(e.target.value);
  };

  /*
        `````````````````````````
        HANDLE CHANGE START VALUE

        `````````````````````````
    */
  const [startValue, setStartValue] = useState("0");
  const handleChangeStartValue = (e) => {
    e.preventDefault();
    setStartValue(e.target.value);
  };

  /*
        `````````````````
        DROPDOWN POSITION

        `````````````````
    */
  const [anchorElTimePosition, setAnchorElTimePosition] = useState(null);

  const [textValuePosition, setTextValuePosition] = useState("");

  const [ownerId, setOwnerId] = useState("");

  function handleClickDropdownPosition(event) {
    setAnchorElTimePosition(event.currentTarget);
  }

  function handleCloseDropdownPosition() {
    setAnchorElTimePosition(null);
  }

  const handleChoosePosition = (e, data) => {
    console.log("Data : ", data);

    handleCloseDropdownPosition();

    // console.log("Position selected : ", data);
    setTextValuePosition(data.structure_position_title_name);
    setOwnerId(data.id);
  };

  /*
        ``````````````````````````````````
        HANDLE SELECTED CALCULATION METHOD 

        `````````````````````````````````
    */
  const [selectedValueCalcMethod, setSelectedValueCalcMethod] = useState({
    id: "1",
    code: SWD_GOAL_CALCULATION_METHOD_LAST_VALUE,
    name: "last value",
  });

  const handleChangeRadioButton = (e, data) => {
    e.preventDefault();
    setSelectedValueCalcMethod(data);

    // console.log("SWD_GOAL_CALCULATION_METHOD : ", data);

    if (data.code === "swd_goal_calculation_method_average") {
      setTargetTypeList([
        {
          code: "swd_goal_target_type_final",
          id: "2",
          name: "Final",
        },
      ]);

      setTargetType({
        code: "swd_goal_target_type_final",
        id: "2",
        name: "Final",
      });
    } else {
      setTargetTypeList([
        {
          code: "swd_goal_target_type_daily",
          id: "1",
          name: "Daily",
        },
        {
          code: "swd_goal_target_type_final",
          id: "2",
          name: "Final",
        },
      ]);

      setTargetType({
        code: "swd_goal_target_type_daily",
        id: "1",
        name: "Daily",
      });
    }
  };

  /*
        ``````````````````````````````````
        HANDLE SELECTED INPUT METHOD 

        `````````````````````````````````
    */
  const [selectedValueInputMethod, setSelectedValueInputMethod] = useState({
    id: "2",
    code: SWD_GOAL_INPUT_METHOD_INCREMENT,
    name: "increment",
  });

  const handleChangeRadioButtonInputMethod = (e, data) => {
    e.preventDefault();
    setSelectedValueInputMethod(data);

    // console.log("Data INPUT METHOD : ", data);

    if (data.code === SWD_GOAL_INPUT_METHOD_INCREMENT) {
      setCalculationMethodList([
        {
          id: "1",
          code: SWD_GOAL_CALCULATION_METHOD_SUM,
          name: "sum",
        },
        {
          id: "2",
          code: SWD_GOAL_CALCULATION_METHOD_AVERAGE,
          name: "average",
        },
      ]);

      setSelectedValueCalcMethod({
        id: "1",
        code: SWD_GOAL_CALCULATION_METHOD_SUM,
        name: "sum",
      });
    } else {
      setSelectedValueCalcMethod({
        id: "3", //"1",
        code: SWD_GOAL_CALCULATION_METHOD_LAST_VALUE,
        name: "last value",
      });

      setCalculationMethodList([
        {
          id: "3", //"1",
          code: SWD_GOAL_CALCULATION_METHOD_LAST_VALUE,
          name: "last value",
        },
      ]);
    }
  };

  /*
        ====================================
        HANDLE SELECTED ACHIEVEMENT METHOD 
        ====================================`
    */

  const [selectedValueAchievementType, setSelectedValueAchievementType] =
    useState({
      id: "1",
      code: "swd_goal_achievement_type_more_oriented",
      name: "more oriented",
    });

  const handleChangeRadioButtonAchievementType = (e, data) => {
    e.preventDefault();
    setSelectedValueAchievementType(data);
  };

  /*
        =====================================
        HANDLE CHANGE TARGET VALUE RANGE
        =====================================
    */
  const [targetValueRange, setTargetValueRange] = useState("");
  const handleChangeTargetValueRange = (e) => {
    e.preventDefault();
    setTargetValueRange(e.target.value);
  };

  /*
        =====================================
        HANDLE SELECTED  VALUE METHOD 
        =====================================
    */

  const [selectedValueType, setSelectedValueType] = useState({
    id: "1",
    code: "numeric",
    name: "numeric",
  });

  const [isShowPercent, setShowPercent] = useState(false);
  const handleChangeRadioButtonValueType = (e, data) => {
    e.preventDefault();
    setSelectedValueType(data);

    if (data.code === "percentage") {
      setShowPercent(true);
    } else {
      setShowPercent(false);
    }
  };

  /*
        =====================================
        HANDLE SELECTED  TARGET TYPE
        =====================================
    */
  const [targetType, setTargetType] = useState({
    code: "swd_goal_target_type_daily",
    id: "1",
    name: "Daily",
  });

  const handleSelectTargetType = (e, data) => {
    e.preventDefault();
    setTargetType(data);
  };

  /*
        ```````````````````
        DROPDOWN TIME FRAME
        ```````````````````
    */
  const [locale, setLocale] = useState("id");

  const [anchorElTimeFrame, setAnchorElTimeFrame] = useState(null);
  const [textValueTimeFrame, setTextValueTimeFrame] = useState("Time Frame");

  function handleClickTimeFrame(event) {
    setAnchorElTimeFrame(event.currentTarget);
  }

  function handleCloseTimeFrame() {
    setAnchorElTimeFrame(null);
  }

  /*
        ``````````````````````````
        HANDLE CHOOSE MEMBER OWNER

        ``````````````````````````
    */

  const handleChooseMemberOwner = (e, data) => {
    e.preventDefault();

    // console.log("Wew data : ", data);
    if (data !== null) {
      setOwnerId(data.id);

      setTextValuePosition(data.structure_position_title_name);

      if (data.member.self.rel.photo_url !== null) {
        setFotoQuery(data.member.self.rel.photo_url);
      } else {
        setFotoQuery(null);
      }
    }
    //*
    // console.log("Member Owner Choosed : ", data);
  };

  /*
        `````````````````````
        HANDLE TIME FRAME ID

        - idDueDate: 3,
        - idDateRange: 2,
        - idPeriod: 1

        `````````````````````
    */
  const [timeFrameId, setTimeFrameId] = useState(null);

  /*
        ```````````````
        DUE DATE OPTION

        ```````````````
    */
  const [selectedDueDate, setSelectedDueDate] = useState("");
  const [isShowDueDateComponent, setShowDueDateComponent] = useState(false);
  const [isShowDueDateComponentDirectly, setShowDueDateComponentDirectly] =
    useState(false);

  const handleChooseDueDate = () => {
    // setEndDateRangeState(new Date()) //*Baris kode ini untuk validasi DISABLED Button saja !
    setTimeFrameId("3");

    handleCloseTimeFrame();
    setShowDueDateComponent(true);
    setShowDueDateComponentDirectly(true);
    setShowTextPeriod(false);

    setShowDateRangeComponent(false);
    setShowTextDateRange(false);
    setTextValueTimeFrame("Due Date");

    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");
  };

  /*
        `````````````````
        DATE RANGE OPTION

        `````````````````
    */
  const [isShowDateRangeComponent, setShowDateRangeComponent] = useState(false);
  const [isShowTextDateRange, setShowTextDateRange] = useState(false);

  const handleChooseRange = () => {
    setTimeFrameId("2");
    // setSelectedDueDate(new Date());//*Baris kode ini untuk validasi DISABLED Button saja !
    handleCloseTimeFrame();
    setShowDueDateComponent(false);
    setShowDueDateComponentDirectly(false);
    setShowDateRangeComponent(true);
    setShowTextDateRange(true);
    setShowTextPeriod(false);

    setTextValueTimeFrame("Date Range");
  };

  /*
        ``````````````
        PERIODE OPTION

        ``````````````
    */
  const [isShowPeriodComponent, setShowPeriodComponent] = useState(false);
  const [isShowTextPeriod, setShowTextPeriod] = useState(false);

  const handleChoosePeriod = (event) => {
    setTimeFrameId("1");
    setAnchorElPeriod(event.currentTarget);

    //*
    handleCloseTimeFrame();
    setShowDueDateComponent(false);
    setShowDueDateComponentDirectly(false);

    setShowDateRangeComponent(false);
    setShowTextDateRange(false);

    setTextValueTimeFrame("Period");

    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");

    setShowPeriodComponent(true);
    setSelectedDueDate(null);
  };

  /*
        ````````````````````````````
        PERIODE OPTION LIST DROPDOWN

        ````````````````````````````
    */
  const [anchorElPeriod, setAnchorElPeriod] = useState(null);

  function handleClickPeriod(event) {
    //*Fungsi setAnchor di sini sudah di pakai langsung di 'handleChoosePeriod()'

    setAnchorElPeriod(event.currentTarget);
  }

  function handleClosePeriod() {
    setAnchorElPeriod(null);
  }

  const [periodId, setPeriodId] = useState("");
  const [periodName, setPeriodName] = useState("");

  const handleChoosePeriodId = (e, item) => {
    e.preventDefault();
    console.log("Item : ", item);

    setPeriodId(item.id);
    setPeriodName(item.name);

    handleClosePeriod();
    setShowTextPeriod(true);
  };

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] =
    useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  /*
        `````````````````````````
        HANDLE BUTTON SUBMIT GOAL

        `````````````````````````
    */
  const [loader, setLoader] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const handleSUBMITGoal = () => {
    setLoader(true);
    setDisabledButton(true);

    let data = {
      Goal: {
        name: bigGoal,
        // owner_id: ownerList.length > 0 && ownerId === '' ?  ownerList[0].id : ownerId,
        owner_id: ownerId,

        input_method_id: selectedValueInputMethod.id,
        achievement_type_id: selectedValueAchievementType.id,
        calculation_method_id: selectedValueCalcMethod.id,
        value_type_id: selectedValueType.id,
        period_id: periodId !== "" ? periodId : null,
        start_date: startDateRangeState !== null ? startDateRangeState : null,
        end_date:
          endDateRangeState !== null
            ? endDateRangeState
            : moment(selectedDueDate).format("YYYY-MM-DD"),
        start_value: startValue !== "0" ? startValue : 0,
        target_value: targetValue,
        target_range_value: targetValueRange !== null ? targetValueRange : null,
        description: description,
        time_frame_id: timeFrameId !== null ? timeFrameId : null,
        target_type_id: targetType.id,
        is_evidence_required: evidance === true ? 1 : 0, //*0 === Tidak butuh evidence, 1 === Butuh evidence
      },
    };

    if (data.Goal.period_id === null) {
      delete data.Goal.period_id;
    }

    if (
      data.Goal.period_id !== null &&
      data.Goal.start_date === null &&
      data.Goal.end_date === "Invalid date"
    ) {
      delete data.Goal.start_date;
      delete data.Goal.end_date;
    }

    if (data.Goal.start_date === null) {
      delete data.Goal.start_date;
    }

    if (
      data.Goal.target_range_value === null ||
      data.Goal.target_range_value === ""
    ) {
      delete data.Goal.target_range_value;
    }

    console.log("Goal SUBMIT : ", data);

    if (userTokenState !== "") {
      axiosConfig
        .post(URL_API + "/swd/goal?mode=simple", data)
        .then(function (response) {
          setDisabledButton(false);

          // console.log("Response Original : ", response);

          if (response.status === 200) {
            // setCollectionGoalList([...collectionGoalList, response.data.data]);
            context.setSuccessCreateGoal(Math.floor(Math.random() * 1000 + 1));
            setModalResponse200(true);

            setModalCreate(false);
            if (response.data.data.status_id === "2") {
              //*Jika status Goal Approved

              context.setValueTab(1);
              context.setStatusGoalId(2);
            }
          }
          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
          setDisabledButton(false);

          if (error.response !== undefined) {
            if (error.response.status === 400) {
              setErrorStatus(400);

              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if (error.response.status === 500) {
              setErrorStatus(500);
              setOpenDialogError(true);
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorInformationValue(
                  "Error 500 : " + Capitalize(error.response.data.message)
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }

          console.log("Error : ", error.response);
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  /*
        ````````````````
        HANDLE SNACKBEER 

        ````````````````
    */
  const [isModalResponse200, setModalResponse200] = useState(false);

  /*
        `````````````````````
        SHOW SIX RADIO BUTTON

        `````````````````````
    */
  const [isShowSixRadioButton, setShowSixRadioButton] = useState(false);

  /*
        ``````````````````````````````
        HANDLE CREATE GOAL MODE WIZARD 

        ``````````````````````````````
    */
  const [isModalCreateModeWizard, setModalCreateModeWizard] = useState(false);

  /*
        ``````````````````````````````````
        HANDLE SHOW TEXT FIELD RANGE VALUE

        ``````````````````````````````````
    */
  const [isShowTextFieldTargetRangeValue, setShowTextFieldTargetRangeValue] =
    useState(false);

  useEffect(() => {
    //*
    if (
      startValue === targetValue &&
      (selectedValueCalcMethod.code === SWD_GOAL_CALCULATION_METHOD_SUM ||
        selectedValueCalcMethod.code === SWD_GOAL_CALCULATION_METHOD_LAST_VALUE)
    ) {
      setShowTextFieldTargetRangeValue(true);
    } else if (
      targetValue === "0" &&
      selectedValueCalcMethod.code === SWD_GOAL_CALCULATION_METHOD_AVERAGE
    ) {
      setShowTextFieldTargetRangeValue(true);
    } else {
      setShowTextFieldTargetRangeValue(false);
    }
  }, [startValue, targetValue, selectedValueCalcMethod]);

  // ============ CONSOLE AREA ============

  // ============ CONSOLE AREA ============

  return (
    <Fragment>
      <Dialog
        open={isModalCreate}
        onClose={() => setModalCreate(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Grid container>
            <Grid item xs={8} style={{ textAlign: "right" }}>
              <Typography variant="h6" className={classes.title}>
                <b>Buat Goal</b>
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <IconButton
                disabled //*Sementara off-in dulu aja
                onClick={() => setModalCreateModeWizard(true)}
                size="small"
                style={{ marginTop: "-8px" }}>
                <HelpOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent style={{ textAlign: "left" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="subtitle1" className={classes.title}>
              <b>Tulis Nama Goal</b>
            </Typography>
            <TextField
              id="outlined-bare"
              className={classes.textField}
              onChange={handleChangeBIGGoal}
              value={bigGoal}
              variant="outlined"
              fullWidth
              inputProps={{
                className: classes.title,
              }}
            />
          </DialogContentText>

          <Grid container>
            <Grid item md={8}>
              <List>
                <ListItem style={{ paddingLeft: 0 }}>
                  <ListItemIcon>
                    {fotoQuery !== null ? (
                      <ImageSmallUser item={fotoQuery} index={1} />
                    ) : (
                      <img
                        src={AvatarDummy}
                        alt="."
                        className={classes.imageAvatar}
                      />
                    )}
                  </ListItemIcon>

                  {/* =================================================================================================================
                    USER PILIH OWNER DI SINI, KEMUDIAN SETELAH DI PILIH, MAKA AKAN TERFILTER LIST DATA KARYAWAN YANG HENDAK DI ASSIGN
                    ================================================================================================================= */}
                  {memberList.length > 0 ? (
                    <Autocomplete
                      id="combo-box-demo"
                      options={memberList}
                      getOptionLabel={(option) =>
                        `${option.member_first_name} ${option.member_last_name}`
                      }
                      style={{ fontSize: 13 }}
                      className={classes.title}
                      noOptionsText="Tidak ada pilihan"
                      onChange={(e, owner) => handleChooseMemberOwner(e, owner)}
                      renderInput={(params) => (
                        <TextField
                          style={{ width: 230, fontSize: 13 }}
                          {...params}
                          label="Search Owner"
                          variant="standard"
                        />
                      )}
                    />
                  ) : (
                    <TextField
                      id="standard-helperText"
                      label="Search Owner"
                      style={{
                        color: "#d1354a",
                        width: 230,
                      }}
                      disabled={true}
                      defaultValue={userLoginName}
                    />
                  )}
                </ListItem>
              </List>
            </Grid>

            <Grid item md={4}>
              {/* 

                `````````````````````````````
                USER PILIH TIME FRAME DI SINI

                `````````````````````````````                        
            */}
              <Button
                onClick={handleClickTimeFrame}
                variant="outlined"
                size="small"
                className={classes.timeFrameIconInModal}
                style={{ marginTop: 24 }}>
                <IconButton style={{ background: "#edcfd8" }}>
                  <CalendarTodayIcon style={{ color: "white", fontSize: 14 }} />
                </IconButton>
                &nbsp;
                <b>{textValueTimeFrame}</b>
              </Button>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElTimeFrame}
                keepMounted
                open={Boolean(anchorElTimeFrame)}
                onClose={handleCloseTimeFrame}>
                <StyledMenuItem onClick={handleChooseDueDate}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Due Date</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                <StyledMenuItem onClick={handleChooseRange}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Date Range</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                <StyledMenuItem onClick={handleChoosePeriod}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Period</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>
              </StyledMenu>

              {/* 

                                ````````````````
                                MENU LIST PERIOD

                                ````````````````
                            */}
              <StyledMenu
                id="customized-menu-period"
                anchorEl={anchorElPeriod}
                keepMounted
                open={Boolean(anchorElPeriod)}
                onClose={handleClosePeriod}
                style={{ marginTop: "-160px" }}>
                {isShowPeriodComponent === true &&
                periodCollections.length > 0 ? (
                  periodCollections.map((item, i) => (
                    <StyledMenuItem
                      key={i}
                      onClick={(e) => handleChoosePeriodId(e, item)}>
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}>
                            <b>{item.name}</b>
                          </Typography>
                        }
                      />
                    </StyledMenuItem>
                  ))
                ) : (
                  <StyledMenuItem disabled>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}>
                          <b>Anda belum memiliki periode ;(</b>
                        </Typography>
                      }
                    />
                  </StyledMenuItem>
                )}
              </StyledMenu>

              {isShowDueDateComponent === true && ( //https://stackoverflow.com/questions/17493309/how-do-i-change-the-language-of-moment-js
                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                  <ThemeProvider theme={theme}>
                    <Fragment>
                      <DatePicker
                        value={selectedDueDate}
                        onChange={setSelectedDueDate}
                        animateYearScrolling
                        open={isShowDueDateComponentDirectly}
                        onOpen={() => setShowDueDateComponentDirectly(true)}
                        onClose={() => setShowDueDateComponentDirectly(false)}
                        variant="dialog" // dialog, static, inline
                        disableToolbar={false}
                        format="DD MMMM YYYY"
                        // orientation="landscape"
                        // TextFieldComponent =
                        // ToolbarComponent
                        label=""
                        style={{ marginLeft: 16 }}
                      />
                    </Fragment>
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              )}

              {isShowDateRangeComponent === true && (
                <Fragment>
                  <DialogDateRange
                    classes={classes}
                    userTokenState={userTokenState}
                    isShowDateRangeComponent={isShowDateRangeComponent}
                    setShowDateRangeComponent={setShowDateRangeComponent}
                    onStartDate={setStartDateRangeState}
                    onEndDate={setEndDateRangeState}
                  />
                </Fragment>
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              {isShowTextDateRange ? (
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIcontDateRangeText}
                  style={{ marginRight: 8, borderWidth: 0 }}
                  onClick={() => setShowDateRangeComponent(true)}>
                  <b>
                    {moment(startDateRangeState).format("DD MMMM YYYY")} -{" "}
                    {moment(endDateRangeState).format("DD MMMM YYYY")}
                  </b>
                </Button>
              ) : null}

              {isShowTextPeriod ? (
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIcontDateRangeText}
                  style={{ marginRight: 8, borderWidth: 0 }}
                  onClick={(e) => handleClickPeriod(e)}>
                  <b>{periodName}</b>
                </Button>
              ) : null}
            </Grid>
          </Grid>

          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "grey" }}>
            <b>Deskripsi (Optional)</b>
          </Typography>

          <TextField
            onChange={handleChangeDescription}
            style={{ marginTop: 0 }}
            id="outlined-multiline-static-description"
            // label="Multiline"
            multiline
            rows="4"
            // defaultValue="Default Value"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            fullWidth
          />

          <br />
          <Grid container>
            <Grid item xs={6}>
              {ownerList.length === 0 && (
                <Button
                  // onClick={ () => Redirect(ToSOTable) }
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIconInModal}
                  style={{ marginTop: 24 }}>
                  <IconButton style={{ background: "#edcfd8" }}>
                    <i
                      className="material-icons"
                      style={{ color: "white", fontSize: 14 }}>
                      person
                    </i>
                  </IconButton>
                  &nbsp;
                  <b>Belum ada posisi yang di tentukan untuk membuat Goal ;(</b>
                </Button>
              )}

              {ownerList.length > 0 && (
                <Button
                  onClick={handleClickDropdownPosition}
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIconInModal}
                  style={{ marginTop: 24 }}
                  disabled>
                  <IconButton style={{ background: "#edcfd8" }}>
                    <i
                      className="material-icons"
                      style={{ color: "white", fontSize: 14 }}>
                      person
                    </i>
                  </IconButton>
                  &nbsp;
                  <b>
                    {ownerList.length > 0 && textValuePosition == ""
                      ? ownerList[0].structure_position_title_name
                      : textValuePosition}
                  </b>
                </Button>
              )}

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElTimePosition}
                keepMounted
                open={Boolean(anchorElTimePosition)}
                onClose={handleCloseDropdownPosition}>
                {ownerList.length > 0 &&
                  ownerList.map((item, i) => {
                    return (
                      <StyledMenuItem
                        key={i}
                        onClick={(e) => handleChoosePosition(e, item)}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle2"
                              className={classes.title}>
                              <b>{item.structure_position_title_name}</b>
                            </Typography>
                          }
                        />
                      </StyledMenuItem>
                    );
                  })}
              </StyledMenu>
            </Grid>

            {/* 
              ``````````````````
              TARGET RANGE VALUE

              ``````````````````
          */}
            <Grid item xs={6}>
              {isShowTextFieldTargetRangeValue === true && (
                <Fragment>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    <b>Target Range value : </b>
                  </Typography>
                  <Box>
                    <TextField
                      placeholder="0"
                      type="number"
                      id="outlined-bare"
                      // onChange= { handleChangeStartValue }
                      onChange={handleChangeTargetValueRange}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              variant="subtitle1"
                              className={classes.title}
                              style={{ color: "grey" }}>
                              {isShowPercent === true ? <b>%</b> : null}
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Fragment>
              )}
            </Grid>
          </Grid>

          <br />
          <br />
          <Grid container>
            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}>
                <b>Start value : </b>
              </Typography>
              <Box marginRight={theme.spacing(0.2)}>
                <TextField
                  placeholder="0"
                  type="number"
                  id="outlined-bare"
                  onChange={handleChangeStartValue}
                  variant="outlined"
                  fullWidth
                  disabled={
                    selectedValueCalcMethod.code ===
                    SWD_GOAL_CALCULATION_METHOD_AVERAGE
                      ? true
                      : false
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          variant="subtitle1"
                          className={classes.title}
                          style={{ color: "grey" }}>
                          {isShowPercent === true ? <b>%</b> : null}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}>
                <b>Target Value : </b>
              </Typography>
              {/* <Box marginLeft={theme.spacing(0.7)}> */}
              <TextField
                // className={classes.textField}
                // color='primary'
                // onKeyDown={handleEnterPress}
                type="number"
                id="outlined-bare"
                onChange={handleChangeTargetValue}
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography
                        variant="subtitle1"
                        className={classes.title}
                        style={{ color: "grey" }}>
                        {isShowPercent == true ? <b>%</b> : null}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
              {/* </Box> */}
            </Grid>
          </Grid>

          {/* IS Evidence Required */}
          <br />

          <Grid>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  // color="primary"
                  size="small"
                  checked={evidance}
                  onChange={(e) => setEvidance(e.target.checked)}
                />
              }
              label={
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: "grey" }}>
                  Wajib lampirkan bukti
                </Typography>
              }
              labelPlacement="end"
            />
          </Grid>

          <br />
          <LightTooltip
            title={
              <div>
                Contoh input data{" "}
                <b>
                  <i>Total</i>
                </b>{" "}
                : Total tabungan saya hari ini{" "}
                <u>
                  <b>
                    <i>503.000</i>
                  </b>
                </u>
                )
                <br />
                Contoh input data{" "}
                <b>
                  <i>Increment</i>
                </b>{" "}
                : Tabungan bertambah{" "}
                <u>
                  <b>
                    <i>40.000</i>
                  </b>
                </u>
                )
              </div>
            }
            placement="top">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Input Method : </b>
            </Typography>
          </LightTooltip>
          <List>
            {inputMethodList.length > 0 &&
              inputMethodList.map((item, i) => (
                <Fragment key={i}>
                  <Radio
                    checked={selectedValueInputMethod.code === item.code}
                    onChange={(e) =>
                      handleChangeRadioButtonInputMethod(e, item)
                    }
                    value={item.id}
                    name={item.name}
                  />
                  <span style={{ fontFamily: "Roboto", color: "grey" }}>
                    {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                  </span>
                </Fragment>
              ))}
          </List>

          <br />

          <LightTooltip
            title={
              <div>
                Pilihan More: Semakin besar nilai, semakin baik. <br />
                Pilihan Less: Semakin kecil nilai, semakin baik
              </div>
            }
            placement="top">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Achievement Type :</b>
            </Typography>
          </LightTooltip>

          <List>
            {inputAchievementTypeList.length > 0 &&
              inputAchievementTypeList.map((item, i) => (
                <Fragment key={i}>
                  <Radio
                    checked={selectedValueAchievementType.id === item.id}
                    onChange={(e) =>
                      handleChangeRadioButtonAchievementType(e, item)
                    }
                    value={item.id}
                    name={item.name}
                  />
                  <span style={{ fontFamily: "Roboto", color: "grey" }}>
                    {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                  </span>
                </Fragment>
              ))}
          </List>
          <br />
          <LightTooltip
            title={
              <div>
                Pilihan <i>Numeric</i>: Data input berupa angka aktual. <br />
                Pilihan <i>Percentage</i>: Data input berupa persen
              </div>
            }
            placement="top">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Value Type : </b>
            </Typography>
          </LightTooltip>

          <List>
            {inputValueTypeList.length > 0 &&
              inputValueTypeList.map((item, i) => (
                <Fragment key={i}>
                  <Radio
                    checked={selectedValueType.id === item.id}
                    onChange={(e) => handleChangeRadioButtonValueType(e, item)}
                    value={item.id}
                    name={item.name}
                  />
                  <span style={{ fontFamily: "Roboto", color: "grey" }}>
                    {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                  </span>
                </Fragment>
                // </ListItem>
              ))}
          </List>

          <br />
          <LightTooltip
            title={
              <div>
                SUM: Penjumlahan dari semua Goal Result. <br />
                AVG: Rata-rata dari semua Goal Result <br />
                LAST VALUE: Hanya untuk "Input Method - Total"
              </div>
            }
            placement="top">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Calculation Method :</b>
            </Typography>
          </LightTooltip>

          <List>
            {calculationMethodList.length > 0 &&
              calculationMethodList.map((item, i) => {
                return (
                  <Fragment key={i}>
                    <Radio
                      checked={selectedValueCalcMethod.code === item.code}
                      onChange={(e) => handleChangeRadioButton(e, item)}
                      value={item.id}
                      name={item.name}
                    />
                    <span style={{ fontFamily: "Roboto", color: "grey" }}>
                      {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                    </span>
                  </Fragment>
                );
              })}
          </List>

          <br />
          <LightTooltip
            title={
              <div>
                Daily: Perhitungan yang digunakan adalah target di hari yang
                sedang berjalan <br />
                Final: Perhitungan yang digunakan adalah target di akhir periode
              </div>
            }
            placement="top">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Target Type :</b>
            </Typography>
          </LightTooltip>

          <List>
            {targetTypeList.length > 0 &&
              targetTypeList.map((item, i) => {
                return (
                  <Fragment key={i}>
                    <Radio
                      checked={targetType.code === item.code}
                      onChange={(e) => handleSelectTargetType(e, item)}
                      value={item.id}
                      name={item.name}
                    />
                    <span style={{ fontFamily: "Roboto", color: "grey" }}>
                      {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                    </span>
                  </Fragment>
                );
              })}
          </List>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          {
            // ownerList.length == 0 ||
            // textValuePosition == '' ||
            // selectedDueDate == '' ||
            // startDateRangeState == null ||
            // endDateRangeState == null
            bigGoal == "" ||
            targetValue == "" ||
            selectedValueCalcMethod == "" ? (
              <Button
                // onClick={handleSUBMITGoal}
                variant="contained"
                className={classes.buttonDisabled}
                fullWidth
                disabled>
                Buat Goal !
              </Button>
            ) : (
              <Button
                onClick={handleSUBMITGoal}
                variant="contained"
                className={classes.button}
                fullWidth
                disabled={disabledButton == true ? true : false}>
                {loader == true ? (
                  <CircularProgress size={20} style={{ color: "#d1354a" }} />
                ) : (
                  "  Buat Goal !"
                )}
              </Button>
            )
          }
        </DialogActions>
        <br />

        <DialogError
          classes={classes}
          isOpenDialogError={isOpenDialogError}
          setOpenDialogError={setOpenDialogError}
          textErrorInformation={textErrorInformationValue}
          errorStatus={errorStatus}
          listError={listError}
        />
      </Dialog>

      <Snackbeer
        // classes = { classes }
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages="Berhasil membuat Goal :)"
      />

      <DialogCreateModeWizard
        classes={classes}
        isModalCreateModeWizard={isModalCreateModeWizard}
        setModalCreateModeWizard={setModalCreateModeWizard}
      />
    </Fragment>
  );
};

export default DialogCreate;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },

  typography: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  textfield: {
    width: 200,
  },
});

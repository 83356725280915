import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Modal from "react-responsive-modal";

import {
  Toolbar,
  Typography,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";

import LightTooltip from "../../../../components/LightTooltip";

import Redirect from "../../../../utilities/Redirect";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    //   paddingRight: theme.spacing.unit,
    // paddingRight: 3,
  },
  highlight:
    //   theme.palette.type === 'light'
    //     ? {
    //         color: theme.palette.secondary.main,
    //         backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    //       }
    //     : {
    //         color: theme.palette.text.primary,
    //         backgroundColor: theme.palette.secondary.dark,
    //       },
    {
      color: "grey",
      // backgroundColor: '#c1322d'
      // background: 'linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)'
      backround: "white",
    },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    //   color: theme.palette.text.secondary,
    color: "grey",
  },
  title: {
    flex: "0 0 auto",
    marginRight: 57,
    fontFamily: "Roboto",
  },
  titleSecond: {
    fontFamily: "Roboto",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, idEmployee } = props;

  const handleDeleteMasterRole = () => {
    setLoading(true);

    const userToken = localStorage.getItem("userToken");

    if (userToken !== undefined) {
      console.log("Delete : ", idEmployee);

      idEmployee.length > 0 &&
        idEmployee.map((item, i) => {
          // console.log(item)

          const header = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "bearer " + userToken,
          };

          axios.defaults.headers.common = header;

          axios
            .delete(URL_API + `/human-resource/employee/${item.id}`)
            .then(function (response) {
              console.log("Response Original : ", response);
              localStorage.removeItem("employee_id");
              window.location.reload();
            })
            .catch(function (error) {
              setLoading(false);
              console.log("Error : ", error.response);

              if (error.response !== undefined) {
                if (error.response.status === 429) {
                  setErrorStatus(429);
                  setTextErrorInformation("Too Many Request !");
                  setOpenDialogError(true);
                }
                if (error.response.status === 422) {
                  setErrorStatus(422);
                  setTextErrorInformation(".");
                  setListError(error.response.data.data);
                  setOpenDialogError(true);
                }
              } else {
                setOpenDialogError(true);
              }

              // const newErrorData = { ...error.response.data};
              // console.log("newErrorData : ", newErrorData);

              // if(newErrorData.info.message !== null){
              //   if(newErrorData.info.status == 422){

              //     alert("Opps, something went wrong ! ",newErrorData.info.message);
              //   }
              // };
            });
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  /*
        ```````````````````
        HANDLE MODAL LOADER

        ```````````````````
    */

  const [isLoading, setLoading] = useState(false);

  /*
      ```````````````````
      HANDLE DIALOG ERROR

      ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState();
  const [listError, setListError] = useState([]);

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      style={{ minHeight: 0, paddingLeft: 3, backgroundColor: "transparent" }}>
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <LightTooltip
            arrow
            placement="right"
            title={
              <Typography variant="subtitle2" className={classes.titleSecond}>
                <b>Hapus Karyawan</b>
              </Typography>
            }>
            <IconButton
              onClick={() => handleDeleteMasterRole()}
              aria-label="Hapus">
              <DeleteIcon style={{ color: "#c1322d" }} />
            </IconButton>
          </LightTooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              {/* <FilterListIcon /> */}
            </IconButton>
          </Tooltip>
        )}
      </div>

      <div className={classes.spacer} />

      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} Terpilih
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {/* Pengaturan Role */}
          </Typography>
        )}
      </div>

      {/*  

            ````````````
            MODAL LOADER

            ````````````
        
        */}
      <Modal
        open={isLoading}
        onClose={() => setLoading(true)}
        closeIconSize={20}
        showCloseIcon={false}
        center
        styles={{ modal: { background: "transparent", boxShadow: "none" } }}>
        <CircularProgress size={32} style={{ color: "red" }} />
      </Modal>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Toolbar>
  );
};

export default EnhancedTableToolbar;

import React, { useState } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";

import {
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  Menu,
  MenuItem,
  Collapse,
  Button,
} from "@material-ui/core";

import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MailIcon from "@material-ui/icons/Mail";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import PictBackgroundInHeader from "../assets/images/mountain.jpeg";
import MahkotaLogoCompany from "../assets/images/Group-862.png";
import ImageProfilPerusahaan from "../assets/images/SVG/Group_863.svg";
import ImageMembership from "../assets/images/SVG/Group_870.svg";
import ImagePengaturanUser from "../assets/images/SVG/Group_866.svg";
import ImagePengaturanRole from "../assets/images/SVG/Group_1421.svg";
import IconMenuOffCanvass from "../assets/images/Group_1923.png";
import IconMenuOffCanvassGrey from "../assets/images/SVG/Group_709.svg";

import IconArrowLeft from "../assets/images/SVG/Group_1186.svg";
import { lightBlue, grey, red, green } from "@material-ui/core/colors";

import Redirect from "../utilities/Redirect";
import {
  ToMembershipStatus,
  ToDashboard,
  ToCompanyProfile,
  ToRole,
  ToUserManagement,
  ToLogin,
} from "../constants/config-redirect-url";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  overrides: {
    // MuiAppBar:{
    //   root:{
    //     backgroundColor: 'cyan'
    //   }
    // },
    // MuiToolbar:{
    //   root:{
    //     backgroundColor: 'white'
    //   }
    // },
    MuiButton: {
      //   root: {
      //     marginTop: 30,
      //     marginRight: 40,
      //     width: '122.2px',
      //     height: '40px',
      //     borderRadius: 5,
      //     background: 'linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)',
      //     border:0,
      //   },
      text: {
        color: "white",
      },
      textSecondary: {
        color: "white",
        fontFamily: "Roboto",
      },
    },
    MuiDrawer: {
      root: {
        backgroundColor: lightBlue,
      },
      paper: {
        backgroundColor: green,
      },

      paperAnchorLeft: {},
    },
    MuiListItemIcon: {
      root: {
        color: "white",
      },
    },
    MuiBadge: {
      root: {
        color: "#d1354a",
      },
    },
  },
});

const styles = (theme) => ({
  list: {
    width: 250,
  },

  fullList: {
    width: "auto",
  },

  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },

  search: {
    position: "relative",
    borderRadius: 3,
    backgroundColor: grey,
    marginRight: 1 * 2,
    marginLeft: 0,
    width: "100%",
  },
  searchIcon: {
    color: "grey",
  },
  sectionDesktop: {
    display: "flex",
  },
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  accountCircle: {
    color: "white",
  },
  menuIcon: {
    color: "grey",
  },
  mail: {
    color: "white",
  },

  notifIcon: {
    color: "white",
  },
  list: {
    backgroundColor: "red",
  },
  listDrawer: {
    backgroundColor: "red",
  },

  /* DRAWER */
  drawerRoot: {
    width: "100%",
    maxWidth: 360,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "transperent",
    color: "#707070",
  },
  nested: {
    // paddingLeft: theme.spacing.unit * 4,
    paddingLeft: 7,
  },

  icon: {
    // margin: theme.spacing.unit * 2,
    margin: 1 * 2,
  },
  iconHover: {
    // margin: theme.spacing.unit * 2,
    margin: 1 * 2,
    "&:hover": {
      color: red[800],
    },
  },

  imageSvg: {
    width: 27,
    height: 27,
    marginLeft: 8,
  },
  iconMenuOffCanvass: {
    width: 27,
    height: 27,
  },
  buttonIconMenuOffCanvass: {
    padding: 0,
    marginLeft: 127,
    marginBottom: 12,
  },
  iconSmall: {
    fontSize: 20,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  buttonBackDashboard: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: 5,
    fontFamily: "Roboto",
    textTransform: "capitalize",
    color: "grey",
  },
  iconArrowLeft: {
    marginRight: theme.spacing(1),
  },
  buttonMenuBesiArrowLeft: {
    width: "27px",
    height: "27px",
    borderRadius: 2,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    padding: 0,
  },
  nav: {
    color: "#707070",
    fontFamily: "Roboto",
  },
  navActive: {
    color: "#c1272d",
    fontFamily: "Roboto",
  },
  perbaruiSampul: {
    fontFamily: "Roboto",
    color: "white",
    textTransform: "capitalize",
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(9),
  },
});

const HeaderCompanyProfile = (props) => {
  // const classes = useStyles();
  const { classes } = props;

  const [state, setState] = useState({
    top: false,
    // left: true,
    left: false,
    bottom: false,
    right: false,
  });

  const handleCloseOffCanvass = () => {
    setState((state.left = false));
  };

  const toggleDrawer = (side, open) => () => {
    setState({ ...state, [side]: open });
  };

  // const [isLogout, setLogout] = useState(false);

  function handleLogOut() {
    localStorage.removeItem("userToken");
    localStorage.removeItem("intro_enabled");

    // localStorage.clear();

    Redirect(ToLogin);
    // setLogout(true);
  }

  const ITEM_HEIGHT = 24;

  const options = ["Pengaturan", "Log-out"];

  const [anchorElMenuPojokKananAtas, setAnchorElPoKaAtas] = useState(null);
  const isOpenMenuPojokKananAtas = Boolean(anchorElMenuPojokKananAtas);

  function handleMenuPojokKananAtas(event) {
    setAnchorElPoKaAtas(event.currentTarget);
  }

  function handleCloseMenuPojokKananAtas() {
    setAnchorElPoKaAtas(null);
  }

  /*
      ````````````````````````````````
        LIST IN DRAWER

      ````````````````````````````````
    */

  const [openNestedList, setOpenNestedList] = useState(true);

  function handleOpenNestedList() {
    setOpenNestedList(!openNestedList);
  }

  const redirectToDashboard = () => Redirect(ToDashboard);

  /* Redirect to Pengaturan User */
  const [isRedirectToUser, setRedirectToUser] = useState(false);
  const redirectToPengaturanUser = () => setRedirectToUser(true);

  /* Redirect to Pengaturan Role */
  const [isRedirectToRole, setRedirectToRole] = useState(false);
  const redirectToPengaturanRole = () => setRedirectToRole(true);

  /* 
        ``````````````````````````````````````````````
        START | HANDLE ACTIVE - INACTIVE NAV LIST OFF CANVASS
        
        ``````````````````````````````````````````````
    */

  let inisateLabel = {
    label: "",
    // image: ImageMembership,
    // type: 'Keanggotaan'
  };

  // const [typeLabel, setTypeLabel ] = useState();

  const currentLocation = window.location.pathname;
  console.log("currentLocation : ", currentLocation);

  if (currentLocation === ToCompanyProfile) {
    inisateLabel.label = "Profil Perusahaan";
  }

  if (currentLocation === ToRole) {
    inisateLabel.label = "Pengaturan Role";
  }

  if (currentLocation === ToUserManagement) {
    inisateLabel.label = "Pengaturan User";
  }

  if (currentLocation === ToMembershipStatus) {
    inisateLabel.label = "Keanggotaan";
  }

  const [isActiveList, setActiveList] = useState(inisateLabel);

  const handleActiveListItem = (item) => {
    console.log("Nav : ", item);

    setActiveList(item);

    if (item.type === "ProfilPerusahaan") {
      Redirect(ToCompanyProfile);
    }

    if (item.type === "PengaturanUser") {
      Redirect(ToUserManagement);
    }

    if (item.type === "PengaturanRole") {
      Redirect(ToRole);
    }

    if (item.type === "Keanggotaan") {
      Redirect(ToMembershipStatus);
    }
  };

  /* 
        ```````````````````````````````````````````````````
        END | HANDLE ACTIVE - INACTIVE NAV LIST OFF CANVASS
        
        ```````````````````````````````````````````````````
    */
  return (
    <MuiThemeProvider theme={theme}>
      <AppBar
        position="sticky"
        // style={{height: 190}}
        // style={{ background: 'transparent', boxShadow: 'none'}}
        style={{
          // marginLeft:'16px',
          // marginRight: '16px',
          backgroundImage: "url(" + PictBackgroundInHeader + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          height: 190,
        }}>
        <Toolbar>
          <IconButton
            onClick={toggleDrawer("left", true)}
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu">
            <img
              src={IconMenuOffCanvassGrey}
              alt="Icon Menu Grey"
              style={{ width: 28, height: 28 }}
            />
          </IconButton>
          {/* <Typography variant="h6" color="inherit" className={classes.grow}>
                    Abang Bisa
                </Typography> */}
          <Grid container spacing={8} alignItems="flex-end"></Grid>

          <IconButton color="inherit">
            <Badge badgeContent={17} color="primary">
              <NotificationsIcon className={classes.notifIcon} />
            </Badge>
          </IconButton>

          <IconButton color="inherit">
            <MailIcon className={classes.mail} />
          </IconButton>
          <IconButton
            // aria-owns={isMenuOpen ? 'material-appbar' : undefined}
            aria-haspopup="true"
            onClick={handleLogOut}
            // onClick={() => localStorage.removeItem('userToken')}
            // onClick={() => console.log("Test !")}
            color="inherit">
            <AccountCircle className={classes.accountCircle} />
          </IconButton>

          <IconButton
            aria-label="More"
            aria-owns={isOpenMenuPojokKananAtas ? "long-menu" : undefined}
            aria-haspopup="true"
            onClick={handleMenuPojokKananAtas}>
            <ExpandMore color="#fff" />
          </IconButton>

          <Menu
            id="long-menu"
            anchorEl={anchorElMenuPojokKananAtas}
            open={isOpenMenuPojokKananAtas}
            onClose={handleCloseMenuPojokKananAtas}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200,
              },
            }}>
            {options.map((option) => (
              <MenuItem
                key={option}
                selected={option === "Pengaturan"}
                onClick={handleCloseMenuPojokKananAtas}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>

        {/* 
                    ````````````````````````
                    PERBARUI BACKGROUND FOTO

                    ````````````````````````
                */}
        <Grid container>
          <Grid item sm={12} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              size="small"
              className={classes.perbaruiSampul}>
              Perbarui foto sampul
              {/* <LensIcon 
                                style={{ color: '#19dc5a', width: 10, marginLeft: 7}}            
                            /> */}
              <i
                className="material-icons"
                style={{ color: "white", marginLeft: 7 }}>
                camera_alt
              </i>
            </Button>
          </Grid>
        </Grid>
      </AppBar>

      <Drawer open={state.left} onClose={toggleDrawer("left", false)}>
        <div style={{ background: "#fefefe", height: 1000, width: 303 }}>
          <Button
            onClick={redirectToDashboard}
            variant="outlined"
            size="medium"
            className={classes.buttonBackDashboard}>
            <img
              src={IconArrowLeft}
              alt="icon-left-arrow"
              className={classes.iconArrowLeft}
            />
            <b>Dashboard</b>
          </Button>
          <IconButton
            color="inherit"
            aria-label="Menu"
            className={classes.buttonIconMenuOffCanvass}
            // style={{padding: 0, marginLeft: 127, marginBottom: 12 }}
          >
            <img
              onClick={handleCloseOffCanvass}
              src={IconMenuOffCanvass}
              className={classes.iconMenuOffCanvass}
            />
          </IconButton>
          <List component="nav" className={classes.drawerRoot}>
            <ListItem button onClick={handleOpenNestedList}>
              <ListItemIcon>
                <img
                  src={MahkotaLogoCompany}
                  style={{ marginRight: 0, paddingRight: 0 }}
                />
              </ListItemIcon>

              <ListItemText
                secondary={
                  <Typography
                    type="Madinah"
                    style={{ color: "#707070", fontFamily: "Roboto" }}>
                    Indonesia
                  </Typography>
                }
                primary={
                  <Typography
                    type="Madinah"
                    style={{ color: "#707070", fontFamily: "Roboto" }}>
                    PT. Madinah
                  </Typography>
                }
              />

              {openNestedList ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={openNestedList} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {navs.length > 0 &&
                  navs.map((item, i) => (
                    <ListItem button className={classes.nested} key={i}>
                      <ListItemIcon>
                        <img src={item.image} className={classes.imageSvg} />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() => handleActiveListItem(item)}
                        primary={
                          <Typography
                            type={item.type}
                            className={
                              isActiveList.label == item.label
                                ? classes.navActive
                                : classes.nav
                            }>
                            {item.label}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
              </List>
            </Collapse>
          </List>
        </div>
      </Drawer>
    </MuiThemeProvider>
  );
};

// export default HeadCompany;
export default withStyles(styles)(HeaderCompanyProfile);

let navs = [
  {
    label: "Profil Perusahaan",
    image: ImageProfilPerusahaan,
    type: "ProfilPerusahaan",
  },
  {
    label: "Keanggotaan",
    image: ImageMembership,
    type: "Keanggotaan",
  },
  {
    label: "Pengaturan User",
    image: ImagePengaturanUser,
    type: "PengaturanUser",
  },
  {
    label: "Pengaturan Role",
    image: ImagePengaturanRole,
    type: "PengaturanRole",
  },
];

import { GET_FIELD_PRESET, GET_DATA_TABLE, SET_PAGINATING } from "../types";

// ~ Initial State for Table ~
export const initialTableSmr = {
  loading: false,
  fieldPreset: [],
  dataTable: [],
  paginating: {
    currentPage: 1,
    perPage: 10,
    lastPage: 0,
    totalRecords: 0,
  },
};

// =======================================================================
// ~ REDUCER | Managing GLobal State for Table ~
export const tableSmrReducer = (state, { type, payload }) => {
  switch (type) {
    case GET_FIELD_PRESET:
      return {
        ...state,
        fieldPreset: payload.fieldPreset,
      };
    case GET_DATA_TABLE:
      return {
        ...state,
        dataTable: payload.dataTable,
        loading: payload.loading,
      };
    case SET_PAGINATING:
      return { ...state, paginating: payload.paginating };
    default:
      return state;
  }
};

import React, { useState, Fragment } from "react";

import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import "moment/locale/id";

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const ComponentDueDate = (props) => {
  const {
    selectedDueDate,
    setSelectedDueDate,
    isShowDueDateComponentDirectly,
    setShowDueDateComponentDirectly,
  } = props;

  const [locale, setLocale] = useState("id");

  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
      <ThemeProvider theme={theme}>
        <Fragment>
          <DatePicker
            value={selectedDueDate}
            onChange={setSelectedDueDate}
            animateYearScrolling
            open={isShowDueDateComponentDirectly}
            onOpen={() => setShowDueDateComponentDirectly(true)}
            onClose={() => setShowDueDateComponentDirectly(false)}
            variant="dialog" // dialog, static, inline
            disableToolbar={false}
            format="DD MMMM YYYY"
            label=""
            style={{ marginLeft: 16 }}
            // orientation="landscape"
            // TextFieldComponent =
            // ToolbarComponent
          />
        </Fragment>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default ComponentDueDate;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },

  typography: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  textfield: {
    width: 200,
  },
});

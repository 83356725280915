import React, { Component } from 'react';
import { makeStyles, createMuiTheme, withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { 
    Container, Paper, Typography, Grid, TextField, Checkbox, CircularProgress,
    FormControlLabel, Box, Button, MenuItem, Dialog, DialogTitle, DialogContent, 
    DialogContentText, DialogActions, Breadcrumbs, Link
} from '@material-ui/core';

import { navigate } from 'hookrouter';

import logoPerformate from '../../../assets/images/performate-03.png';

import logoMadinahCare from '../../../assets/images/landing/logo-madinah-care.PNG';
import logoMadinahFlash from '../../../assets/images/landing/logo-madinah-flash.PNG'; 
import logoMadinahGroup from '../../../assets/images/landing/logo-madinah-group.PNG'; 
import logoMadinahMultimedia from '../../../assets/images/landing/logo-madinah-multimedia.PNG'; 
import logoMadinahStore from '../../../assets/images/landing/logo-madinah-store.PNG'; 

import img1 from '../../../assets/images/landing/goal-mgmt1.PNG';
import img2 from '../../../assets/images/landing/goal-mgmt2.PNG';
import img3 from '../../../assets/images/landing/goal-mgmt3.PNG';
import img4 from '../../../assets/images/landing/goal-mgmt4.PNG';
import img5 from '../../../assets/images/landing/goal-mgmt5.PNG';
import img6 from '../../../assets/images/landing/goal-mgmt6.PNG';
import img7 from '../../../assets/images/landing/goal-mgmt7.PNG';

const styles = theme => ({
    root: {     
        width: '100%',
    },
	imageLogo : {
		verticalAlign : 'text-bottom',
		width : 35,
		height : 35
	},
	alternateBackground : {
		backgroundColor : '#f5f5f5'
	},
	centerInDiv : {
		textAlign : 'center',
		height : '100%',
		display : 'table',
	},
	centerInDivCell : {
		display : 'table-cell',
		verticalAlign : 'middle'
	},
	bottomBackground : {
		backgroundColor : '#d1364a',
		color : '#ffffff'
	},
	ClientAndPartner : {
		width : '80%',
		display : 'grid',
		gridTemplateColumns: 'repeat(5, 1fr)',
		padding : 0,
		margin : '0 auto',
	},
	paddingSection : {
		paddingTop : '60px',
		paddingBottom : '60px',
	},
	gridDescription : {
		display : 'grid',
		padding : 0,
		margin : '0 auto',
		gridTemplateColumns: '50% 50%',
		width : '80%'
	}
});

const useStyles = makeStyles(styles)

const GoalManagement = props => {
	//const { classes } = props;
	const classes = useStyles();
	
	return ( 
		<div>
			
			<section style={{paddingTop : '0px', textAlign : 'center'}}>
				<div className="box-price">
					<div className="box-price-left-side" style={{width:'70%'}}>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<div style={{display: 'flex', alignItems: 'center'}}>
							<img src={img2} width="30px"/> <span>Goal Management</span>
						</div>
						<h2>Tingkatkan performa organisasi anda dengan membuat goal</h2>	
						<p>Fokuskan energi tim anda pada sebuah tantangan, sehingga mereka mampu mencapai banyak hal.</p>
						<Button 
							onClick={() => navigate('/register')}
							variant="contained" color="primary" disableElevation className={classes.textLowerCase} style={{width: '150px', margin : '0 auto'}}>Coba Gratis</Button>
					</div>
					
					<div>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<div>
							<img src={img1} style={{width : '90%'}}/>
						</div>
					</div>
					
				</div>
				<br/><br/><br/>
				<div style={{textAlign : 'center'}}>
					<h3 className="text-client-title">Your mate in performance monitoring</h3>
					<p>Dipercaya banyak perusahaan, UKM, dan organisasi untuk mencapai goal-goal mereka</p>
					<div className={classes.ClientAndPartner}>
						<div>
							<img src={logoMadinahGroup} width="50%" />
						</div>
						
						<div>
							<img src={logoMadinahStore} width="50%" />
						</div>
						
						<div>
							<img src={logoMadinahFlash} width="100%" />
						</div>
						
						<div>
							<img src={logoMadinahCare} width="70%" />
						</div>
						
						<div>
							<img src={logoMadinahMultimedia} width="80%" />
						</div>
					</div>
					<br/><br/>
				</div>
			
			</section>
			
			<section className={[classes.alternateBackground, classes.paddingSection].join(' ')}>
				<div className={classes.gridDescription}>
					<div>
						<h3>Sinar matahari yang terpancar, terlalu lemah untuk menyalakan api, tetapi begitu Anda memfokuskan sinar tersebut dengan kaca pembesar, sinarnya dapat membakar kertas dalam beberapa detik.</h3>
						<p>Performate membantu anda untuk fokus mencapai tujuan anda, membuat waktu yang anda miliki lebih produktif, dan mengukur keberhasilan tim anda dalam mengejar dan mengukur keberhasilan tim anda dalam mengejar pencapaian mereka</p>
					</div>
					<div className={classes.centerInDiv}>
						<div className={classes.centerInDivCell}>
							<img src={img3} style={{width:'50%'}} />
						</div>
					</div>
				</div>
			</section> 
			
			<section className={classes.paddingSection}>
				<div className={classes.gridDescription}>
					<div className={classes.centerInDiv}>
						<div className={classes.centerInDivCell}>
							<img src={img4} style={{width:'80%'}} />
						</div>
					</div>
					<div>
						<h3>Ketahui performa anda dalam 5 detik</h3>
						<p>Fitur dashboard yang di rancang dengan indikator kode warna standar untuk memudahkan anda melihat pencapaian terbaru goal anda dan tim anda</p>
					</div>					
				</div>
			</section>
			
			<section className={[classes.alternateBackground, classes.paddingSection].join(' ')}>
				<div className={classes.gridDescription}>
					<div>
						<h3>Menjaga konsistensi pencapaian harian.</h3>
						<p>Performate mampu membantu anda untuk menganalisis pencapaian anda dengan laporan yang detail dan transparan. Sehingga mempercepat anda mengambil keputusan untuk mengubah cara bermain tim anda dalam menjaga performa dan mendapatkan pencapaian </p>
					</div>
					<div className={classes.centerInDiv}>
						<div className={classes.centerInDivCell}>
							<img src={img5} style={{width:'80%'}} />
						</div>
					</div>
				</div>
			</section>
			
			<section className={classes.paddingSection}>
				<div className={classes.gridDescription}>
					<div className={classes.centerInDiv}>
						<div className={classes.centerInDivCell}>
							<img src={img6} style={{width:'80%'}} />
						</div>
					</div>
					<div>
						<h3>Delegasikan goal dengan tim anda </h3>
						<p>Bagi seorang leader yang banyak mendelegasikan goal nya ke anak buahnya, performate membuat laporan pencapaian seorang leader lebih mudah dengan mengakumulasi secara otomatis pencapaian semua anak buahnya kepada pencapaian goal leader tersebut 
							<br/><br/>Dengan fitur ini, maka akan mudah bagi seorang manajer memonitor dan menganalisa kinerja dari anak buahnya. 
						</p>
					</div>	
				</div>
			</section>
			
			<section className={[classes.alternateBackground, classes.paddingSection].join(' ')}>
				<div className={classes.gridDescription}>
					<div>
						<h3>Ketahui aktivitas tim anda dengan bukti yang valid</h3>
						<p>Dengan Performate, seorang leader dapat dengan mudah memastikan timnya melakukan aktifitasnya dengan menyertakan bukti upload saat memperbarui resultnya </p>
					</div>
					<div className={classes.centerInDiv}>
						<div className={classes.centerInDivCell}>
							<img src={img7} style={{width:'80%'}} />
						</div>
					</div>
				</div>
			</section>
			
			<section className={classes.paddingSection}>
				<div className="box-tour" style={{textAlign : 'center', margin : '0 auto '}}>
					<h3>Ikuti Tur</h3>
					<p>
						Ingin mempelajari lebih banyak? Atur jadwal demo bersama kami untuk memandu dan menjawab pertanyaan anda dengan menggunakan platform secara langsung
					</p>
					<Button 
						onClick={() => navigate('/home/demo-schedule')}
					variant="outlined" color="primary" disableElevation className={classes.textLowerCase}>Atur demo</Button>
			
				</div>
			</section>
			
			
		</div>
	);
	
}

export default GoalManagement;
import {
  GET_SUMMARY_CONFIG,
  GET_AVG_COMPANY,
  SET_DATE_RANGE,
  HIT_PRIMARY_FIELDS,
  HIT_GROUP_BY,
  HIT_CACL_METHODS,
  HIT_ORDERBY,
  HIT_ENTITY,
  HIT_USERS,
  HIT_UNITS,
  HIT_UNIT,
  HIT_ACHIEVEMENTS,
  HIT_STATUSES,
  HIT_ACHIEVEMENTS_MA,
} from "../types";
import moment from "moment";

// ~ Initial State for Summary ~
export const initialSummary = {
  // global state
  dateRange: {
    title: "Last 7 Days",
    code: "last_seven_days",
    startDate: moment()
      .subtract(7, "days")
      .startOf("days")
      .format("DD MMM YYYY"),
    endDate: moment().subtract(1, "days").endOf("days").format("DD MMM YYYY"),
  },
  // Collection State
  averages: [],
  primaryFields: [],
  groupBy: [],
  calcMethods: [],
  orderBy: [],
  modes: [],
  entities: [],
  filters: [],
  users: [],
  units: [],
  achievements: [],
  status: [],

  // Selected State
  selectedFields: {
    code: "overall_result_achievement",
    name: "Achievement",
  },
  selectedType: {
    name: "Result Achievement",
    code: "ora",
    fields: ["overall_result_achievement"],
  },
  selectedGroup: {
    field: "full_name",
    name: "User Name",
  },
  selectedCalc: { code: "avg", name: "Average", selected: false },
  selectedOrder: {
    field: "full_name",
    method: "ASC",
    name: "User Name",
  },
  selectedEntities: ["goal", "ma"],
  selectedUsers: [],
  selectedUnits: [],
  selectedUnit: {
    created_at: "",
    created_by: "",
    deleted_at: null,
    deleted_by: null,
    id: "",
    name: "",
    selected: false,
    self: { rel: null },
    updated_at: "",
    updated_by: "",
  },
  selectedAchievements: [
    "_value_ >= 100",
    "_value_ >= 85 && _value_ <= 99",
    "_value_ < 85",
  ],
  selectedAchievementsMa: [
    "_value_ >= 100",
    "_value_ >= 85 && _value_ <= 99",
    "_value_ < 85",
  ],
  selectedStatus: [3],
};

// =======================================================================
// ~ REDUCER | Managing GLobal State for Filtering ~
export const summaryReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_DATE_RANGE:
      return {
        ...state,
        dateRange: payload.dateRange,
      };
    case GET_SUMMARY_CONFIG:
      return {
        ...state,
        primaryFields: payload.primaryFields,
        groupBy: payload.groupBy,
        calcMethods: payload.calcMethods,
        orderBy: payload.orderBy,
        entities: payload.entities,
        users: payload.users,
        achievements: payload.achievements,
        status: payload.status,
        units: payload.units,
        // modes: payload.modes,
        // filters: payload.filters,
      };
    case GET_AVG_COMPANY: {
      return { ...state, averages: payload.averages };
    }
    case HIT_PRIMARY_FIELDS:
      return {
        ...state,
        selectedFields: payload.selectedFields,
        selectedType: payload.selectedType,
      };
    case HIT_GROUP_BY:
      return {
        ...state,
        selectedGroup: payload.selectedGroup,
      };
    case HIT_CACL_METHODS:
      return {
        ...state,
        selectedCalc: payload.selectedCalc,
      };
    case HIT_ORDERBY:
      return {
        ...state,
        selectedOrder: payload.selectedOrder,
      };
    case HIT_ENTITY:
      return {
        ...state,
        selectedEntities: payload.selectedEntities,
      };
    case HIT_USERS:
      return {
        ...state,
        selectedUsers: payload.selectedUsers,
      };
    case HIT_UNITS:
      return {
        ...state,
        selectedUnits: payload.selectedUnits,
      };
    case HIT_UNIT:
      return {
        ...state,
        selectedUnit: payload.selectedUnit,
      };
    case HIT_ACHIEVEMENTS:
      return {
        ...state,
        selectedAchievements: payload.selectedAchievements,
      };
    case HIT_ACHIEVEMENTS_MA:
      return {
        ...state,
        selectedAchievementsMa: payload.selectedAchievementsMa,
      };
    case HIT_STATUSES:
      return {
        ...state,
        selectedStatus: payload.selectedStatus,
      };
    default:
      return state;
  }
};

export const styleSpecial = (theme) => ({
  /*
        ``````````
        TYPOGRAPHY

        ``````````
    */
  title: {
    fontFamily: "Roboto",
  },

  titleListChoose: {
    fontFamily: "Roboto",
    cursor: "pointer",
    paddingBottom: theme.spacing(2),
  },
  titleListChooseAktif: {
    fontFamily: "Roboto",
    color: "#d6d3d3",
    cursor: "pointer",
    paddingBottom: theme.spacing(2),
  },
  /*
        ```````````````````````
        LAYOUT MARGIN & PADDING

        ```````````````````````
    */
  layoutMarginLeftAndTop: {
    marginLeft: theme.spacing(9),
    marginTop: theme.spacing(2),
  },
  fixedLayout: {
    // width: "100%",
    // maxWidth: 2192,
    marginLeft: "auto",
    marginRight: "auto",
  },
  layoutMarginTop: {
    // marginLeft: 72,
    marginTop: 56,
  },
  layoutMarginTopNew: {
    marginTop: theme.spacing(10),
  },
  layoutMarginLeftList: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
  },

  /*
        ````````````````````````````
        PAPER, CIRCULAR PROGRESS BAR

        ````````````````````````````        
    */
  paperList: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.2),
  },

  paperListKebakaran: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.2),
    background: "#edcfd8",
    borderBottom: "0.5em solid red",
  },

  circularProgressBar: {
    width: 60,
    height: 60,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0.3),
    marginBottom: theme.spacing(2),
  },

  /*
        `````````````````````````````````
        BUTTON BATAL IN DIALOG DATE RANGE

        `````````````````````````````````   
    */
  dateRangePerbaruiSampul: {
    fontFamily: "Roboto",
    color: "grey",
    textTransform: "capitalize",
  },

  /*
        ``````````````````````````````````````````
        BUTTON MORE DETAIL - IN LIST COLLECTION MA

        ``````````````````````````````````````````        
    */
  moreDetail: {
    fontFamily: "Roboto",
    color: "black", //#4aa9fb
    textTransform: "capitalize",
    fontSize: 11,
    cursor: "pointer",
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 3,
    paddingLeft: 3,
    backgroundColor: "yellow",
  },

  /*
        ````````````````
        TAB IN STATUS MA

        ````````````````
    */
  widthTab: {
    minWidth: 92, // a number of your choice
    width: 92, // a number of your choice
    marginLeft: 24,
    marginRight: 24,
  },
});

import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Checkbox,
  Avatar,
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";

import DialogCreateDirectSubGoal from "../ComponentSubGoal/DialogCreateDirectSubGoal";

import ContextGoalDetail from "../Context/ContextGoalDetail";
import { styles } from "../Style/StyleSubGoal";

import DialogError from "../../../../components/DialogError";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ImageSmallUser from "../../../../components/ImageSmallUser";

const useStyles = makeStyles(styles);

const DialogPilihUser = (props) => {
  const {
    isModalPilihUser,
    setModalPilihUser,
    handleCloseMenuDirectInDirect,
    setFireGoalDetail,
    collectionGoalResultList,
    goalDetailState,
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const context = useContext(ContextGoalDetail);
  /*
        ```````````````````
        COMPONENT DID MOUNT
        
        ```````````````````
    */

  const [listOwner, setListOwner] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (isModalPilihUser === true) {
      //*
      setSelected([]);

      //*
      handleCloseMenuDirectInDirect();

      //*
      localStorage.removeItem("start_date");
      localStorage.removeItem("end_date");

      axiosConfig
        .get(
          URL_API + `/swd/goal/${goalDetailState.id}/direct-link/batch/create`
        )
        .then(function (response) {
          setLoader(false);
          console.log("Response Original : ", response);

          if (response.data.data !== null && response.data.data !== undefined) {
            setListOwner(response.data.data.ownerCollections);
            context.setPeriodCollections(response.data.data.periodCollections);
          }
        })
        .catch(function (error) {
          setLoader(false);
          console.log("Error : ", error.response);

          if (error.response !== undefined) {
            if (error.response.status === 422) {
              setErrorStatus(422);

              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setListError(error.response.data.info.errors);
                  setOpenDialogError(true);
                  setModalPilihUser(false);
                }
              }
            }
          }
        });
    }
  }, [isModalPilihUser]);

  /*
        ```````````````````````````````
        HANDLE CHOOSE USER IN CHECKBOX

        ```````````````````````````````
    */

  const [selected, setSelected] = useState([]);

  const handleClickCheckbox = (e, item) => {
    console.log("Item checkbox - single choose: ", item);

    const selectedIndex = selected.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    console.log("Item checkbox - munltiple choose:: : ", newSelected);

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  /*
        ````````````````````````````````````````
        HANDLE SIMPAN DATA & MODAL LANJUT DIALOG

        ````````````````````````````````````````
    */

  const handleSimpanDataAndDialogLanjut = () => {
    console.log("selected checbox oke", selected);

    // setModalLanjut(true);
    context.setOpenDialogCreateDirectSubGoal(true);
    setModalPilihUser(false);

    let list = [];

    if (selected.length > 0) {
      selected.map((item, i) => {
        let okeData = {
          member_id: item,
          // role_id: [roleId]
        };

        list.push(okeData);
      });
    }
  };

  /*  
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  return (
    <Fragment>
      <Dialog
        open={isModalPilihUser}
        onClose={() => setModalPilihUser(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          <Grid container>
            <Grid item sm={8}>
              <Typography variant="h6" className={classes.title}>
                <b>
                  Pilih <i>Owner</i>
                </b>
              </Typography>
            </Grid>

            <Grid item sm={4}></Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ textAlign: "left" }}>
          <Grid container>
            <Grid item sm={12}>
              <List className={classes.listRoot}>
                {listOwner.length > 0 ? (
                  listOwner.map((item, i) => {
                    const isItemSelected = isSelected(item.id);

                    return (
                      <ListItem
                        key={i}
                        style={{ paddingLeft: 0, marginRight: 24 }}
                        button
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                        onClick={(e) => handleClickCheckbox(e, item)}>
                        <Checkbox checked={isItemSelected} />

                        <ListItemAvatar>
                          <Avatar>
                            {item.member.photo_url !== null ? (
                              <ImageSmallUser
                                item={item.member.self.rel.photo_url}
                                index={1}
                              />
                            ) : (
                              <PersonIcon />
                            )}
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                          className={classes.listItemtext}
                          primary={
                            <Typography
                              variant="subtitle2"
                              className={classes.title}>
                              <b>
                                {item.member_first_name} {item.member_last_name}
                              </b>
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="subtitle2"
                              className={classes.title}>
                              {item.structure_position_title_name + " "}
                            </Typography>
                          }
                        />
                      </ListItem>
                    );
                  })
                ) : (
                  <Typography variant="subtitle2" className={classes.title}>
                    <b>
                      Belum ada <i>User saat ini</i> !
                    </b>
                  </Typography>
                )}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center " }}>
          <Button
            onClick={handleSimpanDataAndDialogLanjut}
            // onClick={() => setModalLanjut(true)}
            variant="contained"
            className={classes.button}
            fullWidth
            disabled={
              listOwner.length === 0 || selected.length === 0 ? true : false
            }>
            {loader !== true ? (
              "Lanjut"
            ) : (
              <CircularProgress size={20} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogCreateDirectSubGoal
        setModalPilihUser={setModalPilihUser}
        listOwner={listOwner}
        selected={selected}
        setFireGoalDetail={setFireGoalDetail}
        goalDetailState={goalDetailState}
      />

      <DialogError
        classes={classes}
        setOpenDialogError={setOpenDialogError}
        isOpenDialogError={isOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
};

export default DialogPilihUser;

import React, { useEffect, useState, Fragment } from "react";
import { IconButton } from "@material-ui/core";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  AreaChart,
  ResponsiveContainer,
} from "recharts";

import axios from "axios";
import moment from "moment";
import { URL_API } from "../../../../constants/config-api";

import "moment/locale/id";

const numeral = require("numeral");

const DotLineChartMeasureActivityInDetail = (props) => {
  const { classes, isModalCreateMAResult, isModalDelete, successCreateMA } =
    props;

  const userToken = localStorage.getItem("userToken");
  const maDetail = localStorage.getItem("MA_detail");
  const maDetailAfterParse = JSON.parse(maDetail);

  const [dataGoalResult, setDataGoalResult] = useState([]);

  const [collectionGoalResultList, setCollectionGoalResultList] = useState([]);

  useEffect(() => {
    if (isModalCreateMAResult == false || isModalDelete == false) {
      if (userToken !== undefined) {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + userToken,
        };

        axios.defaults.headers.common = header;

        //* GOL SELF - ONLY OWNER GOAL USER LOGIN
        axios
          .get(
            URL_API +
              `/swd/measured-activity/${maDetailAfterParse.id}/result-value-graph`
          )
          .then(function (response) {
            console.log("Response Original Line Chart: ", response);

            if (response.status == 200) {
              if (response.data.data !== null) {
                setCollectionGoalResultList(response.data.data.dataPoints);
              }
            }
          })
          .catch(function (error) {
            console.log("Error : ", error.response);
          });
      } else {
        console.log("No Access Token available!");
      }
    }
  }, [isModalCreateMAResult, isModalDelete, successCreateMA]);

  useEffect(() => {
    let dataGoalResultLet = [];

    if (collectionGoalResultList.length > 0) {
      collectionGoalResultList.map((item, i) => {
        let items = {
          // name: moment(item.result_date).format('DD MMMM YYYY'),
          // Pencapaian: parseInt(numeral(item.result_value).format('0[.]00000'))
          name: moment(item.x).format("DD MMMM YYYY"),
          Pencapaian: parseInt(numeral(item.y).format("0[.]00000")),
        };

        dataGoalResultLet.push(items);
      });

      setDataGoalResult(dataGoalResultLet);
    }
  }, [collectionGoalResultList]);

  return (
    <Fragment>
      {collectionGoalResultList.length > 0 && (
        <ResponsiveContainer width="98%" height={250}>
          <AreaChart
            width={730}
            height={250}
            data={dataGoalResult}
            margin={{ top: 8, right: 0, left: 24, bottom: 8 }}>
            <defs>
              <linearGradient id="colorPencapaian" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#d1354a" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#d1354a" stopOpacity={0} />
              </linearGradient>
            </defs>

            {/* <XAxis dataKey="name" tick={{fontSize: 10}} /> */}

            <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="Pencapaian"
              stroke="#d1354a"
              fillOpacity={1}
              fill="url(#colorPencapaian)"
            />
            {/* <Area type="monotone" dataKey="pv" stroke="#d1354a" fillOpacity={1} fill="url(#Pencapaian)" /> */}
          </AreaChart>
        </ResponsiveContainer>
      )}
    </Fragment>
  );
};

export default DotLineChartMeasureActivityInDetail;

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  // console.log("Payload : ", payload);

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="white"
        transform="rotate(-35)"
        fontSize={10}
        fontFamily="Roboto">
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedDot = (props) => {
  const { cx, cy, stroke, payload, value } = props;

  if (value > 2500) {
    return (
      <IconButton style={{ backgroundColor: "grey", padding: "6px" }}>
        <i className="material-icons" style={{ color: "#d1354a" }}>
          radio_button_checked
        </i>
      </IconButton>
    );
  }

  return (
    <IconButton style={{ backgroundColor: "white", padding: "6px" }}>
      <i className="material-icons" style={{ color: "green" }}>
        radio_button_checked
      </i>
    </IconButton>
  );
};

import React, { useEffect, useState, useRef, useContext } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import axios from "axios";

import ContextNewSO from "../Context/ContextNewSO";

import Capitalize from "../../../utilities/Capitalize";
import DialogError from "../../../components/DialogError";
import Snackbeer from "../../../components/Snackbeer";
import { styles } from "../Style/StyleDialogDetail";
import { URL_API } from "../../../constants/config-api";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const DialogEditEmployeeMemberInEditASSISTANT = (props) => {
  const { classes } = props;

  const context = useContext(ContextNewSO);

  let textInputReff = useRef(null);

  const [loader, setLoader] = useState(false);
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformationValue] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);
  const [listError, setListError] = useState([]);

  const [updatedAt, setUpdatedAt] = useState("");

  useEffect(() => {
    if (context.isOpenDialogEditMemberEmployeeInEditASSISTANT == true) {
      // context.setOpenDialogTambahHead(false);//* KHUSUS DI EDIT, DISABLE-in dulu, soalnya biar FORM edit-nya muncul

      //*Focus in Text Field
      // setTimeout(() => {

      //     textInputReff.current.focus();

      // }, 100);

      //*************************************************************** */
      context.setTreeItems([
        {
          id: null,
          parent_id: null,
          structure_position_title_id: null,
          structure_position_title_name: null,

          structure_unit_id: null,
          structure_unit_name: null,

          structure_unit_type_id: null,
          structure_unit_type_name: null,

          member_first_name: null,
          member_last_name: null,
          self: null,

          classification: {
            id: null,
          },

          child: [],
        },
      ]);

      //*************************************************************** */

      /*
                `````````````````````````````````````````````

                Will be update, still development in BACK-END

                `````````````````````````````````````````````
            */
      if (context.userTokenState !== "") {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + context.userTokenState,
        };

        axios.defaults.headers.common = header;

        axios
          .get(
            URL_API +
              `/account-management/member/quick/${context.dataDetail.id}/update`
          )
          .then((response) => {
            console.log("Response Original : ", response);
            if (response.data.data !== null) {
              if (response.data.data !== null) {
                if (response.data.data.updated_at !== null) {
                  setUpdatedAt(response.data.data.updated_at);
                }
              }
            }
          })
          .catch((error) => {
            console.log("Error : ", error.response);
          });
      }
    }
  }, [context.isOpenDialogEditMemberEmployeeInEditASSISTANT]);

  const handleBatal = () => {
    context.setOpenDialogEditNodeSOInASSISTANT(true);
    context.setOpenDialogEditMemberEmployeeInEditASSISTANT(false);
  };

  // const [ firstName, setFirstName ] = useState(''); //*Moved to Global State
  // const [ lastName, setLastName ] = useState('');
  // const [ email, setEmail ] = useState('');

  const handleSubmit = () => {
    setLoader(true);

    let data = {
      Member: {
        updated_at: updatedAt,
        first_name:
          context.firstName !== ""
            ? context.firstName
            : context.dataDetail.first_name,
        last_name:
          context.lastName !== ""
            ? context.lastName
            : context.dataDetail.last_name,
        email: context.email !== "" ? context.email : context.dataDetail.email,
      },
      _method: "PUT",
    };

    console.log("Data SUBMIT : ", data);

    if (context.userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + context.userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .post(
          URL_API + `/account-management/member/quick/${context.dataDetail.id}`,
          data
        )
        .then(function (response) {
          setLoader(false);
          console.log("Response Original : ", response);

          if (response.status == 200) {
            context.setEmployeeId(response.data.data.id);
            // context.setDataDetail(response.data.data);

            context.setOpenDialogEditNodeSOInASSISTANT(true);
            context.setSuccessEditMemberEmployee(true);
            context.setOpenDialogEditMemberEmployeeInEditASSISTANT(false);

            setModalResponse200(true);
            setMessages("Berhasil menyimpan perubahan baru :)");
          }
        })
        .catch(function (error) {
          setLoader(false);

          if (error.response !== undefined) {
            if (error.response.status == 400) {
              setErrorStatus(400);

              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (
                  error.response.data.info.errors.Employee.hasOwnProperty(
                    "email"
                  )
                ) {
                  setTextErrorInformationValue(
                    "Error 400 : " +
                      Capitalize(error.response.data.info.errors.Employee.email)
                  );
                  setOpenDialogError(true);
                } else {
                  setTextErrorInformationValue("Error 400 ");
                  setOpenDialogError(true);
                }
              }
            }

            if (error.response.status == 500) {
              setErrorStatus(500);
              if (error.response.data.hasOwnProperty("message")) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  "Error 500 : " + Capitalize(error.response.data.message)
                );
              }
            }
          } else {
            context.setFirstName("");
            context.setLastName("");

            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops something went wrong !");

            // context.setOpenDialogTambahHead(true);
          }

          console.log("Error : ", error.response);
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */
  const [isModalResponse200, setModalResponse200] = useState(false);
  const [messages, setMessages] = useState("");

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        open={context.isOpenDialogEditMemberEmployeeInEditASSISTANT}
        // onClose={() => setOpenDialogEditMemberEmployeeInEditASSISTANT(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}>
            <b>Ubah Karyawan baru</b>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            inputRef={textInputReff}
            id="outlined-bare"
            className={classes.textField}
            onChange={(e) => context.setFirstName(e.target.value)}
            // color='primary'
            // onKeyDown={handleEnterPress}
            // value= {bigGoal}
            placeholder={context.dataDetail.first_name}
            // value = {goalDetailState.name}
            variant="outlined"
            fullWidth
          />

          <br />
          <br />
          <TextField
            id="outlined-bare"
            className={classes.textField}
            onChange={(e) => context.setLastName(e.target.value)}
            placeholder={context.dataDetail.last_name}
            // onChange= {handleChangeBIGGoal}
            // color='primary'
            // onKeyDown={handleEnterPress}
            // value= {bigGoal}

            // value = {goalDetailState.name}
            variant="outlined"
            fullWidth
          />

          <br />
          <br />
          <TextField
            id="outlined-bare"
            className={classes.textField}
            onChange={(e) => context.setEmail(e.target.value)}
            placeholder={context.dataDetail.email}
            variant="outlined"
            // onChange= {handleChangeBIGGoal}
            // color='primary'
            // onKeyDown={handleEnterPress}
            // value= {bigGoal}
            // value = {goalDetailState.name}
          />
        </DialogContent>
        <DialogActions
          style={{ alignItems: "right", justifyContent: "flex-end" }}>
          <Box marginRight={theme.spacing(0.5)}>
            <Button
              // onClick={() => setOpenDialogEditMemberEmployeeInEditASSISTANT(false)}
              onClick={handleBatal}
              variant="outlined"
              size="small"
              className={classes.buttonOutlined}>
              Batal
            </Button>

            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}>
              {loader !== true ? (
                " Simpan perubahan "
              ) : (
                <CircularProgress size={20} style={{ color: "white" }} />
              )}
            </Button>
          </Box>
        </DialogActions>
        <br />
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />

      <Snackbeer
        classes={classes}
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages={messages}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(DialogEditEmployeeMemberInEditASSISTANT);

import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  Fragment,
} from "react";
import { createMuiTheme } from "@material-ui/core/styles";

import {
  Typography,
  Grid,
  TextField,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputAdornment,
  ThemeProvider,
  Chip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import axiosConfig from "../../../constants/config-axios";

import MomentUtils from "@date-io/moment";
import moment from "moment";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import EventAvailableIcon from "@material-ui/icons/EventAvailable";

import { URL_API } from "../../../constants/config-api";

import ContextTask from "../Context/ContextTask";

import "moment/locale/id";

const numeral = require("numeral");

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const DialogEditTDL = (props) => {
  const {
    classes,
    isOpenDialogEditTdl,
    setOpenDialogEditTdl,
    handleCloseDropdownEditDelete,
  } = props;

  const context = useContext(ContextTask);

  /*
        ``````````````

        NAME OF TASK,
        
        DUE DATE,

        TARGET RESULT,

        OWNER ID,

        OWNER NAME

        ``````````````
    */

  const [locale, setLocale] = useState("id");

  const [taskId, setTaskId] = useState("");
  const [valueTitle, setValueTitle] = useState(""); //*Name title of Task

  const [selectedDueDate, setSelectedDueDate] = useState(null);

  const [valueTarget, setValueTarget] = useState("");

  const [ownerId, setOwnerId] = useState("");
  const [ownerName, setOwnerName] = useState("");

  const [actualResult, setActualResult] = useState(0);
  const [isStatusComplete, setStatusComplete] = useState("1");

  /*
        ````````
        USE REFF

        ````````
    */
  let textInputReff = useRef(null);

  const [listOwner, setListOwner] = useState([]);
  const [updatedAt, setUpdatedAt] = useState("");
  const [createdBy, setCreatedBy] = useState("");

  useEffect(() => {
    if (isOpenDialogEditTdl == true) {
      //*Focus in Text Field
      setTimeout(() => {
        textInputReff.current.focus();
      }, 100);

      //*GET LABEL UPDATE FORM
      //    /todolist/{{id}}/update

      if (context.userTokenState !== undefined) {
        axiosConfig
          .get(URL_API + `/todolist/${context.dataDetailHoriz.id}/update`)
          .then(function (response) {
            console.log("Response Original : ", response);

            if (response.status === 200) {
              setListOwner(
                response.data.data.ownerCollections.map((item) => {
                  return {
                    id: item.id,
                    email: item.email,
                    first_name: item.member.first_name,
                    last_name: item.member.last_name,
                    structure_position_title_name:
                      item.structurePosition === null
                        ? ""
                        : item.structurePosition.structure_position_title_name,
                    structure_unit_type_name:
                      item.structurePosition === null
                        ? ""
                        : item.structurePosition.structure_unit_type_name,
                    structure_unit_name:
                      item.structurePosition === null
                        ? ""
                        : item.structurePosition.structure_unit_name,
                  };
                })
              );
            }
          })
          .catch(function (error) {
            console.log("Error : ", error.response);
          });
      } else {
        console.log("No Access Token available!");
      }

      //************************************* */
      //    console.log("context.dataDetailHoriz: ", context.dataDetailHoriz);

      setValueTitle(context.dataDetailHoriz.name);
      setOwnerName(
        context.dataDetailHoriz.owner.member.first_name !== ""
          ? context.dataDetailHoriz.owner.member.first_name +
              " " +
              context.dataDetailHoriz.owner.member.last_name
          : ""
      );

      setValueTarget(
        context.dataDetailHoriz.target_result !== ""
          ? context.dataDetailHoriz.target_result
          : ""
      );

      setSelectedDueDate(
        context.dataDetailHoriz.end_timestamp !== null
          ? context.dataDetailHoriz.end_timestamp
          : null
      );
      setActualResult(
        context.dataDetailHoriz.actual_result !== null
          ? context.dataDetailHoriz.actual_result
          : null
      );
      setStatusComplete(context.dataDetailHoriz.status_id);
      setOwnerId(context.dataDetailHoriz.owner_id);
      setTaskId(context.dataDetailHoriz.id);

      setCreatedBy(
        context.dataDetailHoriz.createdBy !== null
          ? context.dataDetailHoriz.createdBy.member.first_name +
              " " +
              context.dataDetailHoriz.createdBy.member.last_name
          : " - "
      );
    }
  }, [isOpenDialogEditTdl]);

  /*
    ````````````
    HANDLE OWNER

    ````````````
  */
  const [isTerpilihOwner, setTerpilihOwner] = useState(false);

  const handleChooseOwner = (e, data) => {
    e.preventDefault();

    console.log("Owner terpilih : ", data);

    if (data !== null) {
      setTerpilihOwner(true);

      setOwnerId(data.id);

      if (data.member !== undefined) {
        setOwnerName(data.member.first_name + " " + data.member.last_name);
      }
    }
  };

  /*
      ```````````````````
      DROPDOWN LIST OWNER

      ```````````````````
    */

  /*
        ```````````````````````````````````
        HANDLE SUBMIT EDIT TASK/ TO DO LIST

        ```````````````````````````````````
    */
  const [loader, setLoader] = useState(false);
  const handleSubmitSimpanPerubahan = () => {
    setLoader(true);

    let readyData = {
      Todolist: {
        updated_at: updatedAt,
        name: valueTitle,
        target_result: numeral(valueTarget).format("0,0"),
        actual_result: numeral(actualResult).format("0,0"),
        end_timestamp: moment(selectedDueDate).format("YYYY-MM-DD 00:00:00"),
        // description: '',
        // note: '',
        status_id: isStatusComplete,
        owner_id: ownerId,
      },
    };

    console.log("Data simpan perubahan : ", readyData);

    if (context.userTokenState !== undefined) {
      axiosConfig
        .put(URL_API + `/todolist/${taskId}`, readyData)
        .then(function (response) {
          console.log("Response Original : ", response);
          setLoader(false);

          if (response.status == 200) {
            context.setSuccessEditTDL(true);

            handleCloseDropdownEditDelete();
            setOpenDialogEditTdl(false);

            context.setSnackbarResponse200(true);
            context.setMessages("Berhasil menyimpan perubahan :)");
          }
        })
        .catch(function (error) {
          setLoader(false);
          console.log("Error : ", error.response);
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  return (
    <Dialog
      open={isOpenDialogEditTdl}
      onClose={() => setOpenDialogEditTdl(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth>
      <DialogTitle
        id="alert-dialog-title"
        style={{ textAlign: "left" }}
        // className={classes.title}
        // classes={{
        //     root: classes.title
        // }}
      >
        <Typography variant="h6" component="h2" className={classes.title}>
          <b>
            Edit <i>To Do List</i>
          </b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ textAlign: "left" }}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>Title</b>
        </Typography>

        <TextField
          inputRef={textInputReff}
          id="outlined-bare"
          className={classes.textField}
          onChange={(e) => setValueTitle(e.target.value)}
          value={valueTitle}
          variant="outlined"
          fullWidth
          inputProps={{
            className: classes.title,
          }}
        />

        <Box marginTop={4}>
          <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
            <ThemeProvider theme={themePicker}>
              <Fragment>
                <DatePicker
                  autoOk
                  value={selectedDueDate}
                  onChange={setSelectedDueDate}
                  animateYearScrolling
                  variant="inline" // dialog, static, inline
                  disableToolbar={false}
                  // style={{marginLeft: 16}}
                  format="DD MMMM YYYY"
                  placeholder={
                    selectedDueDate !== null ? selectedDueDate : "Pilih Tanggal"
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          // onClick={setSelectedDueDate}
                        >
                          <EventAvailableIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}

                  // label="Pilih tanggal"
                  // orientation="landscape"
                  // format
                  // TextFieldComponent =
                  // ToolbarComponent
                />
              </Fragment>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </Box>

        <Box marginTop={4}>
          <Typography variant="subtitle1" className={classes.title}>
            <b>Owner</b>
          </Typography>

          <Autocomplete
            id="combo-box-demo"
            options={listOwner}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            style={{ marginTop: 0 }}
            noOptionsText="Tidak ada pilihan"
            onChange={(e, owner) => handleChooseOwner(e, owner)}
            renderInput={(params) => (
              <TextField
                style={{ fontSize: 13 }}
                {...params}
                fullWidth
                label="Search Owner"
                variant="outlined"
              />
            )}
          />
        </Box>

        <Box marginTop={4}>
          <Typography variant="subtitle1" className={classes.title}>
            <b>Target</b>
          </Typography>
          <TextField
            id="outlined-bare"
            onChange={(e) => setValueTarget(e.target.value)}
            value={valueTarget !== "" ? numeral(valueTarget).format("0,0") : ""}
            className={classes.textField}
            placeholder="Masukan hanya angka"
            variant="outlined"
            fullWidth
            type="number"
            inputProps={{
              className: classes.title,
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions
      // style={{alignItems: "right", justifyContent:'flex-end'}}
      >
        <Grid container>
          <Grid item xs={6} md={6}>
            <Box marginLeft={1.7} marginTop={4}>
              <Chip
                label={
                  <Typography
                    variant="caption"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    <i>
                      To Do List ini di buat oleh <b>{createdBy}</b>
                    </i>
                  </Typography>
                }
                variant="outlined"
              />
            </Box>
          </Grid>

          <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
            <Box marginTop={4} marginRight={2}>
              <Button
                variant="outlined"
                className={classes.buttonOutlined}
                onClick={() => setOpenDialogEditTdl(false)}>
                Batal
              </Button>
              <Button
                onClick={handleSubmitSimpanPerubahan}
                variant="contained"
                className={classes.button}
                // fullWidth
                // disabled
              >
                {loader !== true ? (
                  "Simpan"
                ) : (
                  <CircularProgress size={20} style={{ color: "white" }} />
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogActions>

      <br />
    </Dialog>
  );
};

export default DialogEditTDL;

const themePicker = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },

  typography: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  textfield: {
    width: 200,
  },
});

import React, { useContext, useEffect, useState } from "react";
import { Box, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import AxiosConfig from "../../constants/config-axios";
import { URL_API } from "../../constants/config-api";
import {
  field_presets,
  entities,
  status,
  order_by,
} from "./components/global/dataFilterReport";

import { styles } from "./style/styleReports";
import {
  //comparison
  GET_COMPARISONS,
  // detailed
  GET_FILTER_DATA,
  GET_REPORT_CATEGORY,
  GET_FIELD_PRESETS,
  HIT_DATA_PRESET,
  HIT_TOTAL_RECORDS,
  SET_PAGINATION,
  // summary
  GET_SUMMARY_CONFIG,
  GET_DATA_TABLE,
  GET_FIELD_PRESET,
  SET_PAGINATING,
  GET_BAR_CHART,
  GET_PIE_CHART,
  GET_AVG_COMPANY,
  GET_SYNC,
} from "./context/types";
import ContextReports from "./context/ContextReports";
import handleError from "./components/global/handleError";

import Grouping from "./components/report-type/detailed-type/grouping";
import Comparison from "./components/comparison";
import ReportType from "./components/report-type";
import SummaryType from "./components/report-type/summary-type";
import DetailedType from "./components/report-type/detailed-type";
import extractTable from "./components/global/extractTable";
import DialogError from "../../components/DialogError";

import "./style/table.css";

const useStyles = makeStyles(styles);
// const fetcher = (url) => AxiosConfig.get(url).then((r) => r.data.data);

const ViewReports = () => {
  const classes = useStyles();
  const {
    reportState,
    filterState,
    tableState,
    tableDispatch,
    reportDispatch,
    filterDispatch,
    summaryState,
    summaryDispatch,
    tableSmrState,
    tableSmrDispatch,
    barDispatch,
    pieDispatch,
  } = useContext(ContextReports);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  // ===================================================================
  // ~ Pepare Body For Detailed Report ~
  // ~~~~~~~~~~~ BODY For Data Table ~~~~~~~~~~~
  const dataPost = {
    name: "Last Seven Days",
    platform: "web",
    field_preset_id: 1,
    report_format: "table",
    filter_by: {
      entity: [
        { name: "goal", query: "asdfasdfds" },
        { name: "ma", query: "asdfasdfds" },
      ],
      date_range: "last_seven_days",
      user: tableState.usersSelected,
      unit: [],
      status: tableState.status,
      achievement: {
        value_type: tableState.valueType,
        cal_method: tableState.calMethod,
      },
    },
    entities: ["goal", "ma"],
    group_by: {
      entity: ["goal"],
      date_time: [],
      user: false,
      unit: ["division", "branch"],
      orientation: [],
    },
    group_by_calculation: "1",
    order_by: tableState.selectedOrder,
    primary_column: "name",
    limit_type: "desc",
    limit_count_number: 0,
    week_start: "monday",
    date_format: "d m Y",
    summary_only: false,
    comparisons: {
      date_range: "previous_seven_days",
      fields: [
        "result_value",
        "overall_result_achievement",
        "expected_result_value",
      ],
    },
  };

  // Convet achievement
  const calMethodGoal =
    summaryState.selectedAchievements.length > 0
      ? summaryState.selectedAchievements.map((item) => {
          return {
            formula: item,
          };
        })
      : [];
  const calMethodMa =
    summaryState.selectedAchievementsMa.length > 0
      ? summaryState.selectedAchievementsMa.map((item) => {
          return {
            formula: item,
          };
        })
      : [];

  const achievementGoal = {
    field: "goal",
    cal_method: calMethodGoal,
  };
  const achievementMa = {
    field: "ma",
    cal_method: calMethodMa,
  };

  const refGoal = [].concat(
    achievementGoal.cal_method.length > 0 ? achievementGoal : []
  );
  const refMa = [].concat(
    achievementMa.cal_method.length > 0 ? achievementMa : []
  );

  const newAchievement = [...refGoal, ...refMa];

  // ~~~~~~~~~~~ BODY For Data Table Summary ~~~~~~~~~~~
  const dataSummary = {
    name: "Table",
    platform: "web",
    report_format: "table",
    filter_by: {
      entity: [],
      date_range: summaryState.dateRange.code,
      // date_range: "all_time",
      user: summaryState.selectedUsers,
      unit: [],
      status: summaryState.selectedStatus,
      achievement: newAchievement,
    },
    entities: summaryState.selectedEntities,
    group_by: [summaryState.selectedGroup.field],
    group_by_calculation: summaryState.selectedCalc.code,
    order_by: [summaryState.selectedOrder],
    primary_column: summaryState.selectedFields.code,
    unit_type_id: summaryState.selectedUnits.id,
    limit_count_number: 0,
    week_start: "monday",
    date_format: "d m Y",
    summary_only: false,
  };

  // console.log("DatSum", dataSummary);

  // ~~~~~~~~~~~ PAGINATION Data For Table ~~~~~~~~~~~
  const paginating = tableState.pagination;
  const page = paginating.currentPage;
  const perPage = paginating.perPage;

  // ===================================================================
  // ~ SIDE EFFECTS ~
  useEffect(() => {
    // ================= GET REPORT CATEGORIES =================
    AxiosConfig.get(`${URL_API}/reporting/master-category`)
      .then((res) => {
        const result = res.data.data;

        reportDispatch({
          type: GET_REPORT_CATEGORY,
          payload: { data: result },
        });
      })
      .catch((error) => {
        console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });

    // ================= GET REPORT ENTITIES =================
    AxiosConfig.get(`${URL_API}/reporting/master-group`)
      .then((res) => {
        const result = res.data.data;
        // console.log("RES master GRoup", result);

        filterDispatch({
          type: GET_FILTER_DATA,
          payload: { entitiy: result },
        });
      })
      .catch((error) => {
        console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });

    // ================= GET ALL INITIAL DATA CREATION =================
    AxiosConfig.get(
      `${URL_API}/reporting/page/${reportState.group}/${reportState.category}?compare_range=weekly&platform=web`
    ).then((res) => {
      const result = res.data.data;
      // console.log("RES report Page", result);

      if (reportState.category === "detailed") {
        const settings = result.layout[0].widget.setting;
        const filterBy = settings.filter_by.default_value;
        const comparisons = settings.comparisons.default_value;
        const field_preset_id = settings.field_preset_id.default_value;

        console.log("Base Layout", settings);

        const byUser = filterBy.user;
        const byUnit = filterBy.unit;

        const defaultPreset = field_preset_id.filter(
          (item) => item.name === "Result Simple"
        );

        // Set The user List to Local Storage
        localStorage.setItem("filter_user", JSON.stringify(byUser));

        // Just GET presets
        tableDispatch({
          type: GET_FIELD_PRESETS,
          payload: {
            fieldPresets: defaultPreset[0],
          },
        });

        // Load All data For Filter
        filterDispatch({
          type: GET_FILTER_DATA,
          payload: {
            tablePresets: field_preset_id,
            entities,
            users: byUser,
            units: byUnit,
            status: status,
            orderBy: order_by,
          },
        });

        // GET Comparison initial Data
        filterDispatch({
          type: GET_COMPARISONS,
          payload: {
            comparisonList: comparisons.date_range,
            comparisonFields: comparisons.fields,
          },
        });

        tableDispatch({
          type: HIT_DATA_PRESET,
          payload: { dataPresets: [], load: true },
        });

        // ================= GET INITIAL DATA TABLE DETAILED =================
        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${page}&per_page=${perPage}`,
          dataPost
        )
          .then((res) => {
            const result = res.data.data;
            const lastSync = res.data.info.data.last_sync;

            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };

            // console.log("Data First Load", DATATABLE);
            // console.log("Detailed First Load", res);

            if (res.status === 200) {
              reportDispatch({
                type: GET_SYNC,
                payload: { lastSync },
              });

              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
      } else if (reportState.category === "summary") {
        const settings = result.layout[0].widget.setting;
        const primaryFields = settings.primary_column.default_value.filter(
          (el) => el.code === "overall_result_achievement"
        );
        const groupBy = settings.group_by.default_value.filter(
          (g) => g.field !== "ora_status"
        );
        const calcMethods = settings.group_by_calculation.default_value.filter(
          (el) => el.code === "avg"
        );
        const orderBy = settings.order_by.default_value.filter(
          (g) => g.field !== "title" && g.field !== "status_id"
        );
        // const modes = settings.order_by.default_value;
        const entities = settings.filter_by.default_value.entity;
        const users = settings.filter_by.default_value.user;
        const units = settings.filter_by.default_value.unit;
        const achievements =
          settings.filter_by.default_value.achievement.cal_method.filter(
            (el) => el.formula !== "no data"
          );
        const status = settings.filter_by.default_value.status.filter(
          (el) => el.id === "3" || el.id === "6" || el.id === "4"
        );

        // ~~~~~~~~~~~ Data Pagination From Context ~~~~~~~~~~~
        const pagination = tableSmrState.paginating;
        const currentPage = pagination.currentPage;
        const perPage = pagination.perPage;

        // ~~~~~~~~~~~ Set Date Range in LS ~~~~~~~~~~~
        const date_range = {
          title: summaryState.dateRange.title,
          code: summaryState.dateRange.code,
          startDate: summaryState.dateRange.startDate,
          endDate: summaryState.dateRange.endDate,
        };
        localStorage.setItem("summary_range", JSON.stringify(date_range));

        // console.log("Summary Layout", layouts);
        // console.log("Summary primaryFields", primaryFields);

        summaryDispatch({
          type: GET_SUMMARY_CONFIG,
          payload: {
            primaryFields,
            groupBy,
            calcMethods,
            orderBy,
            entities,
            users,
            units,
            achievements,
            status,
            // modes,
            // filters,
          },
        });

        tableSmrDispatch({
          type: GET_DATA_TABLE,
          payload: {
            dataTable: [],
            loading: true,
          },
        });

        // ================= GET INITIAL DATA TABLE SUMMARY =================
        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
          dataSummary
        )
          .then((res) => {
            const result = res.data.data;
            // console.log("RES SUMMARY TABLE", result);

            const paginating = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
              totalRecords: result.results.total,
            };

            if (res.status === 200) {
              tableSmrDispatch({
                type: GET_FIELD_PRESET,
                payload: { fieldPreset: result.settings.field_preset },
              });

              setTimeout(() => {
                tableSmrDispatch({
                  type: GET_DATA_TABLE,
                  payload: {
                    dataTable: result.results.data,
                    // dataTable: result.results,
                    loading: false,
                  },
                });
              }, 1000);

              tableSmrDispatch({
                type: SET_PAGINATING,
                payload: { paginating },
              });
            }
          })
          .catch((error) => {
            console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableSmrDispatch({
              type: GET_DATA_TABLE,
              payload: {
                dataTable: [],
                loading: false,
              },
            });
          });

        //============= Datat For Ovaerge Company =============
        const dataAvg = {
          ...dataSummary,
          report_format: "summary",
        };

        // ================= GET INITIAL DATA BAR CHART SUMMARY =================
        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
          dataAvg
        )
          .then((res) => {
            const result = res.data.data;
            // console.log("RES SUMMARY BAR", result);

            if (res.status === 200) {
              summaryDispatch({
                type: GET_AVG_COMPANY,
                payload: {
                  averages: result.results,
                },
              });
            }
          })
          .catch((error) => {
            console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);
          });

        //============= Datat For Bar Chart =============
        const dataBar = { ...dataSummary, report_format: "bar_chart" };

        barDispatch({
          type: GET_BAR_CHART,
          payload: {
            barData: [],
            barPreset: [],
            loading: true,
          },
        });

        // ================= GET INITIAL DATA BAR CHART SUMMARY =================
        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
          dataBar
        )
          .then((res) => {
            const result = res.data.data;
            // console.log("RES SUMMARY BAR", result);

            if (res.status === 200) {
              barDispatch({
                type: GET_BAR_CHART,
                payload: {
                  barData: result.results.data,
                  barPreset: result.settings.field_preset,
                  loading: false,
                },
              });
            }
          })
          .catch((error) => {
            console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            barDispatch({
              type: GET_BAR_CHART,
              payload: {
                barData: [],
                barPreset: [],
                loading: false,
              },
            });
          });

        //============= Datat For Pie Chart =============
        const dataPie = {
          ...dataSummary,
          report_format: "pie_chart",
          order_by: [],
        };

        pieDispatch({
          type: GET_PIE_CHART,
          payload: {
            pieDataGoal: [],
            pieDataMa: [],
            piePreset: [],
            loading: true,
          },
        });

        // ================= GET INITIAL DATA BAR CHART SUMMARY =================
        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
          dataPie
        )
          .then((res) => {
            const result = res.data.data;
            // console.log("RES SUMMARY BAR", result);
            const pieGoal = result.results.filter((el) => el.type === "goal");
            const pieMa = result.results.filter((el) => el.type === "ma");

            if (res.status === 200) {
              pieDispatch({
                type: GET_PIE_CHART,
                payload: {
                  pieDataGoal: pieGoal,
                  pieDataMa: pieMa,
                  piePreset: result.settings.field_preset,
                  loading: false,
                },
              });
            }
          })
          .catch((error) => {
            console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            pieDispatch({
              type: GET_PIE_CHART,
              payload: {
                pieDataGoal: [],
                pieDataMa: [],
                piePreset: [],
                loading: false,
              },
            });
          });
      }
    });
  }, [reportState.category]);

  return (
    <Box className={classes.root}>
      <Box marginBottom={2} className={classes.layoutMarginLeftAndTop}>
        <ReportType classes={classes} />
      </Box>

      {reportState.valueTab === 1 && <Divider orientation="horizontal" />}

      {reportState.valueTab === 1 && (
        <div
          style={{
            backgroundColor: "#fff",
            padding: `20px 0`,
          }}>
          <Box className={classes.layoutMarginLeft}>
            {/* BOX Container Grouping */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginRight={2}>
              <Grouping classes={classes} />

              <Comparison classes={classes} />
            </Box>
          </Box>
        </div>
      )}

      <div className={classes.dataArea}>
        <Box className={classes.layoutMarginLeft}>
          {/* Box Button Compare */}

          {/* BOX Body Reports */}
          <Box marginTop={1.5} marginRight={2}>
            {reportState.valueTab === 0 && <SummaryType classes={classes} />}
            {reportState.valueTab === 1 && <DetailedType classes={classes} />}
          </Box>
        </Box>
      </div>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Box>
  );
};

export default ViewReports;

import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  Checkbox,
  Badge,
  TextField,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";

import clsx from "clsx";
// import moment from "moment";

import ListItemUser from "./ListItemUser";
import ContextReports from "../../../../../context/ContextReports";
import {
  GET_SYNC,
  HIT_DATA_PRESET,
  HIT_TOTAL_RECORDS,
  HIT_USER_SELECT,
  SET_PAGINATION,
} from "../../../../../context/types";
import AxiosConfig from "../../../../../../../constants/config-axios";
import { URL_API } from "../../../../../../../constants/config-api";
import extractTable from "../../../../global/extractTable";
import handleError from "../../../../global/handleError";
import DialogError from "../../../../../../../components/DialogError";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#d1354a",
    color: "#fff",
  },
}))(Badge);

const UserFilter = ({ classes }) => {
  const { filterState, tableState, tableDispatch, reportDispatch } =
    useContext(ContextReports);

  // ===========================================================================================
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ | STATE | ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ===========================================================================================
  const [selected, setSelected] = useState([]);
  const [anchorUser, setAnchorUser] = useState(null);
  const [user, setUser] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const itemCount = users.length;
  const isSelected = (id) => selected.indexOf(id) !== -1;

  // ===========================================================================================
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ | Side Effects | ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ===========================================================================================
  React.useEffect(() => {
    if (anchorUser !== null) {
      const getUser = localStorage.getItem("filter_user");
      const getUserParsed = JSON.parse(getUser);

      const filterUser =
        getUserParsed.length > 0 &&
        getUserParsed.filter((item) => {
          const fullName = `${item.member.first_name} ${item.member.last_name}`;
          const userName =
            fullName.toLowerCase().indexOf(user.toLowerCase()) !== -1;

          return userName;
        });

      // console.log("USER PARSED", getUserParsed);
      setUsers(filterUser);
    }
  }, [anchorUser, user]);

  // ===========================================================================================
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ | FUNC | ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ===========================================================================================
  const handleShowAnchorUser = (event) => {
    setAnchorUser(event.currentTarget);
  };

  const handleCloseAnchorUser = () => {
    setAnchorUser(null);
  };

  const handleShowResult = () => {
    setLoading(true);

    tableDispatch({
      type: HIT_USER_SELECT,
      payload: { selected },
    });

    tableDispatch({
      type: HIT_DATA_PRESET,
      payload: { dataPresets: [], load: true },
    });

    const dataPost = {
      name: filterState.dateRange.title,
      platform: "web",
      field_preset_id: tableState.fieldPresets.id,
      report_format: "table",
      filter_by: {
        entity: [
          { name: "goal", query: "asdfasdfds" },
          { name: "ma", query: "asdfasdfds" },
        ],
        date_range: filterState.dateRange.code,
        user: selected,
        unit: tableState.unitsSelected,
        status: tableState.status,
        achievement: {
          value_type: tableState.valueType,
          cal_method: tableState.calMethod,
        },
      },
      entities: tableState.entity,
      group_by: {
        entity: ["goal"],
        date_time: [],
        user: false,
        unit: ["division", "branch"],
        orientation: [],
      },
      group_by_calculation: "1",
      order_by: tableState.selectedOrder,
      primary_column: "name",
      limit_type: "desc",
      limit_count_number: 0,
      week_start: "monday",
      date_format: "d m Y",
      summary_only: false,
    };

    const postWithCompare = {
      ...dataPost,
      comparisons: {
        date_range: tableState.comparison.code,
        fields: [
          "result_value",
          "overall_result_achievement",
          "expected_result_value",
        ],
      },
    };

    const { currentPage, perPage } = tableState.pagination;

    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
      tableState.comparison.code === "" ? dataPost : postWithCompare
    )
      .then((res) => {
        const result = res.data.data;
        const lastSync = res.data.info.data.last_sync;

        const DATATABLE = extractTable(result.results.data);
        const pagination = {
          currentPage: result.results.current_page,
          perPage: result.results.per_page,
          lastPage: result.results.last_page,
        };

        // console.log("Ori Result PRST", result);

        // const date = new Date();
        // const after = moment(date).format("mm:ss");

        // console.log("AFTER", after);

        if (res.status === 200) {
          handleCloseAnchorUser();
          setLoading(false);

          reportDispatch({
            type: GET_SYNC,
            payload: { lastSync },
          });

          tableDispatch({
            type: HIT_DATA_PRESET,
            payload: { dataPresets: DATATABLE, load: false },
          });

          tableDispatch({
            type: SET_PAGINATION,
            payload: { pagination },
          });

          tableDispatch({
            type: HIT_TOTAL_RECORDS,
            payload: { totalRecords: result.results.total },
          });
        }
      })
      .catch((error) => {
        console.log("Error : ", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        tableDispatch({
          type: HIT_DATA_PRESET,
          payload: { dataPresets: [], load: false },
        });
      });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filterState.filterUsers.map(
        (n) => n.structure_position.id
      );
      setSelected(newSelecteds);
      // console.log("selAll", newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterUser = (e) => {
    setUser(e.target.value);
  };

  // ===========================================================================================
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ | RENDER | ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ===========================================================================================
  return (
    <>
      <StyledBadge
        badgeContent={selected.length}
        children={
          <Button
            variant="outlined"
            size="small"
            startIcon={<PersonIcon style={{ fontSize: 15 }} />}
            className={classes.actionBtn}
            onClick={handleShowAnchorUser}>
            User
          </Button>
        }
      />

      <Menu
        id="simple-menu"
        anchorEl={anchorUser}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorUser)}
        style={{ marginBottom: 8, width: 320 }}
        onClose={handleCloseAnchorUser}>
        <Box mb={1.5} mt={1.5} ml={2} mr={2}>
          <InputBase
            placeholder="Type user's name"
            variant="outlined"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            style={{ width: 250 }}
            onChange={handleFilterUser}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginLeft={1}
          marginRight={6}>
          <Box display="flex" alignItems="center">
            <Checkbox
              disableRipple
              indeterminate={selected.length > 0 && selected.length < itemCount}
              checked={itemCount > 0 && selected.length === itemCount}
              onChange={handleSelectAllClick}
              className={classes.checkboxRoot}
              checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
              }
              icon={<span className={classes.icon} />}
              color="default"
              inputProps={{ "aria-label": "decorative checkbox" }}
            />
            <Typography variant="subtitle2" className={classes.title555}>
              All
            </Typography>
          </Box>
          <Typography
            className={classes.btnTxt}
            onClick={() => setSelected([])}>
            None
          </Typography>
        </Box>
        {users.length > 0 &&
          users.slice(0, 10).map((item, i) => (
            <div key={i} className={classes.listItemMenu}>
              <ListItemUser
                classes={classes}
                index={i}
                user={item}
                selected={selected}
                setSelected={setSelected}
                isSelected={isSelected}
              />
            </div>
          ))}
        <Box margin={1.5} mt={1}>
          <Divider />
        </Box>

        <Box
          marginRight={2}
          marginBottom={1}
          display="flex"
          justifyContent="flex-end">
          <Button
            size="small"
            variant="outlined"
            className={classes.button0}
            onClick={handleCloseAnchorUser}>
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            className={classes.button1}
            style={{ marginLeft: 7.5 }}
            disabled={selected.length === 0}
            onClick={handleShowResult}>
            {loading ? "Showing..." : "Show results"}
          </Button>
        </Box>
      </Menu>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default UserFilter;

import React, { createContext } from "react";

const ContextMeeting = createContext();

const ContextGlobalMeeting = ({ children }) => {
  return (
    <ContextMeeting.Provider value={{}}>{children}</ContextMeeting.Provider>
  );
};

export { ContextMeeting, ContextGlobalMeeting };

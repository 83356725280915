import React, { useEffect, useState, useContext } from "react";
import {
  Fab,
  Grid,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import PhotoLibraryRoundedIcon from "@material-ui/icons/PhotoLibraryRounded";

import ContextGoalDetail from "../Context/ContextGoalDetail";

import ImageItem from "./DriveComponent/ImageItem";
import VideoItem from "./DriveComponent/VideoItem";

import DialogDeleteBuktiGRInDatabase from "./DialogDeleteBuktiGRInDatabase";

import DialogAddEvidence from "./DialogAddEvidence";
import DialogCreateText from "./DialogCreateText";

import {
  FORMAT_TYPE_IMAGE,
  FORMAT_TYPE_VIDEO,
  FORMAT_TYPE_DOC,
} from "../../../../constants/config-type-file";

import {
  EVIDENCE_CATEGORY_DRIVE,
  EVIDENCE_CATEGORY_LINK,
} from "../../../../constants/config-drive";
import DriveItem from "./DriveComponent/DriveItem";
import LinkItem from "./DriveComponent/LinkItem";
import DocItem from "./DriveComponent/DocItem";
import DialogNavImg from "./DialogNavImg";

const Uploader = (props) => {
  const {
    classes,
    collectionGoalResultList,
    item,
    userToken,
    grItem,
    setFireGoalDetail,
    goalDetailState,
  } = props;

  const context = useContext(ContextGoalDetail);

  /*
  ```````````````````
  COMPONENT DID MOUNT
  
  ```````````````````
  */
  let listPict = [];
  const [listPictState, setListPictState] = useState([]);
  const [anchorEvidence, setAnchorEvidence] = useState(null);
  const [showFade, setShowFade] = useState(false);
  const [openDialogAddEvidence, setOpenDialogAddEvidence] = useState(false);
  const [openDialogPlainText, setOpenDialogPlainText] = useState(false);

  useEffect(() => {
    if (item !== null && item.length > 0) {
      listPict.push(item);

      setListPictState(item);

      setShowFade(true);
    } else {
      setListPictState([]);
    }
  }, [collectionGoalResultList]);

  /*
  ``````````````````````````````````
  OPEN DIALOG NAVGATE IMG
  
  ``````````````````````````````````
  */
  const [openDialogNavImg, setOpenDialogNavImg] = useState(false);

  /*
 `````````````````````````````````````````````````
 ON MOUSE OVER & ON MOUSE LEAVE DELETE IMAGE/VIDEO
 
 `````````````````````````````````````````````````
 */
  const [isShowIconDelete, setShowIconDelete] = useState({
    show: true,
    index: 0,
  });

  /*
  `````````````````````````````````````````````````````````````````
  HANDLE OPEN DIALOG DELETE PICT BUKTI GOAL RESULT in DATABASE
  
  `````````````````````````````````````````````````````````````````
  */
  const [isOpenDialogDeleteBuktiInDB, setOpenDialogDeleteBuktiInDB] =
    useState(false);

  /*
 ````````````````````````````````````````````````
 HANDLE DELETE PICT BUKTI GOAL RESULT in DATABASE
 
 ````````````````````````````````````````````````
 */
  const [dataDetailDelete, setDataDetailDelete] = useState("");

  // ========= Check Attchment Item ===========
  const checkAttach =
    listPictState.length > 0 &&
    listPictState.some((el) => el.content === "attachment");

  const handleDeletePictBuktiGRInDatabase = (e, data) => {
    e.preventDefault();
    // console.log("Data Delete PICT in DATABASE: ", data);
    setDataDetailDelete(data);

    setOpenDialogDeleteBuktiInDB(true);
  };

  const handleShowAnchorEvidence = (event) => {
    setAnchorEvidence(event.currentTarget);
  };

  const handleCloseAnchorEvidence = () => {
    setAnchorEvidence(null);
  };

  // console.log("Chech ATTACh", checkAttach);

  return (
    <Grid container alignItems="flex-start" justify="left">
      <div style={{ marginLeft: 40 }}>
        <br />
        <Grid container spacing={8} alignItems="center">
          {listPictState.length > 0 &&
            listPictState.map((tile, i) => {
              // console.log("TIILEEE", tile);

              // DELETE / VIEW POLICY (HAK AKSES)
              let viewAllowed = false;
              let deleteAllowed = false;

              tile.policy.forEach((premission) => {
                // console.log("Polisi GOAL R", premission);

                if (premission.actionName === "viewDetail") {
                  viewAllowed = premission.result;
                } else if (premission.actionName === "delete") {
                  deleteAllowed = premission.result;
                }
              });

              if (viewAllowed && tile.format_type_id === FORMAT_TYPE_IMAGE) {
                return (
                  <ImageItem
                    i={i}
                    tile={tile}
                    classes={classes}
                    showFade={showFade}
                    setShowIconDelete={setShowIconDelete}
                    handleDeletePictBuktiGRInDatabase={
                      handleDeletePictBuktiGRInDatabase
                    }
                    isShowIconDelete={isShowIconDelete}
                    userToken={userToken}
                    deleteAllowed={deleteAllowed}
                  />
                );
              }

              if (viewAllowed && tile.format_type_id === FORMAT_TYPE_VIDEO) {
                return (
                  <VideoItem
                    i={i}
                    tile={tile}
                    classes={classes}
                    showFade={showFade}
                    setShowIconDelete={setShowIconDelete}
                    handleDeletePictBuktiGRInDatabase={
                      handleDeletePictBuktiGRInDatabase
                    }
                    isShowIconDelete={isShowIconDelete}
                    userToken={userToken}
                    deleteAllowed={deleteAllowed}
                  />
                );
              }

              if (viewAllowed && tile.format_type_id === FORMAT_TYPE_DOC) {
                return (
                  <DocItem
                    i={i}
                    tile={tile}
                    classes={classes}
                    showFade={showFade}
                    handleDeletePictBuktiGRInDatabase={
                      handleDeletePictBuktiGRInDatabase
                    }
                    deleteAllowed={deleteAllowed}
                  />
                );
              }

              if (
                viewAllowed &&
                tile.evidence_category_id === EVIDENCE_CATEGORY_DRIVE
              ) {
                return (
                  <DriveItem
                    i={i}
                    tile={tile}
                    classes={classes}
                    showFade={showFade}
                    handleDeletePictBuktiGRInDatabase={
                      handleDeletePictBuktiGRInDatabase
                    }
                    deleteAllowed={deleteAllowed}
                  />
                );
              }

              if (
                viewAllowed &&
                tile.evidence_category_id === EVIDENCE_CATEGORY_LINK
              ) {
                return (
                  <LinkItem
                    i={i}
                    tile={tile}
                    classes={classes}
                    showFade={showFade}
                    handleDeletePictBuktiGRInDatabase={
                      handleDeletePictBuktiGRInDatabase
                    }
                    deleteAllowed={deleteAllowed}
                  />
                );
              }
            })}

          <Fab
            size="small"
            className={classes.buttonFab}
            disabled={item.length === 5}
            onClick={handleShowAnchorEvidence}>
            <AddIcon fontSize="small" />
          </Fab>
          <Menu
            id="simple-menu"
            anchorEl={anchorEvidence}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            getContentAnchorEl={null}
            open={Boolean(anchorEvidence)}
            onClose={handleCloseAnchorEvidence}>
            <MenuItem
              onClick={() => {
                setOpenDialogAddEvidence(true);
                setAnchorEvidence(null);
              }}
              style={{ width: 150 }}>
              <Typography variant="subtitle2" className={classes.title}>
                Add Evidence
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDialogPlainText(true);
                setAnchorEvidence(null);
              }}
              style={{ width: 150 }}>
              <Typography variant="subtitle2" className={classes.title}>
                Plain Document
              </Typography>
            </MenuItem>
          </Menu>

          {checkAttach && (
            <Tooltip title="Image Slide Show">
              <IconButton
                style={{ marginLeft: 10 }}
                size="small"
                onClick={() => setOpenDialogNavImg(true)}>
                <PhotoLibraryRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </div>

      <DialogDeleteBuktiGRInDatabase
        classes={classes}
        isOpenDialogDeleteBuktiInDB={isOpenDialogDeleteBuktiInDB}
        setOpenDialogDeleteBuktiInDB={setOpenDialogDeleteBuktiInDB}
        dataDetailDelete={dataDetailDelete}
        setFireGoalDetail={setFireGoalDetail}
        setListPictState={setListPictState}
        listPictState={listPictState}
      />

      <DialogAddEvidence
        openDialogAddEvidence={openDialogAddEvidence}
        setOpenDialogAddEvidence={setOpenDialogAddEvidence}
        classes={classes}
        item={grItem}
        greLength={item.length}
        setFireGoalDetail={setFireGoalDetail}
        goalDetailState={goalDetailState}
      />

      <DialogCreateText
        classes={classes}
        openDialogPlainText={openDialogPlainText}
        setOpenDialogPlainText={setOpenDialogPlainText}
        setFireGoalDetail={setFireGoalDetail}
        item={grItem}
      />

      <DialogNavImg
        openDialogNavImg={openDialogNavImg}
        setOpenDialogNavImg={setOpenDialogNavImg}
        listPictState={listPictState}
        userToken={userToken}
      />
    </Grid>
  );
};

export default Uploader;

import React, { useState, useContext } from "react";
import {
  MenuItem,
  FormControl,
  Select,
  withStyles,
  InputBase,
  Box,
  Divider,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

import { theme } from "../../style/styleReports";

import { AntTabs, AntTab } from "./tabStyle";

import ContextReports from "../../context/ContextReports";
import { SET_VALUE_TAB } from "../../context/types";
import { Typography } from "@material-ui/core";

import ConfigReport from "./ConfigReport";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    position: "relative",
    fontSize: 19,
    padding: "2px 16px 4px 8px",
    fontFamily: ["Roboto", '"Segoe UI"', "Roboto"].join(","),
  },
}))(InputBase);

const ReportType = ({ classes }) => {
  const { reportState, reportDispatch } = useContext(ContextReports);

  const [reportType, setReportType] = useState("live_report");

  const handleReportType = (event) => {
    setReportType(event.target.value);
  };

  const handleValueTab = (e, newVal) => {
    if (newVal === 0) {
      reportDispatch({
        type: SET_VALUE_TAB,
        payload: { valueTab: newVal, category: "summary" },
      });
    } else if (newVal === 1) {
      reportDispatch({
        type: SET_VALUE_TAB,
        payload: { valueTab: newVal, category: "detailed" },
      });
    } else if (newVal === 2) {
      reportDispatch({
        type: SET_VALUE_TAB,
        payload: { valueTab: newVal, category: "progress" },
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          <FormControl style={{ marginRight: 18 }}>
            <Select
              value={reportType}
              label={<Typography variant="h6">{reportType}</Typography>}
              onChange={handleReportType}
              input={<BootstrapInput />}>
              <MenuItem dense value="live_report" className={classes.hoverItem}>
                <Typography variant="h6">Live Report</Typography>
              </MenuItem>
              <MenuItem
                dense
                value="saved_report"
                className={classes.hoverItem}>
                <Typography variant="h6">Saved Report</Typography>
              </MenuItem>
            </Select>
          </FormControl>
          <Divider orientation="vertical" flexItem />
          <Box marginLeft={3}>
            <AntTabs
              value={reportState.valueTab}
              onChange={handleValueTab}
              aria-label="ant example">
              {reportState.reportCategories.length > 0 &&
                reportState.reportCategories
                  .filter((fltr) => fltr.id !== "progress")
                  .map((tab, i) => <AntTab key={i} label={tab.name} />)}
            </AntTabs>
          </Box>
        </Box>
        <Box pr={5}>
          <ConfigReport classes={classes} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ReportType;

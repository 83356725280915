import React, { useEffect, useState, useCallback } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";

import { useDropzone } from "react-dropzone";

import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarBorderIconFulled from "@material-ui/icons/Star";

import { extractImageFileExtensionFromBase64 } from "../../../utilities/ReusableUtils";
import Redirect from "../../../utilities/Redirect";
import { ToHrEmployeeRiwayat } from "../../../constants/config-redirect-url";
import PictPlusUploadDocument from "../../../assets/images/Group_2268.png";

// import Snackber from '../Components/Snackber';
// import DialogDeleteAnggotaTambahan from './Components/DialogDeleteAnggotaTambahan'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const styles = (theme) => ({
  root: {
    padding: theme.spacing(5, 2),
    marginTop: theme.spacing(4),
    width: 575,
    borderRadius: 7,
  },
  button: {
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    color: "white",
    textTransform: "capitalize",
    "&:disabled": {
      background: "grey",
      color: "white",
    },
  },
  buttonModal: {
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    marginRight: theme.spacing(4),
  },
  buttonModalDelete: {
    borderRadius: 5,
    backgroundColor: "#d1354a",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    marginRight: theme.spacing(4),
    color: "white",
    "&:hover": {
      color: "#f28181",
    },
  },
  buttonModalCancel: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  buttonOutlined: {
    borderRadius: 5,
    border: 0,
    fontFamily: "Roboto",
    color: "grey",
    textTransform: "capitalize",
    "&:hover": {
      color: "grey",
      background: "#d2d2d2",
      borderWidth: 0,
    },
  },
  paperKeluarga: {
    width: "95%",
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
  },
  titleHeader: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
    // marginTop:  theme.spacing(2),
  },
  titleTambahAnggotaKeluarga: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: "#cc0707",
    cursor: "pointer",
  },
  title: {
    fontFamily: "Roboto",
  },
  titleForm: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    // marginTop: theme.spacing(2),
  },
  textField: {
    minWidth: 425,
    marginLeft: theme.spacing(6),
    marginBottom: theme.spacing(1),
  },
  titleTanggalLahir: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  iconStarList: {
    marginRight: theme.spacing(22),
  },
  iconStartButton: {
    color: "#ffbf39",
  },
  pictUpload: {
    marginRight: theme.spacing(42),
    cursor: "pointer",
    height: 48,
    width: 48,
  },
});

const ViewKeahlian = (props) => {
  const { classes } = props;

  /*
        ````````````````````````````````````
        COMPONENT DID MOUNT

        ````````````````````````````````````
    */
  const [listDataKeahlian, setListDataKeahlian] = useState("");

  let dataList = [];

  const employeeDataKeahlian = localStorage.getItem("employee_data_keahlian");
  let employeeDataKeahlianAfterParse =
    employeeDataKeahlian !== null ? JSON.parse(employeeDataKeahlian) : [];

  useEffect(() => {
    console.log(
      "employeeDataKeahlianAfterParse : ",
      employeeDataKeahlianAfterParse
    );

    setListDataKeahlian(employeeDataKeahlianAfterParse);
  }, []);

  /*
        ````````````````````````````````````
        HANDLE MODAL TAMBAH, DELETE, UPDATE

        ````````````````````````````````````
    */
  const [modalTambahKeahlian, setModalTambahKeahlian] = useState(false);
  const [modalDeleteKeahlian, setModalDeleteKeahlian] = useState(false);

  /*
        `````````````````````
        HANDLE NAME KEAHLIAN 

        ````````````````````
    */
  const [nameKeahlian, setNameKeahlian] = useState("");

  const handleChangeKeahlian = (e) => {
    setNameKeahlian(e.target.value);
  };

  const handleSimpanDataKeahlian = () => {
    /*

           ````````````````````````````````````````````````````````````````````````````````````
           * SEMENTARA INI TGL 11 Juli 2019 LEVELING START BELUM BISA MULTIPLE/ SEMPURNA,
           NEXT ANE MESTI IMPLEMENTASI PAKAI : https://github.com/ekeric13/react-star-ratings

           ````````````````````````````````````````````````````````````````````````````````````

       */

    // let dataList = [];

    let data = {
      name_keahlian: nameKeahlian,
      level: beginnerText,
      document: imageBinaryPreviewUrl,
      file_name: nameFile,
    };

    // dataList.push(data);
    if (listDataKeahlian.length > 0) {
      console.log("Run v1");

      const newList = [...listDataKeahlian, data];

      console.log("newList : ", newList);

      localStorage.setItem("employee_data_keahlian", JSON.stringify(newList));
      setListDataKeahlian([...listDataKeahlian, data]);
    } else {
      console.log("Run v2");

      dataList.push(data);
      localStorage.setItem("employee_data_keahlian", JSON.stringify(dataList));

      window.location.reload();
    }

    setModalTambahKeahlian(false);
  };

  /*
        `````````````````````````
        HANDLE ICON BUTTONS LEVEL

        `````````````````````````
    */

  const [beginnerText, setBeginnerText] = useState("");
  const [mediumText, setMediumText] = useState("");
  const [expertText, setExpertText] = useState("");

  const [isYellowIconFirst, setYellowIconFirst] = useState(true);
  const [isYellowIconSecond, setYellowIconSecond] = useState(false);
  const [isYellowIconThree, setYellowIconThree] = useState(false);

  const toggleIconButtonFirst = () => {
    isYellowIconFirst ? setYellowIconFirst(true) : setYellowIconFirst(true);
    isYellowIconFirst
      ? setBeginnerText("Beginner")
      : setBeginnerText("Beginner");
    isYellowIconFirst ? setMediumText("") : setMediumText("");
  };

  const toggleIconButtonSecond = () => {
    setYellowIconThree(false);
    isYellowIconSecond ? setYellowIconSecond(false) : setYellowIconSecond(true);

    isYellowIconSecond
      ? setBeginnerText("Beginner")
      : setBeginnerText("Beginner");
    isYellowIconSecond ? setMediumText("") : setMediumText("");
  };

  const toggleIconButtonThree = () => {
    setYellowIconFirst(true);
    setYellowIconSecond(true);

    isYellowIconThree ? setYellowIconThree(false) : setYellowIconThree(true);
    isYellowIconThree ? setMediumText("") : setMediumText("Medium");

    isYellowIconThree ? setBeginnerText("Beginner") : setBeginnerText("");
  };

  /* 
       ````````````````````
       Feature Upload Foto 
       
       ````````````````````
    */
  // const [imageBinaryPreviewUrl, setImageBinaryPreviewUrl] = useState(dataIdentitasInfoDasarAfterParse.foto);
  const [imageBinaryPreviewUrl, setImageBinaryPreviewUrl] = useState("");
  const [imgSrcExt, setImgSrcExt] = useState();

  const [nameFile, setNameFile] = useState("");

  // const [imgSrc, setImgSrc ] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log("acceptedFiles : ", acceptedFiles);
    setNameFile(acceptedFiles[0].name);

    //*
    const reader = new FileReader();

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      // Do whatever you want with the file contents
      //   console.log("Reader : ", reader)
      const binaryStr = reader.result;
      console.log("Binary String : ", binaryStr);

      setImageBinaryPreviewUrl(binaryStr);
      setImgSrcExt(extractImageFileExtensionFromBase64(binaryStr));
    };

    // acceptedFiles.forEach(file => reader.readAsBinaryString(file))
    acceptedFiles.forEach((file) => reader.readAsDataURL(file));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <MuiThemeProvider theme={theme}>
      <br />
      <br />
      <Paper elevation={1} className={classes.paperKeluarga} square={true}>
        <Grid container>
          <Grid item xs={10} style={{ textAlign: "left" }}>
            <br />
            <Typography variant="subtitle1" className={classes.titleHeader}>
              <b>Keahlian</b>
            </Typography>

            <br />
            <Typography
              variant="subtitle2"
              className={classes.titleTambahAnggotaKeluarga}
              onClick={() => setModalTambahKeahlian(true)}>
              <b>+ Tambah Keahlian</b>
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            {/* 
                            <IconButton className={classes.iconEdit}>
                                <i className='material-icons'>edit</i>
                            </IconButton> 
                        */}
          </Grid>
        </Grid>

        {listDataKeahlian.length > 0 &&
          listDataKeahlian.map((item, i) => {
            return (
              <Grid container key={i}>
                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    className={classes.titleHeader}>
                    <b>{item.name_keahlian}</b>
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="subtitle2"
                    className={classes.titleHeader}>
                    <b>Level {item.level}</b>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{ color: "#cc0707", cursor: "pointer" }}>
                      Edit{" "}
                    </span>{" "}
                    &nbsp;&nbsp;- &nbsp;&nbsp;
                    <span
                      onClick={() => setModalDeleteKeahlian(true)}
                      style={{ color: "#cc0707", cursor: "pointer" }}>
                      Hapus
                    </span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    className={classes.titleTanggalLahir}>
                    {"File yang telah di upload : " + item.file_name}
                  </Typography>

                  <br />
                </Grid>
              </Grid>
            );
          })}

        <br />
        <br />
      </Paper>

      {/* 

                ````````````````````
                STICKY FOOTER

                ````````````````````
            */}
      <Grid
        container
        spacing={8}
        direction="row"
        justify="center"
        // alignItems="center"
      >
        <Grid item sm={9}></Grid>

        <Grid item sm={1} style={{ textAlign: "right" }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          <Button
            variant="outlined"
            color="secondary"
            className={classes.buttonOutlined}
            style={{ margin: 14 }}
            // onClick={() => Redirect(ToMembershipStatusScenarioPENDING_SEMENTARA)}
          >
            Keluar
          </Button>
        </Grid>
        <Grid item sm={2} style={{ textAlign: "left" }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            style={{ margin: 14 }}
            disabled={false}
            onClick={() => Redirect(ToHrEmployeeRiwayat)}>
            Lanjut
          </Button>
        </Grid>
      </Grid>

      <Dialog
        open={modalTambahKeahlian}
        onClose={() => setModalTambahKeahlian(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          <Typography variant="subtitle1" className={classes.title}>
            <b>Tambah Keahlian</b>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <List className={classes.list}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Nama Keahlian</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-bare"
                onChange={handleChangeKeahlian}
                value={nameKeahlian}
                className={classes.textField}
                // placeholder={'NIP'}
                variant="outlined"
                // error={infoError == true && npwp == '' ? true : false}
                // helperText={infoError == true && npwp == '' ? "Wajib di isi" : ' '}
                // required={true}
                // name='nip'
                // color='primary'
                // onKeyDown={handleEnterPress}
                // disabled= {isLockedStatusState == 1 ? true : false}
                // fullWidth
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Level</b>
                  </Typography>
                }
              />

              <ListItemSecondaryAction className={classes.iconStarList}>
                <IconButton
                  onClick={toggleIconButtonFirst}
                  className={
                    isYellowIconFirst == true ? classes.iconStartButton : null
                  }
                  edge="start"
                  aria-label="Delete">
                  {isYellowIconFirst == true ? (
                    <StarBorderIconFulled />
                  ) : (
                    <StarBorderIcon />
                  )}
                </IconButton>
                <IconButton
                  onClick={toggleIconButtonSecond}
                  className={
                    isYellowIconSecond == true ? classes.iconStartButton : null
                  }
                  edge="start"
                  aria-label="Delete">
                  {isYellowIconSecond == true ? (
                    <StarBorderIconFulled />
                  ) : (
                    <StarBorderIcon />
                  )}
                </IconButton>
                <IconButton
                  onClick={toggleIconButtonThree}
                  className={
                    isYellowIconThree == true ? classes.iconStartButton : null
                  }
                  edge="start"
                  aria-label="Delete">
                  {isYellowIconThree == true ? (
                    <StarBorderIconFulled />
                  ) : (
                    <StarBorderIcon />
                  )}
                </IconButton>
                <IconButton edge="start" aria-label="Delete">
                  <StarBorderIcon />
                </IconButton>
                <IconButton edge="start" aria-label="Delete">
                  <StarBorderIcon />
                </IconButton>
                <span style={{ fontFamily: "Roboto", fontSize: 12 }}>
                  <b>
                    <i>
                      {beginnerText}
                      {mediumText}
                    </i>
                  </b>
                </span>
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Sertifikat</b>
                  </Typography>
                }
              />

              <div {...getRootProps()}>
                <input {...getInputProps()} />

                {imageBinaryPreviewUrl ? (
                  <Typography variant="caption" className={classes.title}>
                    <i>{nameFile}</i>
                  </Typography>
                ) : (
                  <img
                    src={PictPlusUploadDocument}
                    className={classes.pictUpload}
                  />
                )}
              </div>
            </ListItem>
          </List>

          <DialogContentText id="alert-dialog-description">
            <Typography variant="h6"></Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "right", justifyContent: "flex-end" }}>
          <Button
            onClick={() => setModalTambahKeahlian(false)}
            variant="outlined"
            size="small"
            className={classes.buttonModalCancel}>
            Batal
          </Button>

          <Button
            // onClick={() => Redirect(ToCompletionProfile)}
            onClick={handleSimpanDataKeahlian}
            variant="contained"
            color="primary"
            size="small"
            className={classes.buttonModal}>
            Simpan
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewKeahlian);

const stars = [
  {
    image: "https://material-ui.com/components/grid-list/",
  },
];

import React, { useCallback, useEffect, useState, Fragment } from "react";
import axios from 'axios';
import { URL_API } from '../../../../constants/config-api';
import {
    getDateFormat,
    getServerDateFormat
} from '../Utilities/PeriodUtil';
import moment from 'moment';
import {
    Calendar,
    DateLocalizer,
    momentLocalizer,
    globalizeLocalizer,
    move,
    Views,
    Navigate,
    components,
  } from 'react-big-calendar';

/**
 * Icon
 */
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';


/**
 * Core
 */
import { Typography, Grid, CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import Divider from '@material-ui/core/Divider';

const navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
}

const DetailPeriod = props => {

    
	/*
		````````````````````````````````````
		INIT

		````````````````````````````````````
	*/
    const { 

        classes,
        item,
        setItem,
        open,
        setOpen,
        handleOpenCreateForm

    } = props;

    const allViews = Object.keys(Views).map(k => Views[k]);

    const userToken = localStorage.getItem('userToken');
	const [ loader, setLoader] = useState(true);
    
    useEffect(() => {		
        console.log(allViews);
        console.log(navigate);
        if ( userToken !== undefined ) {
			let header = {
                'Accept': "application/json",
                'Content-Type' : "application/json",
                'Authorization' : "bearer " + userToken,
			};
			console.log(allViews);
			axios.defaults.headers.common = header;    
			
			// GET LIST LABEL
            axios
                .get(URL_API + `/swd/master-period/${item['id']}`)
                .then((response) => {
                    let responseData = response.data.data;
                    setItem( responseData );
                    setLoader(false);
                    refreshNavigationButtonStatus();
                    // generateCalenderView('weekly');
                    console.log("Response Original GET VIEW DETAIL: ", response);
                })
                .catch((error) => {
                    setLoader(false);
                    refreshNavigationButtonStatus();
                    console.log("Error : ", error.response)
				});
		}
		
    },[]);

    const [ currentDateNavigation, setCurrentDateNavigation ] = useState(moment());
    const [ calendarKey, setCalendarKey ] = useState( Math.floor((Math.random() * 1000000) + 1) );
    /**
     * Handling click next
     * 
     * @param {object} evt event object.
     * @return {void}
     * 
     */
    const [ isEnableClickPrev, setIsEnableClickPrev ] = useState(true);
    const handleClickPrev = (evt) => {
        setCurrentDateNavigation( currentDateNavigation.subtract('1', 'M') );
        setCalendarKey( Math.floor((Math.random() * 1000000) + 1) );
        console.log("prev");
        console.log(currentDateNavigation.format( getDateFormat() ));
        refreshNavigationButtonStatus();
    }
    
    /**
     * Handling click next
     * 
     * @param {object} evt event object.
     * @return {void}
     * 
     */
    const [ isEnableClickNext, setIsEnableClickNext ] = useState(true);
    const handleClickNext = (evt) => {
        setCurrentDateNavigation( currentDateNavigation.add('1', 'M') );
        setCalendarKey( Math.floor((Math.random() * 1000000) + 1) );
        refreshNavigationButtonStatus();
    } 
    
    /**
     * Handling click today navigation
     * 
     * @param {object} evt event object
     * @return {void}
     * 
     */
    const handleClickTodayNavigation = (evt) => {
        setCalendarKey( Math.floor((Math.random() * 1000000) + 1) );
        setCurrentDateNavigation( moment() );
        refreshNavigationButtonStatus();
    }

    /**
     * Handling status of navigation button
     * 
     *  @return {void}
     * 
     */
    const refreshNavigationButtonStatus = () => {
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                {
                    loader === true && (
                        <Grid container style={{ marginTop: '100px', marginLeft: '50px' }}>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={6} md={3}>
                                <CircularProgress />
                            </Grid>
                            <Grid item xs={3}></Grid>
                        </Grid>
                    )
                }
                {
                    loader === false && (
                        <div>
                            <Grid container>
                                <Grid item xs={12}>&nbsp;</Grid>
                            </Grid>
                            <Grid container>

                                <Grid item xs={12} md={5}>
                                    
                                    <Typography variant="h6" className={classes.title}>
                                        { item.name }
                                    </Typography>
                                    <Typography className={classes.subTitle}>
                                        { moment(item.start_date).format( getDateFormat() ) + ' - ' + moment(item.end_date).format( getDateFormat() ) }
                                    </Typography>
                                </Grid>

                                

                                <Grid item xs={12} md={7} style={{textAlign: 'right', marginTop: '5px' }}>	
                                
                                <ButtonBase 
                                        size="medium"
                                        style={{ height: '48px', minWidth: '100px' }}
                                    >
                                    { currentDateNavigation.format( getDateFormat() ) }
                                    </ButtonBase>
                                    &nbsp;
                                    <ButtonBase 
                                        size="medium"
                                        style={{ height: '48px' }}
                                        onClick={ handleClickPrev }
                                        disabled={ !isEnableClickPrev }
                                    >
                                        <NavigateBeforeIcon />
                                    </ButtonBase>
                                    &nbsp;      
                                    <ButtonBase 
                                        size="medium"
                                        style={{ height: '48px' }}
                                        onClick={ handleClickNext }
                                        disabled={ !isEnableClickNext }
                                    >
                                        <NavigateNextIcon />
                                    </ButtonBase>
                                    &nbsp;
                                    <Button 
                                        
                                        variant="outlined"
                                        className={classes.title}
                                        style={{ 
                                            backgroundCOlor: 'rgba(0,0,0,0)',
                                            border: '1px rgba(0,0,0,0.2) solid',
                                            textTransform: 'capitalize'
                                        }}
                                        onClick={ handleClickTodayNavigation }
                                    >
                                        Hari ini
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        onClick={ handleOpenCreateForm }
                                    >
                                        Tambah Periode
                                    </Button>
                                </Grid>
                            </Grid>

				            <Divider light />

                            <Grid container>
                                <Grid item xs={12}>&nbsp;</Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={12} style={{ 'height': '400px' }}>
                                    <Calendar key={ calendarKey }
                                        selectable
                                        style={{ height: 500, width: '100%' }}
                                        toolbar={false}
                                        events={[
                                            {
                                                title: item.name,
                                                start: moment(item.start_date).toDate(),
                                                end: moment(item.end_date).toDate(),
                                                allDay: true,
                                                resource: [],
                                            }
                                        ]}
                                        step={60}
                                        views={allViews}
                                        view={"month"}
                                        onView={() => {}}
                                        date={ currentDateNavigation.toDate() }
                                        localizer={ momentLocalizer(moment) }
                                        onNavigate={ () => {} }
                                        startAccessor="start"
                                        endAccessor="end"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            </Grid>
        </Grid>
    );
}

export default DetailPeriod;
import React from "react";
import { Typography, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";
import { styles } from "../Style/StyleMA";

const useStyles = makeStyles(styles);

const PeriodListMenu = (props) => {
  const {
    periodCollections,
    anchorElPeriod,
    handleClosePeriod,
    handlePilihPeriod,
  } = props;

  const classes = useStyles();

  return (
    <StyledMenu
      id="customized-menu-period"
      anchorEl={anchorElPeriod}
      keepMounted
      open={Boolean(anchorElPeriod)}
      onClose={handleClosePeriod}
      style={{ marginTop: "-160px" }}>
      {periodCollections !== undefined && periodCollections.length > 0 ? (
        periodCollections.map((item, i) => {
          return (
            <StyledMenuItem key={i} onClick={(e) => handlePilihPeriod(e, item)}>
              <ListItemText
                primary={
                  <Typography variant="subtitle2" className={classes.title}>
                    <b>{item.name}</b>
                  </Typography>
                }
              />
            </StyledMenuItem>
          );
        })
      ) : (
        <StyledMenuItem disabled>
          <ListItemText
            primary={
              <Typography variant="subtitle2" className={classes.title}>
                <b>Tidak ada periode yang bisa di pilih !</b>
              </Typography>
            }
          />
        </StyledMenuItem>
      )}
    </StyledMenu>
  );
};

export default PeriodListMenu;

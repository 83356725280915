import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#d1354a", //#cc0707, #c62828
      light: "#fff",
      dark: "#fff",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Roboto"].join(","),
  },
});

export const styles = () => ({
  root: {
    width: "100%",
    marginTop: 1 * 3,
    background: "#fff",
    height: "200vh",
  },
  layoutMarginLeftAndTop: {
    marginLeft: 95,
    marginTop: 95,
  },
  layoutMarginLeft: {
    marginLeft: 95,
  },
  // Typogrtaphy
  title: {
    // fontFamily: "Roboto",
  },
  title333: {
    // fontFamily: "Roboto",
    color: "#333",
  },
  title555: {
    // fontFamily: "Roboto",
    color: "#555",
  },
  title888: {
    // fontFamily: "Roboto",
    color: "#888",
  },
  color888: {
    color: "#888",
  },
  btnNav: {
    textTransform: "capitalize",
    color: "#d64253",
    fontSize: 14,
    borderRadius: 30,
    border: "1px solid #d64253",
    "&:hover": {
      color: "#DE6775",
      cursor: "pointer",
    },
  },
  btnDone: {
    textTransform: "capitalize",
    color: "#27ae60",
    fontSize: 14,
    borderRadius: 30,
    border: "1px solid #27ae60",
    "&:hover": {
      color: "#2ecc71",
      cursor: "pointer",
    },
  },
  btnDrop: {
    textTransform: "capitalize",
    padding: `3px 6px`,
    color: "#fff",
    backgroundColor: "#d64253",
    "&:hover": {
      backgroundColor: "#DE6775",
    },
  },
  button1: {
    textTransform: "capitalize",
    fontSize: 14,
    padding: `3px 20px`,
    color: "#fff",
    backgroundColor: "#d64253",
    "&:hover": {
      backgroundColor: "#DE6775",
    },
  },
  button2: {
    textTransform: "capitalize",
    fontSize: 14,
    padding: `3px`,
    color: "#fff",
    backgroundColor: "#d64253",
    "&:hover": {
      backgroundColor: "#DE6775",
    },
  },

  // =============================
  mainArea: {
    backgroundColor: "#F9F9F9",
    boxShadow: `inset 7px 7px 10px -10px rgba(0,0,0,0.3)`,
    height: "150%",
    paddingBottom: 100,
  },
});

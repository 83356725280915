import React, { useState, useReducer, useEffect, useRef } from 'react';
import axios from 'axios';
import { URL_API } from '../../../constants/config-api';

import ContextNewSO from './ContextNewSO';

const ContextGlobalNewSO = props => {

    /*
        ````````````````
        USER TOKEN STATE

        ````````````````
    */
    const [ userTokenState, setUserTokenState ] = useState('');
    
    useEffect(() => {

        const userToken = localStorage.getItem('userToken');
        setUserTokenState(userToken);
    },[])

    /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */
    const [ isSnackbarResponse200, setSnackbarResponse200 ] = useState(false);
    const [ messages, setMessages ] = useState('');

    /*
        ```````````````````
        LIST TREE VIEW DATA

        ```````````````````
    */
    const [ treeExisting, setTreeExisting ] = useState([]);

    const [ treeItems, setTreeItems ] = useState([

        {

            id: null,
            parent_id: null,
            structure_position_title_id: null,
            structure_position_title_name : null,

            structure_unit_id: null,
            structure_unit_name: null,

            structure_unit_type_id: null,
            structure_unit_type_name: null,
            
            member_first_name: null,
            member_last_name: null,
            member_id: null,
            self: null,

            classification: {

                id: null,
                code: null
            },
            classification_id: null,

            child: [],
            
            first_name: null,
            last_name: null,
            email: null
        
        }
    ]);

    /*
        ```````````````````````
        HANDLE CHILD SUBORDINAT

        ```````````````````````
    */
    // const [ childSubordinat, setChildSubordinat ] = useState({
    //     child: []
    // });

    /*
        ``````````````````````````````````````````````````````
        HANDLE LIST TREE VIEW DATA AFTER CLICK ICON SUBORDINAT

        ```````````````````````````````````````````````````````
    */

    const [ isShowBackButton, setShowBackButton ] = useState(false);

    function taskRecursive(list){//*This function is called inside Recursive component

        console.log("From function recursion : ", list)

        if(list !== null){
            if(list !== undefined){

                if(list.length > 0){    
                    list.map((item, i) => {

                        //*
                        if(item.descendant_count === 0){

                            console.log("Got it ");
                            return
                        };
                    
                        //*
                        let dataChilSubordinat = [];

                        if(item.id === dataDetail.id && item.descendant_count !== 0){

                            let objectData = {
                                child : treeSubordinat,
                                ...item //*Sementara tak kepake dulu ya, next insya Allah pake ini
                            };
                            
                            // console.log("Test objectData : ", objectData);
                            dataChilSubordinat.push(objectData);

                            setTreeItems(dataChilSubordinat);

                            setShowBackButton(true);
                            
                            return taskRecursive(objectData.child)

                        } else {

                            if(item.child !== undefined){

                                console.log("subordinat inside test item : ", dataChilSubordinat)
    
                                return taskRecursive(item.child)
                            };

                        };

                        // return taskRecursive(item.child) // Default return 
                    });
                }
            }
        };
   };

    const [ treeSubordinat, setTreeSubordinat ] = useState([]);

    useEffect(() => {

        // console.log("Got it !");
        // console.log("treeExisting inside subordinat : ", treeExisting);

        taskRecursive(treeExisting)

    },[treeSubordinat])

    /*
        ````````````````````````````````````
        STATE AROUND FEATURE DIALOG CREATE :

            - TAMBAH HEAD,

            - TAMBAH DEPUTY HEAD,

            - TAMBAH ASSISTANT,

            - TAMBAH STAFF

            - isSuccessTambahHEAD,

            - isSuccessTambahDeputyHEAD,

            - isSuccessTambahASSISTANT,

            - isSuccessTambahSTAFF

        ```````````````````````````````````
    */

    const [ isOpenDialogTambahHead, setOpenDialogTambahHead ] = useState(false);

    const [ isOpenDialogTambahDeputyHead, setOpenDialogTambahDeputyHead ] = useState(false);

    const [ isOpenDialogTambahAssistant, setOpenDialogTambahAssistant ] = useState(false); 

    const [ isOpenDialogTambahStaff, setOpenDialogTambahStaff ] = useState(false);

    const [ isSuccessTambahHEAD, setSuccessTambahHEAD ] = useState(false);

    const [ isSuccessTambahDeputyHEAD, setSuccessTambahDeputyHEAD ] = useState(false);

    const [ isSuccessTambahASSISTANT, setSuccessTambahASSISTANT ] = useState(false);

    const [ isSuccessTambahSTAFF, setSuccessTambahSTAFF ] = useState(false);

    /*
        ````````````````````````````````````````````````````````
        STATE AROUND FEATURE DIALOG CREATE POSISI/ NAMA JABATAN 

            - IN TAMBAH HEAD

            - IN DEPUTY HEAD 

            - IN ASSISTANT

            - IN STAFF

            - IN EDIT NODE SO HEAD

            - IN EDIT NODE ROOT TOP

        ````````````````````````````````````````````````````````
    */
    const [ isOpenDialogCreatePositionTitle, setOpenDialogCreatePositionTitle ] = useState(false);
    const [ isOpenDialogCreatePositionTitleInDeputyHEAD, setOpenDialogCreatePositionTitleInDeputyHEAD ] = useState(false);
    const [ isOpenDialogCreatePositionTitleInASSISTANT, setOpenDialogCreatePositionTitleInASSISTANT ] = useState(false);
    const [ isOpenDialogCreatePositionTitleInSTAFF, setOpenDialogCreatePositionTitleInSTAFF ] = useState(false);

    const [ isOpenDialogCreatePositionTitleInEditNodeSoHEAD, setOpenDialogCreatePositionTitleInEditNodeSoHEAD ] = useState(false);
    const [ isOpenDialogCreatePositionTitleInEditRootTop, setOpenDialogCreatePositionTitleInEditRootTop ] = useState(false);


    /*
        ````````````````````````````````````````````````````````
        STATE pernak- pernik Create Position Title/ Nama Jabatan

        ````````````````````````````````````````````````````````
    */
    const [ positionTitleName, setPositionTitleName ] = useState('');
    const [ positionTitleId, setPositionTitleId ] = useState(''); 
    const [ isPositionTitleChoosed, setPositionTitleChoose ] = useState(false); 
    const [ isSuccessCreatePositionTitle, setSuccessCreatePositionTitle ] = useState(false);

    /*
        ```````````````````````````````````````````````````````
        STATE AROUND FEATURE DIALOG EDIT POSITION TITLE/ NAMA JABATAN

        ```````````````````````````````````````````````````````
    */
    const [ isOpenDialogEditPositionTitle, setOpenDialogEditPositionTitle ] = useState(false);
    const [ isOpenDialogEditPositionTitleInTambahASSISTANT, setOpenDialogEditPositionTitleInTambahASSISTANT ] = useState(false);

    const [ isSuccessEditPositionTitle, setSuccessEditPositionTitle ] = useState(false);


    /*
        `````````````````````````````````````````````````````````
        STATE AROUND FEATURE DIALOG DELETE POSITION/ NAMA JABATAN

        `````````````````````````````````````````````````````````
    */
    const [ isOpenDialogDeletePositionTitle,setOpenDialogDeletePositionTitle ] = useState(false);
    const [ isSuccessDeletePositionTitle, setSuccessDeletePositionTitle ] = useState(false); 

    /*
        ``````````````````````````````````````````````````
        STATE AROUND FEATURE DIALOG DELETE MEMBER EMPLOYEE

            - IN HEAD

            - IN ASSISTANT

        ``````````````````````````````````````````````````
    */
    const [ isOpenDialogDeleteEmployeeMember,setOpenDialogDeleteEmployeeMember ] = useState(false);
    // const [ isOpenDialogDeleteEmployeeMemberInASSISTANT,setOpenDialogDeleteEmployeeMemberInASSISTANT ] = useState(false); //*Ga usah pake, karena udah ALL IN ONE COMPONENT
    const [ isSuccessDeleteEmployeeMember, setSuccessDeleteEmployeeMember ] = useState(false); 

    /*
        ````````````````````````````````````````````````
        STATE AROUND FEATURE DIALOG EDIT MEMBER EMPLOYEE

            - In Tambah HEAD

            - In Edit Root TOP

            - In Edit HEAD

            - In Edit Deputy HEAD

        ````````````````````````````````````````````````
    */
    const [ isOpenDialogEditMemberEmployee, setOpenDialogEditMemberEmployee ] = useState(false);
    const [ isOpenDialogEditMemberEmployeeInEditRootTOP, setOpenDialogEditMemberEmployeeInEditRootTOP ] = useState(false);
    const [ isOpenDialogEditMemberEmployeeInEditHEAD, setOpenDialogEditMemberEmployeeInEditHEAD ] = useState(false);
    const [ isOpenDialogEditMemberEmployeeInEditDeputyHEAD, setOpenDialogEditMemberEmployeeInEditDeputyHEAD ] = useState(false);
    const [ isOpenDialogEditMemberEmployeeInEditASSISTANT, setOpenDialogEditMemberEmployeeInEditASSISTANT ] = useState(false);
    const [ isOpenDialogEditMemberEmployeeInEditSTAFF, setOpenDialogEditMemberEmployeeInEditSTAFF ] = useState(false);

    const [ isSuccessEditMemberEmployee, setSuccessEditMemberEmployee ] = useState(false);

    /*
        ``````````````````````````````````````````````
        STATE AROUND FEATURE DIALOG CREATE JENIS UNIT 

        ``````````````````````````````````````````````
    */
    const [ isOpenDialogCreateJenisUnit, setOpenDialogCreateJenisUnit ] = useState(false);
    const [ isSuccessCreateJenisUnit, setSuccessCreateJenisUnit ] = useState(false);

    const [ valueJenisUnitName, setValueJenisUnitName ] = useState('');
    const [ namaJenisUnitChoosed, setNamaJenisUnitChoosed] = useState('');

    /*
        ```````````````````````````````````````````
        STATE AROUND FEATURE DIALOG EDIT JENIS UNIT

        ```````````````````````````````````````````
    */
    const [ isOpenDialogEditJenisUnit, setOpenDialogEditJenisUnit ] = useState(false);
    const [ isSuccessEditJenisUnit, setSuccessEditJenisUnit ] = useState(false);

    /*
        ``````````````````````````````````````````
        STATE AROUND FEATURE DIALOG EDIT NAMA UNIT

        ``````````````````````````````````````````
    */
    const [ isOpenDialogEditNamaUnit, setOpenDialogEditNamaUnit ] = useState(false);
    const [ isSuccessEditNamaUnit, setSuccessEditNamaUnit ] = useState(false);


    /*  */
    const [ isOpenDialogCreateJenisUnitInEditHEAD, setOpenDialogCreateJenisUnitInEditHEAD ] = useState(false);
    const [ isOpenDialogCreateNamaUnitInEditHEAD, setOpenDialogCreateNamaUnitInEditHEAD ]= useState(false);
                
    /*
        ````````````````````````````````````````````
        STATE AROUND FEATURE DIALOG CREATE NAMA UNIT

        ````````````````````````````````````````````
    */
    const [ isJenisUnitChoosed, setJenisUnitChoosed] = useState(false);
    const [ structureUnitTypeId, setStructureUnitTypeId ] = useState('');

    const [ isOpenDialogCreateNamaUnit, setOpenDialogCreateNamaUnit ] = useState(false);
    const [ isNamaUnitChoosed, setNamaUnitChoosedNow] = useState(false);
    const [ namaUnitChoosed, setNamaUnitChoosed] = useState('');
    const [ structureUnitId, setStructureUnitId ] = useState('');
    const [ isSuccessCreateNamaUnit, setSuccessCreateNamaUnit ] = useState(false);

    /*
        `````````````````````````````````````````````````````````
        STATE AROUND FEATURE DELETE NODE OF SO :

            - IN ALL (ROOT, HEAD, DEPUTY-HEAD, ASSISTANT, STAFF )

        `````````````````````````````````````````````````````````
    */
    const [ isOpenDialogDeleteNodeSO, setOpenDialogDeleteNodeSO ] = useState(false);
    const [ isSuccessDeleteNodeSO, setSuccessDeleteNodeSO ] = useState(false);

    /*
        ``````````````````````````````````````
        STATE AROUND FEATURE EDIT NODE OF SO :
        
            - IN HEAD 
        
            - IN ROOT TOP LEADER
            
            - IN STAFF

        ``````````````````````````````````````
    */
    const [ isOpenDialogEditNodeSOInRootTop, setOpenDialogEditNodeSOInRootTop ] = useState(false);
    const [ isOpenDialogEditNodeSO, setOpenDialogEditNodeSO ] = useState(false);//*In HEAD
    const [ isOpenDialogEditNodeSOInDeputyHEAD, setOpenDialogEditNodeSOInDeputyHEAD ] = useState(false);
    const [ isOpenDialogEditNodeSOInASSISTANT, setOpenDialogEditNodeSOInASSISTANT ] = useState(false);
    const [ isOpenDialogEditNodeSOInSTAFF, setOpenDialogEditNodeSOInSTAFF ] = useState(false);


    const [ isSuccessEditNodeSOInSTAFF, setSuccessEditNodeSOInSTAFF ] = useState(false);
    const [ isSuccessEditNodeSOInHEAD, setSuccessEditNodeSOInHEAD ] = useState(false);//*In HEAD


       
    /*
        ````````````````````````````````````````````````````
        STATE AROUNF FEATURE CREATE KARYAWAN/ MEMBER 
        
            - IN HEAD

            - IN DEPUTY HEAD,

            - IN ASSISTANT,

            - IN EDIT ROOT TOP,

            - IN EDIT HEAD

            - IN EDIT DEPUTY HEAD

            - IN EDIT ASSISTANT

            - in EDIT STAFF



        ````````````````````````````````````````````````````
    */
    const [ isOpenDialogCreateEmployee, setOpenDialogCreateEmployee ] = useState(false)

    const [ isOpenDialogCreateEmployeeInDeputyHEAD, setOpenDialogCreateEmployeeInDeputyHEAD ] = useState(false)

    const [ isOpenDialogCreateEmployeeInASSISTANT, setOpenDialogCreateEmployeeInASSISTANT ] = useState(false)
    
    const [ isOpenDialogCreateEmployeeInEditRootTOP, setOpenDialogCreateEmployeeInEditRootTOP ] = useState(false);

    const [ isOpenDialogCreateEmployeeInEditHEAD, setOpenDialogCreateEmployeeInEditHEAD ] = useState(false);

    const [ isOpenDialogCreateEmployeeInEditASSISTANT, setOpenDialogCreateEmployeeInEditASSISTANT ] = useState(false);

    const [ isOpenDialogCreateEmployeeInEditSTAFF, setOpenDialogCreateEmployeeInEditSTAFF ] = useState(false);


    const [ isOpenDialogCreateEmployeeInEditDeputyHEAD, setOpenDialogCreateEmployeeInEditDeputyHEAD ] = useState(false);




    /*
        ````````````````````````````````````````````
        STATE pernak- pernik Create KARYAWAN/ MEMBER

        ````````````````````````````````````````````
    */
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ employeeId, setEmployeeId ] = useState('');
    const [ isSucessCreateEmployee, setSuccessCreateEmployee ] = useState(false);

    /*
        ``````````````````````````````
        WEW, untuk berurusan dengan SO

        ``````````````````````````````    
    */
    const [ dataDetailIconMoreHorizon, setDataDetailIconMoreHorizon ] = useState({

        id: null,
        member_id: null,
        structure_position_title_id : null,
        structure_unit_name: null,
        structure_unit_type_name: null,
        member_first_name: null,
        member_last_name: null,
        member_id: null
        
    })

    /*
        ````````````````````````````````````````````
        WEW, untuk berurusan dengan Member/ Karyawan

        ````````````````````````````````````````````
    */
    const [ dataDetail, setDataDetail ] = useState(

        {
            id: null,
            parent_id:null,
            classification : {
                id: null,
                code: null
            },
            member_first_name: null,
            member_last_name: null,

            structure_unit_type_id: null,
            structure_unit_type_name: null,

            structure_unit_id: null,
            structure_unit_name: null,

            structure_position_title_id : null,
            structure_position_title_name : null,
            child: []               

        }
    );

    return (

        <ContextNewSO.Provider

            value={{

                userTokenState : userTokenState,
                setUserTokenState : setUserTokenState,

                setTreeExisting : setTreeExisting,
                treeExisting : treeExisting,
 
                /*
                    ````````````````
                    HANDLE SNACKBEER

                    ````````````````
                */
               setSnackbarResponse200 : setSnackbarResponse200,
               isSnackbarResponse200 : isSnackbarResponse200,

               setMessages : setMessages,
               messages : messages, 

               /*   
                    ``````````````````
                    HANDLE BACK BUTTON

                    ``````````````````
               */
                isShowBackButton : isShowBackButton,
                setShowBackButton : setShowBackButton,
               
                /*
                    ```````````````````````````````````````

                        FEATURE DIALOG TAMBAH HEAD || Create Position Title,

                        FEATURE DIALOG TAMBAH DEPUTY HEAD,

                        FEATURE DIALOG TAMBAH ASSISTANT,

                        FEATURE DIALOG TAMBAH STAFF,

                        State ini ada di file : 

                            - DialogTambahHEad.js,

                            - DataTreeView.js,

                            - DialogCreatePositionTitle.js,

                    ```````````````````````````````````````
                */

                setTreeItems: setTreeItems,
                treeItems: treeItems,

                setTreeSubordinat : setTreeSubordinat,
                treeSubordinat : treeSubordinat,

                setOpenDialogTambahHead : setOpenDialogTambahHead,
                isOpenDialogTambahHead: isOpenDialogTambahHead,

                setOpenDialogTambahDeputyHead: setOpenDialogTambahDeputyHead,
                isOpenDialogTambahDeputyHead : isOpenDialogTambahDeputyHead,

                setOpenDialogTambahAssistant : setOpenDialogTambahAssistant,
                isOpenDialogTambahAssistant : isOpenDialogTambahAssistant,

                setOpenDialogTambahStaff: setOpenDialogTambahStaff,
                isOpenDialogTambahStaff : isOpenDialogTambahStaff,

                setSuccessTambahHEAD: setSuccessTambahHEAD,
                isSuccessTambahHEAD :  isSuccessTambahHEAD,

                setSuccessTambahDeputyHEAD: setSuccessTambahDeputyHEAD,
                isSuccessTambahDeputyHEAD : isSuccessTambahDeputyHEAD,

                setSuccessTambahASSISTANT: setSuccessTambahASSISTANT,
                isSuccessTambahASSISTANT : isSuccessTambahASSISTANT,

                setSuccessTambahSTAFF: setSuccessTambahSTAFF,
                isSuccessTambahSTAFF : isSuccessTambahSTAFF,

                setOpenDialogCreatePositionTitle : setOpenDialogCreatePositionTitle,
                isOpenDialogCreatePositionTitle : isOpenDialogCreatePositionTitle,

                setOpenDialogCreatePositionTitleInDeputyHEAD: setOpenDialogCreatePositionTitleInDeputyHEAD,
                isOpenDialogCreatePositionTitleInDeputyHEAD : isOpenDialogCreatePositionTitleInDeputyHEAD,

                setOpenDialogCreatePositionTitleInASSISTANT : setOpenDialogCreatePositionTitleInASSISTANT,
                isOpenDialogCreatePositionTitleInASSISTANT : isOpenDialogCreatePositionTitleInASSISTANT, 

                setOpenDialogCreatePositionTitleInSTAFF: setOpenDialogCreatePositionTitleInSTAFF,
                isOpenDialogCreatePositionTitleInSTAFF : isOpenDialogCreatePositionTitleInSTAFF,

                /*
                    ``````````````````````````````````````````````
                        FEATURE EDIT NODE OF SO IN ROOT TOP LEADER

                    ``````````````````````````````````````````````
                */

               setOpenDialogEditNodeSOInRootTop: setOpenDialogEditNodeSOInRootTop,
               isOpenDialogEditNodeSOInRootTop : isOpenDialogEditNodeSOInRootTop,

               setOpenDialogCreatePositionTitleInEditRootTop : setOpenDialogCreatePositionTitleInEditRootTop ,
               isOpenDialogCreatePositionTitleInEditRootTop : isOpenDialogCreatePositionTitleInEditRootTop,



                setOpenDialogCreatePositionTitleInEditNodeSoHEAD : setOpenDialogCreatePositionTitleInEditNodeSoHEAD,
                isOpenDialogCreatePositionTitleInEditNodeSoHEAD : isOpenDialogCreatePositionTitleInEditNodeSoHEAD,

                setPositionTitleName : setPositionTitleName,
                positionTitleName : positionTitleName,

                setPositionTitleId : setPositionTitleId,
                positionTitleId : positionTitleId,

                setPositionTitleChoose : setPositionTitleChoose,
                isPositionTitleChoosed :  isPositionTitleChoosed,

                setSuccessCreatePositionTitle : setSuccessCreatePositionTitle,
                isSuccessCreatePositionTitle : isSuccessCreatePositionTitle,

                // setOpenDialogDeletePositionTitle : setOpenDialogDeletePositionTitle,
                // isOpenDialogDeletePositionTitle : isOpenDialogDeletePositionTitle,

                setDataDetail : setDataDetail,
                dataDetail : dataDetail,

                setDataDetailIconMoreHorizon : setDataDetailIconMoreHorizon,
                dataDetailIconMoreHorizon : dataDetailIconMoreHorizon,

                /*
                    ``````````````````````````````````
                    FEATURE DELETE NODE OF SO IN HEAD, 

                    State ini ada di file : 

                        - DialogTambahHEad.js,

                        - DialogDeleteNodeSO.js

                        - DataTreeView.js,

                    ``````````````````````````````````
                */

                setOpenDialogDeleteNodeSO : setOpenDialogDeleteNodeSO,
                isOpenDialogDeleteNodeSO : isOpenDialogDeleteNodeSO,

                setSuccessDeleteNodeSO : setSuccessDeleteNodeSO,
                isSuccessDeleteNodeSO : isSuccessDeleteNodeSO,

                /*
                    ```````````````````````````````````````````````
                    FEATURE CREATE, DELETE, EDIT  KARYAWAN/ MEMBER,

                    - IN HEAD,

                    - IN DEPUTY HEAD

                    State ini ada di file : 

                        - DialogTambahHEad.js,

                        - DialogCreateEmployee.js

                    ````````````````````````````````````````````````
                */
               setOpenDialogCreateEmployee : setOpenDialogCreateEmployee,
               isOpenDialogCreateEmployee :  isOpenDialogCreateEmployee,

               setOpenDialogCreateEmployeeInDeputyHEAD : setOpenDialogCreateEmployeeInDeputyHEAD,
               isOpenDialogCreateEmployeeInDeputyHEAD : isOpenDialogCreateEmployeeInDeputyHEAD,

               setOpenDialogCreateEmployeeInASSISTANT: setOpenDialogCreateEmployeeInASSISTANT,
               isOpenDialogCreateEmployeeInASSISTANT : isOpenDialogCreateEmployeeInASSISTANT,

               setOpenDialogCreateEmployeeInEditRootTOP : setOpenDialogCreateEmployeeInEditRootTOP,
               isOpenDialogCreateEmployeeInEditRootTOP : isOpenDialogCreateEmployeeInEditRootTOP,

               setOpenDialogCreateEmployeeInEditHEAD : setOpenDialogCreateEmployeeInEditHEAD,
               isOpenDialogCreateEmployeeInEditHEAD : isOpenDialogCreateEmployeeInEditHEAD,

               setOpenDialogCreateEmployeeInEditASSISTANT : setOpenDialogCreateEmployeeInEditASSISTANT,
               isOpenDialogCreateEmployeeInEditASSISTANT : isOpenDialogCreateEmployeeInEditASSISTANT ,

               
               setOpenDialogCreateEmployeeInEditDeputyHEAD : setOpenDialogCreateEmployeeInEditDeputyHEAD,
               isOpenDialogCreateEmployeeInEditDeputyHEAD : isOpenDialogCreateEmployeeInEditDeputyHEAD,
               
               setOpenDialogCreateEmployeeInEditSTAFF : setOpenDialogCreateEmployeeInEditSTAFF,
               isOpenDialogCreateEmployeeInEditSTAFF : isOpenDialogCreateEmployeeInEditSTAFF,

               setFirstName: setFirstName,
               firstName : firstName,

               setLastName : setLastName,
               lastName : lastName,

               setEmail : setEmail,
               email : email ,
               
               setEmployeeId : setEmployeeId,
               employeeId: employeeId,  

               setSuccessCreateEmployee: setSuccessCreateEmployee,
               isSucessCreateEmployee:  isSucessCreateEmployee,

               setOpenDialogDeleteEmployeeMember: setOpenDialogDeleteEmployeeMember,
               isOpenDialogDeleteEmployeeMember: isOpenDialogDeleteEmployeeMember,

               setSuccessDeleteEmployeeMember : setSuccessDeleteEmployeeMember,
               isSuccessDeleteEmployeeMember: isSuccessDeleteEmployeeMember,


               //** 
               setOpenDialogEditMemberEmployee: setOpenDialogEditMemberEmployee,
               isOpenDialogEditMemberEmployee : isOpenDialogEditMemberEmployee,

               setOpenDialogEditMemberEmployeeInEditRootTOP : setOpenDialogEditMemberEmployeeInEditRootTOP,
               isOpenDialogEditMemberEmployeeInEditRootTOP :  isOpenDialogEditMemberEmployeeInEditRootTOP,

               setOpenDialogEditMemberEmployeeInEditHEAD : setOpenDialogEditMemberEmployeeInEditHEAD,
               isOpenDialogEditMemberEmployeeInEditHEAD : isOpenDialogEditMemberEmployeeInEditHEAD,




               setSuccessEditMemberEmployee : setSuccessEditMemberEmployee,
               isSuccessEditMemberEmployee : isSuccessEditMemberEmployee,

                /*

                    ``````````````````````````````````
                    FEATURE DIALOG CREATE, EDIT JENIS UNIT,

                    FEATURE DIALOG CREATE, EDIT NAMA UNIT,

                    FEATURE DIALOG EDIT, DELETE POSISI/ NAMA JABATAN 

                    State ini ada di file : 

                        - DialogTambahHEad.js,

                        - DataTreeView.js,

                        - DialogCreateJenisUnit.js

                    `````````````````````````````````
                */
               setOpenDialogEditJenisUnit : setOpenDialogEditJenisUnit,
               isOpenDialogEditJenisUnit : isOpenDialogEditJenisUnit,

               setOpenDialogEditNamaUnit : setOpenDialogEditNamaUnit,
               isOpenDialogEditNamaUnit : isOpenDialogEditNamaUnit,

               setSuccessEditJenisUnit : setSuccessEditJenisUnit,
               isSuccessEditJenisUnit : isSuccessEditJenisUnit,

               setSuccessEditNamaUnit : setSuccessEditNamaUnit,
               isSuccessEditNamaUnit : isSuccessEditNamaUnit,

               setOpenDialogEditPositionTitle : setOpenDialogEditPositionTitle,
               isOpenDialogEditPositionTitle: isOpenDialogEditPositionTitle,
               
               setOpenDialogEditPositionTitleInTambahASSISTANT : setOpenDialogEditPositionTitleInTambahASSISTANT,
               isOpenDialogEditPositionTitleInTambahASSISTANT: isOpenDialogEditPositionTitleInTambahASSISTANT,

               setSuccessEditPositionTitle : setSuccessEditPositionTitle,
               isSuccessEditPositionTitle: isSuccessEditPositionTitle,

               setOpenDialogDeletePositionTitle : setOpenDialogDeletePositionTitle,
               isOpenDialogDeletePositionTitle : isOpenDialogDeletePositionTitle,

               setSuccessDeletePositionTitle : setSuccessDeletePositionTitle,
               isSuccessDeletePositionTitle : isSuccessDeletePositionTitle,

               setOpenDialogCreateJenisUnit : setOpenDialogCreateJenisUnit,
               isOpenDialogCreateJenisUnit : isOpenDialogCreateJenisUnit,

               setSuccessCreateJenisUnit : setSuccessCreateJenisUnit,
               isSuccessCreateJenisUnit : isSuccessCreateJenisUnit,

               setValueJenisUnitName : setValueJenisUnitName,
               valueJenisUnitName : valueJenisUnitName,

               setJenisUnitChoosed : setJenisUnitChoosed,
               isJenisUnitChoosed : isJenisUnitChoosed,

               setNamaJenisUnitChoosed:setNamaJenisUnitChoosed,
               namaJenisUnitChoosed : namaJenisUnitChoosed,

               setOpenDialogCreateNamaUnit: setOpenDialogCreateNamaUnit,
               isOpenDialogCreateNamaUnit: isOpenDialogCreateNamaUnit,

               setNamaUnitChoosedNow : setNamaUnitChoosedNow,
               isNamaUnitChoosed : isNamaUnitChoosed,

               setNamaUnitChoosed: setNamaUnitChoosed,
               namaUnitChoosed : namaUnitChoosed,

               setStructureUnitId: setStructureUnitId,
               structureUnitId: structureUnitId,

               setStructureUnitTypeId : setStructureUnitTypeId,
               structureUnitTypeId : structureUnitTypeId,

               setSuccessCreateNamaUnit : setSuccessCreateNamaUnit,
               isSuccessCreateNamaUnit: isSuccessCreateNamaUnit,  


                /*
                    ````````````````````````````````````
                        FEATURE EDIT NODE OF SO IN HEAD,

                        State ini ada di file : 

                            - DialogTambahHEad.js,

                            - DataTreeView.js,

                            - DialogEditNodeSO.js  

                    ````````````````````````````````````
                */


               setOpenDialogCreateJenisUnitInEditHEAD : setOpenDialogCreateJenisUnitInEditHEAD,
               isOpenDialogCreateJenisUnitInEditHEAD : isOpenDialogCreateJenisUnitInEditHEAD,

               setOpenDialogCreateNamaUnitInEditHEAD : setOpenDialogCreateNamaUnitInEditHEAD,
               isOpenDialogCreateNamaUnitInEditHEAD : isOpenDialogCreateNamaUnitInEditHEAD,

               setOpenDialogEditMemberEmployeeInEditDeputyHEAD: setOpenDialogEditMemberEmployeeInEditDeputyHEAD,
               isOpenDialogEditMemberEmployeeInEditDeputyHEAD : isOpenDialogEditMemberEmployeeInEditDeputyHEAD,

               setOpenDialogEditMemberEmployeeInEditASSISTANT: setOpenDialogEditMemberEmployeeInEditASSISTANT,
               isOpenDialogEditMemberEmployeeInEditASSISTANT : isOpenDialogEditMemberEmployeeInEditASSISTANT,

               setOpenDialogEditMemberEmployeeInEditSTAFF : setOpenDialogEditMemberEmployeeInEditSTAFF,
               isOpenDialogEditMemberEmployeeInEditSTAFF : isOpenDialogEditMemberEmployeeInEditSTAFF,

                /*
                    ``````````````````````````````````````````
                        FEATURE EDIT NODE OF SO IN HEAD

                    ``````````````````````````````````````````
                */
                setOpenDialogEditNodeSO : setOpenDialogEditNodeSO,
                isOpenDialogEditNodeSO :  isOpenDialogEditNodeSO,

                setSuccessEditNodeSOInHEAD: setSuccessEditNodeSOInHEAD,
                isSuccessEditNodeSOInHEAD: isSuccessEditNodeSOInHEAD,

                /*
                    ``````````````````````````````````````````
                        FEATURE EDIT NODE OF SO IN DEPUTY HEAD

                    ``````````````````````````````````````````
                */
                setOpenDialogEditNodeSOInDeputyHEAD : setOpenDialogEditNodeSOInDeputyHEAD,
                isOpenDialogEditNodeSOInDeputyHEAD : isOpenDialogEditNodeSOInDeputyHEAD,

                /*
                    ````````````````````````````````````````
                        FEATURE EDIT NODE OF SO IN ASSISTANT

                    ````````````````````````````````````````
                */
                setOpenDialogEditNodeSOInASSISTANT: setOpenDialogEditNodeSOInASSISTANT,
                isOpenDialogEditNodeSOInASSISTANT : isOpenDialogEditNodeSOInASSISTANT,


                /*
                    `````````````````````````````````````
                        FEATURE EDIT NODE OF SO IN STAFF

                    `````````````````````````````````````
                */

                setOpenDialogEditNodeSOInSTAFF : setOpenDialogEditNodeSOInSTAFF,
                isOpenDialogEditNodeSOInSTAFF : isOpenDialogEditNodeSOInSTAFF,

                setSuccessEditNodeSOInSTAFF: setSuccessEditNodeSOInSTAFF,
                isSuccessEditNodeSOInSTAFF: isSuccessEditNodeSOInSTAFF


 
            }}
        >
            {props.children}

        </ContextNewSO.Provider>
    );
 
};

export default ContextGlobalNewSO;



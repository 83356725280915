import React, { useState, useContext, Fragment } from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TablePagination,
  TableHead,
  Button,
  Checkbox,
} from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

import { navigate } from "hookrouter";
import TablePaginationCustom from "../../Components/TablePaginationCustom";
import ContextGoal from "../../Context/ContextGoal";
import { styles } from "../../Style/StyleGoal";

import axiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

import PictEmptyStateGoal from "../../Components/PictEmptyStateGoal";
import ListCardItemGoal from "../../Components/ListCardItemGoal";
import { ToGoalDetail } from "../../../../../constants/config-redirect-url";
import DialogSetAsCompleteMultipleGoal from "../../Components/DialogSetAsCompleteMultipleGoal";
import DialogDeleteMultiple from "../../Components/DialogDeleteMultiple";
import PaginationGoal from "../../Components/PaginationGoal";

const useStyle = makeStyles(styles);

const ListStatusGoalOverdue = () => {
  const context = useContext(ContextGoal);
  const classes = useStyle();
  /*  
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */
  const [selected, setSelected] = useState([]);
  const [validGoal, setValidGoal] = useState([]);
  const [checkValidDelete, setCheckValidDelete] = useState([]);
  const [
    isOpenDialogSetAsCompleteMultiple,
    setOpenDialogSetAsCompleteMultiple,
  ] = useState(false);
  const [isOpenDialogDeleteMultiple, setOpenDialogDeleteMultiple] = useState(
    false
  );

  /*  
        ``````````````````
        HANDLE GOAL DETAIL

        ``````````````````
    */
  const handleDetail = (e, data) => {
    e.preventDefault();
    console.log(data);

    // localStorage.setItem("goal_detail", JSON.stringify(data));
    // Redirect(ToGoalDetail);
    // navigate(ToGoalDetail, false, { id: data.id });
  };

  const handleGetValidGoal = () => {
    return Promise.all(
      selected.length > 0 &&
        selected.map((item, index) => {
          const params = new URLSearchParams();
          params.append(`Goal[${index}][id]`, item);

          return axiosConfig.put(
            `${URL_API}/swd/goal/batch:completed/validate-only`,
            params
          );
        })
    )
      .then((response) => {
        // console.log("ORI Response SAC", response);
        setValidGoal(response);
        setOpenDialogSetAsCompleteMultiple(true);
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  };

  const handleGetValidGoalDelete = () => {
    return Promise.all(
      selected.length > 0 &&
        selected.map((item, index) => {
          const params = new URLSearchParams();
          params.append(`Goal[${index}][id]`, item);

          return axiosConfig.delete(
            `${URL_API}/swd/goal/batch?validateOnly=true`,
            { data: params }
          );
        })
    )
      .then((response) => {
        // console.log("ORI Response DEL", response);

        setCheckValidDelete(response);
        setOpenDialogDeleteMultiple(true);
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  };

  /*
      ````````````````````````````````````````````
      HANDLE PAGINATION //*Will be to global state

      ````````````````````````````````````````````
  */

  const handleChangePage = (event, newPage) => {
    context.setLoaderForPaging(true);
    context.setPage(newPage);
    context.setOffset((parseInt(newPage + 1) - 1) * context.rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log("Rows perpage : ", event.target.value);
    context.setLoaderForPaging(true);
    context.setRowsPerPage(parseInt(event.target.value, 10));
    context.setPage(0);
  };

  // Length Of Collection Goal
  const itemCount = context.collectionGoalList.length;

  /* =========================
    selected ID
    ============================= */
  const isSelected = (id) => selected.indexOf(id) !== -1;

  /* =========================
    selected All List Item
    ============================= */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = context.collectionGoalList.map((n) => n.id);
      setSelected(newSelecteds);
      // console.log("selAll", newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <Fragment>
      <Grid container style={{ backgroundColor: "transparent", marginTop: 40 }}>
        <Box
          style={{
            marginLeft: 42,
            marginTop: -36,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
          <Checkbox
            indeterminate={selected.length > 0 && selected.length < itemCount}
            checked={itemCount > 0 && selected.length === itemCount}
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
          <Typography
            color="inherit"
            variant="subtitle1"
            className={classes.title}>
            <b>{selected.length}</b> Goal ditandai
          </Typography>
        </Box>
        {selected.length > 0 && (
          <Fragment>
            <Button
              onClick={handleGetValidGoal}
              disableElevation
              variant="contained"
              className={classes.button4}
              startIcon={<CheckCircleRoundedIcon />}
              style={{ marginLeft: 24, marginTop: -31 }}>
              <b>Set as complete</b>
            </Button>

            <Button
              onClick={handleGetValidGoalDelete}
              disableElevation
              variant="contained"
              className={classes.button5}
              startIcon={<DeleteRoundedIcon />}
              style={{ marginLeft: 8, marginTop: -31 }}>
              <b>Delete Multiple</b>
            </Button>
          </Fragment>
        )}
        <Grid item xs={12}>
          {context.loader ? (
            <Grid
              container
              spacing={0}
              direction="row"
              justify="center"
              alignItems="center">
              <CircularProgress
                size={32}
                style={{ marginTop: 72, color: "red" }}
              />
            </Grid>
          ) : (
            <Table className={classes.table} aria-label="enhanced table">
              <TableHead></TableHead>
              <TableBody>
                {context.collectionGoalList.length > 0 &&
                  context.collectionGoalList.map((item, i) => {
                    return (
                      <ListCardItemGoal
                        key={i}
                        classes={classes}
                        item={item}
                        selected={selected}
                        setSelected={setSelected}
                        handleDetail={handleDetail}
                        userTokenState={context.userTokenStateGlobal}
                        isSelected={isSelected}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          )}

          {context.collectionGoalList.length == 0 && context.loader == false && (
            <Paper
              elevation={1}
              className={classes.paperList}
              style={{ marginTop: 8 }}>
              <Grid container>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{
                      color: "grey",
                      marginTop: 16,
                      marginBottom: 16,
                    }}>
                    <PictEmptyStateGoal />
                    <b>
                      Tidak ada <i> Overdue Goal</i> yang dapat di tampilkan
                      saat ini ;(
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          )}

          {context.collectionGoalList.length > 0 &&
            context.dataTabActive.id !== 2 && ( //*Bukan YOU maka muncul
              <PaginationGoal
                theme={theme}
                classes={classes}
                lengthGoalCount={context.lengthGoalCount}
                rowsPerPage={context.rowsPerPage}
                page={context.page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                loaderForPaging={context.loaderForPaging}
              />
            )}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Grid>
      </Grid>

      <DialogSetAsCompleteMultipleGoal
        classes={classes}
        isOpenDialogSetAsCompleteMultiple={isOpenDialogSetAsCompleteMultiple}
        setOpenDialogSetAsCompleteMultiple={setOpenDialogSetAsCompleteMultiple}
        selected={selected}
        validGoal={validGoal}
      />

      <DialogDeleteMultiple
        classes={classes}
        isOpenDialogDeleteMultiple={isOpenDialogDeleteMultiple}
        setOpenDialogDeleteMultiple={setOpenDialogDeleteMultiple}
        selected={selected}
        checkValidDelete={checkValidDelete}
      />
    </Fragment>
  );
};

export default ListStatusGoalOverdue;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  TextField,
  Button,
  InputAdornment,
  Grid,
  IconButton,
  Paper,
  Radio,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckIcon from "@material-ui/icons/Check";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import FlashOnIcon from "@material-ui/icons/FlashOn";

import {
  ACCOUNT_TYPE_BISNIS,
  ACCOUNT_TYPE_PERSONAL,
  PACKAGE_BISNIS_A,
  PACKAGE_BISNIS_B,
  PACKAGE_BISNIS_C,
  PACKAGE_PERSONAL,
  STATUS_NONE,
  STATUS_TRIAL,
  STATUS_ACTIVE,
  STATUS_LAPSED,
  STATUS_INACTIVE,
} from "../../../MembershipManagement/constant-data";

const DialogChoosePackage = ({
  classes,
  openDialogChoosePackage,
  setOpenDialogChoosePackage,
  packageCollections,
  selectedPackage,
  onChoosePackage,
  userLicense,
  licenseUser,
  pmoVisit,
  pmoTicket,
  setUserLicense,
  setPmoVisit,
  setChooseOrNot,
  setDeployment,
  // deployment,
}) => {
  const user = JSON.parse(localStorage.getItem("status_user_login"));
  const groupName = user.membership_account_type_name;
  const pckgName = user.membership_package_name;
  const levelName = user.membership_level_name;
  // const accTypeId = user.membership_account_type_id;
  const pckgId = user.membership_package_id;
  const statusIdMember = user.membership_status_id;
  let level_color;
  let status_member;
  let status_color;

  if (user.membership_status_id === STATUS_NONE) {
    status_member = "None";
    status_color = "#c0392b";
  } else if (user.membership_status_id === STATUS_TRIAL) {
    status_member = "Trial";
    status_color = "#ff9f43";
  } else if (user.membership_status_id === STATUS_ACTIVE) {
    status_member = "Active";
    status_color = "#2ecc71";
  } else if (user.membership_status_id === STATUS_LAPSED) {
    status_member = "Lapsed";
    status_color = "#7f8c8d";
  } else if (user.membership_status_id === STATUS_INACTIVE) {
    status_member = "Inactive";
    status_color = "#2c3e50";
  }

  if (levelName === "Basic") {
    level_color = "#cd6133";
  } else if (levelName === "Silver") {
    level_color = "#a5b1c2";
  } else if (levelName === "Gold") {
    level_color = "#f7b731";
  } else if (levelName === "Platinum") {
    level_color = "#84817a";
  }

  // ====================================================
  // State Colections
  // ====================================================
  const [detail, setDetail] = useState({
    account: groupName,
    packages: pckgName,
  });

  const [currentPckg, setCurrentPckg] = useState({
    id: "",
    name: "",
    trial_user_license_count: 0,
    trial_duration_value: null,
    trial_duration_unit: null,
    free_user_license_count: 0,
    package_level: 0,
    accountType: {
      id: "",
      name: "",
    },
    membershipPackageItem: [],
  });

  // ====================================================
  // Side Effects
  // ====================================================
  useEffect(() => {
    if (openDialogChoosePackage) {
      const paketMembership = packageCollections.filter(
        (item) => item.id === pckgId
      );

      // console.log("PMO tiktik", pmoTicket);

      onChoosePackage(paketMembership[0]);
      setCurrentPckg(paketMembership[0]);
      setPmoVisit(pmoTicket);
      setUserLicense(licenseUser);
      setDetail({ account: groupName, packages: pckgName });
    }
  }, [openDialogChoosePackage]);

  // ====================================================
  // Func/Method Colections
  // ====================================================
  const handleCancel = () => {
    // let noPackage = {};
    setChooseOrNot(false);
    onChoosePackage(null);
    setUserLicense(0);
    setPmoVisit(0);
    setDeployment(0);
    setOpenDialogChoosePackage(false);
  };

  const handleChoosePackage = () => {
    setChooseOrNot(true);
    setOpenDialogChoosePackage(false);
  };

  const handleUserLicense = (value) => {
    switch (currentPckg.accountType.id) {
      case ACCOUNT_TYPE_BISNIS:
        if (value >= 25 && value <= 500) {
          setUserLicense(value);
        } else if (value > 500) {
          setUserLicense(500);
        } else if (value < 25) {
          setUserLicense(25);
        }
        setUserLicense(value);
        break;
      case ACCOUNT_TYPE_PERSONAL:
        setUserLicense(value);
        break;
      default:
        setUserLicense(value);
    }
  };

  const handlePmoVisit = (val) => {
    const value = Number(val);

    if (value === 0) {
      const person = packageCollections.filter(
        (item) => item.id === PACKAGE_PERSONAL
      );

      onChoosePackage(person[0]);
      setCurrentPckg(person[0]);
      setPmoVisit(0);
      setDetail({ account: "Personal", packages: "Personal" });
    } else if (value === 1) {
      const bisnisC = packageCollections.filter(
        (item) => item.id === PACKAGE_BISNIS_C
      );

      onChoosePackage(bisnisC[0]);
      setCurrentPckg(bisnisC[0]);
      setPmoVisit(1);
      setDetail({ account: "Business", packages: "Bisnis C" });
    } else if (value > 1 && value <= 3) {
      const bisnisB = packageCollections.filter(
        (item) => item.id === PACKAGE_BISNIS_B
      );

      onChoosePackage(bisnisB[0]);
      setCurrentPckg(bisnisB[0]);
      setPmoVisit(value);
      setDetail({ account: "Business", packages: "Bisnis B" });
    } else if (value >= 4 && value <= 100) {
      const bisnisA = packageCollections.filter(
        (item) => item.id === PACKAGE_BISNIS_A
      );

      onChoosePackage(bisnisA[0]);
      setCurrentPckg(bisnisA[0]);
      setPmoVisit(value);
      setDetail({ account: "Business", packages: "Bisnis A" });
    }
  };

  const handleCurrentSelect = (data) => {
    setCurrentPckg(data);

    if (data.id === PACKAGE_PERSONAL) {
      setDetail({ account: "Personal", packages: "Personal" });
    } else if (data.id === PACKAGE_BISNIS_A) {
      setDetail({ account: "Business", packages: "Bisnis A" });
    } else if (data.id === PACKAGE_BISNIS_B) {
      setDetail({ account: "Business", packages: "Bisnis B" });
    } else if (data.id === PACKAGE_BISNIS_C) {
      setDetail({ account: "Business", packages: "Bisnis C" });
    }
  };

  // ====================================================
  // Render CARD/BOX Business Package
  // ====================================================
  const renderPackages =
    packageCollections.length > 0 &&
    packageCollections.map((item, i) => {
      const isCurrent = selectedPackage.name === item.name;
      let benefits = [];
      // let stone = false;

      // set detail text for each package
      if (item.id === PACKAGE_PERSONAL) {
        benefits.push("Lisensi User", "Optional Service");
      } else if (item.id === PACKAGE_BISNIS_A) {
        benefits.push("Lebih dari 3 Consultant Visit", "25 Lisensi User");
      } else if (item.id === PACKAGE_BISNIS_B) {
        benefits.push("2 - 3 Consultant Visit", "25 Lisensi User");
      } else if (item.id === PACKAGE_BISNIS_C) {
        benefits.push("1 Consultant Visit", "25 Lisensi User");
      }

      // if (statusIdMember === STATUS_ACTIVE) {
      //   if (pckgId === PACKAGE_BISNIS_A && item.id !== PACKAGE_BISNIS_A) {
      //     stone = true;
      //   } else if (pckgId === PACKAGE_BISNIS_B) {
      //     if (item.id !== PACKAGE_BISNIS_A && item.id !== PACKAGE_BISNIS_B) {
      //       stone = true;
      //     }
      //   } else if (pckgId === PACKAGE_BISNIS_C) {
      //     if (
      //       item.id !== PACKAGE_BISNIS_A &&
      //       item.id !== PACKAGE_BISNIS_B &&
      //       item.id !== PACKAGE_BISNIS_C
      //     ) {
      //       stone = true;
      //     }
      //   }
      // }

      return (
        <label
          key={i}
          className={isCurrent ? classes.cardPackage : classes.cardPlain}
          style={{ margin: 5 }}>
          <Box>
            <Typography
              variant="subtitle1"
              className={isCurrent ? classes.txtTitle : classes.txtTitlePlain}>
              {item.name}
            </Typography>

            {benefits.map((benefit, j) => (
              <Box
                key={j}
                display="flex"
                alignItems="center"
                margin={1}
                marginTop={1}>
                <CheckIcon
                  style={{ marginRight: 7 }}
                  fontSize="small"
                  className={
                    isCurrent ? classes.benefitText : classes.benefitTextPlain
                  }
                />
                <Typography
                  variant="subtitle2"
                  className={
                    isCurrent ? classes.benefitText : classes.benefitTextPlain
                  }>
                  {benefit}
                </Typography>
              </Box>
            ))}
            <Radio
              onChange={() => {
                onChoosePackage(item);
                handleCurrentSelect(item);
              }}
              checked={selectedPackage.name === item.name}
              // disabled={stone}
              style={{
                visibility: "hidden",
                height: 0,
                width: 0,
                opacity: 0,
              }}
            />
          </Box>
        </label>
      );
    });

  return (
    <Dialog
      open={openDialogChoosePackage}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>
        <Typography variant="h6" className={classes.title333}>
          <b>Pilih Paket</b>
        </Typography>
        {/* <Typography variant="subtitle2" className={classes.title333}>
          Paket anda saat ini : <b>{pckgName}</b>
        </Typography>
        <Typography variant="caption" className={classes.title333}>
          Lisensi User anda : <b>{licenseUser}</b>
        </Typography> */}
      </DialogTitle>
      <DialogContent style={{ width: 800 }}>
        <Box marginBottom={2} display="flex" justifyContent="flex-start">
          {renderPackages}
        </Box>

        <Paper variant="outlined" style={{ margin: 5 }}>
          <Box margin={1}>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  marginTop={1}>
                  <Typography
                    variant="subtitle1"
                    className={classes.title333}
                    style={{ textTransform: "uppercase" }}>
                    <b>{detail.account}</b>
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title333}>
                    {detail.packages}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <StarRoundedIcon
                      fontSize="small"
                      style={{ color: level_color, marginRight: 3 }}
                    />
                    <Typography
                      variant="caption"
                      className={classes.title}
                      style={{ color: level_color }}>
                      <b>{levelName}</b>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Typography variant="subtitle2" className={classes.title333}>
                  <b>Lisensi User</b>
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  helperText={
                    userLicense < 1 && `input tidak boleh kurang dari 1`
                  }
                  value={userLicense}
                  onChange={(e) => handleUserLicense(e.target.value)}
                  style={{ width: 200, marginTop: 7 }}
                  disabled={selectedPackage.id === undefined}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">User</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="subtitle2" className={classes.title333}>
                  <b>Consultant Visit</b>
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  // helperText="perubahan Consultant Visit : 0"
                  value={pmoVisit}
                  onChange={(e) => handlePmoVisit(e.target.value)}
                  style={{ width: 200, marginTop: 7 }}
                  disabled={
                    selectedPackage.id === undefined ||
                    (pmoVisit === 0 && pckgId === PACKAGE_PERSONAL)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">Tiket</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: 20,
        }}>
        <Box display="flex" alignItems="center">
          <FlashOnIcon fontSize="small" style={{ color: status_color }} />
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: status_color }}>
            <b>{status_member}</b>
          </Typography>
        </Box>
        <Box>
          <Button
            variant="outlined"
            size="small"
            className={classes.button3}
            style={{ width: 100, marginRight: 8 }}
            onClick={handleCancel}>
            Batal
          </Button>
          <Button
            variant="contained"
            size="small"
            className={classes.button2}
            style={{ width: 100 }}
            disabled={userLicense < 1}
            onClick={handleChoosePackage}>
            Pilih
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DialogChoosePackage;

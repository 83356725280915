import React, { useContext, useState } from "react";
import { Box, Button, Divider, MenuItem } from "@material-ui/core";

import moment from "moment";
import "moment/locale/en-SG";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import {
  GET_COMPARISONS,
  GET_DATA_FIELD,
  GET_FIELD_COMPARE,
  GET_SYNC,
  HIT_DATA_PRESET,
  HIT_TOTAL_RECORDS,
  SELECT_DATE_RANGE,
  SET_PAGINATION,
} from "../../../../../context/types";
import ContextReports from "../../../../../context/ContextReports";
import { URL_API } from "../../../../../../../constants/config-api";
import AxiosConfig from "../../../../../../../constants/config-axios";
import extractTable from "../../../../global/extractTable";
import handleError from "../../../../global/handleError";
import DialogError from "../../../../../../../components/DialogError";
import ListItemDateRange from "./ListItemDateRange";
import { defaultStaticRanges } from "./customRanges";

const DateRangeFilter = ({ classes, onCloseAnchorDate, setAnchorDate }) => {
  const {
    reportState,
    tableState,
    filterState,
    reportDispatch,
    filterDispatch,
    tableDispatch,
  } = useContext(ContextReports);
  const weeks = [
    "last_seven_days",
    "previous_seven_days",
    "this_week",
    "last_week",
  ];
  const months = ["this_month", "last_month"];
  const dailies = ["today", "yesterday"];

  const [dateRange, setDateRange] = useState({
    title: "",
    code: "",
    length: null,
    startDate: moment(filterState.dateRange.startDate),
    endDate: moment(filterState.dateRange.endDate),
  });
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleSelectDateRange = (data) => {
    // console.log("select DR : ", data);

    // setDateRange({
    //   ...dateRange,
    //   startDate: e.selection.startDate,
    //   endDate: e.selection.endDate,
    // });

    // if (
    //   data.hasOwnProperty("title") &&
    //   data.hasOwnProperty("code")
    // ) {
    // Dispatch data start_date & end_date to global state (context)
    filterDispatch({
      type: SELECT_DATE_RANGE,
      payload: {
        date_range: {
          title: data.label,
          code: data.code,
          startDate: moment(data.start_date).format("DD MMM YYYY"),
          endDate: moment(data.end_date).format("DD MMM YYYY"),
        },
      },
    });
    setDateRange({
      ...dateRange,
      title: data.label,
      code: data.code,
      startDate: data.start_date,
      endDate: data.end_date,
    });
    // } else {
    // Dispatch data start_date & end_date to global state (context)
    //   filterDispatch({
    //     type: SELECT_DATE_RANGE,
    //     payload: {
    //       date_range: {
    //         title: "Any Date",
    //         code: "any_date",
    //         startDate: moment(data.startDate).format("DD MMM YYYY"),
    //         endDate: moment(data.endDate).format("DD MMM YYYY"),
    //       },
    //     },
    //   });

    //   setDateRange({
    //     ...dateRange,
    //     title: "Any Date",
    //     code: "any_date",
    //     startDate: data.startDate,
    //     endDate: data.endDate,
    //   });
    // }
  };

  const handleShowResult = () => {
    setAnchorDate(null);

    // Weekly or Monthly or Daily
    let compare = "";
    if (weeks.includes(dateRange.code)) compare = "weekly";
    if (months.includes(dateRange.code)) compare = "monthly";
    if (dailies.includes(dateRange.code)) compare = "daily";

    tableDispatch({
      type: HIT_DATA_PRESET,
      payload: { dataPresets: [], load: true },
    });

    tableDispatch({
      type: GET_FIELD_COMPARE,
      payload: { fieldCompare: [], fieldDiff: [] },
    });

    tableDispatch({
      type: GET_DATA_FIELD,
      payload: {
        comparison: { name: "", code: "" },
      },
    });

    // DATA BODY For Backend
    const dataPost = {
      name: dateRange.title,
      platform: "web",
      field_preset_id: tableState.fieldPresets.id,
      report_format: "table",
      filter_by: {
        entity: [
          { name: "goal", query: "asdfasdfds" },
          { name: "ma", query: "asdfasdfds" },
        ],
        date_range: dateRange.code,
        user: tableState.usersSelected,
        unit: tableState.unitsSelected,
        status: tableState.status,
        achievement: {
          value_type: tableState.valueType,
          cal_method: tableState.calMethod,
        },
      },
      entities: tableState.entity,
      group_by: {
        entity: ["goal"],
        date_time: [],
        user: false,
        unit: ["division", "branch"],
        orientation: [],
      },
      group_by_calculation: "1",
      order_by: tableState.selectedOrder,
      primary_column: "name",
      limit_type: "desc",
      limit_count_number: 0,
      week_start: "monday",
      date_format: "d m Y",
      summary_only: false,
    };

    const { currentPage, perPage } = tableState.pagination;

    // ===================== GET Request | For Comparison =====================
    AxiosConfig.get(
      `${URL_API}/reporting/page/${reportState.group}/detailed?compare_range=${compare}`
    ).then((res) => {
      const result = res.data.data;

      if (res.status === 200) {
        const settings = result.layout[0].widget.setting;
        const comparisons = settings.comparisons.default_value;

        // GET Comparison initial Data
        filterDispatch({
          type: GET_COMPARISONS,
          payload: {
            comparisonList: comparisons.date_range,
            comparisonFields: comparisons.fields,
          },
        });
      }
    });

    // ===================== POST Request | For Data Table =====================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
      dataPost
    )
      .then((res) => {
        const result = res.data.data;
        const lastSync = res.data.info.data.last_sync;

        // console.log("Ori Result PRST", result);
        const DATATABLE = extractTable(result.results.data);
        const pagination = {
          currentPage: result.results.current_page,
          perPage: result.results.per_page,
          lastPage: result.results.last_page,
        };

        if (res.status === 200) {
          reportDispatch({
            type: GET_SYNC,
            payload: { lastSync },
          });

          tableDispatch({
            type: HIT_DATA_PRESET,
            payload: { dataPresets: DATATABLE, load: false },
          });

          tableDispatch({
            type: SET_PAGINATION,
            payload: { pagination },
          });

          tableDispatch({
            type: HIT_TOTAL_RECORDS,
            payload: { totalRecords: result.results.total },
          });
        }
      })
      .catch((error) => {
        console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        tableDispatch({
          type: HIT_DATA_PRESET,
          payload: { dataPresets: [], load: false },
        });
      });
  };

  return (
    <>
      {/* <DefinedRange
        // onInit={handleSelectDateRange}
        onChange={(e) => handleSelectDateRange(e)}
        ranges={[
          {
            startDate: moment(filterState.dateRange.startDate),
            endDate: moment(filterState.dateRange.endDate),
            key: "selection",
          },
        ]}
        staticRanges={defaultStaticRanges}
      /> */}
      {defaultStaticRanges.length > 0 &&
        defaultStaticRanges.map((item, i) => (
          <MenuItem key={i} style={{ margin: 0, padding: "0 10px" }} dense>
            <ListItemDateRange
              classes={classes}
              item={item}
              range={filterState.dateRange}
              onHandleRange={handleSelectDateRange}
            />
          </MenuItem>
        ))}

      <Box margin={1.5}>
        <Divider />
      </Box>

      <Box
        marginRight={2}
        marginBottom={1}
        display="flex"
        justifyContent="flex-end">
        <Button
          size="small"
          variant="outlined"
          className={classes.button0}
          onClick={onCloseAnchorDate}>
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          className={classes.button1}
          style={{ marginLeft: 7.5 }}
          disabled={dateRange.code === ""}
          onClick={handleShowResult}>
          Show results
        </Button>
      </Box>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default DateRangeFilter;

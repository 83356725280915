import React, { useState, useRef } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  IconButton,
} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PersonAddRoundedIcon from "@material-ui/icons/PersonAddRounded";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";

const options = ["Export Tempalte", "Save Template", "Share Template"];

const RightMenu = ({ classes }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box display="flex" alignItems="center">
      <Box mr={6}>
        <ButtonGroup
          variant="outlined"
          ref={anchorRef}
          aria-label="split button">
          <Button className={classes.button1} onClick={handleClick}>
            {options[selectedIndex]}
          </Button>
          <Button
            className={classes.button2}
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "right top" : "right bottom",
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}>
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>

      <Box mr={4}>
        <IconButton>
          <PersonAddRoundedIcon style={{ fontSize: 25, color: "#aaa" }} />
        </IconButton>
      </Box>

      <Box mr={2}>
        <IconButton>
          <MoreVertRoundedIcon style={{ fontSize: 25, color: "#aaa" }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default RightMenu;

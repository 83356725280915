import React, { Fragment } from "react";
import {
  Box,
  Grid,
  TablePagination,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import TablePaginationCustom from "./TablePaginationCustom";

function PaginationGoal({
  theme,
  classes,
  lengthGoalCount,
  rowsPerPage,
  page,
  loaderForPaging,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  return (
    <Box marginLeft={theme.spacing(1)} marginTop={theme.spacing(0.3)}>
      <Grid container direction="row" justify="flex-end" alignItems="right">
        <TablePagination
          classes={{
            toolbar: classes.toolbar,
            root: classes.noBorderBottom,
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          colSpan={3}
          count={lengthGoalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              "aria-label": "Jumlah baris",
            },
          }}
          labelDisplayedRows={({ from, to, count }) => (
            <Typography
              variant="subtitle2"
              className={
                loaderForPaging === true
                  ? classes.titleWithOpacity
                  : classes.title
              }>
              {loaderForPaging === true ? (
                <Fragment>
                  <CircularProgress size={16} style={{ color: "red" }} />
                  {` dari total ${count} baris.`}
                </Fragment>
              ) : (
                <i>{`${from} - ${
                  to === -1 ? count : to
                } dari total ${count} baris.`}</i>
              )}
            </Typography>
          )}
          labelRowsPerPage="Baris setiap halaman"
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationCustom}
        />
      </Grid>
    </Box>
  );
}

export default PaginationGoal;

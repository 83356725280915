import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Grid,
  Tab,
  Tabs,
  Typography,
  IconButton,
  InputBase,
  Box,
  Button,
} from "@material-ui/core";
import { Search as SearchIcon, Cancel as CancelIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import { useDebouncedCallback } from "use-debounce";
import Snackbeer from "../../../../components/Snackbeer";
import {
  STATUS_DRAFT,
  STATUS_APPROVED,
  STATUS_ACTIVE,
  STATUS_OVERDUE,
  STATUS_COMPLETED,
} from "../../../../constants/config-status-goal";

import ContextAllMA from "../context/AllMaContext";
import ListStatusAllMADraft from "../ComponentStatusAllMA/ListStatusAllMADraft";
import ListStatusAllMAApproved from "../ComponentStatusAllMA/ListStatusAllMAApproved";
import ListStatusAllMAActive from "../ComponentStatusAllMA/ListStatusAllMAActive";
import ListStatusAllMAOverdue from "../ComponentStatusAllMA/ListStatusAllMAOverdue";
import ListStatusAllMACompleted from "../ComponentStatusAllMA/ListStatusAllMACompleted";

import { styles, theme } from "../style/StyleAllMA";
import DialogConfirmWeight from "../../MeasureActivities/Components/DialogConfirmWeight";

const useStyle = makeStyles(styles);

function ViewYou() {
  // Important Variable
  const context = useContext(ContextAllMA);
  const classes = useStyle();
  let inputRefSearch = useRef(null);

  const userToken = localStorage.getItem("userToken");
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);

  //   State Goes Here
  const [userTokenState, setUserTokenState] = useState("");
  const [disableCreateMA, setDisableCreateMA] = useState(false);

  // CONFIRM WEIGHT
  const [openDialogConfirmWeight, setOpenDialogConfirmWeight] = useState(false);

  //   GET Initial DATA MA
  useEffect(() => {
    setUserTokenState(userToken);

    const statusAllMAId = context.statusAllMAIdYou;
    const locationTab = context.dataTabActive.label;

    if (locationTab === "You") {
      if (userToken !== undefined) {
        context.setLoader(true);

        axiosConfig
          .get(
            `${URL_API}/swd/measured-activity?options[filter][scope]=self&options[filter][status_id]=${
              statusAllMAId !== null ? statusAllMAId : 3
            }`
          )
          .then((response) => {
            console.log("Response Original COLLECTION MA : ", response);

            if (response.status === 200) {
              if (response.data.data !== null) {
                context.setCollectionMAList(response.data.data);
                context.setCollectionMALength(response.data.info.count);
              }
            }
            context.setLoader(false);
          })
          .catch((error) => {
            context.setLoader(false);
            console.log("Error : ", error);
          });
      }
    }
  }, [
    context.deleteSuccess,
    context.successSetAsComplete,
    context.successDuplicate,
  ]);

  // Handle Disable Create MA depending on status code 403
  useEffect(() => {
    const locationTab = context.dataTabActive.label;

    if (locationTab === "You") {
      if (userTokenState !== "") {
        axiosConfig
          .get(`${URL_API}/swd/measured-activity/create`)
          .then((response) => {
            // console.log("Response Create Goal", response);
          })
          .catch((error) => {
            console.log("Error : ", error);
            if (error.response.status === 403) {
              setDisableCreateMA(true);
            }
          });
      } else {
        console.log("User Token Not Exist");
      }
    }
  }, []);

  /**
   ==================================================================================
    HANDLE CHANGE TAB STATUS (Draft, Active, Completed, etc)
   ==================================================================================
   */
  const handleChangeTab = (event, newValue) => {
    context.setValueTabYou(newValue);

    // if (newValue === 0) {
    //   context.setStatusAllMAIdYou(1); // Draft
    //   localStorage.setItem("status_allma_id", "1");
    //   context.setLoader(true);

    //   axiosConfig
    //     .get(
    //       `${URL_API}/swd/measured-activity?options[filter][scope]=self&options[filter][status_id]=${1}`
    //     )
    //     .then((response) => {
    //       console.log("Response Original COLLECTION MA : ", response);

    //       if (response.status === 200) {
    //         if (response.data.data !== null) {
    //           context.setCollectionMAList(response.data.data);
    //           context.setCollectionMALength(response.data.info.count);
    //         }
    //       }
    //       context.setLoader(false);
    //     })
    //     .catch((error) => {
    //       context.setLoader(false);
    //       console.log("Error : ", error);
    //     });
    // }

    if (newValue === 0) {
      context.setStatusAllMAIdYou(2); // Approved
      localStorage.setItem("status_allma_id", "2");
      context.setLoader(true);

      axiosConfig
        .get(
          `${URL_API}/swd/measured-activity?options[filter][scope]=self&options[filter][status_id]=${2}`
        )
        .then((response) => {
          console.log("Response Original COLLECTION MA : ", response);

          if (response.status === 200) {
            if (response.data.data !== null) {
              context.setCollectionMAList(response.data.data);
              context.setCollectionMALength(response.data.info.count);
            }
          }
          context.setLoader(false);
        })
        .catch((error) => {
          context.setLoader(false);
          console.log("Error : ", error);
        });
    }

    if (newValue === 1) {
      context.setStatusAllMAIdYou(3); // Active
      localStorage.setItem("status_allma_id", "3");
      context.setLoader(true);

      axiosConfig
        .get(
          `${URL_API}/swd/measured-activity?options[filter][scope]=self&options[filter][status_id]=${3}`
        )
        .then((response) => {
          console.log("Response Original COLLECTION MA : ", response);

          if (response.status === 200) {
            if (response.data.data !== null) {
              context.setCollectionMAList(response.data.data);
              context.setCollectionMALength(response.data.info.count);
            }
          }
          context.setLoader(false);
        })
        .catch((error) => {
          context.setLoader(false);
          console.log("Error : ", error);
        });
    }

    if (newValue === 2) {
      context.setStatusAllMAIdYou(6); // Overdue
      localStorage.setItem("status_allma_id", "6");
      context.setLoader(true);

      axiosConfig
        .get(
          `${URL_API}/swd/measured-activity?options[filter][scope]=self&options[filter][status_id]=${6}`
        )
        .then((response) => {
          console.log("Response Original COLLECTION MA : ", response);

          if (response.status === 200) {
            if (response.data.data !== null) {
              context.setCollectionMAList(response.data.data);
              context.setCollectionMALength(response.data.info.count);
            }
          }
          context.setLoader(false);
        })
        .catch((error) => {
          context.setLoader(false);
          console.log("Error : ", error);
        });
    }

    if (newValue === 3) {
      context.setStatusAllMAIdYou(4); // Completed
      localStorage.setItem("status_allma_id", "4");
      context.setLoader(true);

      axiosConfig
        .get(
          `${URL_API}/swd/measured-activity?options[filter][scope]=self&options[filter][status_id]=${4}`
        )
        .then((response) => {
          context.setLoader(false);
          console.log("Response Original COLLECTION MA : ", response);

          if (response.status === 200) {
            if (response.data.data !== null) {
              context.setCollectionMAList(response.data.data);
              context.setCollectionMALength(response.data.info.count);
            }
          }
        })
        .catch((error) => {
          context.setLoader(false);
          console.log("Error : ", error);
        });
    }
  };

  /**
   ==================================================================================
    HANDLE SEARCH FOR EVERY STATUS (Draft, Active, Completed, etc)
   ==================================================================================
   */
  const [handleSearch, cancel] = useDebouncedCallback(
    useCallback((value) => {
      context.setLoader(true);

      const statusAllMAId = localStorage.getItem("status_allma_id");
      console.log("Status All MA Id : ", statusAllMAId);
      console.log("I want to know All MA : ", value);

      localStorage.setItem("value_search", value);

      if (userToken !== undefined) {
        axiosConfig
          .get(
            `${URL_API}/swd/measured-activity?options[filter][search]=${value}&options[filter][status_id]=${statusAllMAId}&options[filter][scope]=self`
          )
          .then((response) => {
            console.log("Response Original All MA from search : ", response);

            if (response.status === 200) {
              if (response.data.data !== null) {
                context.setSearchRun(true);
                context.setCollectionMAList(response.data.data);
              }
            }
            context.setLoader(false);
          })
          .catch((error) => {
            context.setLoader(false);
            console.log("Error : ", error.response);
          });
      } else {
        console.log("No Access Token available!");
      }
    }, []),
    1000,

    { maxWait: 5000 }
  );

  /**
   ==================================================================================
    Show Measured Activity Counter Status
   ==================================================================================
   */

  const [maCounter, setMACounter] = useState([]);

  useEffect(() => {
    axiosConfig
      .get(
        `${URL_API}/swd/measured-activity/count-status?options[filter][scope]=self`
      )
      .then((response) => {
        // console.log("Response Status Counter", response);
        const result = response.data.data;

        if (response.status === 200) {
          const filterStatusDraft = result.filter((item) => item.id !== "1");

          setMACounter(filterStatusDraft);
        }
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  }, [
    context.deleteSuccess,
    context.successSetAsComplete,
    context.successDuplicate,
    context.isSuccessCreateMAR,
  ]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}>
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  const handleDialogCreate = () => {
    // context.setModalCreate(true);
    setOpenDialogConfirmWeight(true);

    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");
  };

  return (
    <Fragment>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ backgroundColor: "transparent" }}>
        <Grid item sm={6} md={6}>
          <Tabs
            indicatorColor="primary"
            value={context.valueTabYou}
            onChange={handleChangeTab}
            aria-label="simple tabs example">
            {listTabs.length > 0 &&
              listTabs.map((item, i) => {
                let counter = 0;

                if (maCounter.length > 0) {
                  if (maCounter[i].code === item.code) {
                    counter = maCounter[i].count;
                  }
                }

                return (
                  <Tab
                    classes={{ root: classes.widthTab }}
                    label={
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{
                          textTransform: "capitalize",
                          marginBottom: -10,
                        }}>
                        <b>{item.label}</b>
                        <span
                          style={{
                            color: "#d1354a",
                            backgroundColor: "#ffa9c1",
                            fontWeight: "700",
                            paddingLeft: 4,
                            paddingRight: 4,
                            fontSize: 14,
                            marginLeft: 5,
                            borderRadius: 5,
                          }}>
                          {counter}
                        </span>
                      </Typography>
                    }
                    {...a11yProps(i)}
                  />
                );
              })}
          </Tabs>
        </Grid>

        <Grid item sm={3} md={3} style={{ textAlign: "right" }}>
          <Button
            onClick={() => context.setModalExportMA(true)}
            variant="outlined"
            className={classes.buttonOutlined}
            style={{ borderWidth: 1 }}>
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "grey" }}>
              Export CSV
            </Typography>
          </Button>
        </Grid>

        <Grid item sm={3} md={3}>
          <Box display="flex">
            <Box flexGrow={1} widht="100%">
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  onChange={(e) => handleSearch(e.target.value)}
                  inputRef={inputRefSearch}
                  placeholder={
                    context.valueSearchState !== ""
                      ? context.valueSearchState
                      : "Search Measure Activity..."
                  }
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "Search" }}
                />
              </div>
            </Box>

            <Box width="20%" marginLeft={theme.spacing(-0.8)} marginTop={0.2}>
              {context.isSearchRun === true && (
                <IconButton
                  size="small"
                  onClick={() => {
                    context.setValueSearchState("");
                    context.setSearchRun(false);
                    context.setClearSearchResult(true);
                    localStorage.removeItem("value_search");

                    setTimeout(() => {
                      inputRefSearch.current.value = "";
                    }, 200);
                  }}>
                  <CancelIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container style={{ backgroundColor: "transparent" }}>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          {context.statusAllMAIdYou === 3 && (
            <Button
              onClick={handleDialogCreate}
              variant="contained"
              className={classes.button}
              disabled={disableCreateMA}
              style={{ marginRight: 48, marginTop: 16 }}>
              Buat Measure Activity baru
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container style={{ backgroundColor: "transparent" }}>
        <Grid item xs={12} md={12}>
          {/* <TabPanel value={context.valueTabYou} index={0}>
            <ListStatusAllMADraft />
          </TabPanel> */}
          <TabPanel value={context.valueTabYou} index={0}>
            <ListStatusAllMAApproved />
          </TabPanel>
          <TabPanel value={context.valueTabYou} index={1}>
            <ListStatusAllMAActive />
          </TabPanel>
          <TabPanel value={context.valueTabYou} index={2}>
            <ListStatusAllMAOverdue />
          </TabPanel>
          <TabPanel value={context.valueTabYou} index={3}>
            <ListStatusAllMACompleted />
          </TabPanel>
        </Grid>
      </Grid>

      <Snackbeer
        setModalResponse200={context.setModalResponse200}
        isModalResponse200={context.isModalResponse200}
        messages={context.messages}
      />

      <DialogConfirmWeight
        classes={classes}
        openDialogConfirmWeight={openDialogConfirmWeight}
        setOpenDialogConfirmWeight={setOpenDialogConfirmWeight}
        setModalContinue={context.setModalCreate}
      />
    </Fragment>
  );
}

export default ViewYou;

const listTabs = [
  {
    id: 2,
    label: "Approved",
    code: STATUS_APPROVED,
    status_id: 2,
  },

  {
    id: 3,
    label: "Active",
    code: STATUS_ACTIVE,
    status_id: 3,
  },

  {
    id: 4,
    label: "Overdue",
    code: STATUS_OVERDUE,
    status_id: 6,
  },

  {
    id: 5,
    label: "Complete",
    code: STATUS_COMPLETED,
    status_id: 4,
  },
];

/*
    ``````````````````````````````````````
    NOW, 05 Desember 2019, 13.30. 
    
    Term "Employee" di ubah menjadi "User"
    
    ``````````````````````````````````````
*/

import React, { useEffect, useState, useRef, useContext } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import axios from "axios";

import ContextNewSO from "../Context/ContextNewSO";

import Capitalize from "../../../utilities/Capitalize";
import DialogError from "../../../components/DialogError";
import Snackbeer from "../../../components/Snackbeer";

import { styles } from "../Style/StyleDialogDetail";

import { URL_API } from "../../../constants/config-api";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const DialogCreateEmployeeInDeputyHEAD = (props) => {
  const { classes } = props;

  const context = useContext(ContextNewSO);

  let textInputReff = useRef(null);

  const [loader, setLoader] = useState(false);
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformationValue] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  const [numOfUsers, setNumOfUsers] = useState({
    maxUser: 0,
    remainingUser: 0,
    usedUser: 0,
  });

  const [roleId, setRoleId] = useState("");

  useEffect(() => {
    if (context.isOpenDialogCreateEmployeeInDeputyHEAD == true) {
      context.setFirstName("");
      context.setLastName("");

      //*Focus in Text Field
      setTimeout(() => {
        textInputReff.current.focus();
      }, 100);

      /*
                `````````
                GET LABEL

                `````````
            */
      if (context.userTokenState !== undefined) {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + context.userTokenState,
        };

        axios.defaults.headers.common = header;

        axios
          .get(URL_API + `/account-management/user/quick/create`)
          .then((response) => {
            console.log("Res Deputy Head : ", response);
            const result = response.data.data;

            if (response.status === 200) {
              if (result.roleCollections !== null) {
                if (result.roleCollections.length > 0) {
                  setRoleId(result.roleCollections[0].id);
                }
              }
              setNumOfUsers({
                maxUser: result.maximum_user_quota,
                remainingUser: result.remaining_user_quota,
                usedUser: result.used_user_quota,
              });
            }
          })
          .catch((error) => {
            console.log("Error : ", error.response);
          });
      }
    }
  }, [context.isOpenDialogCreateEmployeeInDeputyHEAD]);

  const handleBatal = () => {
    context.setOpenDialogTambahDeputyHead(true);
    context.setOpenDialogCreateEmployeeInDeputyHEAD(false);
  };

  // const [ firstName, setFirstName ] = useState(''); //*Moved to Global State
  // const [ lastName, setLastName ] = useState('');
  // const [ email, setEmail ] = useState('');

  const handleSubmit = () => {
    setLoader(true);

    let data = {
      User: {
        first_name: context.firstName,
        last_name: context.lastName !== "" ? context.lastName : "-",
        email: context.email,
        role_id: [roleId],
      },
    };

    console.log("Data SUBMIT : ", data);

    if (context.userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + context.userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .post(URL_API + "/account-management/user/quick", data)
        .then(function (response) {
          setLoader(false);
          console.log("Response Original : ", response);

          if (response.status == 200) {
            context.setOpenDialogCreateEmployeeInDeputyHEAD(false);
            context.setOpenDialogTambahDeputyHead(true);

            context.setSuccessCreateEmployee(true);

            setModalResponse200(true);
            setMessages("Berhasil membuat user baru :)");
          }
        })
        .catch(function (error) {
          setLoader(false);

          if (error.response !== undefined) {
            if (error.response.status == 400) {
              setErrorStatus(400);
              if (error.response.data !== null) {
                if (error.response.data.info !== null) {
                  if (
                    error.response.data.info.errors !== null &&
                    error.response.data.info.errors !== undefined
                  ) {
                    if (error.response.data.info.errors.length > 0) {
                      setListError(error.response.data.info.errors);
                      setTextErrorInformationValue(".");
                      setOpenDialogError(true);
                    }
                    // if(error.response.data.info.errors[0].code === 'VALIDATION_ERROR'){

                    //     setTextErrorInformationValue('Error 400 : ' + Capitalize(error.response.data.info.errors[0].description))
                    //     setOpenDialogError(true);
                    // };
                  }
                }
              }
            }

            if (error.response.status == 500) {
              setErrorStatus(500);
              if (error.response.data.hasOwnProperty("message")) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  "Error 500 : " + Capitalize(error.response.data.message)
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");

            context.setOpenDialogTambahDeputyHead(true);
          }

          console.log("Error : ", error.response);
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */

  const [isModalResponse200, setModalResponse200] = useState(false);
  const [messages, setMessages] = useState("");

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        open={context.isOpenDialogCreateEmployeeInDeputyHEAD}
        // onClose={() => setOpenDialogCreateEmployeeInDeputyHEAD(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}>
            <b>
              Undang <i>User</i> baru
            </b>
          </Typography>
          <Box marginTop={2} marginBottom={2}>
            <div className={classes.warningPasswordBg}>
              <Typography
                variant="subtitle2"
                className={classes.warningPasswordTxt}>
                <b>
                  {numOfUsers.usedUser} dari {numOfUsers.maxUser} telah
                  diundang.{" "}
                </b>
                <span style={{ color: "#333" }}>
                  {numOfUsers.remainingUser} User tersisa.
                </span>
              </Typography>
            </div>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TextField
            inputRef={textInputReff}
            id="outlined-bare"
            className={classes.textField}
            onChange={(e) => context.setFirstName(e.target.value)}
            // color='primary'
            // onKeyDown={handleEnterPress}
            // value= {bigGoal}
            placeholder="First name"
            // value = {goalDetailState.name}
            variant="outlined"
            fullWidth
          />

          <br />
          <br />
          <TextField
            id="outlined-bare"
            className={classes.textField}
            onChange={(e) => context.setLastName(e.target.value)}
            // onChange= {handleChangeBIGGoal}
            // color='primary'
            // onKeyDown={handleEnterPress}
            // value= {bigGoal}
            placeholder="Last name"
            // value = {goalDetailState.name}
            variant="outlined"
            fullWidth
          />
          <br />
          <br />

          <TextField
            id="outlined-bare"
            className={classes.textField}
            onChange={(e) => context.setEmail(e.target.value)}
            // onChange= {handleChangeBIGGoal}
            // color='primary'
            // onKeyDown={handleEnterPress}
            // value= {bigGoal}
            placeholder="Email"
            // value = {goalDetailState.name}
            variant="outlined"
            fullWidth
          />
        </DialogContent>
        <DialogActions
          style={{ alignItems: "right", justifyContent: "flex-end" }}>
          <Box marginRight={theme.spacing(0.5)}>
            <Button
              // onClick={() => setOpenDialogCreateEmployeeInDeputyHEAD(false)}
              onClick={handleBatal}
              variant="outlined"
              size="small"
              className={classes.buttonOutlined}>
              Batal
            </Button>

            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}>
              {loader !== true ? (
                " Undang "
              ) : (
                <CircularProgress size={20} style={{ color: "white" }} />
              )}
            </Button>
          </Box>
        </DialogActions>
        <br />
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />

      <Snackbeer
        classes={classes}
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages={messages}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(DialogCreateEmployeeInDeputyHEAD);

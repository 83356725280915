import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment,
} from "react";

import {
  Grid,
  IconButton,
  InputBase,
  Tooltip,
  Box,
  Button,
  Badge,
  ListItemIcon,
  ListItemText,
  Typography,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useDebouncedCallback } from "use-debounce/lib";

import clsx from "clsx";

import SearchIcon from "@material-ui/icons/Search";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import FormatListBulletedRoundedIcon from "@material-ui/icons/FormatListBulletedRounded";
import GridOnRoundedIcon from "@material-ui/icons/GridOnRounded";
import DoneAllRoundedIcon from "@material-ui/icons/DoneAllRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import RefreshRoundedIcon from "@material-ui/icons/RefreshRounded";
import SettingsIcon from "@material-ui/icons/Settings";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import FolderIcon from "@material-ui/icons/Folder";
import RestoreRoundedIcon from "@material-ui/icons/RestoreRounded";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";

import axiosConfig from "../../../constants/config-axios";
import { URL_API } from "../../../constants/config-api";

// import {
//   StyledMenu,
//   StyledMenuItem,
// } from "../../../components/StyledMenuDropdown";
import ContextGlobalDrive from "../context/ContextDrive";
import DialogCreateFolder from "./components/DialogCreateFolder";
import DialogUploadFile from "./components/DialogUploadFile";
import DialogDeleteMultiple from "./components/DialogDeleteMultiple";
import DialogMoveMultiple from "./components/DialogMoveMultiple";
import DialogFolderSetting from "./components/DialogFolderSetting";
import DialogCreatePlainDoc from "./components/DialogCreatePlainDoc";
import DialogMultipleRestore from "./components/DialogMultipleRestore";
import DialogDownloadMultipleGallery from "./components/DialogDownloadMultipleGallery";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../components/StyledMenuDropdown";

function MenuSection({ classes, theme }) {
  const context = useContext(ContextGlobalDrive);
  const pathName = window.location.pathname;
  let inputRefSearch = useRef(null);
  const breadcrumb = localStorage.getItem("breadcrumb");
  const listOfBread = breadcrumb ? breadcrumb.split(",") : [];

  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [anchorElSetting, setAnchorElSetting] = useState(null);
  const [anchorElCreate, setAnchorElCreate] = useState(null);
  const [idFolderFromPath, setIdFolderFromPath] = useState("");
  const [rootFolderPath, setRootFolderPath] = useState("");

  const [isSearchRun, setSearchRun] = useState(false);
  const [clearSearchResult, setClearSearchResult] = useState(false);
  const [driveSearchValue, setDriveSearchValue] = useState("");

  // Dialog
  const [openDialogCreateFolder, setOpenDialogCreateFolder] = useState(false);
  const [openDialogUploadFile, setOpenDialogUploadFile] = useState(false);
  const [openDialogDeleteMultiple, setOpenDialogDeleteMultiple] = useState(
    false
  );
  const [openDialogMoveMultiple, setOpenDialogMoveMultiple] = useState(false);
  const [openDialogRestoreMultiple, setOpenDialogRestoreMultiple] = useState(
    false
  );
  const [openDialogDownloadMultiple, setOpenDialogDownloadMultiple] = useState(
    false
  );
  const [openDialogFolderSetting, setOpenDialogFolderSetting] = useState(false);
  const [openDialogCreatePlainDoc, setOpenDialogCreatePlainDoc] = useState(
    false
  );

  /* ====================================================
    Side Effect
  ==================================================== */
  useEffect(() => {
    const pathArray = pathName.split("/");
    const getLastPathArr = pathArray[pathArray.length - 1];

    // console.log("getLastPathArr", getLastPathArr);

    if (getLastPathArr !== undefined && getLastPathArr !== "") {
      setIdFolderFromPath(getLastPathArr);
      setRootFolderPath(getLastPathArr);
    }
  }, [pathName]);

  useEffect(() => {
    if (
      driveSearchValue === "" &&
      isSearchRun === false &&
      clearSearchResult === true
    ) {
      context.setLoadFolders(true);
      context.setLoadFiles(true);

      axiosConfig
        .get(
          `${URL_API}/drive/folder?options[filter][folder_parent_id]=${
            pathName === "/drive" ? "root" : rootFolderPath
          }&options[embedded][]=owner&options[embedded][]=policy`
        )
        .then((responseFolder) => {
          // console.log("Clear Folder", responseFolder);

          if (responseFolder.status === 200) {
            context.setListFolders(responseFolder.data.data);
            context.setLoadFolders(false);

            axiosConfig
              .get(
                `${URL_API}/drive/file?options[filter][folder_id]=${
                  pathName === "/drive" ? "root" : rootFolderPath
                }&options[embedded][0]=owner`
              )
              .then((responseFile) => {
                // console.log("Clear File", responseFile);

                if (responseFile.status === 200) {
                  context.setListFiles(responseFile.data.data);
                  context.setLoadFiles(false);
                }
              })
              .catch((error) => {
                context.setLoadFiles(false);
                console.log("Error : ", error);
              });
          }
        })
        .catch((error) => {
          context.setLoadFolders(false);
          console.log("Error : ", error);
        });
    }
  }, [driveSearchValue, isSearchRun, clearSearchResult]);

  /* ====================================================
    LOCAL Functions / Methods
  ==================================================== */
  const handleShowAnchorSetting = (event) => {
    setAnchorElSetting(event.currentTarget);
  };

  const handleCloseAnchorSetting = () => {
    setAnchorElSetting(null);
  };

  const handleShowAnchoeCreate = (event) => {
    setAnchorElCreate(event.currentTarget);
  };

  const handleCloseAnchorCreate = () => {
    setAnchorElCreate(null);
  };

  const handleRefresh = () => {
    context.setSuccessTriggerFolder(Math.floor(Math.random() * 1000 + 1));
    context.setSuccessTriggerFile(Math.floor(Math.random() * 1000 + 1));
  };

  const [handleSearch, cancel] = useDebouncedCallback(
    useCallback((value, idFolder) => {
      if (value !== "") {
        context.setLoadFolders(true);
        context.setLoadFiles(true);

        localStorage.setItem("filemanager_search", value);

        setDriveSearchValue(value);

        // console.log("ID FOLDER", idFolder);

        axiosConfig
          .get(
            `${URL_API}/drive/folder?options[filter][ancestor_id]=${
              idFolder === "drive" ? "" : idFolder
            }&options[filter][search]=${value}&options[embedded][]=owner&options[embedded][]=policy`
          )
          .then((responseFolder) => {
            // console.log("Search Folder", responseFolder);

            if (responseFolder.status === 200) {
              setSearchRun(true);
              context.setListFolders(responseFolder.data.data);
              context.setLoadFolders(false);

              axiosConfig
                .get(
                  `${URL_API}/drive/file?options[filter][ancestor_id]=${
                    idFolder === "drive" ? "" : idFolder
                  }&options[filter][search]=${value}&options[embedded][0]=owner`
                )
                .then((responseFile) => {
                  // console.log("Search File", responseFile);

                  if (responseFile.status === 200) {
                    setSearchRun(true);
                    context.setListFiles(responseFile.data.data);
                    context.setLoadFiles(false);

                    context.setLoadGalleryGR(true);

                    axiosConfig
                      .get(
                        `${URL_API}/drive/gallery/goal-result-evidence?options[embedded][]=goal&options[embedded][]=owner&options[filter][search]=${value}`
                      )
                      .then((responseGallery) => {
                        // console.log("Search Gallery", responseGallery);
                        const result = responseGallery.data.data;

                        if (responseGallery.status === 200) {
                          context.setListGalleryEvidence(result);

                          context.setLoadGalleryMAR(true);

                          axiosConfig
                            .get(
                              `${URL_API}/drive/gallery/measured-activity-result-evidence?options[embedded][]=measuredActivity&options[embedded][]=owner&options[filter][search]=${value}`
                            )
                            .then((responseGalleryMA) => {
                              // console.log(
                              //   "Search Gallery MA",
                              //   responseGalleryMA
                              // );
                              const result = responseGalleryMA.data.data;

                              if (responseGalleryMA.status === 200) {
                                context.setListGalleryEvidenceMAR(result);
                              }
                              context.setLoadGalleryMAR(false);
                            })
                            .catch((error) => {
                              context.setLoadGalleryMAR(false);
                              console.log("Error : ", error);
                            });
                        }
                        context.setLoadGalleryGR(false);
                      })
                      .catch((error) => {
                        context.setLoadGalleryGR(false);
                        console.log("Error : ", error);
                      });
                  }
                })
                .catch((error) => {
                  context.setLoadFiles(false);
                  console.log("Error : ", error);
                });
            }
          })
          .catch((error) => {
            context.setLoadFolders(false);
            console.log("Error : ", error);
          });
      } else {
        setSearchRun(false);
        setDriveSearchValue("");
        localStorage.removeItem("filemanager_search");
        inputRefSearch.current.value = "";

        context.setLoadFolders(true);
        context.setLoadFiles(true);

        axiosConfig
          .get(
            `${URL_API}/drive/folder?options[filter][folder_parent_id]=${
              idFolder === "drive" ? "root" : idFolder
            }&options[embedded][]=owner&options[embedded][]=policy`
          )
          .then((responseFolder) => {
            // console.log("Search Folder", responseFolder);
            if (responseFolder.status === 200) {
              setSearchRun(true);
              context.setListFolders(responseFolder.data.data);
              context.setLoadFolders(false);
              axiosConfig
                .get(
                  `${URL_API}/drive/file?options[filter][folder_id]=${
                    idFolder === "drive" ? "root" : idFolder
                  }options[embedded][0]=owner`
                )
                .then((responseFile) => {
                  // console.log("Search File", responseFile);
                  if (responseFile.status === 200) {
                    context.setListFiles(responseFile.data.data);
                    context.setLoadFiles(false);
                  }
                })
                .catch((error) => {
                  context.setLoadFiles(false);
                  console.log("Error : ", error);
                });
            }
          })
          .catch((error) => {
            context.setLoadFolders(false);
            console.log("Error : ", error);
          });
      }
    }, []),
    1000,

    { maxWait: 5000 }
  );

  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      backgroundColor: "#d1354a",
      color: "#fff",
    },
  }))(Badge);

  // ============== Pollicy ==============
  const user_login = localStorage.getItem("status_user_login");
  const superAdmin = JSON.parse(user_login);
  const role = superAdmin.userRole[0].name;

  let createAllowed = false;
  let manageAllowed = false;

  // console.log("PLISI", context.detailFolder);

  context.detailFolder.policy !== undefined &&
    context.detailFolder.policy.length > 0 &&
    context.detailFolder.policy.map((isAllowed) => {
      if (isAllowed.actionName === "create") {
        createAllowed = isAllowed.result;
      } else if (isAllowed.actionName === "manage") {
        manageAllowed = isAllowed.result;
      }
    });

  // Disabling For Upload & Create Folder
  if (pathName === "/drive" && role === "superadmin") {
    createAllowed = true;
  } else if (pathName === "/drive" && role !== "superadmin") {
    createAllowed = false;
  }

  // Disabling For Folder Settings
  if (role !== "superadmin") {
    manageAllowed = false;
  }

  const handleOpenDownloadMultiple = () => {
    if (context.selectedFile.length > 0) {
      setOpenDialogDownloadMultiple(true);
    }
  };

  // console.log("SUpa Admeen", superAdmin.userRole[0].name);

  return (
    <Grid container>
      <Grid item lg={10}>
        <Box display="flex" alignItems="center">
          <Box display="flex">
            <Box flexGrow={1} widht="80%">
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon style={{ color: "#888" }} fontSize="small" />
                </div>
                <InputBase
                  onChange={(e) =>
                    handleSearch(e.target.value, idFolderFromPath)
                  }
                  placeholder="Search..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "Search" }}
                  inputRef={inputRefSearch}
                  disabled={
                    listOfBread[0] === "Gallery" ||
                    listOfBread[0] === "Recycle Bin" ||
                    listOfBread[0] === "Recovered Files & Folder"
                  }
                />
              </div>
            </Box>

            <Box width="20%" marginLeft={-7} marginTop={1.1}>
              <IconButton
                size="small"
                onClick={() => {
                  setSearchRun(false);
                  setDriveSearchValue("");
                  localStorage.removeItem("filemanager_search");
                  setClearSearchResult(true);

                  setTimeout(() => {
                    inputRefSearch.current.value = "";
                  }, 200);
                }}>
                <HighlightOffOutlinedIcon
                  style={{ color: "#888" }}
                  fontSize="small"
                />
              </IconButton>
            </Box>
          </Box>

          {listOfBread[0] === "Gallery" ||
          listOfBread[0] === "Recycle Bin" ||
          listOfBread[0] === "Recovered Files & Folder" ? (
            <div className={classes.menuContainer}>
              <Tooltip
                title={context.switchToListView ? "Grid View" : "List View"}>
                <IconButton
                  className={classes.menuIcon}
                  onClick={() =>
                    context.setSwitchToListView((prevState) => {
                      context.setSelectedFolder([]); // Empty Selected Folder
                      context.setSelectedFile([]); // Empty Selected File

                      return !prevState;
                    })
                  }>
                  {!context.switchToListView ? (
                    <GridOnRoundedIcon className={classes.menuIconColor} />
                  ) : (
                    <FormatListBulletedRoundedIcon
                      className={classes.menuIconColor}
                    />
                  )}
                </IconButton>
              </Tooltip>

              {/* Select All For RecycleBin */}
              {listOfBread[0] === "Recycle Bin" && (
                <>
                  <Tooltip title="Restore Multiple">
                    <IconButton
                      className={classes.menuIcon}
                      onClick={() => setOpenDialogRestoreMultiple(true)}>
                      <StyledBadge
                        badgeContent={
                          context.selectedFolder.length +
                          context.selectedFile.length
                        }>
                        <RestoreRoundedIcon className={classes.menuIconColor} />
                      </StyledBadge>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      !context.toggleSelect
                        ? "Select Mode"
                        : "Cancel Select Mode"
                    }>
                    <IconButton
                      className={classes.menuIcon}
                      onClick={() => context.setToggleSelect((prev) => !prev)}>
                      {!context.toggleSelect ? (
                        <DoneAllRoundedIcon className={classes.menuIconColor} />
                      ) : (
                        <ClearRoundedIcon className={classes.menuIconColor} />
                      )}
                    </IconButton>
                  </Tooltip>
                </>
              )}

              {/* Select All For Recovered Files & Folder */}
              {listOfBread[0] === "Recovered Files & Folder" && (
                <>
                  <Tooltip title="Delete">
                    <IconButton
                      className={classes.menuIcon}
                      onClick={() => setOpenDialogDeleteMultiple(true)}>
                      <StyledBadge
                        badgeContent={
                          context.selectedFolder.length +
                          context.selectedFile.length
                        }>
                        <DeleteRoundedIcon className={classes.menuIconColor} />
                      </StyledBadge>
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Move">
                    <IconButton
                      className={classes.menuIcon}
                      onClick={() => setOpenDialogMoveMultiple(true)}>
                      <StyledBadge
                        badgeContent={
                          context.selectedFolder.length +
                          context.selectedFile.length
                        }>
                        <SyncAltIcon className={classes.menuIconColor} />
                      </StyledBadge>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      !context.toggleSelect
                        ? "Select Mode"
                        : "Cancel Select Mode"
                    }>
                    <IconButton
                      className={classes.menuIcon}
                      onClick={() => context.setToggleSelect((prev) => !prev)}>
                      {!context.toggleSelect ? (
                        <DoneAllRoundedIcon className={classes.menuIconColor} />
                      ) : (
                        <ClearRoundedIcon className={classes.menuIconColor} />
                      )}
                    </IconButton>
                  </Tooltip>
                </>
              )}

              {/* Select All & Filter Status For Gallery */}
              {listOfBread[0] === "Gallery" && (
                <Fragment>
                  <Tooltip title="Download File Multiple">
                    <IconButton
                      className={classes.menuIcon}
                      onClick={handleOpenDownloadMultiple}>
                      <StyledBadge
                        badgeContent={
                          context.selectedFolder.length +
                          context.selectedFile.length
                        }>
                        <CloudDownloadOutlinedIcon
                          className={classes.menuIconColor}
                        />
                      </StyledBadge>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      !context.toggleSelect
                        ? "Select Mode"
                        : "Cancel Select Mode"
                    }>
                    <IconButton
                      className={classes.menuIcon}
                      onClick={() => context.setToggleSelect((prev) => !prev)}>
                      {!context.toggleSelect ? (
                        <DoneAllRoundedIcon className={classes.menuIconColor} />
                      ) : (
                        <ClearRoundedIcon className={classes.menuIconColor} />
                      )}
                    </IconButton>
                  </Tooltip>
                  {listOfBread.length === 2 && (
                    <>
                      <FormControlLabel
                        label={
                          <p
                            style={{ color: "#aaa", fontSize: 14 }}
                            className={classes.title}>
                            Active
                          </p>
                        }
                        control={
                          <Checkbox
                            style={{ marginLeft: 20 }}
                            checked={context.statusActive}
                            onChange={(e) =>
                              context.setStatusActive(e.target.checked)
                            }
                            className={classes.checkboxRoot}
                            disableRipple
                            color="default"
                            checkedIcon={
                              <span
                                className={clsx(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            icon={<span className={classes.icon} />}
                            inputProps={{ "aria-label": "decorative checkbox" }}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          <p
                            style={{ color: "#aaa", fontSize: 14 }}
                            className={classes.title}>
                            Overdue
                          </p>
                        }
                        control={
                          <Checkbox
                            style={{ marginLeft: 20 }}
                            checked={context.statusOverdue}
                            onChange={(e) =>
                              context.setStatusOverdue(e.target.checked)
                            }
                            className={classes.checkboxRoot}
                            disableRipple
                            color="default"
                            checkedIcon={
                              <span
                                className={clsx(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            icon={<span className={classes.icon} />}
                            inputProps={{ "aria-label": "decorative checkbox" }}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          <p
                            style={{ color: "#aaa", fontSize: 14 }}
                            className={classes.title}>
                            Completed
                          </p>
                        }
                        control={
                          <Checkbox
                            style={{ marginLeft: 20 }}
                            checked={context.statusCompleted}
                            onChange={(e) =>
                              context.setStatusCompleted(e.target.checked)
                            }
                            className={classes.checkboxRoot}
                            disableRipple
                            color="default"
                            checkedIcon={
                              <span
                                className={clsx(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            icon={<span className={classes.icon} />}
                            inputProps={{ "aria-label": "decorative checkbox" }}
                          />
                        }
                      />
                    </>
                  )}
                </Fragment>
              )}
            </div>
          ) : (
            <div className={classes.menuContainer}>
              <Tooltip title="Upload File">
                <IconButton
                  className={classes.menuIcon}
                  disabled={!createAllowed}
                  onClick={() => setOpenDialogUploadFile(true)}>
                  <CloudUploadIcon className={classes.menuIconColor} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete">
                <IconButton
                  className={classes.menuIcon}
                  onClick={() => setOpenDialogDeleteMultiple(true)}>
                  <StyledBadge
                    badgeContent={
                      context.selectedFolder.length +
                      context.selectedFile.length
                    }>
                    <DeleteRoundedIcon className={classes.menuIconColor} />
                  </StyledBadge>
                </IconButton>
              </Tooltip>

              <Tooltip title="Move">
                <IconButton
                  className={classes.menuIcon}
                  onClick={() => setOpenDialogMoveMultiple(true)}>
                  <StyledBadge
                    badgeContent={
                      context.selectedFolder.length +
                      context.selectedFile.length
                    }>
                    <SyncAltIcon className={classes.menuIconColor} />
                  </StyledBadge>
                </IconButton>
              </Tooltip>

              <Tooltip
                title={context.switchToListView ? "Grid View" : "List View"}>
                <IconButton
                  className={classes.menuIcon}
                  onClick={() =>
                    context.setSwitchToListView((prevState) => {
                      context.setSelectedFolder([]); // Empty Selected Folder
                      context.setSelectedFile([]); // Empty Selected File

                      return !prevState;
                    })
                  }>
                  {!context.switchToListView ? (
                    <GridOnRoundedIcon className={classes.menuIconColor} />
                  ) : (
                    <FormatListBulletedRoundedIcon
                      className={classes.menuIconColor}
                    />
                  )}
                </IconButton>
              </Tooltip>

              <Tooltip
                title={
                  !context.toggleSelect ? "Select Mode" : "Cancel Select Mode"
                }>
                <IconButton
                  className={classes.menuIcon}
                  onClick={() => context.setToggleSelect((prev) => !prev)}>
                  {!context.toggleSelect ? (
                    <DoneAllRoundedIcon className={classes.menuIconColor} />
                  ) : (
                    <ClearRoundedIcon className={classes.menuIconColor} />
                  )}
                </IconButton>
              </Tooltip>

              <Tooltip title="Refresh">
                <IconButton
                  className={classes.menuIcon}
                  onClick={handleRefresh}>
                  <RefreshRoundedIcon className={classes.menuIconColor} />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </Box>
      </Grid>
      <Grid item lg={2}>
        <div className={classes.settingContainer} style={{ marginLeft: 0 }}>
          <Box display="flex" justifyContent="flex-end">
            <Tooltip title="Setting">
              <IconButton
                className={classes.menuIcon}
                disabled={!manageAllowed}
                onClick={handleShowAnchorSetting}>
                <SettingsIcon className={classes.menuIconColor} />
              </IconButton>
            </Tooltip>

            {rootFolderPath !== "drive" && (
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElSetting}
                keepMounted
                open={Boolean(anchorElSetting)}
                onClose={handleCloseAnchorSetting}>
                <StyledMenuItem
                  style={{ width: 200 }}
                  onClick={() => {
                    setOpenDialogFolderSetting(true);
                    setAnchorElSetting(null);
                  }}>
                  <ListItemIcon>
                    <FolderIcon
                      fontSize="small"
                      className={classes.hoverColor}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        className={classes.rightMenuText}>
                        Folder Setting
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                {/* <StyledMenuItem>
                <ListItemIcon>
                  <RestorePageIcon
                    fontSize="small"
                    className={classes.hoverColor}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.rightMenuText}>
                      Restore Files
                    </Typography>
                  }
                />
              </StyledMenuItem> */}
              </StyledMenu>
            )}
            <Button
              className={classes.button}
              style={{ marginLeft: 40, width: 150 }}
              disabled={!createAllowed}
              variant="contained"
              onClick={handleShowAnchoeCreate}>
              Create
            </Button>

            <Menu
              id="simple-menu"
              anchorEl={anchorElCreate}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              getContentAnchorEl={null}
              open={Boolean(anchorElCreate)}
              onClose={handleCloseAnchorCreate}>
              <MenuItem
                onClick={() => {
                  setOpenDialogCreateFolder(true);
                  setAnchorElCreate(null);
                }}
                style={{ width: 150 }}>
                <Typography variant="subtitle2" className={classes.title}>
                  Folder
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenDialogCreatePlainDoc(true);
                  setAnchorElCreate(null);
                }}>
                <Typography variant="subtitle2" className={classes.title}>
                  Plain Document
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </div>
      </Grid>

      <DialogCreateFolder
        openDialogCreateFolder={openDialogCreateFolder}
        setOpenDialogCreateFolder={setOpenDialogCreateFolder}
        classes={classes}
      />

      <DialogUploadFile
        openDialogUploadFile={openDialogUploadFile}
        setOpenDialogUploadFile={setOpenDialogUploadFile}
        classes={classes}
      />

      <DialogDeleteMultiple
        openDialogDeleteMultiple={openDialogDeleteMultiple}
        setOpenDialogDeleteMultiple={setOpenDialogDeleteMultiple}
        classes={classes}
      />

      <DialogMoveMultiple
        openDialogMoveMultiple={openDialogMoveMultiple}
        setOpenDialogMoveMultiple={setOpenDialogMoveMultiple}
        classes={classes}
      />

      <DialogMultipleRestore
        openDialogRestoreMultiple={openDialogRestoreMultiple}
        setOpenDialogRestoreMultiple={setOpenDialogRestoreMultiple}
        classes={classes}
      />

      <DialogDownloadMultipleGallery
        openDialogDownloadMultiple={openDialogDownloadMultiple}
        setOpenDialogDownloadMultiple={setOpenDialogDownloadMultiple}
        classes={classes}
      />

      <DialogFolderSetting
        openDialogFolderSetting={openDialogFolderSetting}
        setOpenDialogFolderSetting={setOpenDialogFolderSetting}
        classes={classes}
      />

      <DialogCreatePlainDoc
        openDialogCreatePlainDoc={openDialogCreatePlainDoc}
        setOpenDialogCreatePlainDoc={setOpenDialogCreatePlainDoc}
        classes={classes}
      />
    </Grid>
  );
}

export default MenuSection;

import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Fab,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import PhotoLibraryRoundedIcon from "@material-ui/icons/PhotoLibraryRounded";

import ImageItem from "./DriveComponent/ImageItem";
import VideoItem from "./DriveComponent/VideoItem";
import DocItem from "./DriveComponent/DocItem";
import DriveItem from "./DriveComponent/DriveItem";
import LinkItem from "./DriveComponent/LinkItem";

import ContextAllMA from "../context/AllMaContext";

import {
  FORMAT_TYPE_IMAGE_IN_MA_RESULT,
  FORMAT_TYPE_VIDEO_IN_MA_RESULT,
  FORMAT_TYPE_DOC_IN_MA_RESULT,
} from "../../../../constants/config-type-file";
import {
  MARE_CATEGORY_DRIVE,
  MARE_CATEGORY_LINK,
} from "../../../../constants/config-drive";

import DialogAddEvidenceAllMA from "./DialogAddEvidenceAllMA";
import DialogCreateText from "./DialogCreateText";
import DialogNavImgMA from "./DialogNavImgMA";

const Uploader = (props) => {
  const {
    classes,
    collectionMaResultList,
    item,
    userTokenState,
    maItem,
    maDetailState,
  } = props;
  const context = useContext(ContextAllMA);

  /*
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */
  let listPict = [];
  const [listPictState, setListPictState] = useState([]);
  const [anchorEvidence, setAnchorEvidence] = useState(null);
  const [showFade, setShowFade] = useState(false);
  const [openDialogAddEvidence, setOpenDialogAddEvidence] = useState(false);
  const [openDialogPlainText, setOpenDialogPlainText] = useState(false);

  useEffect(() => {
    if (item !== null) {
      listPict.push(item);

      setListPictState(item);
      setShowFade(true);
    } else {
      setListPictState([]);
    }
  }, [collectionMaResultList]);

  // ========= Check Attchment Item ===========
  const checkAttach =
    listPictState.length > 0 &&
    listPictState.some((el) => el.content === "attachment");

  /*
        ``````````````````````````````````
        OPEN DIALOG LIHAT PICT LEBIH BESAR

        ``````````````````````````````````
    */
  const [openDialogNavImg, setOpenDialogNavImg] = useState(false);
  /*
        `````````````````````````````````````````````````
        ON MOUSE OVER & ON MOUSE LEAVE DELETE IMAGE/VIDEO

        `````````````````````````````````````````````````
    */

  /*
        `````````````````````````````````````````````````````````````````
        HANDLE OPEN DIALOG DELETE PICT BUKTI GOAL RESULT in DATABASE

        `````````````````````````````````````````````````````````````````
    */
  const [isOpenDialogDeleteBuktiInDB, setOpenDialogDeleteBuktiInDB] =
    useState(false);

  const handleShowAnchorEvidence = (event) => {
    setAnchorEvidence(event.currentTarget);
  };

  const handleCloseAnchorEvidence = () => {
    setAnchorEvidence(null);
  };

  // console.log("Item MARR", item);

  return (
    <Grid container alignItems="flex-start" justify="left">
      <div style={{ marginLeft: 40 }}>
        <br />
        <Grid container spacing={8} alignItems="center">
          {listPictState.length > 0 &&
            listPictState.map((tile, i) => {
              // DELETE / VIEW POLICY (HAK AKSES)
              let viewAllowed = false;
              let deleteAllowed = false;

              tile.policy.forEach((premission) => {
                // console.log("Polisi GOAL R", premission);

                if (premission.actionName === "viewDetail") {
                  viewAllowed = premission.result;
                } else if (premission.actionName === "delete") {
                  deleteAllowed = premission.result;
                }
              });

              if (
                viewAllowed &&
                tile.format_type_id === FORMAT_TYPE_IMAGE_IN_MA_RESULT
              ) {
                return (
                  <ImageItem
                    i={i}
                    tile={tile}
                    classes={classes}
                    showFade={showFade}
                    userToken={userTokenState}
                    deleteAllowed={deleteAllowed}
                    listPictState={listPictState}
                    setListPictState={setListPictState}
                  />
                );
              }

              if (
                viewAllowed &&
                tile.format_type_id === FORMAT_TYPE_VIDEO_IN_MA_RESULT
              ) {
                return (
                  <VideoItem
                    i={i}
                    tile={tile}
                    classes={classes}
                    showFade={showFade}
                    userToken={userTokenState}
                    deleteAllowed={deleteAllowed}
                    listPictState={listPictState}
                    setListPictState={setListPictState}
                  />
                );
              }

              if (
                viewAllowed &&
                tile.format_type_id === FORMAT_TYPE_DOC_IN_MA_RESULT
              ) {
                return (
                  <DocItem
                    i={i}
                    tile={tile}
                    classes={classes}
                    showFade={showFade}
                    deleteAllowed={deleteAllowed}
                    listPictState={listPictState}
                    setListPictState={setListPictState}
                  />
                );
              }

              if (
                viewAllowed &&
                tile.evidence_category_id === MARE_CATEGORY_DRIVE
              ) {
                return (
                  <DriveItem
                    i={i}
                    tile={tile}
                    classes={classes}
                    showFade={showFade}
                    deleteAllowed={deleteAllowed}
                    listPictState={listPictState}
                    setListPictState={setListPictState}
                  />
                );
              }

              if (
                viewAllowed &&
                tile.evidence_category_id === MARE_CATEGORY_LINK
              ) {
                return (
                  <LinkItem
                    i={i}
                    tile={tile}
                    classes={classes}
                    showFade={showFade}
                    deleteAllowed={deleteAllowed}
                    listPictState={listPictState}
                    setListPictState={setListPictState}
                  />
                );
              }
            })}

          <Fab
            size="small"
            className={classes.buttonFab}
            disabled={item.length === 5}
            onClick={handleShowAnchorEvidence}>
            <AddIcon fontSize="small" />
          </Fab>
          <Menu
            id="simple-menu"
            anchorEl={anchorEvidence}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            getContentAnchorEl={null}
            open={Boolean(anchorEvidence)}
            onClose={handleCloseAnchorEvidence}>
            <MenuItem
              onClick={() => {
                setOpenDialogAddEvidence(true);
                setAnchorEvidence(null);
              }}
              style={{ width: 150 }}>
              <Typography variant="subtitle2" className={classes.title}>
                Add Evidence
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDialogPlainText(true);
                setAnchorEvidence(null);
              }}
              style={{ width: 150 }}>
              <Typography variant="subtitle2" className={classes.title}>
                Plain Document
              </Typography>
            </MenuItem>
          </Menu>

          {checkAttach && (
            <Tooltip title="Image Slide Show">
              <IconButton
                style={{ marginLeft: 10 }}
                size="small"
                onClick={() => setOpenDialogNavImg(true)}>
                <PhotoLibraryRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </div>

      <DialogAddEvidenceAllMA
        openDialogAddEvidence={openDialogAddEvidence}
        setOpenDialogAddEvidence={setOpenDialogAddEvidence}
        classes={classes}
        mareLength={item.length}
        item={maItem}
        maDetailState={maDetailState}
        // setFireGoalDetail={setFireGoalDetail}
      />

      <DialogCreateText
        classes={classes}
        openDialogPlainText={openDialogPlainText}
        setOpenDialogPlainText={setOpenDialogPlainText}
        item={maItem}
        triggerSuccess={context.setSuccessCreateMAR}
      />

      <DialogNavImgMA
        openDialogNavImg={openDialogNavImg}
        setOpenDialogNavImg={setOpenDialogNavImg}
        listPictState={listPictState}
        userToken={userTokenState}
      />
    </Grid>
  );
};

export default Uploader;

import React from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import ProgressBarItem from "./ProgressBarItem";
import PictEmptyStateGoal from "../../../7WD/Goal/Components/PictEmptyStateGoal";

import { styles } from "../../../7WD/Goal/Style/StyleGoal";
import ImageSmallUser from "../../../../components/ImageSmallUser";

const numeral = require("numeral");

const useStyle = makeStyles(styles);

const CardGoalTop = (props) => {
  const { collectionGoalTeratas } = props;
  const classes = useStyle();

  const userToken = localStorage.getItem("userToken");

  // useEffect()

  return (
    <Box marginTop={theme.spacing(0.3)}>
      <Paper
        elevation={1}
        className={
          collectionGoalTeratas.length === 0 ? classes.paperTransparent : null
        }>
        <br />

        {collectionGoalTeratas.length === 0 && (
          <Grid container direction="row" justify="center" alignItems="center">
            <Box
              // marginTop={theme.spacing(1.4)}
              marginBottom={theme.spacing(0.8)}>
              <Box display="flex" justifyContent="center">
                <PictEmptyStateGoal />
              </Box>

              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "grey" }}>
                Tidak ada{" "}
                <b>
                  <i>Goal teratas</i>
                </b>{" "}
                yang muncul saat ini.
              </Typography>
            </Box>
          </Grid>
        )}

        {collectionGoalTeratas.length > 0 && (
          <Grid container>
            <Grid item sm={12} md={12} xl={12}>
              <Box
                marginLeft={theme.spacing(0.2)}
                marginBottom={theme.spacing(0.2)}>
                <Typography variant="subtitle2" className={classes.title}>
                  <b>
                    {`${
                      collectionGoalTeratas.length <= 5
                        ? collectionGoalTeratas.length
                        : 5
                    } Goal Teratas`}{" "}
                  </b>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}

        <Grid container>
          <Grid item xs={12}>
            <List>
              {collectionGoalTeratas.length > 0 &&
                collectionGoalTeratas.slice(0, 5).map((item, i) => {
                  return (
                    <ListItem key={i}>
                      <ListItemAvatar>
                        <Avatar>
                          {item.member_photo_full_url !== null &&
                          item.member_photo_full_url !== undefined ? (
                            <ImageSmallUser
                              item={item.member_photo_full_url}
                              index={1}
                            />
                          ) : (
                            <AccountCircleIcon fontSize="large" />
                          )}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                            style={{ color: "grey" }}>
                            {`${item.member_first_name}`} (
                            <i>{`${numeral(
                              item.overall_result_achievement * 100
                            ).format("0.00")}%`}</i>
                            )
                          </Typography>
                        }
                        secondary={<ProgressBarItem item={item} index={i} />}
                      />
                    </ListItem>
                  );
                })}
            </List>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default CardGoalTop;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

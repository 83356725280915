import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Box,
  TextField,
} from "@material-ui/core";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import DialogError from "../../../../components/DialogError";
import ContextReports from "../../context/ContextReports";
import handleError from "../global/handleError";

const DialogSaveConfig = ({
  classes,
  openDialogSaveConfig,
  setOpenDialogSaveConfig,
}) => {
  const handleSaveConfig = () => {};

  return (
    <Dialog
      open={openDialogSaveConfig}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Save Configuration</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ textAlign: "left", width: 600 }}>
        <Typography
          variant="subtitle2"
          className={classes.title888}
          style={{ textAlign: "justify" }}>
          Laporan ini hanya dapat dilihat oleh Anda. Setelah disimpan, Anda
          dapat melihat laporan dengan mengklik ikon List Saved Configuration
          tanpa mengkonfigurasi kembali dari awal.
        </Typography>

        <Box display="flex" alignItems="center" mt={4}>
          <Typography variant="subtitle2" className={classes.title888}>
            <b>Title</b>
          </Typography>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            style={{ marginLeft: 20 }}
          />
        </Box>
      </DialogContent>

      <DialogActions
        style={{
          backgroundColor: "#ECECEC",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: 15,
          marginTop: 20,
        }}>
        <Button
          variant="outlined"
          disableElevation
          size="medium"
          className={classes.button0}
          onClick={() => setOpenDialogSaveConfig(false)}>
          Cancel
        </Button>

        <Button
          variant="outlined"
          size="medium"
          className={classes.button1}
          onClick={handleSaveConfig}>
          {/* {loading ? "downloading..." : "Export"} */}
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSaveConfig;

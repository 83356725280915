import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import logoPerformate from "../../../assets/images/performate-03.png";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  textParagraph: {
    color: "#888",
    textAlign: "justify",
  },
});

const useStyles = makeStyles(styles);

const TermCondition = () => {
  const classes = useStyles();

  return (
    <div>
      <section style={{ paddingTop: 85, textAlign: "center" }}>
        <div className="box-about">
          <img src={logoPerformate} width="50px" />
          <h1>Term and Conditions</h1>
        </div>
      </section>
      <Container maxWidth="md" style={{ marginTop: 20 }}>
        <Typography variant="subtitle1">
          <b>Subscription Period (Masa berlangganan)</b>
        </Typography>
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Masa berlangganan dihitung sejak tanggal pembayaran dari invoice yang
          dikirimkan ketika memulai berlangganan, atau ketika memperpanjang masa
          berlangganan.
        </Typography>
        <br />

        <Typography variant="subtitle1">
          <b>Trial & Free Period (Masa percobaan dan masa gratis)</b>
        </Typography>
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Trial Period/Masa Percobaan diberikan kepada user akun personal ketika
          pertama kali mendaftarkan emailnya pada aplikasi kami, lama masa trial
          adalah 30 hari tanpa dikenakan biaya. Setelah masa trial berakhir,
          maka user wajib membayar sesuai dengan opsi berlangganan yang dipilih.
        </Typography>
        <br />

        <Typography variant="subtitle1">
          <b>Lapsed Period (Masa Tenggang) </b>
        </Typography>
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Untuk pelanggan berbayar, akan diberikan 1 bulan masa tenggang setelah
          masa keanggotaan berakhir. Setelah 1 bulan, akun akan di non-aktifkan
          sampai tagihan sebelumnya dan subscription baru dibayarkan.
        </Typography>
        <br />

        <Typography variant="subtitle1">
          <b>Invoice (Tagihan)</b>
        </Typography>
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Tagihan akan diterbitkan 14 hari sebelum masa berlangganan berakhir,
          dan paling lambat pembayaran di saat masa berlangganan berakhir.
          Nominal tagihan membership yang harus dibayarkan sesuai dengan jumlah
          membership yang terdaftar atau diajukan ketika memulai berlangganan
          atau ketika memperpanjang masa membership.
        </Typography>
        <br />

        <Typography variant="subtitle1">
          <b>Refund Policy (Kebijakan Pengembalian Uang)</b>
        </Typography>
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Semua transaksi dengan status pembayaran selesai tidak dapat
          dikembalikan, kecuali jika aplikasi tidak dapat diakses lebih dari
          2x24 jam tanpa notifikasi lebih awal, atau adanya kesalahan dari
          konsultan kami dengan melampirkan bukti-bukti yang cukup.
        </Typography>
        <br />

        <Typography variant="subtitle1">
          <b>Payment (Pembayaran)</b>
        </Typography>
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Pembayaran dilakukan setelah tagihan/Invoice dikirimkan melalui email,
          setelah klien melakukan pembayaran, klien wajib untuk melaporkan bukti
          pembayaran melalui email yang terlampir di invoice.
        </Typography>
        <br />

        <Typography variant="subtitle1">
          <b>Termination (Pemutusan Akses)</b>
        </Typography>
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Pemutusan akses dapat terjadi jika klien berhenti untuk berlangganan
          atau tidak melakukan kewajibannya pada tenggat waktu yang sudah
          ditentukan.
        </Typography>
        <br />

        <Typography variant="subtitle1">
          <b>Post Inactive Data Access (Akses Data Pasca non Aktif)</b>
        </Typography>
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Data yang sudah klien input ke dalam sistem akan dapat digunakan
          kembali oleh klien ketika mengaktifkan kembali akun yang non aktif
          dalam waktu tidak lebih dari 1 tahun
        </Typography>
        <br />

        <Typography variant="subtitle1">
          <b>Security</b>
        </Typography>
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Keamanan informasi pribadi anda penting bagi kami, kami berusaha untuk
          menggunakan sarana yang terbaik untuk melindungi informasi pribadi
          anda.
        </Typography>
        <br />

        <Typography variant="subtitle1">
          <b>Konsultan/PMC</b>
        </Typography>
        <Typography variant="subtitle2" className={classes.textParagraph}>
          Klien tidak diperkenankan untuk mengajak, memberi penawaran, janji
          atau pun mempengaruhi Konsultan kami yang ditugaskan di lingkungan
          Klien untuk meninggalkan, berhenti, atau pindah dari perusahaan kami
          tanpa izin dari pihak kami terlebih dahulu, kecuali jika klien
          bersedia memberikan ganti biaya investasi yang sudah kami keluarkan.
        </Typography>
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default TermCondition;

/**
 * Utilities
 */
import React, { useCallback, useEffect, useState, Fragment } from "react";
import moment from 'moment';
import axios from 'axios';
import { URL_API } from '../../../../constants/config-api';
import {
    getDateFormat,
    getServerDateFormat
} from '../Utilities/PeriodUtil';
/**
 * Core
 */
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography, Grid, CircularProgress } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

/**
 * Form
 */
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

/**
 * Dialog
 */
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

/**
 * DateRange
 */
//import { DateRangePicker, DateRange } from "@matharumanpreet00/react-daterange-picker";
//import { Calendar } from 'react-date-range';

// https://github.com/Adphorus/react-date-range
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';


/**
 * Custom Component
 */
import ErrorIconDialog from '../../../../assets/images/icon-info-24px.svg';
import Snackbeer from '../../../../components/Snackbeer';
const CreatePeriod = props => {

	/*
		```````````````````````````````````
		INIT

		```````````````````````````````````
    */
    const {
        classes,
        openCreateForm,
        setOpenCreateForm,
        
        setListPeriode,
        listPeriode,
    } = props;

    const userToken = localStorage.getItem('userToken');
    const [ masterIntervalCollections, setMasterIntervalCollections ] = useState([]);
    const [ loader, setLoader ] = useState(true);

    const theme = useTheme();

    // form state
    const [ inputName, setInputName ] = useState('');
    const [ inputIntervalId, setInputIntervalId ] = useState('');
    const [ inputStartDate, setInputStartDate ] = useState( moment().format( getServerDateFormat() ) );
    const [ inputEndDate, setInputEndDate ] = useState( moment().format( getServerDateFormat() ) ); 
    const [ fakeInputStartDate, setFakeInputStartDate ] = useState( moment().format( getDateFormat() ) );
    const [ fakeInputEndDate, setFakeInputEndDate ] = useState( moment().format( getDateFormat() ) );

    useEffect(() => {		
        if ( userToken !== undefined ) {
			let header = {
                'Accept': "application/json",
                'Content-Type' : "application/json",
                'Authorization' : "bearer " + userToken,
			};
			
			axios.defaults.headers.common = header;    
			
			// GET LIST LABEL
            axios
                .get(URL_API + `/swd/master-period/create`)
                .then((response) => {
                    let responseData = response.data.data;

                    // set default interval_id
                    let masterIntervalCollections = responseData.masterIntervalCollections;

                    for (let i in masterIntervalCollections) {
                        if (masterIntervalCollections[i]['code'].toLowerCase() === 'custom') {
                            console.log(masterIntervalCollections[i]);
                            setInputIntervalId(masterIntervalCollections[i]['id']);
                        }
                    }
                    setLoader(false);
                    setMasterIntervalCollections(masterIntervalCollections);
      
                    console.log("Response Original GET LIST LABEL   : ", response);
                })
                .catch((error) => {
                    setLoader(false);
                    console.log("Error : ", error.response)
				});
		}
		
    },[]);
    
	/*
		```````````````````````````````````
		HANDLE FORM 

		```````````````````````````````````
    */
   
    /**
     * Handling form input name
     * 
     * @param {object} evt event object
     * @return {void}
     * 
     */
    const formInputName = (evt) => {
        setInputName(evt.target.value);
    }

    /**
     * Handling form input interval_id
     * 
     * @param {object} evt event object
     * @return {void}
     * 
     */
    const formInputIntervalId = (evt) => {
        setInputIntervalId(evt.target.value);
    }

    /**
     * Handling form input start_date
     * 
     * @param {object} evt event object
     * @return {void}
     * 
     */
    const formInputStartDate = (evt) => {
        setInputStartDate(evt.target.value);
    }

    /**
     * Handling form input end_date
     * 
     * @param {object} evt event object
     * @return {void}
     * 
     */
    const formInputEndDate = (evt) => {
        setInputEndDate(evt.target.value);
    }

    /**
     * Handling when click submit button
     * 
     * @param {object} evt event object
     * @return {void}
     * 
     */
    const handleSubmit = (evt) => {
        // formatting
        let bodyInputName = inputName;
        let bodyIntervalId = inputIntervalId;
        let bodyStartDate = inputStartDate;
        let bodyEndDate = inputEndDate;

        // ASSIGN TO BODY
        let body =  {
            MasterPeriod: {
                name: bodyInputName,
                interval_id: bodyIntervalId,
                start_date: bodyStartDate,
                end_date: bodyEndDate,
            }
        }
        let isValid = true;

        if ( isValid && userToken) {
            let header = {
                'Accept': "application/json",
                'Content-Type' : "application/json",
                'Authorization' : "bearer " + userToken,
            };
            
            axios.defaults.headers.common = header;    
            
            // GET LIST PERIODE
            axios
                .post(URL_API + `/swd/master-period`, body)
                .then((response) => {
                    setListPeriode([ ...listPeriode, response.data.data ]);
                    setOpenSnackbeer(true);
                    setOpenCreateForm(false);
                    console.log("Response Original CREATE PERIODE   : ", response);
                })
                .catch((error) => {
                    if (error.response !== undefined) {
                        let resData = error.response.data;
                        
                        // when bad request
                        if (resData.info.status == 400) {
                            setErrorStatus(resData.info.status);
                            setOpenErrorDialog(true);
                            setErrorTitle('Validation Error');
                            setErrorContent(resData.info.errors);
                            console.log(resData.info.errors);

                        } else if (resData.info.status == 404) {
                            setErrorStatus(resData.info.status);
                            setOpenErrorDialog(true);
                            setErrorTitle('Page Not Found');
                            setErrorContent({ "description": 'The request does not exists' });
                        } else if (resData.info.status == 500) {
                            setErrorStatus(resData.info.status);
                            setOpenErrorDialog(true);
                            setErrorTitle('Internal Server Error');
                            setErrorContent({ "description": 'whoops, something went wrong!' });
                        } else {
                            setErrorStatus(-1);
                            setOpenErrorDialog(true);
                            setErrorTitle('Cannot connect to server');
                            setErrorContent({ "description": 'Sorry, we cannot reach the server. Please check your connection' });
                        }
                    } else {
                        
                        setErrorStatus(-1);
                        setOpenErrorDialog(true);
                        setErrorTitle('Cannot connect to server');
                        setErrorContent({ "description": 'Sorry, we cannot reach the server. Please check your connection' });
                    }

                });
        } else {
            alert("whoops something went wrong!");
        }
    }
    
    /**
     * Handling when closing create form
     * 
     * @param {object} evt event object
     * @return {void}
     * 
     */
    const handleClose = (evt) => {
        setOpenCreateForm(false);
    }
    
	/*
		```````````````````````````````````
		HANDLE DATE RANGE 

		```````````````````````````````````
    */
    const [ openDateRange, setOpenDateRange ] = useState(false);
    const [ startDateInDateRange, setStartDateInDateRange ] = useState( moment() );
    const [ endDateInDateRange, setEndDateInDateRange ] = useState( moment() );

    /**
     * Handling click open date range picker
     * 
     * @param {object} evt event object
     * @return {void}
     * 
     */
    const handleOpenDateRange = (evt) => {
        setOpenDateRange(true);
    }

    /**
     * Handling close date range picker
     * 
     * @param {object} evt event object
     * @return {void}
     * 
     */
    const handleCloseDateRange = (evt) => {
        setOpenDateRange(false);
    } 

    /**
     * Handling select calendar in date range picker
     * 
     * @param {object} evt event object
     * @return {void}
     * 
     */
    const handleSelectDateRange = (evt) => {
        setStartDateInDateRange( moment(evt.selection.startDate) );
        setEndDateInDateRange( moment(evt.selection.endDate) );
        

        // update its interval
        let newInterval = getIntervalFromStartDateAndEndDate( evt.selection.startDate, evt.selection.endDate );
        console.log(newInterval);
        if (newInterval) {
            // set selected interval id
            setInputIntervalId( newInterval['id'] );
        }
    }

    /**
     * Handling click date range picker.
     * 
     * @param {object} evt event object.
     * @return {void}
     * 
     */
    const handleClickDateRange = (evt) => {
        setInputStartDate(moment(startDateInDateRange).format( getServerDateFormat() ));
        setInputEndDate(moment(endDateInDateRange).format( getServerDateFormat() ));

        setFakeInputStartDate( moment(startDateInDateRange).format( getDateFormat()) );
        setFakeInputEndDate( moment(endDateInDateRange).format( getDateFormat()) );

        setOpenDateRange(false);
    }

    /**
     * Handling click interval id button to update end date.
     * 
     * @param {object} evt event object.
     * @param {object} interval consist of interval rule.
     * @return {void}.
     * 
     */
    const handleClickIntervalId = (evt, interval) => {
        console.log(interval);
        // prevent
        evt.preventDefault()
        // change start date and end date
        let newEndDate = getIntervalEndDate( startDateInDateRange.format( getServerDateFormat() ), interval);
        // set input interval id
        setInputIntervalId( interval['id'] );
        // set form input end date
        setInputEndDate( moment(newEndDate).format( getDateFormat() ) );
        // set selected end date in date range
        setEndDateInDateRange( moment(newEndDate) );
    }
    
    /**
     * Getting end date from start date based on interval.
     * 
     * @param {string} startDate start date.
     * @param {object} interval consist of interval rule.
     * @return {string} outp end date.
     * 
     */
    const getIntervalEndDate = (startDate, interval) => {
        // define default output
        let outp = moment(startDate).format( getServerDateFormat() );

        // define default end date
        let momentEndDate = moment(startDate);
        // when interval is defined and not null
        if (interval && interval.unit !== null && interval.value !== null) {
            if (interval.unit.toLowerCase() == 'year') {
                // when interval unit rule is year
                outp = momentEndDate.add(interval.value, 'year')
                                    .subtract(1, 'days')
                                    .format( getServerDateFormat() );
            } else if (interval.unit.toLowerCase() == 'month') {
                // when interval unit rule is month
                outp = momentEndDate.add(interval.value, 'month')
                                    .subtract(1, 'days')
                                    .format( getServerDateFormat() );    
            } else {
                // set default value when there is no rule in interval
                outp = momentEndDate.format( getServerDateFormat() );
            }
        } else {
            // set default value when interval is undefined or null
            outp = momentEndDate.format( getServerDateFormat() );
        }

        return outp;
    }

    /**
     * Getting interval from given start date and and end date.
     * 
     * @param {string} srcStartDate string value of start date.
     * @param {string} srcEndDate string value of end date.
     * @return {object|null} outp contains interval object.
     * 
     */
    const getIntervalFromStartDateAndEndDate = (srcStartDate, srcEndDate) => {
        // define default output
        let outp = null;
        // define moment from given startDate and endDate
        let momentSrcStartDate = moment(srcStartDate);
        let momentSrcEndDate = moment(srcEndDate);

        // finding the item
        if ( masterIntervalCollections.length > 0) {
            for ( let i in masterIntervalCollections ) {
                let newEndDate = moment(
                    getIntervalEndDate(momentSrcStartDate.format( getServerDateFormat() ),
                    masterIntervalCollections[ i ])
                );
                if ( momentSrcEndDate.diff(newEndDate, 'days') == 0 ) {
                    outp = masterIntervalCollections[ i ];
                    break;
                } else if ( masterIntervalCollections[ i ].code == 'custom') {
                    // when there is no interval matching then it must be a custom
                    outp = masterIntervalCollections[ i ];
                }
            }
        }
        
        return outp;
    }

    /**
     * Handling error dialog
     * 
     */
    const [ errorStatus, setErrorStatus ] = useState(0);
    const [ openErrorDialog, setOpenErrorDialog ] = useState(false);
    const [ errorTitle, setErrorTitle ] = useState('');
    const [ errorContent, setErrorContent ] = useState({});
    const handleCloseErrorDialog = (evt) => {
        setErrorStatus(0);
        setOpenErrorDialog(false);
        setErrorTitle('');
        setErrorContent({});
    }

    /**
     * Handle success dialog
     */
    const [ openSnackbeer, setOpenSnackbeer ] = useState(false);

    return (
        <Fragment>
            {
                // <!-- Error Dialog -->
                errorStatus !== 0 &&
                (
                    <Dialog
                        open={ openErrorDialog }
                        onClose={ handleCloseErrorDialog }
                    >
                        <DialogTitle style={{ textAlign: "center" }}>
                            <img src={ ErrorIconDialog } style={{ height: '96px' }} alt='Pict Info '/>
                            <br />
                            <br />
                            <Typography className={classes.title} style={{color: 'black'}}>
                                <b>{ errorTitle }</b>
                            </Typography>

                            {
                                console.log(Object.keys(errorContent))
                            }
                            {
                                console.log(errorContent)
                            }
                            {
                                errorContent !== null && Object.keys(errorContent).length > 0 && Object.keys(errorContent).map((row, i) => (
                                    
                                    <div key={"error-lists-" + row} className={classes.title}>
                                        { errorContent[row] }
                                    </div>
                                ))
                            }

                        </DialogTitle>

                        <DialogContent style={{ textAlign: "center" }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Button 
                                        variant='contained' 
                                        onClick={ handleCloseErrorDialog }
                                        size='medium'
                                        className={ classes.button }
                                    >  
                                        Saya Mengerti
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <br />
                        <br />
                    </Dialog>
                )
                // <!-- /Error Dialog -->
            }
            {
                openSnackbeer && (
                    <Snackbeer
                        isModalResponse200={openSnackbeer}
                        setIsModalResponse200={setOpenSnackbeer}
                    />
                )
            }
            <Dialog 
                open={openCreateForm}
                onClose={handleClose}
            >
                <DialogTitle className={`${ classes.title }`} style={{ textAlign: 'center' }}>Tambah Periode</DialogTitle>
                <DialogContent style={{ minWidth: '500px' }}>
                    {
                        loader == true ? (
                            <Grid container>
                                <Grid item xs={5}></Grid>
                                <Grid item xs={2} style={{ textAlign: 'center' }}>
                                    <CircularProgress />
                                </Grid>
                                <Grid item xs={5}></Grid>
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid container>
                                    <Typography className={ `${ classes.title}` }>Title</Typography>
                                    <TextField
                                        margin="normal"
                                        variant="outlined"
                                        onChange={formInputName}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid container>
                                    <Grid item xs={5}>
                                        <Typography className={ `${ classes.title}` }>Mulai</Typography>
                                        <TextField
                                            style={{ borderRight: '' }}
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={ fakeInputStartDate }
                                            onClick={ handleOpenDateRange }
                                            fullWidth
                                        />
                                        <input type="hidden" value={ inputStartDate } />
                                    </Grid>
                                    <Grid item xs={2} style={{ textAlign: 'center' }}>
                                        <br />
                                        <br />
                                        <br />
                                        &nbsp;-&nbsp;
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography className={ `${ classes.title}` }>Berakhir</Typography>
                                        <TextField
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={ fakeInputEndDate }
                                            onClick={ handleOpenDateRange }
                                            fullWidth
                                        />
                                        <input type="hidden" value={ inputEndDate } />
                                    </Grid>
                                </Grid>

                                {
                                    // <-- Span -->
                                    <Grid container>
                                        <Grid item xs={12}>&nbsp;</Grid>
                                    </Grid>
                                    // <!-- /Span -->
                                }
                                
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Button
                                            className={ `${ classes.button } ${ classes.title }` }
                                            variant="contained"
                                            color="secondary"
                                            onClick={ handleSubmit }
                                            fullWidth
                                        >
                                            Tambah Periode
                                        </Button>
                                    </Grid>
                                </Grid>
                                
                                {
                                    // <-- Span -->
                                    <Grid container>
                                        <Grid item xs={12}>&nbsp;</Grid>
                                    </Grid>
                                    // <!-- /Span -->
                                }

                            </Fragment>
                        )
                    }
                </DialogContent>
                
                {
                    // <!-- date range -->
                }
                <Dialog
                    aria-labelledby="simple-dialog-title"
                    open={openDateRange} 
                    onClose={handleCloseDateRange}
                >
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseDateRange}>
                        <Typography>Pilih Mulai dan Berakhir</Typography>
                    </DialogTitle>

                    <DialogContent style={{ minWidth: '500px' }}>

                        <Grid container>
                            <Grid item xs={12} >
                                <div style={{ marginLeft: '-250px', transform: 'scale(0.80, 0.80)', paddingTop: 0, marginTop: 0,  }}>
                                    <DateRangePicker
                                        ranges={[ {
                                            startDate: startDateInDateRange,
                                            endDate: endDateInDateRange,
                                            key: 'selection',
                                        } ]}
                                        rangedCalendars={ true }
                                        onInit={ handleSelectDateRange }
                                        onChange={ handleSelectDateRange }
                                        months={2}
                                        direction={ 'horizontal' }
                                        staticRanges={[]}
                                        inputRanges={[]}
                                    />
                                </div>

                            </Grid>
                        </Grid>

                        {
                            // <!-- interval selector -->
                        }
                        <Grid container>
                            <Grid item xs={12}>
                                {
                                    masterIntervalCollections.length > 0 && masterIntervalCollections.map((item, i) => (
                                        <Fragment key={ item['id'] }>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="secondary" 
                                                className={ classes.title }
                                                onClick={ (e) => handleClickIntervalId(e, item) }
                                                style={ item['id'] == inputIntervalId ? {
                                                    textTransform: 'capitalize',
                                                    color: 'white'
                                                } : {
                                                    textTransform: 'capitalize',
                                                    backgroundColor: 'rgba(0,0,0,0.1)',
                                                    color: 'gray'
                                                } }
                                            >
                                                { item.name }
                                            </Button>&nbsp;
                                        </Fragment>
                                    ))
                                }
                            </Grid>
                        </Grid>

                        {
                            // <!-- span -->
                        }
                        <Grid container>
                            <Grid item xs={12}>
                                &nbsp;
                            </Grid>
                        </Grid>
                        {
                            // <!-- /span -->
                        }

                        <Grid container>
                            <Grid item xs={5}>
                            
                                <Button
                                    variant="contained"
                                    onClick={ handleClickDateRange }
                                    fullWidth
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={5}>
                                <Button
                                    className={`${classes.title} ${classes.button}`}
                                    variant="contained"
                                    color="secondary"
                                    onClick={ handleClickDateRange }
                                    fullWidth
                                >
                                    Pilih Daterange
                                </Button>
                            </Grid>
                        </Grid>

                        {
                            // <!-- /span --?
                        }
                        <Grid container>
                            <Grid item xs={12}>&nbsp;</Grid>
                        </Grid>
                        {
                            // <!-- /span -->
                        }
                    
                    </DialogContent>
                </Dialog>
                {
                    // <!-- /date range -->
                }

            </Dialog>

            

        </Fragment>
    );
}

export default CreatePeriod;
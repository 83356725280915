import React, { Fragment, useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  CircularProgress,
  Button,
  Box,
  DialogActions,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import CheckSharpIcon from "@material-ui/icons/CheckSharp";
import CloseIcon from "@material-ui/icons/Close";

import { styles } from "../Style/StyleSubGoal";
import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import ContextGoalDetail from "../Context/ContextGoalDetail";
import Snackbeer from "../../../../components/Snackbeer";
import IconWarningYellow from "../../../../assets/images/icon_warning_yellow.png";
import DialogError from "../../../../components/DialogError";
import DialogFeedbackSetAsCompleteSubGoal from "./DialogFeedbackSetAsCompleteSubGoal";

const useStyles = makeStyles(styles);

function DialogSetAsCompleteMultiple({
  isOpenDialogSetAsCompleteMultiple,
  setOpenDialogSetAsCompleteMultiple,
  goalId,
  subGoalId,
  listSubGoalId,
  setSelected,
  validGoal,
}) {
  const classes = useStyles();
  const context = useContext(ContextGoalDetail);

  const [loader, setLoader] = useState(false);
  const [loadingGoal, setLoadingGoal] = useState(false);
  const [isModalResponse200, setModalResponse200] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] =
    useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  const [listGoal, setListGoal] = useState([]);

  const [responseSetAsComplete, setResponseSetAsComplete] = useState([]);
  const [openDialogFeedbackSetAsComplete, setOpenDialogFeedbackSetAsComplete] =
    useState(false);

  useEffect(() => {
    if (isOpenDialogSetAsCompleteMultiple === true) {
      function getDataGoal() {
        setLoadingGoal(true);
        return Promise.all(
          listSubGoalId.map((item) => {
            return axiosConfig.get(`${URL_API}/swd/goal/${item}`);
          })
        )
          .then((response) => {
            // console.log("ORI Response SAC", response);
            setListGoal(response);

            setLoadingGoal(false);
          })
          .catch((error) => {
            setLoadingGoal(false);
            console.log("Error : ", error);
          });
      }

      getDataGoal();
    }
  }, [isOpenDialogSetAsCompleteMultiple]);

  /* ==========================
   Check Validation Goal
    If True (success.length > 0) = Valid
    If False (success.length is empty : []) = Not Valid 
    =========================== */
  const checkValidGoal = validGoal.map(
    (item) => item.data.data.success.length > 0
  );

  const handleSetAsComplete = () => {
    return Promise.all(
      listSubGoalId.length > 0 &&
        listSubGoalId.map((item, index) => {
          setLoader(true);
          const params = new URLSearchParams();
          params.append(`Goal[${index}][id]`, item);

          return axiosConfig.put(
            `${URL_API}/swd/goal/batch:completed`,
            params,
            {
              timeout: 20000,
            }
          );
        })
    )
      .then((response) => {
        console.log("Set As Complete Sub Goalz", response);
        setResponseSetAsComplete(response);
        setOpenDialogFeedbackSetAsComplete(true);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log("Error : ", error);
      });
  };

  return (
    <Fragment>
      <Dialog
        open={isOpenDialogSetAsCompleteMultiple}
        onClose={() => setOpenDialogSetAsCompleteMultiple(false)}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <img
            src={IconWarningYellow}
            alt="info-icon-pict-info"
            style={{ width: 48, height: 40 }}
          />
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}>
            <b>
              Apakah Anda yakin ingin mengubah status {subGoalId.length}{" "}
              <i>Sub-Goal</i> menjadi <i>Completed</i> ?
            </b>
            <br />
            {loadingGoal ? (
              <CircularProgress style={{ color: "#d1354a", fontSize: 14 }} />
            ) : (
              <List>
                {listGoal.length > 0 &&
                  listGoal.map((item, i) => (
                    <ListItem key={i}>
                      <ListItemAvatar>
                        {checkValidGoal[i] ? (
                          <CheckSharpIcon style={{ color: "#1EBC61" }} />
                        ) : (
                          <CloseIcon style={{ color: "#d1354a" }} />
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <span
                            style={{
                              color: "#333",
                              fontStyle: "normal",
                            }}>
                            <b>{item.data.data.name}</b>{" "}
                            <span
                              className={
                                checkValidGoal[i]
                                  ? classes.validGoal
                                  : classes.notValidGoal
                              }>
                              {checkValidGoal[i] ? "valid" : "tidak valid"}
                            </span>
                          </span>
                        }
                        secondary={
                          <span
                            style={{
                              fontStyle: "normal",
                            }}>
                            {item.data.data.member.first_name}{" "}
                            {item.data.data.member.last_name}
                          </span>
                        }
                      />
                    </ListItem>
                  ))}
              </List>
            )}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "grey" }}>
            <i>
              Hanya goal yang Valid yang dapat diubah statusnya menjadi
              Completed
            </i>
          </Typography>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Box marginLeft={4}>
            <Button
              onClick={handleSetAsComplete}
              // onClick={() => seOpenDialogConfirmDelete(false)}
              variant="outline"
              className={classes.buttonOutlined}
              // fullWidth
            >
              {loader !== true ? (
                "Ya"
              ) : (
                <CircularProgress size={16} style={{ color: "red" }} />
              )}
            </Button>

            <Button
              size="small"
              // onClick={handleDelete}
              onClick={() => setOpenDialogSetAsCompleteMultiple(false)}
              variant="contained"
              className={classes.buttonModalDelete}
              // fullWidth
            >
              Batal
            </Button>
          </Box>
        </DialogActions>
        <br />
      </Dialog>

      <Snackbeer
        classes={classes}
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages={`Berhasil mengubah ${listSubGoalId.length} Sub Goal menjadi Completed !`}
      />

      <DialogFeedbackSetAsCompleteSubGoal
        classes={classes}
        openDialogFeedbackSetAsComplete={openDialogFeedbackSetAsComplete}
        setOpenDialogFeedbackSetAsComplete={setOpenDialogFeedbackSetAsComplete}
        responseSetAsComplete={responseSetAsComplete}
        listGoal={listGoal}
        setOpenDialogSetAsCompleteMultiple={setOpenDialogSetAsCompleteMultiple}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformationValue}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
}

export default DialogSetAsCompleteMultiple;

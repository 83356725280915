import React from "react";
import { MenuItem, Typography, Divider } from "@material-ui/core";

import PieChartOutlinedIcon from "@material-ui/icons/PieChartOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

const ListItemMenu = ({ classes, overview, setOverview, setAnchorMenu }) => {
  const handleOverview = () => {
    if (overview.code === "goal") {
      setOverview({ name: "Overview MA", code: "ma" });
    }

    if (overview.code === "ma") {
      setOverview({ name: "Overview Goal", code: "goal" });
    }

    setAnchorMenu(null);
  };

  return (
    <>
      <MenuItem style={{ width: 190 }} onClick={handleOverview}>
        <PieChartOutlinedIcon
          fontSize="small"
          style={{ color: "#8F8F8F", marginRight: 10 }}
        />
        <Typography variant="subtitle2" className={classes.title}>
          Overview {overview.code === "goal" ? "MA" : "Goal"}
        </Typography>
      </MenuItem>
      {/* <MenuItem style={{ width: 190 }}>
        <SettingsOutlinedIcon
          fontSize="small"
          style={{ color: "#8F8F8F", marginRight: 10 }}
        />
        <Typography variant="subtitle2" className={classes.title}>
          Chart Settings
        </Typography>
      </MenuItem>
      <MenuItem style={{ width: 190 }}>
        <ExitToAppOutlinedIcon
          fontSize="small"
          style={{ color: "#8F8F8F", marginRight: 10 }}
        />
        <Typography variant="subtitle2" className={classes.title}>
          Export
        </Typography>
      </MenuItem>

      <Divider />

      <MenuItem style={{ width: 190 }}>
        <DeleteOutlinedIcon
          fontSize="small"
          style={{ color: "#8F8F8F", marginRight: 10 }}
        />
        <Typography variant="subtitle2" className={classes.title}>
          Delete
        </Typography>
      </MenuItem> */}
    </>
  );
};

export default ListItemMenu;

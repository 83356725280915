import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  TextField,
  Button,
  InputAdornment,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import StarsIcon from "@material-ui/icons/Stars";

import useSWR from "swr";

import Axios from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";
import {
  USER_LICENSE,
  PMO_VISIT,
} from "../../../MembershipManagement/constant-data";

import Card3Items from "./Card3Items";
import DialogCheckoutExtend from "./DialogCheckoutExtend";

const DialogExtend = ({
  classes,
  openDialogExtend,
  setOpenDialogExtend,
  detailPckg,
}) => {
  const userData = localStorage.getItem("status_user_login");
  const user = JSON.parse(userData);
  const groupName = user.membership_account_type_name;
  const pckgName = user.membership_package_name;
  const accTypeId = user.membership_account_type_id;
  const pckgeId = user.membership_package_id;

  // =============== STATE ===============
  const [extendVal, setExtendVal] = useState(1);
  const [userLicense, setUserLicense] = useState(0);
  const [pmoVisit, setPmoVisit] = useState(0);
  const [voucherCode, setVoucherCode] = useState("");
  const [statusRes, setStatusRes] = useState(null);
  const [voucherErrorsList, setVoucherErrosList] = useState([]);
  const [openDialogCheckoutExtend, setOpenDialogCheckoutExtend] = useState(
    false
  );
  const fetcher = (url) =>
    Axios.get(url).then((r) => {
      if (r.status === 200) {
        setStatusRes(r.status);
      }

      return r.data.data;
    });

  useEffect(() => {
    if (openDialogExtend) {
      detailPckg.membership_items.forEach((item) => {
        if (item.membership_item_id === USER_LICENSE) {
          setUserLicense(item.quantity);
        } else if (item.membership_item_id === PMO_VISIT) {
          setPmoVisit(item.quantity);
        }
      });
    }
  }, [openDialogExtend]);

  // console.log("pckG", detailPckg);

  const root_url = `account-management/membership-payment:calculate`;
  const act_type = `MembershipPayment[membership_account_type_id]=${accTypeId}`;
  const pckg_id = `MembershipPayment[membership_package_id]=${pckgeId}`;
  const dur_val = `MembershipPayment[membership_duration_value]=${extendVal}`;
  const user_license_id = `MembershipPayment[membership_item][0][id]=${USER_LICENSE}`;
  const user_license_count = `MembershipPayment[membership_item][0][quantity]=${userLicense}`;
  // additional
  const pmo_id = `MembershipPayment[membership_item][1][id]=${PMO_VISIT}`;
  const pmo_count = `MembershipPayment[membership_item][1][quantity]=${pmoVisit}`;
  const voucher = `MembershipPayment[membership_voucher_code]=${voucherCode}`;

  let FINAL_URL;
  const C_URL1 = `${URL_API}/${root_url}?${act_type}&${pckg_id}&${dur_val}&${user_license_id}&${user_license_count}&${voucher}`;
  const C_URL2 = `${URL_API}/${root_url}?${act_type}&${pckg_id}&${dur_val}&${user_license_id}&${user_license_count}&${pmo_id}&${pmo_count}&${voucher}`;

  if (pmoVisit === 0) {
    FINAL_URL = C_URL1;
  } else if (userLicense !== 0 && pmoVisit !== 0) {
    FINAL_URL = C_URL2;
  }

  const { data: calculation, error: errCalculate } = useSWR(
    FINAL_URL,
    fetcher,
    {
      onError: (error) => {
        // console.log("Ew Ew", error);
        if (error.response !== undefined) {
          setStatusRes(error.response.status);
          setVoucherErrosList(error.response.data.info.errors);
        }
      },
    }
  );

  // console.log(
  //   "Extend Error",
  //   errCalculate !== undefined && errCalculate.response
  // );
  // console.log("Extend pay", calculation !== undefined && calculation);

  return (
    <Dialog
      open={openDialogExtend}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>
        <Typography variant="h6" className={classes.title333}>
          <b>Extend Membership</b>
        </Typography>
      </DialogTitle>
      <DialogContent style={{ width: 550 }}>
        <Card3Items
          classes={classes}
          account={groupName}
          pckgName={pckgName}
          prop1="User"
          prop2="Consultant Visit"
          prop3="Paket Membership"
          val1={userLicense}
          val2={pmoVisit}
          val3={pckgName}
          icon1={<PersonIcon fontSize="small" style={{ color: "#aaa" }} />}
          icon2={
            <ConfirmationNumberIcon
              fontSize="small"
              style={{ color: "#aaa" }}
            />
          }
          icon3={<StarsIcon fontSize="small" style={{ color: "#aaa" }} />}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop={3}>
          <Box>
            <Typography variant="subtitle2" className={classes.title333}>
              <b>Durasi</b>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              value={extendVal}
              onChange={(e) => setExtendVal(e.target.value)}
              style={{ width: 150, marginTop: 7 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="caption">Bulan</Typography>
                  </InputAdornment>
                ),
              }}
            />
            <div>
              {errCalculate !== undefined &&
                errCalculate.response !== undefined &&
                errCalculate.response.data.info.errors.map((err, i) => (
                  <Typography
                    key={i}
                    variant="caption"
                    className={classes.title}
                    style={{ color: "#d64253", marginTop: 3 }}>
                    {err.description}
                  </Typography>
                ))}
            </div>
          </Box>
          <Box textAlign="right">
            <Typography variant="subtitle2" className={classes.title333}>
              <b>Harga</b>
            </Typography>
            <Typography
              variant="h6"
              className={classes.title}
              style={{ color: "#d64253", marginTop: 7 }}>
              <b>
                Rp{" "}
                {calculation !== undefined &&
                  new Intl.NumberFormat("id-ID").format(
                    calculation.total_item_price_after_discount
                  )}
              </b>
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ margin: 10 }}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            size="small"
            className={classes.button3}
            onClick={() => setOpenDialogExtend(false)}
            style={{ width: 100, marginRight: 8 }}>
            Batal
          </Button>
          <Button
            variant="contained"
            size="small"
            className={classes.button2}
            disabled={extendVal < 1}
            style={{ width: 100 }}
            onClick={() => setOpenDialogCheckoutExtend(true)}>
            Extend
          </Button>
        </Box>
      </DialogActions>

      <DialogCheckoutExtend
        classes={classes}
        calculation={calculation !== undefined && calculation}
        duration={extendVal}
        userLicense={userLicense}
        pmoVisit={pmoVisit}
        openDialogCheckoutExtend={openDialogCheckoutExtend}
        setOpenDialogCheckoutExtend={setOpenDialogCheckoutExtend}
        setOpenDialogExtend={setOpenDialogExtend}
        onVoucher={setVoucherCode}
        voucherErrorsList={voucherErrorsList}
        statusRes={statusRes}
      />
    </Dialog>
  );
};

export default DialogExtend;

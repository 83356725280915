import React, { useState, useEffect, useContext, Fragment } from "react";
import {
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import ContextGoalDetail from "../Context/ContextGoalDetail";

import DialogError from "../../../../components/DialogError";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

const DialogDelete = (props) => {
  const {
    classes,
    isModalDelete,
    setModalDelete,
    goalResultId,
    goalDetailState,
    setFireGoalDetail,
  } = props;

  const context = useContext(ContextGoalDetail);

  const [userTokenState, setUserTokenState] = useState("");

  useEffect(() => {
    if (isModalDelete == true) {
      const userToken = localStorage.getItem("userToken");
      setUserTokenState(userToken);

      setFireGoalDetail(false);
    }
  }, [isModalDelete]);

  const [loader, setLoader] = useState(false);
  const handleDelete = () => {
    setLoader(true);
    setFireGoalDetail(false);

    if (userTokenState !== "") {
      axiosConfig
        .delete(
          URL_API + `/swd/goal/${goalDetailState.id}/result/${goalResultId}`
        )
        .then(function (response) {
          setLoader(false);

          console.log("Response Original : ", response);
          if (response.status === 200) {
            setModalDelete(false);

            // console.log("aloha FROM Hell");

            setFireGoalDetail(true);
            context.seTriggerGR(Math.floor(Math.random() * 1000 + 1));

            context.setModalResponse200(true);
            context.setMessages("Berhasil menghapus Goal Result !");
          }
        })
        .catch(function (error) {
          console.log("Error : ", error.response);

          setLoader(false);

          if (error.response !== undefined) {
            setErrorStatus(error.response.status);
            if (error.response.status === 404) {
              setOpenDialogError(true);
              setTextErrorInformation(
                `Error ${error.response.status} : ` +
                  "Goal Result sudah terhapus, tapi Anda mencoba menghapusnya kembali !"
              );
            } else {
              setOpenDialogError(true);
              setTextErrorInformation(
                `Error ${error.response.status} : ` +
                  " Whoops, something went wrong !"
              );
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformation(
              `Error ${error.response.status} : ` +
                " Whoops, something went wrong !"
            );
            // alert(error.response.status + ' Whoops, something went wrong !')
          }
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */

  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState("");

  return (
    <Fragment>
      <Dialog
        open={isModalDelete}
        onClose={() => setModalDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          style={{ textAlign: "left" }}></DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "black" }}>
              <b>
                Apakah Anda yakin ingin menghapus <i>Goal Result</i> ini ?
              </b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            onClick={handleDelete}
            variant="contained"
            className={classes.buttonModalDelete}
            // fullWidth
          >
            {loader !== true ? (
              "Yakin"
            ) : (
              <CircularProgress size={16} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
      />
    </Fragment>
  );
};

export default DialogDelete;

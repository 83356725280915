import React, { useEffect, useState, useContext, Fragment } from "react";
import {
  Typography,
  TextField,
  Checkbox,
  Box,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  TableRow,
  TableCell,
  Fade,
  Badge,
} from "@material-ui/core";

import axios from "axios";
import clsx from "clsx";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

// import Snackbeer from '../../../components/Snackbeer';
import ContextTask from "../Context/ContextTask";

import DialogEditTDL from "./DialogEditTDL";
import DialogDeleteTDL from "./DialogDeleteTDL";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../components/StyledMenuDropdown";
import LightTooltip from "../../../components/LightTooltip";
import TruncateTextShortInTASK from "../../../utilities/TruncateTextShortInTASK";
import { URL_API } from "../../../constants/config-api";

import moment from "moment";
import ImageSmallUser from "../../../components/ImageSmallUser";

const numeral = require("numeral");

const COMPLETED = "todolist_status_id_completed";

const TableItem = (props) => {
  const {
    classes,
    row,
    valueSearch,
    handleSearch,
    key,
    selected,
    setSelected,
    isSelected,
  } = props;

  const context = useContext(ContextTask);

  /*
      ````````````````````
      DROPDOWN EDIT DELETE

      ````````````````````
    */
  const [anchorElEditDelete, setAnchorElEditDelete] = useState(null);

  function handleDropdownChooseEditDelete(event, data) {
    console.log("Data from horiz_icon : ", data);

    context.setDataDetailHoriz(data);

    setAnchorElEditDelete(event.currentTarget);
  }

  function handleCloseDropdownEditDelete() {
    setAnchorElEditDelete(null);
  }

  /*
        ``````````````````
        HANDLE DIALOG EDIT

        ``````````````````
    */
  const [isOpenDialogEditTdl, setOpenDialogEditTdl] = useState(false);

  /*
        ````````````````````
        HANDLE DIALOG DELETE

        ````````````````````
    */

  const [isModalDeleteTDL, setModalDeleteTDL] = useState(false);

  /*
        ``````````````````````
        HANDLE SET AS COMPLETE

        ``````````````````````
    */
  // const [checked, setChecked] = useState(false);

  /*
        `````````````````````````
        HANDLE SET AS IN COMPLETE

        `````````````````````````
    */

  const [isRunFade, setRunFade] = useState(false);

  const handleChangeCheckbox = (e, data) => {
    // console.log("Data  : ", data);
    // console.log(e.target.checked);

    // setChecked(e.target.checked);

    setRunFade(true);

    let readyData = {
      Todolist: {
        // actual_result: context.actualResultGlobal,
        // note: context.valueNoteGlobal

        actual_result: actualResult,
        note: valueNote,
      },
    };

    if (context.userTokenState !== undefined) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + context.userTokenState,
      };

      axios.defaults.headers.common = header;

      if (data.status.code === COMPLETED) {
        axios
          .put(`${URL_API}/todolist/${data.id}:incompleted`, readyData)
          .then(function (response) {
            // console.log("Response Original : ", response);

            if (response.status === 200) {
              context.setSuccessTaskINCOMPLETED(true);

              setRunFade(false);
              context.setSnackbarResponse200(true);
              context.setMessages(
                "Berhasil mengembalikan status " +
                  data.name +
                  " menjadi incomplete !"
              );

              //*Set Default in AKTUAL RESULT & NOTES
              setValueNote("");
              setActualResult(0);
            }
          })
          .catch(function (error) {
            console.log("Error : ", error.response);
          });
      } else {
        axios
          .put(`${URL_API}/todolist/${data.id}:completed`, readyData)
          .then(function (response) {
            // console.log("Response Original : ", response);

            handleSearch(valueSearch);

            if (response.status === 200) {
              context.setSuccessTaskCOMPLETED(true);

              // context.setRunFade(false)
              setRunFade(false);

              context.setSnackbarResponse200(true);
              context.setMessages(data.name + " sudah complete :)");

              //*Set Default in AKTUAL RESULT & NOTES
              setValueNote("");
              setActualResult(0);
            }
          })
          .catch(function (error) {
            console.log("Error : ", error.response);
          });
      }
    } else {
      console.log("No Access Token available!");
    }
  };

  /*
        ```````````````````````````
        HANDLE CHANGE ACTUAL RESULT

        ``````````````````````````
    */
  const [actualResult, setActualResult] = useState(0);
  const handleValueActualResult = (e) => {
    e.preventDefault();
    // context.setActualResultGlobal(e.target.value)
    setActualResult(e.target.value);
  };

  /*
        ``````````````````
        HANDLE VALUE NOTE

        ``````````````````
    */
  const [valueNote, setValueNote] = useState("");
  const handleValueNote = (e) => {
    e.preventDefault();
    // context.setValueNoteGlobal(e.target.value);
    setValueNote(e.target.value);
  };

  const [isValueNoteKutuMacan, setValueNoteKutuMacan] = useState(false); //*Kutu Macan === "Too Much character"

  useEffect(() => {
    if (typeof valueNote === "string") {
      // console.log("Value Note : ", valueNote.length)
      if (valueNote.length > 24) {
        setValueNoteKutuMacan(true);
      } else {
        setValueNoteKutuMacan(false);
      }
    }
  }, [valueNote]);

  /* ==================================================
    Disable Some Operation/Function Depend On Strucuture
  ================================================== */

  let editAllowed = false;
  let deleteAllowed = false;
  let setCompleteAllowed = false;
  let incompletedAllowed = false;

  row.policy &&
    row.policy.forEach((item) => {
      if (item.actionName === "update") {
        editAllowed = item.result;
      } else if (item.actionName === "delete") {
        deleteAllowed = item.result;
      } else if (item.actionName === "completed") {
        setCompleteAllowed = item.result;
      } else if ((item.actionName = "incompleted")) {
        incompletedAllowed = item.result;
      }
    });

  /* ==================================================
    Select Single or Multiple Todo List
  ================================================== */

  // Choose Selected
  const handleGetSelectedId = (data) => {
    const selectedIndex = selected.indexOf(data);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, data);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);

    // console.log("neSel", newSelected);
  };

  const isItemSelected = isSelected(row.id);
  const labelId = `enhanced-table-checkbox-${key}`;

  return (
    <Fragment>
      <Fade
        in={isRunFade === true ? false : true}
        timeout={isRunFade === true ? 1000 : 300}>
        <TableRow
          key={row.id}
          role="checkbox"
          tabIndex={-1}
          aria-checked={isItemSelected}
          selected={isItemSelected}>
          <TableCell padding="checkbox">
            <Checkbox
              onClick={() => handleGetSelectedId(row.id)}
              disabled={!deleteAllowed}
              checked={isItemSelected}
              inputProps={{ "aria-labelledby": labelId }}
            />
          </TableCell>
          <TableCell component="th" scope="row">
            {row.status.id === "2" ? ( //*COMPLETED === 2, INCOMPLETED === 1
              <LightTooltip title={row.name} placement="top" arrow>
                <Typography
                  variant="subtitle2"
                  className={classes.checkboxRoot}
                  color="default"
                  disableRipple
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  style={{ textDecoration: "line-through" }}>
                  {TruncateTextShortInTASK(row.name)}
                </Typography>
              </LightTooltip>
            ) : (
              <LightTooltip title={row.name} placement="top" arrow>
                {moment(row.end_timestamp).isBefore(moment()) === true ? (
                  <Fragment>
                    {moment(row.end_timestamp).isSame(
                      moment().format("YYYY-MM-DD 00:00:00")
                    ) !== true ? (
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: "red" }}>
                        {TruncateTextShortInTASK(row.name)}
                      </Typography>
                    ) : (
                      <Typography variant="subtitle2" className={classes.title}>
                        {TruncateTextShortInTASK(row.name)}
                      </Typography>
                    )}
                  </Fragment>
                ) : (
                  <Typography variant="subtitle2" className={classes.title}>
                    {TruncateTextShortInTASK(row.name)}
                  </Typography>
                )}
              </LightTooltip>
            )}
          </TableCell>

          <TableCell component="th" scope="row">
            {row.status.id === "2" ? ( //*COMPLETED === 2, INCOMPLETED === 1
              <Typography
                variant="caption"
                className={classes.title}
                style={{ textDecoration: "line-through" }}>
                {moment(row.end_timestamp).format("DD MMMM YYYY")}
              </Typography>
            ) : (
              <Fragment>
                {moment(row.end_timestamp).isBefore(moment()) === true ? (
                  <Fragment>
                    {moment(row.end_timestamp).isSame(
                      moment().format("YYYY-MM-DD 00:00:00")
                    ) !== true ? (
                      <Typography
                        variant="caption"
                        className={classes.title}
                        style={{ color: "red" }}>
                        {moment(row.end_timestamp).format("DD MMMM YYYY")}
                      </Typography>
                    ) : (
                      <Typography variant="caption" className={classes.title}>
                        {moment(row.end_timestamp).format("DD MMMM YYYY")}
                      </Typography>
                    )}
                  </Fragment>
                ) : (
                  <Typography variant="caption" className={classes.title}>
                    {moment(row.end_timestamp).format("DD MMMM YYYY")}
                  </Typography>
                )}
              </Fragment>
            )}
          </TableCell>

          <TableCell component="th" scope="row">
            <ListItem style={{ padding: 0 }}>
              <ListItemAvatar>
                {row.owner !== undefined &&
                row.owner.member !== undefined &&
                row.owner.member.photo_url !== undefined &&
                row.owner.member.self !== undefined &&
                row.owner.member.self.rel !== undefined &&
                row.owner.member.self.rel.photo_url !== undefined ? (
                  <ImageSmallUser
                    item={row.owner.member.self.rel.photo_url}
                    index={1}
                  />
                ) : (
                  <AccountCircleIcon
                    fontSize="large"
                    style={{ color: "grey" }}
                  />
                )}
              </ListItemAvatar>

              <ListItemText
                style={{ marginLeft: 7 }}
                id="label-employee"
                primary={
                  <Typography variant="subtitle2" className={classes.title}>
                    <b>
                      {row.owner.member.first_name + " "}{" "}
                      {row.owner.member.last_name}
                    </b>
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    {row.email}
                  </Typography>
                }
              />
            </ListItem>
          </TableCell>

          <TableCell align="left">
            <Typography variant="subtitle2" className={classes.title}>
              {row.target_result !== null
                ? numeral(row.target_result).format("0,0")
                : "-"}
            </Typography>
          </TableCell>

          <TableCell align="left">
            {row.actual_result !== null ? (
              <Badge
                color="primary"
                variant="dot"
                classes={
                  row.actual_result > row.target_result ||
                  row.actual_result === row.target_result
                    ? {
                        colorPrimary: classes.badgeGreen,
                      }
                    : {
                        colorPrimary: classes.badgeRed,
                      }
                }>
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: "black" }}>
                  {numeral(row.actual_result).format("0,0")}
                </Typography>
              </Badge>
            ) : (
              <Box>
                <TextField
                  type="number"
                  onChange={handleValueActualResult}
                  // value={actualResult}
                  id="standard-basic"
                  placeholder="0"
                  inputProps={{
                    className: classes.titleTextField,
                  }}
                  // fullWidth
                />
              </Box>
            )}
          </TableCell>

          <TableCell component="th" scope="row">
            {row.note !== null ? (
              <LightTooltip title={row.note} placement="top-start" arrow>
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: "grey" }}>
                  <i> {TruncateTextShortInTASK(row.note)}</i>
                </Typography>
              </LightTooltip>
            ) : (
              <Box>
                {isValueNoteKutuMacan !== true ? (
                  <TextField
                    onChange={handleValueNote}
                    value={valueNote}
                    // id="standard-basic"
                    placeholder="Tulis Catatan..."
                    inputProps={{
                      className: classes.titleTextFieldNote,
                    }}
                    disabled={
                      row.status.id === "2" ? true : false //*COMPLETED === 2, INCOMPLETED === 1
                    }
                  />
                ) : (
                  <Fade in={true}>
                    <TextField
                      onChange={handleValueNote}
                      value={valueNote}
                      style={{ marginTop: 0 }}
                      id="outlined-multiline-static-visi"
                      // label="Multiline"
                      multiline
                      rows="3"
                      // className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        className: classes.title,
                      }}
                    />
                  </Fade>
                )}
              </Box>
            )}

            {/* 
                            <Typography variant='subtitle2' className={classes.title} style={{color: 'grey'}}>
                                <i> {row.note !== null ? row.note : '-'}</i>
                            </Typography> 
                        */}
          </TableCell>

          <TableCell align="center">
            <Checkbox
              disabled={
                row.status_id === "1"
                  ? !incompletedAllowed
                  : row.status_id === "2"
                  ? !setCompleteAllowed
                  : false
              }
              // value={checked}
              onChange={(e) => handleChangeCheckbox(e, row)}
              icon={
                row.status.id === "2" ? ( //*COMPLETED === 2, INCOMPLETED === 1
                  <CheckCircleOutlineRoundedIcon
                    style={
                      row.actual_result > row.target_result ||
                      row.actual_result === row.target_result
                        ? { color: "green" }
                        : { color: "red" }
                    }
                  />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )
              }
              checkedIcon={
                row.status.id === "1" ? (
                  <CheckBoxOutlineBlankIcon />
                ) : (
                  <CheckCircleOutlineRoundedIcon
                    style={
                      row.actual_result > row.target_result ||
                      row.actual_result === row.target_result
                        ? { color: "green" }
                        : { color: "red" }
                    }
                  />
                )
              }
            />
          </TableCell>

          <TableCell align="center">
            <IconButton
              // disabled={  row.status.id === '2'  ? true : false }  //*COMPLETED === 2, INCOMPLETED === 1}
              onClick={(e) => handleDropdownChooseEditDelete(e, row)}>
              <MoreHorizIcon />
            </IconButton>

            <StyledMenu
              id="customized-menu-wew"
              anchorEl={anchorElEditDelete}
              keepMounted
              open={Boolean(anchorElEditDelete)}
              onClose={handleCloseDropdownEditDelete}>
              <StyledMenuItem
                disabled={!editAllowed}
                onClick={() => setOpenDialogEditTdl(true)}>
                <Typography variant="subtitle2" className={classes.title}>
                  <b>Edit</b>
                </Typography>
              </StyledMenuItem>

              <StyledMenuItem
                disabled={!deleteAllowed}
                onClick={() => setModalDeleteTDL(true)}>
                <Typography variant="subtitle2" className={classes.title}>
                  <b>Delete</b>
                </Typography>
              </StyledMenuItem>
            </StyledMenu>
          </TableCell>
        </TableRow>
      </Fade>

      <DialogEditTDL
        classes={classes}
        isOpenDialogEditTdl={isOpenDialogEditTdl}
        setOpenDialogEditTdl={setOpenDialogEditTdl}
        handleCloseDropdownEditDelete={handleCloseDropdownEditDelete}
      />

      <DialogDeleteTDL
        classes={classes}
        setModalDeleteTDL={setModalDeleteTDL}
        isModalDeleteTDL={isModalDeleteTDL}
        handleCloseDropdownEditDelete={handleCloseDropdownEditDelete}
      />
    </Fragment>
  );
};

export default TableItem;

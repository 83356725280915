import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import ImageSmallUser from "../../../../components/ImageSmallUser";

const ButtonListItemPhotoAndOwnerName = (props) => {
  const { classes, fotoQueryContent, choosedMemberOwner, ownerMAName } = props;

  return (
    <List>
      <ListItem style={{ paddingLeft: 0 }}>
        <ListItemIcon>
          {fotoQueryContent !== "" && fotoQueryContent !== null ? (
            <ImageSmallUser item={fotoQueryContent} index={1} />
          ) : (
            <IconButton aria-haspopup="true" color="inherit">
              <AccountCircleIcon
                className={classes.imageAvatar}
                style={{ color: "#dcdcdc" }}
              />
            </IconButton>
          )}
        </ListItemIcon>

        <ListItemText
          className={classes.listItemtext}
          primary={
            <Button
              variant="outlined"
              size="small"
              className={classes.timeFrameIconInModal}>
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "grey" }}>
                Pilih owner :
              </Typography>
            </Button>
          }
          secondary={
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ marginLeft: 8 }}>
              <b>
                {choosedMemberOwner !== "" ? choosedMemberOwner : ownerMAName}
              </b>
            </Typography>
          }
        />
      </ListItem>
    </List>
  );
};

export default ButtonListItemPhotoAndOwnerName;

import React from "react";
import { Gluejar } from "@charliewilco/gluejar";

const PasteClipboard = ({ onFiles }) => {
  // ======================= Paste From Clipboard =======================
  const getImgClipboard = (files) => {
    const fileImg =
      files.length > 0 &&
      files.map(async (file, i) => {
        const images = await fetch(file)
          .then((r) => r.blob())
          .then(
            (blobFile) =>
              new File([blobFile], `img_clipboard_${[i]}.png`, {
                type: "image/png",
              })
          );

        console.log("satuan", images);
        // kosong.push(images);

        return Object.assign(images, { preview: file });
      });

    fileImg.length > 0 && Promise.all(fileImg).then((res) => onFiles(res));

    // console.log("gabungan", kosong);
  };

  return (
    <Gluejar
      onPaste={(filez) => getImgClipboard(filez.images)}
      onError={(err) => console.log("Errz>", err)}
    />
  );
};

export default PasteClipboard;

import React, { useEffect, useState } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import clsx from "clsx";

import {
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Collapse,
  InputBase,
} from "@material-ui/core";

import { navigate } from "hookrouter";
import { URL_API } from "../constants/config-api";

import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import ImageDashboardHome from "../assets/images/Subtraction_3.png";
import Image7WD from "../assets/images/Group_110.png";
import ImageHumanCapital from "../assets/images/Group_663.png";

import IconMenuOffCanvass from "../assets/images/Group_1923.png";
import IconMenuOffCanvassGrey from "../assets/images/SVG/Group_709.svg";

import { lightBlue, green } from "@material-ui/core/colors";

import axiosConfig from "../constants/config-axios";

import {
  ToDashboard,
  ToLogin,
  ToEmptyStateGeneral,
  ToNewSO,
  To7wdPeriode,
  ToGoal,
  ToTask,
} from "../constants/config-redirect-url";

import { styles } from "./Style/StyleHeadNewGeneral";

import DialogError from "../components/DialogError";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: "cyan",
      },
    },

    MuiToolbar: {
      root: {
        backgroundColor: "white",
      },
    },
    MuiButton: {
      text: {
        color: "white",
      },
      textSecondary: {
        color: "white",
        fontFamily: "Roboto",
      },
    },
    MuiDrawer: {
      root: {
        backgroundColor: lightBlue,
      },
      paper: {
        backgroundColor: green,
      },

      paperAnchorLeft: {},
    },
    MuiListItemIcon: {
      root: {
        color: "white",
      },
    },
    MuiBadge: {
      root: {
        color: "#d1354a",
      },
    },
  },
});

const HeadNewGeneral = (props) => {
  const { classes } = props;

  /*
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */

  const userToken = localStorage.getItem("userToken");
  const [userTokenState, setUserTokenState] = useState("");

  const [fotoQuery, setFotoQuery] = useState("");
  const [fotoQueryUser, setFotoQueryUser] = useState("");

  const [namaGroupPerusahaan, setNamaGroupPerusahaan] = useState("");
  const [roleName, setRoleName] = useState([]);

  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);

  useEffect(() => {
    setUserTokenState(userToken);

    if (statusUserLoginAfterParse !== null) {
      setFotoQueryUser(statusUserLoginAfterParse.member_photo_url);

      if (statusUserLoginAfterParse.userRole.length > 0) {
        setRoleName(statusUserLoginAfterParse.userRole[0].name);
      } else {
        console.log("Anda tidak memiliki ROLE sama sekali, hubungi Mas Tri !");
      }
    }

    if (userToken !== undefined) {
      axiosConfig
        .get(URL_API + `/group/profile/patch`)
        .then(function (response) {
          console.log("Response Original from HeadNewGeneral : ", response);

          if (response.status == 200) {
            if (response.data.data.fields !== undefined) {
              if (response.data.data.fields.photo_logo_icon !== "") {
                setFotoQuery(response.data.data.fields.photo_logo_icon.value);
                setNamaGroupPerusahaan(response.data.data.fields.name.value);
              }
            }
          }
        })
        .catch(function (error) {
          if (error.response !== undefined) {
            if (error.response.status == 403) {
              setErrorStatus(403);
              setOpenDialogError(true);
              setTextErrorInformation(error.response.data.info.message);
            }
          }

          console.log("Error : ", error.response);
        });
    }
  }, []);

  /* ````````````START DRAWER`````````````````````*/

  //   const classes = useStyles();
  const [state, setState] = useState({
    top: false,
    // left: true,
    left: false,
    bottom: false,
    right: false,
  });

  const handleCloseOffCanvass = () => {
    setState((state.left = false));
  };

  const toggleDrawer = (side, open) => () => {
    setState({ ...state, [side]: open });
  };

  /* ````````````END DRAWER ``````````````````````*/

  function handleLogOut() {
    localStorage.removeItem("userToken");
    localStorage.removeItem("intro_enabled");

    localStorage.removeItem("verifyToken");
    localStorage.removeItem("status_user_login");
    localStorage.removeItem("goal_detail");
    localStorage.removeItem("employee_id");
    localStorage.removeItem("response_employee_detail");

    localStorage.removeItem("value_search");
    localStorage.removeItem("status_goal_id");
    localStorage.removeItem("email");
    localStorage.removeItem("appVersion");

    navigate(ToLogin);
  }

  /* 
          ````````````````````````````````
            START APP DRAWER

          ````````````````````````````````
      */

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  /*
        `````````````````````
          LIST NESTED PROFILE
  
        `````````````````````
    */

  const [openNestedList, setOpenNestedList] = useState(false);

  function handleOpenNestedList() {
    setOpenNestedList(!openNestedList);
  }

  /*
        ```````````````
        LIST NESTED 7WD

        ```````````````
    */

  const [openNestedSevenWD, setOpenNestedSevenWD] = useState(false);

  function handleOpenSevenWD() {
    setOpenNestedSevenWD(!openNestedSevenWD);
  }

  /*
        ````````````````````````````````
          LIST NESTED HUMAN CAPITAL

        ````````````````````````````````
    */
  const [openNestedHumanCapital, setOpenNestedHumanCapital] = useState(false);

  function handleOpenHumanCapital() {
    setOpenNestedHumanCapital(!openNestedHumanCapital);
  }

  /*
        ````````````````````````````````
          ICON POJOK KANAN ATAS - MENU
  
        ````````````````````````````````
    */

  const ITEM_HEIGHT = 24;

  const options = ["Pengaturan User", "Log-out"];

  const [anchorElMenuPojokKananAtas, setAnchorElPoKaAtas] = useState(null);
  const isOpenMenuPojokKananAtas = Boolean(anchorElMenuPojokKananAtas);

  function handleMenuPojokKananAtas(event) {
    setAnchorElPoKaAtas(event.currentTarget);
  }

  function handleCloseMenuPojokKananAtas(e, item) {
    if (item == "Log-out") {
      handleLogOut();
    }

    setAnchorElPoKaAtas(null);
  }

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            onClick={toggleDrawer("left", true)}
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu">
            <img
              src={IconMenuOffCanvassGrey}
              alt="Icon Menu Grey"
              style={{ width: 28, height: 28 }}
            />
          </IconButton>

          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Ketik untuk pencarian..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "Search" }}
            />
          </div>

          <Grid container spacing={8} alignItems="flex-end"></Grid>

          {/* <IconButton color="inherit">
                        <Badge badgeContent={17} color="primary">
                            <NotificationsIcon className={classes.notifIcon}/>
                        </Badge>
                    </IconButton>

                    <IconButton color="inherit">
                        <MailIcon className={classes.mail}/>
                    </IconButton> */}

          <IconButton aria-haspopup="true" color="inherit">
            {fotoQueryUser !== "" &&
            fotoQueryUser !== null &&
            fotoQueryUser !== undefined ? (
              <img
                src={URL_API + "/" + fotoQueryUser + "&token=" + userToken}
                className={classes.bigRealFoto}
              />
            ) : (
              <AccountCircle className={classes.accountCircle} />
            )}
          </IconButton>

          <IconButton
            aria-label="More"
            aria-owns={isOpenMenuPojokKananAtas ? "long-menu" : undefined}
            aria-haspopup="true"
            onClick={handleMenuPojokKananAtas}>
            <ExpandMore />
          </IconButton>

          <Menu
            id="long-menu"
            anchorEl={anchorElMenuPojokKananAtas}
            open={isOpenMenuPojokKananAtas}
            onClose={handleCloseMenuPojokKananAtas}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200,
              },
            }}>
            {options.map((option) => (
              <MenuItem
                key={option}
                selected={option === "Pengaturan User"}
                onClick={(e) => handleCloseMenuPojokKananAtas(e, option)}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>

      {/* 
                ``````
                DRAWER

                ``````
            */}

      <Drawer open={state.left} onClose={toggleDrawer("left", false)}>
        <div
          style={{
            background:
              "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
            height: 1000,
            width: 303,
          }}>
          <span style={{ padding: 14, marginLeft: 244 }}>
            <img
              src={IconMenuOffCanvass}
              className={classes.iconMenuOffCanvass}
              onClick={handleCloseOffCanvass}
            />
          </span>

          <List component="nav" className={classes.drawerRoot}>
            <ListItem
              button
              onClick={handleOpenNestedList}
              style={{ paddingLeft: 0 }}>
              <ListItemIcon>
                {/* <img src={MahkotaLogoCompany} style={{marginRight: 0, paddingRight:0}}/> */}
                <IconButton
                  aria-haspopup="true"
                  // onClick={() => handleLogOut()}
                  color="inherit">
                  {fotoQuery !== "" ? (
                    <img
                      src={URL_API + "/" + fotoQuery + "&token=" + userToken}
                      className={classes.bigRealFotoDrawer}
                    />
                  ) : (
                    <AccountCircle className={classes.accountCircle} />
                  )}
                </IconButton>
              </ListItemIcon>

              <ListItemText
                secondary={
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "white",
                      fontFamily: "Roboto",
                    }}></Typography>
                }
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.namaPerusahaan}>
                    {namaGroupPerusahaan !== "" ? namaGroupPerusahaan : "-"}
                  </Typography>
                }
              />

              {/* {openNestedList ? <ExpandLess /> : <ExpandMore />} */}
            </ListItem>
            <Collapse
              in={openNestedList}
              timeout="auto"
              unmountOnExit></Collapse>

            <List className={classes.drawerRoot} component="nav">
              <ListItem button onClick={() => navigate(ToDashboard)}>
                <ListItemIcon>
                  <img src={ImageDashboardHome} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      type="PengaturanRole"
                      style={{ color: "white", fontFamily: "Roboto" }}>
                      Dashboard
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem button onClick={handleOpenSevenWD}>
                <ListItemIcon>
                  <img src={Image7WD} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      type="PengaturanRole"
                      style={{ color: "white", fontFamily: "Roboto" }}>
                      7WD
                    </Typography>
                  }
                />

                {openNestedSevenWD ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openNestedSevenWD} timeout="auto" unmountOnExit>
                <List component="nav" disablePadding>
                  <ListItem
                    onClick={() => navigate(ToGoal)}
                    button
                    className={classes.nested}>
                    <ListItemText
                      inset
                      primary={
                        <Typography
                          type="so"
                          style={{
                            color: "white",
                            fontFamily: "Roboto",
                            marginLeft: 10,
                          }}>
                          Goal
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    // onClick={() => Redirect(ToCompanyProfile)}
                    onClick={() => navigate(To7wdPeriode)}
                    button
                    className={classes.nested}>
                    <ListItemText
                      inset
                      primary={
                        <Typography
                          type="karyawan"
                          style={{
                            color: "white",
                            fontFamily: "Roboto",
                            marginLeft: 10,
                          }}>
                          Periode
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Collapse>

              {/* 
                            `````````````````````
                            HUMAN RESOURCE NESTED

                            `````````````````````
                          
                        */}

              <ListItem onClick={handleOpenHumanCapital} button>
                <ListItemIcon>
                  <img src={ImageHumanCapital} />
                </ListItemIcon>

                <ListItemText
                  primary={
                    <Typography
                      type="PengaturanRole"
                      style={{ color: "white", fontFamily: "Roboto" }}>
                      Human Capital
                    </Typography>
                  }
                />

                {openNestedHumanCapital ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse
                in={openNestedHumanCapital}
                timeout="auto"
                unmountOnExit>
                <List component="div" disablePadding>
                  {roleName == "superadmin" && (
                    <ListItem
                      onClick={() => navigate(ToNewSO)}
                      button
                      className={classes.nested}>
                      <ListItemText
                        inset
                        primary={
                          <Typography
                            type="so"
                            style={{
                              color: "white",
                              fontFamily: "Roboto",
                              marginLeft: 10,
                            }}>
                            SO
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}
                  <ListItem
                    // onClick={() => Redirect(ToCompanyProfile)}
                    onClick={() => navigate(ToEmptyStateGeneral)}
                    button
                    className={classes.nested}>
                    <ListItemText
                      inset
                      primary={
                        <Typography
                          type="karyawan"
                          style={{
                            color: "white",
                            fontFamily: "Roboto",
                            marginLeft: 10,
                          }}>
                          Karyawan
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem button onClick={() => navigate(ToTask)}>
                <ListItemIcon>
                  {/* <img src={ImageDashboardHome} style={{marginLeft: 4}} /> */}
                  <CheckBoxIcon style={{ color: "#df8a8d" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      type="PengaturanRole"
                      style={{ color: "white", fontFamily: "Roboto" }}>
                      Task
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </List>
        </div>
      </Drawer>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(HeadNewGeneral);

import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Checkbox,
} from "@material-ui/core";

function EnhancedTableHead({
  classes,
  numSelected,
  rowCount,
  onSelectAllClick,
}) {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" className={classes.title}>
            <b>
              <i>Title</i>
            </b>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" className={classes.title}>
            <b>
              <i>Due date</i>
            </b>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" className={classes.title}>
            <b>
              <i>Owner</i>
            </b>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" className={classes.title}>
            <b>
              <i>Target</i>
            </b>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" className={classes.title}>
            <b>Aktual</b>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" className={classes.title}>
            <b>Catatan</b>
          </Typography>
        </TableCell>

        <TableCell align="center">
          <Typography variant="subtitle2" className={classes.title}>
            <b>Status</b>
          </Typography>
        </TableCell>
        <TableCell align="center"></TableCell>
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;

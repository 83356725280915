import React, { useCallback, useEffect, useState, useContext, Fragment, useRef} from "react";
import { Box } from '@material-ui/core';

import { select } from "d3";

import Path from './Component/Path';

import './Style/basic.css';


const ViewBasicReactAndD3 = () => {

    const svgRef = useRef();

    const [data, setData ] = useState([25]);

    useEffect(() => {

        const svg = select(svgRef.current);

        svg
            .selectAll("circle")
            .data(data)
            .join("circle")
            .attr("r", value => value)
            .attr("cx", value => value * 3)
            .attr("cy", value => value * 3)
            .attr("stroke", "magenta")


    },[data])

    //*

    // const emailList = ['ganesh@gmail.com','john@gmail.com','tarry@gmail.com']

    const sendEmail = (userEmail) => {
    
        //Sending mail
        return new Promise(async (resolve,reject) => {

            //this is a mock email send logic
            setTimeout(() => {
    
                resolve(`Email Sent to ${userEmail}`);

            },3000)

        })
    
    };


    const  sendEmails = async () => {

        const userEmails = ['ganesh@gmail.com','john@gmail.com','Sam@gmail.com'];
    
        const status = await Promise.all(userEmails.map(email => sendEmail(email)));
        
        console.log("Status =>",status);
    
    };

    useEffect(() => {

        sendEmails();
        
    },[])

    return(

        <Fragment>
            <Box display='flex' justifyContent="center">
                <Box marginTop={3} p={1}>
                    <svg ref={svgRef}></svg>
                </Box>

                <Box marginTop={3} p={1}>
                    <Path />            
                </Box>
            </Box>

        </Fragment>


    )
};

export default ViewBasicReactAndD3;
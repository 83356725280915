/*
  ````````````````````````````````````````````````````````````````````````````````````````````````
  NEXT GANTI INI LINECHART-nya : 
  
    - https://apexcharts.com/react-chart-demos/line-charts/brush-chart/

    - https://codesandbox.io/s/q8o7zqw7lw

  ````````````````````````````````````````````````````````````````````````````````````````````````
*/

import React, { useEffect, useState, useContext, Fragment } from "react";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { withStyles } from "@material-ui/core/styles";

import moment from "moment";
import numeral from "numeral";

import ContextGoalDetail from "../Context/ContextGoalDetail";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import "moment/locale/id";

const PurpleSwitch = withStyles({
  switchBase: {
    color: "#DA5D6E",
    "&$checked": {
      color: "#d1354a",
    },
    "&$checked + $track": {
      backgroundColor: "#d1354a",
    },
  },
  checked: {},
  track: {},
})(Switch);

const DotLineChartGoalDetailComplete = (props) => {
  const {
    classes,
    isModalCreate, //*Ini state from GOAL RESULT
    isModalEdit,
    isModalDelete,
    goalDetailState,
  } = props;

  const context = useContext(ContextGoalDetail);

  const userToken = localStorage.getItem("userToken");

  const [anchorChart, setAnchorChart] = useState(null);
  const [chartType, setChartType] = useState({
    name: "Result (descrete)",
    code: "discrete",
  });
  const [showDate, setShowDate] = useState(true);
  const [loading, setLoading] = useState(false);

  const [dataGoalResult, setDataGoalResult] = useState([]);
  const [collectionGoalResultList, setCollectionGoalResultList] = useState([]);

  const locationPathGoalId = window.location.pathname.split("/")[2];

  useEffect(() => {
    // if (
    //   isModalCreate === false ||
    //   isModalEdit === false ||
    //   isModalDelete === false
    // ) {
    if (userToken !== undefined) {
      setLoading(true);

      axiosConfig
        .get(
          URL_API +
            `/swd/goal/${locationPathGoalId}/result-value-graph?option=${chartType.code}`
        )
        .then(function (resGraph) {
          console.log("ResGraph Original Line Chart: ", resGraph);

          // console.log("Data Goal", dataGoal);

          if (resGraph.status === 200) {
            if (resGraph.data.data.dataPoints.length > 0) {
              const dataPoints = resGraph.data.data.dataPoints.map((item) => {
                return {
                  x: item.x,
                  y: item.y,
                  tv: item.tv,
                };
              });

              setCollectionGoalResultList(dataPoints);
            }
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.log("Error : ", error.response);
          setLoading(false);
        });
      // }
      // })
      // .catch(function (error) {
      //   console.log("Error : ", error.response);
      // });
    } else {
      console.log("No Access Token available!");
    }
    // }
  }, [isModalCreate, isModalEdit, isModalDelete, context.triggerGR, chartType]);

  useEffect(() => {
    let dataGoalResultLet = [];

    if (collectionGoalResultList.length > 0) {
      collectionGoalResultList.map((item) => {
        let items = {
          name: moment(item.x).format("DD MMM YYYY"),
          Pencapaian: item.y,
          Target: item.tv,
          // Pencapaian: context.modeRupiahOn === true ?  `Rp ${numeral(item.y).format('Rp0,0.00')}` : numeral(item.y).format('0.00')
        };

        dataGoalResultLet.push(items);
      });

      setDataGoalResult(dataGoalResultLet);
    }
  }, [collectionGoalResultList]);

  const [max, setMax] = useState(0);
  const [min, setMin] = useState(0);

  useEffect(() => {
    let list = [];

    if (collectionGoalResultList.length > 0) {
      collectionGoalResultList.map((item, i) => {
        list.push(parseInt(item.y));
      });

      // console.log("List in", list)

      setMax(Math.max(...list));
      setMin(Math.min(...list));
    }
  }, [collectionGoalResultList]);

  const handleCloseAnchorChart = () => {
    setAnchorChart(null);
  };

  const handleShowAnchoeChart = (event) => {
    setAnchorChart(event.currentTarget);
  };

  const handleShowDate = () => {
    setShowDate((prev) => !prev);
  };

  const CustomizedAxisTick = (props) => {
    const { x, y, stroke, payload } = props;

    // console.log("Payload : ", payload);

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
          style={{ fontSize: 12 }}>
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <Fragment>
      {collectionGoalResultList.length > 0 && (
        <Box
          mb={2}
          ml={3}
          display="flex"
          justifyContent="flex-start"
          alignItems="center">
          <FormControlLabel
            control={
              <PurpleSwitch
                checked={showDate}
                onChange={handleShowDate}
                name="showDate"
                size="small"
              />
            }
            style={{ marginRight: 0 }}
            label={
              <Typography variant="caption" className={classes.title}>
                Show Date
              </Typography>
            }
          />
          {(goalDetailState.calculation_method_id === "1" ||
            goalDetailState.calculation_method_id === "3") && (
            <>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Button
                className={classes.btnWhite}
                variant="outlined"
                size="small"
                endIcon={<ArrowDropDownIcon />}
                onClick={handleShowAnchoeChart}>
                {chartType.name}
              </Button>
            </>
          )}
          {loading && (
            <Box display="flex" alignItems="center" ml={4}>
              <CircularProgress
                size={14}
                style={{ color: "#d1354a", marginRight: 7 }}
              />
              <Typography variant="caption" className={classes.title888}>
                Loading chart...
              </Typography>
            </Box>
          )}
          <Menu
            id="simple-menu"
            anchorEl={anchorChart}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            getContentAnchorEl={null}
            open={Boolean(anchorChart)}
            onClose={handleCloseAnchorChart}>
            <MenuItem
              onClick={() => {
                setAnchorChart(null);
                setChartType({
                  name: "Actual (default)",
                  code: "actual",
                });
              }}
              style={{ width: 150 }}>
              <Typography variant="subtitle2" className={classes.title}>
                Actual (default)
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorChart(null);
                setChartType({
                  name: "Result (cumulative)",
                  code: "cumulative",
                });
              }}
              style={{ width: 150 }}>
              <Typography variant="subtitle2" className={classes.title}>
                Result (cumulative)
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorChart(null);
                setChartType({
                  name: "Result (descrete)",
                  code: "discrete",
                });
              }}
              style={{ width: 150 }}>
              <Typography variant="subtitle2" className={classes.title}>
                Result (descrete)
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      )}
      {collectionGoalResultList.length > 0 && (
        <ResponsiveContainer width="98%" height={300}>
          <LineChart
            data={dataGoalResult}
            margin={{ top: 8, right: 0, left: 24, bottom: 8 }}>
            <XAxis
              dataKey={showDate ? "name" : ""}
              height={showDate ? 60 : 5}
              tick={<CustomizedAxisTick />}
            />
            {/* <YAxis /> */}

            <YAxis
              domain={[min, max]}

              /*
                        ``````````````````````````````````````````````````````````````````````
                        https://stackoverflow.com/questions/50078787/recharts-set-y-axis-range

                        ``````````````````````````````````````````````````````````````````````
                      */
            />

            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Line type="linear" dataKey="Pencapaian" stroke="#d1354a" />
            <Line type="linear" dataKey="Target" stroke="#00b894" />
          </LineChart>
        </ResponsiveContainer>
      )}
    </Fragment>
  );
};

export default DotLineChartGoalDetailComplete;

// https://github.com/recharts/recharts/issues/1849
// https://github.com/recharts/recharts/issues/1139

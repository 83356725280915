import React, { Component, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import axios from "axios";
import { navigate } from "hookrouter";

import Modal from "react-responsive-modal";
import Snackbeer from "../../../components/Snackbeer";
import SnackbeerRED from "../../../components/SnackbeerRED";

import { URL_API } from "../../../constants/config-api";
import Group_738 from "./../../../assets/images/Group_738.png";
import { ToRegister } from "../../../constants/config-redirect-url";
import PictInfoSuccess from "./../../../assets/images/icon-info-24px-green.svg";
import PictInfo from "./../../../assets/images/icon-info-24px.svg";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(5, 2),
    marginTop: theme.spacing(4),
    // width: 575,
    borderRadius: 5,
  },
  mediaThankyou: {
    width: 60,
    fontFamily: "Roboto",
  },
  description: {
    color: "#212529",
    marginTop: theme.spacing(1),
    fontFamily: "Roboto",
  },
  button: {
    width: "503px",
    height: "42px",
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    // backgroundColor: 'cyan',
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
  },
  buttonOutlined: {
    fontFamily: "Roboto",
    textTransform: "lowercase",
    color: "#d64253",
    borderWidth: 0,
    "&:hover": {
      borderWidth: 0,
    },
  },
  media: {
    height: 80,
    width: 80,
    // marginLeft: theme.spacing(7)
  },
  title: {
    fontFamily: "Roboto",
  },
  titleDialog: {
    fontFamily: "Roboto",
  },
  buttonModal: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    color: "white",
  },
});

const ViewNewWelcome = (props) => {
  const { classes } = props;

  /*
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */
  const [emailFromLocal, setEmailFromLocal] = useState("");
  useEffect(() => {
    const emailFromLocalStorage = localStorage.getItem("email");

    setEmailFromLocal(emailFromLocalStorage);
  }, []);

  //*
  const [loader, setLoader] = useState(false);

  /*

        ```````````````````````
        HANDLE SEND OTP AGAIN

        ```````````````````````
    */

  const [isModalOTPAgain200, setModalOTPAgain200] = useState(false);
  const handleSendOtpAgain = (e) => {
    e.preventDefault();

    setLoader(true);

    // let params = {

    //     token: verifyToken
    // };

    let data = {
      ResendEmail: {
        email: emailFromLocal,
      },
    };

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    axios.defaults.headers.common = header;

    axios
      .post(
        `${URL_API}/register/register-by-email/resend-email-verification-link`,
        data
      )
      .then((response) => {
        setLoader(false);
        console.log("Response Original : ", response);
        if (response.status == 200) {
          setLoader(false);
          // setModalOTPAgain200(true)

          //*Open Snackbeer
          setModalResponse200(true);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("Error : ", error.response);

        if (error.response !== null && error.response !== undefined) {
          if (error.response.status === 400) {
            setOpenSnackbeer(true);
          }
        }
      });
  };

  /*
        ```````````
        MODAL, etc.

        ```````````
    */
  const [isResponse400, setResponse400] = useState(false);
  const [
    infoErrorResponse400InvalidToken,
    setInfoErrorResponse400InvalidToken,
  ] = useState("");

  const [isResponse498, setResponse498] = useState(false);
  const [infoErrorResponse498InvalidOTP, setInfoErrorResponse498InvalidOTP] =
    useState("");

  /*
        ````````````````````````````
        HANDLE SNACKBEER GREEN & RED

        ````````````````````````````
    */

  const [isModalResponse200, setModalResponse200] = useState(false);
  const [isOpenSnackbeer, setOpenSnackbeer] = useState(false);

  return (
    <MuiThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Paper className={classes.root} elevation={2}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="h5" component="h3" className={classes.title}>
                Terima kasih
              </Typography>

              <br />
              <img
                src={Group_738}
                className={classes.mediaThankyou}
                alt="logo"
              />

              <Typography variant="h6" className={classes.description}>
                Selamat <i>registrasi</i> berhasil
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.description}
                style={{ color: "grey" }}>
                Periksa pesan yang kami kirimkan ke{" "}
                <b>
                  <i>{emailFromLocal}</i>
                </b>{" "}
                lalu <i> klik link verifikasi</i> email
              </Typography>

              <br />
              <Typography variant="body2" className={classes.description}>
                <b>
                  Belum menerima kode ?
                  <span
                    onClick={(e) => handleSendOtpAgain(e)}
                    style={{ color: "#cc0707", cursor: "pointer" }}>
                    &#8287;&#8287;Kirim ulang
                  </span>
                </b>
              </Typography>

              <br />
              <br />
              <Button
                variant="outlined"
                className={classes.buttonOutlined}
                style={{ backgroundColor: "#f6d6db" }}
                onClick={() => {
                  navigate(ToRegister);
                  localStorage.removeItem("email");
                }}>
                <span style={{ textTransform: "uppercase" }}>K</span>embali ke
                halaman daftar, jika ingin ubah alamat email
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <br />
      <br />
      <br />
      <br />

      <Modal
        open={loader}
        onClose={() => setLoader(false)}
        closeIconSize={20}
        showCloseIcon={false}
        center
        styles={{ modal: { background: "transparent", boxShadow: "none" } }}>
        <CircularProgress size={32} style={{ color: "red" }} />
      </Modal>

      {/* 

                `````````````````````````````
                MODAL SUCCESS SEND OTP AGAIN

                `````````````````````````````
            
            */}
      <Dialog
        open={isModalOTPAgain200}
        onClose={() => setModalOTPAgain200(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <img
            src={PictInfoSuccess}
            className={classes.media}
            alt="info-icon"
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h6" className={classes.titleDialog}>
              Kode OTP telah berhasil kami kirimkan
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={() => setModalOTPAgain200(false)}
            // color="primary"
            size="small"
            className={classes.buttonModal}>
            OK, mengerti !
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>

      {/* 

                `````````````````````````````
                MODAL RESPONSE 400

                `````````````````````````````
            
            */}

      <Dialog
        open={isResponse400}
        onClose={() => setResponse400(false)}
        aria-labelledby="alert-dialog-title-400"
        aria-describedby="alert-dialog-description-400">
        <DialogTitle
          id="alert-dialog-title-400"
          style={{ textAlign: "center" }}>
          <img src={PictInfo} className={classes.media} alt="info-icon" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-400">
            <Typography variant="h6" className={classes.titleDialog}>
              {infoErrorResponse400InvalidToken != ""
                ? infoErrorResponse400InvalidToken
                : ""}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={() => setResponse400(false)}
            // color="primary"
            size="small"
            className={classes.buttonModal}>
            Silahkan coba lagi
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>

      {/* 

                ```````````````````````````````````````
                MODAL RESPONSE 498 - INVALID TYPE CODE

                ```````````````````````````````````````
            
            */}

      <Dialog
        open={isResponse498}
        onClose={() => setResponse498(false)}
        aria-labelledby="alert-dialog-title-400"
        aria-describedby="alert-dialog-description-400">
        <DialogTitle
          id="alert-dialog-title-400"
          style={{ textAlign: "center" }}>
          <img src={PictInfo} className={classes.media} alt="info-icon" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-400">
            <Typography variant="h6" className={classes.titleDialog}>
              {infoErrorResponse498InvalidOTP !== ""
                ? infoErrorResponse498InvalidOTP
                : ""}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={() => setResponse498(false)}
            // color="primary"
            size="small"
            className={classes.buttonModal}>
            Silahkan coba lagi
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>

      <Snackbeer
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages="Silahkan check inbox email Anda, link verifikasi email telah berhasil kami kirimkan ulang"
      />

      <SnackbeerRED
        isOpenSnackbeer={isOpenSnackbeer}
        setOpenSnackbeer={setOpenSnackbeer}
        messages="Whoops, ada sedikit masalah ketika Anda memasukan alamat email ! Coba registrasi ulang lagi."
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewNewWelcome);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

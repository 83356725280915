import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogContentText,
  TextField,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Button,
  Box,
  Radio,
  InputAdornment,
  DialogActions,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import numeral from "numeral";
import {
  CalendarToday as CalendarTodayIcon,
  AccountCircle as AccountCircleIcon,
} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import { URL_API } from "../../../../constants/config-api";
import axiosConfig from "../../../../constants/config-axios";
import ContextGoal from "../Context/ContextGoal";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";
import DialogDateRange from "../Components/DialogDateRange";
import "moment/locale/id";
import LightTooltip from "../../../../components/LightTooltip";
import Capitalize from "../../../../utilities/Capitalize";
import DialogError from "../../../../components/DialogError";
import ImageSmallUser from "../../../../components/ImageSmallUser";

import AvatarDummy from "../../../../assets/images/Avatar_dummy.png";

// CLASS | LocalizedUtils |
class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

// COMPONENT | DialogEditDuplicate |
function DialogEditDuplicate({
  classes,
  goalData,
  //   timeFrame,
  isModalEditDuplicate,
  setModalEditDuplicate,
}) {
  /* ====================================
        IMPORTANT VARIABLE
    ===================================== */
  const context = useContext(ContextGoal);

  //   let textInputReff = useRef(null);
  const start_date_range = localStorage.getItem("start_date");
  const end_date_range = localStorage.getItem("end_date");

  const SWD_GOAL_INPUT_METHOD_INCREMENT = "swd_goal_input_method_increment";
  const SWD_GOAL_INPUT_METHOD_LAST_VALUE = "swd_goal_input_method_last_value";

  const SWD_GOAL_CALCULATION_METHOD_SUM = "swd_goal_calculation_method_sum";
  const SWD_GOAL_CALCULATION_METHOD_LAST_VALUE =
    "swd_goal_calculation_method_last_value";
  const SWD_GOAL_CALCULATION_METHOD_AVERAGE =
    "swd_goal_calculation_method_average";

  /* ====================================
     STATE COMPONENT
    ===================================== */

  // HANDLE INPUT CHANGE
  const [name, setName] = useState("");
  const [fotoQuery, setFotoQuery] = useState(null);
  const [description, setDescription] = useState("");
  const [target_value, setTarget_value] = useState("");
  const [start_value, setStart_value] = useState("");
  const [targetValueRange, setTargetValueRange] = useState("");
  //   STATE WITH EVIDENCE OR NOT
  const [evidance, setEvidance] = useState(false);
  const [achievementType, setAchievementType] = useState({
    id: "",
    code: "",
    name: "",
  });
  const [inputMethod, setInputMethod] = useState({
    id: "",
    code: "",
    name: "",
  });
  const [calculationMethod, setCalculationMethod] = useState({
    id: "",
    code: "",
    name: "",
  });
  const [valueType, setValueType] = useState({
    id: "",
    code: "",
    name: "",
  });
  const [targetType, setTargetType] = useState({
    code: "",
    id: "",
    name: "",
  });
  const [locale, setLocale] = useState("id");
  const [userTokenState, setUserTokenState] = useState("");
  const [loader, setLoader] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  // TIME-FRAME STATE
  const [isShowDateRangeComponent, setShowDateRangeComponent] = useState(false);
  const [isShowTextDateRange, setShowTextDateRange] = useState(false);
  const [startDateRange, setStartDateRange] = useState(""); // Start Date
  const [endDateRange, setEndDateRange] = useState(""); // End Date
  const [selectedDueDate, setSelectedDueDate] = useState(""); // Select Due Date
  const [isShowDueDateComponent, setShowDueDateComponent] = useState(false); // Show Due Date
  const [isShowDueDateComponentDirectly, setShowDueDateComponentDirectly] =
    useState(false); // Show Due Date Directly
  const [isDisabledFeature, setDisabledFeature] = useState(false); // Disable Feature
  const [ownerId, setOwnerId] = useState(""); // Set Owner ID
  const [choosedOwner, setChoosedOwner] = useState(""); // Choosed Owner
  const [anchorElTimeFrame, setAnchorElTimeFrame] = useState(null); // Anchor time-frame
  const [textValuePosition, setTextValuePosition] = useState(""); // Text Value Position
  const [textValueTimeFrame, setTextValueTimeFrame] = useState("Time Frame"); // Text value time-frame
  const [anchorElPeriod, setAnchorElPeriod] = useState(null); // Anchor Period
  const [isShowPeriodComponent, setShowPeriodComponent] = useState(false); // Shpw Peroid component
  const [isShowTextPeriod, setShowTextPeriod] = useState(false); // Show text peroid
  const [periodCollections, setPeriodCollections] = useState([]); // Period collections
  const [periodId, setPeriodId] = useState(""); // Period ID
  const [periodName, setPeriodName] = useState(""); // Perid Name
  const [timeFrameId, setTimeFrameId] = useState(null); // Time frame Id
  const [ownerList, setOwnerList] = useState([]); // Owner / Member List
  //   RADIO INPUT State
  const [calculationMethodList, setCalculationMethodList] = useState([]);
  const [inputAchievementTypeList, setInputAchievementTypeList] = useState([]);
  const [inputMethodList, setInputMethodList] = useState([]);
  const [inputValueTypeList, setInputValueTypeList] = useState([]);
  const [isShowOnlyInputMethodTOTAL, setShowOnlyInputMethodTOTAL] =
    useState(false);
  const [targetTypeList, setTargetTypeList] = useState([]);
  const [isShowPercent, setShowPercent] = useState(false); // Show Percent
  const [calcMethodDataID, setCalcMethodDataID] = useState("");
  const [isShowTextFieldTargetRangeValue, setShowTextFieldTargetRangeValue] =
    useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  /* ====================================
     LIFECYCLE HOOK
    ===================================== */
  useEffect(() => {
    if (isModalEditDuplicate === true) {
      // console.log("GolDat", goalData);
      // SETUP USER TOKEN
      const userToken = localStorage.getItem("userToken");
      setUserTokenState(userToken);

      setName(goalData.name);
      setDescription(goalData.description);
      setTarget_value(goalData.target_value);
      setStart_value(goalData.start_value);

      setAchievementType(goalData.achievementType);
      setInputMethod(goalData.inputMethod);
      setCalculationMethod(goalData.calculationMethod);
      setValueType(goalData.valueType);
      setTargetType(goalData.targetType);

      if (goalData.is_evidence_required === 1) {
        setEvidance(true);
      } else {
        setEvidance(false);
      }

      setCalcMethodDataID(goalData.calculationMethod.id);

      if (goalData.inputMethod.code === "total") {
        setShowOnlyInputMethodTOTAL(true);
      }

      //   Setup Time Frame
      if (goalData.time_frame_id !== undefined) {
        // Period
        if (goalData.time_frame_id === "1") {
          if (goalData.period !== null) {
            setPeriodName(goalData.period.name);
            setPeriodId(goalData.period_id);
          }
          if (goalData.period_id === "-1") {
            setPeriodName("Period tidak valid");
          }
          setShowTextDateRange(false);
          setDisabledFeature(true);
          setShowTextPeriod(true);
        }
        // Date Range
        if (goalData.time_frame_id === "2") {
          setShowTextDateRange(true);
          setShowTextPeriod(false);
          setStartDateRange(goalData.start_date);
          setEndDateRange(goalData.end_date);
        }
        // Due Date
        if (goalData.time_frame_id === "3") {
          setSelectedDueDate(goalData.end_date);
          setShowDueDateComponent(true);
          setDisabledFeature(true);
          setShowTextPeriod(false);
          setShowTextDateRange(false);
        }
      }

      setLoader(true);
      setDisableBtn(false);

      //******************************************************** */

      if (userToken !== undefined) {
        axiosConfig
          .get(`${URL_API}/swd/goal/create`)
          .then((response) => {
            console.log("Response Edit DUPLICATE : ", response);

            if (response.status === 200) {
              setInputMethodList(response.data.data.inputMethodCollections);
              setInputAchievementTypeList(
                response.data.data.achievementTypeCollections
              );

              setInputValueTypeList(response.data.data.valueTypeCollections);

              setCalculationMethodList([
                {
                  code: "swd_goal_calculation_method_sum",
                  id: "1",
                  name: "sum",
                },
                {
                  code: "swd_goal_calculation_method_average",
                  id: "2",
                  name: "average",
                },
              ]);
              setPeriodCollections(response.data.data.periodCollections);

              setTargetTypeList(response.data.data.targetTypeCollections);

              setOwnerList(response.data.data.ownerCollections);
            }
            setLoader(false);
          })
          .catch(function (error) {
            console.log("Error : ", error.response);
            setLoader(false);
            // error response
            if (error.response !== undefined) {
              let responseStatus = error.response.status;

              setErrorStatus(responseStatus);
              setOpenDialogError(true);

              if ([422, 400].includes(responseStatus)) {
                if (
                  error.response.data.info.errors !== null &&
                  error.response.data.info.errors !== undefined
                ) {
                  if (error.response.data.info.errors.length > 0) {
                    setOpenDialogError(true);
                    setListError(error.response.data.info.errors);
                  }
                }
              }

              if (
                [503, 500, 403, 429, 405, 404, 401].includes(responseStatus)
              ) {
                if (error.response.data.hasOwnProperty("message")) {
                  setTextErrorMessage(
                    `Error ${responseStatus} : ` + error.response.data.message
                  );
                }
              }
            } else {
              setOpenDialogError(true);
              setTextErrorMessage("Whoops, something went wrong !");
            }
          });
      } else {
        console.log("No Access Token available!");
      }

      if (
        goalData.valueType.id === "2" &&
        goalData.valueType.code === "percentage"
      ) {
        setShowPercent(true);
      }
    }
  }, [isModalEditDuplicate]);

  useEffect(() => {
    setStartDateRange(start_date_range);
    setEndDateRange(end_date_range);
  }, [start_date_range, end_date_range]);

  // =========== TARGET RANGE VALUE ===========
  useEffect(() => {
    //*
    if (
      start_value === target_value &&
      (calculationMethod.code === SWD_GOAL_CALCULATION_METHOD_SUM ||
        calculationMethod.code === SWD_GOAL_CALCULATION_METHOD_LAST_VALUE)
    ) {
      setShowTextFieldTargetRangeValue(true);
    } else if (
      target_value === start_value &&
      calculationMethod.code === SWD_GOAL_CALCULATION_METHOD_AVERAGE
    ) {
      setShowTextFieldTargetRangeValue(true);
    } else if (
      start_value === target_value &&
      calculationMethod.code === SWD_GOAL_CALCULATION_METHOD_AVERAGE
    ) {
      setShowTextFieldTargetRangeValue(true);
    } else {
      setShowTextFieldTargetRangeValue(false);
    }
  }, [start_value, target_value, calculationMethod]);

  /* ====================================
     HANDLE FUNCTION
    ===================================== */

  //   Handle Click Time-Frame | ON
  function handleClickTimeFrame(event) {
    setAnchorElTimeFrame(event.currentTarget);
  }

  //   Handle Click Time-Frame | OFF
  function handleCloseTimeFrame() {
    setAnchorElTimeFrame(null);
  }

  const handleChangeTargetValueRange = (e) => {
    e.preventDefault();
    setTargetValueRange(e.target.value);
  };

  //   Handle Choose Due Date
  const handleChooseDueDate = () => {
    setTimeFrameId("3");
    // setEndDateRangeState(new Date()) //*Baris kode ini untuk validasi DISABLED Button saja !

    handleCloseTimeFrame();
    setShowDueDateComponent(true);
    setShowDueDateComponentDirectly(true);

    setShowDateRangeComponent(false);
    setShowTextDateRange(false);
    setShowTextPeriod(false);

    setTextValueTimeFrame("Due Date");
  };

  //   Handle Choose Date Range
  const handleChooseRange = () => {
    // setSelectedDueDate(new Date());//*Baris kode ini untuk validasi DISABLED Button saja !

    setTimeFrameId("2");

    handleCloseTimeFrame();
    setShowDueDateComponent(false);
    setShowDueDateComponentDirectly(false);
    setShowTextPeriod(false);
    setShowDateRangeComponent(true);
    setShowTextDateRange(true);

    setTextValueTimeFrame("Date Range");
  };

  //   Handle Choose Period
  const handleChoosePeriod = (event) => {
    setTimeFrameId("1");

    setAnchorElPeriod(event.currentTarget);

    //*
    handleCloseTimeFrame();
    setShowDueDateComponent(false);
    setShowDueDateComponentDirectly(false);

    setShowDateRangeComponent(false);
    setShowTextDateRange(false);
    // setShowTextPeriod(true)

    setTextValueTimeFrame("Period");

    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");

    setShowPeriodComponent(true);
    setSelectedDueDate(null);
  };

  // Handle Choose Period
  const handleChoosePeriodId = (e, item) => {
    e.preventDefault();

    console.log("Period ID : ", item);

    setPeriodId(item.id);
    setPeriodName(item.name);

    handleClosePeriod();
    setShowTextPeriod(true);
  };

  // Handle Choose Period
  function handleClosePeriod() {
    setAnchorElPeriod(null);
  }

  // Handle Click Period
  function handleClickPeriod(event) {
    setAnchorElPeriod(event.currentTarget);
  }

  // Choose Owner
  const handleChooseMemberOwner = (e, data) => {
    e.preventDefault();

    // console.log("Wew data : ", data);
    if (data !== null) {
      setOwnerId(data.id);

      setChoosedOwner(data.member_first_name + " " + data.member_last_name);
      setTextValuePosition(data.structure_position_title_name);

      if (data.member.self.rel.photo_url !== null) {
        setFotoQuery(data.member.self.rel.photo_url);
      } else {
        setFotoQuery(null);
      }
    }
    //*
    // console.log("Member Owner Choosed : ", data);
  };

  //   handle Change Radio Input Method
  const handleChangeRadioButtonInputMethod = (e, data) => {
    e.preventDefault();
    setInputMethod(data);

    if (data.code === SWD_GOAL_INPUT_METHOD_INCREMENT) {
      setCalculationMethodList([
        {
          id: "1",
          code: SWD_GOAL_CALCULATION_METHOD_SUM,
          name: "sum",
        },
        {
          id: "2",
          code: SWD_GOAL_CALCULATION_METHOD_AVERAGE,
          name: "average",
        },
      ]);

      setCalculationMethod({
        id: "1",
        code: SWD_GOAL_CALCULATION_METHOD_SUM,
        name: "sum",
      });
    } else {
      setCalculationMethod({
        id: "3", //"1",
        code: SWD_GOAL_CALCULATION_METHOD_LAST_VALUE,
        name: "last value",
      });

      setCalculationMethodList([
        {
          id: "3", //"1",
          code: SWD_GOAL_CALCULATION_METHOD_LAST_VALUE,
          name: "last value",
        },
      ]);

      setTargetTypeList([
        {
          code: "swd_goal_target_type_daily",
          id: "1",
          name: "Daily",
        },
        {
          code: "swd_goal_target_type_final",
          id: "2",
          name: "Final",
        },
      ]);
    }
  };

  //   handle Change Radio Achievement Type
  const handleChangeRadioButtonAchievementType = (e, data) => {
    e.preventDefault();
    setAchievementType(data);
  };

  //   handle Change Radio Value Type
  const handleChangeRadioButtonValueType = (e, data) => {
    e.preventDefault();
    setValueType(data);

    if (data.code === "percentage") {
      setShowPercent(true);
    } else {
      setShowPercent(false);
    }
  };

  //   handle Change Radio Calculation Method
  const handleChangeRadioCalcMethod = (e, data) => {
    e.preventDefault();
    setCalculationMethod(data);
    setCalcMethodDataID(data.id);

    if (data.code === "swd_goal_calculation_method_average") {
      setTargetTypeList([
        {
          code: "swd_goal_target_type_final",
          id: "2",
          name: "Final",
        },
      ]);

      setTargetType({
        code: "swd_goal_target_type_final",
        id: "2",
        name: "Final",
      });
    } else {
      setTargetTypeList([
        {
          code: "swd_goal_target_type_daily",
          id: "1",
          name: "Daily",
        },
        {
          code: "swd_goal_target_type_final",
          id: "2",
          name: "Final",
        },
      ]);

      setTargetType({
        code: "swd_goal_target_type_daily",
        id: "1",
        name: "Daily",
      });
    }
  };

  // HANDLE SELECTED  TARGET TYPE
  const handleSelectTargetType = (e, data) => {
    e.preventDefault();
    setTargetType(data);
  };

  const handleSubmitDuplicate = () => {
    setLoader(true);
    setDisableBtn(true);

    let data = {
      Goal: {
        name: name,
        description: description,
        owner_id: ownerId !== "" ? ownerId : goalData.owner_id,

        input_method_id: inputMethod.id,
        achievement_type_id: achievementType.id,
        calculation_method_id: calculationMethod.id,
        value_type_id: valueType.id,
        target_type_id: targetType.id,
        period_id: periodId !== "" ? periodId : null,
        start_date: startDateRange,
        end_date: isShowTextDateRange
          ? endDateRange
          : isShowDueDateComponent
          ? moment(selectedDueDate).format("YYYY-MM-DD")
          : null,
        start_value: start_value !== "0" ? start_value : 0,
        target_value: target_value,
        target_range_value: targetValueRange !== "" ? targetValueRange : null,
        time_frame_id:
          timeFrameId !== null ? timeFrameId : goalData.time_frame_id,
        is_evidence_required: evidance === true ? 1 : 0, //*0 === Tidak butuh evidence, 1 === Butuh evidence
      },
    };

    // console.log("Data SUBMIT : ", data);

    axiosConfig
      .post(`${URL_API}/swd/goal`, data)
      .then((response) => {
        // console.log("SUCCESS SUBMIT DUPLICATE : ", response);

        if (response.status === 200) {
          context.setOpenSnackbeer(true);
          context.setMessages("Berhasil menyalin Goal");
          setModalEditDuplicate(false);

          // setTimeout(() => window.location.reload(), 1500);
        }
        setLoader(false);
        setDisableBtn(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log("EROR POST DUPLICATE", error);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  return (
    <Fragment>
      <Dialog
        open={isModalEditDuplicate}
        onClose={() => setModalEditDuplicate(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography variant="h6" className={classes.title}>
            <b>Edit Duplicate Goal</b>
          </Typography>
        </DialogTitle>

        <DialogContent style={{ textAlign: "left" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="subtitle1" className={classes.title}>
              <b>Title Goal</b>
            </Typography>
            <TextField
              // inputRef={textInputReff}
              id="outlined-bare"
              className={classes.textField}
              onChange={(e) => setName(e.target.value)}
              value={name}
              variant="outlined"
              fullWidth
              inputProps={{
                className: classes.title,
              }}
            />
          </DialogContentText>

          <Grid container>
            <Grid item xs={8}>
              <List>
                <ListItem style={{ paddingLeft: 0 }}>
                  <ListItemIcon>
                    {fotoQuery !== null ? (
                      <ImageSmallUser item={fotoQuery} index={1} />
                    ) : (
                      <img
                        src={AvatarDummy}
                        alt="."
                        className={classes.imageAvatar}
                      />
                    )}
                  </ListItemIcon>

                  {/* 
                  =========================================================================================
                  USER PILIH OWNER DI SINI, KEMUDIAN SETELAH DI PILIH, MAKA AKAN TERFILTER LIST DATA KARYAWAN YANG HENDAK DI ASSIGN
                  =========================================================================================
              */}
                  {ownerList.length > 0 ? (
                    <Autocomplete
                      id="combo-box-demo"
                      options={ownerList}
                      getOptionLabel={(option) =>
                        `${option.member_first_name} ${option.member_last_name}`
                      }
                      style={{ fontSize: 13 }}
                      className={classes.title}
                      noOptionsText="Tidak ada pilihan"
                      onChange={(e, owner) => handleChooseMemberOwner(e, owner)}
                      defaultValue={goalData.owner}
                      renderInput={(params) => (
                        <TextField
                          style={{ width: 230, fontSize: 13 }}
                          {...params}
                          label="Search Owner"
                          variant="standard"
                        />
                      )}
                    />
                  ) : (
                    <TextField
                      id="standard-helperText"
                      label="Search Owner"
                      disabled={true}
                      style={{
                        color: goalData.owner !== null ? "#333" : "#d1354a",
                        width: 230,
                        fontSize: 13,
                      }}
                      defaultValue={
                        goalData.owner !== null
                          ? `${goalData.owner.member_first_name} ${goalData.owner.member_last_name}`
                          : "Owner ini bukan subordinate anda"
                      }
                    />
                  )}
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={4}>
              {/* 
            ===========================================
            TIME FRAME BUTTON
            ===========================================                        
            */}
              <Button
                onClick={handleClickTimeFrame}
                variant="outlined"
                size="small"
                className={classes.timeFrameIconInModal}
                style={{ marginTop: 24 }}>
                <IconButton style={{ background: "#edcfd8" }} size="medium">
                  <CalendarTodayIcon color="#fff" fontSize="small" />
                </IconButton>
                &nbsp;
                <b>{textValueTimeFrame}</b>
              </Button>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElTimeFrame}
                keepMounted
                open={Boolean(anchorElTimeFrame)}
                onClose={handleCloseTimeFrame}>
                <StyledMenuItem onClick={handleChooseDueDate}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Due Date</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                <StyledMenuItem onClick={handleChooseRange}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Date Range</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                <StyledMenuItem onClick={handleChoosePeriod}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Period</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>
              </StyledMenu>

              {/* 
                =================================================
                MENU LIST PERIOD
                =================================================
            */}

              <StyledMenu
                id="customized-menu-period"
                anchorEl={anchorElPeriod}
                keepMounted
                open={Boolean(anchorElPeriod)}
                onClose={handleClosePeriod}
                style={{ marginTop: "-160px" }}>
                {isShowPeriodComponent === true &&
                periodCollections.length > 0 ? (
                  periodCollections.map((item, i) => (
                    <StyledMenuItem
                      key={i}
                      onClick={(e) => handleChoosePeriodId(e, item)}>
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}>
                            <b>{item.name}</b>
                          </Typography>
                        }
                      />
                    </StyledMenuItem>
                  ))
                ) : (
                  <StyledMenuItem disabled>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}>
                          <b>Anda belum memiliki periode ;(</b>
                        </Typography>
                      }
                    />
                  </StyledMenuItem>
                )}
              </StyledMenu>

              {isShowDueDateComponent === true && ( //https://stackoverflow.com/questions/17493309/how-do-i-change-the-language-of-moment-js
                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                  <ThemeProvider theme={theme}>
                    <Fragment>
                      <DatePicker
                        value={selectedDueDate}
                        onChange={setSelectedDueDate}
                        animateYearScrolling
                        open={isShowDueDateComponentDirectly}
                        onOpen={() => setShowDueDateComponentDirectly(true)}
                        onClose={() => setShowDueDateComponentDirectly(false)}
                        variant="dialog" // dialog, static, inline
                        disableToolbar={false}
                        // orientation="landscape"
                        format="DD MMMM YYYY"
                        // TextFieldComponent =
                        // ToolbarComponent
                        label=""
                        style={{ marginLeft: 16 }}
                        // disabled={isDisabledFeature == true ? true : false }
                      />
                    </Fragment>
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              )}

              {isShowDateRangeComponent === true && (
                <Fragment>
                  <DialogDateRange
                    classes={classes}
                    userTokenState={userTokenState}
                    isShowDateRangeComponent={isShowDateRangeComponent}
                    setShowDateRangeComponent={setShowDateRangeComponent}
                  />
                </Fragment>
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              {isShowTextDateRange ? (
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIcontDateRangeText}
                  style={{ marginRight: 8, borderWidth: 0 }}
                  onClick={() => setShowDateRangeComponent(true)}>
                  <b>
                    {moment(startDateRange).format("DD MMMM YYYY")} -{" "}
                    {moment(endDateRange).format("DD MMMM YYYY")}
                  </b>
                </Button>
              ) : null}

              {isShowTextPeriod ? (
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIcontDateRangeText}
                  style={{ marginRight: 8, borderWidth: 0 }}
                  onClick={(e) => handleClickPeriod(e)}>
                  <b>{periodName}</b>
                </Button>
              ) : null}
            </Grid>
          </Grid>

          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "grey" }}>
            <b>Deskripsi (Optional)</b>
          </Typography>

          <TextField
            onChange={(e) => setDescription(e.target.value)}
            style={{ marginTop: 0 }}
            id="outlined-multiline-static-description"
            // label="Multiline"
            multiline
            rows="4"
            // defaultValue="Default Value"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            fullWidth
            value={description !== null ? description : ""}
            inputProps={{
              className: classes.title,
            }}
          />

          <br />
          <br />
          <Grid container>
            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}>
                <b>Start value : </b>
              </Typography>
              <Box marginRight={theme.spacing(0.2)}>
                <TextField
                  placeholder={numeral(start_value).format("0.00")}
                  defaultValue={numeral(start_value).format("0.00")}
                  type="number"
                  id="outlined-bare"
                  onChange={(e) => setStart_value(e.target.value)}
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    className: classes.title,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          variant="subtitle1"
                          className={classes.title}
                          style={{ color: "grey" }}>
                          {isShowPercent === true ? <b>%</b> : null}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}>
                <b>Target Value : </b>
              </Typography>
              <TextField
                placeholder={numeral(target_value).format("0.00")}
                defaultValue={numeral(target_value).format("0.00")}
                type="number"
                id="outlined-bare"
                onChange={(e) => setTarget_value(e.target.value)}
                variant="outlined"
                fullWidth
                inputProps={{
                  className: classes.title,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography
                        variant="subtitle1"
                        className={classes.title}
                        style={{ color: "grey" }}>
                        {isShowPercent === true ? <b>%</b> : null}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <br />
          {/* IS Evidence Required */}

          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    // color="primary"
                    size="small"
                    checked={evidance}
                    onChange={(e) => setEvidance(e.target.checked)}
                  />
                }
                label={
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    Wajib lampirkan bukti
                  </Typography>
                }
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={6}>
              {isShowTextFieldTargetRangeValue === true && (
                <Fragment>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    <b>Target Range value : </b>
                  </Typography>
                  <Box>
                    <TextField
                      placeholder="0"
                      type="number"
                      id="outlined-bare"
                      // onChange= { handleChangeStartValue }
                      onChange={handleChangeTargetValueRange}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              variant="subtitle1"
                              className={classes.title}
                              style={{ color: "grey" }}>
                              {isShowPercent === true ? <b>%</b> : null}
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Fragment>
              )}
            </Grid>
          </Grid>
          <br />

          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "grey" }}>
            <b>Input Method :</b>
          </Typography>

          <List>
            {inputMethodList.length > 0 &&
              inputMethodList.map((item, i) => (
                <Fragment key={i}>
                  <Radio
                    checked={inputMethod.code === item.code}
                    onChange={(e) =>
                      handleChangeRadioButtonInputMethod(e, item)
                    }
                    value={item.id}
                    name={item.name}
                  />
                  <span style={{ fontFamily: "Roboto", color: "grey" }}>
                    {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                  </span>
                </Fragment>
              ))}
          </List>

          <br />
          {isShowOnlyInputMethodTOTAL !== true && (
            <Fragment>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}>
                <b>Achievement Type : &nbsp; </b>
              </Typography>
              <List>
                {inputAchievementTypeList.length > 0 &&
                  inputAchievementTypeList.map((item, i) => (
                    <Fragment key={i}>
                      <Radio
                        checked={achievementType.id === item.id}
                        onChange={(e) =>
                          handleChangeRadioButtonAchievementType(e, item)
                        }
                        value={item.id}
                        name={item.name}
                      />
                      <span style={{ fontFamily: "Roboto", color: "grey" }}>
                        {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                      </span>
                    </Fragment>
                  ))}
              </List>

              <br />
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}>
                <b>Value Type : &nbsp; </b>
                {/* <span style={{fontFamily: 'Roboto', color: 'grey'}}>
                                            {Capitalize(valueTypeData !== '' ? valueTypeData.code : '')}
                                        </span> */}
              </Typography>
              <List>
                {inputValueTypeList.length > 0 &&
                  inputValueTypeList.map((item, i) => (
                    <Fragment key={i}>
                      <Radio
                        checked={valueType.id === item.id}
                        onChange={(e) =>
                          handleChangeRadioButtonValueType(e, item)
                        }
                        value={item.id}
                        name={item.name}
                      />
                      <span style={{ fontFamily: "Roboto", color: "grey" }}>
                        {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                      </span>
                    </Fragment>
                    // </ListItem>
                  ))}
              </List>

              <br />
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}>
                <b>Calculation Method : &nbsp; </b>
              </Typography>

              <List>
                {calculationMethodList.length > 0 &&
                  calculationMethodList.map((item, i) => (
                    <Fragment key={i}>
                      <Radio
                        checked={calculationMethod.code === item.code}
                        onChange={(e) => handleChangeRadioCalcMethod(e, item)}
                        value={item.id}
                        name={item.name}
                        // disabled={item.code == 'last_value' ? true : false }
                      />
                      <span style={{ fontFamily: "Roboto", color: "grey" }}>
                        {Capitalize(item.name)} : &nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </Fragment>
                  ))}
              </List>

              <br />
              <LightTooltip
                title={
                  <div>
                    Daily: Perhitungan yang digunakan adalah target di hari yang
                    sedang berjalan <br />
                    Final: Perhitungan yang digunakan adalah target di akhir
                    periode
                  </div>
                }
                placement="top">
                <Typography
                  variant="subtitle1"
                  className={classes.title}
                  style={{ color: "grey" }}>
                  <b>Target Type :</b>
                </Typography>
              </LightTooltip>

              <List>
                {targetTypeList.length > 0 &&
                  targetTypeList.map((item, i) => {
                    return (
                      <Fragment key={i}>
                        <Radio
                          checked={targetType.code === item.code}
                          onChange={(e) => handleSelectTargetType(e, item)}
                          value={item.id}
                          name={item.name}
                        />
                        <span style={{ fontFamily: "Roboto", color: "grey" }}>
                          {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                        </span>
                      </Fragment>
                    );
                  })}
              </List>
            </Fragment>
          )}
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            onClick={handleSubmitDuplicate}
            variant="contained"
            className={classes.button}
            fullWidth
            // disableRipple={disabledButton == true ? true : false}
            // disabled={disableBtn ? true : false}
          >
            {loader ? (
              <CircularProgress size={20} style={{ color: "white" }} />
            ) : (
              "Duplicate Goal !"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
}

export default DialogEditDuplicate;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },

  typography: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  textfield: {
    width: 200,
  },
});

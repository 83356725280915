const field_presets = [
  {
    id: 1,
    name: "Result Simple",
    selected_fields: [
      {
        id: 20,
        caption: "Name",
        data_field_name: "full_name",
        sort_order: 1,
      },
      {
        id: 1,
        caption: "Job Title",
        data_field_name: "position_title",
        sort_order: 2,
      },
      {
        id: 6,
        caption: "Title",
        data_field_name: "title",
        sort_order: 3,
      },
      {
        id: 15,
        caption: "Results - Achievements",
        data_field_name: "overall_result_achievement",
        sort_order: 4,
      },
      {
        id: 12,
        caption: "Results",
        data_field_name: "result_value",
        sort_order: 5,
      },
      {
        id: 13,
        caption: "Results - Expected",
        data_field_name: "expected_result_value",
        sort_order: 6,
      },
    ],
    selected: true,
  },
  {
    id: 2,
    name: "Actual Simple",
    selected_fields: [
      {
        id: 20,
        caption: "Name",
        data_field_name: "full_name",
        sort_order: 1,
      },
      {
        id: 1,
        caption: "Job Title",
        data_field_name: "position_title",
        sort_order: 2,
      },
      {
        id: 6,
        caption: "Title",
        data_field_name: "title",
        sort_order: 3,
      },
      {
        id: 16,
        caption: "Actual",
        data_field_name: "actual_value",
        sort_order: 4,
      },
      {
        id: 17,
        caption: "Actual - Expected",
        data_field_name: "expected_actual_value",
        sort_order: 5,
      },
      {
        id: 19,
        caption: "Actual - Achievements",
        data_field_name: "overall_actual_achievement",
        sort_order: 6,
      },
    ],
    selected: false,
  },
  {
    id: 3,
    name: "All Simple",
    selected_fields: [
      {
        id: 20,
        caption: "Name",
        data_field_name: "full_name",
        sort_order: 1,
      },
      {
        id: 1,
        caption: "Job Title",
        data_field_name: "position_title",
        sort_order: 2,
      },
      {
        id: 6,
        caption: "Title",
        data_field_name: "title",
        sort_order: 3,
      },
      {
        id: 15,
        caption: "Results - Achievements",
        data_field_name: "overall_result_achievement",
        sort_order: 4,
      },
      {
        id: 12,
        caption: "Results",
        data_field_name: "result_value",
        sort_order: 5,
      },
      {
        id: 13,
        caption: "Results - Expected",
        data_field_name: "expected_result_value",
        sort_order: 6,
      },
      {
        id: 16,
        caption: "Actual",
        data_field_name: "actual_value",
        sort_order: 7,
      },
      {
        id: 17,
        caption: "Actual - Expected",
        data_field_name: "expected_actual_value",
        sort_order: 8,
      },
      {
        id: 19,
        caption: "Actual - Achievements",
        data_field_name: "overall_actual_achievement",
        sort_order: 9,
      },
    ],
    selected: false,
  },
  {
    id: 4,
    name: "Results Details",
    selected_fields: [
      {
        id: 20,
        caption: "Name",
        data_field_name: "full_name",
        sort_order: 1,
      },
      {
        id: 1,
        caption: "Job Title",
        data_field_name: "position_title",
        sort_order: 2,
      },
      {
        id: 7,
        caption: "Entities",
        data_field_name: "type",
        sort_order: 3,
      },
      {
        id: 15,
        caption: "Results - Achievements",
        data_field_name: "overall_result_achievement",
        sort_order: 4,
      },
      {
        id: 6,
        caption: "Title",
        data_field_name: "title",
        sort_order: 5,
      },
      {
        id: 4,
        caption: "Start Date",
        data_field_name: "start_date",
        sort_order: 6,
      },
      {
        id: 5,
        caption: "End Date",
        data_field_name: "end_date",
        sort_order: 7,
      },
      {
        id: 9,
        caption: "Calc Method",
        data_field_name: "calculation_method_id",
        sort_order: 8,
      },
      {
        id: 10,
        caption: "Start",
        data_field_name: "start_value",
        sort_order: 9,
      },
      {
        id: 11,
        caption: "Target",
        data_field_name: "current_period_target_value",
        sort_order: 10,
      },
      {
        id: 12,
        caption: "Results",
        data_field_name: "result_value",
        sort_order: 11,
      },
      {
        id: 13,
        caption: "Results - Expected",
        data_field_name: "expected_result_value",
        sort_order: 12,
      },
      {
        id: 14,
        caption: "Results - Gap",
        data_field_name: "gap_result_value",
        sort_order: 13,
      },
    ],
    selected: false,
  },
  {
    id: 5,
    name: "Actual Details",
    selected_fields: [
      {
        id: 20,
        caption: "Name",
        data_field_name: "full_name",
        sort_order: 1,
      },
      {
        id: 1,
        caption: "Job Title",
        data_field_name: "position_title",
        sort_order: 2,
      },
      {
        id: 7,
        caption: "Entities",
        data_field_name: "type",
        sort_order: 3,
      },
      {
        id: 19,
        caption: "Actual - Achievements",
        data_field_name: "overall_actual_achievement",
        sort_order: 4,
      },
      {
        id: 6,
        caption: "Title",
        data_field_name: "title",
        sort_order: 5,
      },
      {
        id: 4,
        caption: "Start Date",
        data_field_name: "start_date",
        sort_order: 6,
      },
      {
        id: 5,
        caption: "End Date",
        data_field_name: "end_date",
        sort_order: 7,
      },
      {
        id: 9,
        caption: "Calc Method",
        data_field_name: "calculation_method_id",
        sort_order: 8,
      },
      {
        id: 10,
        caption: "Start",
        data_field_name: "start_value",
        sort_order: 9,
      },
      {
        id: 11,
        caption: "Target",
        data_field_name: "current_period_target_value",
        sort_order: 10,
      },
      {
        id: 16,
        caption: "Actual",
        data_field_name: "actual_value",
        sort_order: 11,
      },
      {
        id: 17,
        caption: "Actual - Expected",
        data_field_name: "expected_actual_value",
        sort_order: 12,
      },
      {
        id: 18,
        caption: "Actual - Gap",
        data_field_name: "gap_actual_value",
        sort_order: 13,
      },
    ],
    selected: false,
  },
  {
    id: 6,
    name: "All Details",
    selected_fields: [
      {
        id: 20,
        caption: "Name",
        data_field_name: "full_name",
        sort_order: 1,
      },
      {
        id: 1,
        caption: "Job Title",
        data_field_name: "position_title",
        sort_order: 2,
      },
      {
        id: 7,
        caption: "Entities",
        data_field_name: "type",
        sort_order: 3,
      },
      {
        id: 15,
        caption: "Results - Achievements",
        data_field_name: "overall_result_achievement",
        sort_order: 4,
      },
      {
        id: 19,
        caption: "Actual - Achievements",
        data_field_name: "overall_actual_achievement",
        sort_order: 5,
      },
      {
        id: 6,
        caption: "Title",
        data_field_name: "title",
        sort_order: 5,
      },
      {
        id: 4,
        caption: "Start Date",
        data_field_name: "start_date",
        sort_order: 6,
      },
      {
        id: 5,
        caption: "End Date",
        data_field_name: "end_date",
        sort_order: 7,
      },
      {
        id: 9,
        caption: "Calc Method",
        data_field_name: "calculation_method_id",
        sort_order: 8,
      },
      {
        id: 10,
        caption: "Start",
        data_field_name: "start_value",
        sort_order: 9,
      },
      {
        id: 11,
        caption: "Target",
        data_field_name: "current_period_target_value",
        sort_order: 10,
      },
      {
        id: 12,
        caption: "Results",
        data_field_name: "result_value",
        sort_order: 11,
      },
      {
        id: 13,
        caption: "Results - Expected",
        data_field_name: "expected_result_value",
        sort_order: 12,
      },
      {
        id: 14,
        caption: "Results - Gap",
        data_field_name: "gap_result_value",
        sort_order: 13,
      },
      {
        id: 16,
        caption: "Actual",
        data_field_name: "actual_value",
        sort_order: 14,
      },
      {
        id: 17,
        caption: "Actual - Expected",
        data_field_name: "expected_actual_value",
        sort_order: 15,
      },
      {
        id: 18,
        caption: "Actual - Gap",
        data_field_name: "gap_actual_value",
        sort_order: 16,
      },
    ],
    selected: false,
  },
  {
    id: 7,
    name: "Results Achievement Only",
    selected_fields: [
      {
        id: 20,
        caption: "Name",
        data_field_name: "full_name",
        sort_order: 1,
      },
      {
        id: 1,
        caption: "Job Title",
        data_field_name: "position_title",
        sort_order: 2,
      },
      {
        id: 6,
        caption: "Title",
        data_field_name: "title",
        sort_order: 3,
      },
      {
        id: 15,
        caption: "Results - Achievements",
        data_field_name: "overall_result_achievement",
        sort_order: 4,
      },
    ],
    selected: false,
  },
  {
    id: 8,
    name: "Actual Achievement Only",
    selected_fields: [
      {
        id: 20,
        caption: "Name",
        data_field_name: "full_name",
        sort_order: 1,
      },
      {
        id: 1,
        caption: "Job Title",
        data_field_name: "position_title",
        sort_order: 2,
      },
      {
        id: 6,
        caption: "Title",
        data_field_name: "title",
        sort_order: 3,
      },
      {
        id: 19,
        caption: "Actual - Achievements",
        data_field_name: "overall_actual_achievement",
        sort_order: 4,
      },
    ],
    selected: false,
  },
];

const entities = [
  {
    code: "goal",
    name: "Goal",
    selected: false,
  },
  {
    code: "ma",
    name: "Measured Activity",
    selected: false,
  },
];

const status = [
  {
    id: "1",
    code: "draft",
    name: "draft",
    sort_order: 0,
    selected: true,
  },
  {
    id: "2",
    code: "approved",
    name: "approved",
    sort_order: 1,
    selected: true,
  },
  {
    id: "3",
    code: "active",
    name: "active",
    sort_order: 2,
    selected: true,
  },
  {
    id: "6",
    code: "over_due",
    name: "overdue",
    sort_order: 3,
    selected: false,
  },
  {
    id: "4",
    code: "completed",
    name: "completed",
    sort_order: 4,
    selected: false,
  },
  {
    id: "5",
    code: "archived",
    name: "archived",
    sort_order: 5,
    selected: false,
  },
];

const value_type = [
  {
    name: "Result Achievement",
    code: "ora",
    fields: ["overall_result_achievement"],
    selected: false,
  },
  {
    name: "Actual Achievement",
    code: "oav",
    fields: ["overall_actual_achievement"],
    selected: false,
  },
];

const cal_method = [
  {
    name: "Achieved",
    formula: "_value_ >= 100",
    selected: false,
  },
  {
    name: "Warning",
    formula: "_value_ >= 85 && _value_ <= 99",
    selected: false,
  },
  {
    name: "Not achieved",
    formula: "_value_ < 85 && is_no_data!=1",
    selected: false,
  },
  {
    name: "No Data",
    formula: "no data",
    selected: false,
  },
];

const order_by = [
  {
    field: "node_path_named",
    name: "Goal",
    method: "ASC",
    selected: false,
  },
  // {
  //   field: "title",
  //   name: "Title",
  //   method: "ASC",
  //   selected: false,
  // },
  {
    field: "full_name",
    name: "User",
    method: "ASC",
    selected: false,
  },
  {
    field: "position_title",
    name: "Unit",
    method: "ASC",
    selected: false,
  },
  {
    field: "status_id",
    name: "Status",
    method: "ASC",
    selected: false,
  },
  {
    field: "overall_result_achievement",
    name: "Achievement",
    method: "ASC",
    selected: false,
  },
];

export { field_presets, entities, status, value_type, cal_method, order_by };

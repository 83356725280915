import React from "react";
import { Box, Typography, Divider } from "@material-ui/core";
import EditRoundedIcon from "@material-ui/icons/EditRounded";

const SideMenu = ({ classes }) => {
  return (
    <Box className={classes.layoutMarginLeft} mt={3}>
      {listMenu.map((item, i) => (
        <Box mb={1.5}>
          <Typography
            variant="subtitle1"
            className={classes.title555}
            style={{ color: i === 1 && "#d64253" }}>
            <b>
              {i + 1}. {item.title}
            </b>
          </Typography>
          {item.subMenu.length > 0 &&
            item.subMenu.map((sub, j) => (
              <Box ml={3} mb={1.5} mt={j === 0 && 1.5}>
                <Typography variant="subtitle1" className={classes.title555}>
                  {i + 1}.{j + 1} {sub.title}
                </Typography>
              </Box>
            ))}
        </Box>
      ))}
      <Box mt={3} mb={3} mr={3}>
        <Divider />
      </Box>

      <Box display="flex" alignItems="center">
        <EditRoundedIcon
          style={{ fontSize: 20, color: "#888", marginRight: 10 }}
        />
        <Typography variant="body1" className={classes.title888}>
          Edit Agenda
        </Typography>
      </Box>
    </Box>
  );
};

export default SideMenu;

const listMenu = [
  {
    title: "Opening",
    subMenu: [],
  },
  {
    title: "Review Goal Meeting",
    subMenu: [
      {
        title: "Jumlah Visitor dan Artikel",
      },
      {
        title: "Web Visitor (buyer)",
      },
      {
        title: "Produksi Video",
      },
      {
        title: "Revisi Goal & MA",
      },
      {
        title: "Web Visitor (merchant)",
      },
    ],
  },
  {
    title: "Review Problem",
    subMenu: [],
  },
  {
    title: "Review Todo List",
    subMenu: [],
  },
  {
    title: "Closing",
    subMenu: [],
  },
];

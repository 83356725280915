import React, { useEffect, useState } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { Paper, Typography, Grid, Button } from "@material-ui/core";

import DoneIcon from "@material-ui/icons/Done";

import DialogTambahPosisiNEW from "./Components/DialogTambahPosisiNEW";
import DialogDeletePosisi from "./Components/DialogDeletePosisi";

import Redirect from "../../../utilities/Redirect";
import { ToHrEmployeeKeahlian } from "../../../constants/config-redirect-url";
import { styles } from "./Style/StyleInfoPosisi"; // NEXT GANTI TARO DI COMPONENT INFO PEGAWAI SENDIRI !

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewInfoPosisi = (props) => {
  const { classes } = props;

  /*
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */

  const [listPosisitionForLocal, setListPositionForLocal] = useState([]);

  let dataList = [];
  const employeeDataPosition = localStorage.getItem(
    "employee_data_info_position"
  );
  let employeeDataPositionAfterParse =
    employeeDataPosition !== null ? JSON.parse(employeeDataPosition) : [];

  useEffect(() => {
    console.log(
      "employeeDataKeluargaAfterParse : ",
      employeeDataPositionAfterParse
    );

    setListPositionForLocal(employeeDataPositionAfterParse);
  }, []);

  /*
        `````````````````````
        MODAL TAMBAH POSITION

        `````````````````````
    */

  const [modalTambahPosition, setModalTambahPosition] = useState(false);

  //************************************************ PART-1 EXPLORE */
  /*
        ``````````````
        HANDLE CHEKBOX

        ``````````````
    */
  const [isChecked, setChecked] = useState({
    id: "",
    value: "",
    label: "",
  });

  // const [ idPosition, setIdPosition ] = useState([])
  const handleChangeCheckbox = (e, data) => {
    e.preventDefault();

    console.log("Data checkbox : ", data);

    if (data !== undefined) {
      setChecked(data);

      // setIdPosition(data.id)
    }
  };

  //***************************************PART-2 EXPLORE  */
  /*
        ```````````````````````````````
        HANDLE CHOOSE USER IN CHECKBOX

        ```````````````````````````````
    */

  const [selected, setSelected] = useState([]);

  // const handleClickCheckbox = (e, id) => {

  const handleClickCheckbox = (e, item) => {
    console.log("Item checkbox - single choose: ", item);

    const selectedIndex = selected.indexOf(item);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    console.log("Item checkbox - munltiple choose:: : ", newSelected);

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  /*
        ````````````
        MODAL DELETE

        ````````````
    */
  const [modalDelete, setModalDelete] = useState(false);
  const [dataDetailDelete, setDataDetailDelete] = useState("");

  const handleDeleteDataDetail = (e, data) => {
    e.preventDefault();

    setModalDelete(true);
    setDataDetailDelete(data);
  };

  /*
        ``````````````````
        HANDLE SIMPAN DATA 

        ``````````````````
    */

  const handleSimpanData = () => {
    // console.log("Data checked position : ", isChecked);
    console.log("selected : ", selected);

    if (listPosisitionForLocal.length > 0) {
      console.log("Run v1");

      const newList = [...listPosisitionForLocal, ...selected];

      // console.log("newList : ", newList);

      localStorage.setItem(
        "employee_data_info_position",
        JSON.stringify(newList)
      );
      setListPositionForLocal([...listPosisitionForLocal, ...selected]);
    } else {
      console.log("Run v2");
      // console.log("selected : ", selected);
      // dataList.push(selected);
      localStorage.setItem(
        "employee_data_info_position",
        JSON.stringify(selected)
      );

      setListPositionForLocal(selected);
      window.location.reload();
    }

    setModalTambahPosition(false);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <br />
      <br />
      <Grid container>
        <Grid item xs={4} style={{ textAlign: "left" }}>
          <Paper
            elevation={1}
            className={classes.paperInfoIdentitas}
            square={true}>
            <br />
            <Typography variant="subtitle2" className={classes.titleHeader}>
              <b>Informasi Kepegawaian</b>
            </Typography>
            <ul>
              <li className={classes.titleHaveDone} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleHaveDone}>
                  <b>Informasi Pegawai</b>
                  <DoneIcon
                    style={{
                      color: "green",
                      position: "absolute",
                      marginTop: 1,
                      marginLeft: 8,
                      fontSize: 17,
                    }}
                  />
                </Typography>
              </li>

              <li className={classes.titleActive} style={{ margin: 8 }}>
                <Typography variant="subtitle2" className={classes.titleActive}>
                  <b>Informasi Posisi</b>
                </Typography>
              </li>
            </ul>
            <br />
          </Paper>
        </Grid>

        <Grid item xs={8} style={{ textAlign: "left " }}>
          <Paper
            elevation={1}
            className={classes.paperInfoDasar}
            square={true}
            style={{ marginRight: 16 }}>
            <br />
            <Typography variant="subtitle1" className={classes.titleHeader}>
              <b>Informasi Posisi</b>
            </Typography>

            <br />
            <Typography
              variant="subtitle2"
              className={classes.titleTambahAnggotaKeluarga}
              onClick={() => setModalTambahPosition(true)}>
              <b>+ Tambah Posisi</b>
            </Typography>
            <br />
            {listPosisitionForLocal.length > 0 &&
              listPosisitionForLocal.map((item, i) => (
                <Grid container key={i}>
                  <Grid item xs={3}>
                    <Typography
                      variant="subtitle2"
                      className={classes.titleHeader}>
                      <b>Posisi {i + 1}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      variant="subtitle2"
                      className={classes.titleHeader}>
                      <b>{item.structure_position_title_name}</b>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span
                        // onClick= {(e) => handleEditDataDetail(e, item )}
                        style={{ color: "#cc0707", cursor: "pointer" }}>
                        Edit
                      </span>{" "}
                      &nbsp;&nbsp;- &nbsp;&nbsp;
                      <span
                        // onClick={() => localStorage.removeItem('employee_data_info_position')}
                        onClick={(e) => handleDeleteDataDetail(e, item)}
                        style={{ color: "#cc0707", cursor: "pointer" }}>
                        Hapus
                      </span>
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      className={classes.titleHeader}>
                      Di Unit : &nbsp;
                      {item.structure_unit_name}
                    </Typography>
                    <br />
                  </Grid>
                </Grid>
              ))}
            <br />
          </Paper>
        </Grid>
      </Grid>

      {/* 

                ````````````````````
                STICKY FOOTER

                ````````````````````
            */}
      <Grid
        container
        spacing={8}
        direction="row"
        justify="center"
        // alignItems="center"
      >
        <Grid item sm={9}></Grid>

        <Grid item sm={1} style={{ textAlign: "right" }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Button
            variant="outlined"
            color="secondary"
            className={classes.buttonOutlined}
            style={{ margin: 14 }}
            // onClick={() => Redirect(ToMembershipStatusScenarioPENDING_SEMENTARA)}
          >
            Keluar
          </Button>
        </Grid>

        <Grid item sm={2} style={{ textAlign: "left" }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            style={{ margin: 14 }}
            disabled={false}
            onClick={() => Redirect(ToHrEmployeeKeahlian)}>
            Lanjut
          </Button>
        </Grid>
      </Grid>

      <DialogTambahPosisiNEW
        classes={classes}
        modalTambahPosition={modalTambahPosition}
        setModalTambahPosition={setModalTambahPosition}
        isChecked={isChecked}
        handleChangeCheckbox={handleChangeCheckbox}
        handleSimpanData={handleSimpanData}
        //****EXPLORE TEST */
        handleClickCheckbox={handleClickCheckbox}
        selected={selected}
        isSelected={isSelected}
      />

      <DialogDeletePosisi
        classes={classes}
        modalDelete={modalDelete}
        setModalDelete={setModalDelete}
        listPosisitionForLocal={listPosisitionForLocal}
        setListPositionForLocal={setListPositionForLocal}
        dataDetailDelete={dataDetailDelete}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewInfoPosisi);

const positions = [
  {
    id: 1,
    value: "CEO",
    label: "CEO",
  },
  {
    id: 2,
    value: "Manajer HRD",
    label: "Manajer HRD",
  },
  {
    id: 3,
    value: "Staff HRD",
    label: "Staff HRD",
  },
];

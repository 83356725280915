import React, { useEffect, useState } from "react";
import {
  Chip,
  Avatar,
  Typography,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LinkRoundedIcon from "@material-ui/icons/LinkRounded";
import LinkOffRoundedIcon from "@material-ui/icons/LinkOffRounded";

import { URL_API } from "../../../../../constants/config-api";
import axiosConfig from "../../../../../constants/config-axios";

import TruncateTextShortSuperPendek from "../../../../../utilities/TruncateTextShortSuperPendek";

import pdf_icon from "../../../../../assets/images/icons_file/PDF.png";
import excel_icon from "../../../../../assets/images/icons_file/Excel.png";
import ppt_icon from "../../../../../assets/images/icons_file/PowerPoint.png";
import word_icon from "../../../../../assets/images/icons_file/Word.png";
import video_icon from "../../../../../assets/images/icons_file/Video.png";
import text_icon from "../../../../../assets/images/icons_file/Text.png";

function ListEvidenceForEdit({
  item,
  classes,
  onRemove,
  openDialogEditResult,
}) {
  const userToken = localStorage.getItem("userToken");
  let source = null;

  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [response_type, setResponseType] = useState(null);
  const [response404, setResponse404] = useState(false);

  /* ====================================================
    SIDE EFFECT
  ==================================================== */
  useEffect(() => {
    if (openDialogEditResult) {
      if (item.evidenceCategory.name === "Drive") {
        setLoading(true);

        axiosConfig
          .get(`${URL_API}/drive/file/${item.content}?mode=thumbnail`, {
            responseType: "blob",
          })
          .then((response) => {
            // console.log("Response Thumbnail", response);

            if (response.status === 200) {
              console.log("response.data.type", response.data.type);

              setResponseType(response.data.type);
              setImage(response.data);

              if (response.data.type !== null) {
                if (response.data.type === "application/pdf") {
                  source = pdf_icon;
                } else if (response.data.type.includes("video")) {
                  source = video_icon;
                } else if (
                  response.data.type === "application/msword" ||
                  response.data.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                ) {
                  source = word_icon;
                } else if (
                  response.data.type === "application/vnd.ms-powerpoint" ||
                  response.data.type ===
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                ) {
                  source = ppt_icon;
                } else if (
                  response.data.type === "application/vnd.ms-excel" ||
                  response.data.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                  source = excel_icon;
                } else if (response.data.type === "text/plain") {
                  source = text_icon;
                }
              }
            }
            setLoading(false);
          })
          .catch((error) => {
            console.log("Error", error.response);
            setLoading(false);

            if (error.response.status === 404) {
              setResponse404(true);
            }
          });
      }
    }
  }, [openDialogEditResult]);

  // Blob Type to URL => for thumbnail
  const blobUrlImage = new Blob([image], { type: "image/*" });

  return (
    <div style={{ marginRight: 5, marginBottom: 5 }}>
      {item.evidenceCategory.name === "Drive" && (
        <Tooltip
          title={response404 ? "File di Drive sudah tidak ada" : item.label}>
          <Chip
            size="small"
            label={
              <Typography className={classes.title} style={{ fontSize: 13 }}>
                {TruncateTextShortSuperPendek(item.label)}
              </Typography>
            }
            avatar={
              loading ? (
                <CircularProgress size={12} style={{ color: "#d1354a" }} />
              ) : !response404 ? (
                <Avatar
                  alt={item.label}
                  src={source}
                  style={{
                    borderRadius: 5,
                    width: 32,
                    border: "2px solid #f2f2f2",
                    margin: 3,
                  }}
                />
              ) : null
            }
            style={{
              textDecorationLine: response404 && "line-through",
            }}
            icon={
              response404 ? (
                <LinkOffRoundedIcon style={{ color: "#d35400" }} />
              ) : null
            }
            deleteIcon={
              <HighlightOffIcon fontSize="small" style={{ color: "#d1354a" }} />
            }
            onDelete={() => onRemove(item)}
          />
        </Tooltip>
      )}

      {item.evidenceCategory.name === "Attachment" && (
        <Tooltip title={item.label}>
          <Chip
            size="small"
            label={
              <Typography className={classes.title} style={{ fontSize: 13 }}>
                {TruncateTextShortSuperPendek(item.label)}
              </Typography>
            }
            avatar={
              <Avatar
                alt={item.label}
                src={`${URL_API}/${item.self.rel.content}?token=${userToken}&mode=thumbnail`}
                style={{
                  borderRadius: 5,
                  width: 32,
                  border: "2px solid #f2f2f2",
                  margin: 3,
                }}
              />
            }
            deleteIcon={
              <HighlightOffIcon fontSize="small" style={{ color: "#d1354a" }} />
            }
            onDelete={() => onRemove(item)}
          />
        </Tooltip>
      )}

      {item.evidenceCategory.name === "Link" && (
        <Tooltip title={item.label}>
          <Chip
            size="small"
            label={
              <Typography className={classes.title} style={{ fontSize: 13 }}>
                {TruncateTextShortSuperPendek(item.label)}
              </Typography>
            }
            icon={<LinkRoundedIcon />}
            style={{ margin: 3 }}
            deleteIcon={
              <HighlightOffIcon fontSize="small" style={{ color: "#d1354a" }} />
            }
            onDelete={() => onRemove(item)}
          />
        </Tooltip>
      )}
    </div>
  );
}

export default ListEvidenceForEdit;

import React, { useState, useReducer, useEffect, useRef } from 'react';
import axios from 'axios';

import ContextCompanySetting from './ContextCompanySetting';
import { URL_API } from '../../../../constants/config-api';

const ContextGlobalCompanySetting = props => {

    /*
        ````````````````
        USER TOKEN STATE

        ````````````````
    */
   const [ userTokenStateGlobal, setUserTokenStateGlobal ] = useState('');
   const [ fireUpdate, setFireUpdate ] = useState(false);   
   
   useEffect(() => {
       
       const userToken = localStorage.getItem('userToken');
        setUserTokenStateGlobal(userToken);

        // setFireUpdate(false);

    },[]);

    const [ loader, setLoader ] = useState(false);

    const [ isUpdateLocationStorage, setUpdateLocationStorage ] = useState(false);
    
    /*
        ``````````````````
        HANDLE PAGINATION 

        ``````````````````
    */
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const[ offset, setOffset ] = useState('');
    const [ loaderForPaging, setLoaderForPaging ] = useState(false);
    const [valueSearchState, setValueSearchState ] = useState('');


    /*
        ```````````
        LIST & Ids

        ``````````
    */

    const [ cityCollections, setCityCollections ] = useState([]);
    const [ countryCollections, setCountryCollections] = useState([]);
    const [ postalCodeCollections, setPostalCodeCollections]= useState([]);
    const [ provinceCollections, setProvinceCollections] = useState([]);

    const [ fieldsCountryId, setFieldsCountryId ] = useState(null);
    const [ fieldsProvinceId, setFieldsProvinceId ] = useState(null);
    const [ fieldsCityId, setFieldsCityId ] = useState(null);
    const [ fieldsPostalCodeId, setFieldsPostalCodeId ] = useState(null);

    return (

        <ContextCompanySetting.Provider
            
            value={{

                userTokenStateGlobal : userTokenStateGlobal,
                loader :  loader,
                setLoader : setLoader,

                page : page,
                setPage : setPage,

                rowsPerPage : rowsPerPage,
                setRowsPerPage : setRowsPerPage,

                offset : offset,
                setOffset: setOffset,

                loaderForPaging: loaderForPaging,
                setLoaderForPaging: setLoaderForPaging,

                valueSearchState : valueSearchState,
                setValueSearchState:  setValueSearchState,

                cityCollections : cityCollections, 
                setCityCollections: setCityCollections,

                countryCollections : countryCollections, 
                setCountryCollections : setCountryCollections,

                postalCodeCollections : postalCodeCollections, 
                setPostalCodeCollections : setPostalCodeCollections,

                provinceCollections : provinceCollections,
                setProvinceCollections : setProvinceCollections,

                fieldsCountryId : fieldsCountryId,
                setFieldsCountryId : setFieldsCountryId,

                fieldsProvinceId : fieldsProvinceId,
                setFieldsProvinceId : setFieldsProvinceId,

                fieldsCityId : fieldsCityId,
                setFieldsCityId : setFieldsCityId,

                fieldsPostalCodeId : fieldsPostalCodeId,
                setFieldsPostalCodeId : setFieldsPostalCodeId,

                fireUpdate : fireUpdate,
                setFireUpdate: setFireUpdate,

                isUpdateLocationStorage : isUpdateLocationStorage,
                setUpdateLocationStorage : setUpdateLocationStorage
          
            }}
        >
            {props.children}
        </ContextCompanySetting.Provider>
    )
};

export default ContextGlobalCompanySetting;







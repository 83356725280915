import React, { Component, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Breadcrumbs,
  Link,
  IconButton,
  InputBase,
  Divider,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TablePagination,
  TableHead,
  TableSortLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";

import StickyFooter from "react-sticky-footer";
import DoneIcon from "@material-ui/icons/Done";

import Redirect from "../../../utilities/Redirect";
import { ToHrEmployeeRiwayatPendidikanFormal } from "../../../constants/config-redirect-url";
import { styles } from "./Style/StyleRiwayat"; // NEXT GANTI TARO DI COMPONENT INFO PEGAWAI SENDIRI !

// import Snackber from '../Components/Snackber';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewRiwayat = (props) => {
  const { classes } = props;

  /*
        ````````````````````````````````````
        COMPONENT DID MOUNT

        ````````````````````````````````````
    */
  const [listDataRiwayat, setListDataRiwayat] = useState("");

  let dataList = [];

  const employeeDataRiwayatKesehatan = localStorage.getItem(
    "employee_data_riwayat_kesehatan"
  );
  let employeeDataRiwayatKesehatanAfterParse =
    employeeDataRiwayatKesehatan !== null
      ? JSON.parse(employeeDataRiwayatKesehatan)
      : [];

  useEffect(() => {
    // localStorage.removeItem('employee_data_riwayat_kesehatan');
    console.log(
      "employeeDataRiwayatKesehatanAfterParse : ",
      employeeDataRiwayatKesehatanAfterParse
    );

    setListDataRiwayat(employeeDataRiwayatKesehatanAfterParse);
  }, []);

  /*
        `````````````````````
        MODAL TAMBAH POSITION

        `````````````````````
    */

  const [modalRiwayatKesehatan, setModalRiwayatKesehatan] = useState(false);

  /*
        `````````
        FORM DATA

        `````````
    */

  const [penyakit, setPenyakit] = useState("");

  const [kategoriTingkatanPenyakit, setKategoriTingkatanPenyakit] = useState({
    name: "",
  });

  const [tindakan, setTindakan] = useState({
    name: "",
  });

  const [frekuensi, setFrekuensi] = useState("");

  const [dateIN, setDateIN] = useState({
    name: "",
  });

  const [dateOUT, setDateOUT] = useState({
    name: "",
  });

  const handleChangePenyakit = (e) => setPenyakit(e.target.value);
  const handleChangeKategoriTingkatanPenyakit = (name) => (event) => {
    setKategoriTingkatanPenyakit({
      ...kategoriTingkatanPenyakit,
      [name]: event.target.value,
    });
  };

  const handleChangeTindakan = (name) => (event) => {
    setTindakan({ ...tindakan, [name]: event.target.value });
  };

  const handleChangeFrekuensi = (e) => setFrekuensi(e.target.value);

  const handleChangeDateIN = (name) => (event) => {
    setDateIN({ ...dateIN, [name]: event.target.value });
  };

  const handleChangeDateOUT = (name) => (event) => {
    setDateOUT({ ...dateOUT, [name]: event.target.value });
  };

  // const handleChangeCheckboxStatusPelajar = name => event => {

  //     setStatusPelajarChecked({ ...statusPelajarChecked, [name]: event.target.checked });
  // };

  /*
        ```````````````````
        HANDLE SIMPAN DATA

        ```````````````````
    */

  const handleSimpanDataPendidikanFormal = () => {
    // let paramsData = {

    //     EmployeeHealthHistory : [
    //         {
    //             name: penyakit,
    //             category_id: kategoriTingkatanPenyakit.name !== '' ?  kategoriTingkatanPenyakit.name.id : '',
    //             illness_action_id: tindakan.name !== '' ? tindakan.name.id : '',
    //             frequency: frekuensi,
    //             start_date: dateIN.name !== '' ?  dateIN.name.value : '',
    //             end_date : dateOUT.name !== '' ?  dateOUT.name.value : ''

    //         }
    //     ]

    // };

    let paramsData = {
      name: penyakit,
      category_id:
        kategoriTingkatanPenyakit.name !== ""
          ? kategoriTingkatanPenyakit.name
          : "",
      illness_action_id: tindakan.name !== "" ? tindakan.name : "",
      frequency: frekuensi,
      start_date: dateIN.name !== "" ? dateIN.name : "",
      end_date: dateOUT.name !== "" ? dateOUT.name : "",
    };

    console.log("Riwayat Penyakit : ", paramsData);

    if (listDataRiwayat.length > 0) {
      console.log("Run v1");

      const newList = [...listDataRiwayat, paramsData];

      console.log("newList : ", newList);

      localStorage.setItem(
        "employee_data_riwayat_kesehatan",
        JSON.stringify(newList)
      );
      setListDataRiwayat([...listDataRiwayat, paramsData]);
    } else {
      console.log("Run v2");

      dataList.push(paramsData);
      localStorage.setItem(
        "employee_data_riwayat_kesehatan",
        JSON.stringify(dataList)
      );
      window.location.reload();
    }

    setModalRiwayatKesehatan(false);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <br />
      <br />
      <Grid container>
        <Grid item xs={4} style={{ textAlign: "left" }}>
          <Paper
            elevation={1}
            className={classes.paperInfoIdentitas}
            square={true}>
            <br />
            <Typography variant="subtitle2" className={classes.titleHeader}>
              <b>Informasi Riwayat</b>
            </Typography>
            <ul>
              {/* <li className={classes.titleHaveDone}  style={{margin: 8}}> */}
              <li className={classes.titleActive} style={{ margin: 8 }}>
                <Typography variant="subtitle2" className={classes.titleActive}>
                  <b>Kesehatan</b>
                  {/* <DoneIcon style={{color: 'green', position: 'absolute', marginTop: 1, marginLeft: 8, fontSize: 17}} /> */}
                </Typography>
              </li>

              <li className={classes.titleNotActive} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleNotActive}>
                  <b>Pendidikan formal </b>
                </Typography>
              </li>

              <li className={classes.titleNotActive} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleNotActive}>
                  <b>Pendidikan informal </b>
                </Typography>
              </li>

              <li className={classes.titleNotActive} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleNotActive}>
                  <b>Organisasi</b>
                </Typography>
              </li>
              <li className={classes.titleNotActive} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleNotActive}>
                  <b>Pengalaman Kerja</b>
                </Typography>
              </li>
            </ul>
            <br />
          </Paper>
        </Grid>

        <Grid item xs={8} style={{ textAlign: "left " }}>
          <Paper
            elevation={1}
            className={classes.paperInfoDasar}
            square={true}
            style={{ marginRight: 16 }}>
            <br />
            <Typography variant="subtitle1" className={classes.titleHeader}>
              <b>Kesehatan</b>
            </Typography>

            <br />
            <Typography
              variant="subtitle2"
              className={classes.titleTambahAnggotaKeluarga}
              onClick={() => setModalRiwayatKesehatan(true)}>
              <b>+ Tambah Riwayat Kesehatan</b>
            </Typography>
            <br />
            {listDataRiwayat.length > 0 &&
              listDataRiwayat.map((item, i) => {
                return (
                  <Grid container key={i}>
                    <Grid xs={4}>
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}>
                        <b>{item.name}</b>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}>
                        <b>
                          {item.start_date.label + " - "} {item.end_date.label}
                        </b>
                      </Typography>
                      {/* <Typography variant='subtitle2' className={classes.titleHeader}>
                                                <b>{item.illness_action_id.label + " "}</b> 
                                            </Typography> */}
                    </Grid>
                    <Grid xs={8}>
                      <Typography
                        variant="subtitle1"
                        className={classes.titleHeader}>
                        {/* <b>{ item.instansi + ", "} {item.kota}</b> &nbsp;&nbsp; */}
                        <span
                          style={{
                            color: "#cc0707",
                            cursor: "pointer",
                            fontFamily: "Roboto",
                            fontSize: 12,
                          }}>
                          Edit
                        </span>{" "}
                        &nbsp;&nbsp;- &nbsp;&nbsp;
                        <span
                          // onClick={() => setModalDeleteAnggotaKeluarga(true)}
                          onClick={() =>
                            localStorage.removeItem(
                              "employee_data_riwayat_kesehatan"
                            )
                          }
                          style={{
                            color: "#cc0707",
                            cursor: "pointer",
                            fontFamily: "Roboto",
                            fontSize: 12,
                          }}>
                          Hapus
                        </span>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}>
                        {item.illness_action_id.label}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}>
                        Kategori penyakit : {item.category_id.label}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}>
                        Tingkat frekuensi : {item.frequency}
                      </Typography>

                      <br />
                    </Grid>
                  </Grid>
                );
              })}
          </Paper>
        </Grid>
      </Grid>

      {/* 

                ````````````````````
                STICKY FOOTER

                ````````````````````
                    
            */}

      <Grid
        container
        spacing={8}
        direction="row"
        justify="center"
        // alignItems="center"
      >
        <Grid item sm={9}></Grid>

        <Grid item sm={1} style={{ textAlign: "right" }}>
          <br />
          <br />
          <br />
          <Button
            variant="outlined"
            color="secondary"
            className={classes.buttonOutlined}
            style={{ margin: 14 }}
            // onClick={() => Redirect(ToMembershipStatusScenarioPENDING_SEMENTARA)}
          >
            Keluar
          </Button>
        </Grid>
        <Grid item sm={2} style={{ textAlign: "left" }}>
          <br />
          <br />
          <br />
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            style={{ margin: 14 }}
            disabled={false}
            onClick={() => Redirect(ToHrEmployeeRiwayatPendidikanFormal)}>
            Lanjut
          </Button>
        </Grid>
      </Grid>

      <Dialog
        open={modalRiwayatKesehatan}
        onClose={() => setModalRiwayatKesehatan(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          <Typography variant="subtitle1" className={classes.title}>
            <b>Tambah Riwayat Penyakit</b>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Nama Penyakit</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-bare"
                onChange={handleChangePenyakit}
                value={penyakit}
                className={classes.textField}
                variant="outlined"
                // placeholder={'NIP'}
                // error={infoError == true && npwp == '' ? true : false}
                // helperText={infoError == true && npwp == '' ? "Wajib di isi" : ' '}
                // required={true}
                // name='nip'
                // color='primary'
                // onKeyDown={handleEnterPress}
                // disabled= {isLockedStatusState == 1 ? true : false}
                // fullWidth
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Kategori Penyakit</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-select-provinsi"
                select
                // label="Pilih Negara : "
                className={classes.textField}
                value={kategoriTingkatanPenyakit.name}
                onChange={handleChangeKategoriTingkatanPenyakit("name")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined">
                {kategoriTingkatanPenyakits.map((option) => (
                  <MenuItem key={option.value} value={option}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Tindakan</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-select-provinsi"
                select
                // label="Pilih Negara : "
                className={classes.textField}
                value={tindakan.name}
                onChange={handleChangeTindakan("name")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined">
                {tindakans.map((option) => (
                  <MenuItem key={option.value} value={option}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Frekuensi</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-bare"
                onChange={handleChangeFrekuensi}
                value={frekuensi}
                className={classes.textField}
                variant="outlined"
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Tanggal</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-select-tahun-in"
                select
                label="Masuk"
                className={classes.textFieldDropDown}
                value={dateIN.name}
                onChange={handleChangeDateIN("name")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined">
                {tahuns.map((option) => (
                  <MenuItem key={option.value} value={option}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="outlined-select-tahun-out"
                select
                label="Selesai"
                className={classes.textFieldDropDown}
                value={dateOUT.name}
                onChange={handleChangeDateOUT("name")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined">
                {tahuns.map((option) => (
                  <MenuItem key={option.value} value={option}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </ListItem>
          </List>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h5" className={classes.title}></Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "right", justifyContent: "flex-end" }}>
          <Button
            onClick={() => setModalRiwayatKesehatan(false)}
            variant="outlined"
            size="small"
            className={classes.buttonModalCancel}>
            Batal
          </Button>

          <Button
            // onClick={() => Redirect(ToCompletionProfile)}
            // onClick= {() => setModalRiwayatKesehatan(false)}
            onClick={handleSimpanDataPendidikanFormal}
            variant="contained"
            color="primary"
            size="small"
            className={classes.buttonModal}>
            Simpan
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewRiwayat);

const tindakans = [
  {
    id: 1,
    value: "Rawat Inap",
    label: "Rawat Inap",
  },
  {
    id: 2,
    value: "Rawat Jalan",
    label: "Rawat Jalan",
  },
  {
    id: 3,
    value: "Operasi",
    label: "Operasi",
  },
];

const kategoriTingkatanPenyakits = [
  {
    id: 1,
    value: "Akut",
    label: "Akut",
  },

  {
    id: 2,
    value: "Kronis",
    label: "Kronis",
  },
];

const tahuns = [
  {
    value: 1991,
    label: 1991,
  },
  {
    value: 1992,
    label: 1992,
  },
  {
    value: 1993,
    label: 1993,
  },
  {
    value: 1994,
    label: 1994,
  },
  {
    value: 1995,
    label: 1995,
  },
  {
    value: 1996,
    label: 1996,
  },
  {
    value: 1997,
    label: 1997,
  },
  {
    value: 1998,
    label: 1998,
  },
  {
    value: 1999,
    label: 1999,
  },
  {
    value: 2005,
    label: 2005,
  },
  {
    value: 2006,
    label: 2006,
  },
  {
    value: 2007,
    label: 2007,
  },
  {
    value: 2008,
    label: 2009,
  },
  {
    value: 2010,
    label: 2010,
  },
  {
    value: 2011,
    label: 2011,
  },
  {
    value: 2012,
    label: 2012,
  },
  {
    value: 2013,
    label: 2013,
  },
  {
    value: 2014,
    label: 2014,
  },
  {
    value: 2015,
    label: 2015,
  },
  {
    value: 2016,
    label: 2016,
  },
  {
    value: 2017,
    label: 2017,
  },
  {
    value: 2018,
    label: 2018,
  },
  {
    value: 2019,
    label: 2019,
  },
  {
    value: 2020,
    label: 2020,
  },
  {
    value: 2021,
    label: 2021,
  },
  {
    value: 2022,
    label: 2022,
  },
  {
    value: 2023,
    label: 2023,
  },
  {
    value: 2024,
    label: 2024,
  },
  {
    value: 2025,
    label: 2025,
  },
  {
    value: 2026,
    label: 2026,
  },
  {
    value: 2027,
    label: 2027,
  },
];

import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Typography,
  TableRow,
  TableCell,
  Box,
  Avatar,
  IconButton,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import SyncAltIcon from "@material-ui/icons/SyncAlt";

import clsx from "clsx";
import moment from "moment";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import FormatBytes from "../../../../utilities/FormatBytes";
import ContextGlobalDrive from "../../context/ContextDrive";

import pdf_icon from "../../../../assets/images/icons_file/PDF.png";
import excel_icon from "../../../../assets/images/icons_file/Excel.png";
import ppt_icon from "../../../../assets/images/icons_file/PowerPoint.png";
import word_icon from "../../../../assets/images/icons_file/Word.png";
import video_icon from "../../../../assets/images/icons_file/Video.png";
import text_icon from "../../../../assets/images/icons_file/Text.png";

import DialogInfoFiles from "../components/DialogInfoFiles";
import DialogDeleteFile from "../components/DialogDeleteFile";
import DialogRenameFile from "../components/DialogRenameFile";
import DialogPreviewImage from "../components/DialogPreviewImage";
import DialogMoveFile from "../components/DialogMoveFile";
import DialogPreviewVideo from "../components/DialogPreviewVideo";
import DialogDownloadFile from "../components/DialogDownloadFile";
import DialogPreviewPDF from "../components/DialogPreviewPDF";
import DialogPreviewDocument from "../components/DialogPreviewDocument";
import DialogPreviewText from "../components/DialogPreviewText";

const RecoveredFilesList = ({ classes, item, isFileSelected }) => {
  const context = useContext(ContextGlobalDrive);
  /* ====================================================
    LOCAL STATE
    ==================================================== */
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [anchorElFile, setAnchorElFile] = useState(null);

  const [openDialogInfoFile, setOpenDialogInfoFile] = useState(false);
  const [openDialogDeleteFile, setOpenDialogDeleteFile] = useState(false);
  const [openDialogRenameFile, setOpenDialogRenameFile] = useState(false);
  const [openDialogMoveFile, setOpenDialogMoveFile] = useState(false);
  const [openDialogPreviewImage, setOpenDialogPreviewImage] = useState(false);
  const [openDialogPreviewVideo, setOpenDialogPreviewVideo] = useState(false);
  const [openDialogDownloadFile, setOpenDialogDownloadFile] = useState(false);
  const [openDialogPreviewPDF, setOpenDialogPreviewPDF] = useState(false);
  const [openDialogPreviewDocument, setOpenDialogPreviewDocument] = useState(
    false
  );
  const [openDialogPreviewText, setOpenDialogPreviewText] = useState(false);

  /* ====================================================
    SIDE EFFECT
  ==================================================== */

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`${URL_API}/drive/file/${item.id}?mode=thumbnail`, {
        responseType: "blob",
      })
      .then((response) => {
        // console.log("Response Thumbnail", response);

        if (response.status === 200) {
          setImage(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setLoading(false);
      });
  }, []);

  /* ====================================================
    LOCAL FUNCS / METHODS
  ==================================================== */

  const handleShowAnchorFile = (event) => {
    setAnchorElFile(event.currentTarget);
  };

  const handleCloseAnchorFile = () => {
    setAnchorElFile(null);
  };

  // Handle Dialog Info File
  const handleDialogInfoFile = () => {
    setOpenDialogInfoFile(true);
    setAnchorElFile(null);
  };

  const handleDialogDeleteFile = () => {
    setOpenDialogDeleteFile(true);
    setAnchorElFile(null);
  };

  const handleDialogRenameFile = () => {
    setOpenDialogRenameFile(true);
    setAnchorElFile(null);
  };

  const handleDialogMoveFile = () => {
    setOpenDialogMoveFile(true);
    setAnchorElFile(null);
  };

  const handleDialogDownloadFile = () => {
    setOpenDialogDownloadFile(true);
    setAnchorElFile(null);
  };

  // Handle Preview File
  const handlePreviewFile = (data) => {
    const imageType = data.mime_type.includes("image");
    const videoType = data.mime_type.includes("video");
    const pdfType = data.mime_type.includes("pdf");
    const textType = data.mime_type.includes("text");
    const docType = data.extension;

    if (imageType) {
      setOpenDialogPreviewImage(true);
      setAnchorElFile(null);
    } else if (videoType) {
      setOpenDialogPreviewVideo(true);
      setAnchorElFile(null);
    } else if (pdfType) {
      setOpenDialogPreviewPDF(true);
      setAnchorElFile(null);
    } else if (
      docType === "doc" ||
      docType === "docx" ||
      docType.includes("ppt") ||
      docType === "xlsx" ||
      docType === "xls"
    ) {
      setOpenDialogPreviewDocument(true);
      setAnchorElFile(null);
    } else if (textType) {
      setOpenDialogPreviewText(true);
      setAnchorElFile(null);
    }

    // console.log(data.mime_type);
  };

  // Blob Type to URL => for thumbnail
  const blobUrlImage = new Blob([image], { type: item.mime_type });

  // Handle Select Checkbox
  const handleChangeCheckbox = (item) => {
    const selectedIndex = context.selectedFile.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(context.selectedFile, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(context.selectedFile.slice(1));
    } else if (selectedIndex === context.selectedFile.length - 1) {
      newSelected = newSelected.concat(context.selectedFile.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        context.selectedFile.slice(0, selectedIndex),
        context.selectedFile.slice(selectedIndex + 1)
      );
    }

    // console.log("selected Folders", newSelected);

    context.setSelectedFile(newSelected);
  };

  // Check is Folder selected or not
  const isItemFileSelected = isFileSelected(item.id);

  return (
    <Fragment>
      <TableRow style={{ backgroundColor: "#eafaf1" }}>
        <TableCell padding="checkbox">
          {context.toggleSelect && (
            <Checkbox
              style={{ marginTop: -10 }}
              onClick={() => handleChangeCheckbox(item)}
              className={classes.checkboxRoot}
              checked={isItemFileSelected}
              disableRipple
              color="default"
              checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
              }
              icon={<span className={classes.icon} />}
              inputProps={{ "aria-label": "decorative checkbox" }}
            />
          )}
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center">
            {item.mime_type !== null && item.mime_type.includes("image") ? (
              <Avatar
                variant="rounded"
                src={image !== "" && URL.createObjectURL(blobUrlImage)}
                style={{
                  width: 30,
                  height: 30,
                }}>
                {loading && (
                  <CircularProgress size={14} style={{ color: "#d1354a" }} />
                )}
              </Avatar>
            ) : item.mime_type !== null &&
              item.mime_type === "application/pdf" ? (
              <img
                src={pdf_icon}
                style={{
                  width: 20,
                  margin: `0 5px`,
                }}
              />
            ) : (item.mime_type !== null &&
                item.mime_type === "application/msword") ||
              item.mime_type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
              <img
                src={word_icon}
                style={{
                  width: 20,
                  margin: `0 5px`,
                }}
              />
            ) : (item.mime_type !== null &&
                item.mime_type === "application/vnd.ms-powerpoint") ||
              item.mime_type ===
                "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
              <img
                src={ppt_icon}
                style={{
                  width: 20,
                  margin: `0 5px`,
                }}
              />
            ) : (item.mime_type !== null &&
                item.mime_type === "application/vnd.ms-excel") ||
              item.mime_type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
              <img
                src={excel_icon}
                style={{
                  width: 20,
                  margin: `0 5px`,
                }}
              />
            ) : item.mime_type !== null && item.mime_type.includes("video") ? (
              <img
                src={video_icon}
                style={{
                  width: 20,
                  margin: `0 5px`,
                }}
              />
            ) : item.mime_type !== null && item.mime_type === "text/plain" ? (
              <img
                src={text_icon}
                style={{
                  width: 25,
                  margin: `0 2px`,
                }}
              />
            ) : (
              <InsertDriveFileIcon
                style={{ color: "#888" }}
                fontSize="default"
              />
            )}

            <Typography
              variant="subtitle2"
              className={classes.folderNameText}
              style={{ marginLeft: 15, marginRight: 7.5 }}
              onClick={() => handlePreviewFile(item)}>
              {item.name}
            </Typography>
          </Box>
        </TableCell>

        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}>
            {moment(item.updated_at).format("DD MMM YYYY, HH:mm")}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}>
            {item.extension}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}>
            {FormatBytes(Number(item.size))}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}>
            {item.owner !== undefined && item.owner !== null
              ? `${item.owner.member.first_name} ${item.owner.member.last_name}`
              : "User has been deleted"}
          </Typography>
        </TableCell>

        <TableCell align="right">
          <IconButton onClick={handleShowAnchorFile}>
            <MoreVertIcon />
          </IconButton>
        </TableCell>

        <StyledMenu
          id="customized-menu"
          anchorEl={anchorElFile}
          keepMounted
          open={Boolean(anchorElFile)}
          onClose={handleCloseAnchorFile}>
          <StyledMenuItem style={{ width: 200 }} onClick={handleDialogInfoFile}>
            <ListItemIcon>
              <VisibilityOutlinedIcon
                fontSize="small"
                className={classes.hoverColor}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  className={classes.rightMenuText}>
                  View Info
                </Typography>
              }
            />
          </StyledMenuItem>
          <StyledMenuItem onClick={handleDialogRenameFile}>
            <ListItemIcon>
              <EditOutlinedIcon
                fontSize="small"
                className={classes.hoverColor}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  className={classes.rightMenuText}>
                  Rename
                </Typography>
              }
            />
          </StyledMenuItem>

          <StyledMenuItem onClick={handleDialogDeleteFile}>
            <ListItemIcon>
              <DeleteOutlinedIcon
                fontSize="small"
                className={classes.hoverColor}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  className={classes.rightMenuText}>
                  Delete File
                </Typography>
              }
            />
          </StyledMenuItem>
          <StyledMenuItem onClick={handleDialogMoveFile}>
            <ListItemIcon>
              <SyncAltIcon fontSize="small" className={classes.hoverColor} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  className={classes.rightMenuText}>
                  Move File
                </Typography>
              }
            />
          </StyledMenuItem>
          <StyledMenuItem onClick={handleDialogDownloadFile}>
            <ListItemIcon>
              <CloudDownloadOutlinedIcon
                fontSize="small"
                className={classes.hoverColor}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  className={classes.rightMenuText}>
                  Download
                </Typography>
              }
            />
          </StyledMenuItem>
        </StyledMenu>
      </TableRow>

      <DialogInfoFiles
        openDialogInfoFile={openDialogInfoFile}
        setOpenDialogInfoFile={setOpenDialogInfoFile}
        item={item}
        classes={classes}
        origin="Drive"
      />

      <DialogDeleteFile
        openDialogDeleteFile={openDialogDeleteFile}
        setOpenDialogDeleteFile={setOpenDialogDeleteFile}
        item={item}
        classes={classes}
      />

      <DialogRenameFile
        openDialogRenameFile={openDialogRenameFile}
        setOpenDialogRenameFile={setOpenDialogRenameFile}
        item={item}
        classes={classes}
      />

      <DialogPreviewImage
        openDialogPreviewImage={openDialogPreviewImage}
        setOpenDialogPreviewImage={setOpenDialogPreviewImage}
        itemId={item.id}
        itemType={item.mime_type}
        itemName={item.name}
        origin="Drive"
      />

      <DialogMoveFile
        openDialogMoveFile={openDialogMoveFile}
        setOpenDialogMoveFile={setOpenDialogMoveFile}
        classes={classes}
        item={item}
      />

      <DialogPreviewVideo
        openDialogPreviewVideo={openDialogPreviewVideo}
        setOpenDialogPreviewVideo={setOpenDialogPreviewVideo}
        itemId={item.id}
        itemName={item.name}
      />

      <DialogDownloadFile
        openDialogDownloadFile={openDialogDownloadFile}
        setOpenDialogDownloadFile={setOpenDialogDownloadFile}
        item={item}
        classes={classes}
        origin="Drive"
      />

      <DialogPreviewPDF
        openDialogPreviewPDF={openDialogPreviewPDF}
        setOpenDialogPreviewPDF={setOpenDialogPreviewPDF}
        itemId={item.id}
        itemName={item.name}
        itemType={item.mime_type}
      />

      <DialogPreviewDocument
        openDialogPreviewDocument={openDialogPreviewDocument}
        setOpenDialogPreviewDocument={setOpenDialogPreviewDocument}
        itemId={item.id}
        itemName={item.name}
        origin="Drive"
      />

      <DialogPreviewText
        openDialogPreviewText={openDialogPreviewText}
        setOpenDialogPreviewText={setOpenDialogPreviewText}
        itemId={item.id}
        itemName={item.name}
        classes={classes}
        item={item}
        origin="Drive"
      />
    </Fragment>
  );
};

export default RecoveredFilesList;

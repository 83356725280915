import React, { useEffect, useState, useContext } from "react";
import {
  createMuiTheme,
  MuiThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import clsx from "clsx";

import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  CssBaseline,
} from "@material-ui/core";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";

import AccountCircle from "@material-ui/icons/AccountCircle";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PollRoundedIcon from "@material-ui/icons/PollRounded";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import DateRangeIcon from "@material-ui/icons/DateRange";
import SpeedIcon from "@material-ui/icons/Speed";
import PeopleIcon from "@material-ui/icons/People";
import AccountTreeIcon from "@material-ui/icons/AccountTree";

import { navigate } from "hookrouter";

import ContextCompanySetting from "../screen/AM/CompanySetting/Context/ContextCompanySetting";

import DialogHelp from "../components/DialogHelp";
import ImageSmallUser from "../components/ImageSmallUser";

import { StyledMenu, StyledMenuItem } from "../components/StyledMenuDropdown";

import ImageDashboardHome from "../assets/images/Subtraction_3.png";
import Image7WD from "../assets/images/Group_110.png";
import ImageHumanCapital from "../assets/images/Group_663.png";

import IconMenuOffCanvass from "../assets/images/Group_1923.png";
import IconMenuOffCanvassGrey from "../assets/images/SVG/Group_709.svg";
import { lightBlue, green } from "@material-ui/core/colors";

import axiosConfig from "../constants/config-axios";

import { URL_API } from "../constants/config-api";
import { styles } from "./Style/StyleHeaderGoal";
import DialogError from "../components/DialogError";

// import { version } from "../../package.json";

import {
  ToCompanySetting,
  ToDashboard,
  ToUserManagement,
  ToLogin,
  ToEmptyStateGeneral,
  ToNewSO,
  To7wdPeriode,
  ToGoal,
  ToGoalDetail,
  ToGoalDetailALL,
  ToTask,
  ToAllMA,
  ToDrive,
  ToReports,
  ToMeeting,
} from "../constants/config-redirect-url";

import { ToNewSoTreeViewOrgChart } from "../screen/SO/Constants/config-navigate-url-with-hookrouter";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#fff", //#cc0707, #c62828
      light: "#fff",
      dark: "#fff",
      contrastText: "#ffffff",
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: "#fff",
      },
    },
    MuiToolbar: {
      root: {
        backgroundColor: "#fff",
        // backgroundColor: '#d6d6d6'
      },
    },
    MuiButton: {
      text: {
        color: "white",
      },
      textSecondary: {
        color: "white",
        fontFamily: "Roboto",
      },
    },
    MuiDrawer: {
      root: {
        backgroundColor: lightBlue,
      },
      paper: {
        backgroundColor: green,
      },

      paperAnchorLeft: {},
    },
    MuiListItemIcon: {
      root: {
        color: "white",
      },
    },
    MuiBadge: {
      root: {
        color: "#d1354a",
      },
    },
  },
});

const useStyles = makeStyles(styles);

const HeaderGOAL = () => {
  const classes = useStyles();
  const contextCompanySetting = useContext(ContextCompanySetting);
  const user = JSON.parse(localStorage.getItem("status_user_login"));
  const statusUser = user.membership_status_id;

  /* 
        ````````````````````
        TO KNOW CURRENT PAGE
        
        ````````````````````
    */
  const [inisateLabel, setInisisateLabel] = useState("");

  const currentLocation = window.location.pathname;

  /*
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */
  const userToken = localStorage.getItem("userToken");
  const [userTokenState, setUserTokenState] = useState("");

  const [fotoQuery, setFotoQuery] = useState("");
  const [fotoQueryUser, setFotoQueryUser] = useState("");

  const [namaGroupPerusahaan, setNamaGroupPerusahaan] = useState("");
  const [roleName, setRoleName] = useState([]);

  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  const [memberFirstName, setMemberFirstName] = useState("");
  const [memberLastName, setMemberLastName] = useState("");

  const [structurePositionTitleName, setStructurePositionTitleName] =
    useState("");

  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);

  useEffect(() => {
    setUserTokenState(userToken);

    if (
      statusUserLoginAfterParse !== null &&
      statusUserLoginAfterParse !== undefined
    ) {
      setFotoQueryUser(statusUserLoginAfterParse.member_photo_url);

      if (statusUserLoginAfterParse.userRole.length > 0) {
        if (statusUserLoginAfterParse.userRole[0].name === "superadmin") {
          setRoleName(statusUserLoginAfterParse.userRole[0].name);
        }
        if (statusUserLoginAfterParse.userRole.length > 1) {
          if (statusUserLoginAfterParse.userRole[1].name === "admin") {
            setRoleName(statusUserLoginAfterParse.userRole[1].name);
          }
        }
      } else {
        console.log("Anda tidak memiliki ROLE sama sekali, hubungi Mas Tri !");
      }

      //*
      if (statusUserLoginAfterParse.hasOwnProperty("member_first_name")) {
        setMemberFirstName(statusUserLoginAfterParse.member_first_name);
      }

      if (statusUserLoginAfterParse.hasOwnProperty("member_last_name")) {
        setMemberLastName(statusUserLoginAfterParse.member_last_name);
      }

      if (
        statusUserLoginAfterParse.hasOwnProperty(
          "structure_position_title_name"
        )
      ) {
        setStructurePositionTitleName(
          statusUserLoginAfterParse.member_position
            .structure_position_title_name
        );
      }
    }

    if (userToken !== undefined) {
      axiosConfig
        .get(URL_API + `/group/profile/detail?token=${userToken}`)
        .then(function (response) {
          console.log("Response Original from HeadNewGeneral : ", response);

          setFotoQuery(response.data.data.group_photo_logo_icon);
          setNamaGroupPerusahaan(response.data.data.group_name);
        })
        .catch(function (error) {
          if (error.response !== undefined) {
            if (error.response.status == 401) {
              setErrorStatus(401);
              setOpenDialogError(true);
              setTextErrorInformation(error.response.statusText);
            }

            if (error.response.status == 403) {
              setErrorStatus(403);
              setOpenDialogError(true);
              setTextErrorInformation(error.response.data.info.message);
            }
          } else {
            setTextErrorInformation("Periksa koneksi jaringan internet Anda ");
            setOpenDialogError(true);
          }
          console.log("Error : ", error.response);
        });
    }
  }, []);

  useEffect(() => {
    //* TO KNOW CURRENT LOCATION PAGE
    if (currentLocation === ToGoal) {
      setInisisateLabel("7WD - Goal");
    } else if (currentLocation === ToAllMA) {
      setInisisateLabel("7WD - Measured Activity");
    } else if (currentLocation === ToGoalDetail) {
      setInisisateLabel("7WD - Goal");
    } else if (currentLocation === "/ma") {
      setInisisateLabel("7WD - Measured Activity");
    } else if (currentLocation === ToGoalDetailALL) {
      setInisisateLabel("7WD - Goal");
    } else if (currentLocation === "/7wd-period") {
      setInisisateLabel("7WD - Period");
    } else if (currentLocation === ToDashboard) {
      setInisisateLabel("Dashboard");
    } else if (currentLocation === ToNewSoTreeViewOrgChart) {
      setInisisateLabel("HR");
    } else if (currentLocation === ToEmptyStateGeneral) {
      setInisisateLabel("Karyawan");
    } else if (currentLocation === ToUserManagement) {
      setInisisateLabel("User");
    } else if (currentLocation === ToCompanySetting) {
      setInisisateLabel("Pengaturan");
    } else if (currentLocation.includes(ToDrive)) {
      setInisisateLabel("File Manager");
    } else if (currentLocation === "/task") {
      setInisisateLabel("To Do List");
    } else if (currentLocation === "/reports") {
      setInisisateLabel("Report");
    }
  }, [currentLocation]);

  /*

        ``````````````````
        HANDLE FIRE UPDATE

        ``````````````````
    */
  useEffect(() => {
    if (contextCompanySetting.fireUpdate === true) {
      if (userTokenState !== undefined) {
        axiosConfig
          .get(URL_API + `/group/profile/detail?token=${userToken}`)
          // .get(URL_API + `/group/profile/patch`)
          .then(function (response) {
            console.log("Response Original from HeadNewGeneral : ", response);

            setFotoQuery(response.data.data.group_photo_logo_icon);
            setNamaGroupPerusahaan(response.data.data.group_name);
          })
          .catch(function (error) {
            if (error.response !== undefined) {
              if (error.response.status == 401) {
                setErrorStatus(401);
                setOpenDialogError(true);
                setTextErrorInformation(error.response.statusText);
              }

              if (error.response.status == 403) {
                setErrorStatus(403);
                setOpenDialogError(true);
                setTextErrorInformation(error.response.data.info.message);
              }
            } else {
              setErrorStatus(400);
              setTextErrorInformation(
                "Periksa koneksi jaringan internet Anda "
              );
              setOpenDialogError(true);
            }
            console.log("Error : ", error.response);
          });
      }
    }
  }, [contextCompanySetting.fireUpdate]);

  /*
        ```````````````````````
        OPEN HANDLE DRAWER OPEN

        ```````````````````````
    */

  const [open, setOpen] = useState(false);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  /*
        `````````````````````
          LIST NESTED PROFILE
  
        `````````````````````
    */

  const [openNestedList, setOpenNestedList] = useState(false);

  function handleOpenNestedList() {
    if (roleName === "superadmin") {
      setOpenNestedList(!openNestedList);
    }
  }

  /*
        ```````````````
        LIST NESTED 7WD

        ```````````````
    */
  const [openNestedSevenWD, setOpenNestedSevenWD] = useState(false);

  function handleOpenSevenWD() {
    setOpenNestedSevenWD(!openNestedSevenWD);
  }

  /*
        ````````````````````````````````
            LIST NESTED HUMAN CAPITAL

        ````````````````````````````````
    */
  const [openNestedHumanCapital, setOpenNestedHumanCapital] = useState(false);

  function handleOpenHumanCapital() {
    setOpenNestedHumanCapital(!openNestedHumanCapital);
  }

  /*
        ``````````````````````
        LIST NESTED EXTRA TOOL

        ``````````````````````
    */
  const [openNestedExtra, setOpenNestedExtra] = useState(false);

  function handleOpenExtra() {
    setOpenNestedExtra(!openNestedExtra);
  }

  /*
        ``````````````
        HANDLE LOG OUT
  
        ``````````````
    */
  function handleLogOut() {
    localStorage.removeItem("userToken");
    localStorage.removeItem("intro_enabled");

    localStorage.removeItem("verifyToken");
    localStorage.removeItem("status_user_login");
    localStorage.removeItem("goal_detail");
    localStorage.removeItem("employee_id");
    localStorage.removeItem("response_employee_detail");

    localStorage.removeItem("value_search");
    localStorage.removeItem("status_goal_id");
    localStorage.removeItem("email");
    localStorage.removeItem("appVersion");
    localStorage.removeItem("location_tab");
    localStorage.removeItem("main_goal_name");
    localStorage.removeItem("data_identitas");

    localStorage.removeItem("breadcrumb");
    localStorage.removeItem("directory_id");
    localStorage.removeItem("status_allma_id");
    localStorage.removeItem("setting_tab");
    localStorage.removeItem("org_tab");

    // localStorage.clear();
    navigate(ToLogin);
  }

  /*
        ````````````````````````````````
          ICON POJOK KANAN ATAS - MENU
  
        ````````````````````````````````
    */

  const [anchorElMenuPojokKananAtas, setAnchorElPoKaAtas] = useState(null);
  const isOpenMenuPojokKananAtas = Boolean(anchorElMenuPojokKananAtas);

  /* ````````````START DRAWER`````````````````````*/

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  /*
       `````````````````````````
       DROPDOWN POJOK KANAN ATAS

       ``````````````````````````
   */
  const [anchorEl, setAnchorEl] = useState(null);

  function handleOpenDropdownPojokKananAtas(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseDropdownPojokKananAtas() {
    setAnchorEl(null);
  }

  /*
        ``````````````````
        HANDLE DIALOG HELP

        ``````````````````
    */
  const [isOpenDialogHelp, setOpenDialogHelp] = useState(false);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        style={{
          borderBottomWidth: 1,
          borderBottomColor: "#ccc",
          borderBottomStyle: "solid",
        }}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
            style={{ color: "grey" }}>
            <img
              src={IconMenuOffCanvassGrey}
              alt="Icon Menu Grey"
              style={{ width: 28, height: 28 }}
            />
          </IconButton>

          <Typography
            variant="h6"
            className={classes.textHeaderGoal}
            style={{ width: "100%" }}>
            <b>{inisateLabel}</b>
          </Typography>

          <IconButton
            // onClick={() => handleLogOut()}
            aria-haspopup="true"
            color="inherit">
            {fotoQueryUser !== "" &&
            fotoQueryUser !== null &&
            fotoQueryUser !== undefined ? (
              // <img src={URL_API + '/' +fotoQueryUser+ "&token=" + userToken}  className={classes.bigRealFoto} />
              <ImageSmallUser item={fotoQueryUser} index={1} />
            ) : (
              <AccountCircle className={classes.accountCircle} />
            )}
          </IconButton>

          <IconButton
            aria-label="More"
            aria-owns={isOpenMenuPojokKananAtas ? "long-menu" : undefined}
            aria-haspopup="true"
            onClick={handleOpenDropdownPojokKananAtas}>
            <ExpandMore />
          </IconButton>

          <StyledMenu
            style={{ marginRight: 16, marginLeft: -16 }}
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseDropdownPojokKananAtas}>
            <StyledMenuItem disabled>
              <ListItemIcon>
                {fotoQueryUser !== "" &&
                fotoQueryUser !== null &&
                fotoQueryUser !== undefined ? (
                  <ImageSmallUser item={fotoQueryUser} index={1} />
                ) : (
                  <AccountCircle
                    className={classes.accountCircle}
                    fontSize="large"
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.title}>
                    <b>
                      {memberFirstName + " "}
                      {memberLastName}
                    </b>
                  </Typography>
                }
                secondary={
                  <Typography variant="subtitle2" className={classes.title}>
                    {structurePositionTitleName}
                  </Typography>
                }
              />
            </StyledMenuItem>
            <Divider />

            {(roleName === "superadmin" || roleName === "admin") && (
              <div>
                <StyledMenuItem
                  // onClick={() => Redirect(ToUserManagement)}
                  onClick={() => navigate(ToCompanySetting)}>
                  <ListItemText
                    style={{ paddingRight: 24 }}
                    primary={
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: "grey" }}>
                        <b>Pengaturan Akun</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>
                <Divider />
              </div>
            )}

            <br />
            <StyledMenuItem onClick={() => setOpenDialogHelp(true)}>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    <b>Help</b>
                  </Typography>
                }
              />
            </StyledMenuItem>

            <StyledMenuItem
              //    onClick={(e) => handleCloseMenuPojokKananAtas(e, {item: "Log-out"})}
              onClick={() => handleLogOut()}>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    <b>Sign out</b>
                  </Typography>
                }
              />
            </StyledMenuItem>
          </StyledMenu>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        onMouseLeave={() => setOpen(false)}
        onMouseOver={() => setOpen(true)}
        open={open}>
        <div
          style={{
            background: "#282560",
            // "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
            height: "100vh",
            width: 303,
          }}>
          <IconButton
            onClick={handleDrawerClose}
            style={{ padding: 14, marginLeft: 245 }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <img
                src={IconMenuOffCanvass}
                className={classes.iconMenuOffCanvass}
              />
            )}
          </IconButton>

          <List component="nav" className={classes.drawerRoot}>
            <ListItem
              button
              onClick={handleOpenNestedList}
              style={{ paddingLeft: 0, marginBottom: 25 }}>
              <ListItemIcon>
                <IconButton aria-haspopup="true" color="inherit">
                  {fotoQuery !== "" ? (
                    <img
                      src={
                        URL_API +
                        "/" +
                        fotoQuery +
                        "&token=" +
                        userToken +
                        "&mode=thumbnail"
                      }
                      className={classes.bigRealFotoDrawer}
                    />
                  ) : (
                    <LocationCityIcon
                      className={classes.accountCircle}
                      fontSize="large"
                    />
                  )}
                </IconButton>
              </ListItemIcon>

              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.namaPerusahaan}
                    style={{ marginLeft: 8 }}>
                    {namaGroupPerusahaan !== "" ? namaGroupPerusahaan : "-"}
                  </Typography>
                }
              />
            </ListItem>

            <ListItem
              style={{
                backgroundColor: currentLocation === ToDashboard && "#52507f",
                borderLeft:
                  currentLocation === ToDashboard && "4px solid #a9a7bf",
              }}
              button
              onClick={() => navigate(ToDashboard)}
              selected={currentLocation === ToDashboard}
              disabled={statusUser === "22"}>
              <ListItemIcon>
                <img src={ImageDashboardHome} style={{ marginLeft: 4 }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{ color: "white", fontFamily: "Roboto" }}>
                    Dashboard
                  </Typography>
                }
              />
            </ListItem>

            <Box my={0.5}>
              <Divider className={classes.divider} />
            </Box>
            {open && (
              <Box m={2}>
                <Typography
                  type="PengaturanRole"
                  style={{ color: "white", fontFamily: "Roboto" }}>
                  7WD
                </Typography>
              </Box>
            )}

            <ListItem
              onClick={() => navigate(ToGoal)}
              style={{
                backgroundColor: currentLocation === ToGoal && "#52507f",
                borderLeft: currentLocation === ToGoal && "4px solid #a9a7bf",
              }}
              selected={currentLocation === ToGoal}
              button
              className={classes.nested}>
              <ListItemIcon>
                <TrackChangesIcon style={{ color: "#d4d3df" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: "white",
                      fontFamily: "Roboto",
                    }}>
                    Goal
                  </Typography>
                }
              />
            </ListItem>

            <ListItem
              onClick={() => navigate(ToAllMA)}
              style={{
                backgroundColor: currentLocation === ToAllMA && "#52507f",
                borderLeft: currentLocation === ToAllMA && "4px solid #a9a7bf",
              }}
              selected={currentLocation === ToAllMA}
              button
              className={classes.nested}>
              <ListItemIcon>
                <SpeedIcon style={{ color: "#d4d3df" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: "white",
                      fontFamily: "Roboto",
                    }}>
                    Measured Activities
                  </Typography>
                }
              />
            </ListItem>

            <ListItem
              onClick={() => navigate(To7wdPeriode)}
              style={{
                backgroundColor: currentLocation === To7wdPeriode && "#52507f",
                borderLeft:
                  currentLocation === To7wdPeriode && "4px solid #a9a7bf",
              }}
              selected={currentLocation === To7wdPeriode}
              button
              className={classes.nested}>
              <ListItemIcon>
                <DateRangeIcon style={{ color: "#d4d3df" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: "white",
                      fontFamily: "Roboto",
                    }}>
                    Periode
                  </Typography>
                }
              />
            </ListItem>

            {/* 
                                    `````````````````````
                                    HUMAN RESOURCE NESTED

                                    `````````````````````
                                */}
            <Box my={0.5}>
              <Divider className={classes.divider} />
            </Box>

            {open && (
              <Box m={2}>
                <Typography
                  type="PengaturanRole"
                  style={{ color: "white", fontFamily: "Roboto" }}>
                  Human Capital
                </Typography>
              </Box>
            )}

            {roleName === "superadmin" && (
              <ListItem
                onClick={() => navigate(ToNewSO)}
                selected={currentLocation === ToNewSO}
                style={{
                  backgroundColor: currentLocation === ToNewSO && "#52507f",
                  borderLeft:
                    currentLocation === ToNewSO && "4px solid #a9a7bf",
                }}
                button
                className={classes.nested}>
                <ListItemIcon>
                  <AccountTreeIcon style={{ color: "#d4d3df" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: "white",
                        fontFamily: "Roboto",
                      }}>
                      SO
                    </Typography>
                  }
                />
              </ListItem>
            )}

            <ListItem
              onClick={() => navigate(ToEmptyStateGeneral)}
              selected={currentLocation === ToEmptyStateGeneral}
              style={{
                backgroundColor:
                  currentLocation === ToEmptyStateGeneral && "#52507f",
                borderLeft:
                  currentLocation === ToEmptyStateGeneral &&
                  "4px solid #a9a7bf",
              }}
              button
              className={classes.nested}>
              <ListItemIcon>
                <PeopleIcon style={{ color: "#d4d3df" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: "white",
                      fontFamily: "Roboto",
                    }}>
                    Karyawan
                  </Typography>
                }
              />
            </ListItem>

            {/* 
                ``````````
                EXTRA TOOL

                ``````````
            */}
            <Box my={0.5}>
              <Divider className={classes.divider} />
            </Box>

            {open && (
              <Box m={2}>
                <Typography
                  type="PengaturanRole"
                  style={{ color: "white", fontFamily: "Roboto" }}>
                  Extra
                </Typography>
              </Box>
            )}

            {/* 
                ``````````
               PF DRIVE

                ``````````
            */}

            <ListItem
              button
              disabled={statusUser === "22"}
              selected={currentLocation === ToDrive}
              style={{
                backgroundColor: currentLocation === ToDrive && "#52507f",
                borderLeft: currentLocation === ToDrive && "4px solid #a9a7bf",
              }}
              onClick={() => {
                navigate(ToDrive);
                localStorage.removeItem("breadcrumb");
                localStorage.removeItem("directory_id");
              }}>
              <ListItemIcon>
                <FolderOpenIcon style={{ color: "#d4d3df" }} />
                {/* <FolderOpenIcon style={{ color: "#df8a8d" }} /> */}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{ color: "white", fontFamily: "Roboto" }}>
                    Drive
                  </Typography>
                }
              />
            </ListItem>

            {/* 
                ``````````
               PF RPORTUNG

                ``````````
            */}

            <ListItem
              button
              disabled={statusUser === "22"}
              selected={currentLocation === ToReports}
              style={{
                backgroundColor: currentLocation === ToReports && "#52507f",
                borderLeft:
                  currentLocation === ToReports && "4px solid #a9a7bf",
              }}
              onClick={() => navigate(ToReports)}>
              <ListItemIcon>
                <PollRoundedIcon style={{ color: "#d4d3df" }} />
                {/* <PollRoundedIcon style={{ color: "#df8a8d" }} /> */}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{ color: "white", fontFamily: "Roboto" }}>
                    Reports
                  </Typography>
                }
              />
            </ListItem>

            {/* 
                ``````````
               PF REGULER MEETING

                ``````````
            */}

            <ListItem
              button
              disabled={statusUser === "22"}
              selected={currentLocation === ToMeeting}
              style={{
                backgroundColor: currentLocation === ToMeeting && "#52507f",
                borderLeft:
                  currentLocation === ToMeeting && "4px solid #a9a7bf",
              }}
              onClick={() => navigate(ToMeeting)}>
              <ListItemIcon>
                <MeetingRoomIcon style={{ color: "#d4d3df" }} />
                {/* <PollRoundedIcon style={{ color: "#df8a8d" }} /> */}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{ color: "white", fontFamily: "Roboto" }}>
                    Regular Meeting
                  </Typography>
                }
              />
            </ListItem>

            <ListItem
              onClick={() => navigate(ToTask)}
              selected={currentLocation === ToTask}
              style={{
                backgroundColor: currentLocation === ToTask && "#52507f",
                borderLeft: currentLocation === ToTask && "4px solid #a9a7bf",
              }}
              button
              className={classes.nested}>
              <ListItemIcon>
                <CheckBoxIcon style={{ color: "#d4d3df" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: "white",
                      fontFamily: "Roboto",
                    }}>
                    To Do List
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </div>
      </Drawer>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />

      <DialogHelp
        isOpenDialogHelp={isOpenDialogHelp}
        setOpenDialogHelp={setOpenDialogHelp}
      />
    </MuiThemeProvider>
  );
};

export default HeaderGOAL;

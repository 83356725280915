import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import { navigate } from "hookrouter";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  imageLogo: {
    verticalAlign: "text-bottom",
    width: 35,
    height: 35,
  },
  alternateBackground: {
    backgroundColor: "#f5f5f5",
  },
});

const useStyles = makeStyles(styles);

const Contact = (props) => {
  //const { classes } = props;
  const classes = useStyles();

  return (
    <div>
      <section style={{ paddingTop: "0px", textAlign: "center" }}>
        <br />
        <br />
        <br />
        <div className="box-contact">
          <div style={{ textAlign: "left" }}>
            <h2>Kontak kami</h2>
            <p>
              Hubungi kami untuk belajar lebih lanjut tentang Performate.id.
              Anda bisa mendiskusikan tentang studi kasus project anda,
              tantangan yang anda hadapi dan apa yang ingin anda tingkatkan dari
              performa organisasi anda.
            </p>
          </div>
        </div>

        <div className="box-contact-address">
          <div>
            <p>Sales & Technical Support</p>
            <p>
              Email : cs@performate.id
              <br />
              <br />
              Nomor Telepon/WhatsApp : 0812-1203-0314
            </p>
          </div>

          <div></div>

          <div>
            <p>Alamat Kantor</p>
            <p>
              Jl. Pondok Kelapa Raya Blok C4 1B Kel. Pondok Kelapa, Kec. Duren
              Sawit Jakarta Timur
              <br />
              <br />
              Kode Pos : 13460, DKI Jakarta
            </p>
          </div>
        </div>
        <br />
        <br />
      </section>

      <section>
        <div
          className="box-tour"
          style={{ margin: "0 auto", textAlign: "center", width: "70%" }}>
          <h3>Ikuti Tur</h3>
          <p>
            Ingin mempelajari lebih banyak? Atur jadwal demo bersama kami untuk
            memandu dan menjawab pertanyaan anda dengan menggunakan platform
            secara langsung
          </p>
          <Button
            onClick={() => navigate("/home/demo-schedule")}
            variant="outlined"
            color="primary"
            disableElevation
            className={classes.textLowerCase}>
            Atur demo
          </Button>
        </div>
        <br />
        <br />
      </section>
    </div>
  );
};

export default Contact;

import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Menu,
  Typography,
  Divider,
  IconButton,
  Badge,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContextReports from "../../../../../context/ContextReports";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

// import moment from "moment";
import AxiosConfig from "../../../../../../../constants/config-axios";
import { URL_API } from "../../../../../../../constants/config-api";

import MenuSelect from "./MenuSelect";
import Mode from "./Mode";
import {
  GET_SYNC,
  HIT_DATA_PRESET,
  HIT_ORDER_BY,
  HIT_TOTAL_RECORDS,
  SET_PAGINATION,
} from "../../../../../context/types";
import { order_by } from "../../../../global/dataFilterReport";

import handleError from "../../../../global/handleError";
import extractTable from "../../../../global/extractTable";
import DialogError from "../../../../../../../components/DialogError";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#d1354a",
    color: "#fff",
  },
}))(Badge);

const OrderBy = ({ classes }) => {
  const { filterState, tableState, reportDispatch, tableDispatch } =
    useContext(ContextReports);

  // ========= STATE =========
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );
  const [expandMode, setExpandMode] = useState(false);
  const [anchorOrderBy, setAnchorOrderBy] = useState(null);
  const [listFirstOrder, setListFirstOrder] = useState(order_by);
  const [listSecondOrder, setListSecondOrder] = useState([]);
  const [listThirdOrder, setListThirdOrder] = useState([]);
  const [firstOrder, setFirstOrder] = useState({
    field: "",
    name: "",
    method: "",
  });
  const [secondOrder, setSecondOrder] = useState({
    field: "",
    name: "",
    method: "",
  });
  const [thirdOrder, setThirdOrder] = useState({
    field: "",
    name: "",
    method: "",
  });
  const [mode, setMode] = useState({ name: "Ascending", method: "ASC" });

  let allOrderSelect = [
    { ...firstOrder },
    { ...secondOrder },
    { ...thirdOrder },
  ].filter((item) => item.name !== "");

  // ========= SIDE EFFECTS =========
  useEffect(() => {
    if (anchorOrderBy !== null) {
      setListFirstOrder(order_by);
    }
  }, [anchorOrderBy]);
  useEffect(() => {
    const secondOrderList =
      order_by.length > 0 &&
      order_by.filter((item) => {
        return item.name !== firstOrder.name;
      });
    const thirdOrderList =
      listSecondOrder.length > 0 &&
      listSecondOrder.filter((item) => {
        return item.name !== secondOrder.name;
      });

    setListSecondOrder(secondOrderList);
    setListThirdOrder(thirdOrderList);
  }, [firstOrder, secondOrder, thirdOrder, anchorOrderBy]);

  // ========= FUNC & METHODS =========
  const handleShowAnchorOrderBy = (event) => {
    setAnchorOrderBy(event.currentTarget);
  };

  const handleCloseAnchorOrderBy = () => {
    setAnchorOrderBy(null);
    setFirstOrder({ field: "", name: "", method: "" });
    setSecondOrder({ field: "", name: "", method: "" });
    setThirdOrder({ field: "", name: "", method: "" });
    setListFirstOrder([]);
    setListSecondOrder([]);
    setListThirdOrder([]);
  };

  const handleShowResults = () => {
    setAnchorOrderBy(null);
    const allOrderBy = [
      { ...firstOrder },
      { ...secondOrder },
      { ...thirdOrder },
    ]
      .filter((item) => item.name !== "")
      .map((item) => {
        return { field: item.field, method: mode.method };
      });

    tableDispatch({
      type: HIT_ORDER_BY,
      payload: { allOrderBy },
    });

    tableDispatch({
      type: HIT_DATA_PRESET,
      payload: { dataPresets: [], load: true },
    });

    const dataPost = {
      name: filterState.dateRange.title,
      platform: "web",
      field_preset_id: tableState.fieldPresets.id,
      report_format: "table",
      filter_by: {
        entity: [
          { name: "goal", query: "asdfasdfds" },
          { name: "ma", query: "asdfasdfds" },
        ],
        date_range: filterState.dateRange.code,
        user: tableState.usersSelected,
        unit: tableState.unitsSelected,
        status: tableState.status,
        achievement: {
          value_type: tableState.valueType,
          cal_method: tableState.calMethod,
        },
      },
      entities: tableState.entity,
      group_by: {
        entity: ["goal"],
        date_time: [],
        user: false,
        unit: ["division", "branch"],
        orientation: [],
      },
      group_by_calculation: "1",
      order_by: allOrderBy,
      primary_column: "name",
      limit_type: "desc",
      limit_count_number: 0,
      week_start: "monday",
      date_format: "d m Y",
      summary_only: false,
    };

    const postWithCompare = {
      ...dataPost,
      comparisons: {
        date_range: tableState.comparison.code,
        fields: [
          "result_value",
          "overall_result_achievement",
          "expected_result_value",
        ],
      },
    };

    const { currentPage, perPage } = tableState.pagination;

    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
      tableState.comparison.code === "" ? dataPost : postWithCompare
    )
      .then((res) => {
        const result = res.data.data;
        const lastSync = res.data.info.data.last_sync;

        const DATATABLE = extractTable(result.results.data);
        const pagination = {
          currentPage: result.results.current_page,
          perPage: result.results.per_page,
          lastPage: result.results.last_page,
        };

        // console.log("Ori Result PRST", result);

        if (res.status === 200) {
          reportDispatch({
            type: GET_SYNC,
            payload: { lastSync },
          });

          tableDispatch({
            type: HIT_DATA_PRESET,
            payload: { dataPresets: DATATABLE, load: false },
          });

          tableDispatch({
            type: SET_PAGINATION,
            payload: { pagination },
          });

          tableDispatch({
            type: HIT_TOTAL_RECORDS,
            payload: { totalRecords: result.results.total },
          });
        }
      })
      .catch((error) => {
        console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        tableDispatch({
          type: HIT_DATA_PRESET,
          payload: { dataPresets: [], load: false },
        });
      });
  };

  const handleExpandMode = () => {
    setExpandMode((more) => !more);
  };

  const handleMode = (data) => {
    // console.log("MODE", data);
    setMode(data);
  };

  return (
    <>
      <StyledBadge
        badgeContent={allOrderSelect.length}
        children={
          <Button
            variant="outlined"
            size="small"
            className={classes.groupBtn}
            endIcon={<ExpandMoreIcon />}
            onClick={handleShowAnchorOrderBy}>
            Order By
          </Button>
        }
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorOrderBy}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorOrderBy)}>
        <Box
          minWidth={350}
          marginBottom={1.5}
          marginTop={1.5}
          marginLeft={2}
          marginRight={2}>
          <Typography variant="subtitle2" className={classes.title333}>
            <b>Order Table</b>
          </Typography>
        </Box>
        <Box
          marginBottom={1.5}
          marginTop={1.5}
          marginLeft={2}
          marginRight={2}
          display="flex"
          alignItems="center">
          <Typography variant="caption" className={classes.title333}>
            Order by
          </Typography>
          <MenuSelect
            classes={classes}
            isDisable={false}
            OrderByList={listFirstOrder}
            // OrderByList={anchorOrderBy === null ? [] : order_by}
            onSelected={setFirstOrder}
          />
          <MenuSelect
            classes={classes}
            isDisable={firstOrder.name !== "" ? false : true}
            // isDisable={listSecondOrder.length > 0 ? true : false}
            OrderByList={listSecondOrder}
            onSelected={setSecondOrder}
          />
          <MenuSelect
            classes={classes}
            isDisable={secondOrder.name !== "" ? false : true}
            // isDisable={listThirdOrder.length > 0 ? true : false}
            OrderByList={listThirdOrder}
            onSelected={setThirdOrder}
          />
        </Box>
        <Box margin={2}>
          <Divider />
        </Box>
        <Box
          margin={2}
          mt={0}
          display="flex"
          alignItems="center"
          justifyContent="space-between">
          <Typography variant="subtitle2" className={classes.title333}>
            <b>Mode</b>
          </Typography>

          <IconButton size="small" onClick={handleExpandMode}>
            {!expandMode ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>
        </Box>
        {expandMode && (
          <Box display="flex">
            {listMode.map((item, i) => (
              <Mode
                key={i}
                classes={classes}
                item={item}
                mode={mode}
                onMode={handleMode}
              />
            ))}
          </Box>
        )}
        <Box margin={2}>
          <Divider />
        </Box>
        <Box
          marginRight={2}
          marginBottom={1}
          display="flex"
          justifyContent="flex-end">
          <Button
            size="small"
            variant="outlined"
            className={classes.button0}
            onClick={handleCloseAnchorOrderBy}>
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            className={classes.button1}
            style={{ marginLeft: 7.5 }}
            onClick={handleShowResults}>
            Show results
          </Button>
        </Box>

        <DialogError
          classes={classes}
          isOpenDialogError={isOpenDialogError}
          setOpenDialogError={setOpenDialogError}
          textErrorInformation={textErrorMessage}
          errorStatus={errorStatus}
          listError={listError}
        />
      </Menu>
    </>
  );
};

export default OrderBy;

const listMode = [
  {
    id: 1,
    name: "Ascending",
    method: "ASC",
  },
  {
    id: 2,
    name: "Descending",
    method: "DESC",
  },
];

import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";

import ContextGoal from "../Context/ContextGoal";

import DialogError from "../../../../components/DialogError";
import Capitalize from "../../../../utilities/Capitalize";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import Snackbeer from "../../../../components/Snackbeer";

const DialogSetAsComplete = (props) => {
  const {
    classes,
    isOpenDialogSetAsComplete,
    setOpenDialogSetAsComplete,
    goalDetailState,
  } = props;

  const context = useContext(ContextGoal);

  const [userTokenState, setUserTokenState] = useState("");

  useEffect(() => {
    if (isOpenDialogSetAsComplete === true) {
      const userToken = localStorage.getItem("userToken");
      setUserTokenState(userToken);
    }
  }, [isOpenDialogSetAsComplete]);

  const [loader, setLoader] = useState(false);

  const handleDelete = () => {
    setLoader(true);

    if (userTokenState !== "") {
      axiosConfig
        .put(`${URL_API}/swd/goal/${goalDetailState.id}:completed`)
        .then((response) => {
          // console.log("Response Original : ", response);

          if (response.status === 200) {
            context.setOpenSnackbeer(true);
            context.setSuccessComplete(Math.floor(Math.random() * 1000 + 1));
            context.setMessages("Berhasil ubah status Goal menjadi complete");
            setOpenDialogSetAsComplete(false);
          }
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          console.log("Error : ", error.response);

          if (error.response !== undefined) {
            if (error.response.status === 403) {
              setErrorStatus(403);
              if (error.response.data.info !== null) {
                if (error.response.data.info.message !== null) {
                  setOpenDialogError(true);
                  setTextErrorInformationValue(
                    "Error 409 :" + Capitalize(error.response.data.info.message)
                  );
                }
              }
            }

            if (error.response.status === 422) {
              setErrorStatus(422);
              if (error.response.data.info !== null) {
                if (error.response.data.info.errors !== null) {
                  setTextErrorInformationValue(".");
                  setListError(error.response.data.info.errors);
                  setOpenDialogError(true);
                }
              }
            }

            if (error.response.status === 500) {
              if (error.response.data.info !== null) {
                if (error.response.data.info.message !== null) {
                  setErrorStatus(error.response.status);
                  setOpenDialogError(true);
                  setTextErrorInformationValue(
                    Capitalize(error.response.data.info.message)
                  );
                }
              }
            }
          } else {
            console.log("Error : ", error.response);
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  /*
        ```````````````
        HANDLE SNACKBAR

        ```````````````
    */
  //   const [isModalResponse200, setModalResponse200] = useState(false);
  //   const [message, setMessage] = useState("");

  /*
       ```````````````````
       HANDLE DIALOG ERROR

       ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] = useState(
    ""
  );

  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  return (
    <Fragment>
      <Dialog
        open={isOpenDialogSetAsComplete}
        onClose={() => setOpenDialogSetAsComplete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          style={{ textAlign: "left" }}></DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "black" }}>
              <b>
                Apakah Anda yakin ingin mengubah status Goal{" "}
                <i>"{goalDetailState.name}"</i> menjadi <i>Completed Goal</i> ?
              </b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            onClick={handleDelete}
            variant="contained"
            className={classes.buttonModalDelete}
            // fullWidth
          >
            {loader !== true ? (
              "Yakin"
            ) : (
              <CircularProgress size={20} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformationValue}
        errorStatus={errorStatus}
        listError={listError}
      />

      {/* <Snackbeer
        isModalResponse200={context.isOpenSnackbeer}
        setModalResponse200={context.setOpenSnackbeer}
        messages={message}
      /> */}
    </Fragment>
  );
};

export default DialogSetAsComplete;

import React, { useContext, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TableRow,
  TableCell,
  ListItemIcon,
  ListItemSecondaryAction,
  Slide,
  Badge,
  Checkbox,
  Grid,
} from "@material-ui/core";
import moment from "moment";
import numeral from "numeral";
import { A } from "hookrouter";
import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/Error";
import RoomIcon from "@material-ui/icons/Room";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PersonIcon from "@material-ui/icons/Person";
import ReorderRoundedIcon from "@material-ui/icons/ReorderRounded";

// Externam Component Utilities
import CircleInPercentForExsponent from "../../../../components/CircleInPercentForExsponent";
import CircleInPercentNEW from "../../../../components/CircleInPercentNEW";
import TruncateTextShort from "../../../../utilities/TruncateTextShort";
import TruncateTextShortNameGoal from "../../../../utilities/TruncateTextShortNameGoal";
import LightTooltip from "../../../../components/LightTooltip";
import TruncateTextShortSuperPendek from "../../../../utilities/TruncateTextShortSuperPendek";
import ConvertExponentialToSubstring from "../../../../utilities/ConvertExponentialToSubstring";
import PictEmptyStateGoalResultWithoutIconButton from "../ComponentsMAResult/PictEmptyStateGoalResultWithoutIconButton";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";
import ImageSmallUser from "../../../../components/ImageSmallUser";
import DialogSetAsComplete from "./DialogSetAsComplete";

import ContextAllMA from "../context/AllMaContext";

import DialogDeleteMA from "./DialogDeleteMA";
import DialogDuplicate from "./DialogDuplicate";

import PictIconGoal from "../../../../assets/images/Group_3017.png";
import DialogConfirmWeight from "../../MeasureActivities/Components/DialogConfirmWeight";

function ListCardItemMA({
  classes,
  item,
  handleDetail,
  userTokenState,
  setSelected,
  selected,
  isSelected,
  key,
}) {
  const context = useContext(ContextAllMA);
  /* ===============================================
  STATE COMPONENT
 ================================================= */

  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalDeleteMA, setModalDeleteMA] = useState(false);
  const [isModalDuplicate, setModalDuplicate] = useState(false);
  const [isOpenDialogSetAsComplete, setOpenDialogSetAsComplete] =
    useState(false);

  // CONFIRM WEIGHT
  const [openDialogConfirmWeight, setOpenDialogConfirmWeight] = useState(false);

  // Function Show Dropdown List
  function handleDropdownOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  // Function Close Dropdown List
  function handleDropdownClose() {
    setAnchorEl(null);
  }

  //   Handle Dialog Set As Complete
  const handleDialogSetAsComplete = () => {
    handleDropdownClose();
    setOpenDialogSetAsComplete(true);
  };

  // Function For Handling Dialog DUPLICATE is Show or Not
  function handleDialogDuplicate() {
    handleDropdownClose();
    setModalDuplicate(true);
  }

  // Function For Handling Dialog Delete Goal
  function handleDialogDelete() {
    handleDropdownClose();
    // setModalDeleteMA(true);
    setOpenDialogConfirmWeight(true);
  }

  const handleChangeCheckbox = (item) => {
    const selectedIndex = selected.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    // console.log("selected ALL MA", newSelected);

    setSelected(newSelected);
  };

  /* ===============================================================================================
    SETUP ALOWWED BUTTON DEPEND ON ROLE
    =============================================================================================== */

  let viewDetailAllowed = false;
  let deleteAllowed = false;
  let setCompleteAllowed = false;

  item.policy &&
    item.policy.map((isAllowed) => {
      if (isAllowed.actionName === "viewDetail") {
        viewDetailAllowed = isAllowed.result;
      } else if (isAllowed.actionName === "delete") {
        deleteAllowed = isAllowed.result;
      } else if (isAllowed.actionName === "completed") {
        setCompleteAllowed = isAllowed.result;
      }
    });

  // isTemSelected & Label Id
  const isItemSelected = isSelected(item.id);
  const labelId = `enhanced-table-checkbox-${key}`;

  return (
    <Slide
      direction="up"
      in={context.loaderForPaging === true ? false : true}
      mountOnEnter
      unmountOnExit>
      <TableRow
        key={item.id}
        selected={isItemSelected}
        aria-checked={isItemSelected}
        tabIndex={-1}
        role="checkbox">
        <List dense className={classes.listCardItem}>
          <Paper
            elevation={1}
            className={
              item.calculatedValue.resultOriented.overall_result_achievement *
                100 <
              85
                ? classes.paperListKebakaran
                : classes.paperList
            }>
            <Grid
              container
              style={{ marginBottom: -15, marginLeft: 75, marginTop: 5 }}
              direction="row">
              <img
                src={PictIconGoal}
                style={{
                  marginRight: 12.5,
                  backgroundColor: "#555",
                  borderRadius: 5,
                  padding: 3,
                  width: 20,
                  height: 20,
                }}
              />
              <Typography variant="subtitle2" className={classes.title}>
                <b>{item.goal.name}</b>
              </Typography>
            </Grid>
            <ListItem>
              {item.status_id === "1" ? null : item.status_id === "2" ? null : (
                <TableCell
                  component="th"
                  scope="row"
                  size="small"
                  padding="none"
                  onClick={() => handleChangeCheckbox(item)}
                  style={{ paddingLeft: 0 }}>
                  <Checkbox
                    disabled={!setCompleteAllowed}
                    checked={isItemSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </TableCell>
              )}
              <TableCell
                component="th"
                scope="row"
                size="small"
                padding="none"
                classes={{
                  root: classes.noBorderBottom,
                }}>
                <Box width={"16%"} marginLeft={2}>
                  <div className={classes.circularBeside}>
                    {item.calculatedValue.resultOriented.overall_result_achievement
                      .toString()
                      .indexOf("e") !== -1 ? (
                      <CircleInPercentForExsponent
                        classes={classes}
                        item={item}
                      />
                    ) : (
                      <CircleInPercentNEW classes={classes} item={item} />
                    )}
                  </div>
                </Box>
              </TableCell>

              {/* Progres Circel Percentage Section */}
              <TableCell
                classes={{
                  root: classes.noBorderBottom,
                }}
                component="th"
                scope="row"
                size="small"
                padding="none">
                <Box marginLeft={-4} width={360}>
                  <List>
                    <ListItem style={{ paddingLeft: 0, marginRight: 16 }}>
                      <ListItemIcon>
                        <IconButton
                          size="small"
                          style={{
                            backgroundColor: "transparent",
                            padding: "1px",
                          }}>
                          <i
                            className="material-icons"
                            style={{ color: "transparent" }}>
                            person
                          </i>
                        </IconButton>
                      </ListItemIcon>
                      <ListItemText
                        className={classes.listItemtext}
                        primary={
                          <LightTooltip
                            title={item.name}
                            placement="right-start">
                            <Typography
                              variant="subtitle2"
                              className={classes.title}>
                              {/* <b>{TruncateTextShortNameGoal(item.name)}</b> */}
                              <b>{item.name}</b>
                              {item.targetValueSyncStatus.name !== "sync" ||
                              item.startValueSyncStatus.name !== "sync" ? (
                                <LightTooltip
                                  title="Value MA ada masalah"
                                  placement="bottom-start">
                                  <ErrorIcon
                                    fontSize="small"
                                    style={{
                                      color: "red",
                                      marginLeft: 2,
                                      marginTop: 2,
                                    }}
                                  />
                                </LightTooltip>
                              ) : null}
                            </Typography>
                          </LightTooltip>
                        }
                        secondary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}>
                            {
                              // *PERIOD
                              item.period !== null && item.period.name
                            }

                            {
                              // *DATE RANGE
                              item.start_date !== null &&
                                item.end_date !== null &&
                                item.period == null && (
                                  <LightTooltip
                                    title={
                                      moment(item.start_date).format(
                                        "DD MMMM YYYY"
                                      ) +
                                      " - " +
                                      moment(item.end_date).format(
                                        "DD MMMM YYYY"
                                      )
                                    }
                                    placement="right-start">
                                    <span>
                                      {moment(item.start_date).format(
                                        "DD MMMM YYYY"
                                      ) +
                                        " - " +
                                        moment(item.end_date).format(
                                          "DD MMMM YYYY"
                                        )}
                                    </span>
                                  </LightTooltip>
                                )
                            }

                            {
                              // *DUE DATE
                              item.start_date == null &&
                                item.end_date !== null &&
                                item.period == null &&
                                moment(item.end_date).format("DD MMMM YYYY")
                            }
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Box>
              </TableCell>

              {/* User Name and Position Section */}
              <TableCell
                classes={{
                  root: classes.noBorderBottom,
                }}
                component="th"
                scope="row"
                size="small"
                padding="none">
                <Box width={200}>
                  <List>
                    <ListItem
                      style={{
                        marginRight: 24,
                        paddingLeft: 0,
                        // padding: 0
                      }}>
                      <ListItemIcon
                        style={{
                          marginBottom: -8,
                          marginLeft: 8,
                        }}>
                        <IconButton
                          style={{
                            backgroundColor: "#dbdbdb",
                            padding: "1px",
                          }}>
                          {item.member !== null &&
                          item.member.photo_url !== null &&
                          item.member.self.rel.photo_url !== null ? (
                            <ImageSmallUser
                              item={item.member.self.rel.photo_url}
                              index={1}
                            />
                          ) : (
                            <PersonIcon color="#c4c5c4" />
                          )}
                        </IconButton>
                      </ListItemIcon>
                      {/* } */}

                      <ListItemText
                        className={classes.listItemtext}
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}>
                            <b>
                              {item.member !== null
                                ? item.member.first_name + " "
                                : item.owner.member_first_name}
                              {item.member !== null
                                ? item.member.last_name
                                : item.owner.member_first_name}
                            </b>
                          </Typography>
                        }
                        secondary={
                          <LightTooltip
                            title={
                              item.owner
                                ? item.owner.structure_position_title_name
                                : "-"
                            }
                            placement="left-start">
                            <Typography
                              variant="subtitle2"
                              className={classes.title}>
                              {item.owner
                                ? TruncateTextShortSuperPendek(
                                    item.owner.structure_position_title_name
                                  )
                                : "-"}
                            </Typography>
                          </LightTooltip>
                        }
                      />
                    </ListItem>
                  </List>
                </Box>
              </TableCell>

              {/* Expected Value */}
              <TableCell
                classes={{
                  root: classes.noBorderBottom,
                }}
                component="th"
                scope="row"
                size="small"
                padding="none">
                <List>
                  <ListItem
                    style={{
                      marginRight: 16,
                      paddingLeft: 0,
                    }}>
                    <ListItemIcon style={{ marginLeft: 8 }}>
                      <IconButton
                        style={{ backgroundColor: "#bdffde", padding: "8px" }}>
                        <DoneIcon style={{ color: "#36d686", fontSize: 21 }} />
                      </IconButton>
                    </ListItemIcon>

                    <ListItemText
                      className={classes.listItemtext}
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}>
                          <b>Expected Value</b>
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}>
                          {numeral(
                            item.calculatedValue.resultOriented
                              .expected_result_value_achievement * 100
                          ).format("0.00")}
                          %
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </TableCell>

              {/* Result Value */}
              <TableCell
                component="th"
                scope="row"
                size="small"
                padding="none"
                classes={{
                  root: classes.noBorderBottom,
                }}>
                <List>
                  <ListItem
                    style={{
                      marginRight: 24,
                      paddingLeft: 0,
                      // marginTop: 16
                    }}>
                    <ListItemIcon style={{ marginLeft: 8 }}>
                      <IconButton
                        style={{ backgroundColor: "#f2f2f2", padding: "8px" }}>
                        <RoomIcon style={{ color: "#b7b7b7", fontSize: 21 }} />
                      </IconButton>
                    </ListItemIcon>

                    <ListItemText
                      className={classes.listItemtext}
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}>
                          <b>Result Value</b>
                        </Typography>
                      }
                      secondary={
                        item.calculatedValue.resultOriented.result_value_achievement
                          .toString()
                          .indexOf("e") !== -1 ? (
                          <Typography
                            variant="subtitle2"
                            className={classes.title}>
                            {ConvertExponentialToSubstring(
                              item.calculatedValue.resultOriented
                                .result_value_achievement * 100
                            )}
                            %
                          </Typography>
                        ) : (
                          <Typography
                            variant="subtitle2"
                            className={classes.title}>
                            {numeral(
                              item.calculatedValue.resultOriented
                                .result_value_achievement * 100
                            ).format("0.00")}
                            %
                          </Typography>
                        )
                      }
                    />
                  </ListItem>
                </List>
              </TableCell>

              {/* Weight MA */}
              <TableCell
                component="th"
                scope="row"
                size="small"
                padding="none"
                classes={{
                  root: classes.noBorderBottom,
                }}>
                <List>
                  <ListItem
                    style={{
                      marginRight: 24,
                      paddingLeft: 0,
                      // marginTop: 16
                    }}>
                    <ListItemIcon style={{ marginLeft: 8 }}>
                      <IconButton
                        style={{ backgroundColor: "#D9D1E3", padding: "8px" }}>
                        <ReorderRoundedIcon
                          style={{ color: "#A96BA0", fontSize: 21 }}
                        />
                      </IconButton>
                    </ListItemIcon>

                    <ListItemText
                      className={classes.listItemtext}
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}>
                          <b>Weight</b>
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}>
                          {item.weight !== null
                            ? `${numeral(item.weight).format("0.00")}%`
                            : "Not set"}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </TableCell>

              {/* Last Update & More Detail */}
              <TableCell
                classes={{
                  root: classes.noBorderBottom,
                }}
                component="th"
                scope="row"
                size="small"
                padding="none">
                <Box marginLeft={-8}>
                  <List>
                    <ListItem
                      alignItems="center"
                      style={{ paddingLeft: 0, marginRight: 24 }}>
                      <ListItemIcon>
                        <IconButton
                          size="small"
                          style={{
                            backgroundColor: "transparent",
                            padding: "1px",
                          }}>
                          <i
                            className="material-icons"
                            style={{ color: "transparent" }}>
                            person
                          </i>
                        </IconButton>
                      </ListItemIcon>

                      <ListItemText
                        // noWrap
                        className={classes.listItemtext}
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}>
                            Terakhir update &nbsp;
                            <Button
                              onClick={(e) => handleDetail(e, item)}
                              variant="outlined"
                              size="small"
                              disabled={!viewDetailAllowed}
                              className={classes.moreDetail}>
                              <A href={`/allma-detail/${item.id}`}>
                                <b>More Detail</b>
                              </A>
                            </Button>
                          </Typography>
                        }
                        secondary={
                          <LightTooltip
                            title={`Terakhir update ${
                              item.last_measured_activity_result_date !== null
                                ? moment(
                                    item.last_measured_activity_result_date
                                  ).format("DD MMMM YYYY h:mm:ss a")
                                : "-"
                            }`}
                            placement="left-start">
                            <Typography
                              variant="subtitle2"
                              className={classes.title}>
                              {item.last_measured_activity_result_date !== null
                                ? moment(
                                    item.last_measured_activity_result_date
                                  ).format("DD MMMM YYYY h:mm:ss a")
                                : "-"}
                            </Typography>
                          </LightTooltip>
                        }
                      />

                      {item.calculationMethod.code === "average" &&
                        item.calculatedValue.overall_achievement === "0" && (
                          <ListItemSecondaryAction>
                            <StyledBadge1
                              badgeContent={
                                <span style={{ fontFamily: "Roboto" }}>0</span>
                              }
                              showZero={true}
                              color="primary"
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}>
                              <PictEmptyStateGoalResultWithoutIconButton />
                            </StyledBadge1>
                          </ListItemSecondaryAction>
                        )}
                    </ListItem>
                  </List>
                </Box>
              </TableCell>
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={handleDropdownOpen}>
                  <MoreVertIcon />
                </IconButton>

                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleDropdownClose}>
                  {item.status_id !== "4" && item.status_id !== "2" && (
                    <StyledMenuItem
                      onClick={handleDialogSetAsComplete}
                      disabled={!setCompleteAllowed}>
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}>
                            <b>Set as complete</b>
                          </Typography>
                        }
                      />
                    </StyledMenuItem>
                  )}
                  <StyledMenuItem
                    disabled={context.errorDuplicate === true ? true : false}
                    onClick={handleDialogDuplicate}>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}>
                          <b>Duplicate</b>
                        </Typography>
                      }
                    />
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={handleDialogDelete}
                    disabled={!deleteAllowed}>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}>
                          <b>Delete</b>
                        </Typography>
                      }
                    />
                  </StyledMenuItem>
                </StyledMenu>
              </ListItemSecondaryAction>
            </ListItem>
          </Paper>
        </List>
        <DialogSetAsComplete
          classes={classes}
          isOpenDialogSetAsComplete={isOpenDialogSetAsComplete}
          setOpenDialogSetAsComplete={setOpenDialogSetAsComplete}
          maDetailState={item}
        />
        <DialogDuplicate
          classes={classes}
          item={item}
          isModalDuplicate={isModalDuplicate}
          setModalDuplicate={setModalDuplicate}
        />
        <DialogDeleteMA
          classes={classes}
          isModalDeleteMA={isModalDeleteMA}
          setModalDeleteMA={setModalDeleteMA}
          maDetailState={item}
        />

        <DialogConfirmWeight
          classes={classes}
          openDialogConfirmWeight={openDialogConfirmWeight}
          setOpenDialogConfirmWeight={setOpenDialogConfirmWeight}
          setModalContinue={setModalDeleteMA}
        />
      </TableRow>
    </Slide>
  );
}

export default ListCardItemMA;

const StyledBadge1 = withStyles((theme) => ({
  badge: {
    right: -4,
    top: -4,
    border: `2px solid #edcfd8`,
    padding: "0 4px",
    //   border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Badge);

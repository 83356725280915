import React, { useContext, Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Button,
  Checkbox,
  Box,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

import axiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

import { navigate } from "hookrouter";
import ContextGoal from "../../Context/ContextGoal";
import { styles } from "../../Style/StyleGoal";
import DialogCreate from "../../Components/DialogCreate";
import PictEmptyStateGoal from "../../Components/PictEmptyStateGoal";
import { ToGoalDetail } from "../../../../../constants/config-redirect-url";

import ListCardItemGoal from "./../../Components/ListCardItemGoal";
import DialogDeleteMultiple from "../../Components/DialogDeleteMultiple";

const useStyle = makeStyles(styles);

const ListStatusGoalDraft = (props) => {
  const context = useContext(ContextGoal);
  const classes = useStyle();

  const [selected, setSelected] = useState([]);
  const [checkValidDelete, setCheckValidDelete] = useState([]);
  const [isOpenDialogDeleteMultiple, setOpenDialogDeleteMultiple] = useState(
    false
  );

  /*  
        ``````````````````
        HANDLE GOAL DETAIL

        ``````````````````
    */
  const handleDetail = (e, data) => {
    e.preventDefault();
    console.log(data);

    // localStorage.setItem("goal_detail", JSON.stringify(data));
    // Redirect(ToGoalDetail);
    // navigate(ToGoalDetail, false, { id: data.id });
  };

  const handleGetValidGoalDelete = () => {
    return Promise.all(
      selected.length > 0 &&
        selected.map((item, index) => {
          const params = new URLSearchParams();
          params.append(`Goal[${index}][id]`, item);

          return axiosConfig.delete(
            `${URL_API}/swd/goal/batch?validateOnly=true`,
            { data: params }
          );
        })
    )
      .then((response) => {
        // console.log("ORI Response DEL", response);

        setCheckValidDelete(response);
        setOpenDialogDeleteMultiple(true);
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  };

  // Length Of Collection Goal
  const itemCount = context.collectionGoalList.length;

  /* =========================
        selected ID
        ============================= */
  const isSelected = (id) => selected && selected.indexOf(id) !== -1;

  /* =========================
        selected All List Item
        ============================= */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = context.collectionGoalList.map((n) => n.id);
      setSelected(newSelecteds);
      // console.log("selAll", newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <Fragment>
      <Grid container style={{ backgroundColor: "transparent", marginTop: 40 }}>
        <Box
          style={{
            marginLeft: 42,
            marginTop: -42,
            display: "flex",
            flexDirection: "row",
            // justifyContent: "center",
            alignItems: "center",
          }}>
          <Checkbox
            indeterminate={selected.length > 0 && selected.length < itemCount}
            checked={itemCount > 0 && selected.length === itemCount}
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
          <Typography
            color="inherit"
            variant="subtitle1"
            className={classes.title}>
            <b>{selected.length}</b> Goal ditandai
          </Typography>
        </Box>
        {selected.length > 0 && (
          <Box style={{ marginLeft: 24, marginTop: -42 }}>
            <Button
              onClick={handleGetValidGoalDelete}
              disableElevation
              variant="contained"
              className={classes.button5}
              startIcon={<DeleteRoundedIcon />}
              style={{ paddingTop: 8, paddingBottom: 8 }}>
              <b>Delete Multiple</b>
            </Button>
          </Box>
        )}
        <Grid item xs={12} md={12}>
          {context.loader ? (
            <Grid
              container
              spacing={0}
              direction="row"
              justify="center"
              alignItems="center">
              <CircularProgress
                size={32}
                style={{ marginTop: 72, color: "red" }}
              />
            </Grid>
          ) : (
            <Table
              className={classes.table}
              aria-label="sticky table"
              // stickyHeader
            >
              <TableHead></TableHead>
              <TableBody>
                {
                  // find.length > 0 && find.map((item,i) => {
                  context.collectionGoalList.length > 0 &&
                    context.collectionGoalList.map((item, i) => {
                      return (
                        <ListCardItemGoal
                          key={i}
                          classes={classes}
                          item={item}
                          selected={selected}
                          setSelected={setSelected}
                          handleDetail={handleDetail}
                          userTokenState={context.userTokenStateGlobal}
                          isSelected={isSelected}
                        />
                      );

                      // }
                    })
                }
              </TableBody>
            </Table>
          )}

          {context.collectionGoalList.length === 0 && context.loader === false && (
            <Paper
              elevation={1}
              className={classes.paperList}
              style={{ marginTop: 8 }}>
              <Grid container>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ color: "grey", marginTop: 16, marginBottom: 16 }}>
                    <PictEmptyStateGoal />
                    <b>
                      Tidak ada <i>Draft Goal </i> yang dapat di tampilkan saat
                      ini ;(
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          )}

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Grid>
      </Grid>

      <DialogCreate
        classes={classes}
        isModalCreate={context.isModalCreate}
        setModalCreate={context.setModalCreate}
        fotoQuery={context.fotoQuery}
        userLoginName={context.userLoginName}
        memberPositionList={context.memberPositionList}
        setCollectionGoalList={context.setCollectionGoalList}
        collectionGoalList={context.collectionGoalList}
      />

      <DialogDeleteMultiple
        classes={classes}
        isOpenDialogDeleteMultiple={isOpenDialogDeleteMultiple}
        setOpenDialogDeleteMultiple={setOpenDialogDeleteMultiple}
        selected={selected}
        checkValidDelete={checkValidDelete}
      />
    </Fragment>
  );
};

export default ListStatusGoalDraft;

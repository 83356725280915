import React, { useEffect, useState, Fragment } from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { Container, Typography, Grid, Box, Button } from "@material-ui/core";

import { navigate } from "hookrouter";
import moment from "moment";

import axiosConfig from "../../../constants/config-axios";

import CardAchievementYou from "./ComponentsDashboard/CardAchievementYou";
import CardGoalTop from "./ComponentsDashboard/CardGoalTop";
import CardGoalWorst from "./ComponentsDashboard/CardGoalWorst";

import CardTopMA from "./ComponentsDashboard/CardTopMA";
import CardWorstMA from "./ComponentsDashboard/CardWorstMA";

import { styles } from "../../7WD/Goal/Style/StyleGoal";
import { URL_API } from "../../../constants/config-api";
import {
  STATUS_ACTIVE,
  STATUS_LAPSED,
} from "../../AM/MembershipManagement/constant-data";

import PictEmptyStateDashboard from "../../../assets/images/empty_dashboard.png";

import "./Style/Bar.css";
import CardTDL from "./ComponentsDashboard/CardTDL";
import DialogLapsed from "./ComponentsDashboard/DialogLapsed";
import DialogActive from "./ComponentsDashboard/DialogActive";

const useStyle = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

// Fetcher

const ViewNewDashboard = () => {
  const classes = useStyle();
  const status_user_login = localStorage.getItem("status_user_login");
  const userLogin = JSON.parse(status_user_login);
  const status_id = userLogin.membership_status_id;

  // console.log("Stats", status_id);

  /*
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */

  const [collectionGoalTeratas, setCollectionGoalTeratas] = useState([
    {
      owner_id: "",
      member_first_name: "",
      member_last_name: "",
      member_photo_full_url: null,
      structure_position_title_name: "",
      overall_result_achievement: null,
    },
  ]);

  const [collectionGoalWorst, setCollectionGoalWorst] = useState([
    {
      owner_id: "",
      member_first_name: "",
      member_last_name: "",
      member_photo_full_url: null,
      structure_position_title_name: "",
      overall_result_achievement: null,
    },
  ]);

  const [collectionSelfGoal, setCollectionSelfGoal] = useState([
    {
      owner_id: "",
      member_first_name: "",
      member_last_name: "",
      member_photo_full_url: null,
      structure_position_title_name: "",
      overall_result_achievement: 0,
    },
  ]);

  const [
    collectionSelfMeasuredActivity,
    setCollectionSelfMeasuredActivity,
  ] = useState([
    {
      owner_id: "",
      member_first_name: "",
      member_last_name: "",
      member_photo_full_url: null,
      structure_position_title_name: "",
      overall_result_achievement: 0,
    },
  ]);

  const [collectionMaTeratas, setCollectionMaTeratas] = useState([
    {
      owner_id: "",
      member_first_name: "",
      member_last_name: "",
      member_photo_full_url: null,
      structure_position_title_name: "",
      overall_result_achievement: null,
    },
  ]);

  const [collectionMaWorst, setCollectionMaWorst] = useState([
    {
      owner_id: "",
      member_first_name: "",
      member_last_name: "",
      member_photo_full_url: null,
      structure_position_title_name: "",
      overall_result_achievement: null,
    },
  ]);

  const [subscription, setSubscription] = useState({
    start_date: "",
    finish_date: "",
    duration: {
      year: 0,
      months: 0,
      days: 0,
      hours: 0,
      minute: 0,
    },
    used: {
      year: 0,
      months: 0,
      days: 0,
      hours: 0,
      minute: 0,
    },
    left: {
      year: 0,
      months: 0,
      days: 0,
      hours: 0,
      minute: 0,
    },
    trial: {
      duration: 0,
      end_date: "",
      start_date: "",
      used: {
        year: 0,
        months: 0,
        days: 0,
        hours: 0,
        minute: 0,
      },
      left: {
        year: 0,
        months: 0,
        days: 0,
        hours: 0,
        minute: 0,
      },
    },
    lapsed: {
      duration: 0,
      end_date: "",
      start_date: "",
      used: {
        year: 0,
        months: 0,
        days: 0,
        hours: 0,
        minute: 0,
      },
      left: {
        year: 0,
        months: 0,
        days: 0,
        hours: 0,
        minute: 0,
      },
    },
  });
  const [days, setDays] = useState(null);
  const [day, setDay] = useState(null);
  const [detailPckg, setDetailPckg] = useState({
    membership_items: [],
    package_detail: {
      account_type: { id: "", name: "" },
      name: "",
    },
    subscription,
  });

  const [openDialogLapsed, setOpenDialogLapsed] = useState(false);
  const [openDialogActive, setOpenDialogActive] = useState(false);

  const [collectionTdl, setCollectionTdl] = useState([]);

  const [userTokenState, setUserTokenState] = useState("");
  useEffect(() => {
    //*SPECIAL FOR TAB STATUS YOU in PAGE GOAL
    localStorage.setItem("location_tab", "You");

    //*
    const userToken = localStorage.getItem("userToken");
    setUserTokenState(userTokenState);

    if (userToken !== undefined) {
      /*
        ```````````````````````````
        GET Membership

        ```````````````````````````
    */

      axiosConfig
        .get(`${URL_API}/account-management/group-membership-item`)
        .then((response) => {
          const result = response.data.data;

          if (response.status === 200) {
            console.log("on-result Memship", result);

            setDetailPckg(result);

            const endDate =
              result.subscription.lapsed && result.subscription.lapsed.end_date;
            const finishDate = subscription.finish_date;
            const convertDays = moment().diff(endDate, "days");
            const convertDay = moment().diff(finishDate, "days");

            setDays(convertDays);
            setDay(convertDay);
            setSubscription(result.subscription);

            if (status_id === STATUS_LAPSED) {
              setOpenDialogLapsed(true);
            }

            if (status_id === STATUS_ACTIVE) {
              if (convertDay >= -3) {
                setOpenDialogActive(true);
              }
            }
          }
        })
        .catch((error) => {
          console.log("Error : ", error.response);
        });

      /*
                ````````
                TOP GOAL

                ````````
            */
      axiosConfig
        .get(`${URL_API}/swd/dashboard/top-goal`)
        .then((response) => {
          console.log("Response Original TOP-GOAL : ", response);
          if (response.data.data !== null && response.data.data !== []) {
            setCollectionGoalTeratas(response.data.data);
          } else {
            setShowEmptyAllContentDashboard(true);
          }
          // setCollectionGoalWorst(response.data.data)
        })
        .catch((error) => {
          console.log("Error : ", error.response);
        });

      /*
                ``````````
                WORST GOAL

                ````````````
            */

      axiosConfig
        .get(`${URL_API}/swd/dashboard/worst-goal`)
        .then((response) => {
          console.log("Response Original WORST-GOAL : ", response);
          if (response.data.data !== null && response.data.data !== []) {
            setCollectionGoalWorst(response.data.data);
          } else {
            setShowEmptyAllContentDashboard(true);
          }
        })
        .catch((error) => {
          console.log("Error : ", error.response);
        });

      /*
                `````````
                SELF GOAL

                `````````
            */
      axiosConfig
        .get(`${URL_API}/swd/dashboard/self-goal`)
        .then((response) => {
          console.log("Response Original SELF-GOAL : ", response);

          if (response.data.data !== null && response.data.data !== []) {
            setCollectionSelfGoal([response.data.data]);
          }
        })
        .catch((error) => {
          console.log("Error : ", error.response);
        });

      /*
                ```````
                SELF MA

                ```````
            */

      axiosConfig
        .get(`${URL_API}/swd/dashboard/self-measured-activity`)
        .then((response) => {
          console.log("Response Original SELF-MA : ", response);

          if (response.data.data !== null && response.data.data !== []) {
            setCollectionSelfMeasuredActivity([response.data.data]);
          }
        })
        .catch((error) => {
          console.log("Error : ", error.response);
        });

      /*
                `````````````````````
                TOP MEASURED ACTIVITY

                ````````````````````
            */
      axiosConfig
        .get(`${URL_API}/swd/dashboard/top-measured-activity`)
        .then((response) => {
          console.log("Response Original TOP-MEASURED-ACTIVITY : ", response);
          if (response.data.data !== null && response.data.data !== []) {
            setCollectionMaTeratas(response.data.data);
          }
        })
        .catch((error) => {
          console.log("Error : ", error.response);
        });

      /*
                ```````````````````````````
                MEASURED ACTIVITY WORST

                ```````````````````````````
            */
      axiosConfig
        .get(`${URL_API}/swd/dashboard/worst-measured-activity`)
        .then((response) => {
          console.log("Response Original MEASURED-ACTIVITY-worst : ", response);
          if (response.data.data !== null && response.data.data !== []) {
            setCollectionMaWorst(response.data.data);
          }
          // else {

          //     setShowEmptyAllContentDashboard(true)
          // }
        })
        .catch((error) => {
          console.log("Error : ", error.response);
        });

      /*
                ```````````````````````````
                TDL USER

                ```````````````````````````
            */
      axiosConfig
        .get(
          `${URL_API}/todolist?options[filter][search]=${userLogin.member_first_name} ${userLogin.member_last_name}&options[paging][limit]=5&options[paging][offset]=0`
        )
        .then((response) => {
          console.log("TDL User : ", response);
          const result = response.data.data;
          if (response.data.data !== null && response.data.data !== []) {
            const filterIncomplete = result.filter(
              (item) => item.status_id === "1"
            );

            setCollectionTdl(filterIncomplete);
          }
          // else {

          //     setShowEmptyAllContentDashboard(true)
          // }
        })
        .catch((error) => {
          console.log("Error : ", error.response);
        });
    }
  }, []);

  const [
    isShowEmptyAllContentDashboard,
    setShowEmptyAllContentDashboard,
  ] = useState(false);

  return (
    <Container>
      {
        // collectionSelfGoal.length === 1 &&
        // collectionSelfMeasuredActivity.length === 1 &&

        // collectionGoalTeratas.length === 1 &&
        // collectionGoalWorst.length === 1 &&

        // collectionMaTeratas.length === 1 &&
        // collectionMaWorst.length === 1
        isShowEmptyAllContentDashboard === true ? (
          <Grid item xs={12} md={12} xl={12} style={{ textAlign: "center" }}>
            <Box
              display="flex"
              justifyContent="center"
              marginTop={theme.spacing(3)}>
              <img
                src={PictEmptyStateDashboard}
                alt={"Empty state"}
                width={248}
                height={224}
              />
            </Box>

            <Box marginTop={theme.spacing(0.5)}>
              <Typography variant="h6" className={classes.title}>
                <b>
                  Tidak ada pencapaian sama sekali di dalam <i>Dashboard</i>{" "}
                  Anda :(
                </b>
              </Typography>
            </Box>

            <Box marginTop={theme.spacing(0.1)}>
              <Typography variant="subtitle1" className={classes.title}>
                Yuk, mulai eksekusi sekarang !
              </Typography>
            </Box>

            <Box marginTop={theme.spacing(0.5)}>
              <Button
                size="small"
                onClick={() => navigate("/goal")}
                variant="contained"
                className={classes.button}>
                <Typography variant="subtitle2" className={classes.title}>
                  Buat Goal sekarang !
                </Typography>
              </Button>
            </Box>
          </Grid>
        ) : (
          <Fragment>
            <Grid container style={{ marginTop: 56 }}>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <CardAchievementYou
                  collectionSelfGoal={collectionSelfGoal}
                  collectionSelfMeasuredActivity={
                    collectionSelfMeasuredActivity
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} xl={4}>
                <CardGoalTop collectionGoalTeratas={collectionGoalTeratas} />
              </Grid>

              <Grid item xs={12} sm={12} md={4} xl={4}>
                <CardGoalWorst collectionGoalWorst={collectionGoalWorst} />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <CardTDL tdlCollections={collectionTdl} />
              </Grid>

              <Grid item xs={12} sm={12} md={4} xl={4}>
                <CardTopMA collectionMaTeratas={collectionMaTeratas} />
              </Grid>

              <Grid item xs={12} sm={12} md={4} xl={4}>
                <CardWorstMA
                  collectionMaWorst={collectionMaWorst}
                  collectionMaTeratas={collectionMaTeratas}
                />
              </Grid>
            </Grid>
          </Fragment>
        )
      }

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <DialogLapsed
        classes={classes}
        openDialogLapsed={openDialogLapsed}
        setOpenDialogLapsed={setOpenDialogLapsed}
        subscription={subscription}
        days={days}
        detailPckg={detailPckg}
      />

      <DialogActive
        classes={classes}
        openDialogActive={openDialogActive}
        setOpenDialogActive={setOpenDialogActive}
        subscription={subscription}
        day={day}
      />
    </Container>
  );
};

export default ViewNewDashboard;

import React, { Fragment, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  Box,
  Button,
} from "@material-ui/core";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { withStyles } from "@material-ui/core/styles";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import moment from "moment";

import TruncateTextGoalResult from "../../../../utilities/TruncateTextGoalResult";
import {
  FormatThousand,
  FormatDecimal,
} from "../../../../utilities/FormatDecimal";
import LightTooltip from "../../../../components/LightTooltip";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";
import Uploader from "./Uploader";

import DialogEditResult from "./DialogEditResult";
import DialogDelete from "./DialogDelete";

const ExpansionPanel = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    // backgroundColor: "rgba(0, 0, 0, .03)",
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    maxHeight: 15,
    "&$expanded": {
      maxHeight: 15,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    // padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

function ListItemGoalResultList({
  classes,
  item,
  idx,
  rupiahMode,
  userToken,
  collectionGoalResultList,
  goalDetailState,
  isStatusCompleted,
  setFireGoalDetail,
}) {
  /* ====================================================
    LOCAL STATE
   ==================================================== */
  const [expanded, setExpanded] = useState("");
  const [anchorElListGoalResult, setAnchorElListGoalResult] = useState(null);
  const [openDialogEditResult, setOpenDialogEditResult] = useState(false);
  const [isModalDelete, setModalDelete] = useState(false);

  const handleChangePanel = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  /* ====================================================
    LOCAL METHOD
   ==================================================== */
  const handleDropdownOpenGoalResult = (event) => {
    event.preventDefault();
    setAnchorElListGoalResult(event.currentTarget);
  };

  function handleDropdownCloseGoalResult() {
    setAnchorElListGoalResult(null);
  }

  const handleDialogDelete = (e, data) => {
    e.preventDefault();

    handleDropdownCloseGoalResult();
    setModalDelete(true);
  };

  // DELETE / EDIT POLICY (HAK AKSES)

  let editAllowed = false;
  let deleteAllowed = false;

  item.policy &&
    item.policy.forEach((item) => {
      if (item.actionName === "update") {
        editAllowed = item.result;
      } else if (item.actionName === "delete") {
        deleteAllowed = item.result;
      }
    });

  // console.log("POLISI GGRR", item.policy);

  return (
    <Fragment>
      <List key={item.id} style={{ width: "860%" }}>
        <ListItem>
          <Box display="flex" width="400%">
            <Box marginRight={5}>
              <Typography variant="subtitle2" className={classes.titleGRList}>
                Goal Result
              </Typography>
              <Typography
                variant="h6"
                className={classes.title}
                style={{ color: "#55dc87" }}>
                <b>
                  {rupiahMode === true
                    ? `Rp ${FormatDecimal(item.result_value)}`
                    : goalDetailState.valueType.id === "2"
                    ? FormatDecimal(item.result_value) + " %"
                    : FormatDecimal(item.result_value)}
                </b>
              </Typography>
            </Box>

            <Box marginLeft={6}>
              <Typography variant="subtitle2" className={classes.titleGRList}>
                Komentar
              </Typography>
              <LightTooltip
                placement="top-start"
                title={
                  item.description !== null
                    ? item.description.length > 78
                      ? item.description
                      : ""
                    : ""
                }>
                {expanded ? (
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey", width: 550 }}>
                    {item.description !== null ? item.description : "-"}
                  </Typography>
                ) : (
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    {item.description !== null
                      ? TruncateTextGoalResult(item.description)
                      : "-"}
                  </Typography>
                )}
              </LightTooltip>
            </Box>
          </Box>
        </ListItem>

        <ListItemSecondaryAction style={{ textAlign: "end" }}>
          <IconButton
            disabled={isStatusCompleted === true ? true : false}
            onClick={handleDropdownOpenGoalResult}>
            <MoreHorizIcon />
          </IconButton>

          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "grey" }}>
            <i style={{ fontSize: 12 }}>
              {`${moment(item.result_date).format("DD MMMM YYYY")}`}
            </i>
            ,<i style={{ fontSize: 12 }}> {item.result_time.slice(0, 5)}</i>
          </Typography>

          <StyledMenu
            id="customized-menu-goal-result"
            anchorEl={anchorElListGoalResult}
            keepMounted
            open={Boolean(anchorElListGoalResult)}
            onClose={handleDropdownCloseGoalResult}>
            <StyledMenuItem
              disabled={!editAllowed}
              onClick={() => {
                setOpenDialogEditResult(true);
                setAnchorElListGoalResult(null);
              }}>
              <ListItemText
                primary={
                  <Typography variant="subtitle2" className={classes.title}>
                    <b>Edit</b>
                  </Typography>
                }
              />
            </StyledMenuItem>
            <StyledMenuItem
              disabled={!deleteAllowed}
              onClick={(e) => {
                handleDialogDelete(e, item);
                setAnchorElListGoalResult(null);
              }}>
              <ListItemText
                primary={
                  <Typography variant="subtitle2" className={classes.title}>
                    <b>Delete</b>
                  </Typography>
                }
              />
            </StyledMenuItem>
          </StyledMenu>
        </ListItemSecondaryAction>
      </List>
      <ExpansionPanel
        square
        style={{ width: "860%", borderBottom: "1px solid rgba(0, 0, 0, .125)" }}
        expanded={expanded === `panel${idx}`}
        onChange={handleChangePanel(`panel${idx}`)}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <ExpansionPanelSummary
            aria-controls={`panel${idx}d-content`}
            style={{ margin: 0, padding: 0, textAlign: "center" }}
            id={`panel${idx}d-header`}>
            {expanded ? (
              <Button
                size="small"
                className={classes.expandGRBtn}
                endIcon={
                  <ExpandLessIcon style={{ color: "#888" }} fontSize="small" />
                }>
                <Typography className={classes.titleGRList} variant="subtitle2">
                  {item.goalResultEvidence.length} Evidence
                </Typography>
              </Button>
            ) : (
              <Button
                size="small"
                className={classes.expandGRBtn}
                endIcon={
                  <ExpandMoreIcon style={{ color: "#888" }} fontSize="small" />
                }>
                <Typography className={classes.titleGRList} variant="subtitle2">
                  {item.goalResultEvidence.length} Evidence
                </Typography>
              </Button>
            )}
          </ExpansionPanelSummary>
        </Box>
        <ExpansionPanelDetails>
          <Box paddingBottom={4}>
            <Typography className={classes.titleGRList} variant="subtitle2">
              {moment(item.updated_at).format("DD MMMM YYYY HH:mm:ss a")}
            </Typography>
            {item.goalResultEvidence.length > 0 && (
              <Typography
                className={classes.title}
                variant="subtitle2"
                style={{ fontSize: 12, color: "#999" }}>
                Evidence
              </Typography>
            )}

            <br />

            <Uploader
              classes={classes}
              userToken={userToken}
              collectionGoalResultList={collectionGoalResultList}
              item={
                item.goalResultEvidence.length > 0
                  ? item.goalResultEvidence
                  : []
              }
              grItem={item}
              deleteAllowed={deleteAllowed}
              setFireGoalDetail={setFireGoalDetail}
              goalDetailState={goalDetailState}
            />
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <DialogEditResult
        openDialogEditResult={openDialogEditResult}
        setOpenDialogEditResult={setOpenDialogEditResult}
        setFireGoalDetail={setFireGoalDetail}
        goalDetailState={goalDetailState}
        classes={classes}
        item={item}
        evidenceCount={item.goalResultEvidence.length}
      />

      <DialogDelete
        classes={classes}
        isModalDelete={isModalDelete}
        setModalDelete={setModalDelete}
        setFireGoalDetail={setFireGoalDetail}
        goalResultId={item.id}
        goalDetailState={goalDetailState}
      />
    </Fragment>
  );
}

export default ListItemGoalResultList;

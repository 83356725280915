import React, { Component, useEffect, useState, useCallback } from "react";
import { makeStyles, createMuiTheme, withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { 
    Container, Paper, Typography, Grid, TextField, Checkbox, CircularProgress,
    FormControlLabel, Box, Button, MenuItem, Dialog, DialogTitle, DialogContent, 
    DialogContentText, DialogActions, Breadcrumbs, Link, IconButton, InputBase, Divider,
    Tooltip, List, ListItem, ListItemText, Avatar, Table, TableBody, TableRow, TableCell,
    Chip, TablePagination, TableHead, TableSortLabel, Radio, FormLabel, Snackbar, Fab
} from '@material-ui/core';

import axios from 'axios';

import { useGetHttp } from '../../../../utilities-hook/useGetHttp';
import { URL_API } from '../../../../constants/config-api';

import Snackbeer from '../../../../components/Snackbeer';

const IdentitasSubAlamat = props => {

    const { classes } = props;

    const employeeId = localStorage.getItem('employee_id');

    const [loading, fetchedData, setFetchedData ] = useGetHttp(URL_API + `/human-resource/employee/batch/${employeeId}/patch`, []);

    let updatedAt = null;
    if(fetchedData !== null){

        console.log("fetchedData : ", fetchedData);

        if(fetchedData.fields !== null){
            
            updatedAt = fetchedData.fields.Employee.updated_at

        };
    }

    /* 
        ````````````````````
        COMPONENT DID MOUNT
        
        ````````````````````
    */
   const userToken = localStorage.getItem('userToken');
   const [ userTokenState, setUserTokenState] = useState(userToken);


   const dataIdentitasAlamat = localStorage.getItem('data_identitas_alamat');
   const dataIdentitasAlamatAfterParse = JSON.parse(dataIdentitasAlamat);
   
   useEffect(() => {

       console.log("dataIdentitasAlamatAfterParse : ", dataIdentitasAlamatAfterParse);
       setUserTokenState(userToken);

       
   }, []);


    /*
        ````````````
        HANDLE EDIT

        ````````````
    */
    const [ isTextFieldDisabled, setTextFieldDisabled ] = useState(false);
        
    const handleEdit = () => {

        setTextFieldDisabled(true)

    };

        /*
        ````````````````
        ALAMAT DOMISILI
        
        ````````````````
    */
   const [ negara, setNegara ] = useState({

    name: 'Indonesia'
});

const [ provinsi, setProvinsi ] = useState({

    name: ''
});

const [ kotaKabupaten, setKotaKabupaten ] = useState({
    
    name: ''
});

const [ kodePos, setKodePos ] = useState({

    name: ''
});

const [ alamatDomisili, setAlamatDomisili ] = useState('');

const handleChangeNegara = name => event => {

    setNegara({ ...negara, [name]: event.target.value });
};

const [ kotaKabupatenList, setKotaKabupatenList ] = useState([]);
const [ kotaKabupatenId, setKotaKabupatenId ] = useState('');

const handleChangeProvinsi = name => e => {

    setInfoError(false);
    setProvinsi({ ...provinsi, [name]: e.target.value });

    //***GET LIST CITY
    if(userTokenState !== undefined){

        console.log(e.target.value);
        setKotaKabupatenId(e.target.value.id);

        const headers =  {
            'Accept': "application/json",
            'Content-Type' : "application/json",
            'Access-Control-Allow-Origin': '*',
            'crossorigin':true,
            'crossDomain': true,
            'Authorization': 'bearer ' + userTokenState
        };
    
        axios.defaults.headers.common = headers;    
        axios
            // .get(URL_API + `/group/profile/patch?options[filter][city_id][0][0]=province_id&options[filter][city_id][0][1]=eq&options[filter][city_id][0][2]=${e.target.value}`)
            .get(URL_API + `/human-resource/employee/batch/create?options[filter][province_id][0][0]=country_id&options[filter][province_id][0][1]=eq&options[filter][province_id][0][1]=a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc&options[filter][city_id][0][0]=province_id&options[filter][city_id][0][1]=eq&options[filter][city_id][0][2]=${e.target.value.id}`)
            .then((response) => {
                
                console.log("Original response CITY : ",response);
                
                const listCity = [];
        
                if(response.data.data !== undefined) {

                    Object.getOwnPropertyNames(response.data.data.cityOptions).forEach((val, idx, array) => {
                            const data = {
                                key: val,
                                value: response.data.data.cityOptions[val]
                            };			
                            listCity.push(data);
                        }
                    )
                };  

                setKotaKabupatenList(listCity);
            })
            .catch((error) => {
                console.log("Error response : ",error.response);
                
            });

    } else { console.log("Ga dapet User Token !")}

};

const [ kodePosList, setKodePosList ] = useState([]);
const handleChangeKotaKabupaten = name => e => {        

    setInfoError(false);
    setKotaKabupaten({ ...kotaKabupaten, [name]: e.target.value });

    console.log("kotaKabupatenId : ", kotaKabupatenId);
    

    //**GET KODE POS 
    if(userTokenState !== undefined){

        console.log(e.target.value);



        const headers =  {
            'Accept': "application/json",
            'Content-Type' : "application/json",
            'Access-Control-Allow-Origin': '*',
            'crossorigin':true,
            'crossDomain': true,
            'Authorization': 'bearer ' + userTokenState
        };
    
        axios.defaults.headers.common = headers;    
        axios
            .get(URL_API + `/human-resource/employee/batch/create?options[filter][province_id][0][0]=country_id&options[filter][province_id][0][1]=eq&options[filter][province_id][0][1]=a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc&options[filter][city_id][0][0]=province_id&options[filter][city_id][0][1]=eq&options[filter][city_id][0][2]=${kotaKabupatenId}&options[filter][postal_code_id][0][0]=city_id&options[filter][postal_code_id][0][1]=eq&options[filter][postal_code_id][0][2]=${e.target.value !== undefined ? e.target.value.key : ''}`)
            .then((response) => {
                
                console.log("Original response Kode POS: ",response);
                
                const listKodePos = [];
        
                if(response.data.data !== undefined) {

                    Object.getOwnPropertyNames(response.data.data.postalCodeOptions).forEach((val, idx, array) => {
                            const data = {
                                key: val,
                                value: response.data.data.postalCodeOptions[val]
                            };			
                            listKodePos.push(data);
                        }
                    )
                };  

                setKodePosList(listKodePos);
            })
            .catch((error) => {
                console.log("Error response : ",error.response);
                
            });

        } else { console.log("Ga dapet User Token !")}

    };

    const handleChangeKodePos = name => event => {

        setInfoError(false);
        setKodePos({ ...kodePos, [name]: event.target.value });

    };

    const handleChangeAlamatDomisili = (e) => setAlamatDomisili(e.target.value);


    /*
        `````````````
        ALAMAT IN KTP

        `````````````
    */

    const [ negaraInKtp, setNegaraInKtp ] = useState({

        name: 'Indonesia'
    });

    const [ provinsiInKtp, setProvinsiInKtp ] = useState({

        name: ''
    });

    const [ kotaKabInKtp, setKotaKabInKtp ] = useState({
        
        name: ''
    });

    const [ kodePosInKtp, setKodePosInKtp ] = useState({

        name: ''
    });

    const [ alamatInKtp, setAlamatInKtp ] = useState('');


    /*
        ```````
        IN KTP 

        ```````
    */

    const handleChangeNegaraInKTP = name => event => {

        setNegaraInKtp({ ...negaraInKtp, [name]: event.target.value });
    };


    const [ kotaKabupatenListInKTP, setKotaKabupatenListInKTP ] = useState([]);
    const [ kotaKabupatenIdInKTP, setKotaKabupatenIdInKTP ] = useState('');

    const handleChangeProvinsiInKTP = name => e => {

    setProvinsiInKtp({ ...provinsiInKtp, [name]: e.target.value });

    //***GET LIST CITY
    if(userTokenState !== undefined){

        console.log(e.target.value);
        setKotaKabupatenIdInKTP(e.target.value.id);

        const headers =  {
            'Accept': "application/json",
            'Content-Type' : "application/json",
            'Access-Control-Allow-Origin': '*',
            'crossorigin':true,
            'crossDomain': true,
            'Authorization': 'bearer ' + userTokenState
        };
    
        axios.defaults.headers.common = headers;    
        axios
            // .get(URL_API + `/group/profile/patch?options[filter][city_id][0][0]=province_id&options[filter][city_id][0][1]=eq&options[filter][city_id][0][2]=${e.target.value}`)
            .get(URL_API + `/human-resource/employee/batch/create?options[filter][province_id][0][0]=country_id&options[filter][province_id][0][1]=eq&options[filter][province_id][0][1]=a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc&options[filter][city_id][0][0]=province_id&options[filter][city_id][0][1]=eq&options[filter][city_id][0][2]=${e.target.value.id}`)
            .then((response) => {
                
                console.log("Original response CITY in KTP : ",response);
                
                const listCity = [];
        
                if(response.data.data !== undefined) {

                    Object.getOwnPropertyNames(response.data.data.cityOptions).forEach((val, idx, array) => {
                            const data = {
                                key: val,
                                value: response.data.data.cityOptions[val]
                            };			
                            listCity.push(data);
                        }
                    )
                };  

                setKotaKabupatenListInKTP(listCity);
            })
            .catch((error) => {
                console.log("Error response : ",error.response);
                
            });

    } else { console.log("Ga dapet User Token !")}
};


const [ kodePosListInKTP, setKodePosListInKTP ] = useState([]);
const handleChangeKotaKabInKTP = name => e => {

    setInfoError(false);
    setKotaKabInKtp({ ...kotaKabInKtp, [name]: e.target.value });

    console.log("kotaKabupatenIdInKTP : ", kotaKabupatenIdInKTP);
    
    //**GET KODE POS 
    if(userTokenState !== undefined){

        console.log(e.target.value);

        const headers =  {
            'Accept': "application/json",
            'Content-Type' : "application/json",
            'Access-Control-Allow-Origin': '*',
            'crossorigin':true,
            'crossDomain': true,
            'Authorization': 'bearer ' + userTokenState
        };
    
        axios.defaults.headers.common = headers;    
        axios
            .get(URL_API + `/human-resource/employee/batch/create?options[filter][province_id][0][0]=country_id&options[filter][province_id][0][1]=eq&options[filter][province_id][0][1]=a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc&options[filter][city_id][0][0]=province_id&options[filter][city_id][0][1]=eq&options[filter][city_id][0][2]=${kotaKabupatenIdInKTP}&options[filter][postal_code_id][0][0]=city_id&options[filter][postal_code_id][0][1]=eq&options[filter][postal_code_id][0][2]=${e.target.value !== undefined ? e.target.value.key : ''}`)
            .then((response) => {
                
                console.log("Original response Kode POS in: ",response);
                
                const listKodePos = [];
        
                if(response.data.data !== undefined) {

                    Object.getOwnPropertyNames(response.data.data.postalCodeOptions).forEach((val, idx, array) => {
                            const data = {
                                key: val,
                                value: response.data.data.postalCodeOptions[val]
                            };			
                            listKodePos.push(data);
                        }
                    )
                };  

                setKodePosListInKTP(listKodePos);
            })
            .catch((error) => {
                console.log("Error response : ",error.response);
                
            });

        } else { console.log("Ga dapet User Token !")}
    };

    const handleChangeKodePosInKTP = name => event => {

        setKodePosInKtp({ ...kodePosInKtp, [name]: event.target.value });

    };

    const handleChangeAlamatInKTP = (e) => setAlamatInKtp(e.target.value);


    /*
        ```````````````````
        HANDLE POST LANJUT

        ``````````````````
    */
    const [ infoError, setInfoError ] = useState(false);


    /*
        ```````````````````````
        HANDLE SIMPAN PERUBAHAN

        ````````````````````````
    */
   const handleSimpanPerubahan = () => {

    setTextFieldDisabled(false);

    const userToken = localStorage.getItem('userToken');
    
    let data = {
        
        Employee: {

            address:  // KTP
            {
                country_id: dataIdentitasAlamatAfterParse.negara == 'Indonesia' ? 'a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc' : 'a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc',
                province_id: dataIdentitasAlamatAfterParse !== undefined ? dataIdentitasAlamatAfterParse.provinsi.id : provinsi,
                city_id: dataIdentitasAlamatAfterParse !== undefined ? dataIdentitasAlamatAfterParse.kota_kabupaten.key : kotaKabupaten,
                postal_code_id: dataIdentitasAlamatAfterParse !== undefined ? dataIdentitasAlamatAfterParse.kode_pos.key : kodePos,
                detail: alamatDomisili !== '' ? alamatDomisili : dataIdentitasAlamatAfterParse.alamat_domisili
            },

            current_address_id:  {// DOMISILI

                country_id: dataIdentitasAlamatAfterParse.negara_in_ktp == 'Indonesia' ? 'a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc' : 'a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc',
                province_id: dataIdentitasAlamatAfterParse !== undefined ? dataIdentitasAlamatAfterParse.provinsi_in_ktp.id : provinsiInKtp,
                city_id: dataIdentitasAlamatAfterParse !== undefined ? dataIdentitasAlamatAfterParse.kota_kabupaten_in_ktp.key : kotaKabInKtp,
                postal_code_id: dataIdentitasAlamatAfterParse !== undefined ? dataIdentitasAlamatAfterParse.kode_pos_in_ktp.key : kodePosInKtp,
                detail: alamatInKtp !== '' ? alamatInKtp : dataIdentitasAlamatAfterParse.alamat_in_ktp
            },
            updated_at: updatedAt
            
        },
        _method: 'patch'
    };

    console.log("Simpan Perubahan ... : ", data);
    
    if(userToken !== undefined){
        
        const header =  {       
            'Accept': "application/json",
            'Content-Type' : "application/json",
            'Authorization' : "bearer " + userToken,
        };

        axios.defaults.headers.common = header;    

        axios
            .post(URL_API + `/human-resource/employee/${employeeId}`, data)
            .then(function(response){

                console.log("Response Original : ", response)

                if(response.status == 200 ){

                    setModalResponse200(true);

                    if(response.data.data !== undefined){
                      
                    };
                };
            })
            .catch(function(error){
                
                alert('Whoops, something went wrong !');
                console.log("Error : ", error.response)
                
            })

        } else { console.log("No Access Token available!")};

    };

    /*
        ```````````````
        HANDLE SNACKBAR

        ```````````````
    */

    const [ isModalResponse200, setModalResponse200 ] = useState(false);

    return (

        <Paper elevation={1} className={classes.paperInfoDasar} square={true} style={{marginRight: 16}}> 
            <br />
            <Grid container>
                <Grid item sm={10}>
                    <Typography variant='subtitle1' className={classes.titleHeader}>
                        <b>Alamat</b> 
                    </Typography>               
                </Grid>
                <Grid item sm={2}>
                    <IconButton
                        onClick={handleEdit }
                    >
                        <i className='material-icons'>
                            edit
                        </i>
                    </IconButton>
                </Grid>
            </Grid>

            <br />
            <Typography variant='subtitle2' className={classes.titleHeader}>
                <b>Alamat Domisili : </b> 
            </Typography>

            <List className={classes.list}>
                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Negara</b>
                            </Typography>
                        } 
                    />

                    {
                        isTextFieldDisabled !== true ? (

                            <TextField  
                                id="outlined-bare"                           
                                placeholder={dataIdentitasAlamatAfterParse !== null ? dataIdentitasAlamatAfterParse.negara : 'Indonesia'}
                                className={classes.textField}
                                inputProps={{className: classes.titleTextField}} 
                                disabled
                            />

                        ) : (
                            <TextField
                                    id="outlined-select-currency"
                                    select
                                    // label="Pilih Negara : "
                                    className={classes.textField}
                                    value={negara.name}
                                    onChange={handleChangeNegara('name')}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                
                                >
                                    {
                                        negaras.map (

                                            option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            )
                                        )
                                    }

                            </TextField>
                        )
                    }
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Provinsi</b>
                            </Typography>
                        } 
                    />  

                    {
                        isTextFieldDisabled !== true ? (

                            <TextField  
                                id="outlined-bare"
                                value={dataIdentitasAlamatAfterParse !== null ? dataIdentitasAlamatAfterParse.provinsi.value : ''}
                                className={classes.textField}
                                inputProps={{className: classes.title}} 
                                disabled
                            />

                        ) : (
                            <TextField
                                id="outlined-select-provinsi"
                                select
                                // label="Pilih Negara : "
                                className={classes.textField}
                                value={provinsi.name}
                                onChange={handleChangeProvinsi('name')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                variant="outlined"
                                error={infoError == true && provinsi.name == '' ? true : false}
                                helperText={infoError == true && provinsi.name == '' ? "Wajib di isi" : ' '}
                            >
                                {
                                    provinces.length > 0 ? 
                                        provinces.map (

                                            option => (
                                                <MenuItem key={option.value} value={option}>
                                                    {option.label}
                                                </MenuItem>
                                            )
                                        ) : (
                                            <MenuItem >
                                                Loading...
                                            </MenuItem>
                                        )
                                }

                            </TextField>
                        )
                    }
                </ListItem>
         

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Kota/Kabupaten</b>
                            </Typography>
                        } 
                    />

                    {
                        isTextFieldDisabled !== true ? (

                            <TextField  
                                id="outlined-bare"
                                value={dataIdentitasAlamatAfterParse !== null ? dataIdentitasAlamatAfterParse.kota_kabupaten.value : ''} 
                                className={classes.textField}
                                inputProps={{className: classes.title}} 
                                disabled
                            />

                        ) : (
                            <TextField
                                id="outlined-select-provinsi"
                                select
                                // label="Pilih Negara : "
                                className={classes.textField}
                                value={kotaKabupaten.name}
                                onChange={handleChangeKotaKabupaten('name')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                variant="outlined"
                                error={infoError == true && kotaKabupaten.name == '' ? true : false}
                                helperText={infoError == true && kotaKabupaten.name == '' ? "Wajib di isi" : ' '}
                            >
                                {
                                    kotaKabupatenList.length > 0 ?

                                        kotaKabupatenList.map (

                                            option => (
                                                
                                                // <MenuItem key={option.value} value={option.value}>
                                                //     {option.label}
                                                // </MenuItem>
                                                <MenuItem key={option.key} value={option}>
                                                    {option.value}
                                                </MenuItem>
                                            )

                                        ) : (

                                            <MenuItem>
                                                Loading...
                                            </MenuItem>
                                        )
                                }

                            </TextField>
                        )
                    }

                </ListItem>
                
                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Kode Pos</b>
                            </Typography>
                        } 
                    />

                    {
                        isTextFieldDisabled !== true ? (
                            
                            <TextField  
                                id="outlined-bare"
                                placeholder={dataIdentitasAlamatAfterParse !== null ? dataIdentitasAlamatAfterParse.kode_pos.value : ''}
                                className={classes.textField}
                                inputProps={{className: classes.titleTextField}} 
                                disabled
                            />

                        ) : (
                            <TextField
                                id="outlined-select-provinsi"
                                select
                                // label="Pilih Negara : "
                                className={classes.textField}
                                value={kodePos.name}
                                onChange={handleChangeKodePos('name')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                variant="outlined"
                                error={infoError == true && kodePos.name == '' ? true : false}
                                helperText={infoError == true && kodePos.name == '' ? "Wajib di isi" : ' '}
                            >
                                {

                                    kodePosList.length > 0 ? 
                                        kodePosList.map (

                                            option => (
                                                // <MenuItem key={option.value} value={option.value}>
                                                //     {option.label}
                                                // </MenuItem>
                                                <MenuItem key={option.key} value={option}>
                                                    {option.value}
                                                </MenuItem>
                                            )
                                            
                                        ) : (
                                            <MenuItem >
                                                Loading...
                                            </MenuItem>
                                        )
                                }

                            </TextField>
                        )
                    }
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Alamat Domisili</b>
                            </Typography>
                        } 
                    />

                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeAlamatDomisili}
                        value={alamatDomisili}
                        placeholder={dataIdentitasAlamatAfterParse !== null ? dataIdentitasAlamatAfterParse.alamat_domisili : ''}
                        className={classes.textField}
                        inputProps={{className: classes.titleTextField}} 
                        disabled={isTextFieldDisabled !== true ? true : false}                        
                    />
                </ListItem>
            </List>

            {/* 
                        
                ``````````````
                ALAMAT IN KTP 

                ``````````````
            
            */}
            <br />
            <br />
            <Typography variant='subtitle2' className={classes.titleHeader}>
                <b>Alamat KTP : </b> 
            </Typography>

            <List className={classes.list}>

                <ListItem >  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Negara</b>
                            </Typography>
                        } 
                    />

                    {
                        isTextFieldDisabled !== true ? (

                            <TextField  
                                id="outlined-bare"
                                value={dataIdentitasAlamatAfterParse !== null ? dataIdentitasAlamatAfterParse.negara_in_ktp : ''}
                                className={classes.textField}
                                inputProps={{className: classes.title}} 
                                disabled
                            />

                        ) : (
                                <TextField
                                        id="outlined-select-currency"
                                        select
                                        // label="Pilih Negara : "
                                        className={classes.textField}
                                        value={negaraInKtp.name}
                                        onChange={handleChangeNegaraInKTP('name')}
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                        {
                                            negaras.map (

                                                option => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                )
                                            )
                                        }
                                </TextField>
                        )
                    }

                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Provinsi</b>
                            </Typography>
                        } 
                    />

                    {
                        isTextFieldDisabled !== true ? (

                            <TextField  
                                id="outlined-bare"
                                value={dataIdentitasAlamatAfterParse !== null ? dataIdentitasAlamatAfterParse.provinsi_in_ktp.value : ''}
                                className={classes.textField}
                                inputProps={{className: classes.title}} 
                                disabled
                            />

                        ) : (

                            <TextField
                                id="outlined-select-provinsi"
                                select
                                // label="Pilih Negara : "
                                className={classes.textField}
                                value={provinsiInKtp.name}
                                onChange={handleChangeProvinsiInKTP('name')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                variant="outlined"
                                error={infoError == true && provinsiInKtp.name == '' ? true : false}
                                helperText={infoError == true && provinsiInKtp.name == '' ? "Wajib di isi" : ' '}
                            >
                                {
                                    provinces.map (

                                        option => (
                                            <MenuItem key={option.key} value={option}>
                                                {option.value}
                                            </MenuItem>
                                        )
                                    )
                                }

                            </TextField>
                        )
                    }
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Kota/Kabupaten</b>
                            </Typography>
                        } 
                    />

                    {
                        isTextFieldDisabled !== true ? (

                            <TextField  
                                id="outlined-bare"
                                value={dataIdentitasAlamatAfterParse !== null ? dataIdentitasAlamatAfterParse.kota_kabupaten_in_ktp.value : ''}
                                className={classes.textField}
                                inputProps={{className: classes.title}} 
                                disabled
                            />

                        ) : (

                            <TextField
                                id="outlined-select-provinsi"
                                select
                                // label="Pilih Negara : "
                                className={classes.textField}
                                value={kotaKabInKtp.name}
                                onChange={handleChangeKotaKabInKTP('name')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                variant="outlined"
                                error={infoError == true && kotaKabInKtp.name == '' ? true : false}
                                helperText={infoError == true && kotaKabInKtp.name == '' ? "Wajib di isi" : ' '}
                            >
                                {
                                    kotaKabupatenListInKTP.length > 0 ? 

                                        kotaKabupatenListInKTP.map (

                                            option => (
                                                // <MenuItem key={option.value} value={option.value}>
                                                //     {option.label}
                                                // </MenuItem>
                                                <MenuItem key={option.key} value={option}>
                                                    {option.value}
                                                </MenuItem>
                                            )

                                        ) : (
                                            <MenuItem >
                                                Loading...
                                            </MenuItem>
                                        )
                                }

                            </TextField>
                        )
                    }
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Kode Pos</b>
                            </Typography>
                        } 
                    />

                    {
                        isTextFieldDisabled !== true ? (

                            <TextField  
                                id="outlined-bare"
                                value={dataIdentitasAlamatAfterParse !== null ? dataIdentitasAlamatAfterParse.kode_pos_in_ktp.value : ''}
                                className={classes.textField}
                                inputProps={{className: classes.title}} 
                                disabled
                            />

                        ) : (
                            <TextField
                                id="outlined-select-provinsi"
                                select
                                // label="Pilih Negara : "
                                className={classes.textField}
                                value={kodePosInKtp.name}
                                onChange={handleChangeKodePosInKTP('name')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                variant="outlined"
                                error={infoError == true && kodePosInKtp.name == '' ? true : false}
                                helperText={infoError == true && kodePosInKtp.name == '' ? "Wajib di isi" : ' '}
                            >
                                {
                                    kodePosListInKTP.length > 0 ? 
                                        // codePos.map (
                                        kodePosListInKTP.map (


                                            option => (
                                                // <MenuItem key={option.value} value={option.value}>
                                                //     {option.label}
                                                // </MenuItem>
                                                <MenuItem key={option.key} value={option}>
                                                    {option.value}
                                                </MenuItem>
                                            )

                                        ) : (

                                            <MenuItem>
                                                Loading... 
                                            </MenuItem>
                                        )
                                }

                            </TextField>
                        )
                    }
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Alamat KTP</b>
                            </Typography>
                        } 
                    />

                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeAlamatInKTP}
                        value={alamatInKtp}
                        placeholder={dataIdentitasAlamatAfterParse !== null ? dataIdentitasAlamatAfterParse.alamat_in_ktp : ''}
                        className={classes.textField}
                        inputProps={{className: classes.titleTextField}} 
                        disabled={isTextFieldDisabled !== true ? true : false}

                    />
                </ListItem>                
                

            </List>


            <Grid container>
                <Grid item sm={12} style={{textAlign: 'right'}}>

                    {
                        isTextFieldDisabled == true ? (
                            <Button 
                                variant='contained'
                                className={classes.button}
                                style={{marginRight: 32, marginBottom: 24, marginTop: 24}}
                                onClick={handleSimpanPerubahan}
                                
                            >
                                Simpan Perubahan
                            </Button>

                        ) : null
                    }

                </Grid>
            </Grid>

            <Snackbeer
                classes={classes}
                isModalResponse200= {isModalResponse200}
                setModalResponse200 = {setModalResponse200}
                messages = 'Perubahan data berhasil di simpan !'
            />
        </Paper>
    )
};

export default IdentitasSubAlamat;

const negaras = [

    {
        id : 'a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc',
        value: 'Indonesia',
        label: 'Indonesia',
    }
];

const provinces = [ 
    {
        id:'bb9b0bf9-23e9-42bf-ae7d-e098cfb2040d',
        value: "Bali",
        label: "Bali"
    },
    {
        id:'593d784b-6d8f-439d-a60a-801339777b3a',
        value: "Bangka Belitung",
        label: "Bangka Belitung"

    }, 
    {

        id:'91cfb836-afc5-46de-a969-2bc6406bcb4d',
        value: "Banten",
        label: "Banten"
    },
    {
        id:'f06081cb-6736-49d6-be1b-f61250b386c8',
        value: "Bengkulu",
        label: "Bengkulu"
    },
    {
        id:'f82912b4-7f15-44a0-9332-ca85b3073aa2',
        value: "DI Yogyakarta",
        label: "DI Yogyakarta"
    },
    {
        id:'cd2705ab-eec8-4037-b7aa-4fe700f377c6',
        value: "DKI Jakarta",
        label: "DKI Jakarta"
    },
    {
        id:'a4cd255c-f7c8-4d6e-a72e-3e45b66fd64b',
        value:  "Gorontalo",
        label:  "Gorontalo",
    },
    {
        id:'c390eefd-ea23-4d7e-8d02-e9f2fbae0437',
        value: "Jambi",
        label: "Jambi"
    },
    {
        id:'1e5cc4d9-b9f8-4b0f-8aa3-e3b2d72de334',
        value: "Jawa Barat",
        label: "Jawa Barat",
    },
    {
        id:'6c046f47-39e9-4c49-9912-9d7057e65422',
        value: "Jawa Tengah",
        label: "Jawa Tengah",
    },
    {
        id:'0abd162c-9b51-4b6f-b3b6-3e092cdbaeb5',
        value: "Jawa Timur",
        label: "Jawa Timur",
    },
    {
        id:'35c0608d-2e6b-4c06-ad58-3f5179eb97b9',
        value: "Kalimantan Barat",
        label: "Kalimantan Barat"
    },
    {
        id:'437babc3-84dc-455e-850e-41720d6e8ce9',
        value: "Kalimantan Selatan",
        label: "Kalimantan Selatan",
    },
    {
        id:'65d3ee68-794d-4847-8119-9a163a2303e4',
        value: "Kalimantan Tengah",
        label: "Kalimantan Tengah"
    },
    {
        id:'7e7d1eaa-a6af-4e6d-8a89-cac73e6eb810',
        value: "Kalimantan Timur",
        label: "Kalimantan Timur"
    },
    {
        id:'7c9b0bc7-40a3-42c7-8174-f52da3cc3537',
        value: "Kalimantan Utara",
        label: "Kalimantan Utara"
    },
    {
        id:'e3cc927f-1653-46d3-a08a-01b1103e8ce9',
        value: "Kepulauan Riau",
        label: "Kepulauan Riau",
    },
    {
        id:'e8de84a5-4e69-42ec-a046-36e94994fa24',
        value: "Lampung",
        label: "Lampung",
    },
    {
        id:'744b8776-9f87-4594-9ede-4a2888991e0e',
        value: "Maluku",
        label: "Maluku"
    },
    {
        id:'5400afc5-7dba-4532-b1c2-289b4b06bc80',
        value: "Maluku Utara",
        label: "Maluku Utara",
    },
    {
        id:'55fa136d-172b-40ad-b3e6-521ea6f0e760',
        value: "Nanggroe Aceh Darussalam (NAD)",
        label: "Nanggroe Aceh Darussalam (NAD)",
    },
    {
        id:'243f5bd0-7dfe-4330-813a-09379f8e0657',
        value: "Nusa Tenggara Barat (NTB)",
        label: "Nusa Tenggara Barat (NTB)",
    },
    {
        id:'24d01266-4642-4faf-90c4-9c495357f33c',
        value: "Nusa Tenggara Timur (NTT)",
        label: "Nusa Tenggara Timur (NTT)",
    },
    {
        id:'cd87ab3e-73a8-4244-ac90-b7e20def8ff4',
        value: "Papua",
        label: "Papua"
    },
    {
        id:'417aa349-2895-4361-a25a-95e6eb9d459d',
        value: "Papua Barat",
        label: "Papua Barat",
    },
    {
        id:'67f408f6-d1b9-4651-bf02-f119447a8617',
        value: "Riau",
        label: "Riau"
    },
    {
        id:'9172bfcc-635e-4ba3-9316-7709ee2e146c',
        value: "Sulawesi Barat",
        label: 'Sulawesi Barat'
    },
    {
        id:'eaaba712-cfe5-4872-97c3-e3ee183f813a',
        value: "Sulawesi Selatan",
        label: "Sulawesi Selatan",
    },
    {
        id:'e78825f7-ea65-4238-9c7b-209aeb648d6b',
        value: "Sulawesi Tengah",
        label: "Sulawesi Tengah",
    },
    {
        id:'7d165f82-3b7e-4038-9bb0-1c0d4ad495e8',
        value: "Sulawesi Tenggara",
        label: "Sulawesi Tenggara",
    },
    {
        id:'a4750200-22e0-4805-a5b6-76d5c6ccce41',
        value: "Sulawesi Utara",
        label: "Sulawesi Utara",
    },
    {
        id:'a35875f8-6318-4a90-92aa-397cd1c670c3',
        value: "Sumatera Barat",
        label: "Sumatera Barat",
    },
    {
        id:'210ee43f-747a-434e-9f6e-5eeb51cbb3d5',
        value: "Sumatera Selatan",
        label: "Sumatera Selatan",
    },
    {
        id:'d4847249-4ad6-4b16-bbcf-ced772fc10a0',
        value:"Sumatera Utara",
        value:"Sumatera Utara"
    }
];

// const cities = [
//     {
//         value: 'Jakarta Utara',
//         label: 'Jakarta Utara'
//     },
//     {
//         value: 'Jakarta Timur',
//         label: 'Jawa Timur'
//     },
//     {
//         value: 'Jakarta Barat',
//         label: 'Jakarta Barat'
//     },
//     {
//         value: 'Jakarta Selatan',
//         label: 'Jakarta Selatan'
//     }
// ];

const codePos = [
    {
        value: '16951',
        label: '16951'
    },
    {
        value: '16952',
        label: '16952'
    },
    {
        value: '17951',
        label: '17951'
    },
    {
        value: '17551',
        label: '17551'
    },

];
import React, { Component, useEffect, useState, useCallback } from "react";
import { makeStyles, createMuiTheme, withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { 
    Container, Paper, Typography, Grid, TextField, Checkbox, CircularProgress,
    FormControlLabel, Box, Button, MenuItem, Dialog, DialogTitle, DialogContent, 
    DialogContentText, DialogActions, Breadcrumbs, Link, IconButton, InputBase, Divider,
    Tooltip, List, ListItem, ListItemText, Avatar, Table, TableBody, TableRow, TableCell,
    Chip, TablePagination, TableHead, TableSortLabel, Radio, FormLabel, Snackbar, Fab
} from '@material-ui/core';

import axios from 'axios';

import { useGetHttp } from '../../../../utilities-hook/useGetHttp';
import { URL_API } from '../../../../constants/config-api';
import Snackbeer from '../../../../components/Snackbeer';

const IdentitasSubInfoTambahan = props => {

    const { classes } = props;


    const userToken = localStorage.getItem('userToken');
    const employeeId = localStorage.getItem('employee_id');

    const [ userTokenState, setUserTokenState ] = useState('');
    const [ employeeIdState, setEmployeeIdState ] = useState('');
    const [updatedAt, setUpdatedAt ] = useState('');

    useEffect(() => {

        setUserTokenState(userToken);
        setEmployeeIdState(employeeId);

        const header =  {     
            'Accept': "application/json",
            'Content-Type' : "application/json",
            'Authorization' : "bearer " + userToken,
        };
    
        axios.defaults.headers.common = header;    
            
        axios
            .get(URL_API + `/human-resource/employee/batch/${employeeId}/patch`)
            .then(function(response){
                
                console.log("Response Original : ", response);

                if(response.status == 200){

                    setUpdatedAt(response.data.data.fields.Employee.updated_at);     
                };
                
            })
            .catch(function(error){
                
                console.log("Error : ", error.response)
                
            });

        axios
            .get(URL_API + `/human-resource/employee/batch/${employeeId}`)
            .then(function(response){
                
                console.log("Response Original DETAIL EMPLOYEE : ", response)

                if(response.status == 200){

                    // setNamaDepan(response.data.data.member.first_name);
                    // setNamaBelakang(response.data.data.member.last_name);
                    // setNamaPanggilan(response.data.data.member.nickname);
            
                    // setNip(response.data.data.employee_number);        
                    // setNoHp(response.data.data.member.mobile_number_personal);
                    // setTanggalLahir(response.data.data.member.date_of_birth);
                    // setSelectedValueJenisKelamin(response.data.data.member.sex_id);
                    // setAgama(response.data.data.member.religion.name);
                    // setTempatLahir(response.data.data.member.place_of_birth)
                };
            })
            .catch(function(error){
                
                console.log("Error : ", error.response)
                
            });
       
    }, []);

    /* 
        ````````````````````
        COMPONENT DID MOUNT
        
        ````````````````````

        cita_cita: "Usahawan"
        facebook: "FB"
        gol_darah: "B"
        hobi: "MEmbaca"
        instagram: "IG"
        jumlah_anak: "12"
        kendaraan: "Helikopter"
        linkedin: "LI"
        nomor_sim: "121423432423"
        npwp: "123445555"
        status: "Menikah"
        tweeter: "TW"


    */

    const dataIdentitasInfoTambahan = localStorage.getItem('data_identitas_info_tambahan');
    const dataIdentitasInfoTambahanAfterParse = JSON.parse(dataIdentitasInfoTambahan);
    
    /* 
        ```````````
        HANDLE EDIT
        
        ```````````
    */

    const [ isTextFieldDisabled, setTextFieldDisabled ] = useState(false);
            
    const handleEdit = () => {

        setTextFieldDisabled(true);
   };

    /*
        `````````
        FORM DATA

        `````````
    */
    const [ npwp, setNpwp ] = useState('');
    const [ hobi, setHobi ] = useState('');
    const [ citaCita, setCitaCita ] = useState('');
    const [ kendaraan, setKendaraan ] = useState({

        name: ''
    });
    const [ nomorSim , setNomorSim ] = useState('');
    const [ status , setStatus ] = useState({
        name: ''
    });
    const [ jumlahAnak , setJumlahAnak ] = useState('');
    const [ golDarah , setGolDarah ] = useState('');

    const [ facebook , setFacebook ] = useState('');
    const [ tweeter , setTweeter ] = useState('');
    const [ instagram , setInstagram ] = useState('');
    const [ linkedin , setLinkedin ] = useState('');

    const handleChangeNPWP = (e) => setNpwp(e.target.value);
    const handleChangeHobi = (e) => setHobi(e.target.value);
    const handleChangeCitaCita = (e) => setCitaCita(e.target.value);

    const handleChangeKendaraan = name => event => {

        setKendaraan({ ...kendaraan, [name]: event.target.value });
    };

    const handleChangeNomorSIM = (e) => setNomorSim(e.target.value);

    // const handleChangeStatus = (e) => setStatus(e.target.value);
    const handleChangeStatus = name => event => {

        setStatus({ ...status, [name]: event.target.value });
    };

    const handleChangeJumlahAnak = (e) => setJumlahAnak(e.target.value);
    const handleChangeGolDarah = (e) => setGolDarah(e.target.value);

    const handleChangeFacebook = (e) => setFacebook(e.target.value);
    const handleChangeTweeter = (e) => setTweeter(e.target.value);
    const handleChangeInstagram = (e) => setInstagram(e.target.value);
    const handleChangeLinkedin = (e) => setLinkedin(e.target.value);

    /*
        `````````````````
        HANDLE INFO ERROR

        `````````````````
    */
    const [ infoError, setInfoError ] = useState(false);


    /*
        ```````````````````
        HANDLE RESPONSE 200
        
        ```````````````````
    */
    const [ isModalResponse200, setModalResponse200 ] = useState(false);

    const handleSimpanPerubahan = () => {

        setTextFieldDisabled(false);

        let data = {
            
            Employee: {
                
                tax_id_number: npwp !== '' ? npwp : dataIdentitasInfoTambahanAfterParse.npwp, //*NPWP
                hobby: hobi !== '' ? hobi : dataIdentitasInfoTambahanAfterParse.hobi,
                future_goals: citaCita !== '' ? citaCita : dataIdentitasInfoTambahanAfterParse.cita_cita,
                driving_license_number: nomorSim !== '' ? nomorSim : dataIdentitasInfoTambahanAfterParse.nomor_sim, //*SIM
                marriage_status: citaCita !== '' ? citaCita : dataIdentitasInfoTambahanAfterParse.cita_cita,
                num_of_children: jumlahAnak !== '' ? jumlahAnak : dataIdentitasInfoTambahanAfterParse.jumlah_anak,
                social_media_account_fb: facebook !== '' ? facebook : dataIdentitasInfoTambahanAfterParse.facebook,
                social_media_account_twitter: tweeter !== '' ? tweeter : dataIdentitasInfoTambahanAfterParse.tweeter,
                social_media_account_ig: instagram !== '' ? instagram : dataIdentitasInfoTambahanAfterParse.instagram ,
                vehicle: kendaraan.name !== '' ? kendaraan.name : "", //*KENDARAAN BELUM ADA DARI BE
                // golDarah: '', //*GOLONGAN DARAH BELUM ADA DARI BE
                // social_media_account_linkedin: '' //*BELUM DI PASANG DI FE
                updated_at: updatedAt
            },
            _method: 'patch'
        };

        console.log("Simpan Perubahan ... : ", data);
    
        if(userTokenState !== undefined){
            
            const header =  {       
                'Accept': "application/json",
                'Content-Type' : "application/json",
                'Authorization' : "bearer " + userTokenState,
            };

            axios.defaults.headers.common = header;    

            axios
                .post(URL_API + `/human-resource/employee/${employeeIdState}`, data)
                .then(function(response){

                    console.log("Response Original : ", response)

                    if(response.status == 200 ){

                        setModalResponse200(true);

                        if(response.data.data !== undefined){
                        
                        };
                    };
                })
                .catch(function(error){
                    
                    alert('Whoops something went wrong !');
                    console.log("Error : ", error.response)
                    
                })

        } else { console.log("No Access Token available!")};

    };


    return (

        <div>
        <Paper elevation={1} className={classes.paperInfoDasar} square={true} style={{marginRight: 16}}> 
            <br />
            <Grid container>    
                <Grid item sm={10}>
                    <Typography variant='subtitle1' className={classes.titleHeader}>
                        <b>Informasi Tambahan : </b> 
                    </Typography>          
                </Grid>
                <Grid item sm={2}>
                    <IconButton
                        onClick={handleEdit}                        
                    >
                        <i className='material-icons'>
                            edit
                        </i>
                    </IconButton>
                </Grid>
            </Grid>

            <List className={classes.list}>
                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>NPWP</b>
                            </Typography>
                        } 
                    />
                    <TextField  
                        id="outlined-bare"
                        onChange= {handleChangeNPWP}
                        value={npwp}
                        placeholder={dataIdentitasInfoTambahanAfterParse !== null ? dataIdentitasInfoTambahanAfterParse.npwp : ''}
                        className={classes.textField}
                        inputProps={{className: classes.titleTextField}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}
                    />
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Hobi</b>
                            </Typography>
                        } 
                    />
                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeHobi}
                        value={hobi}
                        placeholder={dataIdentitasInfoTambahanAfterParse !== null ? dataIdentitasInfoTambahanAfterParse.hobi : ''}
                        className={classes.textField}
                        inputProps={{className: classes.titleTextField}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}                        
                    />
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Cita- cita</b>
                            </Typography>
                        } 
                    />
                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeCitaCita}
                        value={citaCita}
                        placeholder={dataIdentitasInfoTambahanAfterParse !== null ? dataIdentitasInfoTambahanAfterParse.cita_cita : ''}
                        className={classes.textField}
                        inputProps={{className: classes.titleTextField}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}                                                
                    />
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Kendaraan</b>
                            </Typography>
                        } 
                    />

                    {
                        isTextFieldDisabled !== true ? (

                            <TextField  
                                id="outlined-bare"
                                value={dataIdentitasInfoTambahanAfterParse !== null ? dataIdentitasInfoTambahanAfterParse.kendaraan : ''}
                                className={classes.textField}
                                inputProps={{className: classes.title}} 
                                disabled
                            />

                        ) : (

                            <TextField
                                id="outlined-select-currency"
                                select
                                // label="Pilih Negara : "
                                className={classes.textField}
                                value={kendaraan.name}
                                onChange={handleChangeKendaraan('name')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                variant="outlined"
                                error={infoError == true && kendaraan.name == '' ? true : false}
                                helperText={infoError == true && kendaraan.name == '' ? "Wajib di isi" : ' '}
                            >
                                {
                                    transportations.map (

                                        option => (
                                            <MenuItem key={option.value} value={option.id}>
                                                {option.label}
                                            </MenuItem>
                                        )
                                    )
                                }

                            </TextField>
                        )
                    }
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Nomor SIM</b>
                            </Typography>
                        } 
                    />
                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeNomorSIM}
                        value={nomorSim}
                        placeholder={dataIdentitasInfoTambahanAfterParse !== null ? dataIdentitasInfoTambahanAfterParse.nomor_sim : ''} 
                        className={classes.textField}
                        inputProps={{className: classes.titleTextField}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}                                                                        
                    />
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Status</b>
                            </Typography>
                        } 
                    />

                    {
                        isTextFieldDisabled !== true ? (

                            <TextField  
                                id="outlined-bare"
                                value={ dataIdentitasInfoTambahanAfterParse !== null ? dataIdentitasInfoTambahanAfterParse.status.value : ''}
                                className={classes.textField}
                                inputProps={{className: classes.title}} 
                                disabled
                            />

                        ) : (

                            <TextField
                                id="outlined-select-provinsi"
                                select
                                // label="Pilih Negara : "
                                className={classes.textField}
                                value={status.name}
                                onChange={handleChangeStatus('name')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                variant="outlined"
                            >
                                {
                                    marriageStatus.map (

                                        option => (
                                            <MenuItem key={option.value} value={option}>
                                                {option.label}
                                            </MenuItem>
                                        )
                                    )
                                }

                        </TextField>

                        )
                    }
                    
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Jumlah Anak</b>
                            </Typography>
                        } 
                    />
                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeJumlahAnak}
                        value={jumlahAnak}
                        placeholder={dataIdentitasInfoTambahanAfterParse !== null ? dataIdentitasInfoTambahanAfterParse.jumlah_anak : ''}
                        className={classes.textField}
                        inputProps={{className: classes.titleTextField}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}                                                                                                
                    />
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Golongan Darah</b>
                            </Typography>
                        } 
                    />
                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeGolDarah}
                        value={golDarah}
                        placeholder={dataIdentitasInfoTambahanAfterParse !== null ? dataIdentitasInfoTambahanAfterParse.gol_darah : ''}
                        className={classes.textField}
                        inputProps={{className: classes.titleTextField}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}                                                                                                                        
                    />
                </ListItem>
            </List>
            
            <br />
            <br />
            <Typography variant='subtitle1' className={classes.titleHeader}>
                <b><i>Link</i>Sosial Media : </b> 
            </Typography>
            <List className={classes.list}>
                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Facebook</b>
                            </Typography>
                        } 
                    />
    
                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeFacebook}
                        value={facebook}
                        placeholder={dataIdentitasInfoTambahanAfterParse !== null ? dataIdentitasInfoTambahanAfterParse.facebook : ''}
                        className={classes.textField}
                        inputProps={{className: classes.titleTextField}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}                                                                                                                                                
                    />
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Tweeter</b>
                            </Typography>
                        } 
                    />
    
                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeTweeter}
                        value={tweeter}
                        placeholder={dataIdentitasInfoTambahanAfterParse !== null ? dataIdentitasInfoTambahanAfterParse.tweeter : ''}
                        className={classes.textField}
                        inputProps={{className: classes.titleTextField}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}                                                                                                                                                                        
                    />
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Instagram</b>
                            </Typography>
                        } 
                    />
    
                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeInstagram}
                        value={instagram}
                        placeholder={dataIdentitasInfoTambahanAfterParse !== null ? dataIdentitasInfoTambahanAfterParse.instagram : ''}
                        className={classes.textField}
                        inputProps={{className: classes.titleTextField}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}                                                                                                                                                                                                
                    />
                </ListItem>
            </List>
            
            <Grid container>
                <Grid item sm={12} style={{textAlign: 'right'}}>

                    {
                        isTextFieldDisabled == true ? (
                            <Button 
                                variant='contained'
                                className={classes.button}
                                style={{marginRight: 32, marginBottom: 24, marginTop: 24}}
                                onClick={handleSimpanPerubahan}
                                
                            >
                                Simpan Perubahan
                            </Button>

                        ) : null
                    }

                </Grid>
            </Grid>

            <Snackbeer
                classes={classes}
                isModalResponse200= {isModalResponse200}
                setModalResponse200 = {setModalResponse200}
                messages = 'Perubahan data berhasil di simpan !'
            />

        </Paper>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        </div>

    )

};


export default IdentitasSubInfoTambahan;



// const transportations = [
    
//     {
//         label: 'Motor',
//         value: 'Motor',
//     },
//     {
//         label: 'Mobil',
//         value: 'Mobil'
//     },
//     {
//         label: 'Helikopter',
//         value: 'Helikopter'
//     },
//     {
//         label: 'Sepeda',
//         value: 'Sepeda'
//     },
//     {
//         label: 'Lain- lain',
//         value: 'Lain- lain'
//     },
    
// ];

const transportations = [
    
    {
        id: '3da8b1ea-88e7-45e4-9a66-5aa1adc77425',
        label: 'Car',
        value: 'Car',
    },
    {
        id: '3dbeb49e-de45-459d-9fd5-c83b42a4b318',
        label: 'Motorcycle',
        value: 'Motorcycle'
    }
];

const marriageStatus = [ 
    {
        id: 1, 
        value: 'Lajang',
        label: 'Lajang',
    },
    {
        id: 2, 
        value: 'Menikah',
        label: 'Menikah',
    },
    {
        id: 3, 
        value: 'Duda / Janda',
        label: 'Duda / Janda',
    }
];
import React, { useState, useContext, Fragment } from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableHead,
  Checkbox,
  Button,
} from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

import { navigate } from "hookrouter";

import ContextGoal from "../../Context/ContextGoal";
import { styles } from "../../Style/StyleGoal";

import axiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

import DialogCreate from "../../Components/DialogCreate";
import ListCardItemGoal from "./../../Components/ListCardItemGoal";
import PictEmptyStateGoal from "../../Components/PictEmptyStateGoal";

import { ToGoalDetail } from "../../../../../constants/config-redirect-url";
import DialogDeleteMultiple from "../../Components/DialogDeleteMultiple";
import PaginationGoal from "../../Components/PaginationGoal";

const ListStatusGoalApproved = () => {
  const useStyle = makeStyles(styles);
  const context = useContext(ContextGoal);
  const classes = useStyle();

  const [selected, setSelected] = useState([]);
  const [checkValidDelete, setCheckValidDelete] = useState([]);
  const [isOpenDialogDeleteMultiple, setOpenDialogDeleteMultiple] = useState(
    false
  );

  /*  
        ``````````````````
        HANDLE GOAL DETAIL

        ``````````````````
    */
  const handleDetail = (e, data) => {
    e.preventDefault();
    console.log(data);

    // localStorage.setItem("goal_detail", JSON.stringify(data));
    // Redirect(ToGoalDetail);
    // navigate(ToGoalDetail, false, { id: data.id });
  };

  const handleGetValidGoalDelete = () => {
    return Promise.all(
      selected.length > 0 &&
        selected.map((item, index) => {
          const params = new URLSearchParams();
          params.append(`Goal[${index}][id]`, item);

          return axiosConfig.delete(
            `${URL_API}/swd/goal/batch?validateOnly=true`,
            { data: params }
          );
        })
    )
      .then((response) => {
        // console.log("ORI Response DEL", response);

        setCheckValidDelete(response);
        setOpenDialogDeleteMultiple(true);
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  };

  /*
        ==============================================
        HANDLE PAGINATION //*Will be to global state
        ==============================================
    */

  const handleChangePage = (event, newPage) => {
    context.setLoaderForPaging(true);
    context.setPage(newPage);
    context.setOffset((parseInt(newPage + 1) - 1) * context.rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    event.preventDefault();
    context.setLoaderForPaging(true);

    // console.log("Rows perpage : ", event.target.value);
    context.setRowsPerPage(parseInt(event.target.value, 10));
    context.setPage(0);
  };

  // Length Of Collection Goal
  const itemCount = context.collectionGoalList.length;

  /* =========================
      selected ID
      ============================= */
  const isSelected = (id) => selected && selected.indexOf(id) !== -1;

  /* =========================
      selected All List Item
      ============================= */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = context.collectionGoalList.map((n) => n.id);
      setSelected(newSelecteds);
      // console.log("selAll", newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <Fragment>
      <Grid container style={{ backgroundColor: "transparent" }}>
        <Box
          style={{
            marginLeft: 42,
            marginTop: -42,
            display: "flex",
            flexDirection: "row",
            // justifyContent: "center",
            alignItems: "center",
          }}>
          <Checkbox
            indeterminate={selected.length > 0 && selected.length < itemCount}
            checked={itemCount > 0 && selected.length === itemCount}
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
          <Typography
            color="inherit"
            variant="subtitle1"
            className={classes.title}>
            <b>{selected.length}</b> Goal ditandai
          </Typography>
        </Box>
        {selected.length > 0 && (
          <Box style={{ marginLeft: 24, marginTop: -42 }}>
            <Button
              onClick={handleGetValidGoalDelete}
              disableElevation
              variant="contained"
              className={classes.button5}
              startIcon={<DeleteRoundedIcon />}
              style={{ paddingTop: 8, paddingBottom: 8 }}>
              <b>Delete Multiple</b>
            </Button>
          </Box>
        )}
        <Grid item xs={12}>
          {context.loader ? (
            <Grid
              container
              spacing={0}
              direction="row"
              justify="center"
              alignItems="center">
              <CircularProgress
                size={32}
                style={{ marginTop: 72, color: "red" }}
              />
            </Grid>
          ) : (
            <Table
              className={classes.table}
              aria-label="sticky table"
              // stickyHeader
            >
              <TableHead></TableHead>
              <TableBody>
                {
                  // find.length > 0 && find.map((item,i) => {
                  context.collectionGoalList.length > 0 &&
                    context.collectionGoalList.map((item, i) => {
                      return (
                        <ListCardItemGoal
                          key={i}
                          classes={classes}
                          item={item}
                          selected={selected}
                          setSelected={setSelected}
                          handleDetail={handleDetail}
                          userTokenState={context.userTokenStateGlobal}
                          isSelected={isSelected}
                        />
                      );

                      // }
                    })
                }
              </TableBody>
            </Table>
          )}

          {context.collectionGoalList.length == 0 && context.loader == false && (
            // find.length === 0 && context.loader == false && (

            <Paper
              elevation={1}
              className={classes.paperList}
              style={{ marginTop: 8 }}>
              <Grid container>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ color: "grey", marginTop: 16, marginBottom: 16 }}>
                    <PictEmptyStateGoal />
                    <b>
                      Tidak ada <i>Approved Goal </i> yang dapat di tampilkan
                      saat ini ;(
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          )}

          {context.collectionGoalList.length > 0 &&
            context.dataTabActive.id !== 2 && ( //*Bukan YOU maka muncul
              <PaginationGoal
                theme={theme}
                classes={classes}
                lengthGoalCount={context.lengthGoalCount}
                rowsPerPage={context.rowsPerPage}
                page={context.page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                loaderForPaging={context.loaderForPaging}
              />
            )}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Grid>
      </Grid>

      <DialogCreate
        classes={classes}
        isModalCreate={context.isModalCreate}
        setModalCreate={context.setModalCreate}
        fotoQuery={context.fotoQuery}
        userLoginName={context.userLoginName}
        memberPositionList={context.memberPositionList}
        setCollectionGoalList={context.setCollectionGoalList}
        collectionGoalList={context.collectionGoalList}
      />

      <DialogDeleteMultiple
        classes={classes}
        isOpenDialogDeleteMultiple={isOpenDialogDeleteMultiple}
        setOpenDialogDeleteMultiple={setOpenDialogDeleteMultiple}
        selected={selected}
        checkValidDelete={checkValidDelete}
      />
    </Fragment>
  );
};

export default ListStatusGoalApproved;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
// import axios from 'axios';
// import { URL_API } from '../../../../constants/config-api';

const DialogDeletePosisi = (props) => {
  const {
    classes,
    modalDelete,
    setModalDelete,
    dataDetailDelete,
    listPosisitionForLocal,
    setListPositionForLocal,
  } = props;

  const [namaPosisi, setNamaPosisi] = useState("");

  useEffect(() => {
    if (modalDelete == true) {
      setNamaPosisi(dataDetailDelete.structure_position_title_name);
    }
  }, [modalDelete]);

  const [isDeletedWorked, setDeleteWorked] = useState(false);

  const handleDelete = () => {
    setDeleteWorked(true);

    console.log("dataDetailDelete : ", dataDetailDelete);
    // console.log('listPosisitionForLocal : ', listPosisitionForLocal);

    setListPositionForLocal(
      listPosisitionForLocal.filter((item) => item.id !== dataDetailDelete.id)
    );
    setModalDelete(false);
  };

  useEffect(() => {
    if (isDeletedWorked == true) {
      localStorage.setItem(
        "employee_data_info_position",
        JSON.stringify(listPosisitionForLocal)
      );
    }
  }, [isDeletedWorked]);

  return (
    <Dialog
      open={modalDelete}
      onClose={() => setModalDelete(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle
        id="alert-dialog-title"
        style={{ textAlign: "left" }}></DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <DialogContentText id="alert-dialog-description">
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}>
            <b>
              Apakah Anda yakin ingin menghapus <i>{namaPosisi}</i> ?
            </b>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ alignItems: "center", justifyContent: "center" }}>
        <Button
          onClick={handleDelete}
          variant="contained"
          className={classes.buttonModalDelete}
          // fullWidth
        >
          Yakin
        </Button>
      </DialogActions>
      <br />
    </Dialog>
  );
};

export default DialogDeletePosisi;

import React, { useState, useContext } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ContextReports from "../../../../context/ContextReports";
import {
  GET_DATA_TABLE,
  GET_FIELD_PRESET,
  SET_PAGINATING,
  TOGGLE_DRAWER_CHART,
  // selected summary
  HIT_GROUP_BY,
  GET_BAR_CHART,
  GET_PIE_CHART,
  GET_AVG_COMPANY,
  HIT_UNIT,
} from "../../../../context/types";

import handleError from "../../../global/handleError";
import AxiosConfig from "../../../../../../constants/config-axios";
import { URL_API } from "../../../../../../constants/config-api";

import ListItemGroupBy from "./sub-components/ListItemGroupBy";
import DialogError from "../../../../../../components/DialogError";

const ConfigGroupBy = ({ classes }) => {
  const {
    summaryState,
    tableSmrState,
    summaryDispatch,
    tableSmrDispatch,
    reportDispatch,
    barDispatch,
    pieDispatch,
  } = useContext(ContextReports);

  // =================== State ===================
  const [anchorGroup, setAnchorGroup] = useState(null);
  const [group, setGroup] = useState(summaryState.selectedGroup);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  // =================== Load List From Store ===================
  const groupBy = summaryState.groupBy.length > 0 ? summaryState.groupBy : [];
  // const groupBy2 = groupBy.filter((el) => el.field !== listGroupBy[0]);

  // =================== METHODS ===================

  const handleShowAnchorGroup = (event) => {
    setAnchorGroup(event.currentTarget);
  };
  const handleCloseAnchorGroup = () => {
    setAnchorGroup(null);
  };

  const handleChangeGroup = (data) => {
    // console.log("GRP", data);
    summaryDispatch({
      type: HIT_GROUP_BY,
      payload: { selectedGroup: data },
    });
    setGroup(data);

    if (data.field !== "structure_unit_name") {
      summaryDispatch({
        type: HIT_UNIT,
        payload: {
          selectedUnit: {
            created_at: "",
            created_by: "",
            deleted_at: null,
            deleted_by: null,
            id: "",
            name: "",
            selected: false,
            self: { rel: null },
            updated_at: "",
            updated_by: "",
          },
        },
      });
    }

    handleCloseAnchorGroup();

    if (data.field !== "structure_unit_name") {
      // ~~~~~~~~~~~ Data Pagination From Context ~~~~~~~~~~~
      const pagination = tableSmrState.paginating;
      const currentPage = pagination.currentPage;
      const perPage = pagination.perPage;

      // Convet achievement
      const calMethodGoal =
        summaryState.selectedAchievements.length > 0
          ? summaryState.selectedAchievements.map((item) => {
              return {
                formula: item,
              };
            })
          : [];
      const calMethodMa =
        summaryState.selectedAchievementsMa.length > 0
          ? summaryState.selectedAchievementsMa.map((item) => {
              return {
                formula: item,
              };
            })
          : [];

      const achievementGoal = {
        field: "goal",
        cal_method: calMethodGoal,
      };
      const achievementMa = {
        field: "ma",
        cal_method: calMethodMa,
      };

      const refGoal = [].concat(
        achievementGoal.cal_method.length > 0 ? achievementGoal : []
      );
      const refMa = [].concat(
        achievementMa.cal_method.length > 0 ? achievementMa : []
      );

      const newAchievement = [...refGoal, ...refMa];

      // ~~~~~~~~~~~ Data POST Summary ~~~~~~~~~~~
      const dataSummary = {
        name: "Table",
        platform: "web",
        report_format: "table",
        filter_by: {
          entity: [],
          date_range: summaryState.dateRange.code,
          user: summaryState.selectedUsers,
          unit: summaryState.selectedUnits,
          status: summaryState.selectedStatus,
          achievement: newAchievement,
        },
        entities: summaryState.selectedEntities,
        group_by: [data.field],
        group_by_calculation: summaryState.selectedCalc.code,
        order_by: [summaryState.selectedOrder],
        primary_column: summaryState.selectedFields.code,
        unit_type_id: summaryState.selectedUnit.id,
        limit_count_number: 0,
        week_start: "monday",
        date_format: "d m Y",
        summary_only: false,
      };

      tableSmrDispatch({
        type: GET_DATA_TABLE,
        payload: {
          dataTable: [],
          loading: true,
        },
      });

      // ================= GET INITIAL DATA TABLE SUMMARY =================
      AxiosConfig.post(
        `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
        dataSummary
      )
        .then((res) => {
          const result = res.data.data;
          // console.log("RES SUMMARY TABLE", result);

          const paginating = {
            currentPage: result.results.current_page,
            perPage: result.results.per_page,
            lastPage: result.results.last_page,
            totalRecords: result.results.total,
          };

          if (res.status === 200) {
            tableSmrDispatch({
              type: GET_FIELD_PRESET,
              payload: { fieldPreset: result.settings.field_preset },
            });

            setTimeout(() => {
              tableSmrDispatch({
                type: GET_DATA_TABLE,
                payload: {
                  dataTable: result.results.data,
                  loading: false,
                },
              });
            }, 1000);

            tableSmrDispatch({
              type: SET_PAGINATING,
              payload: { paginating },
            });
          }
        })
        .catch((error) => {
          console.log("Error : ", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);

          tableSmrDispatch({
            type: GET_DATA_TABLE,
            payload: {
              dataTable: [],
              loading: false,
            },
          });
        });

      //============= Datat For Ovaerge Company =============
      const dataAvg = {
        ...dataSummary,
        report_format: "summary",
      };

      // ================= GET INITIAL DATA BAR CHART SUMMARY =================
      AxiosConfig.post(
        `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
        dataAvg
      )
        .then((res) => {
          const result = res.data.data;
          // console.log("RES SUMMARY BAR", result);

          if (res.status === 200) {
            summaryDispatch({
              type: GET_AVG_COMPANY,
              payload: {
                averages: result.results,
              },
            });
          }
        })
        .catch((error) => {
          console.log("Error : ", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });

      //============= Datat For Bar Chart =============
      const dataBar = { ...dataSummary, report_format: "bar_chart" };

      barDispatch({
        type: GET_BAR_CHART,
        payload: {
          barData: [],
          barPreset: [],
          loading: true,
        },
      });

      // ================= GET INITIAL DATA BAR CHART SUMMARY =================
      AxiosConfig.post(
        `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
        dataBar
      )
        .then((res) => {
          const result = res.data.data;
          // console.log("RES SUMMARY BAR", result);

          if (res.status === 200) {
            barDispatch({
              type: GET_BAR_CHART,
              payload: {
                barData: result.results.data,
                barPreset: result.settings.field_preset,
                loading: false,
              },
            });
          }
        })
        .catch((error) => {
          console.log("Error : ", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);

          barDispatch({
            type: GET_BAR_CHART,
            payload: {
              barData: [],
              barPreset: [],
              loading: true,
            },
          });
        });

      //============= Datat For Pie Chart =============
      const dataPie = {
        ...dataSummary,
        report_format: "pie_chart",
        order_by: [],
      };

      pieDispatch({
        type: GET_PIE_CHART,
        payload: {
          pieDataGoal: [],
          pieDataMa: [],
          piePreset: [],
          loading: true,
        },
      });

      // console.log("dataPie", dataPie);

      // ================= GET INITIAL DATA BAR CHART SUMMARY =================
      AxiosConfig.post(
        `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
        dataPie
      )
        .then((res) => {
          const result = res.data.data;
          // console.log("RES SUMMARY BAR", result);
          const pieGoal = result.results.filter((el) => el.type === "goal");
          const pieMa = result.results.filter((el) => el.type === "ma");

          if (res.status === 200) {
            reportDispatch({ type: TOGGLE_DRAWER_CHART, payload: false });
            pieDispatch({
              type: GET_PIE_CHART,
              payload: {
                pieDataGoal: pieGoal,
                pieDataMa: pieMa,
                piePreset: result.settings.field_preset,
                loading: false,
              },
            });
          }
        })
        .catch((error) => {
          console.log("Error : ", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);

          pieDispatch({
            type: GET_PIE_CHART,
            payload: {
              pieDataGoal: [],
              pieDataMa: [],
              piePreset: [],
              loading: false,
            },
          });
        });
    }
  };

  return (
    <>
      <Button
        size="small"
        className={classes.configBtn}
        endIcon={<ExpandMoreIcon />}
        onClick={handleShowAnchorGroup}>
        Group By {group.name}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorGroup}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
        }}
        transformOrigin={{
          vertical: "top",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorGroup)}
        onClose={handleCloseAnchorGroup}>
        {groupBy.map((item, i) => (
          <MenuItem key={i} style={{ margin: 0, padding: "0 10px" }} dense>
            <ListItemGroupBy
              classes={classes}
              item={item}
              group={group}
              onHandleGroup={handleChangeGroup}
            />
          </MenuItem>
        ))}
      </Menu>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default ConfigGroupBy;

import React, { useEffect, useContext } from "react";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { Grid, Typography, Box, Button, Container } from "@material-ui/core";

import ContextAllMA from "./context/AllMaContext";
import { theme, styles } from "./style/StyleAllMA";

import ViewAll from "./All/ViewAll";
import ViewYou from "./You/ViewYou";
import DialogCreate from "./components/DialogCreate";
import DialogExportAllMA from "./components/DialogExportAllMA";
import Snackbeer from "../../../components/Snackbeer";

const useStyles = makeStyles(styles);

function ViewAllMA() {
  const context = useContext(ContextAllMA);
  const classes = useStyles();

  // Handle Switch Tab (All & You)
  const handleChooseTab = (e, data) => {
    //   Remove Value Search in Local Storage
    localStorage.removeItem("value_search");
    context.setDataTabActive(data);
  };

  // useEffect(() => {
  //   const getMeasuredActivity = async () => {
  //     try {
  //       const response = await axiosConfig.get(
  //         `${URL_API}/swd/measured-activity/create`
  //       );
  //       console.log(response.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   getMeasuredActivity();
  // }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <Grid container>
        <Grid item sm={7} style={{ textAlign: "right" }}></Grid>
        <Grid item sm={5} style={{ textAlign: "right" }}></Grid>
      </Grid>

      {/* <Container> */}
      <Grid className={classes.layoutMarginLeftAndTop}>
        {listChooseTab.map((data, i) => (
          <Button
            style={{ marginTop: 50, marginLeft: 30 }}
            key={i}
            variant="outlined"
            onClick={(e) => handleChooseTab(e, data)}
            classes={
              context.dataTabActive.id === data.id
                ? { outlined: classes.outlinedTabActive }
                : { outlined: classes.outlinedTabNotActive }
            }>
            <Typography variant="subtitle2" className={classes.title}>
              {data.label}
            </Typography>
          </Button>
        ))}

        <Grid item sm={2} md={2}></Grid>
        <Grid item sm={8} md={8} style={{ textAlign: "right" }}></Grid>

        {context.dataTabActive.id === 1 ? (
          <Box marginTop={7.5}>
            <ViewAll />
          </Box>
        ) : (
          <Box marginTop={7.5}>
            <ViewYou />
          </Box>
        )}

        <DialogCreate
          classes={classes}
          isModalCreate={context.isModalCreate}
          setModalCreate={context.setModalCreate}
          userLoginName={context.userLoginName}
          memberPositionList={context.memberPositionList}
          setCollectionMAList={context.setCollectionMAList}
          collectionMAList={context.collectionMAList}
        />

        <Snackbeer
          setModalResponse200={context.setModalResponse200}
          isModalResponse200={context.isModalResponse200}
          messages={context.messages}
        />

        <DialogExportAllMA
          isModalExportMA={context.isModalExportMA}
          setModalExportMA={context.setModalExportMA}
        />
      </Grid>
      {/* </Container> */}
    </MuiThemeProvider>
  );
}

export default ViewAllMA;

const listChooseTab = [
  {
    id: 1,
    label: "All",
  },
  {
    id: 2,
    label: "You",
  },
];

import React, { useCallback, useEffect, useState, useRef } from "react";
import { makeStyles, createMuiTheme, withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { 
    Container, Paper, Typography, Grid, TextField, Checkbox, CircularProgress,
    FormControlLabel, Box, Button, MenuItem, Dialog, DialogTitle, DialogContent, 
    DialogContentText, DialogActions, Breadcrumbs, Link, Hidden

} from '@material-ui/core';

import axios from 'axios';

import { useGetHttp } from '../../../../utilities-hook/useGetHttp';
import { URL_API } from '../../../../constants/config-api';
import Redirect from '../../../../utilities/Redirect';
import { ToDashboard, ToCompletionCompanyStructureQuestionONE, ToCompletionCompanyStructureFormulirKosong } from '../../../../constants/config-redirect-url';

// import ConvertDataUrlToFile from '../../../../utilities/ConvertDataUrlToFile';

import DialogError from '../../../../components/DialogError';

const FormCompletionProfileGroup = props => {

    const { classes, imgSrc, nameFile, contentFile } = props;

    const textInputReff= useRef(null)

    /*
        ```````````````
        GET USER TOKEN

        ```````````````
    */
    const [ userTokenState, setUserTokenState] = useState(undefined);


    const [ updatedAt, setUpdatedAt ] = useState('');
    const [ employees, setEmployees ] = useState([]);
    const [ province, setProvince ] = useState([]);

    const [countryId, setCountryId ] = useState('a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc');

    // let updatedAt = undefined;

    useEffect(() => {

        /*
            ````````````````
            GET ACCESS TOKEN

            ````````````````
        */
        const userToken = localStorage.getItem('userToken');
        setUserTokenState(userToken)

        setTimeout(() => {
            if(textInputReff !== null){

                textInputReff.current.focus();
            };

        }, 100);


        /*
            ````````````
            GET PROVINCE

            ````````````
        */

       if(userToken !== undefined){

            const header =  {       

                'Accept': "application/json",
                'Content-Type' : "application/json",
                'Authorization' : "bearer " + userToken,
            };

            axios.defaults.headers.common = header;   

            axios
                .get(`${URL_API}/group/profile/update`)
                .then((response) => {
                    
                    console.log("Response Original: ", response)
                    if(response.data.data !== null){

                        setProvince(response.data.data.provinceCollections)
                        setUpdatedAt(response.data.data.updated_at)
                        setEmployees(response.data.data.memberCountCollections);

                        // setCountryId(countryCollections[0].)
                    }                
                    
                })
                .catch((error) => {
        
                    console.log("Error : ", error.response)
                    
                })
    
       };




    },[])

  

    /*

        `````````
        GET LABEL 

        `````````

    */    
    // const [ loading, fetchedData, setFetcedData ] = useGetHttp(URL_API + `/group/profile/update`, []);
    // console.log("Fetched Data : ", fetchedData);
    
    // const province = [];//*Change to be STATE
    // const [province, setProvince ] = useState([]);

    // let updatedAt = undefined;

    // if(fetchedData !== null && fetchedData !== undefined){

        //*
        // updatedAt = fetchedData.updated_at;

        // console.log("fetchedData : ",fetchedData)

        // setProvince(fetchedData.provinceCollections);

        //**
        // Object.getOwnPropertyNames(fetchedData.provinceOptions).forEach((val, idx, array) => {
        //     const data = {
        //         key: val,
        //         value: fetchedData.provinceOptions[val]
        //     };			
        //     province.push(data);

        // });
    // };



    /*

        ```````````````````````
        HANDLE FORM TEXT FIELD :

            - COMPANY NAME,

            - NPWP, 

            - ADDRESS,

            - VISSION & MISSION

        ```````````````````````
    */

    const [companyName, setCompanyName] = useState('');
    const handleChangeCompanyName = (e) => setCompanyName(e.target.value);

    const [npwp, setNpwp ] = useState('');
    const handleChangeNPWP = (e) => setNpwp(e.target.value);

    const [address, setAddress] = useState('');
    const handleAddress = (e) => setAddress(e.target.value);

    const [vissionMission, setVissionMission] = useState('');
    const handleVissionMission = (e) => setVissionMission(e.target.value)
        
    /*

        `````````````````````````
        Dropdown JUMLAH KARYAWAN

        `````````````````````````
    */
    const [employeeTotal, setEmployeeTotal] = useState({
        
            // employee: '1 - 10'
            employee: ''
    }); 

    const handleChangeDropdownJumlahKaryawan = name => event => {

        setEmployeeTotal({ ...employeeTotal, [name]: event.target.value });
    };  

    /*

       `````````````````````````
       Dropdown COUNTRY

       `````````````````````````

    */
        
    const [countryList, setCountryList] = useState({
    
        country: 'Indonesia'
    })  

    const handleChangeDropdownCountry = name => e => {

        setCountryList({ ...countryList, [name]: e.target.value });
      
    };  

    /*

       `````````````````````````
       Dropdown PROVINCE

       `````````````````````````

    */
    const [provinceList, setProvinceList] = useState({
    
        province: ''

    });    

    const [ provinceId, setProvinceId ] = useState('');
    
    const handleChangeDropdownProvince =  name => e => {

        setProvinceList({ ...provinceList, [name]: e.target.value });
        
        setProvinceId(e.target.value);

        setStateListCityV2({
            city: ''
        });

        setPostalCodeStateV2({
            code: ''
        })

        if(userTokenState !== undefined){

            console.log(e.target.value);

            const headers =  {
                'Accept': "application/json",
                'Content-Type' : "application/json",
                'Access-Control-Allow-Origin': '*',
                'crossorigin':true,
                'crossDomain': true,
                'Authorization': 'bearer ' + userTokenState
            };
        
            axios.defaults.headers.common = headers;
            axios
                .get(URL_API + `/group/profile/patch?options[filter][city_id][0][0]=province_id&options[filter][city_id][0][1]=eq&options[filter][city_id][0][2]=${e.target.value}`)
                .then((response) => {
                    
                    console.log("Original response : ",response);
                    
                    const listCity = [];
            
                    if(response.data.data !== undefined) {
    
                        Object.getOwnPropertyNames(response.data.data.cityOptions).forEach((val, idx, array) => {
                                const data = {
                                    key: val,
                                    value: response.data.data.cityOptions[val]
                                };			
                                listCity.push(data);
                            }
                        )
                    };  

                    setStateListCity(listCity);
                })
                .catch((error) => {
                    console.log("Error response : ",error.response);
                    
                });

        } else { console.log("Ga dapet User Token !")}
    }; 

    /*

       `````````````````````````
       Dropdown CITY

       `````````````````````````

    */
    const [stateListCity, setStateListCity ] = useState({

        city:''
    });
    
    const [ stateListCityV2, setStateListCityV2 ] = useState({ // State ini as TAMBAHAN TO SOLVE BUG !
        
        city: ''

    });

    const [ cityId, setCityId ] = useState('');

    const handleChangeDropdownCity = name => e => {
        
        setStateListCityV2({...stateListCityV2, [name]:e.target.value})
        console.log("City Selected : ", e.target.value);
        setCityId(e.target.value);

        if(userTokenState !== undefined){

            const headers =  {
                'Accept': "application/json",
                'Content-Type' : "application/json",
                'Access-Control-Allow-Origin': '*',
                'crossorigin':true,
                'crossDomain': true,
                'Authorization': 'bearer ' + userTokenState
            };
        
            axios.defaults.headers.common = headers;
            axios
                
                .get(URL_API + `/group/profile/patch?options[filter][postal_code_id][0][0]=city_id&options[filter][postal_code_id][0][1]=eq&options[filter][postal_code_id][0][2]=${e.target.value}`)
                .then((response) => {
                    
                    console.log("Original response : ",response);
                    
                    const postalCodeList = [];
            
                    if(response.data.data !== undefined) {
    
                        Object.getOwnPropertyNames(response.data.data.postalCodeOptions).forEach((val, idx, array) => {
                                const data = {
                                    key: val,
                                    value: response.data.data.postalCodeOptions[val]
                                };			
                                postalCodeList.push(data);
                            }
                        );
    
                            
                        setPostalCodeState(postalCodeList);
                        
                    };  
                })
                .catch((error) => {
                    console.log("Error response : ",error.response);
                    
                });
        };
    };
    
    /*

       `````````````````````````
       Dropdown POSTAL CODE

       `````````````````````````

    */
    const [postalCode, setPostalCodeState ] = useState({

        code:''
    });

    const [ postalCodeV2, setPostalCodeStateV2 ] = useState({ // State ini as TAMBAHAN TO SOLVE BUG !
        code: ''
    }); 

    const [postalCodeId, setpostalCodeId ] = useState(''); 
    
    
    const handleChangeDropdownPostalCode = name => e => {
        
        setPostalCodeStateV2({...postalCodeV2, [name]:e.target.value})
        console.log("Postal Code Selected : ", e.target.value);

        setpostalCodeId(e.target.value);

    };

    /*
       ```````````````````````````````
       HANDLE POST COMPLETION PROFILE

       ``````````````````````````````
    */
    const [ loader, setLoader ] = useState(false);
    const handlePOSTCompletionProfile = () => {
        
        setLoader(true);

        console.log('contentFile', contentFile);

        // const file = new Blob([contentFile[0]], { type: 'image/png' }); //*Work
        // const file = new Blob([contentFile[0]]); //*Work
        const file = new Blob([contentFile]); //*Work

        // const file = new Blob([imgSrc]); //*Work
        let dataImage = new FormData();

        dataImage.append('_method', 'put');
        
        dataImage.append('ProfileCompletion[updated_at]', updatedAt);
        dataImage.append('ProfileCompletion[name]', companyName);
        dataImage.append('ProfileCompletion[tax_id_number]', npwp);
        dataImage.append('ProfileCompletion[member_count]', employeeTotal !== undefined ? employeeTotal.employee : '1');
        dataImage.append('ProfileCompletion[vision_and_mission]', vissionMission);
        dataImage.append('ProfileCompletion[country_id]', countryId);
        if(contentFile !== undefined){

            dataImage.append('ProfileCompletion[photo_logo_icon]',file);     
        };

        dataImage.append('ProfileCompletion[province_id]', provinceId);
        dataImage.append('ProfileCompletion[city_id]', cityId);
        dataImage.append('ProfileCompletion[postal_code_id]', postalCodeId);
        dataImage.append('ProfileCompletion[detail]', address);

        // let data = { 
        //     ProfileCompletion: {
        //         updated_at: updatedAt,
        //         name: companyName,
        //         tax_id_number: npwp,
        //         member_count: employeeTotal !== undefined ? employeeTotal.employee : '1',
        //         vision_and_mission: vissionMission,
        //         country_id: countryId,
        //         photo_logo_icon: dataImage,                
        //         province_id: provinceId,
        //         city_id: cityId,
        //         postal_code_id: postalCodeId,
        //         detail: address,
        //         // photo_logo_icon: contentFile
        //     },
        //     _method: 'put'
        // };

        // dataImage.append(data);
        
        // console.log(`Data Completion Profile : `, data);

        if(userTokenState !== undefined && userTokenState !== null ){

            const header =  {       

                'Accept': "application/json",
                'Content-Type' : "multipart/form-data",
                'Authorization' : "bearer " + userTokenState,
            };
          
            axios.defaults.headers.common = header;    
      
            axios
                .post(URL_API + `/register/profile-completion`, dataImage)
                .then(function(response){
                    
                    setLoader(false);
                    console.log("Response Original Completion Profile: ", response)
      
                    if(response.status == 200 ){
      
                        // Redirect(ToCompletionCompanyStructureQuestionONE);
                        Redirect(ToCompletionCompanyStructureFormulirKosong);
                        

                    };
                })
                .catch(function(error){
                    
                    console.log("Error : ", error.response)

                    setLoader(false);

                    if(error.response !== undefined){
                                            
                        if(error.response.status === 400){

                            setErrorStatus(400);

                            if(error.response.data.info.errors !== null && error.response.data.info.errors !== undefined){
                                
                                if(error.response.data.info.errors.length > 0){

                                    setListError(error.response.data.info.errors);
                                    setOpenDialogError(true);
                                };

                                if(error.response.data.info.errors.hasOwnProperty('member_count')){
    
                                    setTextErrorInformation(error.response.data.info.errors.member_count);
                                    setOpenDialogError(true);
                                };
    
                                if(error.response.data.info.errors.hasOwnProperty('photo_logo_icon')){
    
                                    setTextErrorInformation(error.response.data.info.errors.photo_logo_icon);
                                    setOpenDialogError(true);
                                };
                            }

                        };

                        if(error.response.status === 500){

                            setOpenDialogError(true);
                            setErrorStatus(500);
                        }


                    } else {
                        
                        setOpenDialogError(true);
                        setErrorStatus(500)

                    };                    
                })
      
          } else { console.log("No Access Token available!")};
    };   

    /*
        ```````````````````
        HANDLE DIALOG ERROR        

        ```````````````````
    */
    const [ isOpenDialogError, setOpenDialogError ] = useState();
    const [ textErrorInformation, setTextErrorInformation] = useState('');
    const [ errorStatus, setErrorStatus ] = useState();
    const [ listError, setListError ] = useState([]);

    return (

        <Grid container>         
            <Grid item xs={12} style={{textAlign: 'center'}}>
                <Typography variant="h4" className={classes.title}>
                    Isi Data Perusahaan
                </Typography>
                <br />
                <br />                
            </Grid> 

            <Grid item xs ={1} sm={4} md={4} style={{textAlign: 'center'}}>                
                <Hidden only='xs'>
                    <img src={imgSrc} key={imgSrc} style={{borderRadius:'50%', width: 150, height: 150}}/> 
                </Hidden>
                
                <br />
                <br />
            </Grid>
            <Grid item xs={10} sm={5} md={5} style={{textAlign: 'left'}}>

                <Hidden smUp={true}>
                    <img src={imgSrc} key={imgSrc} style={{borderRadius:'50%', width: 150, height: 150}}/> 
                </Hidden>
                
                <Typography variant="subtitle1" className={classes.title}>
                    Nama Perusahaan
                </Typography>
                <TextField
                    inputRef={textInputReff}            
                    id="outlined-bare"
                    className={classes.textField}
                    onChange= {handleChangeCompanyName}
                    // onKeyDown={handleEnterPress}
                    variant="outlined"
                    fullWidth
                    inputProps={{className: classes.title}}
                />
                <br />
                <br />

                <Typography variant="subtitle1" className={classes.title}>
                    NPWP
                </Typography>
                <TextField
                    id="outlined-bare"
                    className={classes.textField}
                    onChange= {handleChangeNPWP}
                    // onKeyDown={handleEnterPress}
                    variant="outlined"
                    fullWidth
                    inputProps={{className: classes.title}}

                />

                <br />
                <br />
                <Typography variant="subtitle1" className={classes.title}>
                    Jumlah Karyawan
                </Typography>
                <TextField
                    id="outlined-select-currency"
                    select
                    // label="Pilih bahasa : "
                    className={classes.dropDown}
                    value={employeeTotal.employee}
                    onChange={handleChangeDropdownJumlahKaryawan('employee')}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    // helperText="Please select your currency"
                    // margin="normal"
                    variant="outlined"
                    fullWidth
                >
                    {
                        employees.map (

                            option => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            )
                        )
                    }

                </TextField>

                <br />
                <br />
                <Typography variant="subtitle1" className={classes.title}>
                    Alamat Lengkap
                </Typography>
                <TextField
                    onChange={handleAddress}
                    style={{marginTop: 0}}
                    id="outlined-multiline-static"
                    // label="Multiline"
                    multiline
                    rows="4"
                    // defaultValue="Default Value"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    inputProps={{className: classes.title}}

                />
                <br />
                <br />
                
                <TextField
                    id="outlined-select-country"
                    select
                    label="Negara"
                    className={classes.dropDown}
                    value={countryList.country}
                    onChange={handleChangeDropdownCountry('country')}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    variant="outlined"
                    fullWidth
                >
                    {
                        countries.map (

                            option => (

                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            )
                        )
                    }

                </TextField> &nbsp;
                
                <TextField
                    style={{display: 'inline-block'}}
                    id="outlined-select-province"
                    select
                    label="Provinsi"
                    className={classes.dropDown}
                    value={provinceList.province}
                    onChange={handleChangeDropdownProvince('province')}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    variant="outlined"
                    fullWidth
                >
                    {
                        province.length > 0 ? province.map (

                            (option, i) => (
                                <MenuItem key={i} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            )
                        ) : (
                            <MenuItem value="Pilih Provinsi">
                                    Pilih Provinsi : 
                            </MenuItem>
                        )
                    }

                </TextField> &nbsp; 

                <TextField
                    style={{display: 'inline-block'}}
                    id="outlined-select-city"
                    select
                    label="City"
                    className={classes.dropDown}
                    value={stateListCityV2.city}
                    onChange={handleChangeDropdownCity('city')}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    variant="outlined"
                    fullWidth
                    
                >
                    {
                        stateListCity.city !== '' ? stateListCity.map (

                            (option, i) => (
                                <MenuItem key={i} value={option.key}>
                                    {option.value}
                                </MenuItem>
                            )
                        ) : (
                            <MenuItem value="Pilih Kota">
                                    Pilih Kota : 
                            </MenuItem>
                        )
                    }

                </TextField>  &nbsp; 

                <TextField
                    style={{display: 'inline-block'}}
                    id="outlined-select-postalcode"
                    select
                    label="Kode Pos"
                    className={classes.dropDown}
                    value={postalCodeV2.code}
                    onChange={handleChangeDropdownPostalCode('code')}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    variant="outlined"
                    fullWidth
                    
                >
                    {
                        postalCode.code !== '' ? postalCode.map (
                        // listCity.city !== '' ? listCity.map (

                            (option, i) => (
                                <MenuItem key={i} value={option.key}>
                                    {option.value}
                                </MenuItem>
                            )
                        ) : (
                            <MenuItem value="Pilih Kode Pos">
                                    Pilih Kode Pos : 
                            </MenuItem>
                        )
                    }

                </TextField> 


                <br />
                <br />
                <Typography variant="subtitle1" className={classes.title}>
                    Visi & Misi
                </Typography>
                <TextField
                    onChange={handleVissionMission}
                    style={{marginTop: 0}}
                    id="outlined-multiline-static-visi"
                    // label="Multiline"
                    multiline
                    rows="4"
                    // defaultValue="Default Value"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    inputProps={{className: classes.title}}

                />

                <br />
                <br />
                <Button 
                    // onClick={() => Redirect(ToCompletionCompanyStructure)}
                    onClick={handlePOSTCompletionProfile}
                    variant='contained' 
                    size='medium' 
                    className={classes.button}
                    disabled =
                    {
                        companyName === '' || 
                        provinceList.province === '' ||
                        stateListCity.city === '' ||
                        postalCode.code === '' || 
                        employeeTotal.employee === ''
                        ? 
                        true : false
                    }
                >
                    {
                        loader === true ? (

                            <CircularProgress style={{color: 'white'}} size={16} />

                        ) : "Selanjutnya"
                    }
                    
                </Button>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </Grid>

            <Grid item xs={1} sm={3} md={3} style={{textAlign: 'center'}}>

            </Grid>

            <DialogError 
                classes = { classes }
                isOpenDialogError = { isOpenDialogError }
                setOpenDialogError = { setOpenDialogError }
                textErrorInformation = { textErrorInformation}
                errorStatus = { errorStatus }
                listError = { listError }
            />
        </Grid>
    )
}

export default FormCompletionProfileGroup;



// const employees = [
//     {
//       value: '1',
//       label: '1 - 10',
      
//     },
//     {
//       value: '11',
//       label: '11 - 50',
      
//     },
//     {
//       value: '51',
//       label: '51 - 100',
//     },
//     {
//         value: '101',
//         label: '101 - 500',
//     },
//     {
//         value: '500',
//         label: '500',
//     },
//   ];

const countries = [
    {
        value: 'Indonesia',
        label: 'Indonesia'
    }
]
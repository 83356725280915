import React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import { navigate } from "hookrouter";
import { ToLogin } from "../../../../constants/config-redirect-url";

import PictInfo from "../../../../assets/images/icon-info-24px.svg";

const DialogNoUserToken = ({
  openDialogNoUsertoken,
  setOpenDialogNoUserToken,
  classes,
}) => {
  const handleNoUserToken = () => {
    setOpenDialogNoUserToken(false);
    navigate(ToLogin);
  };

  return (
    <Dialog
      open={openDialogNoUsertoken}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <img src={PictInfo} className={classes.media} alt="info-icon" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="h6" className={classes.title}>
            Anda tidak terautentikasi !
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ alignItems: "center", justifyContent: "center" }}>
        <Button
          variant="contained"
          onClick={handleNoUserToken}
          size="small"
          className={classes.button}>
          Tutup
        </Button>
      </DialogActions>
      <br />
      <br />
    </Dialog>
  );
};

export default DialogNoUserToken;

import React from "react";
import { Box } from "@material-ui/core";

import LeftMenu from "./LeftMenu";
import RightMenu from "./RightMenu";

const NavMenu = ({ classes }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <LeftMenu classes={classes} />
      <RightMenu classes={classes} />
    </Box>
  );
};

export default NavMenu;

import React, { useState, useEffect, useContext, Fragment } from "react";
import {
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import ContextGoalDetail from "../Context/ContextGoalDetail";

import DialogError from "../../../../components/DialogError";
import Capitalize from "../../../../utilities/Capitalize";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

const DialogDeleteBuktiGRInDatabase = (props) => {
  const {
    classes,
    isOpenDialogDeleteBuktiInDB,
    setOpenDialogDeleteBuktiInDB,
    dataDetailDelete,
    setFireGoalDetail,
    listPictState,
    setListPictState,
  } = props;

  const context = useContext(ContextGoalDetail);

  const [loader, setLoader] = useState(false);

  const [name, setName] = useState("");
  const [userTokenState, setUserTokenState] = useState("");

  useEffect(() => {
    if (isOpenDialogDeleteBuktiInDB == true) {
      let userToken = localStorage.getItem("userToken");
      setUserTokenState(userToken);
      // context.setSuccessDeleteBuktiInDatabase(false);
      setFireGoalDetail(false);

      if (dataDetailDelete !== "") {
        if (dataDetailDelete.title !== undefined) {
          // console.log("Data detail delete : ", dataDetailDelete);
          setName(dataDetailDelete.title);
        } else {
          setName(dataDetailDelete.label);
        }
      }
    }
  }, [isOpenDialogDeleteBuktiInDB]);

  const handleDelete = () => {
    // setDeleteWorked(true);
    setLoader(true);

    console.log("dataDetailDelete : ", dataDetailDelete);

    if (userTokenState !== "") {
      axiosConfig
        .delete(
          URL_API +
            `/swd/goal-result/${dataDetailDelete.goal_result_id}/evidence/${dataDetailDelete.id}`,
          { timeout: 20000 }
        )
        .then(function (response) {
          setLoader(false);
          console.log("Response Original : ", response);

          if (response.status === 200) {
            context.setModalResponse200(true);
            context.setMessages("Berhasil menghapus file bukti !");

            setListPictState(
              listPictState.filter((item) => item.id !== dataDetailDelete.id)
            );

            setFireGoalDetail(true);
            context.seTriggerGR(Math.floor(Math.random() * 1000 + 1));
            setOpenDialogDeleteBuktiInDB(false);
          }
        })
        .catch(function (error) {
          setLoader(false);

          setOpenDialogDeleteBuktiInDB(false);

          if (error.response !== undefined) {
            if (error.response.status == 404) {
              setErrorStatus(404);

              if (error.response.data.info.hasOwnProperty("message")) {
                if (error.response.data.info.message !== "") {
                  setOpenDialogError(true);
                  setTextErrorInformation(
                    "Error 404: " + Capitalize(error.response.data.info.message)
                  );
                }
              }
            }
          } else if (error.code === "ECONNABORTED") {
            setErrorStatus(400);
            setOpenDialogError(true);
            setTextErrorInformation(
              `${error.message} ! Harap periksa koneksi jaringan internet Anda`
            );
          } else {
            setOpenDialogError(true);
            setTextErrorInformation("Whoops, something went wrong !");
          }

          console.log("Error : ", error.response);
          console.log("Error Code : ", error.code);
          console.log("Error Message : ", error.message);
          console.log("Error Stack : ", error.stack);
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  return (
    <Fragment>
      <Dialog
        open={isOpenDialogDeleteBuktiInDB}
        onClose={() => setOpenDialogDeleteBuktiInDB(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}></Typography>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "black" }}>
              <b>
                Apakah Anda yakin mau menghapus bukti <i>{name} </i> ?
              </b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            onClick={handleDelete}
            variant="contained"
            className={classes.buttonModalDelete}
            // fullWidth
          >
            {loader !== true ? (
              "Yakin"
            ) : (
              <CircularProgress size={16} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        textErrorInformation={textErrorInformation}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
};

// export default withStyles(styles)(DialogDeleteBuktiGRInDatabase);
export default DialogDeleteBuktiGRInDatabase;

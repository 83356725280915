import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  Fragment,
  useRef,
} from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Box,
  Button,
  IconButton,
  InputBase,
  Tabs,
  Tab,
} from "@material-ui/core";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";

import axiosConfig from "../../../../constants/config-axios";
import { useDebouncedCallback } from "use-debounce";

import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";

import ContextGoal from "../Context/ContextGoal";

import ListStatusGoalDraft from "../ComponentsStatusGoal/Draft/ListStatusGoalDraft";
import ListStatusGoalApproved from "../ComponentsStatusGoal/Approved/ListStatusGoalApproved";
import ListStatusGoalActive from "../ComponentsStatusGoal/Active/ListStatusGoalActive";
import ListStatusGoalOverdue from "../ComponentsStatusGoal/Overdue/ListStatusGoalOverdue";
import ListStatusGoalComplete from "../ComponentsStatusGoal/Complete/ListStatusGoalComplete";

import DialogBiggerPictInAllAndYou from "../Components/DialogBiggerPictInAllAndYou";
import DialogExportGoal from "../Components/DialogExportGoal";

import Snackbeer from "../../../../components/Snackbeer";

import { styles } from "../Style/StyleGoal";
import DialogError from "../../../../components/DialogError";
import { URL_API } from "../../../../constants/config-api";
import {
  STATUS_DRAFT,
  STATUS_APPROVED,
  STATUS_ACTIVE,
  STATUS_OVERDUE,
  STATUS_COMPLETED,
} from "../../../../constants/config-status-goal";

import PictREFRESH from "../../../../assets/images/Group_2725.png";

import "react-circular-progressbar/dist/styles.css";
import "../Style/custom.css";

const useStyle = makeStyles(styles);

const ViewAll = (props) => {
  const context = useContext(ContextGoal);
  const classes = useStyle();

  let inputRefSearch = useRef(null);

  /*
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */

  //*
  const userToken = localStorage.getItem("userToken");
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);

  const [userTokenState, setUserTokenState] = useState("");

  useEffect(() => {
    context.setLoader(true);

    setUserTokenState(userToken);
    if (
      statusUserLoginAfterParse !== null &&
      statusUserLoginAfterParse !== undefined
    ) {
      if (statusUserLoginAfterParse.member_first_name !== null) {
        context.setUserLoginName(
          statusUserLoginAfterParse.member_first_name +
            " " +
            statusUserLoginAfterParse.member_last_name
        );
      }

      if (statusUserLoginAfterParse.member_photo_url !== null) {
        context.setFotoQuery(statusUserLoginAfterParse.member_photo_url);
      }

      if (statusUserLoginAfterParse.member_position !== null) {
        context.setMemberPositionList(
          statusUserLoginAfterParse.member_position
        );
      }
    }

    /*
                `````````````````````````````````
                HANDLE INTERAKSI KETIKA SEARCHING
                
                `````````````````````````````````
            */
    const valueSearch = localStorage.getItem("value_search");

    if (context.dataTabActive.id === 1) {
      //*Data Tab Active ALL

      // console.log("TAB View All");

      context.setLoader(true);

      if (userToken !== undefined) {
        if (valueSearch !== null && valueSearch !== undefined) {
          context.setSearchRun(true);
          context.setValueSearchState(valueSearch);

          axiosConfig
            .get(
              URL_API +
                `/swd/goal?options[filter][search]=${valueSearch}&options[filter][status_id]=${
                  context.statusGoalId
                }&options[paging][offset]=${
                  context.offset === -10 ? 0 : context.offset
                }&options[paging][limit]=${context.rowsPerPage}`
            )
            .then(function (response) {
              context.setLoader(false);
              console.log(
                "Response Original COLLECTION GOAL DID MOUNT SEARCH : ",
                response
              );

              if (response.status === 200) {
                if (response.data.data !== null) {
                  context.setSearchRun(true);
                  context.setCollectionGoalList(response.data.data);
                  context.setLengthGoalCount(response.data.info.count);
                }
              }
            })
            .catch(function (error) {
              context.setLoader(false);
              console.log("Error : ", error.response);
            });
        } else {
          context.setSearchRun(false);
          context.setValueSearchState("");

          axiosConfig
            .get(
              URL_API +
                `/swd/goal?options[filter][status_id]=${context.statusGoalId}&options[paging][offset]=0&options[paging][limit]=10`
            )
            .then(function (response) {
              context.setLoader(false);
              console.log("Response Original COLLECTION GOAL : ", response);

              if (response.status === 200) {
                if (
                  response.data.data !== null &&
                  response.data.data !== undefined
                ) {
                  context.setCollectionGoalList(response.data.data);
                  context.setLengthGoalCount(response.data.info.count);
                }
              }
            })
            .catch(function (error) {
              context.setLoader(false);
              console.log("Error : ", error.response);

              if (error.response !== undefined) {
                if (error.response.status === 429) {
                  setErrorStatus(429);
                  setTextErrorInformation("Too Many Request !");
                  setOpenDialogError(true);
                }
              }
            });
        }
      } else {
        console.log("No Access Token available!");
      }
    }
  }, [
    context.statusGoalId,
    context.dataTabActive,
    context.isOpenSnackbeer,
    context.successComplete,
    context.successCreateGoal,
    // test add dependencies
  ]);

  /*
        ```````````````````````````
        HANDLE REFRES LIST ALL GOAL
        
        ```````````````````````````
    */
  const handleRefresh = () => {
    context.setLoader(true);

    if (userTokenState !== undefined) {
      //* GOL SELF - ONLY OWNER GOAL USER LOGIN
      axiosConfig
        .get(URL_API + `/swd/goal`)
        .then(function (response) {
          context.setLoader(false);
          console.log("Response Original : ", response);

          if (response.status == 200) {
            if (response.data.data !== null) {
              if (response.data.data.length > 0) {
                context.setCollectionGoalList(response.data.data);
              }
            }
          }
        })
        .catch(function (error) {
          context.setLoader(false);
          console.log("Error : ", error.response);
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  /*
        ````````````
        Search  GOAL

        ````````````
    */

  const [handleSearch, cancel] = useDebouncedCallback(
    useCallback((value) => {
      context.setLoader(true);

      const statusGoalId = localStorage.getItem("status_goal_id");
      console.log("Search base on Status Goal Id : ", statusGoalId);

      if (value !== "") {
        console.log("I want to know goal : ", value);
        localStorage.setItem("value_search", value);

        context.setValueSearchState(value);

        if (userToken !== undefined) {
          axiosConfig
            // .get(URL_API + `/swd/goal?options[filter][search]=${value}&options[filter][status_id]=${context.statusGoalId}`)
            .get(
              URL_API +
                `/swd/goal?options[filter][search]=${value}&options[filter][status_id]=${
                  statusGoalId !== null ? statusGoalId : 3
                }&options[paging][offset]=${
                  context.offset === -10 ? 0 : context.offset
                }&options[paging][limit]=${context.rowsPerPage}`
            )
            .then(function (response) {
              context.setLoader(false);
              console.log("Response Original Goal from search : ", response);

              if (response.status === 200) {
                if (response.data.data !== null) {
                  context.setSearchRun(true);
                  context.setCollectionGoalList(response.data.data);
                  context.setLengthGoalCount(response.data.info.count);
                }
              }
            })
            .catch(function (error) {
              context.setLoader(false);
              console.log("Error : ", error.response);
            });
        } else {
          console.log("No Access Token available!");
        }
      } else {
        console.log(`Value search ${typeof value} kosong`);
        context.setValueSearchState("");
        context.setSearchRun(false);

        localStorage.removeItem("value_search");

        axiosConfig
          .get(
            URL_API +
              `/swd/goal?options[filter][status_id]=${context.statusGoalId}&options[paging][offset]=0&options[paging][limit]=10`
          )
          .then(function (response) {
            context.setLoader(false);
            console.log("Response Original COLLECTION GOAL : ", response);

            if (response.status === 200) {
              if (
                response.data.data !== null &&
                response.data.data !== undefined
              ) {
                context.setCollectionGoalList(response.data.data);
                context.setLengthGoalCount(response.data.info.count);
              }
            }
          })
          .catch(function (error) {
            context.setLoader(false);
            console.log("Error : ", error.response);
          });
      }
    }, []),
    1000,

    { maxWait: 5000 }
  );

  /* =======================================================
    Handle Disable Create Goal depending on status code 403
  ======================================================= */
  const [disableCreateGoal, setDisableCreateGoal] = useState(false);

  useEffect(() => {
    if (userToken !== undefined) {
      axiosConfig
        .get(`${URL_API}/swd/goal/create`)
        .then((response) => {
          // console.log("Response Create Goal", response);
        })
        .catch((error) => {
          console.log("Error : ", error);
          if (error.response !== undefined) {
            if (error.response.status === 403) {
              setDisableCreateGoal(true);
            }
          }
        });
    }
  }, []);

  /*
        ````````````````````````````
        HANDLE BIGGER PICT IN DETAIL
        
        ````````````````````````````
    */
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [listPictState, setListPictState] = useState([]);

  const handleBiggerPict = () => {
    setOpenDialog(true);
    let dataFoto = [];
    dataFoto.push("my-profile/photo");

    //    console.log('fotoQueryContent : ', fotoQueryContent);
    setListPictState(dataFoto);
  };

  /*
        ``````````````
        HANDLE NEW TAB

        ``````````````
    */
  // const [valueTab, setValueTab] = useState(2);
  const handleChangeTab = (event, newValue) => {
    context.setValueTab(newValue);
  };

  useEffect(() => {
    // if (context.valueTab === 0) {
    //   context.setStatusGoalId(1); //*Draft
    //   localStorage.setItem("status_goal_id", "1");
    // }

    if (context.valueTab === 0) {
      context.setStatusGoalId(2); //*Approved
      localStorage.setItem("status_goal_id", "2");
    }

    if (context.valueTab === 1) {
      context.setStatusGoalId(3); //*Active
      localStorage.setItem("status_goal_id", "3");
    }

    if (context.valueTab === 2) {
      context.setStatusGoalId(6); //*Overdue
      localStorage.setItem("status_goal_id", "6");
    }

    if (context.valueTab === 3) {
      context.setStatusGoalId(4); //*Completed
      localStorage.setItem("status_goal_id", "4");
    }
  }, [context.valueTab]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}>
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  //*When state Paging changed
  useEffect(() => {
    if (context.loaderForPaging === true) {
      context.setLoader(true);

      const valueSearch = localStorage.getItem("value_search");

      if (valueSearch !== null) {
        axiosConfig
          .get(
            URL_API +
              `/swd/goal?options[filter][search]=${valueSearch}&options[filter][status_id]=${
                context.statusGoalId
              }&options[paging][offset]=${
                context.offset === -10 ? 0 : context.offset
              }&options[paging][limit]=${context.rowsPerPage}`
          )
          .then(function (response) {
            context.setLoaderForPaging(false);
            context.setLoader(false);
            console.log("Response Original COLLECTION GOAL : ", response);

            if (response.status === 200) {
              if (response.data.data !== null) {
                // setFind(response.data.data);
                context.setCollectionGoalList(response.data.data);
                context.setLengthGoalCount(response.data.info.count);
              }
            }
          })
          .catch(function (error) {
            context.setLoader(false);
            context.setLoaderForPaging(false);
            console.log("Error : ", error);
          });
      } else {
        axiosConfig
          .get(
            URL_API +
              `/swd/goal?options[filter][status_id]=${
                context.statusGoalId
              }&options[paging][offset]=${
                context.offset === -10 ? 0 : context.offset
              }&options[paging][limit]=${context.rowsPerPage}`
          )
          .then(function (response) {
            context.setLoaderForPaging(false);
            context.setLoader(false);
            console.log("Response Original COLLECTION GOAL : ", response);

            if (response.status === 200) {
              if (response.data.data !== null) {
                // setFind(response.data.data);
                context.setCollectionGoalList(response.data.data);
                context.setLengthGoalCount(response.data.info.count);
              }
            }
          })
          .catch(function (error) {
            context.setLoader(false);
            context.setLoaderForPaging(false);
            console.log("Error : ", error);
          });
      }
    }
  }, [
    context.offset,
    context.rowsPerPage,
    // context.dataTabActive,
    context.page,
    context.paramsFilterGoal,
    context.loaderForPaging,
    context.statusGoalId,
  ]);

  /*
        ``````````````````````````
        HANDLE CLEAR RESULT SEARCH

        ``````````````````````````
    */
  useEffect(() => {
    if (
      context.valueSearchState === "" &&
      context.isSearchRun === false &&
      context.clearSearchResult === true
    ) {
      context.setLoader(true);

      if (
        context.userTokenStateGlobal !== undefined &&
        context.userTokenStateGlobal !== null
      ) {
        axiosConfig
          .get(
            URL_API +
              `/swd/goal?options[filter][status_id]=${context.statusGoalId}&options[paging][offset]=0&options[paging][limit]=10`
          )
          .then(function (response) {
            context.setLoader(false);
            console.log("Response Original COLLECTION GOAL : ", response);

            if (response.status === 200) {
              if (
                response.data.data !== null &&
                response.data.data !== undefined
              ) {
                context.setCollectionGoalList(response.data.data);
                context.setLengthGoalCount(response.data.info.count);
              }
            }

            context.setClearSearchResult(false);
          })
          .catch(function (error) {
            context.setLoader(false);
            console.log("Error : ", error.response);

            context.setClearSearchResult(false);
          });
      }
    }
  }, [
    context.valueSearchState,
    context.isSearchRun,
    context.clearSearchResult,
  ]);

  /**
   ==================================================================================
    Show Goal Counter Status
   ==================================================================================
   */
  const [goalCounter, setGoalCounter] = useState([]);

  useEffect(() => {
    axiosConfig
      .get(`${URL_API}/swd/goal/status-count`)
      .then((response) => {
        const result = response.data.data;

        if (response.status === 200) {
          const filterDraft = result.filter((item) => item.id !== "1");
          setGoalCounter(filterDraft);
        }
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  }, [
    context.isOpenSnackbeer,
    context.successComplete,
    context.successCreateGoal,
  ]);

  /*
        ````````````````````
        HANDLE DIALOG ERRROR

        ````````````````````
    */

  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);
  const [listError, setListError] = useState([]);

  return (
    <Fragment>
      <br />
      <br />
      <Grid container style={{ backgroundColor: "transparent" }}>
        <Grid item sm={6} md={6}>
          <Tabs
            indicatorColor="primary"
            value={context.valueTab}
            onChange={handleChangeTab}
            aria-label="simple tabs example">
            {listTabs.length > 0 &&
              listTabs.map((item, i) => {
                let counter = 0;

                if (goalCounter.length > 0) {
                  if (goalCounter[i].code === item.code) {
                    counter = goalCounter[i].count;
                  }
                }

                return (
                  <Tab
                    classes={{ root: classes.widthTab }}
                    label={
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{
                          textTransform: "capitalize",
                          marginBottom: -10,
                        }}>
                        <b>{item.label}</b>
                        <span
                          style={{
                            color: "#d1354a",
                            backgroundColor: "#ffa9c1",
                            fontWeight: "700",
                            paddingLeft: 4,
                            paddingRight: 4,
                            fontSize: 14,
                            marginLeft: 5,
                            borderRadius: 5,
                          }}>
                          {counter}
                        </span>
                      </Typography>
                    }
                    {...a11yProps(i)}
                  />
                );
              })}
          </Tabs>
        </Grid>

        <Grid item sm={3} md={3} style={{ textAlign: "right" }}>
          <IconButton style={{ padding: 4 }} onClick={handleRefresh}>
            <img src={PictREFRESH} style={{ width: 17, height: 17 }} alt="." />
          </IconButton>
          &nbsp;&nbsp;
          <IconButton style={{ padding: 4 }}>
            <ViewHeadlineIcon style={{ color: "#4aa9fb" }} />
          </IconButton>
          &nbsp;&nbsp;
          {/*                 
                    <IconButton>
                        <img src={OrganigramGoal} style={{width: 16, height: 16}} />
                    </IconButton> */}
          <Button
            onClick={() => context.setModalExportGoal(true)}
            variant="outlined"
            className={classes.buttonOutlined}
            style={{ borderWidth: 1 }}>
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "grey" }}>
              Export CSV
            </Typography>
          </Button>
        </Grid>

        <Grid item sm={3} md={3}>
          <Box display="flex">
            <Box flexGrow={1} widht="80%">
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  onChange={(e) => handleSearch(e.target.value)}
                  inputRef={inputRefSearch}
                  placeholder={
                    context.valueSearchState !== ""
                      ? context.valueSearchState
                      : "Search Goal..."
                  }
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "Search" }}
                />
              </div>
            </Box>

            <Box width="20%" marginLeft={theme.spacing(-0.8)} marginTop={0.1}>
              {context.isSearchRun === true && (
                <IconButton
                  size="small"
                  onClick={() => {
                    context.setValueSearchState("");
                    context.setSearchRun(false);
                    context.setClearSearchResult(true);
                    localStorage.removeItem("value_search");

                    setTimeout(() => {
                      inputRefSearch.current.value = "";
                    }, 200);
                  }}>
                  <CancelIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container style={{ backgroundColor: "transparent" }}>
        <Grid item xs={12} md={12} style={{ textAlign: "right" }}>
          {context.statusGoalId === 3 && ( //*Default Aktif === 3
            <Button
              // onClick={handleDelete}
              onClick={() => context.setModalCreate(true)}
              variant="contained"
              className={classes.button}
              disabled={disableCreateGoal}
              style={{ marginRight: 24, marginBottom: 16, marginTop: 16 }}>
              Buat Goal baru
            </Button>
          )}
          {context.statusGoalId === 2 && ( //*Default Approved === 2
            <Button
              // onClick={handleDelete}
              onClick={() => context.setModalCreate(true)}
              variant="contained"
              className={classes.button}
              disabled={disableCreateGoal}
              style={{ marginRight: 24, marginBottom: 16, marginTop: 16 }}>
              Buat Goal baru
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container style={{ backgroundColor: "transparent" }}>
        <Grid item xs={12} md={12}>
          {/* <TabPanel value={context.valueTab} index={0}>
            <ListStatusGoalDraft />
          </TabPanel> */}
          <TabPanel value={context.valueTab} index={0}>
            <ListStatusGoalApproved />
          </TabPanel>
          <TabPanel value={context.valueTab} index={1}>
            <ListStatusGoalActive />
          </TabPanel>
          <TabPanel value={context.valueTab} index={2}>
            <ListStatusGoalOverdue />
          </TabPanel>
          <TabPanel value={context.valueTab} index={3}>
            <ListStatusGoalComplete />
          </TabPanel>
        </Grid>
      </Grid>

      <DialogBiggerPictInAllAndYou
        isOpenDialog={isOpenDialog}
        setOpenDialog={setOpenDialog}
        listPictState={listPictState}
        userToken={userTokenState}
      />

      <DialogExportGoal
        isModalExportGoal={context.isModalExportGoal}
        setModalExportGoal={context.setModalExportGoal}
        statusGoalId={context.statusGoalId}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />

      <Snackbeer
        isModalResponse200={context.isOpenSnackbeer}
        setModalResponse200={context.setOpenSnackbeer}
        messages={context.messages}
      />
    </Fragment>
  );
};

export default ViewAll;

const listTabs = [
  {
    id: 2,
    label: "Approved",
    code: STATUS_APPROVED,
    status_id: 2,
  },

  {
    id: 3,
    label: "Active",
    code: STATUS_ACTIVE,
    status_id: 3,
  },

  {
    id: 4,
    label: "Overdue",
    code: STATUS_OVERDUE,
    status_id: 6,
  },

  {
    id: 5,
    label: "Complete",
    code: STATUS_COMPLETED,
    status_id: 4,
  },
];

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

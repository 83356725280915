import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { styles } from "../../../AM/CompanySetting/Style/StyleCompanySetting";

import icons_alert from "../../../../assets/images/icon_alert.png";
import DialogExtend from "../../CompanySetting/ManageMembership/components/DialogExtend";

const useStyle = makeStyles(styles);

const DialogLapsed = ({
  openDialogLapsed,
  setOpenDialogLapsed,
  // subscription,
  days,
  detailPckg,
}) => {
  const classes = useStyle();

  const [openDialogExtend, setOpenDialogExtend] = useState(false);

  const handleClose = () => {
    setOpenDialogLapsed(false);
  };

  return (
    <Dialog
      open={openDialogLapsed}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="center">
          <img src={icons_alert} width={30} />
          <Typography
            variant="h6"
            className={classes.title}
            style={{ color: "#F28181", marginLeft: 5 }}>
            <b>{Math.abs(days)} hari lagi!</b>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent style={{ textAlign: "center", width: 450 }}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>Saatnya Perpanjang Performatemu</b>
        </Typography>
        <br />
        <Typography variant="subtitle2" className={classes.title}>
          Anda telah memasuki masa tenggang (overdue) hingga {Math.abs(days)}{" "}
          hari kedepan. Segera lakukan perpanjangan membership untuk tetap
          berlangganan performate.id
        </Typography>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "center", marginTop: 20, marginBottom: 10 }}>
        <Button
          variant="outlined"
          className={classes.button3}
          onClick={handleClose}>
          Nanti saja
        </Button>

        <Button
          variant="outlined"
          className={classes.button2}
          onClick={() => setOpenDialogExtend(true)}>
          Perpanjang
        </Button>
      </DialogActions>

      <DialogExtend
        classes={classes}
        openDialogExtend={openDialogExtend}
        setOpenDialogExtend={setOpenDialogExtend}
        detailPckg={detailPckg}
      />
    </Dialog>
  );
};

export default DialogLapsed;

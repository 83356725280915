import React,{Component, useState, useEffect, useContext, useRef, Fragment} from 'react';
import { 

    Container, Paper, Typography, Grid, TextField, Checkbox, CircularProgress,
    FormControlLabel, Box, Button, MenuItem, Dialog, DialogTitle, DialogContent, 
    DialogContentText, DialogActions, Breadcrumbs, Link, List, ListItem, ListItemText, 
    ListItemAvatar, Avatar, IconButton, InputBase, Divider, Tooltip, Table, Icon,
    TableBody, TableRow, TableCell, TablePagination, TableSortLabel, TableHead, Menu,
    ListItemIcon, Radio, Chip, InputAdornment
    
} from '@material-ui/core';

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { lightBlue } from "@material-ui/core/colors";

import axios from 'axios';

import idLocale from "date-fns/locale/id";
import DateFnsUtils from "@date-io/date-fns";
import MomentUtils from "@date-io/moment";
import moment from 'moment';
import clsx from 'clsx';

import { DatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers";

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LinkIcon from '@material-ui/icons/Link';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import ContextCompanySetting from '../Context/ContextCompanySetting';

// import { StyledMenu, StyledMenuItem } from '../../../../components/StyledMenuDropdown';
import Snackbeer from '../../../../components/Snackbeer';
import DialogError from '../../../../components/DialogError';
// import LightTooltip from '../../../../components/LightTooltip';

import { URL_API } from '../../../../constants/config-api';
import Capitalize from '../../../../utilities/Capitalize';
// import AvatarDummy from '../../../../assets/images/Avatar_dummy.png';
// import DialogDateRange from '../../Goal/Components/DialogDateRange';

// import { ToSOTable } from '../../../../constants/config-redirect-url';
// import Redirect from '../../../../utilities/Redirect';

// import 'moment/locale/id';

// class LocalizedUtils extends MomentUtils {

//     getDatePickerHeaderText(date) {
//         return this.format(date, "DD MMMM YYYY", { locale: this.locale });
//     }
// };

const DialogCreateMA = (props) => {
    
    const { 

        classes, 
        isOpenDialogEditLocation, 
        setOpenDialogEditLocation, 

        fields

    } = props;

    const context = useContext(ContextCompanySetting);

    useEffect(() => {

        if(isOpenDialogEditLocation === true ){

            //*
            setIsFieldsCountry(true);
            setIsFieldsProvince(true);
            setIsFieldsCity(true);
            setIsFieldsPostalCode(true);

            // context.setFireUpdate(false);
            context.setUpdateLocationStorage(false);

            //*
            context.provinceCollections.filter((element) => {
            
                if(element.id === fields.province_id){

                    console.log("Provinsi : ", element)  
                    // setProvinceLocalStorage(element.name);              
                    setFieldsProvince({ province : element.name });

                    return element;    
                };
            });

            context.cityCollections.filter((element) => {
            
                if(element.id === fields.city_id){

                    console.log("City : ", element)   
                    // setCityLocalStorage(element.name)             
                    setFieldsCity({ city : element.name });
                    return element;    
                };
            });

            context.postalCodeCollections.filter((element) => {
            
                if(element.id === fields.postal_code_id){

                    console.log("Postal Code : ", element)    
                    // setPostalCodeLocalStorage(element.name)            
                    setFieldsPostalCode({ postalCode : element.name });
                    return element;    
                };
            });

            // console.log("Find Province : ", findProvince

        };

    },[isOpenDialogEditLocation ])

    /*
        ````````
        USE REFF

        ````````
    */
    let textInputReff = useRef(null);
   

    /*
        ```````````````````
        COMPONENT DID MOUNT 

        ```````````````````
    */

    /*
        ````````````````
        HANDLE SNACKBEER 

        ````````````````
    */
    const [ isModalResponse200, setModalResponse200 ] = useState(false);

    /*
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */

    const [ isOpenDialogError, setOpenDialogError ] = useState(false);
    const [ textErrorInformationValue, setTextErrorInformationValue ] = useState('Anda tidak memiliki izin mengakses halaman ini !');

    /*
        ```````
        COUNTRY

        ```````
    */
    const [isFieldsCountry, setIsFieldsCountry] = useState(true);
    const [ fieldsCountry, setFieldsCountry ] = useState({

        country: `Indonesia`
    });

    const handleChangeDropdownCountry = name => event => {

        setIsFieldsCountry(false);    
        setFieldsCountry({ ...fieldsCountry, [name]: event.target.value });    
    };  

    /*
        ````````
        PROVINCE

        ````````
    */

   const [isFieldsProvince, setIsFieldsProvince] = useState(true);
   const [ fieldsProvince, setFieldsProvince ] = useState({

       province: `-`
   });

   const handleChangeDropdownProvince = name => event => {

        setIsFieldsProvince(false);    
        setFieldsProvince({ ...fieldsProvince, [name]: event.target.value });  
        
        //*
        context.setFieldsProvinceId(event.target.value);
        
        //*
        if(context.userTokenStateGlobal !== undefined){

            const headers =  {
                
                'Accept': "application/json",
                'Content-Type' : "application/json",
                'Access-Control-Allow-Origin': '*',
                'crossorigin':true,
                'crossDomain': true,
                'Authorization': 'bearer ' + context.userTokenStateGlobal
            };
        
            axios.defaults.headers.common = headers;
            axios
                .get(URL_API + `/group/profile/update?options[filter][province_id]=${event.target.value}`)
                .then((response) => {
                    
                    console.log("Original response  : ",response);
                    context.setCityCollections(response.data.data.cityCollections);
                                  
                })
                .catch((error) => {

                    console.log("Error response : ",error.response);                    
                });

        } else { console.log("Ga dapet User Token !")}


        //*
        setFieldsPostalCode(
            {
                postalCode: "-"
            }
        )
   }; 
   
   
    /*
        ````
        CITY

        ````
    */
   const [isFieldsCity, setIsFieldsCity] = useState(true);
   const [ fieldsCity, setFieldsCity ] = useState({

       city: `-`
   });

   const handleChangeDropdownCity = name => event => {

        setIsFieldsCity(false);    
        setFieldsCity({ ...fieldsCity, [name]: event.target.value });   
        
        //*
        context.setFieldsCityId(event.target.value)

        //*
        if(context.userTokenStateGlobal !== undefined){

            const headers =  {
                
                'Accept': "application/json",
                'Content-Type' : "application/json",
                'Access-Control-Allow-Origin': '*',
                'crossorigin':true,
                'crossDomain': true,
                'Authorization': 'bearer ' + context.userTokenStateGlobal
            };
        
            axios.defaults.headers.common = headers;
            axios
                .get(URL_API + `/group/profile/update?options[filter][city_id]=${event.target.value}`)
                .then((response) => {
                    
                    console.log("Original response  : ",response);
                    context.setPostalCodeCollections(response.data.data.postalCodeCollections);
                                  
                })
                .catch((error) => {

                    console.log("Error response : ",error.response);                    
                });

        } else { console.log("Ga dapet User Token !")}
   };  

    /*
        ```````````
        POSTAL CODE

        ```````````
    */

   const [isFieldsPostalCode, setIsFieldsPostalCode] = useState(true);
   const [ fieldsPostalCode, setFieldsPostalCode ] = useState({

       postalCode: `-`
   });

   const handleChangeDropdownPostalCode = name => event => {

        setIsFieldsPostalCode(false);    
        setFieldsPostalCode({ ...fieldsPostalCode, [name]: event.target.value });    

        //*
        context.setFieldsPostalCodeId(event.target.value);
   };  

   /*
        `````````````
        HANDLE SUBMIT

        `````````````
   */
    const [ provinceLocalStorage, setProvinceLocalStorage ] = useState('');
    const [ cityLocalStorage, setCityLocalStorage ] = useState('');
    const [ postalCodeLocalStorage, setPostalCodeLocalStorage ] = useState('');

    // useEffect(() => {


    //     let dataLocation = {

    //         city: cityLocalStorage,
    //         province: provinceLocalStorage,
    //         postalCode : postalCodeLocalStorage,
    //         country: 'Indonesia'
    //     };

    //     console.log("Wew : ", dataLocation );

    //     localStorage.setItem('location_company', JSON.stringify(dataLocation)); 

    // },[provinceLocalStorage, cityLocalStorage, postalCodeLocalStorage ])

    const handleSaveEditLocation = () => {

        console.log("Save edit location to state... ");
        
        // console.log("fieldsProvinceId : ", context.fieldsProvinceId);        
        // console.log("fieldsCityId : ", context.fieldsCityId);        
        // console.log("fieldsPostalCodeId : ", context.fieldsPostalCodeId);

        let dataLocation = {

            city: '',
            province: '',
            postalCode : '',
            country: 'Indonesia'
        };

        //*
        context.provinceCollections.filter((element) => {
                    
            if(element.id === fieldsProvince.province){

                console.log("Provinsi : ", element)  

                // setProvinceLocalStorage(element.name);  
                dataLocation.province = element.name;           

                return element;    
            };
        });

        context.cityCollections.filter((element) => {

            if(element.id === fieldsCity.city){

                console.log("City : ", element)   
                // setCityLocalStorage(element.name)  
                dataLocation.city = element.name;                      
                return element;    
            };
        });

        context.postalCodeCollections.filter((element) => {

            if(element.id === fieldsPostalCode.postalCode){

                console.log("Postal Code : ", element)    
                // setPostalCodeLocalStorage(element.name) 
                dataLocation.postalCode = element.name;

                return element;    
            };
        });


        //*
        console.log("Wew : ", dataLocation );

        //*
        localStorage.setItem('location_company', JSON.stringify(dataLocation)); 

        //*
        setOpenDialogEditLocation(false);
        context.setUpdateLocationStorage(true)

    };

    return (

        <Fragment>

            <Dialog
                open={isOpenDialogEditLocation}
                onClose={() => setOpenDialogEditLocation(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                // fullWidth
            >
                <DialogTitle id="alert-dialog-title" style={{textAlign: "center"}}>
                    <Grid container direction='row' justify='flex-end' alignItems='flex-start'>
                        <IconButton 
                            size='small' 
                            onClick={() => setOpenDialogEditLocation(false)} 
                        >
                            <HighlightOffIcon style={{color: 'grey', fontSize: '17px'}} />
                        </IconButton>
                    </Grid>
                    <Typography variant='h6' className={classes.title}>
                        <b>Perbaharui Lokasi</b>
                    </Typography>
                </DialogTitle>

                <DialogContent style={{textAlign: "left"}}>

                    <Box marginTop={theme.spacing(0.1)}>
                        <Typography variant='subtitle1' className={classes.title} >
                            <b>Negara</b>
                        </Typography>
                        <TextField
                            id="outlined-bare"
                            select
                            className={classes.textField}
                            onChange={handleChangeDropdownCountry('country')}                   
                            value={fieldsCountry.country}
                            variant="outlined"
                            fullWidth
                            inputProps= {{
                                className: classes.title
                            }}
                        >       
                            {
                                isFieldsCountry === true && (

                                    <MenuItem value={fieldsCountry.country}>
                                        {`${fieldsCountry.country}`} 
                                    </MenuItem>
                                )
                            }               
                            
                            {
                                context.countryCollections.map (

                                    option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {Capitalize(option.name)}
                                        </MenuItem>
                                    )
                                )
                            }
                        </TextField>
                    </Box>

                    <Box marginTop={theme.spacing(0.5)}>
                        <Typography variant='subtitle1' className={classes.title} >
                            <b>Provinsi</b>
                        </Typography>
                        <TextField
                            id="outlined-bare"
                            select
                            className={classes.textField}
                            onChange={handleChangeDropdownProvince('province')}                   
                            value={fieldsProvince.province}
                            variant="outlined"
                            fullWidth
                            inputProps= {{
                                className: classes.title
                            }}
                        >       
                            {
                                isFieldsProvince === true && (
                                    <MenuItem value={fieldsProvince.province}>
                                        {`${fieldsProvince.province}`} 
                                    </MenuItem>
                                )
                            }               
                            
                            {
                                context.provinceCollections.map (

                                    option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {Capitalize(option.name)}
                                        </MenuItem>
                                    )
                                )
                            }
                        </TextField>
                    </Box>

                    <Box marginTop={theme.spacing(0.5)}>
                        <Typography variant='subtitle1' className={classes.title} >
                            <b>Kota</b>
                        </Typography>
                        <TextField
                            id="outlined-bare"
                            select
                            className={classes.textField}
                            onChange={handleChangeDropdownCity('city')}                   
                            value={fieldsCity.city}
                            variant="outlined"
                            fullWidth
                            inputProps= {{
                                className: classes.title
                            }}
                        >       
                            {
                                isFieldsCity === true && (
                                    <MenuItem value={fieldsCity.city}>
                                        {`${fieldsCity.city}`} 
                                    </MenuItem>
                                )
                            }               
                            
                            {
                                context.cityCollections.map (

                                    option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {Capitalize(option.name)}
                                        </MenuItem>
                                    )
                                )
                            }
                        </TextField>
                    </Box>

                    <Box marginTop={theme.spacing(0.5)}>
                        <Typography variant='subtitle1' className={classes.title} >
                            <b>Kode Pos</b>
                        </Typography>
                        <TextField
                            id="outlined-bare"
                            select
                            className={classes.textField}
                            onChange={handleChangeDropdownPostalCode('postalCode')}                   
                            value={fieldsPostalCode.postalCode}
                            variant="outlined"
                            fullWidth
                            inputProps= {{
                                className: classes.title
                            }}
                        >       
                            {
                                isFieldsPostalCode === true && (
                                    <MenuItem value={fieldsPostalCode.postalCode}>
                                        {`${fieldsPostalCode.postalCode}`} 
                                    </MenuItem>
                                )
                            }               
                            
                            {
                                context.postalCodeCollections.map (

                                    option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {Capitalize(option.name)}
                                        </MenuItem>
                                    )
                                )
                            }
                        </TextField>
                    </Box>
                    
                  
                </DialogContent>
                <DialogActions style={{alignItems: "center", justifyContent:'center'}}>
                    <Button 
                        onClick={handleSaveEditLocation}
                        variant='contained' 
                        className={classes.button}
                        fullWidth
                        // disabled={disabledButton == true ? true : false}

                    >  
                        {       
                            context.loader == true ? (

                                <CircularProgress size={20} style={{color: 'white'}} />

                            ) : '  Simpan !'
                        }
                    
                    </Button>
                </DialogActions>
                <br />

                <DialogError 
                    classes= {classes }
                    isOpenDialogError = { isOpenDialogError }
                    setOpenDialogError = { setOpenDialogError }
                    textErrorInformation = { textErrorInformationValue}
                />
                </Dialog>

                <Snackbeer 
                    classes = { classes }
                    isModalResponse200 = { isModalResponse200 }
                    setModalResponse200 = { setModalResponse200 }
                    messages = 'Berhasil memperbaharui profil perusahaan :)'
                />

        </Fragment>
    )
};

export default DialogCreateMA;

const theme = createMuiTheme({

    palette: {

        primary: {
            
            main: '#cc0707', //#cc0707, #c62828
            light: '#ff5f52',
            dark: '#8e0000',
            contrastText: '#ffffff'
        }
    },

    typography: {
        fontFamily: 'Roboto',
        textTransform: 'capitalize'
    },
    textfield: {
        width: 200
    }
  })
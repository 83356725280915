import React, { useState, useReducer, useEffect, useRef } from "react";
import axios from "axios";
// import { URL_API } from '../../../constants/config-api';

import ContextTask from "./ContextTask";

const ContextGlobalTask = (props) => {
  /*
        ````````````````
        USER TOKEN STATE

        ````````````````
    */
  const [userTokenState, setUserTokenState] = useState("");

  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    setUserTokenState(userToken);
  }, []);

  /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */
  const [isSnackbarResponse200, setSnackbarResponse200] = useState(false);
  const [messages, setMessages] = useState("");

  /*
        `````````````````````````````
        DATA DETAIL GLOBAL HORIZ ICON

        `````````````````````````````
    */
  const [dataDetailHoriz, setDataDetailHoriz] = useState({
    id: "",
    name: "",
    owner: {
      member: {
        first_name: "",
        last_name: "",
      },
    },
    target_result: "",
    status_id: "",
    owner_id: "",
  });

  const [actualResultGlobal, setActualResultGlobal] = useState(0);
  // const [ valueNoteGlobal, setValueNoteGlobal ] = useState('');

  /*
        `````````````````````````````````````````
        HANDLE SUCCESS 
            
            - DELETE TDL

            - COMPLETED

            - INCOMPLETED ,

            - EDIT TDL,

            - SET ANCHOR EL in Edit & Delete Task

        `````````````````````````````````````````
    */
  const [isSuccessDeleteTDL, setSucessDeleteTDL] = useState(false);
  const [isSuccessEditTDL, setSuccessEditTDL] = useState(false);

  const [isSuccessTaskCOMPLETED, setSuccessTaskCOMPLETED] = useState(false);
  const [isSuccessTaskINCOMPLETED, setSuccessTaskINCOMPLETED] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  // Show / Hide Completed TDL
  const [showhideCompleted, setShowhideCompleted] = useState(false);

  /*
        `````
        FADE

        `````
    */
  const [isRunFade, setRunFade] = useState(false);

  return (
    <ContextTask.Provider
      value={{
        setUserTokenState: setUserTokenState,
        userTokenState: userTokenState,

        setSnackbarResponse200: setSnackbarResponse200,
        isSnackbarResponse200: isSnackbarResponse200,

        setMessages: setMessages,
        messages: messages,

        setDataDetailHoriz: setDataDetailHoriz,
        dataDetailHoriz: dataDetailHoriz,

        setSucessDeleteTDL: setSucessDeleteTDL,
        isSuccessDeleteTDL: isSuccessDeleteTDL,

        setSuccessEditTDL: setSuccessEditTDL,
        isSuccessEditTDL: isSuccessEditTDL,

        setSuccessTaskCOMPLETED: setSuccessTaskCOMPLETED,
        isSuccessTaskCOMPLETED: isSuccessTaskCOMPLETED,

        setSuccessTaskINCOMPLETED: setSuccessTaskINCOMPLETED,
        isSuccessTaskINCOMPLETED: isSuccessTaskINCOMPLETED,

        setAnchorEl: setAnchorEl,
        anchorEl: anchorEl,

        setActualResultGlobal: setActualResultGlobal,
        actualResultGlobal: actualResultGlobal,

        // setValueNoteGlobal : setValueNoteGlobal,
        // valueNoteGlobal: valueNoteGlobal,

        setRunFade: setRunFade,
        isRunFade: isRunFade,

        showhideCompleted,
        setShowhideCompleted,
      }}>
      {props.children}
    </ContextTask.Provider>
  );
};

export default ContextGlobalTask;

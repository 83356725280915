import React, { Component, useEffect, useState, useCallback } from "react";
import { makeStyles, createMuiTheme, withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { 
    Container, Paper, Typography, Grid, TextField, Checkbox, CircularProgress,
    FormControlLabel, Box, Button, MenuItem, Dialog, DialogTitle, DialogContent, 
    DialogContentText, DialogActions, Breadcrumbs, Link, IconButton, InputBase, Divider,
    Tooltip, List, ListItem, ListItemText, Avatar, Table, TableBody, TableRow, TableCell,
    Chip, TablePagination, TableHead, TableSortLabel, Radio, FormLabel, Snackbar, Fab
} from '@material-ui/core';

import axios from 'axios';

import { useGetHttp } from '../../../../utilities-hook/useGetHttp';
import { URL_API } from '../../../../constants/config-api';
import Snackbeer from '../../../../components/Snackbeer';

const IdentitasSubInfoTambahan = props => {

    const { classes } = props;


    const userToken = localStorage.getItem('userToken');
    const employeeId = localStorage.getItem('employee_id');

    const [ userTokenState, setUserTokenState ] = useState('');
    const [ employeeIdState, setEmployeeIdState ] = useState('');
    const [updatedAt, setUpdatedAt ] = useState('');

    useEffect(() => {

        setUserTokenState(userToken);
        setEmployeeIdState(employeeId);

        const header =  {     
            'Accept': "application/json",
            'Content-Type' : "application/json",
            'Authorization' : "bearer " + userToken,
        };
    
        axios.defaults.headers.common = header;    
            
        axios
            .get(URL_API + `/human-resource/employee/batch/${employeeId}/patch`)
            .then(function(response){
                
                console.log("Response Original : ", response);

                if(response.status == 200){

                    setUpdatedAt(response.data.data.fields.Employee.updated_at);     
                };
                
            })
            .catch(function(error){
                
                console.log("Error : ", error.response)
                
            });

        axios
            .get(URL_API + `/human-resource/employee/batch/${employeeId}`)
            .then(function(response){
                
                console.log("Response Original DETAIL EMPLOYEE : ", response)

                if(response.status == 200){

                    setNpwp(response.data.data.tax_id_number);
                    setHobi(response.data.data.member.hobby);
                    setCitaCita(response.data.data.member.future_goals);
                    setKendaraan(
                        {
                            name: response.data.data.vehicleType.name
                        }
                    );

                    setNomorSim(response.data.data.member.driving_license_number);

                    setJumlahAnak(response.data.data.member.num_of_children);
                    setGolDarah(response.data.data.member.blood_type); //*GOLONGAN DARAH BELUM ADA !
                    setFacebook(response.data.data.member.social_media_account_fb);
                    setTweeter(response.data.data.member.social_media_account_twitter);
                    setInstagram(response.data.data.member.social_media_account_ig);

                    if(response.data.data.member.marriage_status_id == '1'){

                        setStatus({ name: 'Lajang'});
                    };

                    if(response.data.data.member.marriage_status_id == '2'){

                        setStatus({ name: 'Menikah'});
                    };

                    if(response.data.data.member.marriage_status_id == '3'){

                        setStatus({ name: 'Duda/ Janda'});
                    };
                    
                };
            })
            .catch(function(error){
                
                console.log("Error : ", error.response)
                
            });
       
    }, []);

    /* 
        ````````````````````
        COMPONENT DID MOUNT
        
        ````````````````````
    */

    const dataIdentitasInfoTambahan = localStorage.getItem('data_identitas_info_tambahan');
    const dataIdentitasInfoTambahanAfterParse = JSON.parse(dataIdentitasInfoTambahan);
    
    /* 
        ```````````
        HANDLE EDIT
        
        ```````````
    */

    const [ isTextFieldDisabled, setTextFieldDisabled ] = useState(false);
            
    const handleEdit = () => {

        setTextFieldDisabled(true);
   };

    /*
        `````````
        FORM DATA

        `````````
    */
    const [ npwp, setNpwp ] = useState('');
    const [ hobi, setHobi ] = useState('');
    const [ citaCita, setCitaCita ] = useState('');
    const [ kendaraan, setKendaraan ] = useState({

        name: ''
    });
    const [ nomorSim , setNomorSim ] = useState('');
    const [ status , setStatus ] = useState({
        name: ''
    });
    const [ jumlahAnak , setJumlahAnak ] = useState('');
    const [ golDarah , setGolDarah ] = useState('');

    const [ facebook , setFacebook ] = useState('');
    const [ tweeter , setTweeter ] = useState('');
    const [ instagram , setInstagram ] = useState('');
    const [ linkedin , setLinkedin ] = useState('');

    const handleChangeNPWP = (e) => setNpwp(e.target.value);
    const handleChangeHobi = (e) => setHobi(e.target.value);
    const handleChangeCitaCita = (e) => setCitaCita(e.target.value);

    const handleChangeKendaraan = name => event => {

        setKendaraan({ ...kendaraan, [name]: event.target.value });
    };

    const handleChangeNomorSIM = (e) => setNomorSim(e.target.value);

    // const handleChangeStatus = (e) => setStatus(e.target.value);
    const handleChangeStatus = name => event => {

        setStatus({ ...status, [name]: event.target.value });
    };

    const handleChangeJumlahAnak = (e) => setJumlahAnak(e.target.value);
    const handleChangeGolDarah = (e) => setGolDarah(e.target.value);

    const handleChangeFacebook = (e) => setFacebook(e.target.value);
    const handleChangeTweeter = (e) => setTweeter(e.target.value);
    const handleChangeInstagram = (e) => setInstagram(e.target.value);
    const handleChangeLinkedin = (e) => setLinkedin(e.target.value);

    /*
        `````````````````
        HANDLE INFO ERROR

        `````````````````
    */
    const [ infoError, setInfoError ] = useState(false);


    /*
        ```````````````````````
        HANDLE SIMPAN PERUBAHAN

        ````````````````````````
    */
    const [ isModalResponse200, setModalResponse200 ] = useState(false);

    const handleSimpanPerubahan = () => {

        setTextFieldDisabled(false);

        console.log('Kendaraan : ', kendaraan);
        console.log('Status Pernikahan : ', status);
        setKendaraan({name : kendaraan.name !== '' ? kendaraan.name.value : '-'})
        setStatus({name : status.name !== '' ? status.name.value : '-'});

        let data = {
            
            Employee: {
                
                tax_id_number: npwp !== '' ? npwp : '', //*NPWP
                hobby: hobi !== '' ? hobi : '',
                future_goals: citaCita !== '' ? citaCita : '',
                vehicle_type_id: kendaraan.name !== '' ? kendaraan.name.id : '', //*KENDARAAN BELUM ADA DARI BE
                driving_license_number: nomorSim !== '' ? nomorSim : '', //*SIM
                marriage_status_id: status.name !== '' ? status.name.id : '',
                num_of_children: jumlahAnak !== '' ? jumlahAnak : '',
                social_media_account_fb: facebook !== '' ? facebook : '',
                social_media_account_twitter: tweeter !== '' ? tweeter : '',
                social_media_account_ig: instagram !== '' ? instagram : '',
                blood_type: golDarah !== '' ? golDarah : '', //*GOLONGAN DARAH BELUM ADA DARI BE
                // social_media_account_linkedin: '' //*BELUM DI PASANG DI FE
                updated_at: updatedAt
            },
            _method: 'patch'
        };

        if(status.name == ''){ delete data.Employee.marriage_status; };
        if(kendaraan.name == ''){ delete data.Employee.vehicle; };
        if(golDarah == ''){ delete data.Employee.blood_type; };



        console.log("Simpan Perubahan ... : ", data);
    
        if(userTokenState !== undefined){
            
            const header =  {       
                'Accept': "application/json",
                'Content-Type' : "application/json",
                'Authorization' : "bearer " + userTokenState,
            };

            axios.defaults.headers.common = header;    

            axios
                .post(URL_API + `/human-resource/employee/${employeeIdState}`, data)
                .then(function(response){

                    console.log("Response Original : ", response)

                    if(response.status == 200 ){

                        setModalResponse200(true);

                        
                        if(response.data.data !== undefined){
                        
                        };
                    };
                })
                .catch(function(error){
                    
                    alert('Whoops something went wrong !');
                    console.log("Error : ", error.response)
                    
                })

        } else { console.log("No Access Token available!")};
    };

    return (

        <div>
        <Paper elevation={1} className={classes.paperInfoDasar} square={true} style={{marginRight: 16}}> 
            <br />
            <Grid container>    
                <Grid item sm={10}>
                    <Typography variant='subtitle1' className={classes.titleHeader}>
                        <b>Informasi Tambahan : </b> 
                    </Typography>          
                </Grid>
                <Grid item sm={2}>
                    <IconButton
                        onClick={handleEdit}                        
                    >
                        <i className='material-icons'>
                            edit
                        </i>
                    </IconButton>
                </Grid>
            </Grid>

            <List className={classes.list}>
                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>NPWP</b>
                            </Typography>
                        } 
                    />
                    <TextField  
                        id="outlined-bare"
                        onChange= {handleChangeNPWP}
                        value={npwp}
                        // placeholder={dataIdentitasInfoTambahanAfterParse.npwp}
                        className={classes.textField}
                        inputProps={{className: classes.titleText}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}
                    />
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Hobi</b>
                            </Typography>
                        } 
                    />
                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeHobi}
                        value={hobi}
                        // placeholder={dataIdentitasInfoTambahanAfterParse.hobi}
                        className={classes.textField}
                        inputProps={{className: classes.titleText}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}                        
                    />
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Cita- cita</b>
                            </Typography>
                        } 
                    />
                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeCitaCita}
                        value={citaCita}
                        // placeholder={dataIdentitasInfoTambahanAfterParse.cita_cita}
                        className={classes.textField}
                        inputProps={{className: classes.titleText}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}                                                
                    />
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Kendaraan</b>
                            </Typography>
                        } 
                    />

                    {
                        isTextFieldDisabled !== true ? (

                            <TextField  
                                id="outlined-bare"
                                // value={dataIdentitasInfoTambahanAfterParse.kendaraan}
                                value={kendaraan.name !== '' ? kendaraan.name : '-'}
                                className={classes.textField}
                                inputProps={{className: classes.title}} 
                                disabled
                            />

                        ) : (

                            <TextField
                                id="outlined-select-currency"
                                select
                                // label="Pilih Negara : "
                                className={classes.textField}
                                value={kendaraan.name}
                                onChange={handleChangeKendaraan('name')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                variant="outlined"
                                error={infoError == true && kendaraan.name == '' ? true : false}
                                helperText={infoError == true && kendaraan.name == '' ? "Wajib di isi" : ' '}
                            >
                                {
                                    transportations.map (

                                        option => (

                                            <MenuItem key={option.value} value={option}>
                                                {option.label}
                                            </MenuItem>
                                        )
                                    )
                                }

                            </TextField>
                        )
                    }
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Nomor SIM</b>
                            </Typography>
                        } 
                    />
                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeNomorSIM}
                        value={nomorSim}
                        // placeholder={dataIdentitasInfoTambahanAfterParse.nomor_sim}
                        className={classes.textField}
                        inputProps={{className: classes.titleText}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}                                                                        
                    />
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Status Pernikahan</b>
                            </Typography>
                        } 
                    />

                    {
                        isTextFieldDisabled !== true ? (

                            <TextField  
                                id="outlined-bare"
                                value={status.name !== null ? status.name : '-'}
                                // value={ dataIdentitasInfoTambahanAfterParse !== undefined ? dataIdentitasInfoTambahanAfterParse.status.value : ''}
                                className={classes.textField}
                                inputProps={{className: classes.title}} 
                                disabled
                            />

                        ) : (

                            <TextField
                                id="outlined-select-provinsi"
                                select
                                // label="Pilih Negara : "
                                className={classes.textField}
                                value={status.name}
                                onChange={handleChangeStatus('name')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                variant="outlined"
                            >
                                {
                                    marriageStatus.map (

                                        option => (
                                            <MenuItem key={option.value} value={option}>
                                                {option.label}
                                            </MenuItem>
                                        )
                                    )
                                }

                        </TextField>

                        )
                    }
                    
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Jumlah Anak</b>
                            </Typography>
                        } 
                    />
                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeJumlahAnak}
                        value={jumlahAnak}
                        // placeholder={dataIdentitasInfoTambahanAfterParse.jumlah_anak}
                        className={classes.textField}
                        inputProps={{className: classes.titleText}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}                                                                                                
                    />

                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Golongan Darah</b>
                            </Typography>
                        } 
                    />
                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeGolDarah}
                        value={golDarah !== '' ? golDarah : ''}
                        // placeholder={dataIdentitasInfoTambahanAfterParse.gol_darah}
                        className={classes.textField}
                        inputProps={{className: classes.titleText}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}                                                                                                                        
                    />
                </ListItem>
            </List>
            
            <br />
            <br />
            <Typography variant='subtitle1' className={classes.titleHeader}>
                <b><i>Link</i>Sosial Media : </b> 
            </Typography>
            <List className={classes.list}>
                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Facebook</b>
                            </Typography>
                        } 
                    />
    
                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeFacebook}
                        value={facebook}
                        className={classes.textField}
                        inputProps={{className: classes.titleText}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}                                                                                                                                                
                    />
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Tweeter</b>
                            </Typography>
                        } 
                    />
    
                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeTweeter}
                        value={tweeter}
                        // placeholder={dataIdentitasInfoTambahanAfterParse.tweeter}
                        className={classes.textField}
                        inputProps={{className: classes.titleText}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}                                                                                                                                                                        
                    />
                </ListItem>

                <ListItem style={{textAlign: 'left'}}>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Instagram</b>
                            </Typography>
                        } 
                    />
    
                    <TextField  
                        id="outlined-bare"
                        onChange={handleChangeInstagram}
                        value={instagram}
                        // placeholder={dataIdentitasInfoTambahanAfterParse.instagram}
                        className={classes.textField}
                        inputProps={{className: classes.titleText}} 
                        disabled = {isTextFieldDisabled !== true ? true : false}                                                                                                                                                                                                
                    />
                </ListItem>
            </List>
            
            <Grid container>
                <Grid item sm={12} style={{textAlign: 'right'}}>

                    {
                        isTextFieldDisabled == true ? (
                            <Button 
                                variant='contained'
                                className={classes.button}
                                style={{marginRight: 32, marginBottom: 24, marginTop: 24}}
                                onClick={handleSimpanPerubahan}
                                
                            >
                                Simpan Perubahan
                            </Button>

                        ) : null
                    }

                </Grid>
            </Grid>

            <Snackbeer
                classes={classes}
                isModalResponse200= {isModalResponse200}
                setModalResponse200 = {setModalResponse200}
                messages = 'Perubahan data berhasil di simpan !'
            />

        </Paper>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        </div>

    )

};


export default IdentitasSubInfoTambahan;



// const transportations = [
    
//     {
//         label: 'Motor',
//         value: 'Motor',
//     },
//     {
//         label: 'Mobil',
//         value: 'Mobil'
//     },
//     {
//         label: 'Helikopter',
//         value: 'Helikopter'
//     },
//     {
//         label: 'Sepeda',
//         value: 'Sepeda'
//     },
//     {
//         label: 'Lain- lain',
//         value: 'Lain- lain'
//     },
    
// ];

const transportations = [
    
    {
        id: '3da8b1ea-88e7-45e4-9a66-5aa1adc77425',
        label: 'Car',
        value: 'Car',
    },
    {
        id: '3dbeb49e-de45-459d-9fd5-c83b42a4b318',
        label: 'Motorcycle',
        value: 'Motorcycle'
    }
];

const marriageStatus = [ 
    {
        id: 1, 
        value: 'Lajang',
        label: 'Lajang',
    },
    {
        id: 2, 
        value: 'Menikah',
        label: 'Menikah',
    },
    {
        id: 3, 
        value: 'Duda / Janda',
        label: 'Duda / Janda',
    }
];

const golonganDarah = [ 
    {
        id: 'A', 
        value: 'A',
        label: 'A',
    },
    {
        id: 'AB', 
        value: 'B',
        label: 'B',
    },
    {
        id: 'B', 
        value: 'B',
        label: 'B',
    },
    {
        id: 'O', 
        value: 'O',
        label: 'O',
    }
];
import React from "react";

import PictIconGoalResultWhenEmptyState from "../../../../assets/images/Group_3044.png";

const PictEmptyStateGoalResultWithoutIconButton = (props) => {
  return (
    <img
      src={PictIconGoalResultWhenEmptyState}
      style={{ width: 20, height: 20 }}
    />
  );
};

export default PictEmptyStateGoalResultWithoutIconButton;

const handleError = (error) => {
  const listError = [];
  let errorMessage = "";
  let dialogErr = false;
  let resStatus = null;
  // error response
  if (error.response !== undefined) {
    let responseStatus = error.response.status;

    resStatus = responseStatus;
    dialogErr = true;

    if ([422, 400].includes(responseStatus)) {
      if (
        error.response.data.info.errors !== null &&
        error.response.data.info.errors !== undefined
      ) {
        if (error.response.data.info.errors.length > 0) {
          dialogErr = true;
          listError = error.response.data.info.errors;
        }
      }
    }

    if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
      if (error.response.data.hasOwnProperty("message")) {
        errorMessage =
          `Error ${responseStatus} : ` + error.response.data.message;
      }
    }
  } else {
    dialogErr = true;
    errorMessage = "Whoops, something went wrong !";
  }

  return {
    listError,
    errorMessage,
    dialogErr,
    resStatus,
  };
};

export default handleError;

import React, { Fragment } from "react";
import { Chip } from "@material-ui/core";

const PositionName = (props) => {
  const { positionName, memberPositionList } = props;

  return (
    <Fragment>
      {positionName !== "" && (
        <Chip
          label={positionName}
          style={{
            color: "white",
            fontFamily: "Roboto",
            marginTop: 8,
            marginRight: 3,
            fontSize: 12,
          }}
          size="small"
          variant="outlined"
        />
      )}

      {memberPositionList.length > 0 &&
        positionName === "" &&
        memberPositionList.map((item, i) => (
          <Chip
            key={i}
            label={item.structure_position_title_name}
            style={{
              color: "white",
              fontFamily: "Roboto",
              marginTop: 8,
              marginRight: 3,
              fontSize: 12,
            }}
            size="small"
            variant="outlined"
          />
        ))}
    </Fragment>
  );
};

export default PositionName;

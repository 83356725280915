import React, { useState, useEffect } from "react";

import { useToggle } from "react-use";

import ContextGoalDetail from "./ContextGoalDetail";

import getIDs from "../../../../utilities/getIDs";

const ContextGlobalGoalDetail = (props) => {
  /*
        ````````````````
        USER TOKEN STATE

        ````````````````
    */
  const [userTokenStateGlobal, setUserTokenStateGlobal] = useState("");
  const [loader, setLoader] = useState(false);

  const [goalId, setGoalId] = useState({
    id: null,
  });

  const [subGoalId, setSubGoalId] = useState({
    id: null,
  });

  const [listSubGoalId, setListSubGoalId] = useState([]);
  const [collectionGoalResultList, setCollectionGoalResultList] = useState([]);
  const [goalResultLength, setGoalResultLength] = useState(null);

  const [goalDetailStateGlobal, setGoalDetailStateGlobal] =
    useState(initialGoalDetail);

  /*
        ```````````````````````````````````````
        COMPONENT DIALOG CREATE DIRECT SUB GOAL

        ```````````````````````````````````````
    */

  const [timeFrameId, setTimeFrameId] = useState("");

  const [startDateRangeState, setStartDateRangeState] = useState(null);
  const [endDateRangeState, setEndDateRangeState] = useState(null);
  const [period, setPeriod] = useState({
    id: "",
    name: "",
  });

  const [periodCollections, setPeriodCollections] = useState([]);

  const [startValue, setStartValue] = useState("");
  const [targetValue, setTargetValue] = useState("");
  const [goalName, setGoalName] = useState("");
  const [goalNameLocalStorage, setGoalNameLocalStorage] = useState("");

  /*
        `````````````````````````````````````````
        HANDLE LIST AFTER SUCCESS CREATE SUB-GOAL

        `````````````````````````````````````````
    */
  const [isSuccessCreateSubGoal, setSuccessCreateSubGoal] = useState(null);
  const [isSuccessDeleteSubGoal, setSuccessDeleteSubGoal] = useState(null);
  const [isSuccessSetAsComplete, setSuccessSetAsComplete] = useState(null);
  const [isSuccessAddEvidence, setSuccessAddEvidence] = useState(null);

  const [isSubGoalFire, setSubGoalFire] = useState(false);

  useEffect(() => {
    const locationPathGoalId = window.location.pathname.split("/")[2];
    // console.log("locationId : ", locationId);

    const userToken = localStorage.getItem("userToken");
    setUserTokenStateGlobal(userToken);

    const goalDetail = localStorage.getItem("goal_detail");
    const goalDetailAfterParse = JSON.parse(goalDetail);

    if (goalDetailAfterParse !== null) {
      if (goalDetailAfterParse.id !== null) {
        if (goalDetailAfterParse.hasOwnProperty("start_date")) {
          setStartDateRangeState(goalDetailAfterParse.start_date);
        }

        if (goalDetailAfterParse.hasOwnProperty("end_date")) {
          setEndDateRangeState(goalDetailAfterParse.end_date);
        }

        if (goalDetailAfterParse.hasOwnProperty("period")) {
          if (
            goalDetailAfterParse.period !== null &&
            goalDetailAfterParse.period !== undefined
          ) {
            setPeriod(goalDetailAfterParse.period);
          }
        }

        /*
              `````````````
              TAMBAL SULAM()

              - Get ID

              `````````````
            */

        console.log("ID : ", getIDs());

        if (getIDs() === null) {
          console.log("locationPathGoalId : ", locationPathGoalId); // *Ga kepake

          // setGoalId({

          //   id: locationPathGoalId

          // })
        } else {
          setGoalId({
            id: goalDetailAfterParse.id,
          });

          setGoalNameLocalStorage(goalDetailAfterParse.name);
        }

        //*
        // setGoalDetailStateGlobal(goalDetailAfterParse);
      }
    }

    //*
    setLoader(false);
  }, []);

  /*
        ``````````````````````

        ``````````````````````
    */
  const [listCollectionSubGoal, setListCollectionSubGoal] = useState([]);
  const [nodeLevelGoal, setNodeLevelGoal] = useState(1);
  const [isOpenDialogCreateDirectSubGoal, setOpenDialogCreateDirectSubGoal] =
    useState(false);

  /*
        ```````````````````````````
        HANDLE TOGGLE MODE CURRENCY

        ```````````````````````````
    */
  const [modeRupiahOn, toggleModeRupiahOn] = useToggle(false);

  /*
            `````````````````````
            *Global State For MA

            `````````````````````
    */
  const [collectionMA, setColletionMA] = useState(listCollectionMa);
  const [loaderSpecialMa, setLoaderSpecialMa] = useState(false);

  const [isMeasuredActivityDetailShow, setMeasuredActivityDetailShow] =
    useState(true);
  const [memberPositionList, setMemberPositionList] = useState([]);

  const [isModalCreateMAResult, setModalCreateMAResult] = useState(false);
  const [collectionMaResultList, setCollectionMaResultList] = useState([]);
  const [maResultLength, setMAResultLength] = useState([]);

  const [isMaListShow, setMaListShow] = useState(false);

  const [startDatePojokKanan, setStartDatePojokKanan] = useState(null);
  const [endDatePojokKanan, setEndDatePojokKanan] = useState(null);

  /*
      ```````````````````````````````````
      HANDLE EXPORT NEW IN VIEW ALL & YOU

      ```````````````````````````````````
  */
  const [isModalExportMA, setModalExportMA] = useState(false);
  const [statusMaId, setStatusMaId] = useState(3); //Default Aktif = 3
  const [maId, setMaId] = useState(false);

  /*
        ``````````````````````````
        HANDLE UPLOAD IMAGE/ VIDEO

        ``````````````````````````
    */

  // const [listPictState, setListPictState ] = useState([]);

  const [loaderEvidance, setLoaderEvidance] = useState(false);
  const [isSuccessUploadFile, setSuccessUploadFile] = useState(false);
  const [isFireGoalDetail, setFireGoalDetail] = useState(false);
  const [triggerGR, seTriggerGR] = useState(null);

  const [isSuccesCreateGoalResult, setSuccesCreateGoalResult] = useState(false);

  // const [ isSuccessDeleteBuktiInDatabase, setSuccessDeleteBuktiInDatabase ] = useState(false);

  /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */
  const [isModalResponse200, setModalResponse200] = useState(false);
  const [messages, setMessages] = useState("");

  /*
        ````````````````````
        HANDLE SNACKBEER RED

        ````````````````````
    */
  const [isOpenSnackbeer, setOpenSnackbeer] = useState(false);

  // Paging For GR
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [offset, setOffset] = useState("");
  const [loaderForPaging, setLoaderForPaging] = useState(false);

  // Paging For MAR
  const [pageMAR, setPageMAR] = useState(0);
  const [rowsPerPageMAR, setRowsPerPageMAR] = useState(8);
  const [offsetMAR, setOffsetMAR] = useState("");
  const [loaderForPagingMAR, setLoaderForPagingMAR] = useState(false);

  // Hide Create MA Button
  const [hideCreateMABtn, setHideCreateMABtn] = useState(false);

  // Disable Add MA Result State (Previllage)
  const [disableCreateMAResult, setDisableCreateMAResult] = useState(false);

  return (
    <ContextGoalDetail.Provider
      value={{
        userTokenStateGlobal: userTokenStateGlobal,
        listCollectionSubGoal: listCollectionSubGoal,
        setListCollectionSubGoal: setListCollectionSubGoal,

        setGoalId: setGoalId,
        goalId: goalId,

        subGoalId: subGoalId,
        setSubGoalId: setSubGoalId,

        setListSubGoalId: setListSubGoalId,
        listSubGoalId: listSubGoalId,

        nodeLevelGoal: nodeLevelGoal,
        setNodeLevelGoal: setNodeLevelGoal,

        isOpenDialogCreateDirectSubGoal: isOpenDialogCreateDirectSubGoal,
        setOpenDialogCreateDirectSubGoal: setOpenDialogCreateDirectSubGoal,

        setGoalDetailStateGlobal: setGoalDetailStateGlobal,
        goalDetailStateGlobal: goalDetailStateGlobal,

        setStartDateRangeState: setStartDateRangeState,
        startDateRangeState: startDateRangeState,

        setEndDateRangeState: setEndDateRangeState,
        endDateRangeState: endDateRangeState,

        setPeriod: setPeriod,
        period: period,

        setPeriodCollections: setPeriodCollections,
        periodCollections: periodCollections,

        setStartValue: setStartValue,
        startValue: startValue,

        setTargetValue: setTargetValue,
        targetValue: targetValue,

        setGoalName: setGoalName,
        goalName: goalName,

        setGoalNameLocalStorage: setGoalNameLocalStorage,
        goalNameLocalStorage: goalNameLocalStorage,

        setSuccessCreateSubGoal: setSuccessCreateSubGoal,
        isSuccessCreateSubGoal: isSuccessCreateSubGoal,

        setSuccessDeleteSubGoal: setSuccessDeleteSubGoal,
        isSuccessDeleteSubGoal: isSuccessDeleteSubGoal,

        setSuccessSetAsComplete,
        isSuccessSetAsComplete,

        isSubGoalFire: isSubGoalFire,
        setSubGoalFire: setSubGoalFire,

        setTimeFrameId: setTimeFrameId,
        timeFrameId: timeFrameId,

        modeRupiahOn: modeRupiahOn,
        toggleModeRupiahOn: toggleModeRupiahOn,

        //*Global State For MA & MA Detail

        collectionMA: collectionMA,
        setColletionMA: setColletionMA,

        loader: loader,
        setLoader: setLoader,

        loaderSpecialMa: loaderSpecialMa,
        setLoaderSpecialMa: setLoaderSpecialMa,

        isMeasuredActivityDetailShow: isMeasuredActivityDetailShow,
        setMeasuredActivityDetailShow: setMeasuredActivityDetailShow,

        memberPositionList: memberPositionList,
        setMemberPositionList: setMemberPositionList,

        isModalCreateMAResult: isModalCreateMAResult,
        setModalCreateMAResult: setModalCreateMAResult,

        collectionGoalResultList: collectionGoalResultList,
        setCollectionGoalResultList: setCollectionGoalResultList,

        collectionMaResultList: collectionMaResultList,
        setCollectionMaResultList: setCollectionMaResultList,

        maResultLength,
        setMAResultLength,

        isMaListShow: isMaListShow,
        setMaListShow: setMaListShow,

        isModalResponse200: isModalResponse200,
        setModalResponse200: setModalResponse200,

        isOpenSnackbeer: isOpenSnackbeer,
        setOpenSnackbeer: setOpenSnackbeer,

        setLoaderEvidance: setLoaderEvidance,
        loaderEvidance: loaderEvidance,

        // listPictState : listPictState,
        // setListPictState: setListPictState,

        isSuccessUploadFile: isSuccessUploadFile,
        setSuccessUploadFile: setSuccessUploadFile,

        isFireGoalDetail: isFireGoalDetail,
        setFireGoalDetail: setFireGoalDetail,

        isSuccesCreateGoalResult: isSuccesCreateGoalResult,
        setSuccesCreateGoalResult: setSuccesCreateGoalResult,
        // isSuccessDeleteBuktiInDatabase :  isSuccessDeleteBuktiInDatabase,
        // setSuccessDeleteBuktiInDatabase: setSuccessDeleteBuktiInDatabase,

        setMessages: setMessages,
        messages: messages,

        startDatePojokKanan: startDatePojokKanan,
        setStartDatePojokKanan: setStartDatePojokKanan,

        endDatePojokKanan: endDatePojokKanan,
        setEndDatePojokKanan: setEndDatePojokKanan,

        isModalExportMA: isModalExportMA,
        setModalExportMA: setModalExportMA,

        statusMaId: statusMaId,
        setStatusMaId: setStatusMaId,

        maId: maId,
        setMaId: setMaId,

        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        offset,
        setOffset,
        loaderForPaging,
        setLoaderForPaging,

        pageMAR,
        setPageMAR,
        rowsPerPageMAR,
        setRowsPerPageMAR,
        offsetMAR,
        setOffsetMAR,
        loaderForPagingMAR,
        setLoaderForPagingMAR,

        goalResultLength,
        setGoalResultLength,

        hideCreateMABtn,
        setHideCreateMABtn,

        disableCreateMAResult,
        setDisableCreateMAResult,

        isSuccessAddEvidence,
        setSuccessAddEvidence,

        triggerGR,
        seTriggerGR,
      }}>
      {props.children}
    </ContextGoalDetail.Provider>
  );
};

export default ContextGlobalGoalDetail;

// Goal Detail
const initialGoalDetail = {
  achievementType: { id: "", code: "", name: "" },
  achievement_type_id: "",
  calculatedValue: {
    date_offset: "",
    day_offset: 0,
    end_date: "",
    input_value: 0,
    start_date: "",
    start_value: 0,
    target_range_value: 0,
    target_value: 0,
    target_value_achievement: 0,
    actualOriented: {
      actual_value: 0,
      actual_value_achievement: 0,
      expected_actual_value: 0,
      expected_actual_value_achievement: 0,
      gap_actual_value: 0,
      gap_actual_value_achievement: 0,
      overall_actual_achievement: 0,
    },
    resultOriented: {
      expected_result_value: 0,
      expected_result_value_achievement: 0,
      gap_result_value: 0,
      gap_result_value_achievement: 0,
      overall_result_achievement: 0,
      result_value: 0,
      result_value_achievement: 0,
    },
  },
  calculationMethod: { id: "", code: "", name: "" },
  calculation_method_id: "",
  completed_timestamp: null,
  created_at: "",
  created_by: "",
  deleted_at: null,
  deleted_by: null,
  description: "",
  directLinkMainGoal: null,
  direct_link_main_goal_id: null,
  direct_link_node_level: 0,
  end_date: "",
  goalMode: { id: "", code: "", name: "" },
  goalType: { id: "", code: "", name: "" },
  goal_mode_id: "",
  goal_type_id: "",
  has_goal_result: false,
  id: "",
  inputMethod: { id: "", code: "", name: "" },
  input_method_id: "",
  input_value: "",
  is_used: false,
  last_goal_result_date: "",
  member: {
    id: "",
    first_name: "",
    last_name: "",
    nickname: "",
    photo_url: "",
    self: { rel: { photo_url: "" } },
  },
  name: "",
  owner: {
    id: "",
    parent_id: null,
    prev_sibling_id: null,
    next_sibling_id: null,
    code: "",
    created_at: "",
    created_by: "",
    deleted_at: null,
    deleted_by: null,
    id: "",
    member_first_name: "",
    member_id: "",
    member_last_name: "",
    next_sibling_id: null,
    node_level: 0,
    parent_id: null,
    prev_sibling_id: null,
    structure_position_title_id: "",
    structure_position_title_name: "",
    structure_unit_id: null,
    structure_unit_name: null,
    structure_unit_type_id: null,
    structure_unit_type_name: null,
    updated_at: "",
    updated_by: "",
    user_email: "",
    user_id: "",
    self: { rel: null },
  },
  owner_id: "",
  period: null,
  period_id: null,
  policy: [
    { actionName: "", result: false },
    { actionName: "", result: false },
    { actionName: "", result: false },
    { actionName: "", result: false },
    { actionName: "", result: false },
    { actionName: "", result: false },
    { actionName: "", result: false },
  ],
  self: { rel: null },
  startValueSyncStatus: {
    id: "",
    code: "",
    name: "",
  },
  start_date: "",
  start_value: "",
  status: { id: "", code: "", name: "", sort_order: 0 },
  status_id: "",
  targetType: { id: "", code: "", name: "" },
  targetValueSyncStatus: {
    id: "",
    code: "",
    name: "",
  },
  target_range_value: null,
  target_type_id: "",
  target_value: "",
  time: {
    elapsed_days: 0,
    remaining_days: 0,
    total_days: 0,
    start_date: "",
    end_date: "",
  },
  timeFrame: { id: "", code: "", name: "" },
  time_frame_id: "",
  tolerance_value: "",
  updateInterval: {
    id: "",
    name: "",
    code: "",
    datetime_unit: null,
    datetime_value: null,
  },
  update_interval_id: "",
  update_interval_trigger_value: null,
  updated_at: "",
  updated_by: null,
  valueType: { id: "", code: "", name: "" },
  value_type_id: "",
  visibilityType: { id: "", code: "", name: "" },
  visibility_type_id: "",
  weight_value: "",
};

//*
let listCollectionMa = [
  {
    id: null,
    name: "",
    measured_activity_type_id: "",
    goal_id: "",
    owner_id: "",
    achievement_type_id: "",
    value_type_id: "",
    start_value: "",
    target_value: "",
    target_range_value: null,
    tolerance_value: "",
    weight_value: "",
    input_value: "",
    input_method_id: "",
    calculation_method_id: "",
    direct_link_main_measured_activity_id: null,
    direct_link_node_path: "",
    direct_link_node_level: null,
    update_interval_id: "",
    update_interval_trigger_value: null,
    period_id: null,
    is_evidence_required: 0,
    start_date: "",
    end_date: "",
    completed_timestamp: null,
    time_frame_id: "",
    measured_activity_mode_id: "",
    visibility_type_id: "",
    last_measured_activity_result_date: null,
    status_id: "",
    description: null,
    created_by: "",
    updated_by: null,
    deleted_by: null,
    created_at: "",
    updated_at: "",
    deleted_at: null,
    is_used: false,
    period: null,
    goal: {
      id: "",
      name: "",
    },
    achievementType: {
      id: "",
      code: "",
      name: "",
    },
    inputMethod: {
      id: "",
      code: "",
      name: "",
    },
    calculationMethod: {
      id: "",
      code: "",
      name: "",
    },
    measuredActivityMode: {
      id: "",
      code: "",
      name: "",
    },
    measuredActivityType: {
      id: "",
      code: "",
      name: "",
    },
    owner: {
      id: "",
      parent_id: "",
      prev_sibling_id: null,
      next_sibling_id: null,
      code: "",
      member_id: "",
      member_first_name: "",
      member_last_name: "",
      user_id: "",
      user_email: "",
      structure_unit_type_id: "",
      structure_unit_type_name: "",
      structure_unit_id: "",
      structure_unit_name: "",
      structure_position_title_id: "",
      structure_position_title_name: "",
      classification_id: 1,
      classification_name: "",
      node_level: null,
      created_by: "",
      updated_by: "",
      deleted_by: null,
      created_at: "",
      updated_at: "",
      deleted_at: null,
      self: {
        rel: null,
      },
    },
    member: {
      id: "",
      first_name: "",
      last_name: "",
      nickname: "",
      photo_url: "",
    },
    directLinkMainMeasuredActivity: null,
    startValueSyncStatus: {
      id: "1",
      code: "swd_ma_start_value_sync_status_sync",
      name: "sync",
    },
    targetValueSyncStatus: {
      id: "",
      code: "",
      name: "",
    },
    status: {
      id: "",
      code: "",
      name: "",
      sort_order: null,
    },
    updateInterval: {
      id: "",
      name: "",
      code: "",
      datetime_unit: null,
      datetime_value: null,
      sort_order: 0,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      created_at: "",
      updated_at: null,
      deleted_at: null,
      self: {
        rel: null,
      },
    },
    valueType: {
      id: "",
      code: "",
      name: "",
    },
    visibilityType: {
      id: "",
      code: "",
      name: "",
    },
    calculatedValue: {
      start_value: "",
      target_value: "",
      input_value: "",
      target_value_achievement: "",
      target_range_value: "",
      resultOriented: {
        result_value: "",
        result_value_achievement: "",
        expected_result_value: "",
        expected_result_value_achievement: "",
        gap_result_value: "",
        gap_result_value_achievement: "",
        overall_result_achievement: "",
      },
      actualOriented: {
        actual_value: "",
        actual_value_achievement: "",
        expected_actual_value: "",
        expected_actual_value_achievement: "",
        gap_actual_value: "",
        gap_actual_value_achievement: "",
        overall_actual_achievement: "",
      },
      day_offset: null,
      date_offset: "",
      start_date: "",
      end_date: "",
    },
    time: {
      elapsed_days: "",
      remaining_days: "",
      total_days: "",
      start_date: "",
      end_date: "",
    },
    timeFrame: {
      id: "",
      code: "",
      name: "",
    },
    self: {
      rel: null,
    },
  },
];

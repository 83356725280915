import React, { useState, useContext, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TablePagination,
  TableHead,
  Button,
  Checkbox,
} from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

import ListCardItemMA from "../components/ListCardItemMA";
import PictEmptyStateMA from "../../MeasureActivities/Components/PictEmptyStateMA";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import ContextAllMA from "../context/AllMaContext";
import { theme, styles } from "../style/StyleAllMA";
import { STATUS_COMPLETED } from "../../../../constants/config-status-goal";
import DialogDeleteMultiple from "../components/DialogDeleteMultiple";
import PaginationMA from "../components/PaginationMA";

const useStyle = makeStyles(styles);

function ListStatusAllMACompleted() {
  const classes = useStyle();
  const context = useContext(ContextAllMA);

  const [selected, setSelected] = useState([]);
  const [isOpenDialogDeleteMultiple, setOpenDialogDeleteMultiple] = useState(
    false
  );
  const [checkValidDelete, setCheckValidDelete] = useState([]);

  const handleGetValidMADelete = () => {
    return Promise.all(
      selected.length > 0 &&
        selected.map((item, index) => {
          const params = new URLSearchParams();
          params.append(`MeasuredActivity[${index}][id]`, item);

          return axiosConfig.delete(
            `${URL_API}/swd/measured-activity/batch?validateOnly=true`,
            { data: params }
          );
        })
    )
      .then((response) => {
        // console.log("ORI Validate DEL", response);
        setCheckValidDelete(response);
        setOpenDialogDeleteMultiple(true);
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  };

  /*  
        ``````````````````
        HANDLE ALL MA DETAIL
        ``````````````````
    */
  const handleDetail = (e, data) => {
    e.preventDefault();
    if (e.type === "click") {
      console.log("Left click");
    } else if (e.type === "contextmenu") {
      console.log("Right click");
    }

    console.log(data);
    // localStorage.setItem("allma_detail", JSON.stringify(data));
    // navigate(ToGoalDetail, false, {id: data.id})
  };

  /*
    ===================================================================
      HANDLE PAGINATION //*Will be to global state
    ===================================================================
  */
  const handleChangePage = (event, newPage) => {
    context.setPage(newPage);
    context.setLoaderForPaging(true);
    context.setOffset((parseInt(newPage + 1) - 1) * context.rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    context.setLoaderForPaging(true);
    context.setRowsPerPage(parseInt(event.target.value, 10));
    context.setPage(0);
  };

  // Length Of Collection Goal
  const itemCount = context.collectionMAList.length;

  /* =========================
        selected ID
        ============================= */
  const isSelected = (id) => selected.indexOf(id) !== -1;

  /* =========================
        selected All List Item
        ============================= */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = context.collectionMAList.map((n) => n.id);
      setSelected(newSelecteds);
      // console.log("selAll", newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <Fragment>
      <Grid container style={{ backgroundColor: "transparent", marginTop: 40 }}>
        <Box
          style={{
            marginLeft: 42,
            marginTop: -42,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
          <Checkbox
            indeterminate={selected.length > 0 && selected.length < itemCount}
            checked={itemCount > 0 && selected.length === itemCount}
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
          <Typography
            color="inherit"
            variant="subtitle1"
            className={classes.title}>
            <b>{selected.length}</b> Measured Activity ditandai
          </Typography>
        </Box>
        {selected.length > 0 && (
          <Button
            onClick={handleGetValidMADelete}
            disableElevation
            variant="contained"
            className={classes.button5}
            startIcon={<DeleteRoundedIcon />}
            style={{ marginLeft: 24, marginTop: -36 }}>
            <b>Delete</b>
          </Button>
        )}
        <Grid item xs={12} md={12}>
          {context.loader ? (
            <Grid
              container
              spacing={0}
              direction="row"
              justify="center"
              alignItems="center">
              <CircularProgress
                size={32}
                style={{ marginTop: 48, color: "red" }}
              />
            </Grid>
          ) : (
            <Table className={classes.table} aria-label="sticky table">
              <TableHead></TableHead>
              <TableBody>
                {context.collectionMAList.length > 0 &&
                  context.collectionMAList.map((item, i) => {
                    return (
                      <ListCardItemMA
                        key={i}
                        classes={classes}
                        item={item}
                        selected={selected}
                        setSelected={setSelected}
                        handleDetail={handleDetail}
                        userTokenState={context.userTokenStateGlobal}
                        isSelected={isSelected}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          )}

          {context.collectionMAList.length === 0 && context.loader !== true && (
            <Paper elevation={1} className={classes.paperList}>
              <Grid container>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{
                      color: "grey",
                      marginTop: 16,
                      marginBottom: 16,
                    }}>
                    <PictEmptyStateMA />
                    <b>
                      Tidak ada Measure Activity yang dapat di tampilkan saat
                      ini ;(
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          )}

          {context.collectionMAList.length > 0 &&
            context.dataTabActive.id !== 2 && (
              <PaginationMA
                theme={theme}
                classes={classes}
                loader={context.loader}
                collectionMALength={context.collectionMALength}
                page={context.page}
                rowsPerPage={context.rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
        </Grid>
      </Grid>

      <DialogDeleteMultiple
        classes={classes}
        isOpenDialogDeleteMultiple={isOpenDialogDeleteMultiple}
        setOpenDialogDeleteMultiple={setOpenDialogDeleteMultiple}
        selected={selected}
        checkValidDelete={checkValidDelete}
      />
    </Fragment>
  );
}

export default ListStatusAllMACompleted;

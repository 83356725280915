import React, { useEffect, useState, useRef, useContext } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  fade,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import axios from "axios";
import ContextNewSO from "../Context/ContextNewSO";

import { styles } from "../Style/StyleDialogDetail";
import Capitalize from "../../../utilities/Capitalize";
import { URL_API } from "../../../constants/config-api";
import DialogError from "../../../components/DialogError";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const DialogEditNamaUnit = (props) => {
  const {
    classes,
    // setOpenDialogEditNamaUnit,
    // isOpenDialogEditNamaUnit,
  } = props;

  const context = useContext(ContextNewSO);

  let textInputReff = useRef(null);

  const [loader, setLoader] = useState(false);

  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformationValue] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);
  const [listError, setListError] = useState([]);

  const [updatedAt, setUpdatedAt] = useState("");

  useEffect(() => {
    if (context.isOpenDialogEditNamaUnit == true) {
      context.setOpenDialogTambahHead(false);

      //*Focus in Text Field
      setTimeout(() => {
        textInputReff.current.focus();
      }, 100);

      context.setTreeItems([
        {
          id: null,
          parent_id: null,
          structure_position_title_id: null,
          structure_position_title_name: null,

          structure_unit_id: null,
          structure_unit_name: null,

          structure_unit_type_id: null,
          structure_unit_type_name: null,

          member_first_name: null,
          member_last_name: null,
          self: null,

          classification: {
            id: null,
          },

          child: [],
        },
      ]);

      //************************************** */
      if (context.userTokenState !== "") {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + context.userTokenState,
        };

        axios.defaults.headers.common = header;

        axios
          .get(
            URL_API +
              `/human-resource/master-structure-unit/${context.dataDetail.id}/update`
          )
          .then((response) => {
            console.log("Response Original : ", response);
            if (response.data.data !== null) {
              if (response.data.data !== null) {
                if (response.data.data.updated_at !== null) {
                  setUpdatedAt(response.data.data.updated_at);
                }
              }
            }
          })
          .catch((error) => {
            console.log("Error : ", error.response);
          });
      }
    }
  }, [context.isOpenDialogEditNamaUnit]);

  // const [ valueJenisUnitName, context.setValueJenisUnitName ] = useState('');
  const handleChange = (e) => {
    e.preventDefault();
    context.setNamaUnitChoosed(e.target.value);
  };

  const handleBatal = () => {
    context.setOpenDialogTambahHead(true);
    context.setOpenDialogEditNamaUnit(false);
  };

  const handleSubmitEditNamaUnit = () => {
    setLoader(true);

    let data = {
      MasterStructureUnit: {
        updated_at: updatedAt,
        name: context.namaUnitChoosed,
      },
    };

    console.log("Data SUBMIT : ", data);

    if (context.userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + context.userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .put(
          URL_API +
            `/human-resource/master-structure-unit/${context.dataDetail.id}`,
          data
        )
        .then(function (response) {
          setLoader(false);
          console.log("Response Original : ", response);

          if (response.status == 200) {
            context.setOpenDialogTambahHead(true);
            context.setOpenDialogEditNamaUnit(false);

            context.setJenisUnitChoosed(false);
            context.setSuccessEditNamaUnit(true);

            context.setSnackbarResponse200(true);
            context.setMessages(`Perubahan berhasil di simpan :)`);
          }
        })
        .catch(function (error) {
          setLoader(false);

          if (error.response !== undefined) {
            if (error.response.status === 400) {
              setErrorStatus(400);

              if (error.response.data.info.errors.hasOwnProperty("end_date")) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  "Error 400 : " +
                    Capitalize(error.response.data.info.errors.end_date)
                );
              }

              if (
                error.response.data.info.errors.hasOwnProperty(
                  "calculation_method_id"
                )
              ) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  "Error 400 : " +
                    Capitalize(
                      error.response.data.info.errors.calculation_method_id
                    )
                );
              }

              if (error.response.data.info.errors.hasOwnProperty("owner_id")) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  "Error 400 : " +
                    Capitalize(error.response.data.info.errors.owner_id)
                );
              }

              if (
                error.response.data.info.errors.hasOwnProperty("target_value")
              ) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  "Error 400 : " +
                    Capitalize(error.response.data.info.errors.target_value)
                );
              }
            }

            if (error.response.status == 500) {
              setErrorStatus(500);
              if (error.response.data.hasOwnProperty("message")) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  "Error 500 : " + Capitalize(error.response.data.message)
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }

          console.log("Error : ", error.response);
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        open={context.isOpenDialogEditNamaUnit}
        // onClose={() =>setOpenDialogEditNamaUnit(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}>
            <b>
              Edit <i>Nama Unit</i>
            </b>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            inputRef={textInputReff}
            id="outlined-bare"
            className={classes.textField}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder={context.dataDetail.name}
          />
        </DialogContent>
        <DialogActions
          style={{ alignItems: "right", justifyContent: "flex-end" }}>
          <Box marginRight={theme.spacing(0.5)}>
            <Button
              onClick={handleBatal}
              variant="outlined"
              size="small"
              className={classes.buttonOutlined}>
              Batal
            </Button>

            <Button
              onClick={handleSubmitEditNamaUnit}
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}>
              {loader == true ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                "Simpan perubahan"
              )}
            </Button>
          </Box>
        </DialogActions>
        <br />
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(DialogEditNamaUnit);

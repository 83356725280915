import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  Fragment,
} from "react";
import {
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemIcon,
  Radio,
  InputAdornment,
} from "@material-ui/core";

import { createMuiTheme } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import axiosConfig from "../../../../constants/config-axios";

import PeriodListMenu from "./PeriodListMenu";
import ComponentDueDate from "./ComponentDueDate";

import ContextGoalDetail from "../../Goal/Context/ContextGoalDetail";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";
import ImageSmallUser from "../../../../components/ImageSmallUser";

import Snackbeer from "../../../../components/Snackbeer";
import DialogError from "../../../../components/DialogError";
import LightTooltip from "../../../../components/LightTooltip";

import { URL_API } from "../../../../constants/config-api";
import Capitalize from "../../../../utilities/Capitalize";
import DialogDateRange from "../../Goal/Components/DialogDateRange";

import { ToSOTable } from "../../../../constants/config-redirect-url";
import Redirect from "../../../../utilities/Redirect";

import {
  STATUS_ACTIVE,
  STATUS_APPROVED,

  //*CALCULATION METHOD
  SWD_MA_CALCULATION_METHOD_SUM,
  SWD_MA_CALCULATION_METHOD_AVERAGE,
  SWD_MA_CALCULATION_METHOD_LAST_VALUE,

  //*INPUT METHOD
  SWD_MA_INPUT_METHOD_LAST_VALUE,
  SWD_MA_INPUT_METHOD_INCREMENT,

  //*VALUE TYPE
  NUMERIC,
  PERCENTAGE,

  //*ACHIEVEMENT TYPE
  SWD_MA_ACHIEVEMENT_TYPE_MORE_ORIENTED,
  SWD_MA_ACHIEVEMENT_TYPE_LESS_ORIENTED,
} from "../../../../constants/config-status-goal";

import "moment/locale/id";

const numeral = require("numeral");

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const DialogEditMA = (props) => {
  const {
    classes,
    isModalEditMAMaster,
    setModalEditMAMaster,
    // fotoQuery,
    // collectionMA,
    // setColletionMA,
    maDetailState,
    // resultValue,
    positionName,
    setPositionName,
    // goalDetailState,
    setTriggerMaAll,
  } = props;

  const context = useContext(ContextGoalDetail);

  // console.log("Context in Edit MA :", context);

  /*
        ````````
        USE REFF

        ````````
    */
  let textInputReff = useRef(null);
  const [ownerMAName, setOwnerMAName] = useState("");

  /*
        ```````````````````
        COMPONENT DID MOUNT 

        ```````````````````
    */
  const userToken = localStorage.getItem("userToken");

  const [startDateRangeState, setStartDateRangeState] = useState(null);
  const [endDateRangeState, setEndDateRangeState] = useState(null);
  const startDateRange = localStorage.getItem("start_date");
  const endDateRange = localStorage.getItem("end_date");

  const [userTokenState, setUserTokenState] = useState("");
  const [positionList, setPositionList] = useState([]);

  const [achievementTypeCollections, setAchievementTypeCollections] = useState(
    []
  );
  const [calculationMethodList, setCalculationMethodList] = useState([]);
  const [inputMethodList, setInputMethodList] = useState([]);
  const [inputValueTypeList, setInputValueTypeList] = useState([]);

  const [periodCollections, setPeriodCollections] = useState([]);
  const [memberPositionList, setMemberPositionList] = useState([]);

  const [fotoQueryContent, setFotoQueryContent] = useState("");
  const [measuredActivityId, setMeasuredActivityId] = useState("");

  const [statusUnUsedACTIVE, setStatusUnUsedACTIVE] = useState(false);
  const [statusUsedACTIVE, setStatusUsedACTIVE] = useState(false);

  const [statusAPPROVED, setStatusAPPROVED] = useState(false);

  const [goalId, setGoalId] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  const [timeFrameId, setTimeFrameId] = useState(null);
  const [periodId, setPeriodId] = useState(null);
  const [periodName, setPeriodName] = useState("");

  useEffect(() => {
    if (isModalEditMAMaster === true) {
      if (maDetailState.id !== null) {
        //*Is Evidence Required
        setEvidance(maDetailState.is_evidence_required);

        setMeasuredActivityId(maDetailState.id);
        // setOwnerMAName(maDetailState.member.first_name + " " + maDetailState.member.last_name );

        if (maDetailState.member !== null) {
          setOwnerMAName(
            maDetailState.member.first_name +
              " " +
              maDetailState.member.last_name
          );
          setFotoQueryContent(maDetailState.member.self.rel.photo_url);
        }

        if (memberPositionList.length === 0) {
          setPositionName(maDetailState.owner.structure_position_title_name);
        }

        // setFotoQueryContent(maDetailState.member.self.rel.photo_url);
        setDisabledButton(false);

        setSelectedInputMethod(maDetailState.inputMethod);
        setSelectedValueType(maDetailState.valueType);
        setSelectedCalculationMethod(maDetailState.calculationMethod);
        setSelectedAchievementType(maDetailState.achievementType);
        setSelectedTargetType(maDetailState.targetType);

        if (maDetailState.status.code === STATUS_APPROVED) {
          setStatusAPPROVED(true);
        }

        if (maDetailState.time_frame_id !== null) {
          setTimeFrameId(maDetailState.time_frame_id);
        }

        if (
          maDetailState.period !== null &&
          maDetailState.period !== undefined
        ) {
          if (maDetailState.period.name !== null) {
            setPeriodName(maDetailState.period.name);
          }
        }

        /*
                    `````````````
                    ACTIVE UNUSED

                    `````````````
                */

        if (
          maDetailState.status.code === STATUS_ACTIVE &&
          maDetailState.is_used === false
        ) {
          // setDescription(maDetailState.description);
          // setNilaiTarget(numeral(maDetailState.calculatedValue.target_value).format('0,0'));
          setStatusUnUsedACTIVE(true);
        }

        /*
                    ```````````
                    ACTIVE USED

                    `````````````
                */

        if (
          maDetailState.status.code === STATUS_ACTIVE &&
          maDetailState.is_used === true
        ) {
          // setDisabledButton(true);
          setStatusUsedACTIVE(true);

          setStartDateRangeState(maDetailState.start_date);
          setEndDateRangeState(maDetailState.end_date);

          setStartValue(
            numeral(maDetailState.calculatedValue.start_value).format("0.00")
          );
          setNilaiTarget(
            numeral(maDetailState.calculatedValue.target_value).format("0.00")
          );

          setAchievementTypeCollections([maDetailState.achievementType]);
          setSelectedAchievementType(maDetailState.achievementType);

          setInputMethodList([maDetailState.inputMethod]);
          setSelectedInputMethod(maDetailState.inputMethod);

          setInputValueTypeList([maDetailState.valueType]);
          setSelectedValueType(maDetailState.valueType);

          setCalculationMethodList([maDetailState.calculationMethod]);
          setSelectedCalculationMethod(maDetailState.calculationMethod);

          setTargetTypeList([maDetailState.targetType]);
          setSelectedTargetType(maDetailState.targetType);
        }
      }

      //****************************************** */
      setUserTokenState(userToken);
      setLoader(false);

      if (userToken !== undefined || userToken !== null) {
        axiosConfig
          .get(
            URL_API +
              `/swd/measured-activity/${maDetailState.id}/update?goal_id=${maDetailState.goal_id}`
          )
          .then(function (response) {
            console.log("Response Edit MA : ", response);

            if (response.status === 200) {
              setUpdatedAt(response.data.data.updated_at);
              setMaName(response.data.data.fields.name.value);

              if (
                response.data.data.achievementTypeCollections !== null &&
                response.data.data.achievementTypeCollections !== undefined
              ) {
                setAchievementTypeCollections(
                  response.data.data.achievementTypeCollections
                );
              }

              if (
                response.data.data.calculationMethodCollections !== undefined &&
                response.data.data.calculationMethodCollections !== null
              ) {
                setCalculationMethodList(
                  response.data.data.calculationMethodCollections
                );

                // setSelectedCalculationMethod({
                //   id: "1",
                //   code: SWD_MA_CALCULATION_METHOD_SUM,
                //   name: "sum",
                // });
              }

              if (
                response.data.data.targetTypeCollections !== undefined &&
                response.data.data.targetTypeCollections !== null
              ) {
                setTargetTypeList(response.data.data.targetTypeCollections);
              }

              if (
                response.data.data.inputMethodCollections !== null &&
                response.data.data.inputMethodCollections !== undefined
              ) {
                setInputMethodList(response.data.data.inputMethodCollections);
              }

              if (
                response.data.data.valueTypeCollections !== undefined &&
                response.data.data.valueTypeCollections !== null
              ) {
                setInputValueTypeList(response.data.data.valueTypeCollections);
              }

              // setMemberPositionList(response.data.data.ownerCollections);

              if (
                response.data.data.fields.end_date.value !== null &&
                response.data.data.fields.start_date.value !== null
              ) {
                setShowTextDateRange(false);
                setStartDateRangeState(
                  response.data.data.fields.start_date.value
                );
                setEndDateRangeState(response.data.data.fields.end_date.value);
                setSelectedDueDate(response.data.data.fields.end_date.value);
              }

              if (response.data.data.fields.target_value.value !== null) {
                setNilaiTarget(
                  numeral(response.data.data.fields.target_value.value).format(
                    "0.00"
                  )
                );
              }

              if (response.data.data.fields.start_value.value !== null) {
                setStartValue(
                  numeral(response.data.data.fields.start_value.value).format(
                    "0.00"
                  )
                );
              }

              if (response.data.data.fields.description.value !== null) {
                setDescription(response.data.data.fields.description.value);
              }

              if (response.data.data.fields.time_frame_id.value !== null) {
                setTimeFrameId(response.data.data.fields.time_frame_id.value);
                if (response.data.data.fields.time_frame_id.value === "1") {
                  //*1 itu Periode
                  setPeriodId(response.data.data.fields.period_id.value);
                }
              }

              if (response.data.data.periodCollections !== null) {
                setPeriodCollections(response.data.data.periodCollections);
              }
            }
          })
          .catch(function (error) {
            console.log("Error : ", error.response);
          });
      } else {
        console.log("No Access Token available!");
      }

      /* 
                ```````````````````
                Focus in Text Field

                ```````````````````
            */
      setTimeout(() => {
        textInputReff.current.focus();
      }, 100);
    }
  }, [isModalEditMAMaster]);

  /*
        `````````````````````
        CHECK IS IMAGE IS OKE
        
        `````````````````````
    */
  useEffect(() => {
    if (isModalEditMAMaster == true) {
      if (maDetailState.id !== null) {
        if (maDetailState.member !== null) {
          if (fotoQueryContent == "") {
            if (userToken !== undefined) {
              axiosConfig
                .get(
                  URL_API +
                    `/${
                      maDetailState.id !== null
                        ? maDetailState.member.self.rel.photo_url
                        : undefined
                    }&token=${userToken}`
                )
                .then(function (response) {
                  // console.log("Response Original FOTO : ", response);
                  setFotoQueryContent(maDetailState.member.self.rel.photo_url);
                })
                .catch(function (error) {
                  console.log("Error : ", error.response);
                });
            } else {
              console.log("No Access Token available!");
            }
          }
        }
      }
    }
  }, [maDetailState, isModalEditMAMaster]);

  useEffect(() => {
    setStartDateRangeState(startDateRange);
    setEndDateRangeState(endDateRange);
  }, [startDateRange, endDateRange]);

  /*
        ``````````````````````
        HANDLE CHANGE BIG GOAL

        ```````````````````
    */
  const [maName, setMaName] = useState("");
  const handleChangeMA = (e) => {
    setMaName(e.target.value);
  };

  /*
        `````````````````````````
        HANDLE CHANGE DESCRIPTION

        `````````````````````````
    */
  const [description, setDescription] = useState("");
  const handleChangeDescription = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  const [startValue, setStartValue] = useState("");
  const handleChangeStartValue = (e) => {
    e.preventDefault();
    setStartValue(e.target.value);
  };

  /*
        ``````````````````````````
        HANDLE CHANGE NILAI TARGET

        ``````````````````````````
    */
  const [nilaiTarget, setNilaiTarget] = useState("");
  const handleChangeNilaiTarget = (e) => {
    e.preventDefault();
    setNilaiTarget(e.target.value);
  };

  /*
        ````````````````````````````````
        HANDLE SELECTED ACHIEVEMENT TYPE

        ````````````````````````````````
    */

  const [selectedAchievementType, setSelectedAchievementType] = useState({
    id: "1",
    code: SWD_MA_ACHIEVEMENT_TYPE_MORE_ORIENTED,
    name: "more oriented",
  });

  const handleChangeRadioButtonAchievementType = (e, data) => {
    setSelectedAchievementType(data);
  };

  /*
        ``````````````````````````````````
        HANDLE SELECTED CALCULATION METHOD 

        `````````````````````````````````
    */

  const [selectedCalculationMethod, setSelectedCalculationMethod] = useState({
    id: "1",
    code: SWD_MA_INPUT_METHOD_LAST_VALUE,
    name: "last value",
  });

  const handleChangeRadioButton = (e, data) => {
    e.preventDefault();
    setSelectedCalculationMethod(data);

    if (data.id === "3") {
      setSelectedInputMethod({
        id: "1",
        code: "swd_ma_input_method_last_value",
        name: "last value",
      });
    } else {
      setSelectedInputMethod({
        id: "2",
        code: "swd_ma_input_method_increment",
        name: "increment",
      });
    }

    if (data.id === "2") {
      setTargetTypeList([
        {
          code: "swd_ma_target_type_final",
          id: "2",
          name: "Final",
        },
      ]);

      setSelectedTargetType({
        code: "swd_ma_target_type_final",
        id: "2",
        name: "Final",
      });
    } else {
      setTargetTypeList([
        {
          code: "swd_ma_target_type_daily",
          id: "1",
          name: "Daily",
        },
        {
          code: "swd_ma_target_type_final",
          id: "2",
          name: "Final",
        },
      ]);

      setSelectedTargetType({
        code: "swd_ma_target_type_daily",
        id: "1",
        name: "Daily",
      });
    }
  };

  /*
        =====================================
        HANDLE SELECTED  TARGET TYPE
        =====================================
    */
  const [targetTypeList, setTargetTypeList] = useState([]);
  const [selectedTargetType, setSelectedTargetType] = useState({
    code: "swd_ma_target_type_daily",
    id: "1",
    name: "Daily",
  });

  const handleSelectTargetType = (e, data) => {
    e.preventDefault();
    setSelectedTargetType(data);
  };

  /*
        ``````````````````````````````````
        HANDLE SELECTED INPUT METHOD 

        `````````````````````````````````
    */
  const [selectedInputMethod, setSelectedInputMethod] = useState({
    id: "2",
    code: SWD_MA_INPUT_METHOD_INCREMENT,
    name: "increment",
  });

  const handleChangeRadioButtonInputMethod = (e, data) => {
    e.preventDefault();
    setSelectedInputMethod(data);

    if (data.code === SWD_MA_INPUT_METHOD_INCREMENT) {
      // setShowSixRadioButton(true);

      setCalculationMethodList([
        {
          id: "1",
          code: SWD_MA_CALCULATION_METHOD_SUM,
          name: "sum",
        },
        {
          id: "2",
          code: SWD_MA_CALCULATION_METHOD_AVERAGE,
          name: "average",
        },
      ]);

      setSelectedCalculationMethod({
        id: "1",
        code: SWD_MA_CALCULATION_METHOD_SUM,
        name: "sum",
      });
    } else {
      setCalculationMethodList([
        {
          code: SWD_MA_CALCULATION_METHOD_LAST_VALUE,
          id: "3",
          name: "last value",
        },
      ]);

      setSelectedCalculationMethod({
        id: "3",
        code: SWD_MA_CALCULATION_METHOD_LAST_VALUE,
        name: "last value",
      });

      setTargetTypeList([
        {
          code: "swd_ma_target_type_daily",
          id: "1",
          name: "Daily",
        },
        {
          code: "swd_ma_target_type_final",
          id: "2",
          name: "Final",
        },
      ]);
    }

    // else {

    //     setShowSixRadioButton(false);
    // };
  };

  /*
        ``````````````````````````````````
        HANDLE SELECTED  VALUE METHOD 

        `````````````````````````````````
    */

  const [selectedValueType, setSelectedValueType] = useState({
    id: "2",
    code: "numeric",
    name: "numeric",
  });

  const [isShowPercent, setShowPercent] = useState(false);
  const handleChangeRadioButtonValueType = (e, data) => {
    e.preventDefault();
    setSelectedValueType(data);

    if (data.code == "percentage") {
      setShowPercent(true);
    } else {
      setShowPercent(false);
    }
  };

  /*
        `````````````````
        DATE RANGE OPTION

        `````````````````
    */
  const [isShowDateRangeComponent, setShowDateRangeComponent] = useState(false);
  const [isShowTextDateRange, setShowTextDateRange] = useState(false);

  const handleChooseRange = () => {
    // setSelectedDueDate(new Date());//*Baris kode ini untuk validasi DISABLED Button saja !
    // handleCloseTimeFrame();
    // setShowDueDateComponent(false);
    // setShowDueDateComponentDirectly(false);
    setShowDateRangeComponent(true);
    setShowTextDateRange(true);

    // setTextValueTimeFrame('Date Range');
  };

  /*
        `````````````````````````
        HANDLE BUTTON SUBMIT GOAL

        `````````````````````````
    */
  const [loader, setLoader] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const handleSUBMITGoal = () => {
    setLoader(true);
    setDisabledButton(true);
    // console.log("selectedDueDate : ", selectedDueDate)

    let data = {
      MeasuredActivity: {
        updated_at: updatedAt,
        name: maName,
        // owner_id: memberPositionList.length > 0 && ownerId == '' ?  memberPositionList[0].id : ownerId,
        start_value: startValue,
        target_value: nilaiTarget,
        target_range_value:
          targetRangeValue !== null && targetRangeValue !== ""
            ? targetRangeValue
            : null,
        achievement_type_id: selectedAchievementType.id,
        input_method_id: selectedInputMethod.id,
        calculation_method_id: selectedCalculationMethod.id,
        value_type_id: selectedValueType.id,
        start_date:
          startDateRangeState === null
            ? moment(selectedDueDate).format("YYYY-MM-DD")
            : startDateRangeState,
        end_date:
          endDateRangeState === null
            ? moment(selectedDueDate).format("YYYY-MM-DD")
            : endDateRangeState,

        description: description,
        time_frame_id: timeFrameId,
        period_id: periodId,
        is_evidence_required: evidance === true ? "1" : "0", //*0 === Tidak butuh evidence, 1 === Butuh evidence
        target_type_id: selectedTargetType.id,
      },
    };

    if (
      data.MeasuredActivity.target_range_value == null ||
      data.MeasuredActivity.target_range_value === ""
    ) {
      delete data.MeasuredActivity.target_range_value;
    }

    if (
      data.MeasuredActivity.period_id == null ||
      data.MeasuredActivity.period_id === ""
    ) {
      delete data.MeasuredActivity.period_id;
    }

    console.log("Data SUBMIT : ", data);

    if (userTokenState !== "") {
      axiosConfig
        .put(URL_API + `/swd/measured-activity/${measuredActivityId}`, data)
        .then(function (response) {
          setLoader(false);
          setModalResponse200(true);

          console.log("Response Original : ", response);

          if (response.status == 200) {
            // window.location.reload();
            setTriggerMaAll(Math.floor(Math.random() * 1000 + 1));
            // context.startDatePojokKanan(startDateRangeState);//*Note work
            // context.setEndDatePojokKanan(endDateRangeState);//*Note work

            setModalEditMAMaster(false);
            // setColletionMA([...collectionMA, response.data.data]);
          }
        })
        .catch(function (error) {
          setLoader(false);
          setDisabledButton(false);

          if (error.response !== undefined) {
            if (error.response.status == 400) {
              setErrorStatus(400);
              if (
                error.response.data.info.errors !== undefined &&
                error.response.data.info.errors !== null
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                  setTextErrorInformationValue(".");
                }

                if (
                  error.response.data.info.errors.hasOwnProperty("end_date")
                ) {
                  setOpenDialogError(true);
                  setTextErrorInformationValue(
                    "Error 400 : " +
                      Capitalize(error.response.data.info.errors.end_date)
                  );
                }

                if (
                  error.response.data.info.errors.hasOwnProperty(
                    "calculation_method_id"
                  )
                ) {
                  setOpenDialogError(true);
                  setTextErrorInformationValue(
                    "Error 400 : " +
                      Capitalize(
                        error.response.data.info.errors.calculation_method_id
                      )
                  );
                }

                if (
                  error.response.data.info.errors.hasOwnProperty("owner_id")
                ) {
                  setOpenDialogError(true);
                  setTextErrorInformationValue(
                    "Error 400 : " +
                      Capitalize(error.response.data.info.errors.owner_id)
                  );
                }
              }
            }

            if (error.response.status == 500) {
              setErrorStatus(500);
              if (error.response.data.info.hasOwnProperty("message")) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  "Error 500 : " + Capitalize(error.response.data.message)
                );
              }
            }

            if (error.response.status == 409) {
              setErrorStatus(409);
              if (error.response.data.info.hasOwnProperty("message")) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  "Error 409 : " + Capitalize(error.response.data.message)
                );
              }
            }

            if (error.response.status == 422) {
              setErrorStatus(422);
              if (
                error.response.data.info.errors !== undefined &&
                error.response.data.info.errors !== null
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                  setTextErrorInformationValue(".");
                }
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }

          console.log("Error : ", error.response);
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  /*
        ````````````````
        HANDLE SNACKBEER 

        ````````````````
    */
  const [isModalResponse200, setModalResponse200] = useState(false);

  /*
        `````````````````
        DROPDOWN POSITION

        `````````````````
    */
  const [anchorElPosition, setAnchorElPosition] = useState(null);

  const [textValuePosition, setTextValuePosition] = useState("");

  const [ownerId, setOwnerId] = useState("");

  function handleShowDropdownPosition(event) {
    setAnchorElPosition(event.currentTarget);
  }

  function handleCloseDropdownPosition() {
    setAnchorElPosition(null);
  }

  const handleChoosePosition = (e, data) => {
    handleCloseDropdownPosition();

    console.log("Position selected : ", data);
    setTextValuePosition(data.structure_position_title_name);
    setOwnerId(data.id);
  };

  /*
        `````````````````````
        DROPDOWN MEMBER OWNER

        `````````````````````
    */
  const [anchorElOwner, setAnchorElOwner] = useState(null);
  //const [ textValueTimeFrame, setTextValueTimeFrame ] = useState('Time Frame');

  const handleShowDropdownOwner = (event) => {
    setAnchorElOwner(event.currentTarget);
  };

  const handleCloseDropdownOwner = () => {
    setAnchorElOwner(null);
  };

  /*
        ``````````````````````````
        HANDLE CHOOSE MEMBER OWNER

        ``````````````````````````
    */
  const [choosedMemberOwner, setChoosedMemberOwner] = useState("");

  const handleChooseMemberOwner = (e, data) => {
    e.preventDefault();
    handleCloseDropdownOwner();
    setOwnerMAName(data.member_first_name + " " + data.member_last_name);

    // console.log('Member Owner Choosed : ', data);

    if (userToken !== undefined || userToken !== null) {
      axiosConfig
        .get(URL_API + `/swd/goal/create?options[filter][member_id]=${data.id}`)
        .then(function (response) {
          console.log("Response Original Choosed Posisi : ", response);

          if (response.status == 200) {
            setMemberPositionList(response.data.data.ownerCollections);
          }
        })
        .catch(function (error) {
          console.log("Error : ", error.response);
        });
    } else {
      console.log("No Access Token available!");
      setOpenDialogError(true);
    }
  };

  /*
        `````````````````````
        SHOW SIX RADIO BUTTON

        `````````````````````
    */
  const [isShowSixRadioButton, setShowSixRadioButton] = useState(false);

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] =
    useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  /*
        ````````
        EVIDANCE

        ````````
    */
  const [evidance, setEvidance] = useState(false);

  /*
        ```````````````````````````````````````````````
        HANDLE SHOW COMPONENENT TEXT FIELD RANGE VALUE

        ```````````````````````````````````````````````
    */
  const [isShowTextFieldTargetRangeValue, setShowTextFieldTargetRangeValue] =
    useState(false);

  // useEffect(() => {

  //     if(startValue === nilaiTarget){

  //         setShowTextFieldTargetRangeValue(true)

  //     } else {

  //         setShowTextFieldTargetRangeValue(false)
  //     }

  // },[startValue, nilaiTarget])
  useEffect(() => {
    //*
    if (
      startValue === nilaiTarget &&
      (selectedCalculationMethod.code === SWD_MA_CALCULATION_METHOD_SUM ||
        selectedCalculationMethod.code === SWD_MA_CALCULATION_METHOD_LAST_VALUE)
    ) {
      setShowTextFieldTargetRangeValue(true);
    } else if (
      nilaiTarget === "0" &&
      selectedCalculationMethod.code === SWD_MA_CALCULATION_METHOD_AVERAGE
    ) {
      setShowTextFieldTargetRangeValue(true);
    } else {
      setShowTextFieldTargetRangeValue(false);
    }
  }, [startValue, nilaiTarget, selectedCalculationMethod]);

  /*
        ````````````````````````````````
        HANDLE CHANGE TARGET RANGE VALUE

        ````````````````````````````````
    */
  const [targetRangeValue, setTargetRangeValue] = useState("");
  const handleChangeTargetRangeValue = (e) => {
    e.preventDefault();
    setTargetRangeValue(e.target.value);
  };

  /*
        ``````````````````````````````
        HANDLE SPEED DIAL & TIME FRAME

        ``````````````````````````````
    */
  const [anchorElTimeFrame, setAnchorElTimeFrame] = useState(null);

  function handleClickTimeFrame(event) {
    setAnchorElTimeFrame(event.currentTarget);
  }

  function handleCloseTimeFrame() {
    setAnchorElTimeFrame(null);
  }

  /*
        ```````````````````````````````````
        HANDLE PERIOD LIST STYLED MENU ITEM

        ```````````````````````````````````
    */

  const [anchorElPeriod, setAnchorElPeriod] = useState(null);
  function handleChoosePeriod(event) {
    //*Fungsi setAnchor di sini sudah di pakai langsung di 'handleChoosePeriod()'

    // console.log("Event PEriod : ", event)
    setAnchorElPeriod(event.currentTarget);
    // setShowPeriodComponent(true);

    handleCloseTimeFrame();
  }

  function handleClosePeriod() {
    setAnchorElPeriod(null);
  }

  const handlePilihPeriod = (e, data) => {
    // context.setPeriod(data);
    // context.setTimeFrameId("1");

    console.log("data period : ", data);

    setPeriodName(data.name);
    setPeriodId(data.id);
    setTimeFrameId("1");
    handleClosePeriod();
  };

  /*
        ```````````````
        HANDLE DUE DATE

        ```````````````
    */
  const [isShowDueDateComponentDirectly, setShowDueDateComponentDirectly] =
    useState(false);
  const [selectedDueDate, setSelectedDueDate] = useState("");

  const [locale, setLocale] = useState("id");

  return (
    <Fragment>
      <Dialog
        open={isModalEditMAMaster}
        onClose={() => setModalEditMAMaster(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography variant="h6" className={classes.title}>
            <b>
              Edit <i>Measured Activity</i>
            </b>
          </Typography>
        </DialogTitle>

        <DialogContent style={{ textAlign: "left" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="subtitle1" className={classes.title}>
              <b>
                Tulis di sini <i>Measured Activity</i> Anda
              </b>
            </Typography>
            <TextField
              inputRef={textInputReff}
              id="outlined-bare"
              className={classes.textField}
              onChange={handleChangeMA}
              value={maName}
              variant="outlined"
              fullWidth
              inputProps={{
                className: classes.title,
              }}
            />
          </DialogContentText>

          <Grid container>
            <Grid item sm={6} md={6}>
              <List>
                <ListItem style={{ paddingLeft: 0 }}>
                  <ListItemIcon>
                    {fotoQueryContent !== "" && fotoQueryContent !== null ? (
                      // <IconButton
                      //     aria-haspopup="true"
                      //     color="inherit"

                      // >
                      //     <img src={URL_API + '/' +fotoQueryContent+ "&token=" + userToken}  className={classes.userRealFoto} />
                      // </IconButton>
                      <ImageSmallUser item={fotoQueryContent} index={1} />
                    ) : (
                      <IconButton aria-haspopup="true" color="inherit">
                        <AccountCircleIcon
                          className={classes.imageAvatar}
                          style={{ color: "#dcdcdc" }}
                        />
                      </IconButton>
                    )}
                  </ListItemIcon>

                  <ListItemText
                    className={classes.listItemtext}
                    primary={
                      <Button
                        onClick={
                          disabledButton == true
                            ? () => console.log("Disabled pilih owner ! ")
                            : handleShowDropdownOwner
                        }
                        variant="outlined"
                        size="small"
                        className={classes.timeFrameIconInModal}
                        // disabled = { disabledButton == true ? true : false }
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: "grey" }}>
                          Pilih owner :
                        </Typography>
                      </Button>
                    }
                    secondary={
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ marginLeft: 8 }}>
                        <b>
                          {choosedMemberOwner !== ""
                            ? choosedMemberOwner
                            : ownerMAName}
                        </b>
                      </Typography>
                    }
                  />
                </ListItem>
              </List>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElOwner}
                keepMounted
                open={Boolean(anchorElOwner)}
                onClose={handleCloseDropdownOwner}>
                {memberPositionList.length > 0 &&
                  memberPositionList.map((item, i) => {
                    return (
                      <StyledMenuItem
                        key={i}
                        onClick={(e) => handleChooseMemberOwner(e, item)}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle2"
                              className={classes.title}>
                              <b>
                                {item.member_first_name + " "}
                                {item.member_last_name}
                              </b>
                            </Typography>
                          }
                        />
                      </StyledMenuItem>
                    );
                  })}
              </StyledMenu>
            </Grid>

            <Grid item sm={6} md={6}>
              {/* 

                                `````````````````
                                TIME FRAME BUTTON

                                `````````````````                        
                            */}
              <Button
                onClick={handleClickTimeFrame}
                variant="outlined"
                size="small"
                className={classes.timeFrameIconInModal}
                style={{ marginTop: 24 }}
                disabled={
                  disabledButton == true || timeFrameId === "1" ? true : false
                }>
                <IconButton style={{ background: "#edcfd8" }}>
                  <i
                    className="material-icons"
                    style={{ color: "white", fontSize: 14 }}>
                    calendar_today
                  </i>
                </IconButton>
                &nbsp;
                <b>
                  Pilih <i>Timeframe</i>
                </b>
              </Button>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElTimeFrame}
                keepMounted
                open={Boolean(anchorElTimeFrame)}
                onClose={handleCloseTimeFrame}>
                <StyledMenuItem
                  // onClick={handleChooseDueDate}
                  onClick={() => {
                    setShowDueDateComponentDirectly(true);
                    setStartDateRangeState(null);
                    setEndDateRangeState(null);
                    setTimeFrameId("3");
                    handleCloseTimeFrame();
                  }}
                  disabled={statusUsedACTIVE === true ? true : false}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Due Date</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                <StyledMenuItem
                  // onClick= { handleChooseRange }
                  onClick={() => {
                    setShowDateRangeComponent(true);
                    setTimeFrameId("2");
                    handleCloseTimeFrame();
                  }}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Date Range</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                <StyledMenuItem
                  onClick={handleChoosePeriod}
                  // onClick={handleChoosePeriod}
                >
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Period</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>
              </StyledMenu>

              <PeriodListMenu
                periodCollections={periodCollections}
                anchorElPeriod={anchorElPeriod}
                handleClosePeriod={handleClosePeriod}
                handlePilihPeriod={handlePilihPeriod}
              />

              {isShowDueDateComponentDirectly === true && (
                <ComponentDueDate
                  selectedDueDate={selectedDueDate}
                  setSelectedDueDate={setSelectedDueDate}
                  isShowDueDateComponentDirectly={
                    isShowDueDateComponentDirectly
                  }
                  setShowDueDateComponentDirectly={
                    setShowDueDateComponentDirectly
                  }
                />
              )}

              {isShowDateRangeComponent === true && (
                <Fragment>
                  <DialogDateRange
                    classes={classes}
                    userTokenState={userTokenState}
                    isShowDateRangeComponent={isShowDateRangeComponent}
                    setShowDateRangeComponent={setShowDateRangeComponent}
                  />
                </Fragment>
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              {timeFrameId === "1" && ( //if PERIOD
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIcontDateRangeText}
                  style={{ marginRight: 8, borderWidth: 0 }}
                  // onClick={() => setShowDateRangeComponent(true)}
                >
                  <b>{periodName}</b>
                </Button>
              )}

              {timeFrameId === "2" && ( //if DATE RANGE
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIcontDateRangeText}
                  style={{ marginRight: 8, borderWidth: 0 }}
                  onClick={() => setShowDateRangeComponent(true)}>
                  <b>
                    {moment(startDateRangeState).format("DD MMMM YYYY")} -{" "}
                    {moment(endDateRangeState).format("DD MMMM YYYY")}
                  </b>
                </Button>
              )}

              {timeFrameId === "3" && ( //if DUE DATE
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIcontDateRangeText}
                  style={{ marginRight: 8, borderWidth: 0 }}
                  onClick={() => setShowDueDateComponentDirectly(true)}>
                  {/* <b>{ moment(endDateRangeState).format('DD MMMM YYYY') } - { moment(endDateRangeState).format('DD MMMM YYYY') }</b> */}
                  {/* <b>{ moment(endDateRangeState).format('DD MMMM YYYY') }</b> */}
                  <b>{moment(selectedDueDate).format("DD MMMM YYYY")}</b>
                </Button>
              )}
            </Grid>
          </Grid>
          <br />

          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "grey" }}>
            <b>Deskripsi (Optional)</b>
          </Typography>

          <TextField
            onChange={handleChangeDescription}
            style={{ marginTop: 0 }}
            id="outlined-multiline-static-description"
            // label="Multiline"
            multiline
            rows="4"
            // defaultValue="Default Value"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            fullWidth
            placeholder={description}
            value={description}
          />

          <br />
          <br />
          <Grid container>
            <Grid item sm={6} md={6}>
              {positionName !== "" && (
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIconInModal}>
                  <IconButton style={{ background: "#edcfd8" }}>
                    <i
                      className="material-icons"
                      style={{ color: "white", fontSize: 14 }}>
                      person
                    </i>
                  </IconButton>
                  &nbsp;
                  <b>{positionName}</b>
                </Button>
              )}

              {positionName === "" && memberPositionList.length === 0 && (
                <Button
                  onClick={() => Redirect(ToSOTable)}
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIconInModal}
                  style={{ marginTop: 24 }}>
                  <IconButton style={{ background: "#edcfd8" }}>
                    <i
                      className="material-icons"
                      style={{ color: "white", fontSize: 14 }}>
                      person
                    </i>
                  </IconButton>
                  &nbsp;
                  <b>Belum ada posisi ;(</b>
                </Button>
              )}

              {positionName === "" && memberPositionList.length > 0 && (
                <Button
                  onClick={
                    disabledButton == true
                      ? () => console.log("Disabled Button ! ")
                      : handleShowDropdownPosition
                  }
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIconInModal}
                  style={{ marginTop: 24 }}>
                  <IconButton style={{ background: "#edcfd8" }}>
                    <i
                      className="material-icons"
                      style={{ color: "white", fontSize: 14 }}>
                      person
                    </i>
                  </IconButton>
                  &nbsp;
                  <b>
                    {memberPositionList.length > 0 && textValuePosition === ""
                      ? memberPositionList[0].structure_position_title_name
                      : textValuePosition}
                  </b>
                </Button>
              )}

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElPosition}
                keepMounted
                open={Boolean(anchorElPosition)}
                onClose={handleCloseDropdownPosition}>
                {memberPositionList.length > 0 &&
                  memberPositionList.map((item, i) => {
                    return (
                      <StyledMenuItem
                        key={i}
                        onClick={(e) => handleChoosePosition(e, item)}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle2"
                              className={classes.title}>
                              <b>{item.structure_position_title_name}</b>
                            </Typography>
                          }
                        />
                      </StyledMenuItem>
                    );
                  })}
              </StyledMenu>
            </Grid>

            <Grid item sm={6} md={6}>
              {isShowTextFieldTargetRangeValue === true ? (
                <Fragment>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    <b>Target Range Value : </b>
                  </Typography>
                  <Box>
                    <TextField
                      placeholder="0"
                      type="number"
                      id="outlined-bare"
                      // onChange= { handleChangeStartValue }
                      onChange={handleChangeTargetRangeValue}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              variant="subtitle1"
                              className={classes.title}
                              style={{ color: "grey" }}>
                              {isShowPercent === true ? <b>%</b> : null}
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                      disabled={
                        selectedCalculationMethod.code ===
                        SWD_MA_CALCULATION_METHOD_AVERAGE
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Fragment>
              ) : (
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      // color="primary"
                      size="small"
                      checked={evidance}
                      onChange={(e) => setEvidance(e.target.checked)}
                      disabled={statusUsedACTIVE === true ? true : false}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "grey" }}>
                      Wajib lampirkan bukti gambar
                    </Typography>
                  }
                  labelPlacement="end"
                />
              )}
            </Grid>
          </Grid>

          <br />
          <Grid container>
            <Grid item sm={6} md={6}>
              <Box marginRight={theme.spacing(0.3)}>
                <Typography
                  variant="subtitle1"
                  className={classes.title}
                  style={{ color: "grey" }}>
                  <b>Start value</b>
                </Typography>
                <TextField
                  id="outlined-bare"
                  onChange={handleChangeStartValue}
                  value={startValue}
                  variant="outlined"
                  inputProps={{
                    className: classes.title,
                  }}
                  disabled={
                    selectedCalculationMethod.code ===
                    SWD_MA_CALCULATION_METHOD_AVERAGE
                      ? true
                      : false
                  }
                />
              </Box>
            </Grid>

            <Grid item sm={6} md={6}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}>
                <b>Target Value</b>
              </Typography>
              <TextField
                id="outlined-bare"
                onChange={handleChangeNilaiTarget}
                value={nilaiTarget}
                variant="outlined"
                inputProps={{
                  className: classes.title,
                }}
              />
            </Grid>
          </Grid>

          <br />
          <br />
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "grey" }}>
            <b>Achievement Type :</b>
          </Typography>
          <List>
            {achievementTypeCollections.length > 0 &&
              achievementTypeCollections.map((item, i) => (
                <Fragment key={i}>
                  <Radio
                    checked={selectedAchievementType.code == item.code}
                    onChange={(e) =>
                      handleChangeRadioButtonAchievementType(e, item)
                    }
                    value={item.id}
                    name={item.name}
                  />
                  <span style={{ fontFamily: "Roboto", color: "grey" }}>
                    {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </Fragment>
              ))}
          </List>

          <br />
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "grey" }}>
            <b>Input Method :</b>
          </Typography>

          {/* <Typography variant='subtitle2' className={classes.title} style={{color: 'grey'}}>
                        <i>{Capitalize(selectedInputMethod.code) + ' : '} {resultValue} </i>
                    </Typography> */}

          <List>
            {inputMethodList.length > 0 &&
              inputMethodList.map((item, i) => (
                <Fragment key={i}>
                  <Radio
                    checked={selectedInputMethod.code == item.code}
                    onChange={(e) =>
                      handleChangeRadioButtonInputMethod(e, item)
                    }
                    value={item.id}
                    name={item.name}
                  />
                  <span style={{ fontFamily: "Roboto", color: "grey" }}>
                    {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </Fragment>
              ))}
          </List>

          <br />
          <LightTooltip
            title={
              <div>
                Pilihan <i>Numeric</i>: Data input berupa angka aktual. <br />
                Pilihan <i>Percentage</i>: Data input berupa persen
              </div>
            }
            placement="top">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Value Type : </b>
              {/* <i>{Capitalize(selectedValueType.code)}</i> */}
            </Typography>
          </LightTooltip>

          <List>
            {inputValueTypeList.length > 0 &&
              inputValueTypeList.map((item, i) => (
                <Fragment key={i}>
                  <Radio
                    checked={selectedValueType.code === item.code}
                    onChange={(e) => handleChangeRadioButtonValueType(e, item)}
                    value={item.id}
                    name={item.name}
                  />
                  <span style={{ fontFamily: "Roboto", color: "grey" }}>
                    {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                  </span>
                </Fragment>
              ))}
          </List>

          <br />
          <LightTooltip
            title={
              <div>
                SUM: Penjumlahan dari semua Goal Result. <br />
                AVG: Rata-rata dari semua Goal Result <br />
                LAST VALUE: Hanya untuk "Input Method - Total"
              </div>
            }
            placement="top">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Calculation Method :</b>
            </Typography>
          </LightTooltip>

          <List>
            {calculationMethodList.length > 0 &&
              calculationMethodList.map((item, i) => {
                return (
                  <Fragment key={i}>
                    <Radio
                      checked={selectedCalculationMethod.code === item.code}
                      onChange={(e) => handleChangeRadioButton(e, item)}
                      value={item.id}
                      name={item.name}
                      // disabled={item.code == 'last_value' ? true : false }
                    />
                    <span style={{ fontFamily: "Roboto", color: "grey" }}>
                      {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                    </span>
                  </Fragment>
                );
              })}
          </List>

          <br />
          <LightTooltip
            title={
              <div>
                Daily: Perhitungan yang digunakan adalah target di hari yang
                sedang berjalan <br />
                Final: Perhitungan yang digunakan adalah target di akhir periode
              </div>
            }
            placement="top">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Target Type :</b>
            </Typography>
          </LightTooltip>

          <List>
            {targetTypeList.length > 0 &&
              targetTypeList.map((item, i) => {
                return (
                  <Fragment key={i}>
                    <Radio
                      checked={selectedTargetType.code === item.code}
                      onChange={(e) => handleSelectTargetType(e, item)}
                      value={item.id}
                      name={item.name}
                    />
                    <span style={{ fontFamily: "Roboto", color: "grey" }}>
                      {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                    </span>
                  </Fragment>
                );
              })}
          </List>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            onClick={handleSUBMITGoal}
            variant="contained"
            className={classes.button}
            fullWidth
            // disabled={disabledButton == true ? true : false}
          >
            {loader == true ? (
              <CircularProgress size={20} style={{ color: "white" }} />
            ) : (
              " Simpan perubahan"
            )}
          </Button>
        </DialogActions>
        <br />

        <DialogError
          classes={classes}
          isOpenDialogError={isOpenDialogError}
          setOpenDialogError={setOpenDialogError}
          textErrorInformation={textErrorInformationValue}
          errorStatus={errorStatus}
          listError={listError}
        />
      </Dialog>

      <Snackbeer
        classes={classes}
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages="Berhasil memperbaharui Measured Activity :)"
      />
    </Fragment>
  );
};

export default DialogEditMA;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },

  typography: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  textfield: {
    width: 200,
  },
});

import React, { useState } from "react";
import {
  Paper,
  Box,
  Typography,
  IconButton,
  Menu,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import clsx from "clsx";

import Table from "./Table";
import ListItemMenu from "./ListItemMenu";

const TableSection = ({ classes }) => {
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [rate, setRate] = useState({ goal: false, ma: false });

  const handleShowAnchorMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleCloseAnchorMenu = () => {
    setAnchorMenu(null);
  };

  const handleRate = (event) => {
    setRate({ ...rate, [event.target.name]: event.target.checked });
  };

  return (
    <Paper className={classes.shadowSection}>
      <Box padding={3} paddingBottom={0}>
        {/* ====== Title & Icon More ====== */}
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title333} variant="subtitle1">
            <b>Summary Table</b>
          </Typography>

          <Box display="flex">
            <FormControlLabel
              label={
                <Typography variant="caption" clasName={classes.title555}>
                  Goal Input
                </Typography>
              }
              control={
                <Checkbox
                  className={classes.checkboxRoot}
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                  checked={rate.goal}
                  name="goal"
                  onChange={handleRate}
                />
              }
            />

            <FormControlLabel
              label={
                <Typography variant="caption" clasName={classes.title555}>
                  MA Input
                </Typography>
              }
              control={
                <Checkbox
                  className={classes.checkboxRoot}
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                  checked={rate.ma}
                  name="ma"
                  onChange={handleRate}
                />
              }
            />

            <IconButton
              size="small"
              onClick={handleShowAnchorMenu}
              style={{ marginLeft: 30 }}>
              <MoreHorizIcon fontSize="small" style={{ color: "#888" }} />
            </IconButton>

            <Menu
              id="simple-menu"
              anchorEl={anchorMenu}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              getContentAnchorEl={null}
              open={Boolean(anchorMenu)}
              onClose={handleCloseAnchorMenu}>
              <ListItemMenu
                classes={classes}
                setAnchorMenu={setAnchorMenu}
                rate={rate}
              />
            </Menu>
          </Box>
        </Box>

        {/* ====== Table ====== */}
        <Box marginTop={4}>
          <Table classes={classes} rate={rate} />
        </Box>
      </Box>
    </Paper>
  );
};

export default TableSection;

import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useRef,
} from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  TextField,
  FormControl,
  IconButton,
  Box,
  Paper,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

import moment from "moment";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import CardFirstIconPersonName from "./CardFirstIconPersonName";
import CardSecondTextField from "./CardSecondTextField";
import CardFourth from "./CardFourth";
import CardFifthTextfieldDescription from "./CardFifthTextfieldDescription";
import CardSixTargetType from "./CardSixTargetType";
import ContextGoalDetail from "../Context/ContextGoalDetail";

import { styles } from "../Style/StyleSubGoal";

import Snackbeer from "../../../../components/Snackbeer";
import DialogError from "../../../../components/DialogError";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import CompareAndReturnMatch from "../../../../utilities/CompareAndReturnMatch";
import Capitalize from "../../../../utilities/Capitalize";
import { object } from "prop-types";
import CardSevenEvidence from "./CardSevenEvidence";

const useStyles = makeStyles(styles);

const DialogCreateDirectSubGoal = (props) => {
  const {
    setModalPilihUser,
    listOwner,
    selected,
    setFireGoalDetail,
    goalDetailState,
  } = props;

  const classes = useStyles();
  // const theme = useTheme();
  const context = useContext(ContextGoalDetail);
  let textInputReff = useRef(null);

  /*
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */
  const [loader, setLoader] = useState(false);
  const [listOwnerChoosed, setListOwnerChoosed] = useState([]);
  const [listOwnerChoosedLength, setListOwnerChoosedLength] = useState(1);

  const DUE_DATE = "due_date";

  const [timeFrame, setTimeFrame] = useState({
    id: "",
    code: "",
    name: "",
  });

  useEffect(() => {
    if (context.isOpenDialogCreateDirectSubGoal === true) {
      context.setSuccessCreateSubGoal(false);
      setErrorGoalStringKosong(false);

      setTargetValueNow([]);
      setStartValueNow([]);
      setTargetRangeValueNow([]);
      setShowTargetRangeValue(false);

      //*
      const listOkey = CompareAndReturnMatch(listOwner, selected);
      setListOwnerChoosed(listOkey);

      //*
      setListOwnerChoosedLength(listOkey.length);

      //*
      if (context.userTokenStateGlobal !== undefined) {
        axiosConfig
          .get(`${URL_API}/swd/goal/${context.goalDetailStateGlobal.id}`)
          .then(function (response) {
            console.log("Response Original GOAL CREATE : ", response);

            if (response.status === 200) {
              if (response.data.data !== null) {
                context.setGoalDetailStateGlobal(response.data.data);

                if (
                  response.data.data.time_frame_id !== null &&
                  response.data.data.time_frame_id !== undefined
                ) {
                  context.setTimeFrameId(response.data.data.time_frame_id);
                }

                //*
                if (
                  response.data.data.timeFrame !== null &&
                  response.data.data.timeFrame !== undefined
                ) {
                  setTimeFrame(response.data.data.timeFrame);
                }

                if (
                  response.data.data.calculatedValue !== null &&
                  response.data.data.calculatedValue !== undefined
                ) {
                  context.setStartValue(
                    response.data.data.calculatedValue.start_value
                  );
                  context.setTargetValue(
                    response.data.data.calculatedValue.target_value
                  );
                }

                if (
                  response.data.data.name !== null &&
                  response.data.data.name !== undefined
                ) {
                  context.setGoalName(response.data.data.name);
                }

                // context.
              }
            }
          })
          .catch(function (error) {
            console.log("Error : ", error.response);
          });
      }
    }
  }, [context.isOpenDialogCreateDirectSubGoal]);

  /*  
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  /*
        ``````````````````````
        HANDLE SET BIGGER FORM

        ``````````````````````
    */
  const [isBiggerForm, setBiggerForm] = useState(false);
  const handleToggleSwitch = () => {
    setBiggerForm((prev) => !prev);
  };

  /*
        `````````````````````````
        HANDLE DUE DATE COMPONENT

        `````````````````````````
    */
  const [isShowDueDateComponent, setShowDueDateComponent] = useState(false);
  const [
    isShowDueDateComponentDirectly,
    setShowDueDateComponentDirectly,
  ] = useState(false);

  const handleChooseDueDate = () => {
    // setEndDateRangeState(new Date()) //*Baris kode ini untuk validasi DISABLED Button saja !
    context.setTimeFrameId("3");

    handleCloseTimeFrame();
    setShowDueDateComponent(true);
    setShowDueDateComponentDirectly(true);

    // setShowDateRangeComponent(false);
    // setShowTextDateRange(false);
    // setTextValueTimeFrame('Due Date');
    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");
  };

  /*
        ```````````````````````````
        HANDLE DATE RANGE COMPONENT

        ```````````````````````````
    */
  const [isShowTextDateRange, setShowTextDateRange] = useState(false);
  const [isShowDateRangeComponent, setShowDateRangeComponent] = useState(false);

  const handleChooseRange = () => {
    context.setTimeFrameId("2");
    setShowDateRangeComponent(true);
  };

  /*
        ```````````````````````````````````
        HANDLE PERIOD LIST STYLED MENU ITEM

        ```````````````````````````````````
    */
  const [isShowPeriodComponent, setShowPeriodComponent] = useState(false);
  const [anchorElPeriod, setAnchorElPeriod] = useState(null);

  function handleChoosePeriod(event) {
    //*Fungsi setAnchor di sini sudah di pakai langsung di 'handleChoosePeriod()'

    setAnchorElPeriod(event.currentTarget);
    setShowPeriodComponent(true);

    handleCloseTimeFrame();
  }

  function handleClosePeriod() {
    setAnchorElPeriod(null);
  }

  const handlePilihPeriod = (e, data) => {
    context.setPeriod(data);
    context.setTimeFrameId("1");
    handleClosePeriod();
  };

  /*
      `````````````````````````````````````````````````````````````````````````````````````````
      HANDLE INTERACTION

          - Ketika time frame DUE_DATE

          - Ketika value setiap text field Start Value tidak persis dengan TOTAL Start Value

          - Ketika value setiap text field Target Value tidak persis dengan TOTAL Target Value

      `````````````````````````````````````````````````````````````````````````````````````````
  */

  useEffect(() => {
    //*
    if (timeFrame.code === DUE_DATE) {
      setShowDueDateComponent(true);
    }
  }, [timeFrame]);

  // const [ startDateRangeState, setStartDateRangeState ] = useState(null);//*Move to Global
  // const [ endDateRangeState, setEndDateRangeState ] = useState(null);//*Move to Global

  const startDateRange = localStorage.getItem("start_date");
  const endDateRange = localStorage.getItem("end_date");

  useEffect(() => {
    context.setStartDateRangeState(startDateRange);
    context.setEndDateRangeState(endDateRange);
  }, [startDateRange, endDateRange]);

  /*
        ```````````````````````
        HANDLE CHANGE GOAL NAME

        ```````````````````````
    */

  const [valueGoalName, setValueGoalName] = useState([]);
  const handleChangeNameSubGoal = (e, i) => {
    e.preventDefault();

    setValueGoalName({ ...valueGoalName, [i]: e.target.value });

    if (e.target.value === "") {
      context.setGoalName(context.goalNameLocalStorage);
      setValueGoalName([]);
    }
  };

  /*
        ```````````````````````````````````````````````````
        HANDLE CHANGE START VALUE & TARGET VALUE - STARTING

        ```````````````````````````````````````````````````
    */

  const [startValueNow, setStartValueNow] = useState({});
  const handleChangeStartValueNow = (e, i) => {
    e.preventDefault();
    setStartValueNow({ ...startValueNow, [i]: e.target.value });

    console.log("Start value : ", e.target.value);

    console.log(" context.targetValue : ", context.targetValue);

    if (parseInt(e.target.value) === parseInt(context.targetValue)) {
      setShowTargetRangeValue(true);
    } else {
      setShowTargetRangeValue(false);
      // setTargetValueNow([])
    }

    /*
            ``````````````````````````````````````````````````````````````````````````````````
            Hiraukan ini, only for interaction ketika same bewtween start value & target value

            ``````````````````````````````````````````````````````````````````````````````````
        */

    context.setStartValue(e.target.value);

    if (listOwnerChoosedLength > 1) {
      if (
        parseInt(e.target.value) ===
        parseInt(context.targetValue) / listOwnerChoosedLength
      ) {
        setShowTargetRangeValue(true);
      } else {
        setShowTargetRangeValue(false);
      }
    }
  };

  const [targetValueNow, setTargetValueNow] = useState({});
  const handleChangeTargetValueNow = (e, i) => {
    // console.log("Target value : ", e.target.value);
    // console.log(" context.startValue : ",  context.startValue)

    e.preventDefault();
    setTargetValueNow({ ...targetValueNow, [i]: e.target.value });

    if (parseInt(e.target.value) === parseInt(context.startValue)) {
      setShowTargetRangeValue(true);
    } else {
      setShowTargetRangeValue(false);
      // setTargetValueNow([])
    }

    /*
            ``````````````````````````````````````````````````````````````````````````````````
            Hiraukan ini, only for interaction ketika same bewtween start value & target value

            ``````````````````````````````````````````````````````````````````````````````````
        */
    context.setTargetValue(e.target.value);

    if (listOwnerChoosedLength > 1) {
      if (
        parseInt(e.target.value) ==
        parseInt(context.startValue) / listOwnerChoosedLength
      ) {
        setShowTargetRangeValue(true);
      } else {
        setShowTargetRangeValue(false);
      }
    }
  };

  useEffect(() => {
    if (
      Object.entries(startValueNow).length > 0 &&
      Object.entries(targetValueNow).length > 0
    ) {
      console.log("Inisiate !");
      console.log("startValueNow : ", startValueNow);
      console.log("targetValueNow : ", targetValueNow);

      if (JSON.stringify(startValueNow) === JSON.stringify(targetValueNow)) {
        console.log("Same !");
        setShowTargetRangeValue(true);
      } else {
        console.log("Not Same !");
        setShowTargetRangeValue(false);
      }
    }
  }, [targetValueNow, startValueNow]);

  /*
        ````````````````````````````````````
        HANDLE SUBMIT CREATE DIRECT SUB GOAL
        
        ````````````````````````````````````
    */

  const [errorGoalStringKosong, setErrorGoalStringKosong] = useState(false);
  const [errorGoalStartDate, setErrorGoalStartDate] = useState(false);
  const [errorGoalEndDate, setErrorGoalEndDate] = useState(false);

  //   console.log("TARGET TYPEID", context.goalDetailStateGlobal.targetType.id);

  // console.log("StartDAte", context.startDateRangeState);
  // console.log("EndDAte", context.endDateRangeState);

  const handleSubmit = () => {
    setLoader(true);

    const list = [];
    listOwnerChoosed.length > 0 &&
      listOwnerChoosed.map((item, i) => {
        const readyData = {
          name:
            valueGoalName[i] !== undefined
              ? valueGoalName[i]
              : `${context.goalName} (Sub-Goal ${i + 1})`,

          owner_id: item.id,
          period_id: context.period.id,
          start_date: moment(goalDetailState.start_date).format("YYYY-MM-DD"),
          end_date: moment(goalDetailState.end_date).format("YYYY-MM-DD"),

          start_value:
            startValueNow[i] !== undefined && startValueNow[i] !== ""
              ? startValueNow[i]
              : context.startValue / listOwnerChoosedLength,
          target_value:
            targetValueNow[i] !== undefined && targetValueNow[i] !== ""
              ? targetValueNow[i]
              : context.targetValue / listOwnerChoosedLength,
          target_range_value:
            targetRangeValueNow[i] !== undefined && targetRangeValueNow !== []
              ? targetRangeValueNow[i]
              : "",
          time_frame_id: goalDetailState.time_frame_id, // context.timeFrameId
          description:
            valueDescriptionNow[i] !== undefined ? valueDescriptionNow[i] : "-",
          target_type_id:
            targetType[i] !== undefined
              ? targetType[i]
              : context.goalDetailStateGlobal.targetType.id,
          is_evidence_required:
            evidence[i] !== undefined && evidence[i] === true ? 1 : 0,
        };

        if (readyData.period_id === "") {
          delete readyData.period_id;
        }

        if (
          readyData.target_range_value === "" ||
          readyData.target_range_value === undefined ||
          readyData.target_range_value === []
        ) {
          delete readyData.target_range_value;
        }

        if (readyData.name === "") {
          setErrorGoalStringKosong(true);
        }

        list.push(readyData);
      });

    let data = {
      Goal: list,
    };

    console.log("Data submit : ", data);

    // *
    axiosConfig
      .post(`${URL_API}/swd/goal/${goalDetailState.id}/direct-link/batch`, data)
      .then(function (response) {
        console.log("Response Original Create Sub Goal : ", response);

        context.setSuccessCreateSubGoal(true);
        context.setOpenDialogCreateDirectSubGoal(false);
        setModalResponse200(true);

        setFireGoalDetail(true);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        console.log("Error : ", error.response);

        if (error.response !== undefined) {
          if (error.response.status === 500) {
            setErrorStatus(500);
            setOpenDialogError(true);
            setTextErrorInformation(
              `Error ${error.response.status} : ` +
                Capitalize(error.response.statusText)
            );
          }

          if (error.response.status === 400) {
            setErrorStatus(400);

            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setListError(error.response.data.info.errors[0]);
                setTextErrorInformation(".");
                setOpenDialogError(true);
              }
            }
          }
        }
      });
  };

  /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */
  const [isModalResponse200, setModalResponse200] = useState(false);

  /*
        ````````````````
        HANDLE DESKRIPSI

        ````````````````
    */
  const [isShowDescription, setShowDescription] = useState(false);

  const [valueDescriptionNow, setValueDescriptionNow] = useState([]);

  const handleChangeDescription = (e, i) => {
    e.preventDefault();
    setValueDescriptionNow({ ...valueDescriptionNow, [i]: e.target.value });
  };

  /*
        ``````````````````````````````
        HANDLE SPEED DIAL & TIME FRAME

        ``````````````````````````````
    */
  const [anchorElTimeFrame, setAnchorElTimeFrame] = useState(null);

  function handleClickTimeFrame(event) {
    setAnchorElTimeFrame(event.currentTarget);
  }

  function handleCloseTimeFrame() {
    setAnchorElTimeFrame(null);
  }

  /*
        `````````````````````````````````````````````````````
        HANDLE ERROR TEXT IF START/ END DATE VALUE TIDAK SAMA

        `````````````````````````````````````````````````````

                ? ? ? 
                ? ? ? 
                ? ? ? 
    */

  /*
        ``````````````````````````
        HANDLE TARGET RANGE VALUE

        `````````````````````````
    */
  const [isShowTargetRangeValue, setShowTargetRangeValue] = useState(false);

  const [targetRangeValueNow, setTargetRangeValueNow] = useState([]);
  const handleChangeTargetRangeValueNow = (e, i) => {
    e.preventDefault();
    setTargetRangeValueNow({ ...targetRangeValueNow, [i]: e.target.value });
  };

  useEffect(() => {
    if (isShowTargetRangeValue === true) {
      setTimeout(() => {
        if (textInputReff !== null) {
          if (textInputReff.current !== null) {
            textInputReff.current.focus();
          }
        }
      }, 100);
    }
  }, [isShowTargetRangeValue]);

  //  ===================================================
  // TARGET TYPE

  const [targetType, setTargetType] = useState({});

  const handleSelectTargetType = (e, i) => {
    e.preventDefault();
    setTargetType({ ...targetType, [i]: e.target.value });
  };

  // ===================================================
  // IS EVIDENCE REQUIRED
  const [evidence, setEvidence] = useState({});

  const handleEvidenceRequired = (e, i) => {
    e.preventDefault();
    setEvidence({ ...evidence, [i]: e.target.checked });
  };

  return (
    <Fragment>
      <Dialog
        open={context.isOpenDialogCreateDirectSubGoal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={isBiggerForm === true ? "md" : "sm"}>
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="h6" className={classes.title}>
                <b>
                  <i>Create Direct Sub-GOAL</i>
                </b>
              </Typography>
            </Box>
            <Box marginTop={-2} marginRight={-2}>
              <IconButton
                size="small"
                onClick={() => {
                  context.setOpenDialogCreateDirectSubGoal(false);
                  setModalPilihUser(true);
                }}>
                <HighlightOffIcon style={{ color: "grey", fontSize: "17px" }} />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Paper elevation={0} style={{ backgroundColor: grey[100] }}>
            <Box padding={2}>
              <Grid container>
                <Grid item md={4}>
                  <Typography variant="subtitle1" className={classes.title}>
                    <b>Achievement Type</b>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    <i>
                      {goalDetailState.achievementType &&
                        Capitalize(goalDetailState.achievementType.name)}
                    </i>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="subtitle1" className={classes.title}>
                    <b>Input Method</b>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    <i>
                      {goalDetailState.inputMethod &&
                        Capitalize(goalDetailState.inputMethod.name)}
                    </i>
                  </Typography>
                </Grid>

                <Grid item md={4}>
                  <Typography variant="subtitle1" className={classes.title}>
                    <b>Calculation Method</b>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    <i>
                      {goalDetailState.calculationMethod &&
                        Capitalize(goalDetailState.calculationMethod.name)}
                    </i>
                  </Typography>
                </Grid>
              </Grid>
              <br />

              <Grid container>
                <Grid item md={4}>
                  <Typography variant="subtitle1" className={classes.title}>
                    <b>Value Type</b>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    <i>
                      {goalDetailState.valueType &&
                        Capitalize(goalDetailState.valueType.name)}{" "}
                    </i>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="subtitle1" className={classes.title}>
                    <b>Start Value</b>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    <i>{goalDetailState.calculatedValue.start_value}</i>
                  </Typography>
                </Grid>

                <Grid item md={4}>
                  <Typography variant="subtitle1" className={classes.title}>
                    <b>Target Value</b>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    <i>{goalDetailState.calculatedValue.target_value}</i>
                  </Typography>
                </Grid>
              </Grid>

              <br />

              <Grid container>
                <Grid item md={4}>
                  <Typography variant="subtitle1" className={classes.title}>
                    <b>Target Type</b>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    <i>{goalDetailState.targetType.name}</i>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <br />

          <Grid container>
            <Grid item md={12}>
              <List className={classes.listRoot}>
                {listOwnerChoosed.length > 0 ? (
                  listOwnerChoosed.map((item, i) => {
                    return (
                      <Fragment key={i}>
                        <CardFirstIconPersonName
                          classes={classes}
                          item={item}
                          i={i}
                        />

                        <CardSecondTextField
                          context={context}
                          classes={classes}
                          item={item}
                          i={i}
                          valueGoalName={valueGoalName}
                          handleChangeNameSubGoal={handleChangeNameSubGoal}
                          listOwnerChoosedLength={listOwnerChoosedLength}
                          startValueNow={startValueNow}
                          handleChangeStartValueNow={handleChangeStartValueNow}
                          targetValueNow={targetValueNow}
                          handleChangeTargetValueNow={
                            handleChangeTargetValueNow
                          }
                          goalDetailState={goalDetailState}

                          //*TARGET RANGE VALUE
                          // isShowTargetRangeValue = { isShowTargetRangeValue }
                          // textInputReff = { textInputReff }

                          // handleChangeTargetRangeValueNow = { handleChangeTargetRangeValueNow }
                          // targetRangeValueNow = { targetRangeValueNow }
                        />

                        {/* ====================================
                            EDIT TARGET TYPE
                          ==================================== */}

                        <CardSixTargetType
                          i={i}
                          classes={classes}
                          targetType={targetType}
                          handleSelectTargetType={handleSelectTargetType}
                        />

                        {/*  
                            ``````````````````
                            TARGET RANGE VALUE

                            ``````````````````                                                
                        */}

                        {isShowTargetRangeValue === true && (
                          <Grid container key={i}>
                            <Grid item md={12}>
                              <TextField
                                key={i}
                                // inputRef = { textInputReff }
                                onChange={(e) =>
                                  handleChangeTargetRangeValueNow(e, i)
                                }
                                value={targetRangeValueNow[i]}
                                type="number"
                                style={{ marginTop: 0 }}
                                id="outlined-multiline-static-description"
                                label={
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.title}>
                                    Target Range Value
                                  </Typography>
                                }
                                // multiline
                                // rows="4"
                                // margin="normal"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                  "aria-label": "Target Range Value",
                                  className: classes.title,
                                }}
                              />
                            </Grid>
                          </Grid>
                        )}

                        {/* <CardThirdTextFieldTargetRangeValue 

                          classes = { classes }
                          i = { i }

                          isShowTargetRangeValue = { isShowTargetRangeValue }
                          textInputReff = { textInputReff }

                          handleChangeTargetRangeValueNow = { handleChangeTargetRangeValueNow }
                          targetRangeValueNow = { targetRangeValueNow }

                      /> */}

                        <CardFourth
                          classes={classes}
                          context={context}
                          i={i}
                          isShowDescription={isShowDescription}
                          setShowDescription={setShowDescription}
                          handleChooseRange={handleChooseRange}
                          isShowDueDateComponent={isShowDueDateComponent}
                          isShowDateRangeComponent={isShowDateRangeComponent}
                          setShowDateRangeComponent={setShowDateRangeComponent}
                          setShowDueDateComponent={setShowDueDateComponent}
                          handleCloseTimeFrame={handleCloseTimeFrame}
                          isShowDueDateComponentDirectly={
                            isShowDueDateComponentDirectly
                          }
                          setShowDueDateComponentDirectly={
                            setShowDueDateComponentDirectly
                          }
                          handleClickTimeFrame={handleClickTimeFrame}
                          anchorElTimeFrame={anchorElTimeFrame}
                          handleChooseDueDate={handleChooseDueDate}
                          handleChoosePeriod={handleChoosePeriod}
                          anchorElPeriod={anchorElPeriod}
                          handleClosePeriod={handleClosePeriod}
                          isShowPeriodComponent={isShowPeriodComponent}
                          handlePilihPeriod={handlePilihPeriod}
                          goalDetailState={goalDetailState}
                        />

                        <CardFifthTextfieldDescription
                          classes={classes}
                          i={i}
                          isShowDescription={isShowDescription}
                          handleChangeDescription={handleChangeDescription}
                          valueDescriptionNow={valueDescriptionNow}
                        />

                        <CardSevenEvidence
                          classes={classes}
                          i={i}
                          evidence={evidence}
                          handleEvidenceRequired={handleEvidenceRequired}
                        />
                      </Fragment>
                    );
                  })
                ) : (
                  <Typography variant="subtitle2" className={classes.title}>
                    <b>Anda belum memilih Owner Goal !</b>
                  </Typography>
                )}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item md={6}>
              <FormControl component="fieldset" style={{ marginLeft: 16 }}>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="end"
                    control={
                      <Switch
                        checked={isBiggerForm}
                        size="small"
                        color="primary"
                        onChange={handleToggleSwitch}
                      />
                    }
                    label={
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: "grey" }}>
                        <i>
                          {isBiggerForm === true
                            ? "Perkecil "
                            : "Perbesar FORM"}
                        </i>
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item md={6} style={{ textAlign: "right" }}>
              <Button
                onClick={handleSubmit}
                variant="contained"
                className={classes.button}
                // fullWidth
                disabled={
                  context.startDateRangeState === null ||
                  context.endDateRangeState === null
                    ? true
                    : false
                }
                style={{ marginRight: 16 }}>
                {loader !== true ? (
                  "Tambah"
                ) : (
                  <CircularProgress size={20} style={{ color: "white" }} />
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <DialogError
        classes={classes}
        setOpenDialogError={setOpenDialogError}
        isOpenDialogError={isOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />

      <Snackbeer
        classes={classes}
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages="Berhasil membuat Sub-Goal :)"
      />
    </Fragment>
  );
};

export default DialogCreateDirectSubGoal;

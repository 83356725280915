import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Box,
  TextField,
  FormControl,
  Menu,
  MenuItem,
  Select,
} from "@material-ui/core";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import DialogError from "../../../../components/DialogError";
import ContextReports from "../../context/ContextReports";
import handleError from "../global/handleError";
import { BootstrapInput } from "./detailed-type/grouping/group-by/BootstrapInput";

const DialogShareReport = ({
  classes,
  openDialogShareReport,
  setOpenDialogShareReport,
}) => {
  const [format, setFormat] = useState({ code: "pdf", name: "PDF" });

  const handleChangeFormat = (e) => {
    setFormat(e.target.value);
  };

  const handleShareReport = () => {};

  return (
    <Dialog
      open={openDialogShareReport}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Share Report</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ textAlign: "left", width: 600 }}>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2" className={classes.title888}>
            <b>Title</b>
          </Typography>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            style={{ marginLeft: 20 }}
          />
        </Box>

        <Box mt={6}>
          <Box display="flex" mb={2}>
            <Typography
              variant="subtitle2"
              className={classes.title888}
              style={{ width: 150 }}>
              <b>Recipients Email</b>
            </Typography>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              style={{ marginLeft: 30 }}
            />
          </Box>
          <Box display="flex" mb={2}>
            <Typography
              variant="subtitle2"
              className={classes.title888}
              style={{ width: 150 }}>
              <b>Format</b>
            </Typography>
            <FormControl>
              <Select
                value={format}
                onChange={handleChangeFormat}
                style={{ marginLeft: -6 }}
                input={<BootstrapInput />}>
                {[
                  { code: "pdf", name: "PDF" },
                  { code: "xlsx", name: "XLSX" },
                ].map((item, i) => {
                  return (
                    <MenuItem
                      key={i}
                      dense
                      value={item.code}
                      className={classes.hoverItem}>
                      <Typography
                        variant="caption"
                        className={classes.title333}>
                        {item.name}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" mb={2}>
            <Typography
              variant="subtitle2"
              className={classes.title888}
              style={{ width: 150 }}>
              <b>Message</b>
            </Typography>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              multiline
              rows={5}
              style={{ marginLeft: 30 }}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions
        style={{
          backgroundColor: "#ECECEC",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: 15,
          marginTop: 20,
        }}>
        <Button
          variant="outlined"
          disableElevation
          size="medium"
          className={classes.button0}
          onClick={() => setOpenDialogShareReport(false)}>
          Cancel
        </Button>

        <Button
          variant="outlined"
          size="medium"
          className={classes.button1}
          onClick={handleShareReport}>
          {/* {loading ? "downloading..." : "Export"} */}
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogShareReport;

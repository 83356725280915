import React, { useState, Fragment, useContext } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Box,
  Button,
  List,
  Collapse,
  TablePagination,
  LinearProgress,
} from "@material-ui/core";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";

import ContextGoalDetail from "../../Goal/Context/ContextGoalDetail";

import PictLitleIconMA from "../../Goal/ComponentsGoalResult/PictEmptyStateGoalResult";

import DialogExportMaResult from "./DialogExportMaResult";

import ListItemMAResultList from "./ListItemMAResultList";
import DialogAddResultMA from "./DialogAddResultMA";

const numeral = require("numeral");
const SWD_MA_INPUT_METHOD_INCREMENT = "swd_ma_input_method_increment";
const SWD_MA_INPUT_METHOD_LAST_VALUE = "swd_ma_input_method_last_value";

const ListCollectionMaResult = (props) => {
  const {
    classes,
    collectionMaResultList,
    maResultLength,
    maDetailState,
    userTokenState,
    handleDropdownOpenMaResult,
    anchorElListMaResult,
    handleDropdownCloseMaResult,
    handleDialogDelete,
    loader,
    isMeasuredActivityDetailShow,
    setSuccessCreateMA,
  } = props;

  const theme = useTheme();
  const context = useContext(ContextGoalDetail);
  /*
        ```````````````
        HANDLE COLLAPSE

        ```````````````
    */
  const [openCollapse, setOpenCollapse] = useState(true);
  const [openDialogAddResultMA, setOpenDialogAddResultMA] = useState(false);

  function handleCollapse() {
    setOpenCollapse(!openCollapse);
  }

  /*
        ```````````````````````
        HANDLE EXPORT MA RESULT

        ```````````````````````
    */
  const [isModalExportMaResult, setModalExportMaResult] = useState(false);

  /*
    ===================================================================
      HANDLE PAGINATION //*Will be to global state
    ===================================================================
  */

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    context.setLoaderForPagingMAR(true);
    context.setPageMAR(newPage);
    context.setOffsetMAR((parseInt(newPage + 1) - 1) * context.rowsPerPageMAR);
  };

  const handleChangeRowsPerPage = (event) => {
    event.preventDefault();
    context.setLoaderForPagingMAR(true);

    // console.log("Rows perpage : ", event.target.value);
    context.setRowsPerPageMAR(parseInt(event.target.value, 8));
    context.setPageMAR(0);
  };

  // DELETE / EDIT POLICY (HAK AKSES)

  // console.log("PLISI FROM MAR LISt ORI", maDetailState.policy);

  return (
    <Fragment>
      <Grid container>
        <Grid item sm={6}>
          {collectionMaResultList.length > 0 && (
            <Typography variant="h6" className={classes.titleInLineChart}>
              <b>Measured Activity Result</b>
            </Typography>
          )}
        </Grid>

        <Grid item sm={6} style={{ textAlign: "right", paddingRight: 18 }}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => setOpenDialogAddResultMA(true)}>
            <AddIcon
              style={{ fontSize: 14, fontWeight: "bold" }}
              color="#fff"
            />{" "}
            Add Measured Activity Result
          </Button>
        </Grid>
      </Grid>

      <br />

      {collectionMaResultList.length === 0 &&
        maDetailState.status_id !== "4" &&
        loader === false && (
          <Paper className={classes.paperColumnDuaRowFirst}>
            <Grid container>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: "grey", margin: 8 }}>
                  <PictLitleIconMA />
                  Anda belum memiliki <i>MA Result</i> ;(
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        )}

      {collectionMaResultList.length > 0 && (
        <Paper className={classes.paperColumnDuaRowFirst}>
          {loader === true && (
            <div className={classes.rootLinearProgress}>
              <LinearProgress style={{ height: 2, color: "#d64253" }} />
            </div>
          )}

          {collectionMaResultList.length > 0 && (
            <Box display="flex">
              <Box
                flexGrow={1}
                width="85%"
                marginLeft={theme.spacing(0.1)}
                marginTop={theme.spacing(0.1)}>
                <Button
                  variant="outlined"
                  className={classes.buttonOutlined}
                  size="small"
                  endIcon={openCollapse ? <ExpandLess /> : <ExpandMore />}
                  onClick={handleCollapse}
                  style={{
                    borderColor: "transparent",
                    textTransform: "capitalize",
                  }}>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    Record
                  </Typography>
                </Button>
              </Box>

              <Box
                width="15%"
                marginTop={theme.spacing(0.1)}
                marginLeft={theme.spacing(0.1)}>
                <Button
                  onClick={() => setModalExportMaResult(true)}
                  variant="outlined"
                  className={classes.buttonOutlined}
                  style={{ borderWidth: 1 }}>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey", textTransform: "capitalize" }}>
                    Export
                  </Typography>
                </Button>
              </Box>
            </Box>
          )}

          <Grid container>
            <Grid item xs={12}>
              <List style={{ width: 120 }}>
                <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                  {collectionMaResultList.length > 0
                    ? collectionMaResultList.map((item, idx) => {
                        /*
                                                    `````````
                                                    #1,INCREMENT

                                                    `````````
                                                */

                        if (
                          maDetailState.inputMethod.code ===
                          SWD_MA_INPUT_METHOD_INCREMENT
                        ) {
                          return (
                            <ListItemMAResultList
                              classes={classes}
                              item={item}
                              idx={idx}
                              maDetailState={maDetailState}
                              setSuccessCreateMA={setSuccessCreateMA}
                              handleDropdownCloseMaResult={
                                handleDropdownCloseMaResult
                              }
                              handleDropdownOpenMaResult={
                                handleDropdownOpenMaResult
                              }
                              handleDialogDelete={handleDialogDelete}
                              isMeasuredActivityDetailShow={
                                isMeasuredActivityDetailShow
                              }
                              anchorElListMaResult={anchorElListMaResult}
                              userTokenState={userTokenState}
                              collectionMaResultList={collectionMaResultList}
                            />
                          );
                        }

                        /*
                                                    ``````````
                                                    #2,LAST VALUE

                                                    ``````````
                                                */

                        if (
                          maDetailState.inputMethod.code ===
                          SWD_MA_INPUT_METHOD_LAST_VALUE
                        ) {
                          return (
                            <ListItemMAResultList
                              classes={classes}
                              item={item}
                              idx={idx}
                              maDetailState={maDetailState}
                              setSuccessCreateMA={setSuccessCreateMA}
                              handleDropdownCloseMaResult={
                                handleDropdownCloseMaResult
                              }
                              handleDropdownOpenMaResult={
                                handleDropdownOpenMaResult
                              }
                              handleDialogDelete={handleDialogDelete}
                              isMeasuredActivityDetailShow={
                                isMeasuredActivityDetailShow
                              }
                              anchorElListMaResult={anchorElListMaResult}
                              userTokenState={userTokenState}
                              collectionMaResultList={collectionMaResultList}
                            />
                          );
                        }
                      })
                    : null}
                </Collapse>
              </List>
            </Grid>
          </Grid>

          {collectionMaResultList.length > 0 && (
            <Box marginTop={theme.spacing(0.3)}>
              <TablePagination
                classes={{
                  toolbar: classes.toolbar,
                  root: classes.noBorderBottom,
                }}
                rowsPerPageOptions={[8]}
                colSpan={3}
                count={maResultLength}
                rowsPerPage={context.rowsPerPageMAR}
                page={context.pageMAR}
                labelDisplayedRows={({ from, to, count }) => (
                  <Typography variant="subtitle2" className={classes.title}>
                    <h4>
                      {`${from} - ${to === -1 ? count : to} dari total `}
                      <strong>{count}</strong> baris
                    </h4>
                  </Typography>
                )}
                labelRowsPerPage="Baris setiap halaman"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          )}
        </Paper>
      )}

      <DialogAddResultMA
        openDialogAddResultMA={openDialogAddResultMA}
        setOpenDialogAddResultMA={setOpenDialogAddResultMA}
        maDetailState={maDetailState}
        classes={classes}
        setSuccessCreateMA={setSuccessCreateMA}
      />

      <DialogExportMaResult
        isModalExportMaResult={isModalExportMaResult}
        setModalExportMaResult={setModalExportMaResult}
        maDetailState={maDetailState}
      />
    </Fragment>
  );
};

export default ListCollectionMaResult;

import { fade } from "@material-ui/core/styles";

export const styles = (theme) => ({
  root: {
    height: 264,
    flexGrow: 1,
    width: "100%",
    maxWidth: 370,
    "&:focus": {
      backgroundColor: "transparent",
    },
  },

  rootPreview: {
    height: 264,
    flexGrow: 1,
    width: "100%",
    maxWidth: 270,

    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  small: {
    width: 32,
    height: 32,
    marginLeft: theme.spacing(2),
    // margin : theme.spacing(0.5),
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  title: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
  },

  outlinedTabActive: {
    borderBottom: `3px solid #d64253`,
    borderTop: `1px solid transparent`,
    borderLeft: `1px solid transparent`,
    borderRight: `1px solid transparent`,
    padding: "1px 2px",
    borderRadius: 3,
  },

  outlinedTabNotActive: {
    borderBottom: `3px solid transparent`,
    borderTop: `1px solid transparent`,
    borderLeft: `1px solid transparent`,
    borderRight: `1px solid transparent`,
    padding: "1px 2px",
    borderRadius: 3,
  },

  colorPrimary: {
    colorPrimary: "magenta",
  },
  horizontalLine: {
    //*line TIDUR HORIZONTAL

    position: "relative",
    "&::after": {
      content: "''",
      width: "16px",
      position: "absolute",
      left: -12,
      // left: 32,
      top: "50%",
      zIndex: 1,
      borderBottom: `1px solid ${fade(theme.palette.text.primary, 0.4)}`,
    },
  },

  horizontalWithoutLine: {
    position: "relative",

    "&::after": {
      content: "''",
      width: "16px",
      position: "absolute",
      left: -12,
      top: "50%",
      zIndex: 1,
    },
  },
  button: {
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "white",
  },

  buttonOutlined: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },

  paperListKebakaran: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: theme.spacing(0.2),
    background: "#edcfd8", //*#d64253
    borderBottom: "0.5em solid red",
    // borderLeft: '0.1em solid #c3c0c0de',
  },

  /*
      `````````````
      TREE ITEM CSS

      `````````````
    */
  content: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },

  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  group: {
    marginTop: -8,
    marginLeft: 180,
    marginRight: -240,

    paddingLeft: 16,
    borderLeft: `1px solid ${fade(theme.palette.text.primary, 0.4)}`,
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  title: {
    fontFamily: "Roboto",
  },
});

import React, { useEffect, useState } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";

import {
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  Menu,
  MenuItem,
  Collapse,
  Button,
} from "@material-ui/core";

import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MailIcon from "@material-ui/icons/Mail";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import ImageProfilPerusahaan from "../assets/images/SVG/Group_863.svg";
import ImageMembership from "../assets/images/SVG/Group_870.svg";
import ImagePengaturanUser from "../assets/images/SVG/Group_866.svg";
import ImagePengaturanRole from "../assets/images/SVG/Group_1421.svg";
import IconMenuOffCanvass from "../assets/images/Group_1923.png";
import IconMenuOffCanvassGrey from "../assets/images/SVG/Group_709.svg";

import IconArrowLeft from "../assets/images/SVG/Group_1186.svg";
import { lightBlue, green } from "@material-ui/core/colors";

import axiosConfig from "../constants/config-axios";
import { URL_API } from "../constants/config-api";
import { styles } from "./Style/StyleHeaderMembershipManagement";
import Redirect from "../utilities/Redirect";
import {
  ToMembershipStatus,
  ToDashboard,
  ToCompanyProfile,
  ToRole,
  ToUserManagement,
  ToLogin,
} from "../constants/config-redirect-url";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: "cyan",
      },
    },
    MuiToolbar: {
      root: {
        backgroundColor: "white",
      },
    },
    MuiButton: {
      text: {
        color: "white",
      },
      textSecondary: {
        color: "white",
        fontFamily: "Roboto",
      },
    },
    MuiDrawer: {
      root: {
        backgroundColor: lightBlue,
      },
      paper: {
        backgroundColor: green,
      },

      paperAnchorLeft: {},
    },
    MuiListItemIcon: {
      root: {
        color: "white",
      },
    },
    MuiBadge: {
      root: {
        color: "#d1354a",
      },
    },
  },
});

const HeaderMembershipManagement = (props) => {
  // const classes = useStyles();
  const { classes } = props;

  /* 
        ````````````````````
        TO KNOW CURRENT PAGE
        
        ````````````````````
    */
  //*const currentLocation = window.location.pathname;

  /*
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */
  const userToken = localStorage.getItem("userToken");
  const [userTokenState, setUserTokenState] = useState("");

  const [fotoQuery, setFotoQuery] = useState("");
  const [fotoQueryUser, setFotoQueryUser] = useState("");

  const [namaGroupPerusahaan, setNamaGroupPerusahaan] = useState("");
  const [roleName, setRoleName] = useState([]);

  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);

  useEffect(() => {
    setUserTokenState(userToken);

    if (statusUserLoginAfterParse !== null) {
      setFotoQueryUser(statusUserLoginAfterParse.member_photo_url);
      setRoleName(statusUserLoginAfterParse.userRole[0].name);
    }

    if (userToken !== undefined) {
      axiosConfig
        .get(URL_API + `/group/profile/patch`)
        .then(function (response) {
          console.log("Response Original from HeadNewGeneral : ", response);

          if (response.status == 200) {
            if (response.data.data.fields !== undefined) {
              if (response.data.data.fields.photo_logo_icon !== "") {
                setFotoQuery(response.data.data.fields.photo_logo_icon.value);
                setNamaGroupPerusahaan(response.data.data.fields.name.value);
              }
            }
          }
        })
        .catch(function (error) {
          console.log("Error : ", error.response);
        });
    }
  }, []);

  /*
        ``````````````````
        HANLDE OFF CANVASS

        ``````````````````
    */

  const [state, setState] = useState({
    top: false,
    // left: true,
    left: false,
    bottom: false,
    right: false,
  });

  const handleCloseOffCanvass = () => {
    setState((state.left = false));
  };

  const toggleDrawer = (side, open) => () => {
    setState({ ...state, [side]: open });
  };

  // const [isLogout, setLogout] = useState(false);

  /*
        ``````````````
        HANDLE LOG OUT
  
        ``````````````
    */
  function handleLogOut() {
    localStorage.removeItem("userToken");
    localStorage.removeItem("intro_enabled");

    localStorage.removeItem("verifyToken");
    localStorage.removeItem("status_user_login");
    localStorage.removeItem("goal_detail");
    localStorage.removeItem("employee_id");
    localStorage.removeItem("response_employee_detail");

    // localStorage.clear();
    Redirect(ToLogin);
  }

  /*
        ````````````````````````````````
          ICON POJOK KANAN ATAS - MENU
  
        ````````````````````````````````
    */

  const ITEM_HEIGHT = 24;

  const options = ["Pengaturan", "Log-out"];

  const [anchorElMenuPojokKananAtas, setAnchorElPoKaAtas] = useState(null);
  const isOpenMenuPojokKananAtas = Boolean(anchorElMenuPojokKananAtas);

  function handleMenuPojokKananAtas(event) {
    setAnchorElPoKaAtas(event.currentTarget);
  }

  function handleCloseMenuPojokKananAtas(e, item) {
    if (item == "Log-out") {
      handleLogOut();
    }

    setAnchorElPoKaAtas(null);
  }

  /*
      ````````````````````````````````
        LIST IN DRAWER

      ````````````````````````````````
    */

  const [openNestedList, setOpenNestedList] = useState(true);

  function handleOpenNestedList() {
    setOpenNestedList(!openNestedList);
  }

  const redirectToDashboard = () => Redirect(ToDashboard);

  /* 
        ``````````````````````````````````````````````
        START | HANDLE ACTIVE - INACTIVE NAV LIST OFF CANVASS
        
        ``````````````````````````````````````````````
    */

  let inisateLabel = {
    label: "",
    // image: ImageMembership,
    // type: 'Keanggotaan'
  };

  // const [typeLabel, setTypeLabel ] = useState();

  const currentLocation = window.location.pathname;
  console.log("currentLocation : ", currentLocation);

  if (currentLocation === ToCompanyProfile) {
    inisateLabel.label = "Profil Perusahaan";
  }

  if (currentLocation === ToRole) {
    inisateLabel.label = "Pengaturan Role";
  }

  if (currentLocation === ToUserManagement) {
    inisateLabel.label = "Pengaturan User";
  }

  if (currentLocation === ToMembershipStatus) {
    inisateLabel.label = "Keanggotaan";
  }

  const [isActiveList, setActiveList] = useState(inisateLabel);

  const handleActiveListItem = (item) => {
    console.log("Nav : ", item);

    setActiveList(item);

    if (item.type === "ProfilPerusahaan") {
      Redirect(ToCompanyProfile);
    }

    if (item.type === "PengaturanUser") {
      Redirect(ToUserManagement);
    }

    if (item.type === "PengaturanRole") {
      Redirect(ToRole);
    }

    if (item.type === "Keanggotaan") {
      Redirect(ToMembershipStatus);
    }
  };

  /* 
        ```````````````````````````````````````````````````
        END | HANDLE ACTIVE - INACTIVE NAV LIST OFF CANVASS
        
        ```````````````````````````````````````````````````
    */

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            onClick={toggleDrawer("left", true)}
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu">
            <img
              src={IconMenuOffCanvassGrey}
              alt="Icon Menu Grey"
              style={{ width: 28, height: 28 }}
            />
          </IconButton>

          <Typography variant="h6" color="inherit" className={classes.grow}>
            Abang Bisa
          </Typography>

          <Grid container spacing={8} alignItems="flex-end"></Grid>

          <IconButton color="inherit">
            <Badge badgeContent={17} color="primary">
              <NotificationsIcon className={classes.notifIcon} />
            </Badge>
          </IconButton>

          <IconButton color="inherit">
            <MailIcon className={classes.mail} />
          </IconButton>

          <IconButton
            aria-haspopup="true"
            // onClick={() => handleLogOut()}
            color="inherit">
            {fotoQueryUser !== "" ? (
              <img
                src={URL_API + "/" + fotoQueryUser + "&token=" + userToken}
                className={classes.bigRealFoto}
              />
            ) : (
              <AccountCircle className={classes.accountCircle} />
            )}
          </IconButton>

          <IconButton
            aria-label="More"
            aria-owns={isOpenMenuPojokKananAtas ? "long-menu" : undefined}
            aria-haspopup="true"
            onClick={handleMenuPojokKananAtas}>
            <ExpandMore />
          </IconButton>

          <Menu
            id="long-menu"
            anchorEl={anchorElMenuPojokKananAtas}
            open={isOpenMenuPojokKananAtas}
            onClose={handleCloseMenuPojokKananAtas}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200,
              },
            }}>
            {options.map((option) => (
              <MenuItem
                key={option}
                selected={option === "Pengaturan"}
                onClick={(e) => handleCloseMenuPojokKananAtas(e, option)}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer open={state.left} onClose={toggleDrawer("left", false)}>
        <div style={{ background: "#fefefe", height: 1000, width: 303 }}>
          <Button
            onClick={redirectToDashboard}
            variant="outlined"
            size="medium"
            className={classes.buttonBackDashboard}>
            <img
              src={IconArrowLeft}
              alt="icon-left-arrow"
              className={classes.iconArrowLeft}
            />
            <b>Dashboard</b>
          </Button>
          <IconButton
            color="inherit"
            aria-label="Menu"
            className={classes.buttonIconMenuOffCanvass}>
            <img
              onClick={handleCloseOffCanvass}
              src={IconMenuOffCanvass}
              className={classes.iconMenuOffCanvass}
            />
          </IconButton>
          <List component="nav" className={classes.drawerRoot}>
            <ListItem button onClick={handleOpenNestedList}>
              <ListItemIcon>
                <IconButton aria-haspopup="true" color="inherit">
                  {fotoQuery !== "" ? (
                    <img
                      src={URL_API + "/" + fotoQuery + "&token=" + userToken}
                      className={classes.bigRealFotoDrawer}
                    />
                  ) : (
                    <AccountCircle className={classes.accountCircle} />
                    // <img src={MahkotaLogoCompany} style={{marginRight: 0, paddingRight:0}}/>
                  )}
                </IconButton>
              </ListItemIcon>

              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.namaPerusahaan}
                    style={{ marginLeft: 8 }}>
                    {namaGroupPerusahaan !== "" ? namaGroupPerusahaan : "-"}
                  </Typography>
                }
              />

              {openNestedList ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={openNestedList} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {navs.length > 0 &&
                  navs.map((item, i) => (
                    <ListItem
                      inset
                      button
                      className={classes.nested}
                      key={i}
                      style={{ marginLeft: 16 }}>
                      <ListItemIcon>
                        <img src={item.image} className={classes.imageSvg} />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() => handleActiveListItem(item)}
                        primary={
                          <Typography
                            style={{ fontFamily: "Roboto" }}
                            type={item.type}
                            className={
                              isActiveList.label == item.label
                                ? classes.navActive
                                : classes.nav
                            }>
                            {item.label}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
              </List>
            </Collapse>
          </List>
        </div>
      </Drawer>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(HeaderMembershipManagement);

let navs = [
  {
    label: "Profil Perusahaan",
    image: ImageProfilPerusahaan,
    type: "ProfilPerusahaan",
  },
  {
    label: "Keanggotaan",
    image: ImageMembership,
    type: "Keanggotaan",
  },
  {
    label: "Pengaturan User",
    image: ImagePengaturanUser,
    type: "PengaturanUser",
  },
  {
    label: "Pengaturan Role",
    image: ImagePengaturanRole,
    type: "PengaturanRole",
  },
];

import React, { forwardRef } from "react";
import {
  Dialog,
  Box,
  Typography,
  Button,
  Grid,
  Slide,
  AppBar,
  Container,
  DialogContent,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import CardChoosenPackage from "../../../AM/MembershipManagement/ComponentsDialogModal/CardChoosenPackage";

// Transtition Cmponent
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogPaidPayment = ({
  openDialogPaidPayment,
  setOpenDialogPaidPayment,
  classes,
}) => {
  return (
    <Dialog
      fullScreen
      open={openDialogPaidPayment}
      TransitionComponent={Transition}>
      <AppBar
        style={{ position: "relative", backgroundColor: "#fff" }}
        elevation={0}
      />

      <Container maxWidth="md" style={{ marginTop: 50 }}>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CheckCircleIcon
              style={{ fontSize: 36, color: "#10de77", marginBottom: 12 }}
            />
            <Typography variant="h6" className={classes.title}>
              <b>Transaksi anda berhasil</b>
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{
                color: "#888",
                width: 550,
                marginTop: 5,
                textAlign: "center",
              }}>
              Pembayaran anda telah kami terima, paket membership telah aktif
              dan dapat segera anda gunakan setelah registrasi selesai
            </Typography>
          </Box>
          <Box
            marginBottom={6}
            marginTop={5}
            display="flex"
            justifyContent="center">
            <Box width={600}>
              <CardChoosenPackage
                classes={classes}
                paket="Bisnis"
                membership="Bisnis A"
                duration={1}
                totalUser={27}
                totalDeployment={25}
                totalPmo={4}
              />
            </Box>
          </Box>

          <Grid container>
            <Grid item md={6} style={{ textAlign: "right", paddingRight: 15 }}>
              <Typography variant="subtitle2" className={classes.txtSummary2}>
                Meetode Pembayaran
              </Typography>
              <Typography variant="subtitle2" className={classes.txtSummary2}>
                Jumlah Transfer
              </Typography>
              <Typography variant="subtitle2" className={classes.txtSummary2}>
                Tanggal Pembayaran
              </Typography>
            </Grid>
            <Grid item md={6} style={{ textAlign: "left", paddingLeft: 7.5 }}>
              <Typography variant="subtitle2" className={classes.txtSummary2}>
                : <b>BCA Virtual Account</b>
              </Typography>
              <Typography variant="subtitle2" className={classes.txtSummary2}>
                : <b>Rp 4.500.000</b>
              </Typography>
              <Typography variant="subtitle2" className={classes.txtSummary2}>
                : <b>25/01/2021 15:09</b>
              </Typography>
            </Grid>
          </Grid>

          <Box marginTop={5} display="flex" justifyContent="center">
            <Button
              variant="contained"
              className={classes.button1}
              onClick={() => setOpenDialogPaidPayment(false)}
              style={{ width: 200 }}>
              Lanjutkan Registrasi
            </Button>
          </Box>
        </DialogContent>
      </Container>
    </Dialog>
  );
};

export default DialogPaidPayment;

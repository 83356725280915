/*
    ````````````````````````````
    DIALOG EDIT GOAL ACTIVE USED

    ````````````````````````````
*/

import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext,
} from "react";
import {
  Typography,
  Grid,
  TextField,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemIcon,
  Radio,
  InputAdornment,
  createMuiTheme,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

import { ThemeProvider } from "@material-ui/core/styles";

import MomentUtils from "@date-io/moment";
import moment from "moment";

import { usePath } from "hookrouter";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";
import LightTooltip from "../../../../components/LightTooltip";
import DialogError from "../../../../components/DialogError";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import Capitalize from "../../../../utilities/Capitalize";
import DialogDateRange from "../Components/DialogDateRange";

import { ToSOTable } from "../../../../constants/config-redirect-url";

import Redirect from "../../../../utilities/Redirect";

import ContextGoalDetail from "../Context/ContextGoalDetail";

import "moment/locale/id";

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const DialogEdit = (props) => {
  const {
    classes,
    isModalEditGoalMaster,
    setModalEditGoalMaster,
    goalDetailState,
    fotoQuery,
    setFotoQuery,
  } = props;

  // console.log('goalDetailState : ', goalDetailState);
  const path = usePath();
  const context = useContext(ContextGoalDetail);

  const SWD_GOAL_INPUT_METHOD_INCREMENT = "swd_goal_input_method_increment";
  const SWD_GOAL_INPUT_METHOD_LAST_VALUE = "swd_goal_input_method_last_value";

  const SWD_GOAL_CALCULATION_METHOD_SUM = "swd_goal_calculation_method_sum";
  const SWD_GOAL_CALCULATION_METHOD_LAST_VALUE =
    "swd_goal_calculation_method_last_value";
  const SWD_GOAL_CALCULATION_METHOD_AVERAGE =
    "swd_goal_calculation_method_average";

  /*
        ````````
        USE REFF

        ````````
    */
  let textInputReff = useRef(null);
  const userToken = localStorage.getItem("userToken");

  const [userTokenState, setUserTokenState] = useState("");
  const [idGoal, setIdGoal] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [evidance, setEvidance] = useState(false);

  const [isDisabledFeature, setDisabledFeature] = useState(false);
  const [jumlahGoalResult, setJumlahGoalResult] = useState("");

  // const [ inputMethodData, setInputMethodData ] = useState('');
  // const [ achievementTypeData, setAchievementTypeData ] = useState('');
  const [valueTypeData, setValueTypeData] = useState("");
  const [calcMethodData, setCalcMethodData] = useState("");
  const [calcMethodDataID, setCalcMethodDataID] = useState("");

  const [isShowOnlyInputMethodTOTAL, setShowOnlyInputMethodTOTAL] =
    useState(false);

  useEffect(() => {
    if (isModalEditGoalMaster === true) {
      // console.log('collectionGoalList : ', collectionGoalList);

      console.log("goalDetailState : ", goalDetailState);

      const userToken = localStorage.getItem("userToken");
      setUserTokenState(userToken);

      setBigGoal(goalDetailState.name);
      setDescription(goalDetailState.description);
      setJumlahGoalResult(goalDetailState.calculatedValue.result_value);
      setEvidance(goalDetailState.is_evidence_required);

      setStartValue(goalDetailState.calculatedValue.start_value);
      setTargetValue(goalDetailState.calculatedValue.target_value);

      setIdGoal(goalDetailState.id);

      if (
        goalDetailState.owner.structure_position_title_name !== undefined &&
        goalDetailState.owner.structure_position_title_name !== null
      ) {
        setTextValuePosition(
          goalDetailState.owner.structure_position_title_name
        );
        setOwnerId(goalDetailState.owner.id);
      }

      if (goalDetailState.member.self !== undefined) {
        if (goalDetailState.member.self.rel !== null) {
          if (goalDetailState.member.self.rel.photo_url !== null) {
            setFotoQuery(goalDetailState.member.self.rel.photo_url);
          } else {
            setFotoQuery(null);
          }
        }
      }

      if (goalDetailState.target_range_value !== null) {
        setTargetRangeValue(goalDetailState.target_range_value);

        setShowTextFieldTargetRangeValue(true);
      }

      if (goalDetailState.inputMethod.code === "total") {
        setShowOnlyInputMethodTOTAL(true);
      }

      setSelectedValueInputMethod(goalDetailState.inputMethod);
      setSelectedValueAchievementType(goalDetailState.achievementType);
      setSelectedValueCalcMethod(goalDetailState.calculationMethod);
      setSelectedTargetType(goalDetailState.targetType);

      setValueTypeData(goalDetailState.valueType);
      setCalcMethodData(goalDetailState.calculationMethod);
      setCalcMethodDataID(goalDetailState.calculationMethod.id);

      setLoader(false);
      setDisabledButton(false);

      //*Focus in Text Field
      setTimeout(() => {
        textInputReff.current.focus();
      }, 100);

      /*
                ``````````````````````````
                TO KNOW WHAT TIME FRAME IS

                ``````````````````````````
            */
      if (goalDetailState.timeFrame !== undefined) {
        if (goalDetailState.timeFrame.code === "date_range") {
          setShowTextDateRange(true);
          setEndDateRangeState(goalDetailState.time.end_date);
          setStartDateRangeState(goalDetailState.time.start_date);
        }

        if (goalDetailState.timeFrame.code === "period") {
          setShowTextDateRange(true);

          setEndDateRangeState(goalDetailState.time.end_date);
          setStartDateRangeState(goalDetailState.time.start_date);
          setDisabledFeature(true);
        }

        if (goalDetailState.timeFrame.code === "due_date") {
          setShowDueDateComponent(true);
          setSelectedDueDate(goalDetailState.time.end_date);
          setDisabledFeature(true);
        }
      }

      /*
                ````````````````````````````````
                TO KNOW STATUS COMPLETE OR NOT : 

                    - active

                    - approved

                    - draft

                    - completed

                ````````````````````````````````
            */
      if (goalDetailState.status_id === "6") {
        setDisabledFeature(true);
      }

      if (goalDetailState.status.code === "completed") {
        setDisabledFeature(true);
      }

      if (goalDetailState.status.code === "approved") {
        setDisabledFeature(false);
        console.log("Status Goal approved !");
      }

      if (goalDetailState.status.code === "draft") {
        setDisabledFeature(false);
      }

      // if(goalDetailState.is_used === true ){

      //     setDisabledFeature(false);
      // };

      /*
                ````````````````````````````````````````
                GET UPDATED AT, UNTUK VIEW DATA PAKE INI 

                ````````````````````````````````````````
            */
      if (userToken !== undefined) {
        axiosConfig
          .get(URL_API + `/swd/goal/${goalDetailState.id}/update`)
          .then(function (response) {
            console.log("Response Original Updated AT : ", response);

            if (response.status === 200) {
              if (response.data.data.fields.hasOwnProperty("period_id")) {
                setTimeFrameId("1");
              }

              if (response.data.data.fields.hasOwnProperty("end_date")) {
                if (
                  response.data.data.fields.hasOwnProperty("start_date") &&
                  response.data.data.fields.hasOwnProperty("end_date")
                ) {
                  setTimeFrameId("2");
                } else {
                  setTimeFrameId("3");
                }
              }

              setUpdatedAt(response.data.data.updated_at);
            }
          })
          .catch(function (error) {
            console.log("Error : ", error.response);
            // if(){

            // }
          });
      } else {
        console.log("No Access Token available!");
      }

      //******************************************************** */

      if (userToken !== undefined) {
        axiosConfig
          .get(URL_API + `/swd/goal/create?mode=simple`)
          .then(function (response) {
            console.log("Response Original Label : ", response);

            if (response.status === 200) {
              if (goalDetailState.input_method_id === "2") {
                setCalculationMethodList([
                  {
                    code: "swd_goal_calculation_method_sum",
                    id: "1",
                    name: "sum",
                  },
                  {
                    code: "swd_goal_calculation_method_average",
                    id: "2",
                    name: "average",
                  },
                ]);
              } else {
                setCalculationMethodList([
                  {
                    code: "swd_goal_calculation_method_last_value",
                    id: "3",
                    name: "last value",
                  },
                ]);
              }

              setInputMethodList(response.data.data.inputMethodCollections);
              setInputAchievementTypeList(
                response.data.data.achievementTypeCollections
              );

              setInputValueTypeList(response.data.data.valueTypeCollections);

              setTargetTypeList(response.data.data.targetTypeCollections);
              setPeriodCollections(response.data.data.periodCollections);

              if (response.data.data.ownerCollections !== null) {
                if (response.data.data.ownerCollections.length > 0) {
                  setMemberPositionList(response.data.data.ownerCollections);
                } else {
                  setOwnerId(goalDetailState.owner.id);
                  setTextValuePosition(
                    goalDetailState.owner.structure_position_title_name
                  );
                  setFotoQuery("");
                }
              }
            }
          })
          .catch(function (error) {
            console.log("Error : ", error.response);
          });
      } else {
        console.log("No Access Token available!");
      }

      if (
        goalDetailState.valueType.id === "2" &&
        goalDetailState.valueType.code === "percentage"
      ) {
        setShowPercent(true);
      }
    }
  }, [isModalEditGoalMaster]);

  /*
        ```````````````````
        COMPONENT DID MOUNT 

        ```````````````````
    */
  //    const userToken = localStorage.getItem('userToken');

  const [startDateRangeState, setStartDateRangeState] = useState(null);
  const [endDateRangeState, setEndDateRangeState] = useState(null);

  const startDateRange = localStorage.getItem("start_date");
  const endDateRange = localStorage.getItem("end_date");

  //    const [ userTokenState, setUserTokenState ] = useState('');
  const [positionList, setPositionList] = useState([]);

  const [calculationMethodList, setCalculationMethodList] = useState([]);
  const [inputAchievementTypeList, setInputAchievementTypeList] = useState([]);
  const [inputMethodList, setInputMethodList] = useState([]);
  const [inputValueTypeList, setInputValueTypeList] = useState([]);

  const [periodCollections, setPeriodCollections] = useState([]);

  const [memberPositionList, setMemberPositionList] = useState([]);

  // useEffect(() => {
  //   setStartDateRangeState(startDateRange);
  //   setEndDateRangeState(endDateRange);
  // }, [startDateRange, endDateRange]);

  /*
       ``````````````````````
       HANDLE CHANGE BIG GOAL

       ``````````````````````
   */
  const [bigGoal, setBigGoal] = useState("");
  const handleChangeBIGGoal = (e) => {
    setBigGoal(e.target.value);
  };

  /*
       `````````````````````````
       HANDLE CHANGE DESCRIPTION

       `````````````````````````
   */
  const [description, setDescription] = useState("");
  const handleChangeDescription = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  /*
       ``````````````````````````
       HANDLE CHANGE NILAI TARGET

       ``````````````````````````
   */
  const [targetValue, setTargetValue] = useState("");
  const handleChangeTargetValue = (e) => {
    e.preventDefault();
    // setTargetValue(numeral(e.target.value).format('0'));
    setTargetValue(e.target.value);
  };

  /*
        `````````````````````````
        HANDLE CHANGE START VALUE

        `````````````````````````
    */
  const [startValue, setStartValue] = useState("0");
  const handleChangeStartValue = (e) => {
    e.preventDefault();
    setStartValue(e.target.value);
  };

  /*
        ````````````````````````````````
        HANDLE CHANGE TARGET VALUE RANGE

        ````````````````````````````````
    */
  // const [ targetValueRange, setTargetValueRange ] = useState('');

  const [targetRangeValue, setTargetRangeValue] = useState(null);

  const handleChangeTargetValueRange = (e) => {
    e.preventDefault();
    setTargetRangeValue(e.target.value);
  };

  /*
       `````````````````
       DROPDOWN POSITION

       `````````````````
   */
  const [anchorElTimePosition, setAnchorElTimePosition] = useState(null);

  const [textValuePosition, setTextValuePosition] = useState("");
  const [ownerId, setOwnerId] = useState("");

  function handleClickDropdownPosition(event) {
    setAnchorElTimePosition(event.currentTarget);
  }

  function handleCloseDropdownPosition() {
    setAnchorElTimePosition(null);
  }

  const handleChoosePosition = (e, data) => {
    handleCloseDropdownPosition();

    console.log("Position selected : ", data);
    setTextValuePosition(data.structure_position_title_name);
    setOwnerId(data.id);
  };

  /*
       ```````````````````
       DROPDOWN TIME FRAME
       ```````````````````
   */
  const [locale, setLocale] = useState("id");
  const [anchorElTimeFrame, setAnchorElTimeFrame] = useState(null);

  const [textValueTimeFrame, setTextValueTimeFrame] = useState("Time Frame");

  function handleClickTimeFrame(event) {
    setAnchorElTimeFrame(event.currentTarget);
  }

  function handleCloseTimeFrame() {
    setAnchorElTimeFrame(null);
  }

  /*
        `````````````````````
        HANDLE TIME FRAME ID

        - idDueDate: "3",
        - idDateRange: "2",
        - idPeriod: "1"

        `````````````````````
    */
  const [timeFrameId, setTimeFrameId] = useState(null);

  /*
       ```````````````
       DUE DATE OPTION

       ```````````````
   */
  const [selectedDueDate, setSelectedDueDate] = useState("");

  const [isShowDueDateComponent, setShowDueDateComponent] = useState(false);
  const [isShowDueDateComponentDirectly, setShowDueDateComponentDirectly] =
    useState(false);

  const handleChooseDueDate = () => {
    setTimeFrameId("3");
    // setEndDateRangeState(new Date()) //*Baris kode ini untuk validasi DISABLED Button saja !

    handleCloseTimeFrame();
    setShowDueDateComponent(true);
    setShowDueDateComponentDirectly(true);

    setShowDateRangeComponent(false);
    setShowTextDateRange(false);

    setTextValueTimeFrame("Due Date");

    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");
  };

  /*
       `````````````````
       DATE RANGE OPTION

       `````````````````
   */
  const [isShowDateRangeComponent, setShowDateRangeComponent] = useState(false);
  const [isShowTextDateRange, setShowTextDateRange] = useState(false);

  const handleChooseRange = () => {
    // setSelectedDueDate(new Date());//*Baris kode ini untuk validasi DISABLED Button saja !

    setTimeFrameId("2");

    handleCloseTimeFrame();
    setShowDueDateComponent(false);
    setShowDueDateComponentDirectly(false);

    setShowDateRangeComponent(true);
    setShowTextDateRange(true);

    setTextValueTimeFrame("Date Range");
  };

  /*
        ``````````````
        PERIODE OPTION

        ``````````````
    */
  const [isShowPeriodComponent, setShowPeriodComponent] = useState(false);
  const [isShowTextPeriod, setShowTextPeriod] = useState(false);

  const handleChoosePeriod = (event) => {
    setTimeFrameId("1");

    setAnchorElPeriod(event.currentTarget);

    //*
    handleCloseTimeFrame();
    setShowDueDateComponent(false);
    setShowDueDateComponentDirectly(false);

    setShowDateRangeComponent(false);
    setShowTextDateRange(false);

    setTextValueTimeFrame("Period");

    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");

    setShowPeriodComponent(true);
    setSelectedDueDate(null);
  };

  /*
        ````````````````````````````
        PERIODE OPTION LIST DROPDOWN

        ````````````````````````````
    */
  //*
  const [anchorElPeriod, setAnchorElPeriod] = useState(null);

  function handleClickPeriod(event) {
    //*Fungsi setAnchor di sini sudah di pakai langsung di 'handleChoosePeriod()'

    setAnchorElPeriod(event.currentTarget);
  }

  function handleClosePeriod() {
    setAnchorElPeriod(null);
  }

  const [periodId, setPeriodId] = useState("");
  const [periodName, setPeriodName] = useState("");

  const handleChoosePeriodId = (e, item) => {
    e.preventDefault();

    console.log("Item : ", item);

    setPeriodId(item.id);
    setPeriodName(item.name);

    handleClosePeriod();
    setShowTextPeriod(true);
  };

  /*
       ```````````````````
       HANDLE DIALOG ERROR

       ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] =
    useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  /*
        `````````````````````````
        HANDLE BUTTON SUBMIT GOAL

        `````````````````````````
    */

  const [loader, setLoader] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const handleSUBMITGoal = () => {
    setLoader(true);
    setDisabledButton(true);

    let data = {
      Goal: {
        updated_at: updatedAt,
        name: bigGoal,
        // owner_id: memberPositionList.length > 0 && ownerId === '' ?  memberPositionList[0].id : ownerId,
        owner_id: ownerId,
        input_method_id:
          selectedValueInputMethod !== "" ? selectedValueInputMethod.id : "",
        value_type_id: valueTypeData !== "" ? valueTypeData.id : "",
        achievement_type_id:
          selectedValueAchievementType !== ""
            ? selectedValueAchievementType.id
            : null,
        calculation_method_id: calcMethodDataID, //selectedValueCalcMethod.id,
        period_id: periodId !== "" ? periodId : null,
        start_date: startDateRangeState !== null ? startDateRangeState : null,
        end_date:
          endDateRangeState !== null
            ? endDateRangeState
            : moment(selectedDueDate).format("YYYY-MM-DD"),
        start_value: startValue !== "0" ? startValue : 0,
        target_value: targetValue,
        target_range_value: targetRangeValue !== null ? targetRangeValue : null,
        description: description,
        time_frame_id: timeFrameId !== null ? timeFrameId : null,
        target_type_id:
          selectedTargetType.id !== "" ? selectedTargetType.id : "",
        is_evidence_required: evidance === true ? "1" : "0", //*0 === Tidak butuh evidence, 1 === Butuh evidence
      },
    };

    if (data.Goal.period_id === null) {
      delete data.Goal.period_id;
    }

    if (
      data.Goal.period_id !== null &&
      data.Goal.start_date === null &&
      data.Goal.end_date === "Invalid date"
    ) {
      delete data.Goal.start_date;
      delete data.Goal.end_date;
    }

    if (data.Goal.start_date === null) {
      delete data.Goal.start_date;
    }

    if (data.Goal.target_range_value === null) {
      delete data.Goal.target_range_value;
    }

    console.log("Data SUBMIT : ", data);

    if (userTokenState !== "") {
      axiosConfig
        .put(URL_API + `/swd/goal/${idGoal}`, data)
        .then(function (response) {
          const currentLocation = window.location.pathname;
          setLoader(false);
          setDisabledButton(false);

          console.log("Response Original : ", response);

          if (response.status === 200) {
            setModalEditGoalMaster(false);

            if (response.data.data !== null) {
              context.setFireGoalDetail(true);
            }
          }
        })
        .catch(function (error) {
          setLoader(false);
          setDisabledButton(false);

          if (error.response !== undefined) {
            if (error.response.status === 400) {
              setErrorStatus(400);
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setListError(error.response.data.info.errors);
                  setOpenDialogError(true);
                }
              }
            }

            if (error.response.status === 409) {
              setErrorStatus(409);
              if (error.response.data.info !== null) {
                if (error.response.data.info.message !== null) {
                  setOpenDialogError(true);
                  setTextErrorInformationValue(
                    "Error 409 :" + Capitalize(error.response.data.info.message)
                  );
                }
              }
            }

            if (error.response.status === 403) {
              setErrorStatus(403);
              if (error.response.data.info !== null) {
                if (error.response.data.info.message !== null) {
                  setOpenDialogError(true);
                  setTextErrorInformationValue(
                    "Error 409 :" + Capitalize(error.response.data.info.message)
                  );
                }
              }
            }

            if (error.response.status === 422) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  if (
                    error.response.data.info.errors[0].code === "INVALID_STATUS"
                  ) {
                    setOpenDialogError(true);
                    setTextErrorInformationValue(
                      "Error 422 :" +
                        Capitalize(
                          error.response.data.info.errors[0].description
                        )
                    );
                  }
                }
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }

          console.log("Error : ", error.response);
        });
    } else {
      console.log("No Access Token available!");
    }
  };

  /*
       ``````````````````````````````````
       HANDLE SELECTED CALCULATION METHOD 

       `````````````````````````````````
    */
  const [selectedValueCalcMethod, setSelectedValueCalcMethod] = useState("");

  const handleChangeRadioButton = (e, data) => {
    e.preventDefault();
    setSelectedValueCalcMethod(data);

    setCalcMethodData(data.code); //*Ini sebenernya ga ke pake !
    setCalcMethodDataID(data.id);

    if (data.code === "swd_goal_calculation_method_average") {
      setTargetTypeList([
        {
          code: "swd_goal_target_type_final",
          id: "2",
          name: "Final",
        },
      ]);

      setSelectedTargetType({
        code: "swd_goal_target_type_final",
        id: "2",
        name: "Final",
      });
    } else {
      setTargetTypeList([
        {
          code: "swd_goal_target_type_daily",
          id: "1",
          name: "Daily",
        },
        {
          code: "swd_goal_target_type_final",
          id: "2",
          name: "Final",
        },
      ]);

      setSelectedTargetType({
        code: "swd_goal_target_type_daily",
        id: "1",
        name: "Daily",
      });
    }
  };

  /*
        =====================================
        HANDLE SELECTED  TARGET TYPE
        =====================================
    */
  const [targetTypeList, setTargetTypeList] = useState([]);
  const [selectedTargetType, setSelectedTargetType] = useState({
    code: "swd_goal_target_type_daily",
    id: "1",
    name: "Daily",
  });

  const handleSelectTargetType = (e, data) => {
    e.preventDefault();
    setSelectedTargetType(data);
  };

  /*
        ``````````````````````````````````
        HANDLE SELECTED INPUT METHOD 

        `````````````````````````````````
    */
  const [selectedValueInputMethod, setSelectedValueInputMethod] = useState({
    id: "1",
    code: "swd_goal_input_method_last_value",
    name: "last value",
  });

  const handleChangeRadioButtonInputMethod = (e, data) => {
    e.preventDefault();
    setSelectedValueInputMethod(data);

    if (data.code === SWD_GOAL_INPUT_METHOD_INCREMENT) {
      setCalculationMethodList([
        {
          id: "1",
          code: SWD_GOAL_CALCULATION_METHOD_SUM,
          name: "sum",
        },
        {
          id: "2",
          code: SWD_GOAL_CALCULATION_METHOD_AVERAGE,
          name: "average",
        },
      ]);

      setSelectedValueCalcMethod({
        id: "1",
        code: SWD_GOAL_CALCULATION_METHOD_SUM,
        name: "sum",
      });
    } else {
      setSelectedValueCalcMethod({
        id: "3", //"1",
        code: SWD_GOAL_CALCULATION_METHOD_LAST_VALUE,
        name: "last value",
      });

      setCalculationMethodList([
        {
          id: "3", //"1",
          code: SWD_GOAL_CALCULATION_METHOD_LAST_VALUE,
          name: "last value",
        },
      ]);
    }
  };

  /*
        ``````````````````````````````````
        HANDLE SELECTED ACHIEVEMENT METHOD 

        `````````````````````````````````
    */
  const [selectedValueAchievementType, setSelectedValueAchievementType] =
    useState({
      id: "1",
      code: "swd_goal_achievement_type_more_oriented",
      name: "more oriented",
    });

  const handleChangeRadioButtonAchievementType = (e, data) => {
    e.preventDefault();
    setSelectedValueAchievementType(data);
  };

  /*
        ``````````````````````````````````
        HANDLE SELECTED  VALUE METHOD 

        `````````````````````````````````
    */

  const [selectedValueType, setSelectedValueType] = useState({
    id: "1",
    code: "numeric",
    name: "numeric",
  });

  const [isShowPercent, setShowPercent] = useState(false);
  const handleChangeRadioButtonValueType = (e, data) => {
    e.preventDefault();
    setSelectedValueType(data);

    if (data.code === "percentage") {
      setShowPercent(true);
    } else {
      setShowPercent(false);
    }
  };

  /*
        ``````````````````````````````````
        HANDLE SHOW TEXT FIELD RANGE VALUE

        ``````````````````````````````````
    */
  const [isShowTextFieldTargetRangeValue, setShowTextFieldTargetRangeValue] =
    useState(false);

  useEffect(() => {
    if (startValue === targetValue) {
      setShowTextFieldTargetRangeValue(true);
    } else {
      setShowTextFieldTargetRangeValue(false);
    }
  }, [startValue, targetValue]);

  return (
    <Dialog
      open={isModalEditGoalMaster}
      onClose={() => setModalEditGoalMaster(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // fullWidth
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="h6" className={classes.title}>
          <b>Edit Goal </b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ textAlign: "left" }}>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="subtitle1" className={classes.title}>
            <b>What is your BIG ambitious Goal ?</b>
          </Typography>
          <TextField
            inputRef={textInputReff}
            id="outlined-bare"
            className={classes.textField}
            onChange={handleChangeBIGGoal}
            value={bigGoal}
            variant="outlined"
            fullWidth
            // color='primary'
            // onKeyDown={handleEnterPress}
            // placeholder = {goalDetailState.name}
            // value = {goalDetailState.name}
            inputProps={{
              className: classes.title,
            }}
          />
        </DialogContentText>

        <Grid container>
          <Grid item xs={6}>
            <List>
              <ListItem style={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  {fotoQuery !== "" && fotoQuery !== null ? (
                    <img
                      src={`${URL_API}/${fotoQuery}&token=${userTokenState}`}
                      className={classes.userRealFoto}
                      alt="Img"
                    />
                  ) : (
                    <IconButton size="medium">
                      <AccountCircleIcon fontSize="large" />
                    </IconButton>
                  )}
                </ListItemIcon>

                <ListItemText
                  className={classes.listItemtext}
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "grey" }}>
                      Goal owner
                    </Typography>
                  }
                  secondary={
                    <Typography variant="subtitle2" className={classes.title}>
                      {/* <b>{userLoginName !== '' ? userLoginName : goalDetailState.owner.member_first_name }</b> */}
                      <b>{goalDetailState.owner.member_first_name}</b>
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={6}>
            {/* 

                            `````````````````
                            TIME FRAME BUTTON

                            `````````````````                        
                        */}
            <Button
              onClick={handleClickTimeFrame}
              variant="outlined"
              size="small"
              className={classes.timeFrameIconInModal}
              style={{ marginTop: 24 }}
              // disabled={goalDetailState.status_id === "6"}
              disabled={isDisabledFeature === true ? true : false}>
              <IconButton style={{ background: "#edcfd8" }}>
                <CalendarTodayIcon style={{ color: "white", fontSize: 14 }} />
              </IconButton>
              &nbsp;
              <b>{textValueTimeFrame}</b>
            </Button>

            <StyledMenu
              id="customized-menu"
              anchorEl={anchorElTimeFrame}
              keepMounted
              open={Boolean(anchorElTimeFrame)}
              onClose={handleCloseTimeFrame}>
              <StyledMenuItem
                onClick={handleChooseDueDate}
                disabled={goalDetailState.is_used === true ? true : false}>
                <ListItemText
                  primary={
                    <Typography variant="subtitle2" className={classes.title}>
                      <b>Due Date</b>
                    </Typography>
                  }
                />
              </StyledMenuItem>

              <StyledMenuItem onClick={handleChooseRange}>
                <ListItemText
                  primary={
                    <Typography variant="subtitle2" className={classes.title}>
                      <b>Date Range</b>
                    </Typography>
                  }
                />
              </StyledMenuItem>

              <StyledMenuItem onClick={handleChoosePeriod}>
                <ListItemText
                  primary={
                    <Typography variant="subtitle2" className={classes.title}>
                      <b>Period</b>
                    </Typography>
                  }
                />
              </StyledMenuItem>
            </StyledMenu>

            {/* 
                            ````````````````
                            MENU LIST PERIOD

                            ````````````````
                        */}

            <StyledMenu
              id="customized-menu-period"
              anchorEl={anchorElPeriod}
              keepMounted
              open={Boolean(anchorElPeriod)}
              onClose={handleClosePeriod}
              style={{ marginTop: "-160px" }}>
              {isShowPeriodComponent === true &&
              periodCollections.length > 0 ? (
                periodCollections.map((item, i) => (
                  <StyledMenuItem
                    key={i}
                    onClick={(e) => handleChoosePeriodId(e, item)}>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}>
                          <b>{item.name}</b>
                        </Typography>
                      }
                    />
                  </StyledMenuItem>
                ))
              ) : (
                <StyledMenuItem disabled>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Anda belum memiliki periode ;(</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>
              )}
            </StyledMenu>

            {isShowDueDateComponent === true && ( //https://stackoverflow.com/questions/17493309/how-do-i-change-the-language-of-moment-js
              <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                <ThemeProvider theme={theme}>
                  <Fragment>
                    <DatePicker
                      value={selectedDueDate}
                      onChange={setSelectedDueDate}
                      animateYearScrolling
                      open={isShowDueDateComponentDirectly}
                      onOpen={() => setShowDueDateComponentDirectly(true)}
                      onClose={() => setShowDueDateComponentDirectly(false)}
                      variant="dialog" // dialog, static, inline
                      disableToolbar={false}
                      // orientation="landscape"
                      format="DD MMMM YYYY"
                      // TextFieldComponent =
                      // ToolbarComponent
                      label=""
                      style={{ marginLeft: 16 }}
                      // disabled={isDisabledFeature == true ? true : false }
                    />
                  </Fragment>
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            )}

            {isShowDateRangeComponent === true && (
              <Fragment>
                <DialogDateRange
                  classes={classes}
                  userTokenState={userTokenState}
                  isShowDateRangeComponent={isShowDateRangeComponent}
                  setShowDateRangeComponent={setShowDateRangeComponent}
                  onStartDate={setStartDateRangeState}
                  onEndDate={setEndDateRangeState}
                />
              </Fragment>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            {isShowTextDateRange === true && (
              <LightTooltip
                title="Time frame tidak bisa di ubah lagi untuk Goal yang sudah AKTIF"
                placement="right">
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIcontDateRangeText}
                  style={{ marginRight: 8, borderWidth: 0 }}
                  onClick={
                    isDisabledFeature === true
                      ? () =>
                          console.log(
                            "Time frame tidak bisa di ubah lagi untuk Goal yang sudah AKTIF"
                          )
                      : () => setShowDateRangeComponent(true)
                  }>
                  <b>
                    {moment(startDateRangeState).format("DD MMMM YYYY")} -{" "}
                    {moment(endDateRangeState).format("DD MMMM YYYY")}
                  </b>
                </Button>
              </LightTooltip>
            )}

            {isShowTextPeriod === true && (
              <Button
                variant="outlined"
                size="small"
                className={classes.timeFrameIcontDateRangeText}
                style={{ marginRight: 8, borderWidth: 0 }}
                onClick={(e) => handleClickPeriod(e)}>
                <b>{periodName}</b>
              </Button>
            )}
          </Grid>
        </Grid>

        <Typography
          variant="subtitle1"
          className={classes.title}
          style={{ color: "grey" }}>
          <b>Deskripsi (Optional)</b>
        </Typography>

        <TextField
          onChange={handleChangeDescription}
          style={{ marginTop: 0 }}
          id="outlined-multiline-static-description"
          // label="Multiline"
          multiline
          rows="4"
          // defaultValue="Default Value"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={description !== null ? description : ""}
          inputProps={{
            className: classes.title,
          }}
        />

        <br />
        <Grid container>
          <Grid item xs={6}>
            {memberPositionList.length === 0 && ownerId === "" && (
              <Button
                onClick={() => Redirect(ToSOTable)}
                variant="outlined"
                size="small"
                className={classes.timeFrameIconInModal}
                style={{ marginTop: 24 }}>
                <IconButton style={{ background: "#edcfd8" }}>
                  <i
                    className="material-icons"
                    style={{ color: "white", fontSize: 14 }}>
                    person
                  </i>
                </IconButton>
                &nbsp;
                <b>Belum ada posisi yang di tentukan untuk membuat Goal ;(</b>
              </Button>
            )}

            {textValuePosition !== "" && (
              <Button
                onClick={() => console.log("Goal ini member-nya NULL")}
                variant="outlined"
                size="small"
                className={classes.timeFrameIconInModal}
                style={{ marginTop: 24 }}>
                <IconButton style={{ background: "#edcfd8" }}>
                  <i
                    className="material-icons"
                    style={{ color: "white", fontSize: 14 }}>
                    person
                  </i>
                </IconButton>
                &nbsp;
                <b>{textValuePosition}</b>
              </Button>
            )}

            <StyledMenu
              id="customized-menu"
              anchorEl={anchorElTimePosition}
              keepMounted
              open={Boolean(anchorElTimePosition)}
              onClose={handleCloseDropdownPosition}>
              {memberPositionList.length > 0 &&
                memberPositionList.map((item, i) => {
                  return (
                    <StyledMenuItem
                      key={i}
                      onClick={(e) => handleChoosePosition(e, item)}>
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}>
                            <b>{item.structure_position_title_name}</b>
                          </Typography>
                        }
                      />
                    </StyledMenuItem>
                  );
                })}
            </StyledMenu>
          </Grid>

          {/* 
                        ``````````````````
                        TARGET RANGE VALUE

                        ``````````````````
                    */}
          <Grid item xs={6}>
            {isShowTextFieldTargetRangeValue === true && (
              <Fragment>
                <Typography
                  variant="subtitle1"
                  className={classes.title}
                  style={{ color: "grey" }}>
                  <b>Target Range value : </b>
                </Typography>
                <Box>
                  <TextField
                    placeholder={
                      targetRangeValue !== null ? targetRangeValue : 0
                    }
                    type="number"
                    id="outlined-bare"
                    onChange={handleChangeTargetValueRange}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography
                            variant="subtitle1"
                            className={classes.title}
                            style={{ color: "grey" }}>
                            {isShowPercent === true ? <b>%</b> : null}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Fragment>
            )}
          </Grid>
        </Grid>

        <br />
        <br />
        <Grid container>
          <Grid item xs={6}>
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Start value : </b>
            </Typography>
            {/* <Typography variant='subtitle1' className={classes.title}>
                            <b>0</b>
                        </Typography> */}
            <Box marginRight={theme.spacing(0.2)}>
              <TextField
                placeholder={startValue}
                defaultValue={startValue}
                type="number"
                id="outlined-bare"
                onChange={handleChangeStartValue}
                variant="outlined"
                fullWidth
                inputProps={{
                  className: classes.title,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography
                        variant="subtitle1"
                        className={classes.title}
                        style={{ color: "grey" }}>
                        {isShowPercent === true ? <b>%</b> : null}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Target Value : </b>
            </Typography>
            {/* <TextField
                            id="outlined-bare"
                            onChange= {handleChangeTargetValue}
                            value = { numeral(targetValue).format('0,0') }
                            variant="outlined"
                            fullWidth
                        /> */}
            <TextField
              placeholder={targetValue}
              defaultValue={targetValue}
              type="number"
              id="outlined-bare"
              onChange={handleChangeTargetValue}
              variant="outlined"
              fullWidth
              inputProps={{
                className: classes.title,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography
                      variant="subtitle1"
                      className={classes.title}
                      style={{ color: "grey" }}>
                      {isShowPercent === true ? <b>%</b> : null}
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <br />

        <FormControlLabel
          value="end"
          control={
            <Checkbox
              size="small"
              checked={evidance}
              onChange={(e) => setEvidance(e.target.checked)}
              // disabled
            />
          }
          label={
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "grey" }}>
              Wajib lampirkan bukti
            </Typography>
          }
          labelPlacement="end"
        />

        <br />
        <Typography
          variant="subtitle1"
          className={classes.title}
          style={{ color: "grey" }}>
          <b>Input Method :</b>
        </Typography>

        <List>
          {inputMethodList.length > 0 &&
            inputMethodList.map((item, i) => (
              <Fragment key={i}>
                <Radio
                  checked={selectedValueInputMethod.code === item.code}
                  onChange={(e) => handleChangeRadioButtonInputMethod(e, item)}
                  value={item.id}
                  name={item.name}
                  // disabled
                />
                <span style={{ fontFamily: "Roboto", color: "grey" }}>
                  {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                </span>
              </Fragment>
            ))}
        </List>

        <br />
        {isShowOnlyInputMethodTOTAL !== true && (
          <Fragment>
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Achievement Type : &nbsp; </b>
            </Typography>
            <List>
              {inputAchievementTypeList.length > 0 &&
                inputAchievementTypeList.map((item, i) => (
                  <Fragment key={i}>
                    <Radio
                      checked={selectedValueAchievementType.id === item.id}
                      onChange={(e) =>
                        handleChangeRadioButtonAchievementType(e, item)
                      }
                      value={item.id}
                      name={item.name}
                      // disabled
                    />
                    <span style={{ fontFamily: "Roboto", color: "grey" }}>
                      {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                    </span>
                  </Fragment>
                ))}
            </List>

            <br />
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Value Type : &nbsp; </b>
              {/* <span style={{fontFamily: 'Roboto', color: 'grey'}}>
                                            {Capitalize(valueTypeData !== '' ? valueTypeData.code : '')}
                                        </span> */}
            </Typography>
            <List>
              {inputValueTypeList.length > 0 &&
                inputValueTypeList.map((item, i) => (
                  <Fragment key={i}>
                    <Radio
                      checked={selectedValueType.id === item.id}
                      onChange={(e) =>
                        handleChangeRadioButtonValueType(e, item)
                      }
                      value={item.id}
                      name={item.name}
                      // disabled
                    />
                    <span style={{ fontFamily: "Roboto", color: "grey" }}>
                      {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                    </span>
                  </Fragment>
                  // </ListItem>
                ))}
            </List>

            <br />
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Calculation Method : &nbsp; </b>
            </Typography>

            <List>
              {calculationMethodList.length > 0 &&
                calculationMethodList.map((item, i) => (
                  <Fragment key={i}>
                    <Radio
                      checked={selectedValueCalcMethod.code === item.code}
                      onChange={(e) => handleChangeRadioButton(e, item)}
                      value={item.id}
                      name={item.name}
                      // disabled={item.code === 'last_value' ? true : false }
                      // disabled
                    />
                    <span style={{ fontFamily: "Roboto", color: "grey" }}>
                      {Capitalize(item.name)} : &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Fragment>
                ))}
            </List>

            <br />
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              <b>Target Type : </b>
            </Typography>

            <List>
              {targetTypeList.length > 0 &&
                targetTypeList.map((item, i) => {
                  return (
                    <Fragment key={i}>
                      <Radio
                        checked={selectedTargetType.code === item.code}
                        onChange={(e) => handleSelectTargetType(e, item)}
                        value={item.id}
                        name={item.name}
                      />
                      <span style={{ fontFamily: "Roboto", color: "grey" }}>
                        {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                      </span>
                    </Fragment>
                  );
                })}
            </List>
          </Fragment>
        )}
      </DialogContent>
      <DialogActions style={{ alignItems: "center", justifyContent: "center" }}>
        <Button
          onClick={handleSUBMITGoal}
          variant="contained"
          className={classes.button}
          fullWidth
          // disableRipple={disabledButton == true ? true : false}
          disabled={disabledButton === true ? true : false}>
          {loader === true ? (
            <CircularProgress size={20} style={{ color: "white" }} />
          ) : (
            "  Simpan Perubahan !"
          )}
        </Button>
      </DialogActions>
      <br />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformationValue}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogEdit;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },

  typography: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  textfield: {
    width: 200,
  },
});

import React, { useState } from "react";
import { MenuItem, Typography, Divider } from "@material-ui/core";

import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

const ListItemMenu = ({ classes, setAnchorMenu }) => {
  return (
    <>
      <MenuItem style={{ width: 190 }}>
        <SettingsOutlinedIcon
          fontSize="small"
          style={{ color: "#8F8F8F", marginRight: 10 }}
        />
        <Typography variant="subtitle2" className={classes.title}>
          Chart Settings
        </Typography>
      </MenuItem>
      <MenuItem style={{ width: 190 }}>
        <ExitToAppOutlinedIcon
          fontSize="small"
          style={{ color: "#8F8F8F", marginRight: 10 }}
        />
        <Typography variant="subtitle2" className={classes.title}>
          Export
        </Typography>
      </MenuItem>

      <Divider />

      <MenuItem style={{ width: 190 }}>
        <DeleteOutlinedIcon
          fontSize="small"
          style={{ color: "#8F8F8F", marginRight: 10 }}
        />
        <Typography variant="subtitle2" className={classes.title}>
          Delete
        </Typography>
      </MenuItem>
    </>
  );
};

export default ListItemMenu;

import React, { useState, useEffect, useContext, Fragment } from "react";
import {
  Typography,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ContextGoalDetail from "../Context/ContextGoalDetail";
import { styles } from "../Style/StyleSubGoal";

import Snackbeer from "../../../../components/Snackbeer";
import IconWarningYellow from "../../../../assets/images/icon_warning_yellow.png";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";

const useStyles = makeStyles(styles);

const DialogConfirmDelete = (props) => {
  const context = useContext(ContextGoalDetail);
  const locationPathGoalId = window.location.pathname.split("/")[2];

  const {
    isOpenDialogConfirmDelete,
    seOpenDialogConfirmDelete,
    // goalId,
    // subGoalId,
    listSubGoalId,
    setSelected,
  } = props;

  const classes = useStyles();

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (isOpenDialogConfirmDelete === true) {
      context.setSuccessDeleteSubGoal(false);
    }
  }, [isOpenDialogConfirmDelete]);

  /*
        ``````````````````````
        HANDLE DELETE SUB GOAL

        ``````````````````````
    */

  const [loader, setLoader] = useState(false);
  const handleDeleteSubGoal = () => {
    setLoader(true);

    // console.log("list subs gg", listSubGoalId);

    listSubGoalId.length > 0 &&
      listSubGoalId.map((item) => {
        // console.log("list subs id", item);

        axiosConfig
          .delete(
            URL_API +
              `/swd/goal/${locationPathGoalId}/direct-link/${item}:remove`
          )
          .then(function (response) {
            setLoader(false);
            console.log("Response Original DELETE : ", response);
            seOpenDialogConfirmDelete(false);
            context.setSuccessDeleteSubGoal(
              Math.floor(Math.random() * 1000 + 1)
            );
            setModalResponse200(true);

            setSelected([]);
          })
          .catch(function (error) {
            console.log("Error : ", error.response);
            setLoader(false);

            // error response
            if (error.response !== undefined) {
              let responseStatus = error.response.status;

              setErrorStatus(responseStatus);
              setOpenDialogError(true);

              if ([422, 400].includes(responseStatus)) {
                if (
                  error.response.data.info.errors !== null &&
                  error.response.data.info.errors !== undefined
                ) {
                  if (error.response.data.info.errors.length > 0) {
                    setOpenDialogError(true);
                    setListError(error.response.data.info.errors);
                  }
                }
              }

              if (
                [503, 500, 403, 429, 405, 404, 401].includes(responseStatus)
              ) {
                if (error.response.data.info.hasOwnProperty("message")) {
                  setTextErrorMessage(
                    `Error ${responseStatus} : ` +
                      error.response.data.info.message
                  );
                }
              }
            } else {
              setOpenDialogError(true);
              setTextErrorMessage("Whoops, something went wrong !");
            }
          });
      });
  };

  /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */
  const [isModalResponse200, setModalResponse200] = useState(false);

  return (
    <Fragment>
      <Dialog
        open={isOpenDialogConfirmDelete}
        onClose={() => seOpenDialogConfirmDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <img
            src={IconWarningYellow}
            alt="info-icon-pict-info"
            style={{ width: 48, height: 40 }}
          />
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}>
            <b>
              Apakah Anda yakin ingin memutus hubungan <i>Sub-Goal</i> dan{" "}
              <i>Main-Goal</i> yang di tandai ?
            </b>
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "grey" }}>
            <i>
              Seluruh Sub-Goal, Measure Activity, dan Result Value akan di hapus
            </i>
          </Typography>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Box marginLeft={4}>
            <Button
              onClick={handleDeleteSubGoal}
              // onClick={() => seOpenDialogConfirmDelete(false)}
              variant="outline"
              className={classes.buttonOutlined}
              // fullWidth
            >
              {loader !== true ? (
                "Hapus"
              ) : (
                <CircularProgress size={16} style={{ color: "red" }} />
              )}
            </Button>

            <Button
              size="small"
              // onClick={handleDelete}
              onClick={() => seOpenDialogConfirmDelete(false)}
              variant="contained"
              className={classes.buttonModalDelete}
              // fullWidth
            >
              Batal
            </Button>
          </Box>
        </DialogActions>
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />

      <Snackbeer
        classes={classes}
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages={`Berhasil delete ${listSubGoalId.length} yang di tandai !`}
      />
    </Fragment>
  );
};

export default DialogConfirmDelete;

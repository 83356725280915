import React, { Component, useEffect, useState } from "react";
import { makeStyles, createMuiTheme, withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { Container, Paper, Typography, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box, Button, MenuItem, Popover, Slider} from '@material-ui/core';
import LensIcon from '@material-ui/icons/LensOutlined';
import axios from 'axios';

import moment from 'moment';

import DialogModalUpgrade from './ComponentsMembershipStatus/DialogModalUpgrade';
import { useGetHttp } from '../../../utilities-hook/useGetHttp';
import { URL_API } from '../../../constants/config-api';
import Redirect from '../../../utilities/Redirect';
import { ToMembershipStatusManagePENDING_SEMENTARA} from '../../../constants/config-redirect-url';

import PictPaketBasic from '../../../assets/images/SVG/Group_1655.svg';
import PictPaketSilver from '../../../assets/images/SVG/Group_1.svg';
import PictPaketGold from '../../../assets/images/SVG/Group_2.svg';
import PictPaketPlatinum from '../../../assets/images/SVG/Group_3.svg';
import IconSliderPacketMahkota from '../../../assets/images/Group_1745.png';

import PictBackgroundImage from '../../../assets/images/Group_1922.png'
import { PrettoSlider, AirbnbThumbComponent } from '../../../components/Pretto';
import { styles } from './styles/StyleMembershipStatus';

const numeral = require('numeral');

const theme = createMuiTheme({
    
    palette: {

        primary: {
            main: '#cc0707', //#cc0707, #c62828
            light: '#ff5f52',
            dark: '#8e0000',
            contrastText: '#ffffff'
        }
    },
    overrides: {
      
    }   
});


const ViewMembershipStatus = props => {

    const { classes } = props;


    /* 
        ````````````````````
        COMPONENT DID MOUNT 

        ````````````````````

        *Kalau user sudah melakukan UPGRADE dari TRIAL to PLATINUM OR PAKET WHATEVER maka akan mendapatkan status 422, dan 
        kalau muncul status 422 maka akan di arahkan ke "ToMembershipStatusManagePENDING_SEMENTARA"
 
    */

    //*two
    const [ membershipTypeName, setMembershipTypeName ] = useState('');

    const [ userTokenState, setUserTokenState ] = useState(undefined);
    const [companyName, setCompanyName] = useState('');
    const [ expireDate, setExpireDate ] = useState(null);
    const [ userCount, setUserCount ] = useState('');
    const [ userCountHasBeenUsed, setUserCountHasBeenUsed ] = useState('');
    const [ updatedAt, setUpdatedAt ] = useState('');

    useEffect(() => {

        setValueInputText(0)

        const userToken = localStorage.getItem('userToken');
        const statusUserNow = localStorage.getItem('status_user_login');
        const statusUserNowAfterParse = JSON.parse(statusUserNow);

        console.log(`statusUserNow : `, statusUserNowAfterParse);

        if(statusUserNowAfterParse !== null){

            setMembershipTypeName(statusUserNowAfterParse.membership_type_name)
            setExpireDate(statusUserNowAfterParse.group_expiry_date);
            setUserCount(statusUserNowAfterParse.group_membership_user_count);
            setUserCountHasBeenUsed(statusUserNowAfterParse.group_membership_user_used);
            
        };

        if(userToken !== undefined){

            setUserTokenState(userToken)
        };

        const header =  {     

            'Accept': "application/json",
            'Content-Type' : "application/json",
            'Authorization' : "bearer " + userToken,
        };
    
        axios.defaults.headers.common = header;    
            
        axios
            .get(URL_API + `/group/patch`)
            .then(function(response){

                console.log("Response Original GET NAME COMPANY : ", response)

                if(response.status == 200){
                    if(response.data.data !== undefined){

                        if(response.data.data.fields.name.value !== undefined){

                            setCompanyName(response.data.data.fields.name.value);
                        };
                    };
                };
            })
            .catch(function(error){
                
                console.log("Error : ", error.response)

            });


            /*
                ``````````````
                GET UPDATED_AT

                ``````````````
            */

            axios
                .get(URL_API + `/group/membership/premium`)
                .then(function(response){

                    console.log("Response Original  : ", response)
                    // setFetchedData(response.data.data);

                    if(response.status == 200){
                        if(response.data.data !== undefined){

                            setUpdatedAt(response.data.data.updated_at);
                        };
                    };
            })
            .catch(function(error){
                
                console.log("Error : ", error.response);

                if(error.response.status == 422){

                    Redirect(ToMembershipStatusManagePENDING_SEMENTARA);
                }

            });
        
    }, [])

    /*
        `````````````````````````
        GET DATA FROM DATABASE

        `````````````````````````
    */
    /*
        ````````````````
        HANDLE POPOVER

        ````````````````
    */
    const [ anchorEl, setAnchorEl ] = useState(null);
    const openPopover = Boolean(anchorEl);

    const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget)
    const handlePopoverClose = () => setAnchorEl(null);

     /*
        ````````````````````````````````
        HANDLE TOGGLE BOX MAHKOTA PACKET, 

        ````````````````````````````````
    */
    let inisiatePacket = {
        id: 'b28f5f75-9836-40be-b762-7d577a115464',
        value: 'Basic',
        choose: 'Basic',
        image: PictPaketBasic,
        user: 1,
        price: 75000
    };

    const [activeBoxPict, setActiveBoxPict] = useState(inisiatePacket)

    const handleActiveBoxPict = (packet) => {

        console.log("Packet : ", packet);
        setActiveBoxPict(packet);
        setValueInputText('');

        if(packet.user == 1){

            setValueSliderMahkota(1);
        };  
        
        if(packet.user == 11){

            setValueSliderMahkota(11);
        };
        
        if(packet.user == 51){

            setValueSliderMahkota(51);
        }; 
        
        if(packet.user == 101){

            setValueSliderMahkota(101);
        }; 
       
    };

    /*
        ````````````````````````````````````
        HANDLE TOGGLE BUTTON DURATION MONTH

        ```````````````````````````
    */
    let inisiateDuration = {

        value: '1 Bulan',
        discount: 0,
        id: '59bef0c5-f5dc-423b-8fc6-9d5f11e618fc',
        duration: 1

    };

    const [activeButton, setActiveButton] = useState(inisiateDuration);
    const [nominalAfterDiscount, setNominalAfterDiscount] = useState(undefined);

    const handleActiveButtonDuration = (value) => {

        console.log("Value : ", value);
        setActiveButton(value);

        setValueSliderMahkota(1);
        setValueInputText(1)

        let dummyBasic = {

            id: 'b28f5f75-9836-40be-b762-7d577a115464',
            value: 'Basic',
            choose: 'Basic',
            image: PictPaketBasic,
            user: 1,
            price: 75000
        };
        
        setActiveBoxPict(dummyBasic);
        
    };

    
    /*
        `````````````````````
        HANDLE MODAL UPGRADE

        `````````````````````
    */
    const [isModalUpgrade, setModalUpgrade] = useState(false)

    const [paramsData, setParamsData] = useState();
    const handleUpgrade = () => {
   
        let params = {

            membership_type_id : activeBoxPict.id,
            membership_duration_id: activeButton.id,
            bank_id: 'c7333fd9-1c18-4613-870c-c8c9f24b52d3',
            user_count: valueSliderMahkota,
            updated_at: updatedAt !== '' ? updatedAt : ''
        };

        console.log("valueSliderMahkota : ", valueSliderMahkota)

        setParamsData(params);

        if(valueSliderMahkota < 200 || valueSliderMahkota == 200){

            setModalUpgrade(true);   
        };
        
        if(valueSliderMahkota > 200 ){

            alert('Untuk saat ini maximum user adalah 200 User !');
            setValueSliderMahkota(1);
            setValueInputText(1);

        };
    };


    //****************8 */

    useEffect(() => {

        let nominalDiscount = (activeBoxPict.price * activeBoxPict.user) * activeButton.discount/100;
        let nominalNormalPrice = activeBoxPict.price * activeBoxPict.user;
    
        const nominalPriceAfterSubtractionDiscount = nominalNormalPrice - nominalDiscount;
        
        setNominalAfterDiscount(nominalPriceAfterSubtractionDiscount);
        
    },[activeButton, activeBoxPict]);   
    
    /*
        `````````````````````````````````
        HANDLE SLIDER BOX PACKET MAHKOTA

        ``````````````````````````````````
    */

   const [valueSliderMahkota, setValueSliderMahkota ] = useState(1);

   const handleChangeSliderMahkota = (event, value) => {

        event.preventDefault();
        event.stopPropagation();

        setValueInputText('');

        // numeral(value).format('0,0')
        const rangeValueSlider = numeral(value).format('0,0');

        // let nominalDiscount = (activeBoxPict.price * activeBoxPict.user)*activeButton.discount/100;
        let nominalDiscount = (activeBoxPict.price * rangeValueSlider)*activeButton.discount/100;

        let nominalNormalPriceInSlider =  activeBoxPict.price * rangeValueSlider;

        const nominalPriceAfterSubtractionDiscount = nominalNormalPriceInSlider - nominalDiscount;


        /*

            ```````````````````````````````````
            Important Dummy Data inside SLIDER

            ```````````````````````````````````
        */

        let dummyBasic = {

            id: 'b28f5f75-9836-40be-b762-7d577a115464',
            value: 'Basic',
            choose: 'Basic',
            image: PictPaketBasic,
            user: 1,
            price: 75000
        };

        let dummySilver = {

            id: 'b5adb87a-4f22-4a5f-a285-d98daf59bbfd',
            value: 'Silver',
            choose: 'Silver',
            image: PictPaketSilver,
            user: 11,
            price: 65000
        };

        let dummyGold = {

            id: 'ab7d2c39-db0d-4a50-8f13-50183ca7dbec',
            value: 'Gold',
            choose: 'Gold',
            image: PictPaketGold,
            user: 51,
            price: 50000
        };

        let dummyPlatinum = {
            
            id: '8c7d1cef-719f-4653-9495-16dfcb2c2aee',
            value: 'Platinum',
            choose: 'Platinum',
            image: PictPaketPlatinum,
            user: 101,
            price: 40000
        };

        if(rangeValueSlider < 11){
        
            setActiveBoxPict(dummyBasic)
            setValueSliderMahkota(value);
            setNominalAfterDiscount(nominalPriceAfterSubtractionDiscount);

        } else if(rangeValueSlider < 51){

            setActiveBoxPict(dummySilver);
               setValueSliderMahkota(value);
            // setValueSliderMahkota(numeral(value).format('0,0'));
            setNominalAfterDiscount(nominalPriceAfterSubtractionDiscount);

        } else if(rangeValueSlider < 101){

            setActiveBoxPict(dummyGold);
            setValueSliderMahkota(value);
            // setValueSliderMahkota(numeral(value).format('0,0'));
            setNominalAfterDiscount(nominalPriceAfterSubtractionDiscount);

        } else {
            setActiveBoxPict(dummyPlatinum);
            setValueSliderMahkota(value);
            // setValueSliderMahkota(numeral(value).format('0,0'));
            setNominalAfterDiscount(nominalPriceAfterSubtractionDiscount);
        };
    };

    /*

        ``````````````````````````
        HANDLE INPUT JUMLAH USER 

        ``````````````````````````
    */

    const[dataInputJumlahUser, setValueInputText] = useState('');

    const handleInputJumlahUser = (e) => {
        
        e.preventDefault();

        if(e.target.value == 0){
            setValueInputText(1)
        };

        setValueInputText(e.target.value);
        setValueSliderMahkota(e.target.value);

        /*
           `````````````````````````````````````````````
           Important Dummy Data inside INPUT JUMLAH USER

           `````````````````````````````````````````````
        */

        let dummyBasic = {

            id: 'b28f5f75-9836-40be-b762-7d577a115464',
            value: 'Basic',
            choose: 'Basic',
            image: PictPaketBasic,
            user: 1,
            price: 75000
        };

        let dummySilver = {

            id: 'b5adb87a-4f22-4a5f-a285-d98daf59bbfd',
            value: 'Silver',
            choose: 'Silver',
            image: PictPaketSilver,
            user: 11,
            price: 65000
        };

        let dummyGold = {

            id: 'ab7d2c39-db0d-4a50-8f13-50183ca7dbec',
            value: 'Gold',
            choose: 'Gold',
            image: PictPaketGold,
            user: 51,
            price: 50000
        };

        let dummyPlatinum = {
            
            id: '8c7d1cef-719f-4653-9495-16dfcb2c2aee',
            value: 'Platinum',
            choose: 'Platinum',
            image: PictPaketPlatinum,
            user: 101,
            price: 40000
        };

        if(e.target.value < 11){

            setActiveBoxPict(dummyBasic);
        };

        if(e.target.value < 51 && e.target.value > 11){

            setActiveBoxPict(dummySilver);
        };

        if(e.target.value < 101 && e.target.value > 51){

            setActiveBoxPict(dummyGold);
        };

        if(e.target.value == 200 && e.target.value > 101){

            setActiveBoxPict(dummyPlatinum);
        };

        //************************************** BaCKUP*/
        // let nominalDiscount = (activeBoxPict.price * e.target.value)*activeButton.discount/100;

        // let nominalNormalPriceInSlider =  activeBoxPict.price * e.target.value;
        // const nominalPriceAfterSubtractionDiscount = nominalNormalPriceInSlider - nominalDiscount;

        // setNominalAfterDiscount(nominalPriceAfterSubtractionDiscount);

    };

    useEffect(() => {


        let nominalDiscount = (activeBoxPict.price * dataInputJumlahUser)*activeButton.discount/100;

        let nominalNormalPriceInSlider =  activeBoxPict.price * dataInputJumlahUser;
        const nominalPriceAfterSubtractionDiscount = nominalNormalPriceInSlider - nominalDiscount;

        setNominalAfterDiscount(nominalPriceAfterSubtractionDiscount);
      

    },[dataInputJumlahUser])
    
    /*
        ````````````````````````````````
        HANDLE NEXT BIRTHDAY IN MOMENT JS

        ````````````````````````````````
    */
    function daysRemaining() {

        // let eventdate =  moment().add(1, 'months') //* NEXT mesti dapetin data "masaBerlakuAwal" ketika login dan atau setelah register !
        let eventdate =  moment(expireDate)
        let todaysdate = moment();
        return eventdate.diff(todaysdate, 'days');
    };

    return (
        
        <MuiThemeProvider theme={theme}>
            <Paper className={classes.root} elevation={0}>
                <Grid 
                    container 
                    alignItems="flex-start"
                >  
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Keanggotaan
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
            <Paper 
                elevation={0} 
                square 
                style={{
                    marginLeft:'16px', 
                    marginRight: '16px',
                    backgroundImage: 'url(' + PictBackgroundImage + ')', 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Grid container>
                    <Grid item xs={3}>
                        <Typography 
                            variant="subtitle2" 
                            className={classes.kuotaUser}
                            style={{color: 'grey'}}
                        >
                            {userCountHasBeenUsed} <i>Kuota user</i> sudah di gunakan dari {userCount}
                        </Typography>
                    </Grid>
                      
                    <Grid item xs={8}>
                        <Typography variant="subtitle2" className={classes.masaBerlaku}>
                            Masa berlaku anda &nbsp;
                                {
                                    daysRemaining() + ' hari lagi'
                                }
                        </Typography>
                    </Grid>

                    <Grid item xs={1}>
                        <Button variant='outlined' size='small' className={classes.buttonStatusActive} >
                            <LensIcon 
                                style={{ color: '#19dc5a', width: 10, marginRight: 7}}            
                            />
                            Aktif
                        </Button>
                    </Grid>
                </Grid>

                <Grid 
                    container 
                    justify="center" 
                    alignItems="center"
                >  
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Typography variant="h5" className={classes.titleCompanyName}>
                            { companyName !== '' ? companyName : "PT. ***" }
                        </Typography>

                        <Typography variant="subtitle1" className={classes.titleTrial}>
                            {
                                membershipTypeName == null ? "Trial" : membershipTypeName
                            }
                                
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
            <Paper className={classes.subRoot3} elevation={0}>
                <Grid 
                    container 
                    justify="center" 
                    alignItems="center"
                >  
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Typography variant="subtitle1" className={classes.pilihRencana}>
                            Pilih Rencana
                        </Typography>

                        { 
                            duration.length > 0 && duration.map((item, i) => {

                                return (
                                    <Button 
                                        onClick={ () => handleActiveButtonDuration(item) }
                                        variant={activeButton.value === item.value ? 'contained' : 'outlined'}
                                        className={activeButton.value === item.value ? classes.buttonToggle : null}
                                        variant='outlined' 
                                        size='small' 
                                        key={i} 
                                        style={{margin: 7}}
                                    >
                                        {item.value}
                                    </Button>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Paper>

            <Paper className={classes.subRoot4} elevation={0}>
                <Grid container>  
                        <Grid item xs={4} style={{textAlign: 'center'}}>
                            <br />
                            <br />
                            <br />
                            <br />
                            {
                            packets.length > 0 && packets.map((item, i) => {
                         
                                return (

                                    <Box 
                                        key={i} 
                                        component="div" 
                                        m={1} 
                                        className={classes.figureBoxPictMahkota}
                                        onClick={() => handleActiveBoxPict(item)}
                                        style={{backgroundColor: activeBoxPict.value === item.value ? '#fdc9c8' : null}}
                                    >
                                        <figure  className={classes.figurePictMahkota}>
                                            <img 
                                                src={item.image} 
                                                styles={{height: 45, width: 45}} 
                                                alt={item.choose}
                                            />
                                            <figcaption style={{fontSize: 12}}>
                                                <b>{item.choose}</b>
                                            </figcaption>

                                        
                                        </figure>
                                    </Box>
                                    
                                )
                            })
                            }
                        </Grid>
                        
                        <Grid item xs={4} style={{textAlign: 'center'}}>
                            <br />
                            <br />
                            <br />
                            <Typography variant="subtitle1">
                                Jumlah User : &nbsp;
                                    <input 
                                        type='text' 
                                        className={classes.inputTextJumlahUser} 
                                        placeholder={ numeral(valueSliderMahkota).format('0,0') > 1 ? numeral(valueSliderMahkota).format('0,0') :  activeBoxPict.user}

                                        onChange={handleInputJumlahUser}
                                        value={dataInputJumlahUser}
                                    />
                            </Typography>
                        
                            <br />
                            <PrettoSlider 
                                // valueLabelDisplay="auto" 
                                aria-label="slider-mahkota" 
                                // defaultValue={20} 
                                ThumbComponent={
                                    AirbnbThumbComponent
                                }
                                max={200}
                                min={1}
                                value={valueSliderMahkota}
                                aria-labelledby="slider-mahkota"
                                onChange={handleChangeSliderMahkota}
                            
                                
                            />
                        </Grid>

                        <Grid item xs={4} style={{textAlign: 'center'}}>
                        <br />
                        <br />
                        <br />
                        <Typography variant="h6" className={classes.titleStrikePrice}>
                            {
                                activeButton.discount !== 0 ? (

                                    // <strike>Rp {numeral((activeBoxPict.price * valueSliderMahkota)).format('0,0')}</strike>
                                    <strike>Rp {numeral((activeBoxPict.price * valueSliderMahkota * activeButton.duration)).format('0,0')}</strike>

                                ) : null
                            }
                        </Typography>

                        {
                            isModalUpgrade == false ? (
                            
                                <Typography variant="h3" className={classes.price}>
                                    <span style={{fontSize: 14}}>Rp</span> {numeral(nominalAfterDiscount * activeButton.duration).format('0,0')}
                                </Typography>

                            ) : null
                        }

                        <Typography variant="caption" className={classes.discount}>
                            {
                                activeButton.discount !== 0 ? (

                                    "Potongan " + activeButton.discount + "%"

                                    ) : null
                            }
                        </Typography>
                        <br />
                        <br />
                        
                        <Button 
                            variant='outlined' 
                            size='large' 
                            className={classes.buttonUpgrade} 
                            onClick={handleUpgrade}
                        >
                            Upgrade
                        </Button>
                        </Grid>
                </Grid>
            </Paper>

            <DialogModalUpgrade 
                classes={classes} 
                userTokenState={userTokenState}
                isModalUpgrade={isModalUpgrade} 
                setModalUpgrade={setModalUpgrade}
                paramsData={paramsData}

                nominalAfterDiscount={nominalAfterDiscount}
                setNominalAfterDiscount = { setNominalAfterDiscount }
            />
        </MuiThemeProvider>
    )
};

export default withStyles(styles)(ViewMembershipStatus);

const duration = [

    {
        value: '1 Bulan',
        discount: 0,
        id: '59bef0c5-f5dc-423b-8fc6-9d5f11e618fc',
        duration: 1
    },
    {
        value: '3 Bulan',
        discount: 10,
        id:'d1e942f1-8705-4095-9afa-b2bd40daf264',
        duration: 3
    },
    {
        value: '6 Bulan',
        discount: 15,
        id: '4f63f815-bab7-4c6c-95c2-c0e1c619085d',
        duration: 6
    },
    {
        value: '1 Tahun',
        discount: 20,
        id:'fd685d2b-7f4f-4963-961d-529d30fce305',
        duration: 12
    },
];


const packets = [

    {
        id: 'b28f5f75-9836-40be-b762-7d577a115464',
        value: 'Basic',
        choose: 'Basic',
        image: PictPaketBasic,
        user: 1,
        price: 75000,
    },
    {
        id: 'b5adb87a-4f22-4a5f-a285-d98daf59bbfd',
        value: 'Silver',
        choose: 'Silver',
        image: PictPaketSilver,
        user: 11,
        price: 65000
    },
    {
        id: 'ab7d2c39-db0d-4a50-8f13-50183ca7dbec',
        value: 'Gold',
        choose: 'Gold',
        image: PictPaketGold,
        user: 51,
        price: 50000
    },
    {
        id: '8c7d1cef-719f-4653-9495-16dfcb2c2aee',
        value: 'Platinum',
        choose: 'Platinum',
        image: PictPaketPlatinum,
        user: 101,
        price: 40000
    },
];

import React from "react";
import {
  Box,
  Divider,
  Paper,
  Typography,
  IconButton,
  Button,
} from "@material-ui/core";
import FeedbackRoundedIcon from "@material-ui/icons/FeedbackRounded";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";

const ListMeetingItem = ({ classes, item }) => {
  return (
    <Box mb={4}>
      <Paper elevation={3}>
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <Box display="flex" alignItems="center">
            <Typography variant="body1" className={classes.title333}>
              <b>{item.title}</b>
            </Typography>

            <FeedbackRoundedIcon
              style={{ fontSize: 28, color: "#d1354a", marginLeft: 15 }}
            />
          </Box>

          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" className={classes.title888}>
              <b>{item.user}</b>
            </Typography>
            <AccountCircleIcon
              style={{ fontSize: 36, color: "#888", marginLeft: 20 }}
            />
            <IconButton style={{ marginLeft: 15 }} size="small">
              <ArrowDropDownIcon style={{ fontSize: 24, color: "#888" }} />
            </IconButton>
          </Box>
        </Box>
        <Box pb={4}>
          <Divider />
        </Box>

        <Box px={3}>
          <Typography variant="subtitle1" className={classes.title555}>
            <b>Discusions</b>
          </Typography>

          {item.remarks.length > 0 && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <ul>
                <li>
                  <Typography variant="subtitle1" className={classes.title555}>
                    <b>Remarks</b>
                  </Typography>
                </li>
                {item.remarks.map((remark, i) => (
                  <Typography
                    key={i}
                    variant="subtitle1"
                    className={classes.title555}>
                    {remark}
                  </Typography>
                ))}
              </ul>

              <Box display="flex" alignItems="center">
                <Button
                  variant="outlined"
                  className={classes.btnDone}
                  size="small">
                  Done
                </Button>
                <IconButton style={{ marginLeft: 20 }}>
                  <MoreVertOutlinedIcon
                    style={{ fontSize: 24, color: "#555" }}
                  />
                </IconButton>
              </Box>
            </Box>
          )}

          {item.problems.length > 0 && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <ul>
                <li>
                  <Typography variant="subtitle1" className={classes.title555}>
                    <b>Problems</b>
                  </Typography>
                </li>
                {item.problems.map((problem, i) => (
                  <Typography
                    key={i}
                    variant="subtitle1"
                    className={classes.title555}>
                    {problem}
                  </Typography>
                ))}
              </ul>

              <Box display="flex" alignItems="center">
                <Button
                  variant="outlined"
                  className={classes.btnNav}
                  size="small">
                  Pending
                </Button>
                <IconButton style={{ marginLeft: 20 }}>
                  <MoreVertOutlinedIcon
                    style={{ fontSize: 24, color: "#555" }}
                  />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>

        {item.actions.length > 0 && (
          <Box px={3} mt={3} mb={5}>
            <Typography variant="subtitle1" style={{ marginBottom: 24 }}>
              <b>Actions</b>
            </Typography>

            <Paper variant="outlined" style={{ backgroundColor: "#f1f1f1f1" }}>
              {item.actions.map((action, i) => (
                <Box
                  key={i}
                  pl={2}
                  py={0.5}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <CheckBoxOutlineBlankOutlinedIcon
                      style={{
                        fontSize: 20,
                        color: "#d1354a",
                        marginRight: 10,
                      }}
                    />
                    <Typography
                      variant="subtitle1"
                      style={{ color: "#d1354a" }}>
                      {action}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <DateRangeOutlinedIcon
                      style={{
                        fontSize: 20,
                        color: "#d1354a",
                        marginRight: 10,
                      }}
                    />
                    <Typography
                      variant="subtitle1"
                      style={{ color: "#d1354a", marginRight: 30 }}>
                      {item.date}
                    </Typography>
                    <Button
                      variant="outlined"
                      className={classes.btnNav}
                      size="small">
                      Pending
                    </Button>
                    <IconButton style={{ marginLeft: 20 }}>
                      <MoreVertOutlinedIcon
                        style={{ fontSize: 24, color: "#555" }}
                      />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Paper>
          </Box>
        )}

        <Box
          px={3}
          pb={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <AccessAlarmIcon
              style={{ fontSize: 20, color: "#d1354a", marginRight: 10 }}
            />
            <Typography variant="body1" className={classes.title333}>
              {item.duration}
            </Typography>
          </Box>

          <IconButton size="small">
            <AddBoxOutlinedIcon style={{ fontSize: 28, color: "#d1354a" }} />
          </IconButton>
        </Box>
      </Paper>
    </Box>
  );
};

export default ListMeetingItem;

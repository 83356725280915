import React from "react";
import { Box, Divider } from "@material-ui/core";

import TopTitle from "./TopTitle";
import ListMeetingItem from "./ListMeetingItem";

const LeftMenu = ({ classes }) => {
  return (
    <Box ml={4} mt={3} mr={4}>
      <TopTitle classes={classes} />

      <Box mt={3} mb={4}>
        <Divider />
      </Box>

      {listMeeting.map((item, i) => (
        <ListMeetingItem key={i} classes={classes} item={item} />
      ))}
    </Box>
  );
};

export default LeftMenu;

const listMeeting = [
  {
    id: 1,
    title: "2.1 Jumlah Visitor dari Artikel",
    user: "Bagus Harianto",
    remarks: [
      "Ada penurunan drastis secara konsisten dari Q1 rata-rata visitor",
    ],
    problems: [],
    actions: ["FGD meningkatkan konversi calon buyer"],
    duration: "5 min",
    date: "06 Apr 2021",
  },
  {
    id: 2,
    title: "2.2 Web Visitors (buyer)",
    user: "Bagus Harianto",
    remarks: [],
    problems: ["Tidak ada Add to Cart"],
    actions: [],
    duration: "5 min",
    date: "06 Apr 2021",
  },
  {
    id: 3,
    title: "2.3 Produksi Video",
    user: "Bagus Harianto",
    remarks: [],
    problems: [],
    actions: ["Perbaikan kualitas audio untik produksi video"],
    duration: "5 min",
    date: "06 Apr 2021",
  },
  {
    id: 4,
    title: "2.4 Revisi goal dan MA",
    user: "Bagus Harianto",
    remarks: [],
    problems: [],
    actions: ["Revisi goal dan MA"],
    duration: "5 min",
    date: "06 Apr 2021",
  },
  {
    id: 5,
    title: "2.5 Web Visitors (merchant)",
    user: "Bagus Harianto",
    remarks: [
      "Ada penurunan drastis secara konsisten dari Q1 rata-rata visitor",
    ],
    problems: [],
    actions: ["FGD Riset mahasiswa Mas Irham"],
    duration: "5 min",
    date: "06 Apr 2021",
  },
];

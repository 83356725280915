import React, { useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import ContextGlobalReports from "../../context/ContextReports";

const TotalRecords = ({ classes }) => {
  const { tableState } = useContext(ContextGlobalReports);

  return (
    <Box display="flex" alignItems="center">
      <Typography className={classes.title555} variant="subtitle2">
        Total :{" "}
        <span
          style={{
            backgroundColor: "#aaa",
            color: "#fff",
            padding: "0 5px",
            borderRadius: 4,
          }}>
          <b>{tableState.totalRecords}</b>
        </span>{" "}
        Records
      </Typography>
    </Box>
  );
};

export default TotalRecords;

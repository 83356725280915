import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Paper,
  Link,
  Chip,
} from "@material-ui/core";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import DateRangeIcon from "@material-ui/icons/DateRange";
import UpdateIcon from "@material-ui/icons/Update";
import BlockIcon from "@material-ui/icons/Block";

import useSWR from "swr";
import Countdown from "react-countdown";

import {
  STATUS_NONE,
  STATUS_TRIAL,
  STATUS_ACTIVE,
  STATUS_LAPSED,
  STATUS_INACTIVE,
  DEPLOYMENT,
  USER_LICENSE,
  PMO_VISIT,
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_PAID,
  PAYMENT_STATUS_CANCELLED,
} from "../../MembershipManagement/constant-data";
import Axios from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

// import { navigate } from "hookrouter";
// import { ToLogin } from "../../../../constants/config-redirect-url";

import DetailUsed from "./components/DetailUsed";
import DetailUsed2 from "./components/DetailUsed2";
import DialogUnsubscribeMembership from "./components/DialogUnsubscribeMembership";
// import DialogDeploymentTicket from "./components/DialogDeploymentTicket";
import DialogExtend from "./components/DialogExtend";
import DialogUpdate from "./components/DialogUpdate";
import DialogSubscribeMembership from "./components/DialogSubscribeMembership";
import DialogPayFromTrial from "./components/DialogPayFromTrial";

// Fetcher
const fetcher = (url) => Axios.get(url).then((r) => r.data.data);

const ViewManageMembership = ({ classes }) => {
  let status_member;
  let status_color;
  let level_color;
  let expired_date;
  let usedMonths;
  let usedDays;
  let leftMonths;
  let leftDays;
  let labelButton = { extend: "", update: "" };
  const userData = localStorage.getItem("status_user_login");
  const user = JSON.parse(userData);
  const pckgName = user.membership_package_name;
  const accName = user.membership_account_type_name;
  const levelName = user.membership_level_name;

  // ========================================
  // ~~~~~ LOCAL STATE ~~~~~
  // ========================================
  const [subscription, setSubscription] = useState({
    start_date: "",
    finish_date: "",
    duration: {
      year: 0,
      months: 0,
      days: 0,
      hours: 0,
      minute: 0,
    },
    used: {
      year: 0,
      months: 0,
      days: 0,
      hours: 0,
      minute: 0,
    },
    left: {
      year: 0,
      months: 0,
      days: 0,
      hours: 0,
      minute: 0,
    },
    trial: {
      duration: 0,
      end_date: "",
      start_date: "",
      used: {
        year: 0,
        months: 0,
        days: 0,
        hours: 0,
        minute: 0,
      },
      left: {
        year: 0,
        months: 0,
        days: 0,
        hours: 0,
        minute: 0,
      },
    },
    lapsed: {
      duration: 0,
      end_date: "",
      start_date: "",
      used: {
        year: 0,
        months: 0,
        days: 0,
        hours: 0,
        minute: 0,
      },
      left: {
        year: 0,
        months: 0,
        days: 0,
        hours: 0,
        minute: 0,
      },
    },
  });
  const [deployTicket, setDeployTicket] = useState(0);
  const [userLicense, setUserLicense] = useState(0);
  const [pmoTicket, setPmoTicket] = useState(0);
  const [openDialogSubscribe, setOpenDialogSubscribe] = useState(false);
  const [openDialogUnsibscribe, setOpenDialogUnsubscribe] = useState(false);
  const [openDialogExtend, setOpenDialogExtend] = useState(false);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);

  // Checkout & Payment State
  const [checkoutData, setCheckoutData] = useState({});
  const [openDialogPayFromTrial, setOpenDialogPayFromTrial] = useState(false);

  // ========================================
  // ~~~~~ SIDE EFFECTS ~~~~~
  // ========================================
  const { data: detailPckg } = useSWR(
    `${URL_API}/account-management/group-membership-item`,
    fetcher,
    {
      onSuccess: (result) => {
        // console.log("on-result", result);
        setSubscription(result.subscription);
      },
    }
  );

  useEffect(() => {
    Axios.get(
      `${URL_API}/account-management/membership-payment/check-status`
    ).then((res) => {
      // console.log("Payment Check : ", res.data.data);

      const result = res.data.data;
      if (res.status === 200) {
        setCheckoutData(result);

        if (result.status_id === PAYMENT_STATUS_PENDING) {
          setOpenDialogPayFromTrial(true);
          console.log("Pendings");
        }
      }
    });
  }, []);

  useEffect(() => {
    detailPckg !== undefined &&
      detailPckg.membership_items.forEach((item) => {
        if (item.membership_item_id === DEPLOYMENT) {
          setDeployTicket(item.quantity);
        } else if (item.membership_item_id === USER_LICENSE) {
          setUserLicense(item.quantity);
        } else if (item.membership_item_id === PMO_VISIT) {
          setPmoTicket(item.quantity);
        }
      });
  }, [detailPckg]);

  // ========================================
  // ~~~~~ LOCAL FUNC ~~~~~
  // ========================================
  if (user.membership_status_id === STATUS_NONE) {
    status_member = "None";
    status_color = "#c0392b";
    expired_date = user.group_expiry_date;
  } else if (user.membership_status_id === STATUS_TRIAL) {
    status_member = "Trial";
    status_color = "#ff9f43";
    expired_date = user.group_trial_expiry_timestamp;
  } else if (user.membership_status_id === STATUS_ACTIVE) {
    status_member = "Active";
    status_color = "#2ecc71";
    expired_date = user.group_expiry_date;
    labelButton = { extend: "Extend", update: "Update" };
  } else if (user.membership_status_id === STATUS_LAPSED) {
    status_member = "Lapsed";
    expired_date = user.group_expiry_date;
    labelButton = { extend: "Pay to Extend", update: "Update & Extend" };
  } else if (user.membership_status_id === STATUS_INACTIVE) {
    status_member = "Inactive";
    expired_date = user.group_expiry_date;
  }

  if (levelName === "Basic") {
    level_color = "#cd6133";
  } else if (levelName === "Silver") {
    level_color = "#a5b1c2";
  } else if (levelName === "Gold") {
    level_color = "#f7b731";
  } else if (levelName === "Platinum") {
    level_color = "#84817a";
  }

  // console.log("Tehe sub", subscription.lapsed);

  if (status_member === "Trial") {
    usedMonths = subscription.trial && subscription.trial.used.months;
    usedDays = subscription.trial && subscription.trial.used.days;
    leftMonths = subscription.trial && subscription.trial.left.months;
    leftDays = subscription.trial && subscription.trial.left.days;
  } else if (status_member === "Lapsed") {
    if (
      subscription.lapsed.duration !== 0 &&
      subscription.lapsed.end_date !== ""
    ) {
      // console.log("Objecttt");
      usedMonths =
        subscription.lapsed &&
        subscription.lapsed.used.months + subscription.used.months;
      usedDays =
        subscription.lapsed &&
        subscription.lapsed.used.days + subscription.used.days;
      leftMonths = subscription.lapsed && subscription.lapsed.left.months;
      leftDays = subscription.lapsed && subscription.lapsed.left.days;
      // } else {
      //   console.log("NOt Object");
    }
  } else {
    usedMonths = subscription.used.months;
    usedDays = subscription.used.days;
    leftMonths = subscription.left.months;
    leftDays = subscription.left.days;
  }

  // console.log("OPEN DIALOG PAYMENT STATS", checkoutData);

  return (
    <Box marginTop={4} marginLeft={6}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={8}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <Typography variant="h5" className={classes.title333}>
              <b>Manage Membership</b>
            </Typography>

            <Chip
              label={
                <Typography variant="caption" className={classes.title}>
                  <b>
                    {status_member === "Trial"
                      ? subscription.trial &&
                        `${subscription.trial.start_date} - ${subscription.trial.end_date}`
                      : // : status_member === "Lapsed"
                        // ? subscription.lapsed &&
                        //   `${subscription.lapsed.start_date} - ${subscription.lapsed.end_date}`
                        `${subscription.start_date} - ${subscription.finish_date}`}
                  </b>
                </Typography>
              }
              icon={<DateRangeIcon fontSize="small" />}
              size="small"
              variant="outlined"
              color="primary"
              clickable
            />
          </Box>

          {status_member === "Lapsed" ? (
            <Box display="flex" alignItems="center" marginTop={4}>
              <Button
                className={classes.button5}
                variant="outlined"
                size="small"
                startIcon={
                  <UpdateIcon fontSize="small" style={{ color: "#d1354a" }} />
                }
                style={{ marginRight: 10 }}>
                <b>Overdue</b>
              </Button>
              <Typography variant="caption" className={classes.title888}>
                <Countdown
                  date={
                    new Date(
                      subscription.lapsed && subscription.lapsed.end_date
                    )
                  }
                  renderer={({ days, hours, minutes, seconds, completed }) => {
                    if (completed) {
                      return <span>Periode Overdue berakhir</span>;
                    } else {
                      return (
                        <b>
                          {days + 1} Hari {hours}:{minutes}:{seconds}
                        </b>
                      );
                    }
                  }}
                />
              </Typography>
            </Box>
          ) : status_member === "Inactive" ? (
            <Box marginTop={4}>
              <Button
                className={classes.btnInnactive}
                variant="outlined"
                size="small"
                startIcon={
                  <BlockIcon fontSize="small" style={{ color: "#808080" }} />
                }>
                <b>Inactive</b>
              </Button>
            </Box>
          ) : (
            <Box display="flex" alignItems="center" marginTop={4}>
              <FlashOnIcon fontSize="small" style={{ color: status_color }} />
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: status_color }}>
                <b>{status_member}</b>
              </Typography>
            </Box>
          )}

          <Box display="flex" alignItems="center" marginBottom={2}>
            <Typography
              variant="h6"
              className={classes.title333}
              style={{ marginRight: 10 }}>
              <b>{accName} Account</b>
            </Typography>

            {(status_member !== "Trial" || status_member !== "Inactive") && (
              <>
                <StarRoundedIcon
                  fontSize="small"
                  style={{ color: level_color, marginRight: 3 }}
                />
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: level_color }}>
                  <b>{levelName}</b>
                </Typography>
              </>
            )}
          </Box>

          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "#555" }}>
            Paket membership anda saat ini adalah{" "}
            <span className={classes.txtTrialPaket}>
              {pckgName === null ? "Tidak ada" : pckgName}
            </span>
            . dengan durasi maks{" "}
            <span>
              {subscription.duration.months > 0 &&
                `${subscription.duration.months} bulan`}{" "}
              {subscription.duration.days > 0 &&
                `${subscription.duration.days} hari`}
            </span>
            , dan {userLicense} lisensi user
          </Typography>

          {(status_member !== "Trial" || status_member !== "Inactive") && (
            <>
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "#888", marginTop: 30 }}>
                <b>Detail Paket</b>
              </Typography>

              {!detailPckg ? (
                <Box
                  marginTop={2}
                  marginBottom={9}
                  className={classes.boxDetail}>
                  <Typography variant="subtitle2" className={classes.txtUsed}>
                    Memuat...
                  </Typography>
                </Box>
              ) : (
                <Box
                  marginTop={2}
                  marginBottom={9}
                  className={classes.boxDetail}>
                  <DetailUsed
                    classes={classes}
                    txtClass={classes.txtUsed3}
                    prop1="Lisensi user"
                    val1={userLicense}
                    prop2="Deployment"
                    val2={deployTicket}
                    prop3="Consultant visit"
                    val3={pmoTicket}
                  />
                  <DetailUsed
                    classes={classes}
                    txtClass={classes.txtUsed3}
                    prop1="Total masa aktif"
                    val1={
                      status_member === "Lapsed" ? (
                        <span>
                          {subscription.lapsed && subscription.lapsed.duration}
                        </span>
                      ) : (
                        <span>
                          {subscription.duration.months > 0 &&
                            `${subscription.duration.months} bulan`}{" "}
                          {subscription.duration.days > 0 &&
                            `${subscription.duration.days} hari`}
                        </span>
                      )
                    }
                    prop2="Durasi berjalan"
                    val2={
                      <span>
                        {usedMonths > 0 && `${usedMonths} bulan`}{" "}
                        {usedDays > 0 ? `${usedDays} hari` : "0 hari"}
                      </span>
                    }
                    prop3="Sisa durasi"
                    val3={
                      <span>
                        {leftMonths > 0 && `${leftMonths} bulan`}{" "}
                        {leftDays > 0 ? `${leftDays} hari` : "0 hari"}
                      </span>
                    }
                  />
                </Box>
              )}
            </>
          )}

          {(status_member !== "Trial" || status_member !== "Inactive") && (
            <>
              <Box>
                <Box display="flex" alignItems="center">
                  <ConfirmationNumberIcon
                    style={{ color: "#d64253", fontSize: 28, marginRight: 8 }}
                  />
                  <Typography
                    variant="h6"
                    className={classes.title}
                    style={{ color: "#333" }}>
                    <b>Sesi Deployment</b>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "#aaa", marginLeft: 10 }}>
                    (Seminar & Workshop)
                  </Typography>
                </Box>

                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: "#888", marginTop: 30 }}>
                  <b>Detail Deployment</b>
                </Typography>

                <DetailUsed2 classes={classes} val1={deployTicket} />
              </Box>
            </>
          )}
        </Grid>

        <Grid item sm={4} md={3}>
          {user.membership_status_id === STATUS_TRIAL ||
          user.membership_status_id === STATUS_INACTIVE ? (
            <Paper variant="outlined">
              <Box padding={2}>
                <Typography
                  variant="subtitle1"
                  className={classes.title333}
                  style={{ marginBottom: 10 }}>
                  <b>Subscribe Membership</b>
                </Typography>
                <Typography
                  variant="caption"
                  className={classes.title}
                  style={{ color: "#888" }}>
                  Status membership anda adalah <b>{status_member}</b> akan
                  habis pada{" "}
                  <b style={{ color: "#d64253" }}>
                    {status_member === "Trial"
                      ? subscription.trial && subscription.trial.end_date
                      : subscription.finish_date}
                  </b>
                  . Perpanjang membership untuk tetap berlangganan
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.button2}
                  style={{ marginTop: 20 }}
                  disabled={detailPckg === undefined}
                  onClick={() => setOpenDialogSubscribe(true)}>
                  Subscribe
                </Button>
              </Box>
            </Paper>
          ) : (
            <>
              <Paper variant="outlined" style={{}}>
                <Box padding={2}>
                  <Typography
                    variant="subtitle1"
                    className={classes.title333}
                    style={{ marginBottom: 10 }}>
                    <b>{labelButton.extend} Membership</b>
                  </Typography>
                  <Typography
                    variant="caption"
                    className={classes.title}
                    style={{ color: "#888" }}>
                    Masa aktif akun anda{" "}
                    {status_member === "Lapsed" ? (
                      <b style={{ color: "#d64253" }}>sudah habis</b>
                    ) : (
                      "akan habis"
                    )}{" "}
                    pada{" "}
                    <b style={{ color: "#d64253" }}>
                      {subscription.finish_date}
                    </b>
                    . Perpanjang membership untuk tetap berlangganan
                  </Typography>
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.button2}
                    style={{ marginTop: 20 }}
                    disabled={detailPckg === undefined}
                    onClick={() => setOpenDialogExtend(true)}>
                    {labelButton.extend}
                  </Button>
                </Box>
              </Paper>

              <Paper variant="outlined" style={{ marginTop: 25 }}>
                <Box padding={2}>
                  <Typography
                    variant="subtitle1"
                    className={classes.title333}
                    style={{ marginBottom: 10 }}>
                    <b>{labelButton.update} Membership</b>
                  </Typography>
                  <Typography
                    variant="caption"
                    className={classes.title}
                    style={{ color: "#999" }}>
                    Ubah pilihan berlangganan anda dengan Upgrade & downgrade
                    paket membership
                  </Typography>
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.button4}
                    style={{ marginTop: 20 }}
                    disabled={detailPckg === undefined}
                    onClick={() => setOpenDialogUpdate(true)}>
                    {labelButton.update}
                  </Button>
                </Box>
              </Paper>
            </>
          )}

          {(status_member !== "Trial" || status_member !== "Inactive") && (
            <>
              <Box marginTop={3} marginBottom={8}>
                <Link
                  variant="subtitle1"
                  className={classes.linkCancel}
                  onClick={() => setOpenDialogUnsubscribe(true)}>
                  Cancel Membership
                </Link>
              </Box>

              {/* <Paper variant="outlined" style={{ backgroundColor: "#E8ECF0" }}>
                <Box padding={2}>
                  <Box display="flex" alignItems="center">
                    <ConfirmationNumberIcon
                      style={{
                        color: "#d64253",
                        fontSize: 35,
                        marginRight: 16,
                      }}
                    />

                    <Link
                      variant="subtitle1"
                      className={classes.txtDeployTitel}
                      onClick={() => setOpenDialogDeployTicket(true)}>
                      <b>Beli Tiket Deployment</b>
                    </Link>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    marginLeft={6.5}
                    marginTop={1}>
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "#d64253" }}>
                      <b>Rp 40.000 / Tiket</b>
                    </Typography>
                    <Typography
                      variant="caption"
                      className={classes.title}
                      style={{ color: "#888", marginLeft: 5 }}>
                      ( min 25 )
                    </Typography>
                  </Box>
                </Box>
              </Paper> */}
            </>
          )}
        </Grid>
      </Grid>

      <DialogUnsubscribeMembership
        classes={classes}
        openDialogUnsibscribe={openDialogUnsibscribe}
        setOpenDialogUnsubscribe={setOpenDialogUnsubscribe}
      />
      <DialogExtend
        classes={classes}
        openDialogExtend={openDialogExtend}
        setOpenDialogExtend={setOpenDialogExtend}
        detailPckg={detailPckg}
      />
      <DialogUpdate
        classes={classes}
        openDialogUpdate={openDialogUpdate}
        setOpenDialogUpdate={setOpenDialogUpdate}
        subscription={subscription}
        licenseUser={userLicense}
        pmoTicket={pmoTicket}
        deployTicket={deployTicket}
        statusMember={status_member}
      />
      <DialogSubscribeMembership
        classes={classes}
        openDialogSubscribe={openDialogSubscribe}
        setOpenDialogSubscribe={setOpenDialogSubscribe}
      />

      <DialogPayFromTrial
        classes={classes}
        openDialogPayFromTrial={openDialogPayFromTrial}
        setOpenDialogPayFromTrial={setOpenDialogPayFromTrial}
        checkoutData={checkoutData}
      />
    </Box>
  );
};

export default ViewManageMembership;

import React from "react";
import { Box, Grid } from "@material-ui/core";

import SummaryConfig from "./SummaryConfig";
import BarChartSection from "./bar-chart-section";
import Businessprofile from "./business-profile";
import TableSection from "./table-section";
import OverviewAverage from "./overview-average";

const SummaryType = ({ classes }) => {
  return (
    <Box paddingTop={3}>
      <SummaryConfig classes={classes} />

      {/* Summary Body Container */}
      <Grid container spacing={3}>
        <Grid item md={9}>
          <BarChartSection classes={classes} />
          <br />
          <TableSection classes={classes} />
        </Grid>
        <Grid item md={3}>
          <Businessprofile classes={classes} />
          <br />
          <OverviewAverage classes={classes} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SummaryType;

import React, { Fragment, lazy, Suspense } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

/*
    `````````````
    HEADER LAYOUT
  
    `````````````
*/
import Header from "./layout/Header";
import HeaderGOAL from "./layout/HeaderGOAL";
import HeadNewGeneral from "./layout/HeadNewGeneral";
import HeaderMembershipManagement from "./layout/HeaderMembershipManagement";
import HeaderMembershipOptions from "./layout/HeaderMembershipOptions";
import HeaderCompanyProfile from "./layout/HeaderCompanyProfile";
import HeaderHREmployee from "./layout/HeaderHREmployee";
import HeaderHREmployeeKeluarga from "./layout/HeaderHREmployeeKeluarga";
import HeaderHREmployeeKepegawaian from "./layout/HeaderHREmployeeKepegawaian";
import HeaderHREmployeeKeahlian from "./layout/HeaderHREmployeeKeahlian";
import HeaderHREmployeeRiwayat from "./layout/HeaderHREmployeeRiwayat";

import HeaderHREmployeeUrgentContact from "./layout/HeaderHREmployeeUrgentContact";
import HeaderProfileDetail from "./layout/HeaderProfileDetail";
import HeaderSO from "./layout/HeaderSO";

import "./App.css";

/*
    ````````````````````````
    CONTEXT API GLOBAL STATE
  
    ````````````````````````
*/
import ContextGlobalTask from "./screen/SPECIAL/Context/ContextGlobalTask";

import ContextGlobalNewSO from "./screen/SO/Context/ContextGlobalNewSO";

import ContextGlobalGoal from "./screen/7WD/Goal/Context/ContextGlobalGoal";

import ContextGlobalGoalDetail from "./screen/7WD/Goal/Context/ContextGlobalGoalDetail";

import ContextGlobalCompanySetting from "./screen/AM/CompanySetting/Context/ContextGlobalCompanySetting";

import ContextGlobalAllMA from "./screen/7WD/AllMA/context/ContextAllMA";

import ContextGlobalDrive from "./screen/Drive/context/ContextGlobalDrive";

import ContextGlobalReports from "./screen/Report/context/ContextGlobalReports";

import { ContextGlobalMeeting } from "./screen/Meeting/context/ContextMeeting";

/*
    ````````````````````````
    ACCOUNT MANAGEMENT/ AM
  
    ````````````````````````
*/
// import ViewSignin from './screen/AM/Signin/ViewSignin';//* Move to Lazy Component
import ViewRegister from "./screen/AM/Signup/ViewRegister";
import ViewOTP from "./screen/AM/Signup/ViewOTP";
import ViewNewWelcome from "./screen/AM/Signup/ViewNewWelcome";
import ViewNewWelcomeVerify from "./screen/AM/Signup/ViewNewWelcomeVerify";

import ViewMembershipStatus from "./screen/AM/MembershipManagement/ViewMembershipStatus";
import ViewMembershipStatus_scenarioIncreaseQuota from "./screen/AM/MembershipManagement/ViewMembershipStatus_scenarioIncreaseQuota";
import ViewMembershipStatus_scenarioPending from "./screen/AM/MembershipManagement/ViewMembershipStatus_scenarioPending";

import ViewMembershipStatusKelola from "./screen/AM/MembershipManagement/ViewMembershipStatusKelola";
import ViewMembershipStatusKelolaPending from "./screen/AM/MembershipManagement/ViewMembershipStatusKelolaPending";
import ViewMembershipStatusDeactivate from "./screen/AM/MembershipManagement/ViewMembershipStatusDeactivate";
import ViewCompletionMembershipOptions from "./screen/AM/MembershipManagement/ViewCompletionMembershipOptions";
import ViewPaymentAfterCountDown from "./screen/AM/MembershipManagement/ViewPaymentAfterCountDown";

import ViewCompletionProfileGroup from "./screen/AM/ProfileCompletion/ViewCompletionProfileGroup";
import ViewCompletionCompanyStructure from "./screen/AM/QuickSetup/ViewCompletionCompanyStructure";
import ViewCompletionCompanyStructureQuestionFirst from "./screen/AM/QuickSetup/ViewCompletionCompanyStructureQuestionFirst";
import ViewCompletionCompanyStructureFormulirKosong from "./screen/AM/QuickSetup/ViewCompletionCompanyStructureFormulirKosong";
import ViewCompletionCompanyStrutureFormBantuSaya from "./screen/AM/QuickSetup/ViewCompletionCompanyStrutureFormBantuSaya";

import ViewMaintenance from "./screen/AM/Dashboard/ViewMaintenance";

import ViewUser from "./screen/AM/UserManagement/ViewUser";
import ViewUserAturKataSandi from "./screen/AM/UserManagement/ViewUserAturKataSandi";

import ViewChangePassword from "./screen/AM/Signin/ViewChangePassword";
import ViewForgetPassword from "./screen/AM/Signin/ViewForgetPassword";

import ViewCompanyProfile from "./screen/AM/CompanyProfile/Profile/ViewCompanyProfile";
import ViewCompanyProfileSetting from "./screen/AM/CompanyProfile/ProfileSetting/ViewCompanyProfileSetting";

/*
    ```````````````````
    HUMAN RESOURCE / HR
  
    ```````````````````
*/
import ViewEmpty from "./screen/HR/Empty/ViewEmpty";
import ViewOrganigram from "./screen/HR/Organigram/ViewOrganigram";
import ViewIdentitas from "./screen/HR/Identitas/ViewIdentitas";
import ViewIdentitasAlamat from "./screen/HR/Identitas/ViewIdentitasAlamat";
import ViewIdentitasInfoTambahan from "./screen/HR/Identitas/ViewIdentitasInfoTambahan";

import ViewKeluarga from "./screen/HR/Keluarga/ViewKeluarga";
import ViewInfoPegawai from "./screen/HR/Kepegawaian/ViewInfoPegawai";
import ViewInfoPosisi from "./screen/HR/Kepegawaian/ViewInfoPosisi";
import ViewKeahlian from "./screen/HR/Keahlian/ViewKeahlian";
import ViewRiwayat from "./screen/HR/Riwayat/ViewRiwayat";
import ViewRiwayatPendidikanFormal from "./screen/HR/Riwayat/ViewRiwayatPendidikanFormal";
import ViewRiwayatPendidikanINFORMAL from "./screen/HR/Riwayat/ViewRiwayatPendidikanINFORMAL";
import ViewRiwayatOrganisasi from "./screen/HR/Riwayat/ViewRiwayatOrganisasi";
import ViewRiwayatPengalamanKerja from "./screen/HR/Riwayat/ViewRiwayatPengalamanKerja";

import ViewUrgentContact from "./screen/HR/KontakDarurat/ViewUrgentContact";

import ViewProfileDetail from "./screen/HR/ProfileEmployee/ViewProfileDetail";
import ViewProfileDetailSeeDetail from "./screen/HR/ProfileEmployeeSeeDetail/ViewProfileDetail";

/*
    ````````````````````````````````````````````````
    HUMAN RESOURCE - STRUCTURE ORGANIZATION / HR-SO
  
    `````````````````````````````````````````````````
*/
import ViewNewSO from "./screen/SO/ViewNewSO";
import ViewOrgChart from "./screen/SO/ViewOrgChart";
import ViewNewSOTreeview from "./screen/SO/ViewNewSOTreeview";

/*
    ````````````````````````````````````````````````
    7WD
  
    `````````````````````````````````````````````````
*/
import ViewPeriode from "./screen/7WD/Periode/ViewPeriode";

/*
    ````
    GOAL
  
    ````
*/

import ViewGoal from "./screen/7WD/Goal/ViewGoal";
import ViewGoalMoreDetail from "./screen/7WD/Goal/ViewGoalMoreDetail";
// import ViewHome from './screen/7WD/Home/ViewHome'; //*Move to lazy()
import ViewNewDashboard from "./screen/AM/Dashboard/ViewNewDashboard";

/*
``````````````````
MEASURED ACTIVITY

`````````````````
*/
import ViewMAMoreDetail from "./screen/7WD/MeasureActivities/ViewMAMoreDetail";
/*
    ``````````````````
    ALL MEASURED ACTIVITY
  
    `````````````````
*/
import ViewAllMA from "./screen/7WD/AllMA/ViewAllMA";
import ViewAllMAMoreDetail from "./screen/7WD/AllMA/ViewAllMAMoreDetail";

/*
    ```````
    DRIVE
  
    ```````
*/
import ViewDrive from "./screen/Drive/ViewDrive";

/*
    ```````
    Reports
  
    ```````
*/
import ViewReports from "./screen/Report/ViewReports";

/*
    ```````
    Regular Meeting
  
    ```````
*/
import ViewMeeting from "./screen/Meeting/ViewMeeting";

/*
    ```````
    SPECIAL
  
    ```````
*/

import ViewTask from "./screen/SPECIAL/ViewTask";

// import NotFoundPage from './screen/Explore/NotFoundPage';

import ViewBasicReactAndD3 from "./screen/Explore/ViewBasicReactAndD3";
// import ViewLandingPage from './screen/Home/ViewLandingPage'; //*Move to Lazy Component

import {
  ToLogin,
  ToDashboard,
  ToRegister,
  ToOTP,
  ToNewWelcome,
  ToNewWelcomeVerify,
  ToMembershipStatus,
  ToCompletionMemberhipOptions,
  ToMembershipStatusDeactivate,
  ToCompletionProfile,
  ToCompletionCompanyStructureQuestionONE,
  ToCompletionCompanyStructureFormulirKosong,
  ToCompletionCompanyStructureQuestionTHIRD,
  ToCompletionCompanyStructureFormBantuSaya,
  ToCompletionCompanyStructure,
  ToStructureOrganization,
  ToMembershipStatusIncreaseQuotaSEMENTARA,
  ToMembershipStatusManageSEMENTARA,
  ToMembershipPaymentStatusAfterCountDown,
  ToRole,
  ToUserManagement,
  ToUserManagementManagePassword,
  ToCompanyProfile,
  ToCompanyProfileSetting,
  ToCompanySetting,
  ToMembershipStatusScenarioPENDING_SEMENTARA,
  ToMembershipStatusManagePENDING_SEMENTARA,
  ToFormUnit,
  ToRoleDetail,
  ToEmptyStateGeneral,
  ToOrganigram,
  ToPreForgetPassword,
  ToForgotPassword,
  ToHrEmployeeIdentitas,
  ToHrEmployeeIdentitasAlamat,
  ToHrEmployeeIdentitasInfoTambahan,
  ToHrEmployeeKeluarga,
  ToHrEmployeeKepegawaianInfoPegawai,
  ToHrEmployeeKepegawaianInfoPosisi,
  ToHrEmployeeKeahlian,
  ToHrEmployeeRiwayat,
  ToHrEmployeeRiwayatPendidikanFormal,
  ToHrEmployeeRiwayatPendidikanINFORMAL,
  ToHrEmployeeRiwayatOrganisasi,
  ToHrEmployeeRiwayatPengalamanKerja,
  ToHrEmployeeRiwayatUrgentContact,
  ToHrEmployeeProfileDetail,
  ToHrEmployeeProfileDetailSeeDetail,
  ToMaintenance,

  /*
      HR-SO
  */
  ToSOTable,
  ToSOMaster,
  ToNewSO,
  ToNewSoTreeViewChartPreview,
  ToNewSoTreeViewOrgChart,

  /*
    `````
      7WD
    `````
  */
  To7wdPeriode,
  ToGoal,
  ToGoalDetail,
  ToSubGoalDetail,
  ToGoalDetailTabComplete,
  ToGoalDetailALL,
  ToMeasuredActivity,
  ToMeasuredActivityDetail,
  ToMeasuredActivityAll,
  ToTask,
  ToLandingPage,
  ToAllMA,
  ToAllMADetail,
  ToDrive,
  ToReports,
  ToMeeting,
} from "./constants/config-redirect-url";

//*LAZY LOAD
// const ViewHome =  lazy(() => import('./screen/7WD/Home/ViewHome'))
// const ViewLandingPage = lazy(() => import("./screen/Home/ViewLandingPage"));
// const ViewSignin = lazy(() => import("./screen/AM/Signin/ViewSignin"));

import ViewLandingPage from "./screen/Home/ViewLandingPage";
import ViewSignin from "./screen/AM/Signin/ViewSignin";
import ViewCompanySetting from "./screen/AM/CompanySetting/ViewCompanySetting";

export const routes = {
  [ToLandingPage]: () => (
    <Fragment>
      <ViewLandingPage />
    </Fragment>
  ),

  "/explore": () => <ViewBasicReactAndD3 />,

  [ToLogin]: () => (
    <Fragment>
      <Header />
      <ViewSignin />
    </Fragment>
  ),

  [ToRegister]: () => (
    <Fragment>
      <Header />
      <ViewRegister />
    </Fragment>
  ),

  [ToPreForgetPassword]: () => <ViewForgetPassword />,
  [ToForgotPassword]: () => <ViewChangePassword />,

  [ToOTP]: () => (
    <Fragment>
      <Header />
      <ViewOTP />
    </Fragment>
  ),

  [ToNewWelcome]: () => (
    <Fragment>
      <Header />
      <ViewNewWelcome />
    </Fragment>
  ),

  [ToNewWelcomeVerify]: () => (
    <Fragment>
      <Header />
      <ViewNewWelcomeVerify />
    </Fragment>
  ),

  [ToDashboard]: () => (
    <Fragment>
      <HeaderGOAL />
      {/* <ViewHome /> */}
      <ViewNewDashboard />
    </Fragment>
  ),

  [ToMaintenance]: () => (
    <div>
      <HeadNewGeneral />
      <ViewMaintenance />
    </div>
  ),

  [ToMembershipStatus]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewMembershipStatus />
    </div>
  ),

  [ToMembershipStatusDeactivate]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewMembershipStatusDeactivate />
    </div>
  ),

  [ToMembershipPaymentStatusAfterCountDown]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewPaymentAfterCountDown />
    </div>
  ),

  [ToMembershipStatusIncreaseQuotaSEMENTARA]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewMembershipStatus_scenarioIncreaseQuota />
    </div>
  ),

  [ToMembershipStatusScenarioPENDING_SEMENTARA]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewMembershipStatus_scenarioPending />
    </div>
  ),

  [ToMembershipStatusManageSEMENTARA]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewMembershipStatusKelola />
    </div>
  ),

  [ToMembershipStatusManagePENDING_SEMENTARA]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewMembershipStatusKelolaPending />
    </div>
  ),

  [ToCompletionMemberhipOptions]: () => (
    <div>
      <HeaderMembershipOptions />
      <ViewCompletionMembershipOptions />
    </div>
  ),

  [ToCompletionProfile]: () => (
    <div>
      <HeaderMembershipOptions />
      <ViewCompletionProfileGroup />
    </div>
  ),

  [ToCompletionCompanyStructureQuestionONE]: () => (
    <div>
      <HeaderMembershipOptions />
      <ViewCompletionCompanyStructureQuestionFirst />
    </div>
  ),

  [ToCompletionCompanyStructure]: () => (
    <div>
      <HeaderMembershipOptions />
      <ViewCompletionCompanyStructure />
    </div>
  ),

  [ToCompletionCompanyStructureFormulirKosong]: () => (
    <div>
      <HeaderMembershipOptions />
      <ViewCompletionCompanyStructureFormulirKosong />
    </div>
  ),

  [ToCompletionCompanyStructureFormBantuSaya]: () => (
    <div>
      <HeaderMembershipOptions />
      <ViewCompletionCompanyStrutureFormBantuSaya />
    </div>
  ),

  [ToUserManagement]: () => (
    <div>
      <HeaderGOAL />
      <ViewUser />
    </div>
  ),

  [ToUserManagementManagePassword]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewUserAturKataSandi />
    </div>
  ),

  [ToCompanyProfile]: () => (
    <div>
      <HeaderCompanyProfile />
      <ViewCompanyProfile />
    </div>
  ),

  [ToCompanyProfileSetting]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewCompanyProfileSetting />
    </div>
  ),

  [ToCompanySetting]: () => (
    <div>
      <ContextGlobalCompanySetting>
        <HeaderGOAL />
        <ViewCompanySetting />
      </ContextGlobalCompanySetting>
    </div>
  ),

  [ToEmptyStateGeneral]: () => (
    <div>
      <HeaderGOAL />
      <ViewEmpty />
    </div>
  ),

  [ToHrEmployeeIdentitas]: () => (
    <div>
      <HeaderHREmployee />
      <ViewIdentitas />
    </div>
  ),

  [ToHrEmployeeIdentitasAlamat]: () => (
    <div>
      <HeaderHREmployee />
      <ViewIdentitasAlamat />
    </div>
  ),

  [ToHrEmployeeIdentitasInfoTambahan]: () => (
    <div>
      <HeaderHREmployee />
      <ViewIdentitasInfoTambahan />
    </div>
  ),

  [ToHrEmployeeKeluarga]: () => (
    <div>
      <HeaderHREmployeeKeluarga />
      <ViewKeluarga />
    </div>
  ),

  [ToHrEmployeeKepegawaianInfoPegawai]: () => (
    <div>
      <HeaderHREmployeeKepegawaian />
      <ViewInfoPegawai />
    </div>
  ),

  [ToHrEmployeeKepegawaianInfoPosisi]: () => (
    <div>
      <HeaderHREmployeeKepegawaian />
      <ViewInfoPosisi />
    </div>
  ),

  [ToHrEmployeeKeahlian]: () => (
    <div>
      <HeaderHREmployeeKeahlian />
      <ViewKeahlian />
    </div>
  ),

  [ToHrEmployeeRiwayat]: () => (
    <div>
      <HeaderHREmployeeRiwayat />
      <ViewRiwayat />
    </div>
  ),

  [ToHrEmployeeRiwayatPendidikanFormal]: () => (
    <div>
      <HeaderHREmployeeRiwayat />
      <ViewRiwayatPendidikanFormal />
    </div>
  ),

  [ToHrEmployeeRiwayatPendidikanINFORMAL]: () => (
    <div>
      <HeaderHREmployeeRiwayat />
      <ViewRiwayatPendidikanINFORMAL />
    </div>
  ),

  [ToHrEmployeeRiwayatOrganisasi]: () => (
    <div>
      <HeaderHREmployeeRiwayat />
      <ViewRiwayatOrganisasi />
    </div>
  ),

  [ToHrEmployeeRiwayatPengalamanKerja]: () => (
    <div>
      <HeaderHREmployeeRiwayat />
      <ViewRiwayatPengalamanKerja />
    </div>
  ),

  [ToHrEmployeeRiwayatUrgentContact]: () => (
    <div>
      <HeaderHREmployeeUrgentContact />
      <ViewUrgentContact />
    </div>
  ),

  [ToHrEmployeeProfileDetail]: () => (
    <div>
      <HeaderProfileDetail />
      <ViewProfileDetail />
    </div>
  ),

  [ToHrEmployeeProfileDetailSeeDetail]: () => (
    <div>
      <HeaderProfileDetail />
      <ViewProfileDetailSeeDetail />
    </div>
  ),

  /*
      ``````````````
      ORGANIGRAM/ SO

      ``````````````
  */
  [ToNewSoTreeViewChartPreview]: () => <ViewNewSOTreeview />,

  [ToNewSoTreeViewOrgChart]: () => (
    <ContextGlobalNewSO>
      <ViewOrgChart />
    </ContextGlobalNewSO>
  ),

  [ToOrganigram]: () => (
    <div>
      <HeadNewGeneral />
      <ViewOrganigram />
    </div>
  ),

  [ToNewSO]: () => (
    <div>
      <ViewNewSO />
    </div>
  ),

  [To7wdPeriode]: () => (
    <div>
      <HeaderGOAL />
      <ViewPeriode />
    </div>
  ),

  [ToGoal]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalGoal>
        <ViewGoal />
      </ContextGlobalGoal>
    </div>
  ),

  [ToGoalDetail]: () => (
    // "/goal-detail/:id" : ({id}) => (

    <div>
      <HeaderGOAL />
      <ContextGlobalGoalDetail>
        <ViewGoalMoreDetail />
      </ContextGlobalGoalDetail>
    </div>
  ),

  "/goal-detail/:id": ({ id }) => (
    <div>
      <HeaderGOAL />
      <ContextGlobalGoalDetail>
        <ViewGoalMoreDetail id={id} />
      </ContextGlobalGoalDetail>
    </div>
  ),

  "/sub-goal-detail/:id": ({ id }) => (
    <div>
      <HeaderGOAL />
      <ContextGlobalGoalDetail>
        <ViewGoalMoreDetail id={id} />
      </ContextGlobalGoalDetail>
    </div>
  ),

  "/ma*": () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalGoalDetail>
        <ViewGoalMoreDetail />
      </ContextGlobalGoalDetail>
    </div>
  ),

  "/ma/:id": ({ id }) => (
    <div>
      <HeaderGOAL />
      <ContextGlobalGoalDetail>
        <ViewGoalMoreDetail id={id} />
      </ContextGlobalGoalDetail>
    </div>
  ),

  [ToSubGoalDetail]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalGoalDetail>
        <ViewGoalMoreDetail />
      </ContextGlobalGoalDetail>
    </div>
  ),

  [ToMeasuredActivityDetail]: () => (
    //*Tidak di pakai

    <div>
      <HeaderGOAL />
      <ViewMAMoreDetail />
    </div>
  ),

  [ToAllMA]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalAllMA>
        <ViewAllMA />
      </ContextGlobalAllMA>
    </div>
  ),

  [ToAllMADetail]: () => (
    // "/allma-detail/:id" : ({id}) => (

    <div>
      <HeaderGOAL />
      <ContextGlobalAllMA>
        <ViewAllMAMoreDetail />
        {/* <ViewMAMoreDetail /> */}
      </ContextGlobalAllMA>
    </div>
  ),

  "/allma-detail/:id": ({ id }) => (
    <div>
      <HeaderGOAL />
      <ContextGlobalAllMA>
        <ViewAllMAMoreDetail id={id} />
        {/* <ViewMAMoreDetail id={id} /> */}
      </ContextGlobalAllMA>
    </div>
  ),

  [ToReports]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalReports>
        <ViewReports />
      </ContextGlobalReports>
    </div>
  ),

  [ToDrive]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalDrive>
        <ViewDrive />
      </ContextGlobalDrive>
    </div>
  ),

  [ToMeeting]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalMeeting>
        <ViewMeeting />
      </ContextGlobalMeeting>
    </div>
  ),

  "/drive/:folder": ({ folder }) => (
    <div>
      <HeaderGOAL />
      <ContextGlobalDrive>
        <ViewDrive folder={folder} />
      </ContextGlobalDrive>
    </div>
  ),

  [ToTask]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalTask>
        <ViewTask />
      </ContextGlobalTask>
    </div>
  ),
};

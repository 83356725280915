import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import { lightGreen, yellow, red } from "@material-ui/core/colors";

import ProgressBar from "react-bootstrap/ProgressBar";

const numeral = require("numeral");

const ProgressBarItem = (props) => {
  const { item, index } = props;

  return (
    <Box
      marginTop={theme.spacing(0.2)}
      marginRight={theme.spacing(0.2)}
      key={index}>
      <ProgressBar max={100} min={0} style={{ height: "0.8rem" }}>
        {numeral(item.overall_result_achievement * 100).format("0.00") > 0 &&
          numeral(item.overall_result_achievement * 100).format("0.00") <
            85 && (
            <ProgressBar
              style={{
                background: red[800],
              }}
              now={`${numeral(item.overall_result_achievement * 100).format(
                "0.00"
              )}`}
              key={1}
            />
          )}

        {numeral(item.overall_result_achievement * 100).format("0.00") >= 85 &&
          numeral(item.overall_result_achievement * 100).format("0.00") <
            100 && (
            <ProgressBar
              style={{
                background: yellow[300],
              }}
              now={`${numeral(item.overall_result_achievement * 100).format(
                "0.00"
              )}`}
              key={1}
            />
          )}

        {numeral(item.overall_result_achievement * 100).format("0.00") >=
          100 && (
          <ProgressBar
            style={{
              background: lightGreen["A400"],
            }}
            now={`${numeral(item.overall_result_achievement * 100).format(
              "0.00"
            )}`}
            key={1}
          />
        )}
      </ProgressBar>
    </Box>
  );
};

export default ProgressBarItem;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

import React, { useEffect, useState, useContext, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, Grid, CircularProgress } from "@material-ui/core";

import ListCardItemMeasureActivity from "../../../MeasureActivities/Components/ListCardItemMeasureActivity";
import ContextGoalDetail from "../../../Goal/Context/ContextGoalDetail";
import PictEmptyStateMA from "../../Components/PictEmptyStateMA";

import { STATUS_ACTIVE } from "../../../../../constants/config-status-goal";

import { styleSpecial } from "../../Style/StyleMaSPECIAL";

const useStyles = makeStyles(styleSpecial);

const numeral = require("numeral");

const ListStatusMAActive = (props) => {
  const context = useContext(ContextGoalDetail);
  const classes = useStyles();

  /*  
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */

  const [list, setList] = useState([]);

  useEffect(() => {
    let finding = context.collectionMA.filter(function (element) {
      if (element.status.code == STATUS_ACTIVE) {
        return element;
      }
    });

    if (finding !== undefined) {
      if (finding !== null) {
        if (finding.length > 0) {
          if (finding[0] !== []) {
            setList(finding);
          }
        }
      }

      if (context.collectionMA.length == 0) {
        setList([]);
      }
    }
  }, [context.collectionMA]);

  /*  
        ``````````````````
        HANDLE GOAL DETAIL

        ``````````````````
    */
  const handleDetail = (e, data) => {
    e.preventDefault();
    console.log(data);

    localStorage.setItem("MA_detail", JSON.stringify(data));
    context.setMeasuredActivityDetailShow(false);
    // navigate('/ma-detail')
    // Redirect(ToGoalDetail);
  };

  const [isHideListGoal, setHideListGoal] = useState(false);

  return (
    <Fragment>
      {context.loaderSpecialMa == true && (
        <Grid
          container
          spacing={0}
          direction="row"
          justify="center"
          alignItems="center">
          <CircularProgress size={32} style={{ marginTop: 72, color: "red" }} />
        </Grid>
      )}

      {list.length === 0 && context.loaderSpecialMa == false && (
        <Paper elevation={1} className={classes.paperList}>
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey", marginTop: 16, marginBottom: 16 }}>
                <PictEmptyStateMA />
                <b>
                  Tidak ada <i>Measured Activity</i> yang dapat di tampilkan
                  saat ini ;(
                </b>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}

      {list.length > 0 &&
        list.map((item, i) => {
          return (
            <ListCardItemMeasureActivity
              key={i}
              // classes = { classes }
              item={item}
              handleDetail={handleDetail}
              userTokenState={context.userTokenStateGlobal}
              // lengthGoalCount = { lengthGoalCount }
            />
          );
        })}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Fragment>
  );
};

export default ListStatusMAActive;

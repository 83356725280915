import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PersonIcon from "@material-ui/icons/Person";

const ButtonIconPositionTitle = ({ classes, positionTitleName, ownerList }) => (
  <>
    {ownerList.length === 0 && (
      <Button
        variant="outlined"
        size="small"
        className={classes.timeFrameIconInModal}
        style={{ marginTop: 24 }}>
        <IconButton style={{ background: "#edcfd8" }}>
          <PersonIcon color="#fff" style={{ fontSize: 14 }} />
        </IconButton>
        &nbsp;
        <b>Belum ada posisi yang di tentukan untuk membuat Goal ;(</b>
      </Button>
    )}
    {ownerList.length > 0 && (
      <Button
        variant="outlined"
        size="small"
        className={classes.timeFrameIconInModal}
        style={{ marginTop: 24 }}
        disabled>
        <IconButton style={{ background: "#edcfd8" }}>
          <PersonIcon color="#fff" style={{ fontSize: 14 }} />
        </IconButton>
        &nbsp;
        <b>{positionTitleName}</b>
      </Button>
    )}
  </>
);

export default ButtonIconPositionTitle;

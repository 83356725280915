import React, { Fragment, Suspense } from "react";
import { Typography, CircularProgress } from "@material-ui/core";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
  fade,
  useTheme,
  ThemeProvider,
} from "@material-ui/core/styles";

import { useRoutes, A, navigate } from "hookrouter";
import { styles } from "./Style/LandingPage";

import Nav from "./ComponentViewLandingPage/Nav";
import Header from "./ComponentViewLandingPage/Header";
import Main from "./ComponentViewLandingPage/Main";
import About from "./ComponentViewLandingPage/About";
import Price from "./ComponentViewLandingPage/Price";
import Footer from "./ComponentViewLandingPage/Footer";
import Contact from "./ComponentViewLandingPage/Contact";
import TermCondition from "./ComponentViewLandingPage/TermCondition";
import PrivacyPolicy from "./ComponentViewLandingPage/PrivacyPolicy";

//*
import GoalManagement from "./ComponentViewLandingPage/GoalManagement";
import MeasuredActivity from "./ComponentViewLandingPage/MeasuredActivity";
import Scoreboard from "./ComponentViewLandingPage/Scoreboard";
import DemoSchedule from "./ComponentViewLandingPage/DemoSchedule";

import "./Style/landing.css";

//*
const routeNavLandingPage = {
  //*Nested Home
  "/": () => (
    <Fragment>
      <Header />
      <Main />
    </Fragment>
  ),
  "/price": () => <Price />,
  "/contact": () => <Contact />,
  "/about": () => <About />,
  "/term-conditions": () => <TermCondition />,
  "/privacy-policy": () => <PrivacyPolicy />,

  //*
  "/demo-schedule": () => <DemoSchedule />,
  "/feature/goal-management": () => <GoalManagement />,
  "/feature/measured-activity": () => <MeasuredActivity />,
  "/feature/scoreboard": () => <Scoreboard />,
};

const ViewLandingPage = (props) => {
  const routeResultNavLandingPage = useRoutes(routeNavLandingPage);

  return (
    <div className="container">
      <Nav />
      {routeResultNavLandingPage}
      <Footer />
    </div>
  );
};

export default ViewLandingPage;

import React, { useState, useEffect, Fragment } from "react";
import {
  Typography,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { styles } from "../Style/StyleSubGoal";

import Snackbeer from "../../../../components/Snackbeer";
import IconWarningYellow from "../../../../assets/images/icon_warning_yellow.png";
import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

const useStyles = makeStyles(styles);

const DialogRemoveDirectLink = (props) => {
  //  const context = useContext(ContextGoalDetail);

  const {
    isOpenDialogRemoveDirectLink,
    setOpenDialogRemoveDirectLink,
    goalId,
    subGoalId,
    // listSubGoalId,
    // setSelected,
  } = props;

  const classes = useStyles();

  const [userTokenState, setUserTokenState] = useState(false);

  useEffect(() => {
    if (isOpenDialogRemoveDirectLink === true) {
      const userToken = localStorage.getItem("userToken");
      setUserTokenState(userToken);

      // context.setSuccessDeleteSubGoal(false);

      // console.log("Goal ID : ", goalId);
      // console.log("Sub GOAL ID : ", subGoalId);
      // console.log("listSubGoalId : ", listSubGoalId)
    }
  }, [isOpenDialogRemoveDirectLink]);

  /*
        ``````````````````````
        HANDLE DELETE SUB GOAL

        ``````````````````````
    */

  const [loader, setLoader] = useState(false);
  const handleDeleteSubGoal = () => {
    setLoader(true);

    axiosConfig
      .delete(URL_API + `/swd/goal/${goalId}/direct-link/${subGoalId}:remove`)
      .then(function (response) {
        console.log("Response Original REMOVE DIRECT LINK : ", response);
        setModalResponse200(true);

        setTimeout(() => {
          // navigate(ToGoal);
          window.location.reload();
        }, 3000);

        setLoader(false);
        setOpenDialogRemoveDirectLink(false);
      })
      .catch(function (error) {
        setLoader(false);
        console.log("Error : ", error.response);
      });
  };

  /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */
  const [isModalResponse200, setModalResponse200] = useState(false);

  return (
    <Fragment>
      <Dialog
        open={isOpenDialogRemoveDirectLink}
        onClose={() => setOpenDialogRemoveDirectLink(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <img
            src={IconWarningYellow}
            alt="info-icon-pict-info"
            style={{ width: 48, height: 40 }}
          />
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}>
            <b>
              Apakah Anda yakin ingin menghapus <i>Goal</i> ini dari{" "}
              <i>Direct Link</i> ?
            </b>
          </Typography>
        </DialogContent>

        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Box marginLeft={4}>
            <Button
              onClick={handleDeleteSubGoal}
              // onClick={() => setOpenDialogRemoveDirectLink(false)}
              variant="outline"
              className={classes.buttonOutlined}
              // fullWidth
            >
              {loader !== true ? (
                "Hapus"
              ) : (
                <CircularProgress size={16} style={{ color: "red" }} />
              )}
            </Button>

            <Button
              size="small"
              // onClick={handleDelete}
              onClick={() => setOpenDialogRemoveDirectLink(false)}
              variant="contained"
              className={classes.buttonModalDelete}
              // fullWidth
            >
              Batal
            </Button>
          </Box>
        </DialogActions>
        <br />
      </Dialog>

      <Snackbeer
        classes={classes}
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages={`Sub-Goal ini sudah tidak terhubung dengan main Goal !`}
      />
    </Fragment>
  );
};

export default DialogRemoveDirectLink;

import React from "react";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { styles } from "../../../../style/styleGrouping";

import PresetFilter from "./preset/PresetFilter";
import EntitiesFilter from "./entities/EntitiesFilter";
import UserFilter from "./user/UserFilter";
import StatusFilter from "./status/StatusFilter";
import AchievementFilter from "./achievement/AchievementFilter";
import UnitFilter from "./unit/UnitFilter";
// import GroupBy from "./group-by/GroupBy";
import OrderBy from "./order-by/OrderBy";

const useStyles = makeStyles(styles);

const Grouping = () => {
  const classes = useStyles();

  return (
    <Box display="flex">
      <Box marginRight={1.5}>
        <PresetFilter classes={classes} />
      </Box>
      <Box marginRight={1.5}>
        <EntitiesFilter classes={classes} />
      </Box>
      <Box marginRight={1.5}>
        <UnitFilter classes={classes} />
      </Box>
      <Box marginRight={1.5}>
        <UserFilter classes={classes} />
      </Box>
      <Box marginRight={1.5}>
        <StatusFilter classes={classes} />
      </Box>
      <Box marginRight={1.5}>
        <AchievementFilter classes={classes} />
      </Box>

      <Box marginLeft={5}>
        {/* <GroupBy classes={classes} /> */}
        <OrderBy classes={classes} />
      </Box>
    </Box>
  );
};

export default Grouping;

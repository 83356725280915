import React, { useEffect, useState, useContext, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemIcon,
  Slide,
  // Link
} from "@material-ui/core";

import moment from "moment";

import DoneIcon from "@material-ui/icons/Done";

import ContextGoalDetail from "../../../Goal/Context/ContextGoalDetail";

import PictEmptyStateMA from "../../Components/PictEmptyStateMA";
import Circle from "../../Components/Circle";

import LightTooltip from "../../../../../components/LightTooltip";
// import Circle from '../../../../../components/Circle';

import TruncateText from "../../../../../utilities/TruncateText";
import TruncateTextShort from "../../../../../utilities/TruncateTextShort";

import Redirect from "../../../../../utilities/Redirect";
import { ToGoalDetail } from "../../../../../constants/config-redirect-url";
import { STATUS_DRAFT } from "../../../../../constants/config-status-goal";

import { styleSpecial } from "../../Style/StyleMaSPECIAL";

const useStyles = makeStyles(styleSpecial);

const numeral = require("numeral");

const ListStatusGoalDraft = (props) => {
  const context = useContext(ContextGoalDetail);
  const classes = useStyles();

  /*  
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */
  const [list, setList] = useState([]);

  useEffect(() => {
    let finding = context.collectionMA.filter(function (element) {
      if (element.status.code == STATUS_DRAFT) {
        return element;
      }
    });

    if (finding !== undefined) {
      if (finding !== null) {
        if (finding.length > 0) {
          if (finding[0] !== []) {
            setList(finding);
          }
        }
      }
    }
  }, []);

  /*  
        ``````````````````
        HANDLE GOAL DETAIL

        ``````````````````
    */
  const handleDetail = (e, data) => {
    e.preventDefault();
    console.log(data);

    localStorage.setItem("goal_detail", JSON.stringify(data));
    Redirect(ToGoalDetail);
  };

  return (
    <Fragment>
      {/* <Grid container style={{backgroundColor: '#f4f7fc'}}>
                    <Grid item xs={12} md={12} > */}

      {context.loader == true && (
        <Grid
          container
          spacing={0}
          direction="row"
          justify="center"
          alignItems="center">
          <CircularProgress size={32} style={{ marginTop: 72, color: "red" }} />
        </Grid>
      )}

      {
        // find == undefined && collectionMA.length == 0 && loader == false && (
        list.length == 0 && context.loader == false && (
          <Paper
            elevation={1}
            className={classes.paperList}
            style={{ marginTop: 8 }}>
            <Grid container>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography
                  variant="subtitle1"
                  className={classes.title}
                  style={{ color: "grey", marginTop: 16, marginBottom: 16 }}>
                  <PictEmptyStateMA />
                  <b>
                    Tidak ada <i>Draft Measured Activity </i> yang dapat di
                    tampilkan saat ini ;(
                  </b>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        )
      }

      {list.length > 0 &&
        list.map((item, i) => {
          // let structureUnitTypeName = item.owner !== null ? item.owner.structure_unit_type_name : '-';
          // let structureUnitName = item.owner !== null ? item.owner.structure_unit_name : '-'

          return (
            <Slide direction="up" in={true} mountOnEnter unmountOnExit key={i}>
              <Paper
                elevation={1}
                className={
                  item.calculatedValue.gap_value_percent >
                    item.calculatedValue.result_value_percent ||
                  item.calculatedValue.expected_value_percent >
                    item.calculatedValue.result_value_percent
                    ? classes.paperListKebakaran
                    : classes.paperList
                }
                style={{ marginBottom: 16 }}>
                <Grid container alignItems="flex-end">
                  <div className={classes.circularProgressBar}>
                    <Circle classes={classes} item={item} index={i} />
                  </div>

                  <List
                    className={classes.layoutMarginLeftList}
                    style={{ width: 232 }}>
                    <ListItem style={{ paddingLeft: 0, marginRight: 24 }}>
                      <ListItemText
                        className={classes.listItemtext}
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}>
                            <b>{item.name}</b>
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}>
                            {
                              // *DATE RANGE
                              item.start_date !== null &&
                                item.end_date !== null &&
                                item.period == null && (
                                  <LightTooltip
                                    title={
                                      moment(item.start_date).format(
                                        "DD MMMM YYYY"
                                      ) +
                                      " - " +
                                      moment(item.end_date).format(
                                        "DD MMMM YYYY"
                                      )
                                    }
                                    placement="right-start">
                                    <span>
                                      {TruncateTextShort(
                                        moment(item.start_date).format(
                                          "DD MMMM YYYY"
                                        ) +
                                          " - " +
                                          moment(item.end_date).format(
                                            "DD MMMM YYYY"
                                          )
                                      )}
                                    </span>
                                  </LightTooltip>
                                )
                            }
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>

                  <List style={{ width: 318 }}>
                    <ListItem
                      style={{
                        marginRight: 24,
                        paddingLeft: 0,
                        // borderLeft: '0.1em solid #c3c0c0de',
                      }}>
                      <ListItemIcon
                        style={{
                          marginLeft: 8,
                        }}>
                        <IconButton
                          style={{
                            backgroundColor: "#dbdbdb",
                            padding: "7px",
                          }}>
                          <i
                            className="material-icons"
                            style={{ color: "#c4c5c4" }}>
                            person
                          </i>
                        </IconButton>
                      </ListItemIcon>

                      <ListItemText
                        className={classes.listItemtext}
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}>
                            <b>
                              {item.member !== null
                                ? item.member.first_name + " "
                                : item.owner.member_first_name}
                              {item.member !== null
                                ? item.member.last_name
                                : item.owner.member_last_name}
                            </b>
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}>
                            {item.owner
                              ? item.owner.structure_position_title_name
                              : "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>

                  <List>
                    <ListItem
                      style={{
                        marginRight: 24,
                        paddingLeft: 0,
                      }}>
                      <ListItemIcon style={{ marginLeft: 8 }}>
                        <IconButton
                          style={{
                            backgroundColor: "#bdffde",
                            padding: "8px",
                          }}>
                          <DoneIcon
                            style={{ color: "#36d686", fontSize: 21 }}
                          />
                        </IconButton>
                      </ListItemIcon>

                      <ListItemText
                        className={classes.listItemtext}
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}>
                            <b>Posisi aman</b>
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}>
                            {numeral(
                              item.calculatedValue.expected_value_percent
                            ).format("0.00")}
                            %
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>

                  {/* <List>
                                                        <ListItem 
                                                            style={{
                                                                marginRight: 24, 
                                                                paddingLeft: 0
                                                            }}
                                                        >
                        
                                                            <ListItemIcon style={{marginLeft: 8}}>
                                                                <IconButton style={{backgroundColor: 'rgb(255, 222, 172)', padding: '11px'}}>
                                                                    <i className="material-icons" style={{color: 'orange', fontSize: 14}}>
                                                                        calendar_today
                                                                    </i>
                                                                </IconButton>
                                                            </ListItemIcon>
                                                            
                                                            <ListItemText 
                                                                className={classes.listItemtext}
                                                                primary = {
                                                                    <Typography 
                                                                        variant='subtitle2' 
                                                                        className={classes.title}
                                                                    >
                                                                        <b>Sisa waktu</b>
                                                                    </Typography>
                                                                } 
                        
                                                                secondary={
                                                                    <Typography variant='subtitle2' className={classes.title}>
                                                                        {item.time.remaining_days} hari
                                                                    </Typography>
                                                                } 
                                                            />
                                                        </ListItem>
                                                    </List> */}

                  <List className={classes.layoutMarginLeftList}>
                    <ListItem
                      alignItems="center"
                      style={{ paddingLeft: 0, marginRight: 24 }}>
                      <ListItemText
                        noWrap
                        className={classes.listItemtext}
                        primary={
                          <LightTooltip
                            title={`Terakhir update ${moment(
                              item.created_at
                            ).format("DD MMMM YYYY h:mm:ss a")}`}
                            placement="left-start">
                            <Typography
                              variant="subtitle2"
                              className={classes.title}>
                              {TruncateText(
                                `Terakhir update ${moment(
                                  item.created_at
                                ).format("DD MMMM YYYY h:mm:ss a")}`
                              )}
                            </Typography>
                          </LightTooltip>
                        }
                        secondary={
                          <LightTooltip
                            title="Klik di sini ! Untuk melihat lebih detail Goal ini"
                            placement="left-start">
                            <Button
                              onClick={(e) => handleDetail(e, item)}
                              variant="outlined"
                              size="small"
                              className={classes.moreDetail}>
                              <b>More Detail</b>
                            </Button>
                          </LightTooltip>
                        }
                      />
                    </ListItem>

                    {/* 
                                                            <Button 
                                                                onClick={(e) => handleDetailNew(e, item)}                                                                    
                                                                variant='outlined' 
                                                                size='small' 
                                                                className={classes.moreDetail}
                                                            >
                                                                <Link to={`/goal/${item.id}`}> <b>More Detail</b></Link>
                                                            </Button> 
                                                        */}
                  </List>
                </Grid>
              </Paper>
            </Slide>
          );
        })}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {/* </Grid>
                </Grid> */}

      {/* <DialogCreate 
                classes = { classes }
                isModalCreate = { isModalCreate }
                setModalCreate = { setModalCreate }
                fotoQuery = { fotoQuery}
                userLoginName = { userLoginName }
                memberPositionList = { memberPositionList }
                setColletionMA = { setColletionMA}
                collectionMA = {collectionMA }
            /> */}
    </Fragment>
  );
};

export default ListStatusGoalDraft;

import React, { Fragment } from "react";
import {
  Typography,
  Grid,
  TextField,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  AppBar,
} from "@material-ui/core";

import MomentUtils from "@date-io/moment";
import CancelIcon from "@material-ui/icons/Cancel";

import "moment/locale/id";

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const DialogCreateModeWizard = (props) => {
  const { isModalCreateModeWizard, setModalCreateModeWizard, classes } = props;

  return (
    <Fragment>
      <Dialog
        open={isModalCreateModeWizard}
        onClose={() => setModalCreateModeWizard(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
        fullScreen>
        <DialogTitle id="alert-dialog-title">
          <AppBar
            color="primary"
            classes={{
              colorPrimary: classes.colorPrimaryAppbar,
            }}>
            <Grid container>
              <Grid item md={8} style={{ textAlign: "left" }}>
                <Box mt={1} mb={1} ml={2}>
                  <Typography
                    variant="h6"
                    className={classes.title}
                    style={{ color: "black" }}>
                    <b>Membuat Goal Baru</b>
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={4} style={{ textAlign: "right" }}>
                <Box mt={1} mb={1} mr={2}>
                  <IconButton
                    onClick={() => setModalCreateModeWizard(false)}
                    size="small">
                    <CancelIcon color="primary" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </AppBar>
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item md={1}></Grid>

            <Grid item md={5} style={{ textAlign: "left" }}>
              <Box mt={8}>
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: "grey" }}>
                  <b>Langkah 1 dari 7 langkah lagi</b>
                </Typography>
              </Box>

              <Box mt={4}>
                {/* 
                                    ``````````
                                    LANGKAH-01

                                    ``````````
                                */}

                <Typography
                  variant="h5"
                  className={classes.title}
                  style={{ color: "black" }}>
                  <b>Apa yang ingin Anda capai ? </b>
                </Typography>

                <br />
                <TextField
                  // inputRef={textInputReff}
                  id="outlined-bare"
                  className={classes.textField}
                  // onChange= {handleChangeBIGGoal}
                  // value= {bigGoal}
                  placeholder="Masukan Goal besar Anda di sini..."
                  variant="outlined"
                  fullWidth
                />

                <br />
                <Typography
                  variant="caption"
                  className={classes.title}
                  color="primary">
                  Tambahkan deskripsi <i>(Optional)</i>
                </Typography>
              </Box>
            </Grid>

            <Grid item md={6} style={{ textAlign: "left" }}></Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default DialogCreateModeWizard;

import React, { useState } from "react";
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Button,
} from "@material-ui/core";
import { lighten, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";

import DialogDeleteMultiple from "./DialogDeleteMultiple";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: "#d1354a",
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
    fontFamily: "Roboto",
  },
  button: {
    width: "200px",
    fontFamily: "Roboto",
    textTransform: "capitalize",
    color: "#d64253",
    fontSize: 13,
    fontWeight: "600",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#ffcccc",
    },
  },
}));

function EnhancedTableToolbar({
  numSelected,
  itsclasses,
  selected,
  setSelected,
  onShowHideCompleted,
  showhideCompleted,
}) {
  const classes = useToolbarStyles();
  const [openDialogDeleteMultiple, setOpenDialogDeleteMultiple] =
    useState(false);

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}>
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div">
          <b>{numSelected}</b> Ditandai
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div">
          Todo
        </Typography>
      )}
      <Button className={classes.button} onClick={onShowHideCompleted}>
        {!showhideCompleted ? "Hide status completed" : "Show All"}
      </Button>

      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            onClick={() => setOpenDialogDeleteMultiple(true)}>
            <DeleteIcon style={{ color: "#d1354a" }} />
          </IconButton>
        </Tooltip>
      )}

      <DialogDeleteMultiple
        classes={itsclasses}
        selected={selected}
        setSelected={setSelected}
        openDialogDeleteMultiple={openDialogDeleteMultiple}
        setOpenDialogDeleteMultiple={setOpenDialogDeleteMultiple}
      />
    </Toolbar>
  );
}

export default EnhancedTableToolbar;

import React, { useEffect, useState, useContext, Fragment } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  List,
  Avatar,
  IconButton,
  Collapse,
  LinearProgress,
  TablePagination,
  CircularProgress,
  Chip,
} from "@material-ui/core";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

import queryString from "query-string";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextGoalDetail from "../../Goal/Context/ContextGoalDetail";

import LightTooltip from "../../../../components/LightTooltip";

import PictEmptyStateGoalResult from "../ComponentsGoalResult/PictEmptyStateGoalResult";

import { STATUS_COMPLETED } from "../../../../constants/config-status-goal";

import DialogExportGoalResult from "./DialogExportGoalResult";
import TablePaginationCustom from "../Components/TablePaginationCustom";
import ListItemGoalResultList from "./ListItemGoalResultList";
import DialogAddResult from "./DialogAddResult";

import DialogError from "../../../../components/DialogError";

const ListCollectionGoalResult = (props) => {
  const {
    classes,
    collectionGoalResultList,
    goalResultLength,
    goalDetailState,
    userToken,
    handleDropdownOpenGoalResult,
    anchorElListGoalResult,
    handleDropdownCloseGoalResult,
    handleDialogEdit,
    handleDialogDelete,
    handleDialogModalGoalResult,
    onChangePage,
    onChangeRowsPerPage,
    loader,
    page,
    rowsPerPage,
    setFireGoalDetail,
  } = props;

  const context = useContext(ContextGoalDetail);
  const urlParams = window.location.search; // GET Query String
  const query = queryString.parse(urlParams); // Parse Query String Into Object(s)
  // const locationPathGoalId = window.location.pathname.split("/")[2]; // GET Goal ID

  // console.log("Context List Collection Goal Result : ", context)

  const [isStatusCompleted, setStatusCompleted] = useState(false);
  const [policy, setPolicy] = useState([]);
  // GR STATE
  const [openDialogAddResult, setOpenDialogAddResult] = useState(false);
  const [queryState, setQueryState] = useState({
    result: "",
    desc: "",
    open: "",
  });

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  // const [collectionGoalResultList, setCollectionGoalResultList] = useState([]);

  useEffect(() => {
    if (goalDetailState.status.code === STATUS_COMPLETED) {
      setStatusCompleted(true);
    }

    setQueryState({
      result: query.result,
      desc: query.desc,
      open: query.open,
    });

    if (query.open === "1") {
      setOpenDialogAddResult(true);
    }

    setPolicy(goalDetailState.policy);
  }, [goalDetailState]);

  /*
        ```````````````
        HANDLE COLLAPSE

        ```````````````
    */
  const [openCollapse, setOpenCollapse] = useState(true);

  function handleCollapse() {
    setOpenCollapse(!openCollapse);
  }

  /*
        ```````````````````````````
        HANDLE EXPORT GOAL RESULT

        ```````````````````````````
    */
  const [isModalExportGoalResult, setModalExportGoalResult] = useState(false);

  // Handle status code 403 from create Goal Reesult For Disableing Crate Result
  const [disableCreateResult, setDisableCreateResult] = useState(false);

  useEffect(() => {
    if (goalDetailState.id !== null) {
      axiosConfig
        .get(`${URL_API}/swd/goal/${goalDetailState.id}/result/create`)
        .then((response) => {
          // console.log("Response create GR", response);
        })
        .catch((error) => {
          console.log("Error : ", error);

          if (error.response !== undefined) {
            if (error.response.status === 403) {
              setDisableCreateResult(true);
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });
    }
  }, []);

  // console.log("GOL DET ID", goalDetailState.id);

  /* ===============================================================================================
    SETUP ALOWWED BUTTON DEPEND ON ROLE
=============================================================================================== */

  let editAllowed = false;
  let deleteAllowed = false;

  policy.forEach((item) => {
    if (item.actionName === "update") {
      editAllowed = item.result;
    } else if (item.actionName === "delete") {
      deleteAllowed = item.result;
    }
  });

  // console.log("POLISI FROM RESULT LIST", policy);

  return (
    <Fragment>
      <Box display="flex" justifyContent="space-between" pr={1}>
        <Typography variant="h6" className={classes.titleInLineChart}>
          <b>Latest Goal Result</b>
        </Typography>

        <Button
          varian="outlined"
          className={classes.button}
          size="small"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialogAddResult(true)}
          style={{
            marginRight: 10,
            marginTop: 32,
          }}
          size="small"
          disabled={
            isStatusCompleted === true
              ? true
              : goalDetailState.status_id === "2"
              ? true
              : context.listCollectionSubGoal.length > 0
              ? true
              : disableCreateResult
          }>
          Add Result
        </Button>
      </Box>

      <Box style={{ textAlign: "right" }}>
        {context.listCollectionSubGoal.length > 0 && (
          <Button
            variant="outlined"
            className={classes.buttonOutlined}
            size="small"
            style={{
              borderColor: "transparent",
              textTransform: "capitalize",
              marginTop: theme.spacing(4),
            }}
            endIcon={
              context.modeRupiahOn === true ? (
                <CloseIcon style={{ color: "grey" }} />
              ) : (
                <Avatar
                  variant="rounded"
                  style={{
                    width: theme.spacing(3),
                    height: theme.spacing(3),
                  }}>
                  <Typography
                    variant="caption"
                    className={classes.title}
                    style={{ color: "white" }}>
                    Rp
                  </Typography>
                </Avatar>
              )
            }
            onClick={
              context.modeRupiahOn === false
                ? () => context.toggleModeRupiahOn(true)
                : () => context.toggleModeRupiahOn(false)
            }>
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "grey" }}>
              {context.modeRupiahOn === true
                ? "Kembali ke semula"
                : "Aktifkan mode"}
            </Typography>
          </Button>
        )}
      </Box>

      <br />

      {collectionGoalResultList.length === 0 &&
        goalDetailState.status_id !== "4" && (
          <Paper className={classes.paperColumnDuaRowFirst}>
            <Grid container>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: "grey", margin: 8 }}>
                  <PictEmptyStateGoalResult />
                  Anda belum memiliki <i>Result Value</i> ;(
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        )}

      {collectionGoalResultList.length > 0 && (
        <Paper className={classes.paperColumnDuaRowFirst}>
          {loader === true && (
            <div className={classes.rootLinearProgress}>
              <LinearProgress style={{ height: 2 }} />
            </div>
          )}

          {collectionGoalResultList.length > 0 && (
            <Box display="flex">
              <Box
                flexGrow={1}
                width="70%"
                marginLeft={theme.spacing(0.1)}
                marginTop={theme.spacing(0.1)}>
                <Button
                  variant="outlined"
                  className={classes.buttonOutlined}
                  size="small"
                  endIcon={openCollapse ? <ExpandLess /> : <ExpandMore />}
                  onClick={handleCollapse}
                  style={{
                    borderColor: "transparent",
                    textTransform: "capitalize",
                  }}>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    Record
                  </Typography>
                </Button>
              </Box>

              <Box
                width="10%"
                marginTop={theme.spacing(0.1)}
                marginLeft={theme.spacing(0.1)}>
                <Button
                  onClick={() => setModalExportGoalResult(true)}
                  variant="outlined"
                  className={classes.buttonOutlined}
                  // style={{borderWidth: 1}}
                >
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    Export
                  </Typography>
                </Button>
              </Box>

              <Box width="2%" marginTop={theme.spacing(0.1)}>
                <Typography
                  variant="subtitle1"
                  className={classes.title}
                  style={{ color: "grey" }}>
                  |
                </Typography>
              </Box>

              <Box width="18%" marginTop={theme.spacing(0.1)}>
                <Button
                  variant="outlined"
                  className={classes.buttonOutlined}
                  size="small"
                  style={{
                    borderColor: "transparent",
                    textTransform: "capitalize",
                  }}
                  endIcon={
                    context.modeRupiahOn === true ? (
                      <CloseIcon style={{ color: "grey" }} />
                    ) : (
                      <Avatar
                        variant="rounded"
                        style={{
                          width: theme.spacing(3),
                          height: theme.spacing(3),
                        }}>
                        <Typography
                          variant="caption"
                          className={classes.title}
                          style={{ color: "white" }}>
                          Rp
                        </Typography>
                      </Avatar>
                    )
                  }
                  onClick={
                    context.modeRupiahOn === false
                      ? () => context.toggleModeRupiahOn(true)
                      : () => context.toggleModeRupiahOn(false)
                  }>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey" }}>
                    {context.modeRupiahOn === true
                      ? "Kembali ke semula"
                      : "Aktifkan mode"}
                  </Typography>
                </Button>
              </Box>
            </Box>
          )}

          <List style={{ width: 120 }}>
            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
              {collectionGoalResultList.length > 0 &&
                collectionGoalResultList.map((item, idx) => {
                  /*
                        `````````
                        #1,INCREMENT

                        `````````
                    */
                  if (
                    goalDetailState.inputMethod.code ===
                    "swd_goal_input_method_increment"
                  ) {
                    return (
                      <ListItemGoalResultList
                        classes={classes}
                        item={item}
                        idx={idx}
                        rupiahMode={context.modeRupiahOn}
                        handleDropdownOpenGoalResult={
                          handleDropdownOpenGoalResult
                        }
                        handleDropdownCloseGoalResult={
                          handleDropdownCloseGoalResult
                        }
                        handleDialogEdit={handleDialogEdit}
                        handleDialogDelete={handleDialogDelete}
                        userToken={userToken}
                        anchorElListGoalResult={anchorElListGoalResult}
                        collectionGoalResultList={collectionGoalResultList}
                        goalDetailState={goalDetailState}
                        isStatusCompleted={isStatusCompleted}
                        setFireGoalDetail={setFireGoalDetail}
                      />
                    );
                  }

                  /*
                                            ``````````
                                            #2,LAST VALUE

                                            ``````````
                                        */
                  if (
                    goalDetailState.inputMethod.code ===
                    "swd_goal_input_method_last_value"
                  ) {
                    return (
                      <ListItemGoalResultList
                        classes={classes}
                        item={item}
                        idx={idx}
                        rupiahMode={context.modeRupiahOn}
                        handleDropdownOpenGoalResult={
                          handleDropdownOpenGoalResult
                        }
                        handleDropdownCloseGoalResult={
                          handleDropdownCloseGoalResult
                        }
                        handleDialogEdit={handleDialogEdit}
                        handleDialogDelete={handleDialogDelete}
                        userToken={userToken}
                        anchorElListGoalResult={anchorElListGoalResult}
                        collectionGoalResultList={collectionGoalResultList}
                        goalDetailState={goalDetailState}
                        isStatusCompleted={isStatusCompleted}
                        setFireGoalDetail={setFireGoalDetail}
                      />
                    );
                  }
                })}
            </Collapse>
          </List>

          {collectionGoalResultList.length > 0 && (
            <Box marginTop={theme.spacing(0.3)}>
              <TablePagination
                classes={{
                  toolbar: classes.toolbar,
                  root: classes.noBorderBottom,
                }}
                rowsPerPageOptions={[8]}
                count={goalResultLength}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Baris setiap halaman"
                labelDisplayedRows={({ from, to, count }) => (
                  <Typography variant="subtitle2" className={classes.title}>
                    <h4>
                      {`${from} - ${to === -1 ? count : to} dari total `}
                      <strong>{count}</strong> baris
                    </h4>
                  </Typography>
                )}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                ActionsComponent={TablePaginationCustom}
              />
            </Box>
          )}
        </Paper>
      )}

      <DialogExportGoalResult
        isModalExportGoalResult={isModalExportGoalResult}
        setModalExportGoalResult={setModalExportGoalResult}
        goalDetailState={goalDetailState}
      />

      <DialogAddResult
        classes={classes}
        openDialogAddResult={openDialogAddResult}
        setOpenDialogAddResult={setOpenDialogAddResult}
        goalDetailState={goalDetailState}
        collectionGoalResultList={collectionGoalResultList}
        setCollectionGoalResultList={context.setCollectionGoalResultList}
        setFireGoalDetail={setFireGoalDetail}
        query={queryState}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
};

export default ListCollectionGoalResult;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

/*
     -----------------------------------------------------------------------
    |   PAGE INI UNTUK SKENARIO KETIKA USER TIDAK MAU BUAT ORGANISASI-NYA,  |
    |   NTAR- NTARAN BIKIN-NYA.                                             |
    |   MAKA DI ARAHIN KE PAGE INI SETELAH KLIK BUTTON NANTI.               |
    |                                                                       |
    |   KEMUDIAN SETELAH BIKIN POSISI TERTINGGI ==> Redirect(ToDashboard);  |
     -----------------------------------------------------------------------
*/

import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";

import DialogError from "../../../components/DialogError";

import {
  ToSOMaster,
  ToDashboard,
  ToCompletionCompanyStructure, //*Second
  ToCompletionCompanyStructureFormulirKosong,
  ToCompletionCompanyStructureQuestionONE,
} from "../../../constants/config-redirect-url";
import Redirect from "../../../utilities/Redirect";
import Capitalize from "../../../utilities/Capitalize";

import { URL_API } from "../../../constants/config-api";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const styles = (theme) => ({
  /*
        `````````````````````````````````````````````````````````````````````````
        If you want styling Text Field without 'Theme Pallet', using below code : 

        `````````````````````````````````````````````````````````````````````````

    */

  button: {
    width: "400px",
    height: "42px",
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    // backgroundColor: 'cyan',
    border: 0,
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  buttonProgress: {
    // color: red[500],
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  title: {
    fontFamily: "Roboto",
  },
});

const ViewCompletionCompanyStructureFormulirKosong = (props) => {
  const { classes } = props;

  /*
        ``````````````````` 
        HANDLE DIALOG ERROR

        ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  /*

        ``````````````````` 
        ONCHANGE EMAIL

        ```````````````````
    */
  const [namajabatanTertinggi, setNamajabatanTertinggi] = useState("");

  const handleChangeNamaJabatanTertinggi = (e) => {
    setNamajabatanTertinggi(e.target.value);
  };

  /*

        ``````````````````` 
        POST SUBMIT EMAIL

        ```````````````````
    */
  const [userTokenState, setUserTokenState] = useState("");

  const [sentEmailSuccess, setSentEmailSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  // const handleTrueEmailSuccess = () => setSentEmailSuccess(true);
  // setLoading(false);
  const userToken = localStorage.getItem("userToken");

  const handleJabatanTertinggi = () => {
    setLoading(true);

    const data = {
      StructurePosition: {
        top_leader_name: namajabatanTertinggi,
      },
    };

    if (userToken !== undefined) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userToken,
      };

      axios.defaults.headers.common = header;

      axios
        .post(URL_API + `/human-resource/structure-position/root`, data)
        .then((response) => {
          console.log("Response Original : ", response);
          setLoading(false);

          localStorage.setItem(
            "data_preview_so",
            JSON.stringify(response.data.data)
          );
          Redirect(ToCompletionCompanyStructureQuestionONE);
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error : ", error.response);

          if (error.response.status == 422) {
            setErrorStatus(422);
            if (error.response.data.info.errors !== null) {
              if (error.response.data.info.errors.length > 0) {
                setListError(error.response.data.info.errors);
                setTextErrorInformation(".");
                setOpenDialogError(true);
                // if(error.response.data.info.errors[0].code == "POSITION_EXISTS"){

                // };
              }
            }
          } else {
            setTextErrorInformation("Whoops something went wrong !");
            setOpenDialogError(true);
          }
        });
    } else {
      alert("Whoops, something went wrong !");
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Grid
        container
        spacing={8}
        direction="row"
        justify="center"
        alignItems="center">
        <Grid item sm={12} style={{ textAlign: "center" }}>
          <br />
          <br />
          {/* <img src={ImageForgetPassword} alt='Image Forget Password' /> */}
          <br />
          <br />
          <br />
          <br />
          <Typography variant="h6" className={classes.title}>
            <b>Apa nama jabatan tertinggi dalam organisasi Anda ? </b>
          </Typography>
          <br />
          <br />
          <TextField
            // className={classes.margin}
            // label="Email"
            // className={classes.width}
            placeholder="Ketik di sini..."
            variant="outlined"
            id="custom-css-outlined-input"
            style={{ width: 400 }}
            onChange={handleChangeNamaJabatanTertinggi}
            value={namajabatanTertinggi}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                // root: classes.width,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
          />

          <br />
          <br />

          {namajabatanTertinggi !== "" ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleJabatanTertinggi}
              // onClick={() => handleResetPassword(namajabatanTertinggi)}
              // disabled
            >
              {loading !== true ? "Lanjut" : null}
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          ) : null}

          <br />
          <br />
        </Grid>
      </Grid>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={Capitalize(textErrorInformation)}
        errorStatus={errorStatus}
        listError={listError}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewCompletionCompanyStructureFormulirKosong);
